import React, { useCallback, useState } from 'react';
import { Select, Typography, Space, Switch, Radio } from 'antd';
import styles from '../DevicesBulkEdit.module.scss';
import { IQCameraDeviceConfig, getSupportedIQCameraAudioAlertTypes } from '../../constants';
import { Transition } from 'react-transition-group';
import { isBoolean } from 'lodash';
import { useCan } from 'features/permissions';

export const getIQCameraMetaConfig = can => {
  const isValidIQCameraMeta = meta =>
    meta?.cameraSensitivity ||
    meta?.volume ||
    meta?.audioAlertsEnabled ||
    isBoolean(meta?.enableDriverCamera);
  return {
    isValidIQCameraMeta,
    cameraSensitivity: IQCameraDeviceConfig.DefaultSensitivityValue,
    volume: IQCameraDeviceConfig.DefaultVolumeValue,
    audioAlertsEnabled: IQCameraDeviceConfig.DefaultAudioAlertTypes,
    enableDriverCamera: IQCameraDeviceConfig.DefaultEnableDriverCamera
  };
};

const DefaultStyle = {
  transitionProperty: 'opacity,max-height',
  transitionDuration: '150ms',
  transitionTimingFunction: 'linear'
};

const StateStyle = {
  entering: { maxHeight: '100px', opacity: 1 },
  entered: { maxHeight: '100px', opacity: 1 },
  exiting: { maxHeight: 0, opacity: 0 },
  exited: { maxHeight: 0, opacity: 0 }
};

function ComponentSwitch({ onInitial, onChangeCanceled, ...props }) {
  const [enabled, setEnabled] = useState(false);
  const handleSwitchChange = useCallback(checked => {
    setEnabled(checked);
  }, []);

  return (
    <Space direction="vertical" size="small" className={styles.entitySelect}>
      <Space>
        <Switch defaultChecked={props.defaultChecked} onChange={handleSwitchChange} />
        <Typography.Title level={5} className={styles.switchTitle}>
          {props.title}
        </Typography.Title>
      </Space>
      <Transition
        in={enabled}
        timeout={150}
        onEnter={onInitial}
        unmountOnExit
        onExited={onChangeCanceled}
      >
        {state => <div style={{ ...DefaultStyle, ...StateStyle[state] }}>{props.children}</div>}
      </Transition>
    </Space>
  );
}

function CameraSensitivitySelect({ t, meta, onMetaChange }) {
  const initializeMeta = useCallback(() => {
    onMetaChange({ ...meta, cameraSensitivity: IQCameraDeviceConfig.DefaultSensitivityValue });
  }, [meta, onMetaChange]);

  const cancelChange = useCallback(() => {
    onMetaChange({ ...meta, cameraSensitivity: undefined });
  }, [meta, onMetaChange]);

  return (
    <ComponentSwitch
      title={t('Devices.ActualForm.CameraSensitivity')}
      onInitial={initializeMeta}
      onChangeCanceled={cancelChange}
    >
      <Select
        value={meta.cameraSensitivity}
        onChange={cameraSensitivity => {
          onMetaChange({ ...meta, cameraSensitivity });
        }}
        options={IQCameraDeviceConfig.Sensitivities.map(opt => ({
          ...opt,
          label: t(opt.label)
        }))}
      />
    </ComponentSwitch>
  );
}

function CameraAudioAlertVolumeSelect({ t, meta, onMetaChange }) {
  const initializeMeta = useCallback(() => {
    onMetaChange({ ...meta, volume: IQCameraDeviceConfig.DefaultVolumeValue });
  }, [meta, onMetaChange]);

  const cancelChange = useCallback(() => {
    onMetaChange({ ...meta, volume: undefined });
  }, [meta, onMetaChange]);

  return (
    <ComponentSwitch
      title={t('Devices.ActualForm.DeviceVolumeSetting')}
      onInitial={initializeMeta}
      onChangeCanceled={cancelChange}
    >
      <Select
        value={meta.volume}
        onChange={volume => {
          onMetaChange({ ...meta, volume });
        }}
        options={IQCameraDeviceConfig.Volumes}
      />
    </ComponentSwitch>
  );
}

function CameraAudioAlertsSelect({ t, meta, onMetaChange }) {
  const can = useCan();

  const supportedAudioAlertTypes = getSupportedIQCameraAudioAlertTypes(can);

  const initializeMeta = useCallback(() => {
    onMetaChange({ ...meta, audioAlertsEnabled: IQCameraDeviceConfig.DefaultAudioAlertTypes });
  }, [meta, onMetaChange]);

  const cancelChange = useCallback(() => {
    onMetaChange({ ...meta, audioAlertsEnabled: undefined });
  }, [meta, onMetaChange]);

  const [options] = useState(
    Object.values(supportedAudioAlertTypes)
      .map(opt => ({
        ...opt,
        label: t(`Devices.View.CameraAudioAlert.${opt.label}`)
      }))
      .sort((a, b) =>
        a?.label?.toString()?.toLowerCase() > b?.label?.toString()?.toLowerCase() ? 1 : -1
      )
  );

  return (
    <ComponentSwitch
      title={t('Devices.ActualForm.CameraAudioAlerts')}
      onInitial={initializeMeta}
      onChangeCanceled={cancelChange}
    >
      <Select
        status={!meta?.audioAlertsEnabled?.length ? 'error' : ''}
        maxTagCount={2}
        mode="multiple"
        showSearch
        allowClear
        value={meta.audioAlertsEnabled}
        onChange={audioAlertsEnabled => onMetaChange({ ...meta, audioAlertsEnabled })}
        options={options}
      />
      {!meta?.audioAlertsEnabled?.length && (
        <Typography.Text type="danger">
          {t('Devices.FormValidation.AudioAlertsRequired')}
        </Typography.Text>
      )}
    </ComponentSwitch>
  );
}

function CameraEnableDriverCameraSwitch({ t, meta, onMetaChange }) {
  const initializeMeta = useCallback(() => {
    onMetaChange({ ...meta, enableDriverCamera: IQCameraDeviceConfig.DefaultEnableDriverCamera });
  }, [meta, onMetaChange]);

  const cancelChange = useCallback(() => {
    onMetaChange({ ...meta, enableDriverCamera: undefined });
  }, [meta, onMetaChange]);

  return (
    <ComponentSwitch
      title={t('Devices.ActualForm.DriverCamera')}
      onInitial={initializeMeta}
      onChangeCanceled={cancelChange}
    >
      <Radio.Group
        onChange={event => {
          onMetaChange({ ...meta, enableDriverCamera: event.target.value });
        }}
        value={meta.enableDriverCamera}
      >
        <Radio value={true}>{t('Common.On')}</Radio>
        <Radio value={false}>{t('Common.Off')}</Radio>
      </Radio.Group>
    </ComponentSwitch>
  );
}

export const IQCameraMetaSelect = ({ t, meta, onMetaChange }) => {
  return (
    <Space direction="vertical" className={styles.multiSelect}>
      <CameraSensitivitySelect t={t} meta={meta} onMetaChange={onMetaChange} />
      <CameraAudioAlertVolumeSelect t={t} meta={meta} onMetaChange={onMetaChange} />
      <CameraAudioAlertsSelect t={t} meta={meta} onMetaChange={onMetaChange} />
      <CameraEnableDriverCameraSwitch t={t} meta={meta} onMetaChange={onMetaChange} />
    </Space>
  );
};
