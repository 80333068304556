import React from 'react';
import { format } from 'utils/dates';

export function GeofenceExpireContentRender({ data, t, localization }) {
  const geofenceName = data.event?.name;
  const isSetToExpire = t('Alerts.IsSetToExpireOn');
  const expiresAt = data.event?.expiresAt
    ? format(new Date(data.event?.expiresAt), localization.formats.time.formats.dby_imp)
    : null;
  const description = geofenceName && expiresAt && `${geofenceName} ${isSetToExpire} ${expiresAt}`;
  return (
    <>
      <span>{description || data?.description}</span>
    </>
  );
}
