import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import services from 'features/permissions/services';

import { Table } from 'components/ant';
// import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { useUser } from 'features/user/userSlice';
import { useLocalization } from 'features/localization/localizationSlice';

import { prepareAlertsDataForTable } from './helpers/prepareAlertsDataForTable';
import { getDataForEachRow } from './helpers/getDataForEachRow';
import { useCanEveryEntity } from 'features/permissions/canHooks';
import entities from 'features/permissions/entities';
import { useIsUserAlertsEnabled } from 'features/user_alerts';
import { useCanOneOfCompanyServices } from 'features/permissions';

const AlertStatusBar = ({ listOfAlerts, onChange, isLoading, setIsLoading, setIsDirty }) => {
  const dispatch = useDispatch();
  const currentUser = useUser();
  const history = useHistory();
  const canCopy = useCanEveryEntity(entities.COMPANYALERT_COPY);
  const canEdit = useCanEveryEntity(entities.COMPANYALERT_UPDATE);
  const canDelete = useCanEveryEntity(entities.COMPANYALERT_DESTROY);
  const isUserAlertsEnabled = useIsUserAlertsEnabled();
  const localization = useLocalization();

  const isDVIRServiceEnabled = useCanOneOfCompanyServices([services.DVIR]);

  const functions = {
    dispatch,
    history,
    onChange,
    setIsLoading,
    setIsDirty,
    localization
  };

  const { type } = useParams();

  return (
    <Table
      columns={prepareAlertsDataForTable(listOfAlerts, functions, currentUser, type, {
        active: isUserAlertsEnabled,
        copy: canCopy,
        edit: canEdit,
        delete: canDelete,
        isDVIRServiceEnabled: isDVIRServiceEnabled
      })}
      dataSource={getDataForEachRow(listOfAlerts, functions)}
      pagination={false}
      loading={isLoading || false}
      rowKey="id"
      sticky
    />
  );
};

export default AlertStatusBar;

AlertStatusBar.propTypes = {
  listOfAlerts: PropTypes.shape({
    entries: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool.isRequired,
        desktop: PropTypes.bool.isRequired,
        expiry: PropTypes.number,
        mail: PropTypes.bool.isRequired,
        name: PropTypes.string,
        phone: PropTypes.bool.isRequired,
        selectedVehicles: PropTypes.arrayOf(PropTypes.number),
        selectedFleets: PropTypes.arrayOf(PropTypes.number),
        selectedUsers: PropTypes.arrayOf(PropTypes.number)
      })
    ).isRequired,
    entityType: PropTypes.string.isRequired,
    pageType: PropTypes.string.isRequired,
    currentCompanyId: PropTypes.number.isRequired
  }).isRequired,
  onChange: PropTypes.func
};
