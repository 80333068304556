import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, Table, Row, Col, Form, Checkbox } from 'antd';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import styles from './CompanyAgreementAssignment.module.scss';
import { useDevices, useFleets, useIsFetching } from 'features/fleets/fleetsSlice';
import { useTranslation } from 'react-i18next';
import { bulkUpdateDevices } from 'features/devices/devicesSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { fetchBulkEditAudits } from 'features/audits';
import { BULK_EDIT_AUDIT_ENTITY } from 'components/auditsTable/BulkEditAudits';
import { canHistoryGoBack } from 'utils/methods';
import { PATHS } from './constants';
import useDebounce from 'utils/hooks/useDebounce';
import { toLower, trim } from 'lodash';
import { sortStrings } from 'utils/strings';
import { prepareDataForMultiselect } from 'utils/filters';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';

const CompanyAgreementAssignment = ({ agreement }) => {
  const history = useHistory();
  const fleets = useFleets();
  const isFetching = useIsFetching();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const devices = useDevices();
  const [fleetList, setFleetList] = useState([]);
  const [filteredFleetList, setFilteredFleetList] = useState([]);
  const [agreementAssignmentForm] = Form.useForm();
  const [filterTypes, setFilterTypes] = useState([]);
  const types = useDeviceTypesList();
  const currentCompany = useCurrentCompany();

  const [filterText, setFilterText] = useState('');
  const debouncedSearchText = useDebounce(trim(filterText), 300);
  const [isFormValid, setIsFormValid] = useState(false);
  const formValues = Form.useWatch([], { form: agreementAssignmentForm, preserve: true });
  const [scrollSettings, setScrollSettings] = useState({ y: 0 });
  const tableRef = useRef();

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(setPageTitle(t('CompanyConfig.CompanyAgreement.Title')));
  }, [agreement, dispatch]);

  useEffect(() => {
    const fleetList = (fleets || [])
      .filter(i => (i.devices || [])?.length !== 0)
      .map(i => {
        return {
          uniqueId: 'fleet_' + i.id,
          id: i.id,
          name: i.name,
          displayName: i.name,
          isFleet: true,
          children:
            i.devices?.map(k => {
              return {
                uniqueId: 'device_' + i.id + '_' + k.id,
                id: k.id,
                name: k.name,
                displayName: k.name + (k.type?.name ? ` (${k.type?.name})` : ''),
                isDevice: true,
                fleetId: k.id,
                typeId: k.type?.id
              };
            }) || []
        };
      })
      .flat();

    const noFleetDevices = [
      {
        uniqueId: 'fleet_-1',
        name: t('Home.No Fleet'),
        displayName: t('Home.No Fleet'),
        id: -1,
        isFleet: true,
        children:
          devices
            .filter(i => i.fleetInfo.find(k => !k.id))
            ?.map(i => {
              return {
                uniqueId: 'device_-1_' + i.id,
                name: i.name,
                displayName: i.name + (i.type?.name ? ` (${i.type?.name})` : ''),
                id: i.id,
                isDevice: true,
                fleetId: 'fleet_-1',
                typeId: i.type?.id
              };
            }) || []
      }
    ];

    setFleetList([...fleetList, ...noFleetDevices]);
    agreementAssignmentForm.resetFields();
  }, [fleets, devices]);

  useEffect(() => {
    if (!!formValues && Object.keys(formValues).length > 0) {
      setIsFormValid(Object.values(formValues).some(i => i === true));
    }
  }, [agreementAssignmentForm, formValues]);

  useEffect(() => {
    setFilteredFleetList(
      JSON.parse(JSON.stringify(fleetList || []))
        .filter(a => {
          let valid = true;

          //filter by children first then only parent
          if (debouncedSearchText) {
            agreementAssignmentForm.resetFields();
            a.children = a.children.filter(
              c => toLower(c.displayName).indexOf(toLower(debouncedSearchText)) > -1
            );

            valid =
              valid &&
              ([...[a]].some(
                value => toLower(value.displayName).indexOf(toLower(debouncedSearchText)) > -1
              ) ||
                a.children.length > 0);
          }

          const checkedTypesIds = filterTypes
            .filter(type => type.checked)
            .map(type => parseInt(type.id, 10));
          if (!(checkedTypesIds.indexOf(0) > -1)) {
            a.children = a.children.filter(
              c => checkedTypesIds.indexOf(parseInt(c.typeId, 10)) > -1
            );
            valid = valid && a.children.length > 0;
          }

          return valid;
        })
        .sort((a, b) => sortStrings(a?.displayName, b?.displayName))
    );
  }, [debouncedSearchText, fleetList, isFetching, filterTypes]);

  const columns = useMemo(() => {
    return [
      ...[
        {
          title: '',
          dataIndex: 'uniqueId',
          key: 'uniqueId',
          width: 45,
          render: () => '',
          fixed: 'left'
        },
        {
          title: t('Agreement.DevicesAndFleets'),
          dataIndex: 'displayName',
          key: 'displayName',
          fixed: 'left',
          width: 300,
          render: (e, r) => {
            if (r.isFleet) {
              return (
                <div className={styles.tableFleetCell}>
                  <div>{e}</div>
                  <div className={styles.tableFleetSizeLabel}>[{r.children?.length}]</div>
                </div>
              );
            } else {
              return <div>{e}</div>;
            }
          }
        }
      ],
      ...agreement.agreements.map(a => {
        return {
          title: a.subscriptionPack.name,
          align: 'center',
          render: (e, r) => {
            return (
              <Form.Item
                shouldUpdate={(prevValues, curValues) => {
                  let isUpdated = false;
                  if (e.isFleet) {
                    isUpdated = r.children.some(
                      d =>
                        prevValues[d.uniqueId + '_' + a.id] !== curValues[d.uniqueId + '_' + a.id]
                    );
                  }

                  isUpdated =
                    isUpdated ||
                    prevValues[r.uniqueId + '_' + a.id] !== curValues[r.uniqueId + '_' + a.id];
                  return isUpdated;
                }}
                className={styles.tableCheckbox}
              >
                {() => {
                  let isIndeterminate = false;
                  if (r.isFleet) {
                    const checkedCount = r.children.filter(d =>
                      agreementAssignmentForm.getFieldValue(d.uniqueId + '_' + a.id)
                    ).length;
                    isIndeterminate = checkedCount != 0 && checkedCount != r.children.length;

                    const fleetCheckStatus = agreementAssignmentForm.getFieldValue(
                      r.uniqueId + '_' + a.id
                    );
                    if (
                      checkedCount === r.children.length &&
                      r.children.length != 0 &&
                      !fleetCheckStatus
                    ) {
                      agreementAssignmentForm.setFieldValue(r.uniqueId + '_' + a.id, true);
                    } else if (fleetCheckStatus && checkedCount !== r.children.length) {
                      agreementAssignmentForm.setFieldValue(r.uniqueId + '_' + a.id, false);
                    }
                  }

                  return (
                    <Checkbox
                      indeterminate={isIndeterminate}
                      checked={agreementAssignmentForm.getFieldValue(r.uniqueId + '_' + a.id)}
                      onChange={e => {
                        if (r.isFleet === true) {
                          const obj = r.children
                            .map(r => r.uniqueId)
                            .reduce((acc, str) => {
                              acc[str + '_' + a.id] = e.target.checked;
                              return acc;
                            }, {});
                          agreementAssignmentForm.setFieldsValue(obj);
                        }

                        agreementAssignmentForm.setFieldValue(
                          r.uniqueId + '_' + a.id,
                          e.target.checked
                        );
                      }}
                    />
                  );
                }}
              </Form.Item>
            );
          }
        };
      })
    ];
  }, [agreement]);

  const handleSubmit = () => {
    let payload = [];

    const devices = fleetList.map(f => f.children).flat();

    devices.forEach(d => {
      const checkedAgreements = agreement.agreements
        .filter(a => formValues[d.uniqueId + '_' + a.id])
        .map(i => i.id);

      if (checkedAgreements.length > 0) {
        payload.push({
          id: d.id,
          agreements: checkedAgreements
        });
      }
    });

    payload = payload.reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);
      if (!x) {
        acc.push(current);
      } else {
        x.agreements = [...new Set([...x.agreements, ...current.agreements])];
      }
      return acc;
    }, []);

    dispatch(
      bulkUpdateDevices({
        devices: payload,
        onUpdated: ({ successCount, failedCount }) => {
          const allSuccess = successCount === payload.length;
          const allFailed = !successCount;
          dispatch(
            openToast({
              type: allSuccess
                ? ToastType.Success
                : allFailed
                ? ToastType.Error
                : ToastType.Warning,
              message: allSuccess
                ? t('Devices.BulkEdit.Notifications.AgreementUpdateSuccess', {
                    successCount
                  })
                : allFailed
                ? t('Devices.BulkEdit.Notifications.AgreementUpdateFailed')
                : t('Devices.BulkEdit.Notifications.PartialAgreementUpdateSuccess', {
                    successCount,
                    failedCount
                  })
            })
          );
          if (!allFailed) {
            dispatch(fetchBulkEditAudits({ entityName: BULK_EDIT_AUDIT_ENTITY.DEVICE }));
            setIsFormValid(false);
            canHistoryGoBack(history, PATHS.AGREEMENT_DEFAULT);
          }
        }
      })
    );
  };

  const typeSelectTitle = useMemo(
    () => (filterTypes?.some(value => !value.checked) ? t('Common.Types') : t('Common.AllTypes')),
    [filterTypes]
  );

  useEffect(() => {
    setFilterTypes(
      prepareDataForMultiselect(
        types.slice().sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
        t('Common.AllTypes'),
        null
      )
    );
  }, [types, currentCompany?.id, t]);

  const handleResize = () => {
    const { innerHeight: height } = window;
    const header = tableRef.current.querySelector('.ant-table-thead');
    const headerHeight = header?.getBoundingClientRect()?.height || 0;
    //260 is for form footer and web main header
    const newTableHeight = height - headerHeight - 220;
    setScrollSettings({ x: 350 + agreement.agreements.length * 125, y: newTableHeight });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    const tableElement = document.querySelector(`.${CSS.escape(styles.agreementDevices)}`);
    resizeObserver.observe(tableElement);
    return () => {
      resizeObserver.unobserve(tableElement);
    };
  }, []);

  return (
    <div>
      <EditRouteGuard when={isFormValid} navigate={history.push} />
      <Row>
        <Col xs={24}>
          <FilterWrapper
            containerStyle={{ background: '#fff', paddingTop: 5, borderTop: '1px solid #dadee3' }}
          >
            <AntSearchbar onFilter={setFilterText} />
            <AntMultiselect title={typeSelectTitle} data={filterTypes} onFilter={setFilterTypes} />
          </FilterWrapper>
        </Col>
        <Col xs={24}>
          <Form className={styles.assignAgreementPanel} form={agreementAssignmentForm}>
            <Table
              columns={columns}
              virtual
              className={styles.agreementDevices}
              tableLayout={'auto'}
              pagination={false}
              ref={tableRef}
              size={'small'}
              loading={isFetching}
              rowKey={'uniqueId'}
              rowClassName={record => {
                if (record.isDevice) {
                  return 'deviceRow';
                }
              }}
              scroll={scrollSettings}
              expandable={{
                expandIcon: ({ expanded, onExpand, record }) => {
                  if (record.children && record.children?.length !== 0) {
                    if (expanded) {
                      return (
                        <a
                          style={{ color: '#000' }}
                          onClick={e => {
                            onExpand(record, e);
                          }}
                        >
                          <i className={`tn-i-chevron-up`} />
                        </a>
                      );
                    } else {
                      return (
                        <a
                          style={{ color: '#000' }}
                          onClick={e => {
                            onExpand(record, e);
                          }}
                        >
                          <i className={`tn-i-chevron-right`} />
                        </a>
                      );
                    }
                  }
                },
                rowExpandable: record => record.children && record.children?.length !== 0
              }}
              dataSource={filteredFleetList}
            />
          </Form>
        </Col>
      </Row>
      <div className={styles.formFooter}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          onClick={handleSubmit}
          disabled={!isFormValid}
        >
          {t('Agreement.AssignAgreement')}
        </Button>
        <Button size="large" onClick={history.goBack}>
          {t('Common.CancelButton')}
        </Button>
      </div>
    </div>
  );
};

export default CompanyAgreementAssignment;
