import { useMemo, useCallback } from 'react';
import { CountryRegionData } from 'react-country-region-selector';
import i18n from 'i18next';
import { sortBy } from 'lodash';

export const PrefixCountryNameMapping = {
  'Brunei Darussalam': 'Brunei',
  'Congo, Republic of the (Brazzaville)': 'Congo',
  'Congo, the Democratic Republic of the (Kinshasa)':
    'Congo (Democratic Republic of the (Kinshasa))',
  "Côte d'Ivoire, Republic of": 'Côte d’Ivoire',
  'Falkland Islands (Islas Malvinas)': 'Falkland Islands (Malvinas)',
  'French Southern and Antarctic Lands': 'French Southern Territories',
  'Gambia, The': 'Gambia',
  'Holy See (Vatican City)': 'Holy See',
  'Iran, Islamic Republic of': 'Iran',
  "Korea, Democratic People's Republic of": 'North Korea',
  'Korea, Republic of': 'South Korea',
  Macao: 'Macau',
  'Macedonia, Republic of': 'Macedonia',
  'Micronesia, Federated States of': 'Micronesia',
  'Palestine, State of': 'Palestine',
  'Russian Federation': 'Russia',
  'Saint Martin': 'Saint Martin (French part)',
  'Sao Tome and Principe': 'Sao Tomé and Príncipe',
  'South Georgia and South Sandwich Islands': 'South Georgia and the South Sandwich Islands',
  'Syrian Arab Republic': 'Syria',
  'Tanzania, United Republic of': 'Tanzania',
  'Venezuela, Bolivarian Republic of': 'Venezuela',
  'Virgin Islands, British': 'Virgin Islands (British)',
  'Virgin Islands, U.S.': 'Virgin Islands (US)'
};

const StateWithEmptyCodeCorrectionMapping = {
  'Marga-Marga': 'MM',
  Radoviš: '64'
};

export const slicedToArray = (function() {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i['return']) _i['return']();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  return function(arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError('Invalid attempt to destructure non-iterable instance');
    }
  };
})();

export const useCountryOptions = (countryCodeAsValue = true) => {
  return useMemo(() => {
    const countryRegionList = CountryRegionData.reduce(
      (a, country) => [
        ...a,
        {
          label: i18n.t(`Countries.${PrefixCountryNameMapping[country[0]] || country[0]}`),
          value: countryCodeAsValue ? country[1] : country[0]
        }
      ],
      []
    );

    return countryRegionList;
  }, [CountryRegionData]);
};

export const getCountryRegions = ({
  country,
  addCountryAsRegion = false,
  checkLabel = false,
  defaultValue = undefined
}) => {
  try {
    if (!country) {
      return [];
    }

    let regions = [];
    CountryRegionData.forEach(function(i) {
      if ((checkLabel ? i[0] : i[1]) === country) {
        regions = i;
      }
    });
    if (!regions || regions.length === 0) {
      return [];
    }

    const C = {
      REGION_LIST_DELIMITER: '|',
      SINGLE_REGION_DELIMITER: '~'
    };

    let defaultValuePopulated = false;
    let formattedRegions = regions[2].split(C.REGION_LIST_DELIMITER).map(function(regionPair) {
      const _regionPair$split = regionPair.split(C.SINGLE_REGION_DELIMITER),
        _regionPair$split2 = slicedToArray(_regionPair$split, 2),
        regionName = _regionPair$split2[0],
        _regionPair$split2$ = _regionPair$split2[1],
        regionShortCode = _regionPair$split2$ === undefined ? null : _regionPair$split2$;

      //if the default value label or value match then we will take given value instead of library for backward compatibility
      if (
        defaultValue !== undefined &&
        (defaultValue.label === regionName || defaultValue.value === regionShortCode)
      ) {
        defaultValuePopulated = true;
        return defaultValue;
      } else {
        return {
          label: regionName,
          value:
            regionShortCode === ''
              ? StateWithEmptyCodeCorrectionMapping[regionName] || ''
              : regionShortCode
        };
      }
    });

    //if default value is given but not found, try to find if any of them have the same value or label
    //if value is same but label different we will leave it
    //if label is same but value is different then we have to convert the value so that it match incoming data
    if (!defaultValuePopulated && defaultValue !== undefined) {
      formattedRegions.push(defaultValue);
    }

    //check if need to add country as a state option
    if (addCountryAsRegion) {
      let possibleCountryOptionIndex = formattedRegions.findIndex(
        i => i.value === regions[1] || i.label === regions[0]
      );

      if (possibleCountryOptionIndex === -1) {
        formattedRegions.push({
          label: i18n.t(`Countries.${PrefixCountryNameMapping[regions[0]] || regions[0]}`),
          value: regions[1]
        });
      } else {
        formattedRegions[possibleCountryOptionIndex].label = i18n.t(
          `Countries.${PrefixCountryNameMapping[
            formattedRegions[possibleCountryOptionIndex]?.label
          ] || formattedRegions[possibleCountryOptionIndex]?.label}`
        );
      }
    }

    return sortBy(formattedRegions, [o => o.label]);
  } catch (error) {
    return [];
  }
};

export const getCountryCodebyName = countryName => {
  const countryArr = CountryRegionData.find(
    countryRegion => countryRegion && countryRegion[0] && countryRegion[0] === countryName
  );
  return countryArr && countryArr[1];
};

export const getStateLabelByCountry = countryName => {
  const countryCode = getCountryCodebyName(countryName);
  switch (countryCode) {
    case 'CA':
      return i18n.t('Locations.Form.ProvinceTerritory');
    case 'GB':
    case 'IE':
      return i18n.t('Locations.Form.County');
    default:
      return i18n.t('Locations.Form.State');
  }
};
