import services from 'features/permissions/services';
import GlobalRoles from 'features/permissions/GlobalRoles';
import { FeatureFlag } from 'features/permissions/featureFlags';
import entities from 'features/permissions/entities';

const PATH_TOKEN = 'page';

export const redirectPath = ({ servicesPermissions, userInfo, can, userPreferences, pages }) => {
  const currentPath = window.location.pathname;
  let pathname = currentPath;
  if (pathname === '/login' || pathname === '/sso' || pathname === '/logout') {
    pathname = '/';
  }

  if (userInfo?.roles?.some(role => role.name === GlobalRoles.TrackingOnlyAccess)) {
    pathname = '/tracking';
  }

  if (can && userInfo?.type?.code === 'DRIVER') {
    const isDriverPortal = can({
      everyCompanyService: [services.ELD],
      everyEntity: [entities.DRIVERPORTAL],
      featureFlag: [FeatureFlag.driverPortal.flag],
      otherConditions: [() => userInfo?.type?.code === 'DRIVER']
    });

    if (isDriverPortal) {
      pathname = '/fatigue/eld/driverportal/' + userInfo.id;
    } else {
      const isDriverPortal_FBTManager =
        userInfo?.type?.code === 'DRIVER' &&
        can({ featureFlag: FeatureFlag.driverPortalTripManager.flag })
          ? true
          : false;
      if (isDriverPortal_FBTManager) {
        pathname = '/fbtmanager';
      }
    }
  }

  const canAccessNonCameraFeatures = can({
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  });

  if (userPreferences && userPreferences.homeUrl && canAccessNonCameraFeatures) {
    const accessiblePages = pages.filter(page => can({ ...page }));

    if (accessiblePages.find(i => i.link === userPreferences.homeUrl)) {
      pathname = userPreferences.homeUrl;
    }
  }

  // If user has no roles but has access to a service/entity - redirect to that
  if (pathname === '/' && !userInfo.roles?.length) {
    const serviceToPathName = {
      [services.FBTMANAGER]: '/fbtmanager',
      [services.TRACKING]: '/tracking',
      [services.TRACKINGHD]: '/tracking',
      [services.SCORECARD]: '/scorecard',
      [services.SMARTJOBS]: '/smartjobs',
      [services.MESSAGING]: '/messaging',
      [services.INSIGHTSBASIC]: '/insights',
      [services.INSIGHTSSEARCH]: '/insights',
      [services.INSIGHTSADVPROD]: '/insights',
      [services.INSIGHTSADVSAFETY]: '/insights',
      [services.INSIGHTSADVCOMP]: '/insights',
      [services.INSIGHTSBUILDER]: '/insights',
      [services.INSIGHTSVIZ]: '/insights',
      [services.INSIGHTSAI]: '/insights',
      [services.INSIGHTSSUSTAINABILITYSNAPSHOT]: '/insights',
      [services.INSIGHTSSUSTAINABILITYGOALS]: '/insights',
      [services.INSIGHTSSUSTAINABILITYFLEETS]: '/insights',
      [services.INSIGHTSREPORTS]: '/insights',
      [services.INSIGHTSNPI]: '/insights',
      [services.DIRECTORINSIGHTS]: '/insights',
      [services.VEHMNT]: '/vehiclemaintenance',
      [services.DMGT]: '/drivermanagement',
      [services.EASYDOCS]: '/easydocs',
      [services.JOURNEYPLANNER]: '/journeyplanner',
      [services.TACHO]: '/fatigue/tacho',
      [services.ELD]: '/fatigue/eld',
      [services.EWD]: '/fatigue',
      [services.SWD]: '/fatigue'
    };

    if (servicesPermissions?.length) {
      if (servicesPermissions.includes(services.COMMON)) {
        pathname = '/home';
      } else {
        pathname = serviceToPathName[servicesPermissions[0]];
      }
    }
  }

  return pathname || '/';
};

export const getAuthedRedirectUrlWitPath = ({
  redirectUrl = '',
  servicesPermissions,
  userInfo,
  can
}) => {
  let pathname = redirectPath({ servicesPermissions, userInfo, can });

  return pathname && pathname !== '/'
    ? `${redirectUrl}${redirectUrl?.includes('?') ? '&' : '?'}${PATH_TOKEN}=${pathname}`
    : redirectUrl;
};

export const getPathFromAuthedRedirectUrl = ({
  urlSearch,
  servicesPermissions,
  userInfo,
  can,
  userPreferences,
  pages
}) => {
  const reg = new RegExp(`${PATH_TOKEN}=(.+)`);
  let path;
  try {
    path = reg.exec(urlSearch)?.[1];
  } catch (error) {
    //leave it, we will have fallback handling
  }

  return path || redirectPath({ servicesPermissions, userInfo, can, userPreferences, pages });
};
