import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Tooltip, Button, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

import { useCan, entities, FeatureFlag } from 'features/permissions';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { BUTTON_IDS } from '../helper';
import { useUserInfo } from 'features/user/userSlice';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';

export const DeviceActionsCell = ({ device, onDelete }) => {
  const { t } = useTranslation();

  const can = useCan();
  const currentUser = useUserInfo();
  const isSiteAdmin = currentUser.siteAdmin;
  const canEditDevice = can({ everyEntity: [entities.DEVICE_UPDATE] });
  const deviceTypes = useDeviceTypesList();
  const canDeleteDevice = can({ everyEntity: [entities.DEVICE_DESTROY] }) && isSiteAdmin;

  const history = useHistory();

  const handleEdit = useCallback(() => {
    history.push(`/settings/devices/edit/id/${device.id}`);
  }, [history, device?.id]);

  const handleDelete = useCallback(() => {
    let content = `${t('Common.SureDelete')} ${t('Common.device')} ${device.name}?`;
    if (deviceTypes.find(i => i.id === device.typeId)?.code === 'EDR') {
      content = (
        <div>
          {t('Vehicles.Form.UpdateEDRWarning', {
            action: t('Vehicles.Form.Delete'),
            type: t('Common.Device').toLowerCase()
          })}
          <br />
          {t('Vehicles.Form.UpdateEDRWarning2')}
        </div>
      );
    }

    confirmationModal(
      `${t('Common.DeleteButton')} ${device.name}`,
      content,
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      onDelete(device),
      'delete'
    );
  }, [device, onDelete]);

  return (
    <Space size={4}>
      <Tooltip title={!canEditDevice && t('Messaging.NoPermission')}>
        <Button
          disabled={!canEditDevice}
          type="text"
          icon={<FontAwesomeIcon icon={faPen} color="#64748B" />}
          onClick={handleEdit}
          id={BUTTON_IDS.edit_single_device}
        ></Button>
      </Tooltip>
      <Tooltip title={!canDeleteDevice && t('Messaging.NoPermission')}>
        <Button
          disabled={!canDeleteDevice}
          type="text"
          icon={<FontAwesomeIcon icon={faTrash} color="#FF7875" />}
          onClick={handleDelete}
          id={BUTTON_IDS.delete_single_device}
        ></Button>
      </Tooltip>
    </Space>
  );
};
