import React from 'react';
import { Route, Switch } from 'react-router';
import Easydocs from './Easydocs';
import DocumentView from './Documents/DocumentView';
import { entities, services, Can } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { TabNavLink } from 'components/nav/NavLinks';
import { useTranslation } from 'react-i18next';
import { EasydocsDevices } from './MobileDevices/EasydocsDevices';
import { DeviceDetails } from './MobileDevices/DeviceDetails';

export const EasydocsRouter = () => {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ borderBottom: '2px solid #f2f3f2' }}>
        <TabNavLink
          to="/easydocs/folders"
          isActive={(match, location) =>
            location.pathname === '/easydocs/folders' ||
            location.pathname === '/easydocs' ||
            location.pathname.includes('/easydocs/documents')
          }
        >
          {t('Easydocs.Folders')}
        </TabNavLink>
        <TabNavLink to="/easydocs/mobiledevices">{t('Easydocs.mobileDevices')}</TabNavLink>
      </div>
      <Switch>
        <Route exact path={['/easydocs', '/easydocs/folders']}>
          <Can everyEntity={[entities.FOLDER]} onFail={<NoAccessBody />}>
            <Easydocs />
          </Can>
        </Route>
        <Route exact path="/easydocs/mobiledevices">
          <Can everyEntity={[entities.FOLDER]} onFail={<NoAccessBody />}>
            <EasydocsDevices />
          </Can>
        </Route>
        <Route path="/easydocs/mobiledevices/id/:id">
          <Can everyEntity={[entities.FOLDER]} onFail={<NoAccessBody />}>
            <DeviceDetails />
          </Can>
        </Route>
        <Route path="/easydocs/documents">
          <Can
            oneOfEntities={[entities.DOCUMENT_VIEW]}
            oneOfServices={[services.EASYDOCS]}
            onFail={<NoAccessBody />}
          >
            <DocumentView />
          </Can>
        </Route>
      </Switch>
    </>
  );
};
