import React, { useState, useEffect, useMemo } from 'react';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSubCompanies, useCurrentCompany } from 'features/company/companySlice';
import {
  useFolders,
  fetchFolders,
  setActiveFolder as setPrevActiveFolder
} from 'features/easydocs/foldersSlice';
import { useDocuments } from 'features/easydocs/documentsSlice';
import request from 'superagent';
import { API_PATH } from 'config';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import useDebounce from 'utils/hooks/useDebounce';
import { toLower } from 'lodash';
import ListItems from './ListItems';
import ContentFolder from './ContentFolder';
import { FormModal } from './FormModal/FormModal';
import { useFleets, useVehicles, useDevices } from 'features/fleets/fleetsSlice';
import { useUserKey } from 'features/user/userSlice';
import { ExportToExcel, ExportType } from './ExportToExcel';
import { folderlessFolderFile } from './constants';
import { cache } from './CellRenderers';
import { sortBy } from 'lodash';
import { Can, entities } from 'features/permissions';
import iconAddFolder from '../../static/images/icons/add-folder-icon.svg';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import { Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useLocalization } from 'features/localization/localizationSlice';
import { BUTTON_IDS } from 'utils/globalConstants';

const Easydocs = () => {
  const localization = useLocalization();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const company = useCurrentCompany();
  const userKey = useUserKey();
  const allFolders = useFolders();
  const allDocumentsData = useDocuments();
  // Fleets, vehicles and devices are needed in the Export to Excel feature, for associations
  const fleets = useFleets('', 'APP');
  const vehicles = useVehicles();
  const devices = useDevices();

  useEffect(() => {
    dispatch(setPageTitle(`${t('Easydocs.Easydocs')}`));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  // Subcompanies
  const subCompanies = useSubCompanies();

  // Initialise folders, folder options for forms and content documents
  const [folders, setFolders] = useState([]);
  const [foldersForForms, setFoldersForForms] = useState([]);
  const [activeFolder, setActiveFolder] = useState({ name: '' });
  const [editFolderName, setEditFolderName] = useState('');
  const [newActiveFolder, setNewActiveFolder] = useState({ name: '' });
  const [folderAdded, setFolderAdded] = useState(false);

  //Set active folder with we come from a document view
  useEffect(() => {
    if (history.location.state?.folder) {
      const historyFolder = allFolders?.find(
        folder => folder.id === history.location.state.folder.id
      );
      setEditFolderName(historyFolder?.name);
    }
  }, [allFolders, history]);

  useEffect(() => {
    const editFolder = allFolders.find(folder => folder.name === editFolderName);
    let allFoldersCopy = [...allFolders];

    setFolders([{ ...folderlessFolderFile(t), companyId: company.id }, ...allFoldersCopy]);
    if (editFolder) {
      setActiveFolder(editFolder);
    } else {
      setActiveFolder(allFoldersCopy[0]);
    }
    // Make the folder options for forms in select
    const formFolders = allFolders
      .filter(folder => folder.id)
      .map(folder => ({
        ...folder,
        value: folder.id,
        label: folder.name,
        //if in edit, check the ones that are already in the geofence
        checked: false
      }));
    setFoldersForForms(formFolders);
  }, [allFolders, company.id, editFolderName, t]);

  const [contentDocuments, setContentDocuments] = useState([]);
  // Filter folders by search
  const [filterText, setFilterText] = useState('');
  const debouncedSearchText = useDebounce(filterText, 300);

  const allDocuments = useMemo(() => {
    let rows = allDocumentsData?.map((d, idx) => {
      let row = Object.assign({}, d);

      let docCompanies = [];
      let docFleets = [];

      if (row.associations && row.associations.length > 0) {
        row.associations.forEach(a => {
          if (a.fleetId) {
            if (a.fleetId !== -1) {
              const fle = fleets.find(f => f.id === a.fleetId);
              const comp = subCompanies?.find(c => c.id === fle?.company?.id);
              if (comp && !docCompanies.find(c => c.id === comp?.id)) {
                docCompanies.push({ id: comp?.id, name: comp?.name });
              }
              if (fle && !docFleets.find(f => f.id === fle?.id)) {
                docFleets.push({ id: fle?.id, name: fle?.name });
              }
            } else {
              const f1 = fleets.filter(f => !f.id);
              let v1 = [];
              if (f1.length > 0) {
                v1 = f1[0]?.vehicles;
              }

              v1.forEach(v => {
                const c1 = subCompanies?.find(c => c.id === v?.companyId);
                if (!docCompanies.find(c => c.id === c1?.id)) {
                  docCompanies.push({ id: c1?.id, name: c1?.name });
                }
              });
            }
          } else if (a.deviceId) {
            const dev = devices?.find(de => de.id === a.deviceId);
            const comp = subCompanies?.find(c => c.id === dev?.companyId);
            if (comp && !docCompanies.find(c => c.id === comp?.id)) {
              docCompanies.push({ id: comp?.id, name: comp?.name });
            }
            dev?.fleetInfo?.forEach(fle => {
              if (fle && !docFleets.find(f => f.id === fle?.id)) {
                docFleets.push({ id: fle?.id, name: fle?.name });
              }
            });
          } else if (a.vehicleId) {
            const veh = vehicles?.find(de => de.id === a.vehicleId);
            const comp = subCompanies?.find(c => c.id === veh?.companyId);
            if (comp && !docCompanies.find(c => c.id === comp?.id)) {
              docCompanies.push({ id: comp?.id, name: comp?.name });
            }
            veh?.fleets?.forEach(fle => {
              if (fle && !docFleets.find(f => f.id === fle?.id)) {
                docFleets.push({ id: fle?.id, name: fle?.name });
              }
            });
          }
        });

        row.company = docCompanies.filter(c => c.name);
        row.fleet = docFleets.filter(f => f.name);
      }
      return row;
    });
    return rows;
  }, [allDocumentsData, vehicles, devices, fleets, subCompanies]);

  useEffect(() => {
    let currentFolderDocuments = allDocuments.filter(
      doc =>
        doc.folders &&
        (activeFolder.name !== folderlessFolderFile(t).name
          ? doc.folders.find(folder => folder.id === activeFolder.id)
          : doc.folders.length === 0)
    );

    if (debouncedSearchText) {
      setEditFolderName('');
      currentFolderDocuments = currentFolderDocuments.filter(
        doc => toLower(doc.name || doc.filename).indexOf(toLower(debouncedSearchText)) > -1
      );
    }

    setContentDocuments(
      sortBy(currentFolderDocuments, [document => toLower(document.name || document.filename)])
    );
  }, [activeFolder, allDocuments, debouncedSearchText, t]);

  useEffect(() => {
    setShowFormModal(false);
  }, [allDocuments]);

  useEffect(() => {
    const filteredFolders = [
      { ...folderlessFolderFile(t), companyId: company.id },
      ...allFolders
    ].filter(folder => {
      let validFolder = true;
      // Filter by search for folders and documents name
      if (debouncedSearchText) {
        setEditFolderName('');
        const documents = allDocuments.filter(
          doc =>
            (doc.folders && doc.folders.find(folderDoc => folderDoc.id === folder.id)) ||
            (doc.folders.length === 0 && folder.name === 'Folderless Documents')
        );
        validFolder =
          validFolder &&
          documents.find(
            doc => toLower(doc.name || doc.filename).indexOf(toLower(debouncedSearchText)) > -1
          );
      }

      return validFolder;
    });
    setFolders(filteredFolders);
    // On each filter process, set the new active folder
    if (!editFolderName) {
      if (filteredFolders.length > 0) {
        setActiveFolder(filteredFolders[0]);
      } else {
        setActiveFolder({ name: '' });
      }
    }
  }, [debouncedSearchText, allDocuments, allFolders, company.id, editFolderName, t]);

  //Form Modal
  const [showFormModal, setShowFormModal] = useState(false);

  //Add Folder - POST
  const addFolder = () => {
    const newFoldersName = allFolders.filter(folder => folder.name.includes('New Folder'));
    const newFolderNameStrictly = allFolders.find(folder => folder.name === 'New Folder');
    let folderName = `${t('Easydocs.NewFolderSimple')}`;
    let counter = 0;
    /* eslint-disable no-loop-func */
    if (newFoldersName.length && newFolderNameStrictly) {
      do {
        counter++;
        folderName = `${t('Easydocs.NewFolder')}${counter})`;
      } while (allFolders.some(folder => folder.name === folderName));
    }
    const postBody = {
      name: folderName,
      description: ''
    };

    request('POST', API_PATH + `/folders?company_id=${company.id}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .send(postBody)
      .then(() => {
        setEditFolderName('');
        dispatch(fetchFolders());
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18next.t('Easydocs.Notifications.NewFolderNotification', {
              name: folderName || 'New Folder'
            }),
            autoHide: true
          })
        );
      })
      .catch(err => {
        console.warn('ERROR', err);
      });
    setNewActiveFolder(folderName);
    setFolderAdded(true);
  };

  //Recalculate heights for documents grid
  const [tableRef, setTableRef] = useState(null);
  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [folders, tableRef]);

  const handleShowUploadModal = () => {
    setShowFormModal(true);
  };

  const handleCloseUploadModal = () => {
    setShowFormModal(false);
  };

  const searchDocuments = value => {
    setFilterText(value);
  };

  const handleFolderClick = folder => {
    setActiveFolder(folder);
    dispatch(setPrevActiveFolder(folder));
  };

  useEffect(() => {
    if (folderAdded) {
      const newFolder = allFolders.find(folder => folder.name === newActiveFolder);
      setActiveFolder(newFolder);
      setFolderAdded(false);
    }
  }, [allFolders]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        background: '#f7f8f9',
        minHeight: 'calc(100vh - 62px)',
        overflow: 'hidden'
      }}
      className="easydocs"
    >
      <HeaderPageWrapper>
        <Space size={16} style={{ marginLeft: 'auto' }}>
          <Can everyEntity={[entities.DOCUMENT_CREATE]}>
            <Button
              type="primary"
              size="large"
              onClick={handleShowUploadModal}
              id={BUTTON_IDS.easydocsShowUpload}
            >
              {t('Easydocs.UploadDocuments')}
            </Button>
            <FormModal
              show={showFormModal}
              title={t('Easydocs.UploadDocuments')}
              onHide={handleCloseUploadModal}
              folders={foldersForForms}
              companies={subCompanies}
            />
          </Can>
          <ExportToExcel
            data={allDocuments}
            company={company}
            folders={allFolders}
            fleets={fleets}
            vehicles={vehicles}
            devices={devices}
            localization={localization}
            subCompanies={subCompanies}
            exportType={ExportType.Folder}
          />
        </Space>
      </HeaderPageWrapper>
      <div style={{ display: 'flex', overflow: 'scroll' }}>
        {activeFolder && (
          <ListItems
            listValues={folders}
            activeItem={activeFolder.id}
            onClickItem={handleFolderClick}
            header={t('Easydocs.Folders')}
            addItemIcon={iconAddFolder}
            onClickAddItem={addFolder}
            searchDocuments={searchDocuments}
          />
        )}
        {folders.length > 0 && (
          <ContentFolder
            activeFolder={activeFolder}
            setEditFolderName={setEditFolderName}
            company={company}
            userKey={userKey}
            documents={contentDocuments}
            setTableRef={setTableRef}
            foldersForForms={foldersForForms}
          />
        )}
      </div>
    </div>
  );
};

export default Easydocs;
