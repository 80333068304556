import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { Col, Form, Input, Row, Switch } from 'antd';

export const HeaderFormSection = ({
  formItemStyle,
  setCompanyLevel,
  customComponent,
  templateName
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deviceLevelSettingTranslation = t('Common.DeviceLevelSettings');

  // Validate that the template name does not match the translation, ignoring case
  const validateTemplateName = (_, value) => {
    if (
      value &&
      value.trim().toLowerCase() === deviceLevelSettingTranslation.trim().toLowerCase()
    ) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('CompanyConfig.DeviceConfigurations.AddTemplateHeaderForm.ValidTemplateName')
        })
      );
      return Promise.reject();
    }
    return Promise.resolve();
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Form.Item
          style={formItemStyle}
          colon={false}
          label={t('CompanyConfig.DeviceConfigurations.AddTemplateHeaderForm.NameInputLabel')}
          name="name"
          rules={[
            {
              required: true,
              message: t(
                'CompanyConfig.DeviceConfigurations.AddTemplateHeaderForm.NameInputRequiredInput'
              )
            },
            {
              validator: validateTemplateName
            }
          ]}
        >
          <Input maxLength={255} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          style={formItemStyle}
          label={t(
            'CompanyConfig.DeviceConfigurations.AddTemplateHeaderForm.DescriptionInputLabel'
          )}
          name="description"
        >
          <Input.TextArea maxLength={65535} />
        </Form.Item>
      </Col>
      {customComponent && <Col span={12}>{customComponent}</Col>}
      <Col span={12}>
        <Form.Item
          style={formItemStyle}
          label={t(
            'CompanyConfig.DeviceConfigurations.AddTemplateHeaderForm.CompanyLevelToggleLabel',
            { templateName: templateName }
          )}
          name="default"
          tooltip={t(
            'CompanyConfig.DeviceConfigurations.AddTemplateHeaderForm.CompanyLevelToggleTooltip',
            { templateName: templateName }
          )}
          valuePropName="checked"
        >
          <Switch onChange={checked => setCompanyLevel && setCompanyLevel(checked)} />
        </Form.Item>
      </Col>
    </Row>
  );
};
