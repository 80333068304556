import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styles from './Devices.module.scss';
import { UpOutlined } from '@ant-design/icons';
import { Col, Collapse, Row, Table, Button } from 'antd';
import { useCurrentCompany } from 'features/company/companySlice';
import { useTranslation } from 'react-i18next';
import { ngAgreementApi, useGetCompanyAgreementsQuery } from 'services/nextgen/ngAgreementApi';
import { bulkUpdateDevices, saveDeviceApi } from 'features/devices/devicesSlice';
import { canHistoryGoBack } from 'utils/methods';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { PATHS } from './constants';
import { fetchBulkEditAudits } from 'features/audits';
import { BULK_EDIT_AUDIT_ENTITY } from 'components/auditsTable/BulkEditAudits';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import useDebounce from 'utils/hooks/useDebounce';
import { toLower, trim } from 'lodash';
import { sortStrings } from 'utils/strings';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';

export const DeviceAgreementAssignment = ({ devices }) => {
  const currentCompany = useCurrentCompany();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedAgreements, setSelectedAgreements] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filteredAgreements, setFilteredAgreements] = useState([]);
  const debouncedSearchText = useDebounce(trim(filterText), 300);

  const { data: agreementData, isFetching: isFetchingAgreement } = useGetCompanyAgreementsQuery(
    {
      id: currentCompany?.id,
      embed: 'devices'
    },
    { skip: currentCompany?.id === undefined }
  );

  const columns = [
    {
      title: t('Agreement.BillingCode'),
      dataIndex: 'workOrder',
      key: 'workOrder',
      width: '30%',
      render: (e, r) => {
        return r.subscriptionPack.billingCode;
      }
    },
    {
      title: t('Agreement.Description'),
      dataIndex: 'description',
      key: 'description',
      width: '70%',
      render: (e, r) => {
        return r.subscriptionPack.description;
      }
    }
  ];

  useEffect(() => {
    setIsValid(selectedAgreements.length !== 0);
  }, [selectedAgreements]);

  useEffect(() => {
    dispatch(setPageTitle(t('Devices.BulkEdit.AssignAgreements')));
    dispatch(setBackButton(true));
  }, [dispatch, t]);

  useEffect(() => {
    setFilteredAgreements(
      (agreementData || [])
        .filter(a => {
          let valid = true;

          if (debouncedSearchText) {
            valid =
              valid &&
              [
                ...[a.workOrder, a.description],
                ...a.agreements.map(c => c.subscriptionPack.billingCode),
                ...a.agreements.map(c => c.subscriptionPack.description)
              ].some(value => toLower(value).indexOf(toLower(debouncedSearchText)) > -1);
          }

          return valid;
        })
        .sort((a, b) => sortStrings(a?.name, b?.name))
    );
  }, [debouncedSearchText, agreementData, isFetchingAgreement]);

  const handleSubmit = async () => {
    if ((devices || []).length === 1) {
      const postBody = {
        agreements: selectedAgreements
      };
      const devicesResponse = await dispatch(saveDeviceApi('PUT', devices[0].id, postBody));
      if (devicesResponse) {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t(`Devices.ActualForm.DeviceUpdated`, {
              name: devices[0].name
            })
          })
        );

        dispatch(ngAgreementApi.util.invalidateTags(['deviceAgreement']));
        setIsValid(false);
        canHistoryGoBack(history, PATHS.DEVICE_DEFAULT);
      }
    } else {
      dispatch(
        bulkUpdateDevices({
          devices: devices.map(i => {
            return {
              id: i.id,
              agreements: selectedAgreements
            };
          }),
          onUpdated: ({ successCount, failedCount }) => {
            const allSuccess = successCount === devices.length;
            const allFailed = !successCount;
            dispatch(
              openToast({
                type: allSuccess
                  ? ToastType.Success
                  : allFailed
                  ? ToastType.Error
                  : ToastType.Warning,
                message: allSuccess
                  ? t('Devices.BulkEdit.Notifications.AgreementUpdateSuccess', {
                      successCount
                    })
                  : allFailed
                  ? t('Devices.BulkEdit.Notifications.AgreementUpdateFailed')
                  : t('Devices.BulkEdit.Notifications.PartialAgreementUpdateSuccess', {
                      successCount,
                      failedCount
                    })
              })
            );
            if (!allFailed) {
              dispatch(fetchBulkEditAudits({ entityName: BULK_EDIT_AUDIT_ENTITY.DEVICE }));
              setIsValid(false);
              canHistoryGoBack(history, PATHS.DEVICE_DEFAULT);
              dispatch(ngAgreementApi.util.invalidateTags(['deviceAgreement']));
            }
          }
        })
      );
    }
  };

  const workOrder = useMemo(() => {
    return (filteredAgreements || []).map(i => {
      const supportedAgreementIds = i.agreements.map(k => k.id);
      return {
        key: i.workOrder,
        label: `${t('Agreement.CopyAgreementModal.WorkOrder')}: ${i.workOrder} (${i.description})`,
        children: (
          <Row className={styles.agreementTableContent}>
            <Col>
              <Table
                tableLayout="fixed"
                rowKey={'id'}
                rowSelection={{
                  ...{
                    onChange: (selectedRowKeys, selectedRows) => {
                      setSelectedAgreements(prev => {
                        const agreements = [
                          ...prev.filter(k => !supportedAgreementIds.includes(k))
                        ];
                        return [...agreements, ...selectedRowKeys];
                      });
                    }
                  }
                }}
                columns={columns}
                pagination={false}
                loading={isFetchingAgreement}
                dataSource={i.agreements}
              />
            </Col>
          </Row>
        ),
        style: {
          marginTop: 10,
          marginBottom: 10,
          background: '#fff',
          borderRadius: 10
        }
      };
    });
  }, [agreementData, isFetchingAgreement, filteredAgreements]);

  return (
    <div className={styles.agreementAssignmentContainer}>
      <EditRouteGuard when={isValid} navigate={history.push} />
      <Row>
        <Col xs={24}>
          <FilterWrapper
            containerStyle={{ background: '#fff', paddingTop: 5, borderTop: '1px solid #dadee3' }}
          >
            <AntSearchbar onFilter={setFilterText} />
          </FilterWrapper>
        </Col>
        <Col xs={24}>
          <Collapse
            className={styles.agreementList}
            bordered={false}
            expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
            items={workOrder}
            expandIconPosition={'end'}
          />
        </Col>
      </Row>
      <div className={styles.formFooter}>
        <Button
          type="primary"
          size="large"
          disabled={!isValid}
          htmlType="submit"
          onClick={handleSubmit}
        >
          {t('Common.SaveButton')}
        </Button>
        <Button size="large" onClick={history.goBack}>
          {t('Common.CancelButton')}
        </Button>
      </div>
    </div>
  );
};
