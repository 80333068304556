import React, { useState, useEffect, useRef } from 'react';
import scrollStyles from '../../../../ELD/Overview/Overview.module.scss';
import { Table } from 'antd';
import {
  associationsCellRenderer,
  templateCache as cache,
  ellipsisButtonCellRenderer,
  nameCellRenderer,
  configCellRenderer
} from '../components/CellRenderers';
import { GlobalRoles, useCan } from 'features/permissions';
import { ColumnTypes } from 'components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/Filters';
import { useTranslation } from 'react-i18next';
import { useUser } from 'features/user/userSlice';
import { templateTypeEnum } from '../components/constants';
import { useLocalization } from 'features/localization/localizationSlice';
import services from 'features/permissions/services';

export const TemplatesTable = ({
  isFetching,
  templates,
  filterTab,
  handleDeleteAction,
  typeOfEntityToDelete,
  handleAction,
  templateType
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const currentUser = useUser();
  const isSiteAdmin = currentUser.siteAdmin;
  const { tableRef, vtSettings } = useTableResize();
  const can = useCan();
  const canControlSpeedAssistConfiguration = can({
    oneOfRoles: [GlobalRoles.Admin, GlobalRoles.Reseller],
    oneOfCompanyServices: [services.SPEEDASSIST]
  });

  const enableRestoreAction =
    filterTab === 'deleted' && (isSiteAdmin || canControlSpeedAssistConfiguration);
  const showDelete = filterTab === 'all' && (isSiteAdmin || canControlSpeedAssistConfiguration);
  const hideEdit = filterTab === 'deleted';
  const hideCopy = filterTab === 'deleted';

  const containerWidth = 2100;
  const percentageWidth = 50;
  const pixelWidth = (percentageWidth / 100) * containerWidth;
  const allColumns = [
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.Name'),
      key: 'name',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'name',
      width: filterTab !== 'deleted' ? 220 : pixelWidth,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return nameCellRenderer({ rowData, t, templateType });
      },
      defaultSortColumn: true,
      sorter: (a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      sortDirections: ['descend', 'ascend', 'descend']
    },
    ...(filterTab !== 'deleted'
      ? [
          {
            title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.CompanyTemplate'),
            key: 'default',
            type: ColumnTypes.String,
            dataIndex: 'default',
            width: 200,
            isSearchable: false,
            isFilterable: false,
            render: (value, rowData, rowIndex) => (
              <div>{rowData.default ? t('Common.Yes') : t('Common.No')}</div>
            )
          }
        ]
      : []),
    ...(filterTab !== 'deleted'
      ? [
          {
            title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.Associations'),
            key: 'associations',
            type: ColumnTypes.String,
            isConfigurable: false,
            dataIndex: 'associations',
            width: 200,
            isSearchable: false,
            isFilterable: false,
            render: (value, rowData, rowIndex) => {
              return associationsCellRenderer({ rowData, t });
            }
          }
        ]
      : []),
    ...(filterTab !== 'deleted'
      ? [
          {
            title: t(
              'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.Table.MaxSpeedThreshold'
            ),
            key: 'maxspeedthreshold',
            type: ColumnTypes.Number,
            isConfigurable: false,
            dataIndex: 'maxspeedthreshold',
            width: 200,
            isSearchable: false,
            isFilterable: false,
            render: (value, rowData, rowIndex) => {
              return configCellRenderer(
                'company.max.threshold',
                t,
                localization
              )({
                rowData
              });
            },

            defaultSortColumn: false,
            sorter: (a, b) => {
              const configA = a.configurations.find(
                config => config.key === 'company.max.threshold'
              );
              const configB = b.configurations.find(
                config => config.key === 'company.max.threshold'
              );

              const thresholdA = configA ? parseFloat(configA.value) : 0;
              const thresholdB = configB ? parseFloat(configB.value) : 0;

              return thresholdA - thresholdB;
            },
            sortDirections: ['ascend', 'descend', 'ascend']
          }
        ]
      : []),
    ...(filterTab !== 'deleted'
      ? [
          {
            title: t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.Table.MaxOffset'),
            key: 'maxoffset',
            type: ColumnTypes.Number,
            isConfigurable: false,
            dataIndex: 'maxoffset',
            width: 130,
            isSearchable: false,
            isFilterable: false,
            render: (value, rowData, rowIndex) => {
              return configCellRenderer(
                'company.max.offset',
                t,
                localization
              )({
                rowData
              });
            },
            defaultSortColumn: false,
            sorter: (a, b) => {
              const configA = a.configurations.find(config => config.key === 'company.max.offset');
              const configB = b.configurations.find(config => config.key === 'company.max.offset');

              const thresholdA = configA ? parseFloat(configA.value) : 0;
              const thresholdB = configB ? parseFloat(configB.value) : 0;

              return thresholdA - thresholdB;
            },
            sortDirections: ['ascend', 'descend', 'ascend']
          }
        ]
      : []),
    ...(filterTab !== 'deleted'
      ? [
          {
            title: t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.Table.MaxDuration'),
            key: 'maxduration',
            type: ColumnTypes.Number,
            isConfigurable: false,
            dataIndex: 'maxduration',
            width: 140,
            render: (value, rowData, rowIndex) => {
              return configCellRenderer(
                'company.max.duration',
                t,
                localization
              )({
                rowData
              });
            },
            defaultSortColumn: false,
            sorter: (a, b) => {
              const configA = a.configurations.find(
                config => config.key === 'company.max.duration'
              );
              const configB = b.configurations.find(
                config => config.key === 'company.max.duration'
              );

              const thresholdA = configA ? parseFloat(configA.value) : 0;
              const thresholdB = configB ? parseFloat(configB.value) : 0;

              return thresholdA - thresholdB;
            },
            sortDirections: ['ascend', 'descend', 'ascend']
          }
        ]
      : []),
    ...(filterTab !== 'deleted'
      ? [
          {
            title: t(
              'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.Table.CompanyManaged'
            ),
            key: 'companymanaged',
            type: ColumnTypes.String,
            isConfigurable: false,
            dataIndex: 'companymanaged',
            width: 180,
            render: (value, rowData, rowIndex) => {
              return configCellRenderer(
                'company.managed',
                t,
                localization
              )({
                rowData
              });
            },
            defaultSortColumn: false,
            sorter: (a, b) => {
              const configA = a.configurations.find(config => config.key === 'company.managed');
              const configB = b.configurations.find(config => config.key === 'company.managed');

              const valueA = configA ? configA.value === 'true' : false;
              const valueB = configB ? configB.value === 'true' : false;

              return valueA === valueB ? 0 : valueA ? -1 : 1;
            },
            sortDirections: ['ascend', 'descend', 'ascend']
          }
        ]
      : []),
    ...(filterTab !== 'deleted'
      ? [
          {
            title: t(
              'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.Table.SignPostedDisable'
            ),
            key: 'signposteddisable',
            type: ColumnTypes.Number,
            isConfigurable: false,
            dataIndex: 'signposteddisable',
            width: 180,
            render: (value, rowData, rowIndex) => {
              return configCellRenderer(
                'signposted.disable',
                t,
                localization
              )({
                rowData
              });
            },
            defaultSortColumn: false,
            sorter: (a, b) => {
              const configA = a.configurations.find(config => config.key === 'signposted.disable');
              const configB = b.configurations.find(config => config.key === 'signposted.disable');

              const valueA = configA ? configA.value === 'true' : false;
              const valueB = configB ? configB.value === 'true' : false;

              return valueA === valueB ? 0 : valueA ? -1 : 1;
            },
            sortDirections: ['ascend', 'descend', 'ascend']
          }
        ]
      : []),
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.ActionsLabel'),
      key: 'action',
      isConfigurable: false,
      dataIndex: 'action',
      fixed: 'right',
      width: 100,
      render: (value, rowData, rowIndex) =>
        ellipsisButtonCellRenderer({
          rowData,
          handleDeleteAction,
          typeOfEntityToDelete,
          hideEdit,
          showDelete,
          hideCopy,
          enableRestoreAction,
          handleAction,
          templateType: templateTypeEnum.SpeedAssist
        })
    }
  ];
  return (
    <Table
      loading={isFetching}
      dataSource={templates}
      deferredMeasurementCache={cache}
      className={`${scrollStyles.overviewGrid}`}
      columns={allColumns}
      ref={tableRef}
      pagination={false}
      showSorterTooltip={false}
      scroll={vtSettings.scroll}
      components={vtSettings.vt}
    />
  );
};

export const useTableResize = () => {
  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);
  const tableRef = useRef();

  const [vtSettings, setVTSettings] = useState({
    scroll: {
      y: 600,
      x: 1200
    }
  });

  const handleResize = entries => {
    if (entries[0]) {
      const { width, height } = entries[0].contentRect;

      const newTableWidth = width > 10 ? width - 10 : width;
      setTableWidth(newTableWidth);

      const header = tableRef.current.querySelector('.ant-table-thead');
      const headerHeight = header?.getBoundingClientRect()?.height || 0;
      const newTableHeight = height - headerHeight - 16;
      setTableHeight(newTableHeight);

      const newVTSettings = {
        scroll: {
          y: newTableHeight,
          x: newTableWidth
        }
      };

      setVTSettings(newVTSettings);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    const tableElement = document.querySelector(`.${scrollStyles.overviewGrid}`);
    resizeObserver.observe(tableElement);
    return () => {
      resizeObserver.unobserve(tableElement);
    };
  }, []);

  return { tableRef, vtSettings };
};
