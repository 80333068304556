import React, { useState, useEffect, useCallback } from 'react';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import { useDispatch } from 'react-redux';
import { useUser } from 'features/user/userSlice';
import { services } from 'features/permissions';
import { PATHS } from './constants';
import {
  useGetAssetConfigurationTemplateByIdQuery,
  useRestoreAssetConfigurationTemplateByIdMutation,
  useDeleteAssetConfigurationTemplateByIdMutation,
  executeRestoreAssetConfigurationTemplate,
  executeDeleteAssetConfigurationTemplate
} from 'services/nextgen/ngAssetConfigurationApi';
import { setBackButton, setPageTitle, addBackButtonLink } from 'features/page/pageSlice';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { DeletedEntityAlert, DeletableEntityType } from 'features/common/deletedEntityAlert';
import InfoRow from 'components/form/info-row/InfoRow';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';
import {
  useCompanies,
  useRedirectToMainFeaturePageOnCompanyChange
} from 'features/company/companySlice';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { Table } from 'antd';
import styles from '../GPIO/GPIO.module.scss';

export const AssetView = () => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const currentUser = useUser();
  const companies = useCompanies();
  const isSiteAdmin = currentUser.siteAdmin;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );

  const [
    restoreAssetConfigurationTemplateById
  ] = useRestoreAssetConfigurationTemplateByIdMutation();
  const [deleteAssetConfigurationTemplateById] = useDeleteAssetConfigurationTemplateByIdMutation();
  const { data: assetTemplate, isFetching } = useGetAssetConfigurationTemplateByIdQuery(
    { templateId: id, embed: 'devices' },
    { skip: id === undefined }
  );

  const handleFetchError = useCallback(() => {
    history.replace(PATHS.ASSETS_DEFAULT);
  }, [history]);

  useEffect(() => {
    dispatch(
      setPageTitle(
        assetTemplate?.configurationTemplate?.name &&
          `${assetTemplate?.configurationTemplate?.name}`
      )
    );

    dispatch(setBackButton(true));
    addBackButtonLink({
      url: pathname,
      backLink: PATHS.ASSETS_DEFAULT
    });
  }, [dispatch, assetTemplate]);

  const renderDevices = () => {
    if (assetTemplate?.associatedDevices?.length !== 0) {
      return assetTemplate?.associatedDevices.map(device => {
        return (
          <div key={`device-${device.id}`}>
            <Link to={`/settings/devices/id/${device.id}`}>{device.id}</Link>
          </div>
        );
      });
    } else {
      return '-';
    }
  };

  const renderConfigurations = () => {
    if (assetTemplate?.configurationTemplate?.configurations?.length !== 0) {
      let assetselection = assetTemplate?.configurationTemplate?.configurations?.find(
        item => item.key === 'asset.selection'
      )?.value;

      if (assetselection === 'movement') {
        let inuse =
          assetTemplate?.configurationTemplate?.configurations?.find(
            item => item.key === 'movement.inuse'
          )?.value === 'true'
            ? t('Common.Yes')
            : t('Common.No');

        let da = [
          {
            key: '1',
            assetselection: assetselection,
            inuse: inuse
          }
        ];

        return (
          <Table dataSource={da} pagination={false}>
            <Table.Column
              title={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.AssetsUsage')}
              dataIndex="assetselection"
              key="assetselection"
            />
            <Table.Column
              title={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.MovementInUse')}
              dataIndex="inuse"
              key="inuse"
            />
          </Table>
        );
      } else if (assetselection === 'GPIO') {
        let gpioChannel = assetTemplate?.configurationTemplate?.configurations?.find(
          item => item.key === 'gpio.channel'
        )?.value;

        let gpioStatus = assetTemplate?.configurationTemplate?.configurations?.find(
          item => item.key === 'gpio.status'
        )?.value;

        let da = [
          {
            key: '1',
            assetselection: assetselection,
            gpioChannel: gpioChannel,
            gpioStatus: gpioStatus
          }
        ];

        return (
          <Table dataSource={da} pagination={false}>
            <Table.Column
              title={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.AssetsUsage')}
              dataIndex="assetselection"
              key="assetselection"
            />
            <Table.Column
              title={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.GPIOChannel')}
              dataIndex="gpioChannel"
              key="gpioChannel"
            />
            <Table.Column
              title={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.GPIOInUse')}
              dataIndex="gpioStatus"
              key="gpioStatus"
            />
          </Table>
        );
      } else if (assetselection === 'RPM') {
        let rpmValue = assetTemplate?.configurationTemplate?.configurations?.find(
          item => item.key === 'rpm.over'
        )?.value;
        let rpmSeconds = assetTemplate?.configurationTemplate?.configurations?.find(
          item => item.key === 'rpm.min'
        )?.value;

        let da = [
          {
            key: '1',
            assetselection: assetselection,
            rpmValue: rpmValue,
            rpmSeconds: rpmSeconds
          }
        ];

        return (
          <Table dataSource={da} pagination={false}>
            <Table.Column
              title={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.AssetsUsage')}
              dataIndex="assetselection"
              key="assetselection"
            />
            <Table.Column
              title={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.RPMinUse')}
              dataIndex="rpmValue"
              key="rpmValue"
            />
            <Table.Column
              title={
                t('CompanyConfig.DeviceConfigurations.AssetsTemplates.RPMnotUse') +
                rpmValue +
                t('CompanyConfig.DeviceConfigurations.AssetsTemplates.RPMnotUse2')
              }
              dataIndex="rpmSeconds"
              key="rpmSeconds"
            >
              seconds
            </Table.Column>
          </Table>
        );
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  };

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        confirmationModal(
          `${t('Common.DeleteButton')} ${assetTemplate?.configurationTemplate?.name}`,
          `${
            assetTemplate?.configurationTemplate?.default
              ? t('CompanyConfig.DeviceConfigurations.DeleteCompanyTemplateWarning', {
                  templateName: 'Assets'
                })
              : t('Common.SureDelete') + ' ' + assetTemplate?.configurationTemplate?.name + '?'
          }`,
          t('Common.DeleteButton'),
          t('Common.CancelButton'),
          () => {
            executeDeleteAssetConfigurationTemplate(
              { id: id, name: assetTemplate?.configurationTemplate?.name },
              deleteAssetConfigurationTemplateById,
              dispatch
            );
          },
          'delete'
        );
        break;
      case 'restore':
        executeRestoreAssetConfigurationTemplate(
          { id: id, name: assetTemplate?.configurationTemplate?.name },
          restoreAssetConfigurationTemplateById,
          dispatch
        );
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      <ViewHeaderWrapper
        data={{
          entityName: AUDIT_ENTITY.CONFIGURATION_TEMPLATE,
          ...assetTemplate?.configurationTemplate
        }}
        editPath={`/settings/company_config/assetsusage/edit/id/${id}`}
        auditPath={`/settings/company_config/assetsusage/audit/id/${id}`}
        handleButtonAction={handleButtonAction}
        skipDeleteConfirmation={true}
        disableDelete={!isSiteAdmin}
        disableRestore={!isSiteAdmin}
        canService={[services.ASSETSUSAGE]}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '32px',
          color: '#181c21'
        }}
        className={styles.gpioView}
      >
        <DeletedEntityAlert
          entity={DeletableEntityType.ConfigurationTemplate}
          entityStatus={assetTemplate?.status}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.Company')}
          value={
            companies.find(comp => comp.id === assetTemplate?.configurationTemplate?.company?.id)
              ?.name
          }
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.Template')}
          value={assetTemplate?.configurationTemplate?.name}
          sxValue={{ width: '75%' }}
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.Description')}
          value={assetTemplate?.configurationTemplate?.description}
          sxValue={{ width: '75%', whiteSpace: 'pre-wrap' }}
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.IsDefaultTemplate')}
          sxValue={{ width: '75%' }}
          value={assetTemplate?.configurationTemplate?.default ? t('Common.Yes') : t('Common.No')}
          styles={styles}
        />
        <InfoRow
          label={t('Common.Configurations')}
          value={renderConfigurations()}
          styles={styles}
        />
        <InfoRow
          label={t('Devices.Devices')}
          value={renderDevices()}
          styles={styles}
          sxValue={{ width: '75%' }}
        />
        <InfoRow
          sxValue={{ width: '75%' }}
          label={t('CompanyConfig.DeviceConfigurations.View.CreatedAt')}
          value={
            assetTemplate?.configurationTemplate?.createdAt
              ? format(
                  new Date(assetTemplate?.configurationTemplate?.createdAt),
                  localization.formats.time.formats.dby_imp
                )
              : ''
          }
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.UpdatedAt')}
          sxValue={{ width: '75%' }}
          value={
            assetTemplate?.configurationTemplate?.updatedAt
              ? format(
                  new Date(assetTemplate?.configurationTemplate?.updatedAt),
                  localization.formats.time.formats.dby_imp
                )
              : ''
          }
          styles={styles}
        />
      </div>
    </React.Fragment>
  );
};
