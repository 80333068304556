import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Space, Button, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  fetchCompanyDataConsumption,
  fetchCompanyDataConsumptionSummary
} from 'features/camera/cameraApi';
import { useCurrentCompanyId } from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';

import { VisualizationCard } from './VisualizationCard';
import {
  ResponsivePieChart,
  TooltipContent
} from 'components/ant/Charts/responsive-pie-chart/ResponsivePieChart';
import { HintedSearchInput } from './HintedSearchInput';
import { COLS, DeviceList } from './DeviceList';

import styles from './NetworkUsage.module.scss';

const columns = [COLS.DeviceName, COLS.TotalMobileConsumption];
const START_TIME = moment()
    .subtract(30, 'days')
    .startOf('day')
    .format(),
  PAGE_SIZE = 5;

const DataConsumptionRate = {
  dataLessThanGB: {
    name: '<1GB',
    dataKey: 'dataLessThanGB',
    color: '#85c784'
  },
  dataBetweenOneAndTwoGB: {
    name: '1GB - 2GB',
    dataKey: 'dataBetweenOneAndTwoGB',
    color: '#ffeb31'
  },
  dataAboveTwoGB: {
    name: '>2GB',
    dataKey: 'dataAboveTwoGB',
    color: '#fe8785'
  }
};

export function NetworkUsage({ startTime = START_TIME }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompanyId = useCurrentCompanyId();

  const [isLoading, setIsLoading] = useState(true);
  const reqCtxRef = useRef({
    request: null
  });
  const [mobileConsumptionSummary, setMobileConsumptionSummary] = useState();
  const [selectedDataConsumptionRate, setSelectedDataConsumptionRate] = useState();

  useEffect(() => {
    if (!currentCompanyId) {
      return;
    }
    const ctxParams = {
      companyId: currentCompanyId,
      startTime
    };

    const onReqDone = error => {
      if (reqCtxRef.current.request) {
        reqCtxRef.current.request.abort();
      }
      reqCtxRef.current.request = null;
      reqCtxRef.current.fetchTime = moment().valueOf();
      reqCtxRef.current.error = error || null;
      reqCtxRef.current.ctxParams = ctxParams;
      setIsLoading(false);
    };

    let reqCtx = reqCtxRef.current;
    if (!reqCtx?.fetchTime) {
      if (reqCtx.request) {
        reqCtx.request.abort();
        reqCtx.request = null;
      }
      reqCtx.ctxParams = ctxParams;
      reqCtx.request = dispatch(
        fetchCompanyDataConsumptionSummary({
          ...ctxParams,
          onSuccess: summary => {
            onReqDone();
            setMobileConsumptionSummary(
              Object.keys(DataConsumptionRate).reduce(
                (obj, rateKey) => ({
                  ...obj,
                  [rateKey]: isNaN(Number(summary?.mobileDataConsumptionAggregate?.[rateKey] || 0))
                    ? 0
                    : Number(summary?.mobileDataConsumptionAggregate?.[rateKey] || 0)
                }),
                {
                  totalMobileDataConsumption: isNaN(
                    Number(summary?.mobileDataConsumptionAggregate?.totalMobileDataConsumption)
                  )
                    ? 0
                    : Number(summary?.mobileDataConsumptionAggregate?.totalMobileDataConsumption)
                }
              )
            );
          },
          onError: errMsg => {
            onReqDone(errMsg);
            setMobileConsumptionSummary({});
          }
        })
      );
      const fetchData = async () => {
        try {
          setIsLoading(true);
          await reqCtx.request;
        } catch (e) {
          onReqDone(e);
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t('CameraHealth.FetchError', {
                error: parseErrorMessage(e),
                title: t('CameraHealth.Network Usage')
              })
            })
          );
        }
      };
      fetchData();
    }
  }, [dispatch, reqCtxRef, currentCompanyId, startTime]);

  return (
    <Row gutter={16} align={'stretch'}>
      <Col span={12}>
        <DataConsumptionPieChart
          loading={isLoading}
          consumption={mobileConsumptionSummary}
          selectedDataConsumptionRate={selectedDataConsumptionRate}
          onDataConsumptionRateClick={setSelectedDataConsumptionRate}
        />
      </Col>
      <Col span={12}>
        <DataConsumptionDevicesList
          loading={isLoading}
          startTime={startTime}
          dataConsumptionRate={selectedDataConsumptionRate}
        />
      </Col>
    </Row>
  );
}

const DataConsumptionPieChart = ({
  loading,
  consumption = {
    dataAboveTwoGB: 0,
    dataBetweenOneAndTwoGB: 0,
    dataLessThanGB: 0,
    totalMobileDataConsumption: 0
  },
  selectedDataConsumptionRate,
  onDataConsumptionRateClick
}) => {
  const { t } = useTranslation();
  const { data, totalDeviceCount } = useMemo(() => {
    let totalDeviceCount = 0;
    const data = Object.values(DataConsumptionRate).map(rate => {
      const count = isNaN(Number(consumption?.[rate.dataKey] || 0))
        ? 0
        : Number(consumption?.[rate.dataKey] || 0);
      totalDeviceCount += count;
      return {
        ...rate,
        value: count
      };
    });
    return {
      data,
      totalDeviceCount
    };
  }, [consumption]);

  return (
    <VisualizationCard
      loading={loading}
      className={styles.inlineCard}
      title={`${t('CameraHealth.Data Consumption')} - ${t('CameraHealth.Mobile Network')}`}
      subTitle={totalDeviceCount ? t('CameraHealth.Select chart to filter') : null}
      titleExtra={
        <Space direction="vertical">
          {t('Messaging.LastDays', { count: 30 })}
          {totalDeviceCount ? (
            <Button onClick={() => onDataConsumptionRateClick(null)}>{t('Common.Clear')}</Button>
          ) : null}
        </Space>
      }
      content={
        !totalDeviceCount ? (
          <Alert message={t('CameraHealth.NoMobileChartData')} type="info" showIcon />
        ) : (
          <ResponsivePieChart
            height={340}
            data={data}
            activePieIndex={data.findIndex(rate => rate.dataKey === selectedDataConsumptionRate)}
            onPieClick={entry => onDataConsumptionRateClick(entry.payload.dataKey)}
            legendContent={OriginalLegend => (
              <Space align="start" className={styles.chartLegend}>
                <Space direction="vertical" align="start" size={2} className={styles.chartSummary}>
                  {`${t(
                    'CameraHealth.Total Data Consumed'
                  )} (GB): ${consumption?.totalMobileDataConsumption || 0}`}
                  {`${t('CameraHealth.TotalNoOfDevices')}: ${totalDeviceCount}`}
                </Space>
                {OriginalLegend}
              </Space>
            )}
            tooltipContent={activeEntry => {
              if (activeEntry) {
                const { name, value, payload } = activeEntry;
                return (
                  <TooltipContent
                    label={name}
                    value={`${value} / ${totalDeviceCount}`}
                    valueColor={payload?.fill}
                  />
                );
              }
              return null;
            }}
            activePieLabel={activeEntry => {
              const count = isNaN(Number(activeEntry?.value)) ? 0 : Number(activeEntry?.value);
              return `${count} ${count > 1 ? t('Devices.Devices') : t('Devices.Device')}`;
            }}
          />
        )
      }
    />
  );
};

const DataConsumptionDevicesList = ({
  loading,
  startTime,
  pageSize = PAGE_SIZE,
  dataConsumptionRate
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentCompanyId = useCurrentCompanyId();

  const reqCtxRef = useRef({
    request: null
  });

  const [devices, setDevices] = useState(null);
  const [isListLoading, setIsListLoading] = useState(true);
  const [tableParams, setTableParams] = useState({
    limit: pageSize,
    offset: 0,
    searchText: '',
    sortBy: 'totalMobileConsumption',
    sort: 'desc',
    pagination: {
      current: 1,
      pageSize
    }
  });

  const fetchDevices = useCallback(() => {
    if (!currentCompanyId) {
      return;
    }
    const ctxParams = {
      companyId: currentCompanyId,
      startTime,
      dataConsumptionRate: dataConsumptionRate || '',
      limit: tableParams.pagination.pageSize,
      offset: (tableParams.pagination.current - 1) * tableParams.pagination.pageSize,
      search: tableParams.searchText,
      sortBy: tableParams.sortBy,
      sort: tableParams.sort
    };

    const onReqDone = error => {
      if (reqCtxRef.current.request) {
        reqCtxRef.current.request.abort();
      }
      reqCtxRef.current.request = null;
      reqCtxRef.current.fetchTime = moment().valueOf();
      reqCtxRef.current.error = error || null;
      reqCtxRef.current.ctxParams = ctxParams;
      setIsListLoading(false);
    };

    let reqCtx = reqCtxRef.current;
    if (reqCtx.request) {
      reqCtx.request.abort();
      reqCtx.request = null;
    }
    reqCtx.ctxParams = ctxParams;
    reqCtx.request = dispatch(
      fetchCompanyDataConsumption({
        ...ctxParams,
        onSuccess: summary => {
          onReqDone();
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: summary?.totalRecords || 0
            }
          });
          setDevices(
            (summary?.devices || []).map(d => ({
              deviceId: d.deviceId,
              deviceName: d.deviceName,
              totalMobileConsumption: d.totalMobileConsumption
            }))
          );
        },
        onError: errMsg => {
          onReqDone(errMsg);
          setDevices([]);
          setTableParams({
            limit: pageSize,
            offset: 0,
            totalRecords: 0,
            searchText: '',
            sortBy: 'totalMobileConsumption',
            sort: 'desc',
            pagination: {
              current: 1,
              pageSize
            }
          });
        }
      })
    );
    const fetchData = async () => {
      try {
        setIsListLoading(true);
        await reqCtx.request;
      } catch (e) {
        onReqDone(e);
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('CameraHealth.FetchError', {
              error: parseErrorMessage(e),
              title: t('CameraHealth.Network Usage')
            })
          })
        );
      }
    };
    fetchData();
  }, [
    t,
    dispatch,
    reqCtxRef,
    currentCompanyId,
    tableParams,
    pageSize,
    startTime,
    dataConsumptionRate
  ]);

  const filterOptions = useMemo(
    () => [
      {
        key: 'searchFilter',
        id: 'networkUsageSearch',
        component: (
          <HintedSearchInput
            hint={t('CameraHealth.SearchHint')}
            placeholder={t('Devices.ActualForm.NamePlaceholder')}
            onSearch={text =>
              setTableParams(params => ({
                ...params,
                pagination: {
                  ...params?.pagination,
                  current: 1,
                  pageSize
                },
                searchText: text || ''
              }))
            }
          />
        )
      }
    ],
    [t, isListLoading, pageSize]
  );

  useEffect(() => {
    fetchDevices();
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams.sort,
    tableParams.sortBy,
    tableParams.searchText,
    dataConsumptionRate
  ]);

  const handleListChange = (pagination, filter, sorter) =>
    setTableParams(prev => ({
      ...prev,
      pagination,
      sort: sorter.order === 'ascend' ? 'asc' : 'desc',
      sortBy: sorter.field
    }));

  return (
    <VisualizationCard
      loading={loading}
      className={styles.inlineCard}
      title={`${t('CameraHealth.Data Consumption')} - ${t('CameraHealth.Mobile Network')} ${
        DataConsumptionRate[dataConsumptionRate]?.name
          ? '(' + DataConsumptionRate[dataConsumptionRate].name + ')'
          : ''
      }`}
      content={
        <DeviceList
          filterOptions={filterOptions}
          isListLoading={isListLoading}
          devices={devices}
          columns={columns}
          pagination={tableParams.pagination}
          onChange={handleListChange}
        />
      }
    />
  );
};
