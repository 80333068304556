import React from 'react';
import { Can, entities } from 'features/permissions';
import '../Easydocs/ListItems.scss';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const ListItems = ({
  listValues,
  onClickItem,
  header,
  direction,
  addItemIcon,
  onClickAddItem,
  activeItem
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: direction || 'column',
        margin: '0 3px 0 0'
      }}
      className="list"
    >
      {header && (
        <div className="list__header list__item">
          <strong>{header}</strong>
          <Can everyEntity={[entities.FOLDER_CREATE]}>
            <img src={addItemIcon} alt="Add Item Icon" onClick={() => onClickAddItem()} />
          </Can>
        </div>
      )}
      {listValues.map(item => (
        <div
          key={item.id}
          className={`list__item ${activeItem === item.id && 'active'}`}
          onClick={() => onClickItem(item)}
        >
          {item.name}
        </div>
      ))}
      {/* Deleted tab */}
      <div
        className={`list__item ${activeItem === 'deleted' ? 'active' : ''}`}
        onClick={() => onClickItem({ id: 'deleted', name: 'Deleted' })}
      >
        {i18next.t('Easydocs.Deleted')}
      </div>
    </div>
  );
};

export default ListItems;
