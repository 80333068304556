import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer, Column, Table, CellMeasurer } from 'react-virtualized';
import { Checkbox, Layout, Spin, Typography } from 'antd';
import { useCompanies } from 'features/company/companySlice';
import { useCan, entities, GlobalRoles, FeatureFlag } from 'features/permissions';
import { useDeviceTypes } from 'features/device_types/deviceTypesSlice';
import { useDeviceModels } from 'features/device_models/deviceModelsSlice';
import { useBulkError, useIsBulkDeleting, useIsBulkUpdating } from 'features/devices/devicesSlice';
import { bulkEditCache, getSelectedRows } from './helper';
import { LoadingTable } from 'components/grid/LoadingTable';
import { BulkEditHeader } from './BulkEditHeader';
import { DeviceActionsCell } from './Cell/DeviceActionsCell';
import { DeviceFleetsCell } from './Cell/DeviceFleetsCell';
import { DeviceVehicleCell } from './Cell/DeviceVehicleCell';
import { DeviceNameCell } from './Cell/DeviceNameCell';
import { BulkEditRouteGuard } from './BulkEditRouteGuard';
import { PATHS, deviceTypeModelTranslate } from '../constants';
import services from 'features/permissions/services';

import styles from './DevicesBulkEdit.module.scss';

export const DevicesBulkEditTable = ({
  isLoading,
  tableData,
  onTableRowChecked,
  touchedRowsMap,
  onBulkSave = () => {},
  onBulkDelete = () => {},
  onIndividualDelete = () => {},
  onBulkAssignment = () => {},
  isBulkAssigningAgreement
}) => {
  const can = useCan();

  const { t } = useTranslation();
  const [tableRef, setTableRef] = useState(null);
  const canControlGpioConfiguration = can({
    oneOfRoles: [GlobalRoles.Reseller, GlobalRoles.SiteAdmin],
    oneOfCompanyServices: [services.GPIO]
  });

  const companies = useCompanies();
  const getCompanyName = useCallback(
    companyId => companies?.find(comp => String(comp.id) === String(companyId))?.name || '',
    [companies]
  );

  const deviceTypes = useDeviceTypes();
  const deviceModels = useDeviceModels();
  const getTypeName = useCallback(
    deviceTypeId =>
      deviceTypeModelTranslate(
        deviceTypes?.list?.find(type => String(type.id) === String(deviceTypeId))?.name || ''
      ),
    [deviceTypes?.list]
  );
  const getModelName = useCallback(
    (deviceModelId, deviceModelName) => {
      const modelName =
        deviceModels?.list?.find(type => String(type.id) === String(deviceModelId))?.name ||
        deviceModelName ||
        '';
      return deviceTypeModelTranslate(
        modelName === 'MultiIQ' &&
          !can({ oneOfFeatureFlags: [FeatureFlag.evoPhase1.flag, FeatureFlag.evoPhaseTGE.flag] })
          ? 'IQCamera'
          : modelName
      );
    },
    [deviceModels?.list]
  );

  const isEditing = useIsBulkUpdating();
  const isDeleting = useIsBulkDeleting();
  const isProcessing = useMemo(() => isEditing || isDeleting, [isEditing, isDeleting]);

  const bulkErrors = useBulkError();
  const getErrMsg = useCallback(deviceId => bulkErrors?.[deviceId]?.message, [bulkErrors]);

  useEffect(() => {
    if (tableRef) {
      bulkEditCache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [tableData, tableRef]);

  const {
    fixedColumnsWidth,
    getDynamicColWidth,
    showActions,
    selectedRows,
    rowGetter
  } = useMemo(() => {
    const selectedRows = getSelectedRows(touchedRowsMap);
    const showActions =
      !selectedRows.length &&
      can({ oneOfEntities: [entities.DEVICE_UPDATE, entities.DEVICE_DESTROY] });
    const fixedColumnsWidth = {
      select: 20,
      name: 150,
      imei: 150,
      company: 100,
      sn: 120,
      type: 70,
      model: 100,
      vehicle: 120,
      fleets: 120,
      phone: 100,
      gpioTemplate: 100,
      note: 100,
      actions: showActions ? 65 : 0
    };
    return {
      fixedColumnsWidth,
      showActions,
      getDynamicColWidth(width) {
        const dynamicWidth = width - Object.values(fixedColumnsWidth).reduce((a, b) => a + b, 0);
        return isNaN(dynamicWidth) || dynamicWidth < 0 ? fixedColumnsWidth.note : dynamicWidth;
      },
      selectedRows,
      rowGetter: ({ index }) => touchedRowsMap?.[tableData[index].id] || tableData[index]
    };
  }, [tableData, touchedRowsMap]);

  if (isLoading) {
    return <LoadingTable columnSizes={[10, 60, 60, 60, 50, 40, 40, 50, 30]} />;
  }
  return (
    <>
      <div id="deviceBulkEditTable" className={styles.bulkEditTable}>
        <Layout.Header className={styles.topBar}>
          <BulkEditHeader
            selectedRows={selectedRows}
            onBulkSave={onBulkSave}
            onBulkDelete={onBulkDelete}
            onBulkAssignment={onBulkAssignment}
            isBulkAssigningAgreement={isBulkAssigningAgreement}
          />
        </Layout.Header>
        <Layout.Content className={styles.tableContent}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <Spin spinning={isProcessing} wrapperClassName={styles.tableLoader}>
                  <Table
                    deferredMeasurementCache={bulkEditCache}
                    width={width}
                    height={height}
                    headerHeight={48}
                    headerClassName={styles.tableHeader}
                    gridClassName="showScrollbarsOnHover"
                    rowClassName={({ index }) =>
                      tableData?.[index]?.checked ? styles.tableRowSelected : styles.tableRow
                    }
                    rowHeight={bulkEditCache.rowHeight}
                    rowCount={tableData.length}
                    rowGetter={rowGetter}
                    rowStyle={{ alignItems: 'flex-start', paddingTop: 0, paddingBottom: 0 }}
                    ref={ref => setTableRef(ref)}
                  >
                    <Column
                      width={fixedColumnsWidth.select}
                      dataKey="checked"
                      cellRenderer={({ cellData, rowData }) => (
                        <Checkbox checked={cellData} onChange={onTableRowChecked(rowData.id)} />
                      )}
                      className={styles.tableCol}
                      headerRenderer={({ dataKey, label, sortBy, sortDirection }) => (
                        <Checkbox
                          checked={selectedRows?.length && selectedRows.length === tableData.length}
                          indeterminate={
                            selectedRows?.length && selectedRows.length !== tableData.length
                          }
                          onChange={onTableRowChecked()}
                        />
                      )}
                    />
                    <Column
                      label={t('Devices.Table.NameLabel')}
                      dataKey="name"
                      width={fixedColumnsWidth.name}
                      className={styles.unEditableCol}
                      cellRenderer={({ cellData, rowData }) => (
                        <DeviceNameCell
                          id={rowData.id}
                          name={cellData}
                          errMessage={getErrMsg(rowData.id)}
                        />
                      )}
                    />
                    <Column
                      label={t('Devices.Table.ImeiLabel')}
                      dataKey="imei"
                      width={fixedColumnsWidth.imei}
                      className={styles.unEditableCol}
                    />
                    <Column
                      label={t('Devices.Company')}
                      dataKey="companyId"
                      width={fixedColumnsWidth.company}
                      className={styles.tableCol}
                      cellRenderer={({ cellData }) => getCompanyName(cellData)}
                    />
                    <Column
                      label={t('Devices.SN')}
                      dataKey="serialNumber"
                      width={fixedColumnsWidth.sn}
                      className={styles.unEditableCol}
                    />
                    <Column
                      label={t('Devices.Table.TypeLabel')}
                      dataKey="typeId"
                      width={fixedColumnsWidth.type}
                      className={styles.tableCol}
                      cellRenderer={({ cellData }) => getTypeName(cellData)}
                    />
                    <Column
                      label={t('Common.Model')}
                      dataKey="modelId"
                      width={fixedColumnsWidth.model}
                      className={styles.tableCol}
                      cellRenderer={({ cellData, rowData }) =>
                        getModelName(cellData, rowData.modelName)
                      }
                    />
                    <Column
                      label={t('Devices.Table.VehicleLabel')}
                      dataKey="vehicleId"
                      width={fixedColumnsWidth.vehicle}
                      className={styles.tableCol}
                      cellRenderer={props => (
                        <CellMeasurer
                          cache={bulkEditCache}
                          columnIndex={8}
                          key={`cell-measurer-${props.dataKey}-${props.rowData.id}`}
                          parent={props.parent}
                          rowIndex={props.rowIndex}
                        >
                          <DeviceVehicleCell
                            companyId={props.rowData.companyId}
                            vehicleId={props.cellData}
                          />
                        </CellMeasurer>
                      )}
                    />
                    <Column
                      label={t('Fleets.Fleets')}
                      dataKey="fleetIds"
                      width={fixedColumnsWidth.fleets}
                      className={styles.tableCol}
                      cellRenderer={props => (
                        <CellMeasurer
                          cache={bulkEditCache}
                          columnIndex={0}
                          key={`cell-measurer-${props.dataKey}-${props.rowData.id}`}
                          parent={props.parent}
                          rowIndex={props.rowIndex}
                        >
                          <DeviceFleetsCell
                            rowIndex={props.rowIndex}
                            tableRef={tableRef}
                            companyId={props.rowData.companyId}
                            fleetIds={props.cellData}
                            getCompanyName={getCompanyName}
                          />
                        </CellMeasurer>
                      )}
                    />
                    <Column
                      label={t('Vehicles.View.Phone')}
                      dataKey="phone"
                      width={fixedColumnsWidth.phone}
                      className={styles.tableCol}
                    />
                    {canControlGpioConfiguration && (
                      <Column
                        label={t('Devices.Table.GpioTemplate')}
                        dataKey="gpioTemplate"
                        width={fixedColumnsWidth.gpioTemplate}
                        className={styles.tableCol}
                      />
                    )}
                    <Column
                      label={t('Devices.ActualForm.NotesLabel')}
                      dataKey="note"
                      width={getDynamicColWidth(width)}
                      className={styles.tableCol}
                      cellRenderer={({ cellData }) => (
                        <Typography.Paragraph
                          ellipsis={{ rows: 3, expandable: false, tooltip: cellData }}
                          className={styles.note}
                        >
                          {cellData}
                        </Typography.Paragraph>
                      )}
                    />
                    {showActions && (
                      <Column
                        label={t('Devices.Table.ActionsLabel')}
                        dataKey=""
                        style={{ overflow: 'visible' }}
                        width={fixedColumnsWidth.actions}
                        className={styles.tableCol}
                        cellRenderer={props => (
                          <DeviceActionsCell device={props.rowData} onDelete={onIndividualDelete} />
                        )}
                      />
                    )}
                  </Table>
                </Spin>
              );
            }}
          </AutoSizer>
        </Layout.Content>
      </div>
    </>
  );
};
