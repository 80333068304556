import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';

export const TMADeclaration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle(t('Regulatory.TMADeclaration')));
    dispatch(setBackButton(true));
  }, [t, dispatch]);

  return <div />;
};
