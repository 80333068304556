/* global google */
import React, { useState, useEffect, useRef } from 'react';
import {
  useCompanies,
  useSubCompanies,
  useCurrentCompany,
  useIsLoadingCurrentCompany,
  useCompanyGeofenceProviders
} from 'features/company/companySlice';
import { useDispatch } from 'react-redux';
import { useLocations, useDeletedLocations } from 'features/locations/locationsSlice';
import {
  useGeofences,
  fetchGeofences,
  deleteGeofenceApi,
  useIsAsyncFetching,
  useIsFullyFetched,
  useIsFetching as isGeofencesFetching
} from 'features/geofences/geofencesSlice';
import { usePretripChecklists } from 'features/pretripChecklist/pretripChecklistSlice';
import { useDocuments } from 'features/easydocs/documentsSlice';
import { useFleets } from 'features/fleets/fleetsSlice';
import useDebounce from 'utils/hooks/useDebounce';
import { toLower, trim } from 'lodash';
import { TabNavLink } from 'components/nav/NavLinks';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { GeofencesTable } from './GeofencesTable';
import { cache } from './CellRenderers';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import {
  useCan,
  useCanEveryCompanyService,
  useCanOneOfCompanyServices
} from 'features/permissions';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { prepareDataForMultiselect } from 'utils/filters';
import { exportFile } from 'components/excelFile';
import {
  columnWidth,
  XLSX_FILE_TITLE,
  NO_OF_COLUMNS,
  GEOFENCE_TYPES,
  SPEED_LIMIT_OVERRIDE_INDEX
} from './constants';
import { useLocalization } from 'features/localization/localizationSlice';
import {
  helpers,
  prepareFileForExcelExport,
  prepareDataForCsvExport,
  prepareDataForGeojsonExport,
  downloadFile
} from './helpers';
import { getCSVFilename } from '../Locations/helpers';
import { getNonManagedGeofences } from 'features/geofences/geofencesUtil';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { Buttons } from 'components/ant';
import { CSVLink } from 'react-csv';
import { PATHS as COMPANYCONFIG_PATHS } from 'containers/Configuration/CompanyConfig/utils/constants';
import { BULK_TYPES } from 'containers/Administration/BulkManagement/components/Modal/constants';

import styles from './Geofences.module.scss';
import { filterGeofenceTypes, GeofencesTypes } from '../Fleets/constants';
import Map, { MapMode } from 'components/map/Map';
import services from 'features/permissions/services';
import { BUTTON_IDS } from 'utils/globalConstants';

const EMPTY_ARRAY = [];

export const Geofences = ({ fleetId }) => {
  const { t } = useTranslation();
  const can = useCan();
  const companies = useCompanies();
  const subCompanies = useSubCompanies();
  const company = useCurrentCompany();
  const geofences = useGeofences(true);
  const pretrips = usePretripChecklists();
  const documents = useDocuments();
  const fleets = useFleets();
  const locations = useLocations();
  const deletedLocations = useDeletedLocations();
  const [filterText, setFilterText] = useState('');
  const path = window.location.pathname;
  const filterPath = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const [filterTab, setFilterTab] = useState(filterPath);
  const debouncedSearchText = useDebounce(trim(filterText), 300);
  const [filterGeofences, setFilterGeofences] = useState(geofences);
  const [filterFleets, setFilterFleets] = useState([]);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const [tableRef, setTableRef] = useState(null);
  const dispatch = useDispatch();
  const [allLocations, setAllLocations] = useState([]);
  const history = useHistory();
  const localization = useLocalization();
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef(null);
  const isAsyncFetchingGeofence = useIsAsyncFetching();
  const isGeofenceFullyFetched = useIsFullyFetched();
  const isFetchingGeofence = isGeofencesFetching();
  const isCompanyFetching = useIsLoadingCurrentCompany();
  const [filteredGeofenceTypes, setFilteredGeofenceTypes] = useState([]);

  const [selectedGeofenceId, setSelectedGeofenceId] = useState(null);
  const [mapOptions] = useState({
    mapTypeControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT
    },
    center: {
      lat: localization.formats?.geocode?.lat,
      lng: localization.formats?.geocode?.lng
    },
    zoom: 4
  });
  const geofenceProviders = useCompanyGeofenceProviders();
  const canAccessManagedGeofence = geofenceProviders?.length > 0;
  const hasOnsiteTraffic = useCanEveryCompanyService([services.ONSITE_TRAFFIC]);
  const hasSmartJobHeadless = useCanEveryCompanyService([services.SMARTJOBS_HEADLESS]);
  const hasSpeedManagementOverride = useCanOneOfCompanyServices([services.GEOFENCE_SPEED_OVERRIDE]);

  useEffect(() => {
    setAllLocations(locations.concat(deletedLocations));
  }, [locations.length, deletedLocations.length]);

  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setPageTitle(t('GeofencesFeature.Geofences')));
  }, [dispatch, t]);

  useEffect(() => {
    if (
      !isCompanyFetching &&
      !isFetchingGeofence &&
      !isAsyncFetchingGeofence &&
      !isGeofenceFullyFetched
    ) {
      dispatch(fetchGeofences({ geofenceProviders }, false, true));
    }
  }, [company, isFetchingGeofence]);

  useEffect(() => {
    setFilterCompanies(prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), null));
  }, [subCompanies, t]);

  useEffect(() => {
    let geoTypes = filterGeofenceTypes({
      hasManagedAccess: canAccessManagedGeofence,
      hasOnsiteTraffic,
      hasSmartJobHeadless,
      hasSpeedManagementOverride
    });

    setFilteredGeofenceTypes(
      prepareDataForMultiselect(
        geoTypes.map(k => {
          return {
            id: k.toUpperCase(),
            name: GeofencesTypes[k]
          };
        }),
        t('Common.All Geofence Types'),
        null
      )
    );
  }, [canAccessManagedGeofence, hasOnsiteTraffic, hasSmartJobHeadless]);

  useEffect(() => {
    const geofencesCopy = [...geofences];
    const sortedGeofenceArray = geofencesCopy.sort((a, b) => {
      const x = a.name.toLowerCase();
      const y = b.name.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
    const checkedGeoTypes = (filteredGeofenceTypes || []).filter(g => g.checked).map(g => g.id);
    if (checkedGeoTypes.includes('MANAGED')) {
      checkedGeoTypes.push(...(geofenceProviders || []));
    }
    if (checkedGeoTypes.includes('SPEEDMANAGEMENT')) {
      checkedGeoTypes.push(GEOFENCE_TYPES[SPEED_LIMIT_OVERRIDE_INDEX].value);
    }

    setFilterGeofences(
      sortedGeofenceArray.filter(geofence => {
        let validGeofence = true;
        // Filter by user type
        if (filterTab === 'all') {
          validGeofence = true;
        }
        if (filterTab === 'withLocation') {
          validGeofence = validGeofence && geofence.location.id;
        }
        if (filterTab === 'withoutLocation') {
          validGeofence = validGeofence && !geofence.location.id;
        }
        if (filterTab === 'excludeScorecardEvents') {
          validGeofence = validGeofence && geofence.features?.length > 0;
        }

        // Filter by search field
        if (debouncedSearchText) {
          validGeofence =
            validGeofence && toLower(geofence.name).indexOf(toLower(debouncedSearchText)) > -1;
        }
        // Filter by companies
        const checkedCompaniesIds = (filterCompanies || [])
          .filter(company => company.checked)
          .map(company => parseInt(company.id, 10));
        if (!(checkedCompaniesIds.indexOf(0) > -1)) {
          validGeofence =
            validGeofence && checkedCompaniesIds.indexOf(parseInt(geofence.companyId, 10)) > -1;
        }

        // Filter by fleets
        const checkedFleetsIds = (filterFleets || [])
          .filter(fleet => fleet.checked)
          .map(fleet => parseInt(fleet.id, 10));
        if (!(checkedFleetsIds.indexOf(0) > -1)) {
          let isGeofenceInFleet = false;
          // check if vehicle belongs to any of the checked fleets
          geofence.fleets.forEach(fleet => {
            isGeofenceInFleet =
              isGeofenceInFleet || checkedFleetsIds.indexOf(parseInt(fleet.id, 10)) > -1;
          });
          if (!isGeofenceInFleet && checkedFleetsIds.indexOf(-1) > -1) {
            isGeofenceInFleet = !geofence.fleets.length;
          }
          validGeofence = validGeofence && isGeofenceInFleet;
        }

        //filter by geofence types
        if (validGeofence) {
          validGeofence = checkedGeoTypes.includes(geofence.type);
        }

        return validGeofence;
      })
    );
  }, [
    geofences,
    filterCompanies,
    filterFleets,
    filterTab,
    debouncedSearchText,
    companies,
    fleets,
    allLocations,
    filteredGeofenceTypes,
    geofenceProviders
  ]);

  useEffect(() => {
    const subCompaniesIdsFromFilter = (filterCompanies || [])
      .filter(comp => comp.checked)
      .map(comp => comp.id);
    const fleetsOptions = (fleets || []).filter(
      fleet => fleet.id && subCompaniesIdsFromFilter.includes(fleet.company?.id)
    );
    fleetsOptions.push({ id: -1, name: t('Common.NoFleet') });
    setFilterFleets(
      prepareDataForMultiselect(fleetsOptions, t('Common.AllFleets'), fleetId ? [fleetId] : null)
    );
  }, [fleets, filterCompanies, t]);

  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [filterGeofences, tableRef]);

  const handleDeleteAction = data => () => {
    dispatch(deleteGeofenceApi(data));
  };

  useEffect(() => {
    csvData?.length && csvLinkRef?.current?.link?.click();
  }, [csvData]);

  const handleBulkImport = () =>
    history.push({
      pathname: COMPANYCONFIG_PATHS.BULK_MANAGEMENT,
      state: { showImportModal: true, defaultImportType: BULK_TYPES.GEOFENCES }
    });

  const handleExportCSV = () => {
    setCsvData(
      prepareDataForCsvExport({
        data: getNonManagedGeofences(geofenceProviders, filterGeofences),
        locations: helpers.getFilteredLocations(locations),
        pretrips,
        documents,
        localization,
        t
      })
    );
  };

  const handleExportGeojson = e => {
    e.preventDefault();
    downloadFile({
      data: JSON.stringify(
        prepareDataForGeojsonExport({
          data: getNonManagedGeofences(geofenceProviders, filterGeofences),
          locations: helpers.getFilteredLocations(locations),
          pretrips,
          documents,
          localization,
          t
        })
      ),
      fileName: `${getCSVFilename({
        entityName: t('GeofencesFeature.CSV.Filename'),
        companyName: company?.name,
        extension: 'geojson'
      })}`
    });
  };

  const handleExportExcel = () => {
    // Can only view and edit excluded scorecard events with proper permissions
    const canViewScorecardItems = helpers.getCanViewScorecardItems(can);

    const data = prepareFileForExcelExport({
      filterGeofences,
      companies,
      locations,
      localization,
      dateFormat: localization.formats.time.formats.dby_imsp,
      canViewScorecardItems,
      t
    });

    const numColumns = canViewScorecardItems ? NO_OF_COLUMNS + 1 : NO_OF_COLUMNS;

    dispatch(
      exportFile(data, {
        width: columnWidth(numColumns),
        title: XLSX_FILE_TITLE,
        dateFormat: localization.formats.time.formats.dby_imsp
      })
    );
  };

  // Can only view and edit excluded scorecard events with proper permissions
  const canViewScorecardItems = helpers.getCanViewScorecardItems(can);

  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div>
            <TabNavLink
              to="/settings/geofences"
              isActive={(match, location) => {
                return location.pathname === '/settings/geofences';
              }}
              onClick={() => {
                setFilterTab('all');
              }}
            >
              {t('Common.All')}
            </TabNavLink>
            <TabNavLink
              exact
              to={`/settings/geofences/withLocation`}
              onClick={() => {
                setFilterTab('withLocation');
              }}
            >
              {t('GeofencesFeature.GridPage.WithLocation')}
            </TabNavLink>
            <TabNavLink
              exact
              to={`/settings/geofences/withoutLocation`}
              onClick={() => {
                setFilterTab('withoutLocation');
              }}
            >
              {t('GeofencesFeature.GridPage.WithoutLocation')}
            </TabNavLink>
            {canViewScorecardItems && (
              <TabNavLink
                exact
                to={`/settings/geofences/excludeScorecardEvents`}
                onClick={() => {
                  setFilterTab('excludeScorecardEvents');
                }}
              >
                {t('Scorecard.ExcludesScorecardEvents')}
              </TabNavLink>
            )}
            <TabNavLink
              exact
              to={`/settings/geofences/mapview`}
              onClick={() => {
                setFilterTab('mapview');
              }}
            >
              {t('GeofencesFeature.MapView')}
            </TabNavLink>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Buttons
              id={BUTTON_IDS.geofencesPrimary}
              primaryButton={helpers.getPrimaryButton({ can, history })}
              moreButtons={helpers.getMoreButtons({
                handleExportExcel,
                handleBulkImport,
                handleExportCSV,
                handleExportGeojson,
                isExportCsvAllowed:
                  getNonManagedGeofences(geofenceProviders, filterGeofences).length !== 0 &&
                  isGeofenceFullyFetched,
                isExportExcelAllowed: filterGeofences.length !== 0 && isGeofenceFullyFetched
              })}
            />
            <CSVLink
              data={csvData || []}
              enclosingCharacter={``}
              className={styles.hidden}
              filename={getCSVFilename({
                entityName: t('GeofencesFeature.CSV.Filename'),
                companyName: company?.name
              })}
              ref={csvLinkRef}
            ></CSVLink>
          </div>
        </HeaderPageWrapper>
        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={value => setFilterText(value)} />
            <AntMultiselect
              title={
                filterCompanies?.some(value => !value.checked)
                  ? t('Common.Companies')
                  : t('Common.AllCompanies')
              }
              onFilter={v => setFilterCompanies(v)}
              data={filterCompanies}
            />
            <AntMultiselect
              title={
                filterFleets?.some(value => !value.checked)
                  ? t('Common.Fleets')
                  : t('Common.AllFleets')
              }
              data={filterFleets}
              onFilter={v => setFilterFleets(v)}
            />
            <AntMultiselect
              title={
                filteredGeofenceTypes?.some(value => !value.checked)
                  ? t('Common.Geofence Types')
                  : t('Common.All Geofence Types')
              }
              tooltip={
                filteredGeofenceTypes?.some(value => !value.checked)
                  ? t('GeofencesFeature.IncompleteGeofenceTypeSelectionToolTip')
                  : null
              }
              data={filteredGeofenceTypes}
              onFilter={v => setFilteredGeofenceTypes(v)}
            />
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {filterGeofences && filterGeofences.length > 0 ? filterGeofences.length : 0}{' '}
            {filterGeofences.length === 1
              ? t('GeofencesFeature.Geofence')
              : t('GeofencesFeature.Geofences')}
          </label>
        </div>
        <div style={{ flex: '1 0 0', flexDirection: 'row', display: 'flex' }}>
          <div style={{ flex: '1 0 0' }}>
            <GeofencesTable
              geofences={filterGeofences}
              companies={companies}
              locations={allLocations}
              isLoading={isFetchingGeofence && !isAsyncFetchingGeofence}
              setTableRef={setTableRef}
              typeOfEntityToDelete={t('Common.geofence')}
              handleDeleteAction={handleDeleteAction}
              canViewScorecardItems={canViewScorecardItems}
              viewMode={filterTab}
              selectedGeofenceId={selectedGeofenceId}
              onGeofenceSelected={id => setSelectedGeofenceId(id)}
            />
          </div>

          {filterTab === 'mapview' && (
            <Map
              mode={MapMode.GeofenceMapView}
              geofences={filterGeofences}
              devices={EMPTY_ARRAY}
              mapOptions={mapOptions}
              containerElement={<div style={{ height: `100%`, width: `auto`, flex: '2 1 0' }} />}
              mapElement={<div style={{ height: `100%`, width: `100%` }} id="mapGeofence" />}
              showExtraGeofenceParams={true}
              selectedGeofenceId={selectedGeofenceId}
              onGeofenceSelected={setSelectedGeofenceId}
            />
          )}
        </div>
      </ContainerPageWrapper>
    </>
  );
};
