import React, { useEffect, useState, createContext, useCallback, useMemo } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import { useDispatch, useSelector } from 'react-redux';
import ReactDependentScript from 'react-dependent-script';
import { Provider } from 'react-redux';
import store from 'store';
import mqtt from 'mqtt';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ConfigProvider } from 'antd';
import { cloneDeep } from 'lodash';
import fr from 'moment/locale/fr';
import es from 'moment/locale/es';
import en from 'moment/locale/en-gb';
import enUS from 'antd/es/locale/en_US';
import esES from 'antd/es/locale/es_ES';
import frFR from 'antd/es/locale/fr_FR';

import { ApiClient } from 'nextgen_api';

import { fetchCompanies, useCompanies, useCurrentCompany } from 'features/company/companySlice';
import { fetchNotifications } from 'features/notifications/notificationsSlice';
import {
  useUser,
  useUserKey,
  getCurrentUserInfo,
  useCurrentAdminLoggedIn,
  verifyUserLogInfo
} from 'features/user/userSlice';
import { useCan } from 'features/permissions';
import {
  setMqttSubscription,
  unsetMqttSubscription,
  useIsMqttConnected,
  setIsMqttConnected
} from 'features/mqtt/mqttSlice';
import { fetchDeviceTypes } from 'features/device_types/deviceTypesSlice';
import { fetchDeviceModels } from 'features/device_models/deviceModelsSlice';
import { fetchPermissions } from 'features/permissions/permissionsSlice';
import { useUserPreferences, useUserPreferencesFetched } from 'features/user/userPreferencesSlice';
import useInterval from 'features/common/hooks/interval';
import { usePullMessages } from 'features/messaging/hooks';
import { CompatibleLogin } from 'features/compatible_login/CompatibleLogin';
import { LANGUAGES, SPANISH_LOCALES, FRENCH_LOCALES } from 'features/localization/languages';
import { useMixpanelPVEventTrack, useMixpanelProfiles } from 'features/mixpanel';

import Login from 'containers/Login/Login';
import RemoteSignIn from 'containers/RemoteSignIn/RemoteSignIn';
import PublicJobView from 'containers/SmartJobs/JobView/PublicJobView';
import PublicTrackingView from 'containers/Tracking/PublicTrackingView';
import PrivateRoute from 'components/route/PrivateRoute';
import { ToastContainer } from 'components/notifications/toasts/Toast';
import ResetPassword from 'containers/Login/ResetPassword';
import UpdatePassword from 'containers/Login/UpdatePassword';
import { LogInAsUserHeader } from 'components/logInAsUserHeader';
import ErrorBoundary from 'components/pages/ErrorPage';
import Logout from 'containers/Login/Logout';
import DuressAlert from 'containers/Administration/Alerts/DuressAlert';
import { useSSO } from 'containers/SSO/ssoHook';
import { DEFAULT_PREFERENCES } from 'containers/Settings/constants';
import { SSO } from 'containers/SSO/SSO';
import { ErrorRoute } from 'components/pages/ErrorPage';
import { Layout } from 'components/tn/layout';

import { useTimer } from 'utils/hooks/useTimer';
import {
  API_PATH,
  GOOGLE_MAPS_API_KEY,
  HELICOPTER_URL,
  HELICOPTER_USER,
  HELICOPTER_PW
} from 'config';

import { darkTheme } from 'static/js/themes';

import styles from './App.module.scss';
import { setUseEmptyGoogleMapKey, useUseEmptyGoogleMapKey } from 'features/appState/appStateSlice';

export const MqttContext = createContext(null);

const App = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const user = useUser();
  const currentAdminLoggedIn = useCurrentAdminLoggedIn();
  const userKey = useUserKey();
  const company = useCurrentCompany();
  const companies = useCompanies();
  const permissionsHaveFetched = useSelector(state => state.permissions.hasFetched);
  const companiesHaveFetched = useSelector(state => state.companies.meta.lastFetched);
  const currentUserInfoHaveFetched =
    Object.keys(useSelector(state => state.user.currentUserInfo)).length > 0;
  const isMqttConnected = useIsMqttConnected();
  const location = useLocation();
  const userPreferences = useUserPreferences();
  const userPreferencesFetched = useUserPreferencesFetched();
  const can = useCan();
  const history = useHistory();

  const [previousCompanySlug, setPreviousCompanySlug] = useState();
  const [isMqttSubscribed, setIsMqttSubscribed] = useState(false);
  const [locale, setLocale] = useState(enUS);
  const [mqttClient, setMqttClient] = useState();
  const [isAppReady, setIsAppReady] = useState(false);
  const { unreadMsgCheck } = usePullMessages(isAppReady);
  const usingEmptyGoogleMapKey = useUseEmptyGoogleMapKey();
  const isKeycloakIframe = window.self.frameElement?.title === 'keycloak-silent-check-sso';

  useEffect(() => {
    if (!window.gm_authFailure) {
      window.gm_authFailure = () => {
        dispatch(setUseEmptyGoogleMapKey(true));
      };
    }
  }, [dispatch]);

  const [googleMapKey] = useState(() => {
    let key = GOOGLE_MAPS_API_KEY;
    if (usingEmptyGoogleMapKey) {
      key = '';
    }
    return key;
  });

  useEffect(() => {
    if (usingEmptyGoogleMapKey && googleMapKey) {
      window.location.reload();
    }
  }, [usingEmptyGoogleMapKey, googleMapKey]);

  useMixpanelPVEventTrack(location.pathname);
  useMixpanelProfiles(user, company, companies, currentAdminLoggedIn);
  useSSO();

  useEffect(() => {
    setIsAppReady(user && userKey && permissionsHaveFetched);
  }, [user, userKey, permissionsHaveFetched]);

  const isCompatibleLoginEnabled = can({
    otherConditions: [() => !window.localStorage.getItem('AutomationTest')]
  });

  const loadNotifications = useCallback(() => {
    if (user && userKey) {
      if (user?.type?.code !== 'DRIVER') {
        dispatch(fetchNotifications(user));
      }
    }
  }, [user, userKey, dispatch]);

  useInterval(
    unreadMsgCheck,
    userKey && userKey.length
      ? (Number(userPreferences?.refresh?.messaging) || DEFAULT_PREFERENCES.refresh.messaging) *
          1000
      : null
  );
  useEffect(() => unreadMsgCheck(), []);

  const subscribeToCompanyMqtt = () => {
    if (
      location.pathname.indexOf('/tracking') === 0 ||
      location.pathname.indexOf('/home') === 0 ||
      location.pathname === '/'
    ) {
      if (!isMqttSubscribed || (company.slug !== previousCompanySlug && isMqttConnected)) {
        dispatch(setMqttSubscription(company.slug, mqttClient));
        setIsMqttSubscribed(true);
        setPreviousCompanySlug(company.slug);
      }
    } else {
      if (isMqttSubscribed) {
        dispatch(unsetMqttSubscription(company.slug, mqttClient));
        setIsMqttSubscribed(false);
      }
    }
  };

  useEffect(() => {
    if (userPreferences?.language) {
      localStorage.setItem('userPrefsLanguage', userPreferences?.language);
      if (!sessionStorage.getItem('newLoginLanguageSelected')) {
        i18n.changeLanguage(userPreferences?.language);
      }
    }
  }, [userPreferences?.language]);

  useEffect(() => {
    if (userKey && !currentUserInfoHaveFetched) {
      dispatch(getCurrentUserInfo());
    }
  }, [dispatch, userKey, currentUserInfoHaveFetched]);

  useEffect(() => {
    if (userKey && !permissionsHaveFetched) {
      dispatch(fetchPermissions());
    }
    if (userKey && permissionsHaveFetched && !companiesHaveFetched) {
      dispatch(fetchCompanies());
    }
    // Set the base url and default headers for the ApiClient singleton
    ApiClient.instance.basePath = API_PATH;
    ApiClient.instance.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
  }, [dispatch, companiesHaveFetched, permissionsHaveFetched, userKey]);

  useEffect(() => {
    const handleStorageChange = () => {
      dispatch(verifyUserLogInfo(history, user?.auth?.keycloakConfig));
    };

    const subscribeUserLoginChange = () => {
      window.addEventListener('storage', handleStorageChange);
    };

    const unsubscribeUserLoginChange = () => {
      window.removeEventListener('storage', handleStorageChange);
    };

    if (isAppReady && !isKeycloakIframe) {
      if (user?.type?.code !== 'DRIVER') {
        dispatch(fetchNotifications(user));
      }
      dispatch(fetchDeviceTypes(userKey));
      dispatch(fetchDeviceModels(userKey));
      subscribeUserLoginChange();
      return () => {
        unsubscribeUserLoginChange();
      };
    }
  }, [dispatch, user, userKey, isAppReady]);

  useEffect(() => {
    if (userPreferencesFetched && isAppReady) {
      //set the locale for moment
      if (Object.values(FRENCH_LOCALES).some(frLang => userPreferences?.language === frLang)) {
        moment.locale('fr', fr);
        setLocale(frFR);
      } else if (
        Object.values(SPANISH_LOCALES).some(esLang => userPreferences?.language === esLang)
      ) {
        moment.locale('es', es);
        const customizedLocale = cloneDeep(esES);
        customizedLocale.Table.triggerAsc = t('AntDesign.Table.TriggerAsc');
        customizedLocale.Table.triggerDesc = t('AntDesign.Table.TriggerDesc');
        setLocale(customizedLocale);
      } else {
        moment.locale('en', en);
        const customizedLocale = cloneDeep(enUS);
        customizedLocale.Table.triggerAsc = t('AntDesign.Table.TriggerAsc');
        customizedLocale.Table.triggerDesc = t('AntDesign.Table.TriggerDesc');
        setLocale(customizedLocale);
      }
    }
  }, [isAppReady, dispatch, userPreferences, userPreferencesFetched, t]);

  useEffect(() => {
    if (userPreferencesFetched && isAppReady) {
      // check to see if the refresh type for Tracking is set to Live (-1)
      if (userPreferences?.refresh?.tracking === -1) {
        setMqttClient(
          mqtt.connect(HELICOPTER_URL, {
            username: HELICOPTER_USER,
            password: HELICOPTER_PW
          })
        );
      } else {
        mqttClient && mqttClient.end();
        setMqttClient(null);
        dispatch(setIsMqttConnected(false));
      }
    }
  }, [isAppReady, dispatch, userPreferences?.refresh?.tracking, userPreferencesFetched]);

  useEffect(() => {
    if (mqttClient) {
      mqttClient.on('connect', () => {
        dispatch(setIsMqttConnected(true));
      });
    }
  }, [mqttClient, dispatch]);

  useEffect(() => {
    if (isMqttConnected) {
      if (previousCompanySlug && previousCompanySlug !== company.slug) {
        dispatch(unsetMqttSubscription(previousCompanySlug, mqttClient));
        setIsMqttSubscribed(false);
      }
      if (userPreferences?.refresh?.tracking === -1) {
        subscribeToCompanyMqtt();
      }
    }
  }, [company.slug, isMqttConnected, location, userPreferences?.refresh?.tracking]);

  useEffect(() => {
    if (location.pathname.includes('/public/s/')) {
      const hashJob = location.pathname.split('/')[3];
      window.location.replace(`${API_PATH}/s/${hashJob}`);
    }
  }, [history, location]);

  useEffect(() => {
    let timeZone = null;
    if (user != null) {
      timeZone = user.timeZone;
    }

    if (timeZone == null && company != null) {
      timeZone = company.statisticsTz;
    }

    if (timeZone != null) {
      moment.tz.setDefault(timeZone);
    }

    var doc = document.documentElement;
    doc.setAttribute('data-useragent', navigator.userAgent);
    doc.setAttribute('data-platform', navigator.platform);
  }, [user, company]);

  useTimer(
    Math.max(Number(userPreferences?.refresh?.messaging), 60) * 1000,
    loadNotifications,
    isAppReady
  );

  if (isKeycloakIframe) {
    return <Layout isLoading={true} />;
  }

  return (
    <ReactDependentScript
      scripts={[
        `https://maps.googleapis.com/maps/api/js?key=${googleMapKey}&language=${localStorage.getItem(
          'userPrefsLanguage'
        ) ||
          userPreferences?.language ||
          LANGUAGES.EN}&libraries=places,drawing&callback=Function.prototype`
      ]}
    >
      <Provider store={store}>
        <ThemeProvider theme={darkTheme}>
          <ErrorBoundary>
            <ConfigProvider
              locale={locale}
              theme={{
                token: {
                  colorPrimary: '#86b341',
                  borderRadius: 4,
                  fontFamily: 'Roboto'
                },
                components: {
                  Collapse: {
                    contentBg: '#ffffff',
                    headerBg: '#F8F8F8'
                  }
                }
              }}
            >
              <MqttContext.Provider value={{ mqttClient, setMqttClient }}>
                {user?.type?.code !== 'DRIVER' && userKey && <DuressAlert />}
                {currentAdminLoggedIn && <LogInAsUserHeader user={user} />}
                <div
                  className={styles.appContainer}
                  style={currentAdminLoggedIn && { padding: '31px 0 0 0' }}
                >
                  <Switch>
                    <Route
                      path="/login"
                      component={isCompatibleLoginEnabled ? CompatibleLogin : Login}
                    />
                    <Route exact path="/error" component={ErrorRoute} />
                    <Route exact path="/sso" component={SSO} />
                    <Route exact path="/logout" component={Logout} />
                    <Route path="/signin" component={RemoteSignIn} />
                    <Route exact path="/resetpassword" component={ResetPassword} />
                    <Route exact path="/users/password/edit" component={UpdatePassword} />
                    <Route path="/public/pod" component={PublicJobView} />
                    <Route path="/public/tracking" component={PublicTrackingView} />

                    <PrivateRoute
                      loginPath="/login"
                      path="/"
                      render={() => <Layout isLoading={!isAppReady} />}
                    />
                  </Switch>
                  <ToastContainer />
                </div>
              </MqttContext.Provider>
            </ConfigProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </Provider>
    </ReactDependentScript>
  );
};

export default App;
