import { useCan } from './canHooks';

export const Can = ({
  children,
  onFail,
  everyEntity,
  oneOfEntities,
  everyService,
  oneOfServices,
  everyRole,
  oneOfRoles,
  noneOfRoles,
  beyondRoles,
  everyCompanyService,
  oneOfCompanyServices,
  companyConfigs,
  featureFlag,
  oneOfFeatureFlags,
  featureHide,
  otherConditions,
  path
}) => {
  const can = useCan();

  const renderer = can({
    everyEntity,
    oneOfEntities,
    everyService,
    oneOfServices,
    everyRole,
    oneOfRoles,
    noneOfRoles,
    beyondRoles,
    everyCompanyService,
    oneOfCompanyServices,
    companyConfigs,
    featureFlag,
    oneOfFeatureFlags,
    featureHide,
    otherConditions,
    path
  })
    ? children
    : onFail;

  return renderer ? renderer : null;
};

export default Can;
