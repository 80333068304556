import React, { useState, useEffect, useCallback } from 'react';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import { useDispatch } from 'react-redux';
import { useUser } from 'features/user/userSlice';
import { PATHS } from './constants';
import {
  useGetSpeedAssistConfigurationTemplateByIdQuery,
  useRestoreSpeedAssistConfigurationTemplateByIdMutation,
  useDeleteSpeedAssistConfigurationTemplateByIdMutation,
  executeRestoreSpeedAssistConfigurationTemplate,
  executeDeleteSpeedAssistConfigurationTemplate
} from 'services/nextgen/ngSpeedAssistConfigurationApi';
import { setBackButton, setPageTitle, addBackButtonLink } from 'features/page/pageSlice';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { DeletedEntityAlert, DeletableEntityType } from 'features/common/deletedEntityAlert';
import InfoRow from 'components/form/info-row/InfoRow';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { services, GlobalRoles, useCanOneOfRoles } from 'features/permissions';
import { format } from 'utils/dates';
import {
  useCompanies,
  useRedirectToMainFeaturePageOnCompanyChange
} from 'features/company/companySlice';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import styles from '../GPIO/GPIO.module.scss';
import { useDevices } from 'features/fleets/fleetsSlice';
import { DevicesAssignmentTable } from '../components/DevicesAssignmentTable';
import { templateTypeEnum } from '../components/constants';
import { Table } from 'antd';

export const SpeedAssistView = () => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const currentUser = useUser();
  const currentCompany = useCurrentCompany();
  const companies = useCompanies();
  const isSiteAdmin = currentUser.siteAdmin;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );
  const allDevices = useDevices();
  const [saDevices, setSADevices] = useState([]);

  const [
    restoreSpeedAssistConfigurationTemplateById
  ] = useRestoreSpeedAssistConfigurationTemplateByIdMutation();
  const [
    deleteSpeedAssistConfigurationTemplateById
  ] = useDeleteSpeedAssistConfigurationTemplateByIdMutation();
  const { data: speedAssistTemplate, isFetching } = useGetSpeedAssistConfigurationTemplateByIdQuery(
    { templateId: id, embed: 'devices' },
    { skip: id === undefined }
  );

  const hasSiteAdminOrAdminOrResellerRole = useCanOneOfRoles([
    GlobalRoles.Reseller,
    GlobalRoles.SiteAdmin,
    GlobalRoles.Admin
  ]);

  const handleFetchError = useCallback(() => {
    history.replace(PATHS.SPEEDASSIST_DEFAULT);
  }, [history]);
  useEffect(() => {
    if (speedAssistTemplate?.associatedDevices?.length !== 0) {
      const dev = allDevices.filter(device =>
        speedAssistTemplate?.associatedDevices.find(d => d.id === device.id)
      );
      setSADevices(dev);
    }
  }, [allDevices, speedAssistTemplate]);

  useEffect(() => {
    dispatch(
      setPageTitle(
        speedAssistTemplate?.configurationTemplate?.name &&
          `${speedAssistTemplate?.configurationTemplate?.name}`
      )
    );

    dispatch(setBackButton(true));
    addBackButtonLink({
      url: pathname,
      backLink: PATHS.SPEEDASSIST_DEFAULT
    });
  }, [dispatch, speedAssistTemplate]);

  const renderDevices = () => {
    if (speedAssistTemplate?.associatedDevices?.length !== 0) {
      return (
        <DevicesAssignmentTable
          filteredDevices={[]}
          devices={saDevices}
          currentCompany={currentCompany}
          templateType={templateTypeEnum.SpeedAssist}
          useTitle={false}
          useFilter={false}
        />
      );
    } else {
      return '-';
    }
  };

  const renderConfigurations = () => {
    if (speedAssistTemplate?.configurationTemplate?.configurations?.length !== 0) {
      let companymanaged =
        speedAssistTemplate?.configurationTemplate?.configurations?.find(
          item => item.key === 'company.managed'
        )?.value === 'true'
          ? t('Common.Yes')
          : t('Common.No');
      let signposteddisable =
        speedAssistTemplate?.configurationTemplate?.configurations?.find(
          item => item.key === 'signposted.disable'
        )?.value === 'true'
          ? t('Common.Yes')
          : t('Common.No');
      let companymaxthreshold = speedAssistTemplate?.configurationTemplate?.configurations?.find(
        item => item.key === 'company.max.threshold'
      )?.value;
      companymaxthreshold =
        localization.convertDistance(companymaxthreshold || 0, 0) +
        ' ' +
        localization.formats.speed.unit_per_hour;
      let companymaxoffset = speedAssistTemplate?.configurationTemplate?.configurations?.find(
        item => item.key === 'company.max.offset'
      )?.value;
      companymaxoffset =
        localization.convertDistance(companymaxoffset || 0, 0) +
        ' ' +
        localization.formats.speed.unit_per_hour;
      let companymaxduration = speedAssistTemplate?.configurationTemplate?.configurations?.find(
        item => item.key === 'company.max.duration'
      )?.value;
      companymaxduration = companymaxduration + ' ' + t('Common.seconds');

      let da = [
        {
          key: '1',
          companymanaged: companymanaged,
          signposteddisable: signposteddisable,
          companymaxthreshold: companymaxthreshold,
          companymaxoffset: companymaxoffset,
          companymaxduration: companymaxduration
        }
      ];

      return (
        <Table dataSource={da} pagination={false}>
          <Table.Column
            title={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.CompanyManaged')}
            dataIndex="companymanaged"
            key="companymanaged"
          />
          <Table.Column
            title={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.SignpostedDisabled')}
            dataIndex="signposteddisable"
            key="signposteddisable"
          />
          <Table.Column
            title={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxThreshold')}
            dataIndex="companymaxthreshold"
            key="companymaxthreshold"
          />
          <Table.Column
            title={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxOffset')}
            dataIndex="companymaxoffset"
            key="companymaxoffset"
          />
          <Table.Column
            title={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxDuration')}
            dataIndex="companymaxduration"
            key="companymaxduration"
          />
        </Table>
      );
    } else {
      return '-';
    }
  };

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        confirmationModal(
          `${t('Common.DeleteButton')} ${speedAssistTemplate?.configurationTemplate?.name}`,
          `${
            speedAssistTemplate?.configurationTemplate?.default
              ? t('CompanyConfig.DeviceConfigurations.DeleteCompanyTemplateWarning', {
                  templateName: 'Speed Assist'
                })
              : t('Common.SureDelete') +
                ' ' +
                speedAssistTemplate?.configurationTemplate?.name +
                '?'
          }`,
          t('Common.DeleteButton'),
          t('Common.CancelButton'),
          () => {
            executeDeleteSpeedAssistConfigurationTemplate(
              { id: id, name: speedAssistTemplate?.configurationTemplate?.name },
              deleteSpeedAssistConfigurationTemplateById,
              dispatch
            );
          },
          'delete'
        );
        break;
      case 'restore':
        executeRestoreSpeedAssistConfigurationTemplate(
          { id: id, name: speedAssistTemplate?.configurationTemplate?.name },
          restoreSpeedAssistConfigurationTemplateById,
          dispatch
        );
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      <ViewHeaderWrapper
        data={{
          entityName: AUDIT_ENTITY.CONFIGURATION_TEMPLATE,
          ...speedAssistTemplate?.configurationTemplate
        }}
        editPath={`/settings/company_config/speedassist/edit/id/${id}`}
        auditPath={`/settings/company_config/speedassist/audit/id/${id}`}
        handleButtonAction={handleButtonAction}
        skipDeleteConfirmation={true}
        disableDelete={!hasSiteAdminOrAdminOrResellerRole}
        disableRestore={!hasSiteAdminOrAdminOrResellerRole}
        canService={[services.SPEEDASSIST]}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '32px',
          color: '#181c21'
        }}
        className={styles.gpioView}
      >
        <DeletedEntityAlert
          entity={DeletableEntityType.ConfigurationTemplate}
          entityStatus={speedAssistTemplate?.status}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.Company')}
          value={
            companies.find(
              comp => comp.id === speedAssistTemplate?.configurationTemplate?.company?.id
            )?.name
          }
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.Template')}
          value={speedAssistTemplate?.configurationTemplate?.name}
          sxValue={{ width: '75%' }}
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.Description')}
          value={speedAssistTemplate?.configurationTemplate?.description}
          sxValue={{ width: '75%', whiteSpace: 'pre-wrap' }}
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.IsDefaultTemplate')}
          sxValue={{ width: '75%' }}
          value={
            speedAssistTemplate?.configurationTemplate?.default ? t('Common.Yes') : t('Common.No')
          }
          styles={styles}
        />
        <InfoRow
          label={t('Common.Configuration')}
          value={renderConfigurations()}
          styles={styles}
          sxValue={{ width: '75%' }}
        />

        <InfoRow
          label={t('Devices.Devices')}
          value={renderDevices()}
          styles={styles}
          sxValue={{ width: '75%' }}
        />
        <InfoRow
          sxValue={{ width: '75%' }}
          label={t('CompanyConfig.DeviceConfigurations.View.CreatedAt')}
          value={
            speedAssistTemplate?.configurationTemplate?.createdAt
              ? format(
                  new Date(speedAssistTemplate?.configurationTemplate?.createdAt),
                  localization.formats.time.formats.dby_imp
                )
              : ''
          }
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.UpdatedAt')}
          sxValue={{ width: '75%' }}
          value={
            speedAssistTemplate?.configurationTemplate?.updatedAt
              ? format(
                  new Date(speedAssistTemplate?.configurationTemplate?.updatedAt),
                  localization.formats.time.formats.dby_imp
                )
              : ''
          }
          styles={styles}
        />
      </div>
    </React.Fragment>
  );
};
