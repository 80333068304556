import { LoadingTable } from 'components/grid/LoadingTable';
import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import {
  associationsCellRenderer,
  templateCache as cache,
  ellipsisButtonCellRenderer,
  nameCellRenderer,
  sortableHeaderRenderer
} from '../components/CellRenderers';
import { useTranslation } from 'react-i18next';
import { useUser } from 'features/user/userSlice';

export const TemplatesTable = ({
  templates,
  isLoading,
  filterTab,
  handleDeleteAction,
  typeOfEntityToDelete,
  handleAction,
  handleSort,
  sortBy,
  sortDirection,
  templateType
}) => {
  const { t } = useTranslation();
  const currentUser = useUser();
  const isSiteAdmin = currentUser.siteAdmin;

  if (isLoading) return <LoadingTable columnSizes={[200, 300]} />;
  const enableRestoreAction = filterTab === 'deleted' && isSiteAdmin;
  const showDelete = filterTab === 'all' && isSiteAdmin;
  const hideEdit = filterTab === 'deleted';
  const hideCopy = filterTab === 'deleted';

  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          association: 250,
          companyLevel: 250,
          ellipsisButton: 60
        };

        const dynamicColumnWidth =
          width -
          ((filterTab !== 'deleted'
            ? fixedColumnsWidth.companyLevel + fixedColumnsWidth.association
            : 0) +
            fixedColumnsWidth.ellipsisButton);

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={52}
            headerStyle={{ paddingTop: '18px' }}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={templates.length}
            rowGetter={({ index }) => templates[index]}
            rowStyle={{ alignItems: 'flex-start' }}
          >
            <Column
              label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.Name')}
              dataKey="name"
              width={dynamicColumnWidth}
              cellRenderer={props => nameCellRenderer({ ...props, t, templateType })}
              headerRenderer={() =>
                sortableHeaderRenderer({
                  label: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.Name'),
                  name: 'name',
                  sortBy,
                  sortDirection,
                  handleSort
                })
              }
            />
            {filterTab !== 'deleted' && (
              <Column
                label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.CompanyTemplate')}
                dataKey="default"
                width={fixedColumnsWidth.companyLevel}
                cellRenderer={props => (
                  <div>{props.rowData.default ? t('Common.Yes') : t('Common.No')}</div>
                )}
              />
            )}
            {filterTab !== 'deleted' && (
              <Column
                label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.Associations')}
                dataKey="associations"
                width={fixedColumnsWidth.association}
                cellRenderer={props => associationsCellRenderer({ ...props, t })}
              />
            )}
            <Column
              label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.ActionsLabel')}
              dataKey=""
              style={{ overflow: 'visible' }}
              width={fixedColumnsWidth.ellipsisButton}
              cellRenderer={props =>
                ellipsisButtonCellRenderer({
                  ...props,
                  handleDeleteAction,
                  typeOfEntityToDelete,
                  hideEdit,
                  showDelete,
                  hideCopy,
                  enableRestoreAction,
                  handleAction,
                  templateType: templateType
                })
              }
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};
