import React from 'react';
import { Document, Page, Image, View, Text, StyleSheet } from '@react-pdf/renderer';
import { format } from 'utils/dates';
//import styles from './InstallationLog.module.scss';

const styles = StyleSheet.create({
  title: {
    textAlign: 'left',
    fontSize: 18,
    marginBottom: 20,
    borderBottom: '1px solid #ccc',
    padding: 12
  },

  container: {
    flexDirection: 'column',
    objectFit: 'contain',
    width: '100%'
  },

  card: {
    width: '120%',
    height: '30%',
    objectFit: 'contain',
    padding: 15,
    marginBottom: 10,
    position: 'relative',
    borderBottom: '1px solid #ccc'
  },

  image: {
    width: 200,
    height: 140,
    objectFit: 'contain',
    alignSelf: 'flex-left',
    marginBottom: 10,
    top: '8%',
    marginLeft: '0%'
  },

  textContainer: {
    position: 'absolute'
  },
  name: {
    fontSize: 10,
    marginLeft: '6%',
    top: '5%'
  },
  date: {
    fontSize: 10,
    marginLeft: '6%',
    top: '3%'
  }
});
const ImageDocument = ({ imageBlobs, localization, title }) => {
  const imagesPerPage = 4; // Number of images per page
  const totalPages = Math.ceil(imageBlobs.length / imagesPerPage);

  const pages = [];

  for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
    const startIdx = pageIndex * imagesPerPage;
    const endIdx = startIdx + imagesPerPage;
    const pageImages = imageBlobs.slice(startIdx, endIdx);

    // Create a container for the images on the current page
    const container = pageImages.map((blob, index) => (
      <View style={styles.card} key={index}>
        <View style={styles.textContainer}>
          <Text style={styles.name}>{blob?.name?.split('.')[0]}</Text>
          <Text style={styles.date}>
            {format(blob.createdAt, localization.formats.time.formats.dby_imp)}
          </Text>
        </View>
        <Image src={URL.createObjectURL(blob.blob)} style={styles.image} />
      </View>
    ));

    pages.push(
      <Page key={pageIndex} size="A4" style={styles.page}>
        {pageIndex === 0 && <Text style={styles.title}>{title}</Text>}
        <View style={styles.container}>{container}</View>
      </Page>
    );
  }
  // return <Document>{pages}</Document>;
  return pages;
};

export default ImageDocument;
