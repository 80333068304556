import entities from 'features/permissions/entities';

export const initialAddFormValues = companyId => ({
  name: '',
  companyId,
  global: false,
  description: ''
});

export const PermissionsTypes = {
  Entity: 'entity',
  Module: 'module'
};

export const PermissionsCriteria = {
  Subject: 'subject'
};

//Permissions not yet open to user
export const ReservedPermissionTags = [
  entities.GEOFENCE_RESTORE,
  entities.VIDEO_AUDIT,
  entities.DEVICE_DESTROY
];

export const PATHS = {
  Default: '/settings/roles',
  VIEW_BY_ID: '/settings/roles/id/',
  NEW_ROLE: '/settings/roles/newRole',
  EDIT_BY_ID: '/settings/roles/edit/id',
  COPY_BY_ID: '/settings/roles/copy/id',
  AUDIT_BY_ID: '/settings/roles/audit/id'
};
