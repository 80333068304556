import React from 'react';
import { Link } from 'react-router-dom';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { format } from 'utils/dates';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ActionMenu } from 'components/actionMenu';
import { getScheduleStatus, getScheduledBy, getActionMenuItems } from './helpers';
import { dateDiffInDays } from 'utils/dates';
import { formatEngineHours, formatOdometer } from 'features/meters';
import { entities } from 'features/permissions';
import { NoApplicableVehicleOdometer } from './constants';
import moment from 'moment';
import { DATE_FORMAT } from 'features/localization/localization';
import { Paths } from './constants';
import { getMeterValue } from 'features/meters';

import '../VehicleMaintenanceSchedules.scss';
import styles from '../VehicleMaintenanceSchedules.module.scss';

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 60
});

export const vehicleCellRenderer = ({ rowData, rowIndex, dataKey, parent, canViewVehicle }) => {
  return (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${rowData.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      {canViewVehicle ? (
        <Link to={`/settings/vehicles/id/${rowData.id}`}>{rowData.name}</Link>
      ) : (
        <span>{rowData.name}</span>
      )}
    </CellMeasurer>
  );
};

export const lastChecklistCellRenderer = ({ rowData }) => {
  if (rowData.lastPreTripEvent) {
    const timeAt =
      rowData.lastPreTripEvent.timeAt && parseInt(new Date(rowData.lastPreTripEvent.timeAt) / 1000);
    return (
      <Link to={`/events/${rowData.lastPreTripEvent.id}/pretrip?time_at=${timeAt}`}>
        <div>
          <span className="lastChecklistIcon">
            {rowData.lastPreTripEvent.passed === false ? (
              <CloseOutlined style={{ color: '#ff4d4f', display: 'inline-flex' }} />
            ) : (
              <CheckOutlined style={{ color: '#26bd8b', display: 'inline-flex' }} />
            )}
          </span>
          <span>{rowData.lastPreTripEvent.checklistName}</span>
        </div>
      </Link>
    );
  }
};

export const registrationCellRenderer = ({ rowData }) =>
  rowData.registration && `${rowData.registrationState || ''} | ${rowData.registration}`;

export const registrationStateCellRenderer = ({ rowData }) => rowData.registrationState;

export const currentOdometerCellRenderer = ({ rowData, localization }) => {
  const isNAOdometerValue = !!rowData?.config?.isUsingRUCOdometerForMnt && !rowData.rucDevice;
  return isNAOdometerValue
    ? NoApplicableVehicleOdometer
    : formatOdometer(localization, rowData.odometer);
};

export const rucOdometerCellRenderer = ({ rowData, localization }) => {
  return formatOdometer(localization, rowData.rucOdometer);
};

export const currentEngineHoursCellRenderer = ({ rowData, localization }) =>
  formatEngineHours(localization, rowData.engineHours);

// inner Table
export const statusCellRenderer = ({ rowData }) => {
  return getScheduleStatus(rowData.status);
};

export const lastServiceCellRenderer = ({ rowData, localization }) => {
  const serviceOn =
    rowData.completionParameters && rowData.completionParameters !== 'null'
      ? JSON.parse(rowData.completionParameters).service_on
      : '';
  return serviceOn
    ? format(moment(serviceOn, DATE_FORMAT.DEFAULT), localization.formats.time.formats.dby)
    : '';
};

export const lastMileageCellRenderer = ({ rowData, localization, t }) => {
  return rowData.previousMaintenance
    ? `${t('VehicleMntSchedules.View.Odometer')}: ${localization.formatDistance(
        JSON.parse(rowData.previousMaintenance?.completionParameters)?.odometer
      )}`
    : '';
};

export const notesCellRenderer = ({ rowData }) => {
  return rowData.completionParameters && rowData.completionParameters !== 'null'
    ? JSON.parse(rowData.completionParameters).notes || ''
    : '';
};

export const maintenanceTypeCellRenderer = ({ rowData, canViewMaintenanceType }) =>
  canViewMaintenanceType ? (
    <Link to={`/settings/vehicleMntTypes/id/${rowData.manageType?.id}`}>
      {rowData.manageType?.name}
    </Link>
  ) : (
    <span>{rowData.manageType?.name}</span>
  );

export const serviceNameCellRenderer = ({ rowData, history, can }) => {
  if (!rowData?.name) {
    return;
  }
  return can && can({ everyEntity: [entities.VEHICLEMAINTENANCESCHEDULE_VIEW] }) ? (
    <Link onClick={() => history.push(`${Paths.VEHICLEMAINTENANCE_VIEW}/${rowData.id}`)}>
      {rowData.name}
    </Link>
  ) : (
    <span>{rowData.name}</span>
  );
};

export const scheduledByCellRenderer = ({ rowData, users, canViewUser }) => {
  return getScheduledBy(rowData, users, canViewUser);
};

export const serviceDueCellRenderer = ({ rowData, localization, t }) => {
  let parameters = {};
  try {
    parameters = JSON.parse(rowData?.parameters || '{}');
  } catch (e) {
    console.error(e);
  }

  const { gpio_meters } = parameters;

  const areAllParametersShown =
    rowData.schedulesOn &&
    parameters.odometer !== undefined &&
    parameters.engine_hours &&
    gpio_meters?.length &&
    parameters[gpio_meters?.[0]];

  const odometerValue =
    parameters.odometer !== undefined &&
    `${t('VehicleMntSchedules.View.Odometer')}: ${formatOdometer(
      localization,
      parameters.odometer
    )}`;
  const engineHoursValue =
    parameters.engine_hours &&
    `${t('VehicleMntSchedules.View.EngineHours')}: ${formatEngineHours(
      localization,
      parameters.engine_hours
    )}`;

  const gpioValue =
    gpio_meters?.length &&
    parameters[gpio_meters?.[0]] &&
    `${t('VehicleMntSchedules.Table.ServiceDueGPIOHours', {
      type: t(`Vehicles.GPIO.${gpio_meters}`)
    })}: ${formatEngineHours(localization, parameters[gpio_meters[0]])}`;

  return (
    <div className={areAllParametersShown ? styles.smallerFontForAllParameters : ''}>
      {rowData.schedulesOn && (
        <div>
          {`${t('VehicleMntSchedules.View.Date')}: ${format(
            rowData.schedulesOn.split('+')[0],
            localization.formats.time.formats.dby
          )}`}
        </div>
      )}
      {parameters.odometer !== undefined && (
        <div className={styles.overflowFont} title={odometerValue}>
          {odometerValue}
        </div>
      )}
      {parameters.engine_hours && (
        <div className={styles.overflowFont} title={engineHoursValue}>
          {engineHoursValue}
        </div>
      )}
      {gpio_meters?.length && parameters[gpio_meters?.[0]] && (
        <div className={styles.overflowFont} title={gpioValue}>
          {gpioValue}
        </div>
      )}
    </div>
  );
};

export const nextDueCellRenderer = ({ rowData, localization, t }) => {
  let parameters = {};
  try {
    parameters = JSON.parse(rowData?.parameters || '{}');
  } catch (e) {
    console.error(e);
  }
  const daysDiff = rowData?.schedulesOn
    ? dateDiffInDays(new Date(), new Date(rowData.schedulesOn.split('+')[0])).toString()
    : '';
  const isNAOdometerValue =
    !!rowData?.vehicle?.config?.isUsingRUCOdometerForMnt && !rowData?.vehicle?.rucDevice;
  const calculatedOdometer = isNAOdometerValue
    ? NoApplicableVehicleOdometer
    : formatOdometer(localization, Number(parameters.odometer) - Number(rowData.vehicle.odometer));
  const calculatedEngineHours = formatEngineHours(
    localization,
    Number(parameters.engine_hours) - Number(rowData.vehicle.engineHours)
  );

  const { gpio_meters } = parameters;
  const assignedMeter = rowData?.vehicle?.meters?.length
    ? rowData.vehicle.meters.find(meter => gpio_meters && meter?.type === gpio_meters?.[0])
    : null;
  const currentReadingGPIO = assignedMeter ? getMeterValue(assignedMeter).toFixed(1) : 0;

  const nextDueGPIO = formatEngineHours(
    localization,
    Number(parameters[gpio_meters?.[0]]) - Number(currentReadingGPIO)
  );

  const areAllParametersShown =
    daysDiff &&
    parameters.odometer !== undefined &&
    parameters.engine_hours &&
    gpio_meters?.[0] &&
    nextDueGPIO;

  const odometerValue =
    parameters.odometer !== undefined &&
    `${t('VehicleMntSchedules.View.Odometer')}: ${calculatedOdometer}`;
  const engineHoursValue =
    parameters.engine_hours &&
    `${t('VehicleMntSchedules.View.EngineHours')}: ${calculatedEngineHours}`;
  const gpioValue =
    gpio_meters?.[0] &&
    nextDueGPIO &&
    `${t('VehicleMntSchedules.Table.ServiceDueGPIOHours', {
      type: t(`Vehicles.GPIO.${gpio_meters}`)
    })}: ${nextDueGPIO}`;

  return (
    <div className={areAllParametersShown ? styles.smallerFontForAllParameters : ''}>
      {daysDiff && <div>{`${t('Common.Days')}: ${daysDiff} ${t('Common.days')}`}</div>}
      {parameters.odometer !== undefined && (
        <div className={styles.overflowFont} title={odometerValue} s>
          {odometerValue}
        </div>
      )}
      {parameters.engine_hours && (
        <div className={styles.overflowFont} title={engineHoursValue}>
          {engineHoursValue}
        </div>
      )}
      {gpio_meters?.[0] && nextDueGPIO && (
        <div className={styles.overflowFont} title={gpioValue}>
          {gpioValue}
        </div>
      )}
    </div>
  );
};

export const cancelReasonRenderer = schedule => {
  if (!schedule.cancellationParameters) {
    return;
  }
  let cancellationParameters = {};
  try {
    cancellationParameters = JSON.parse(schedule.cancellationParameters || '{}');
  } catch (e) {
    console.log(e);
  }
  return cancellationParameters?.reason;
};

export const ellipsisButtonCellRenderer = ({
  rowData,
  handleDeleteAction,
  handleRestoreAction,
  handleRescheduleAction,
  t,
  history,
  dispatch
}) => {
  return (
    <ActionMenu
      items={getActionMenuItems({
        schedule: rowData,
        t,
        history,
        dispatch,
        handleDeleteAction,
        handleRestoreAction,
        handleRescheduleAction
      })}
      data={rowData}
      dataType={'VEHICLEMAINTENANCESCHEDULE'}
    />
  );
};

export const auditsUserCellRenderer = ({ rowData }) => {
  const user = rowData?.user;
  if (!user) return;
  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`;
  return (
    <Link
      to={{
        pathname: `/settings/users/id/${user.id}`,
        state: { pageTitle: fullName }
      }}
    >
      <div>{fullName}</div>
    </Link>
  );
};

export const auditsParametersCellRenderer = ({ rowData, rowIndex, dataKey, parent }) => {
  let parameters = {};
  try {
    parameters = JSON.parse(rowData?.parameters || '{}');
  } catch (e) {
    console.error(e);
  }
  return (
    <CellMeasurer
      cache={parametersCellCache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${rowData.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      <div style={{ paddingBottom: '15px' }}>
        {Object.keys(parameters).map(key => {
          if (key === 'parameters') {
            return (
              <>
                <div style={{ fontWeight: '500' }}>{`${key}:`}</div>
                <div style={{ whiteSpace: 'normal' }}>
                  {`${
                    parameters[key][0] && typeof parameters[key][0] === 'string'
                      ? parameters[key][0].replace(/,/g, ', ')
                      : JSON.stringify(parameters[key][0]).replace(/,/g, ', ')
                  }`}
                  <br /> to <br />
                  {`${
                    parameters[key][1] && typeof parameters[key][1] === 'string'
                      ? parameters[key][1].replace(/,/g, ', ')
                      : JSON.stringify(parameters[key][1]).replace(/,/g, ', ')
                  }`}
                </div>
              </>
            );
          }
          return (
            <>
              <div style={{ fontWeight: '500' }}>{`${key}:`}</div>
              <div>{`${parameters[key][0]} to ${parameters[key][1]}`}</div>
            </>
          );
        })}
      </div>
    </CellMeasurer>
  );
};

export const parametersCellCache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 80
});

export const stringCellRenderer_ANT = string => {
  return (
    <span title={string} className={styles.fourLineEllipsisCell}>
      {string}
    </span>
  );
};

export const VehicleCellRenderer_ANT = ({ rowData }) => {
  if (rowData && rowData.vehicle) {
    const name = rowData.vehicle.name;
    return (
      // <Link
      //   to={{
      //     pathname: `/settings/vehicles/id/${rowData.vehicle?.id}`
      //   }}
      // >
      <span title={name} className={styles.twoLineEllipsisCell} style={{ width: '200px' }}>
        {name}
      </span>
      // </Link>
    );
  }
  return '';
};

export const dateCellRenderer_ANT = ({ date, dateFormat }) => {
  return <div>{date ? format(new Date(date), dateFormat) : '-'}</div>;
};

export const decimalCellRenderer = ({ value, localization, distanceMode }) => {
  if (!value) {
    return <div>0</div>;
  }
  const numValue = parseFloat(value);
  const formattedValue = Number.isInteger(numValue) ? numValue : numValue.toFixed(1);
  return <div>{distanceMode ? localization?.convertDistance(formattedValue) : formattedValue}</div>;
};
