/* global google */
const headings = {
  0: 'N',
  45: 'NE',
  90: 'E',
  135: 'SE',
  180: 'S',
  225: 'SW',
  270: 'W',
  315: 'NW',
  360: 'N'
};

const getNearestDirection = dir => {
  return Math.abs(Math.round(dir / 45) * 45);
};

export const toDirectionString = dir => {
  return dir + '\u00B0 (' + headings[getNearestDirection(dir)] + ')';
};

export const getBoundsZoomLevel = (bounds, mapDim) => {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  function latRad(lat) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
};

export const getBoundsFromLatLng = points => {
  if (!points) return;
  let bounds = new google.maps.LatLngBounds();
  points.forEach(point => {
    const latitude = point.latitude || point.Lat || point.lat;
    const longitude = point.longitude || point.Lng || point.lng;
    if (latitude && longitude) bounds.extend(new google.maps.LatLng(latitude, longitude));
  });
  return bounds;
};

export const getCardinalPointsFromBounds = bounds => {
  if (!bounds) return;
  return {
    north: bounds.getNorthEast().lat(),
    south: bounds.getSouthWest().lat(),
    east: bounds.getNorthEast().lng(),
    west: bounds.getSouthWest().lng()
  };
};

export const isLatLngValid = (lat, lng) => {
  return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
};

const addressLevel = {
  street_address: 0,
  premise: 1,
  locality: 2,
  plus_code: 3
};

function selectAddress(results) {
  let selectedAddress = null;
  let level = 99;

  for (let a of results) {
    if (a.types.includes('premise')) {
      selectedAddress = a;
      level = addressLevel.premise;
    } else if (a.types.includes('street_address')) {
      selectedAddress = a;
      break;
    } else if (a.types.includes('locality') && level >= addressLevel.locality) {
      selectedAddress = a;
      level = addressLevel.locality;
    } else if (a.types.includes('plus_code') && level >= addressLevel.plus_code) {
      selectedAddress = a;
      level = addressLevel.plus_code;
    }
  }
  return selectedAddress;
}

export const getFormattedAddressFromLatLng = latLng => {
  if (!latLng || !isLatLngValid(latLng.lat, latLng.lng)) {
    return new Promise(resolve => resolve(''));
  }
  const geocoder = new google.maps.Geocoder();
  return geocoder
    .geocode({ location: latLng })
    .then(response =>
      response.results.length > 0 ? selectAddress(response.results).formatted_address : ''
    )
    .catch(err => '');
};

export const getPointsDistance = points => {
  const path = points?.filter(point => point.lat && point.lng);
  if (!path.length) {
    return 0;
  }
  const distanceInMeters = google.maps.geometry?.spherical?.computeLength(path);
  const distanceInKM = Number((distanceInMeters / 1000).toFixed(5));
  return distanceInKM;
};

export const getPointsZoomLevel = ({
  points = [],
  defaultZoom = 12,
  mapDim = {
    height: 640,
    width: 620
  }
}) => {
  const bounds = getBoundsFromLatLng(points);
  let zoom;
  if (bounds) {
    zoom = getBoundsZoomLevel(bounds, mapDim);
    zoom = zoom && !isNaN(Number(zoom)) && isFinite(Number(zoom)) ? Number(zoom) : defaultZoom;
  }
  return zoom || defaultZoom;
};

export const setCurrentRegionBounds = (currentRegion, bounds) => {
  try {
    const geocode = JSON.parse(currentRegion.geocode || '{}');

    geocode.bounds &&
      geocode.bounds.forEach(bound => {
        const point = new google.maps.LatLng(...bound);
        bounds.extend(point);
      });
  } catch (e) {
    console.error(e);
  }
};

export const getPathLength = (path, ErrorValue = 'Error') => {
  try {
    return google.maps.geometry.spherical.computeLength(path);
  } catch (error) {
    return ErrorValue;
  }
};

export const getGPSByAddress = async address => {
  if (!address) {
    return { lat: null, lng: null };
  }

  try {
    const geocoder = new google.maps.Geocoder();
    const response = await geocoder.geocode({ address });

    if (response?.results && response?.results?.length > 0) {
      const location = response.results[0].geometry.location;
      return {
        lat: location.lat(),
        lng: location.lng()
      };
    } else {
      return { lat: null, lng: null };
    }
  } catch (error) {
    return { lat: null, lng: null };
  }
};

export const extractCoordinates = gpsString => {
  let result = {
    latitude: '',
    longitude: ''
  };
  if (!gpsString) {
    return result;
  }
  try {
    // Regular expression to match latitude and longitude
    const regex = /Gps\(([^,]*),\s*([^,]*),\s*[^)]*\)/;
    const match = gpsString?.match(regex);
    if (match) {
      const latitude = match[1].trim();
      const longitude = match[2].trim();
      result.latitude =
        latitude !== 'null' && latitude !== '' && latitude !== 'NaN' ? latitude : '';
      result.longitude =
        longitude !== 'null' && longitude !== '' && longitude !== 'NaN' ? longitude : '';
    }
    return result;
  } catch (error) {
    console.error(error);
    return result;
  }
};
