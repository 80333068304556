import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { fetchCompanyDevicePing } from 'features/camera/cameraApi';
import { useLocalization } from 'features/localization/localizationSlice';
import { useCurrentCompanyId } from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';
import { format } from 'utils/dates';

import { VisualizationCard } from './VisualizationCard';
import { HintedSearchInput } from './HintedSearchInput';
import { COLS, DeviceList } from './DeviceList';

const columns = [COLS.DeviceName, COLS.LastPinged, COLS.DaysInactive, COLS.GPSCoord];
const PAGE_SIZE = 10;

export function LiveDeviceActivity({ pageSize = PAGE_SIZE }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localization = useLocalization();
  const currentCompanyId = useCurrentCompanyId();

  const reqCtxRef = useRef({
    request: null
  });

  const [devices, setDevices] = useState(null);
  const [isListLoading, setIsListLoading] = useState(true);
  const [tableParams, setTableParams] = useState({
    limit: pageSize,
    offset: 0,
    searchText: '',
    sortBy: 'lastCommsAt',
    sort: 'asc',
    pagination: {
      current: 1,
      pageSize
    }
  });

  const fetchDevicePing = useCallback(
    force => {
      if (!currentCompanyId) {
        return;
      }
      const ctxParams = {
        companyId: currentCompanyId,
        limit: tableParams.pagination.pageSize,
        offset: (tableParams.pagination.current - 1) * tableParams.pagination.pageSize,
        search: tableParams.searchText,
        sortBy: tableParams.sortBy,
        sort: tableParams.sort
      };

      const onReqDone = error => {
        if (reqCtxRef.current.request) {
          reqCtxRef.current.request.abort();
        }
        reqCtxRef.current.request = null;
        reqCtxRef.current.fetchTime = moment().valueOf();
        reqCtxRef.current.error = error || null;
        reqCtxRef.current.ctxParams = ctxParams;
        setIsListLoading(false);
      };

      let reqCtx = reqCtxRef.current;
      if (force || !reqCtx?.fetchTime) {
        if (reqCtx.request) {
          reqCtx.request.abort();
          reqCtx.request = null;
        }
        reqCtx.ctxParams = ctxParams;
        reqCtx.request = dispatch(
          fetchCompanyDevicePing({
            ...ctxParams,
            onSuccess: devicePing => {
              onReqDone();
              setTableParams({
                ...tableParams,
                pagination: {
                  ...tableParams.pagination,
                  total: devicePing?.totalRecords || 0
                }
              });
              setDevices(devicePing?.devices || []);
            },
            onError: errMsg => {
              onReqDone(errMsg);
              setDevices([]);
              setTableParams({
                limit: pageSize,
                offset: 0,
                totalRecords: 0,
                searchText: '',
                sortBy: 'lastCommsAt',
                sort: 'asc',
                pagination: {
                  current: 1,
                  pageSize
                }
              });
            }
          })
        );
        const fetchData = async () => {
          try {
            setIsListLoading(true);
            await reqCtx.request;
          } catch (e) {
            onReqDone(e);
            dispatch(
              openToast({
                type: ToastType.Error,
                message: t('CameraHealth.FetchError', {
                  error: parseErrorMessage(e),
                  title: t('CameraHealth.Live Device Activity')
                })
              })
            );
          }
        };
        fetchData();
      }
    },
    [t, dispatch, reqCtxRef, currentCompanyId, tableParams, pageSize]
  );

  const filterOptions = useMemo(
    () => [
      {
        key: 'searchFilter',
        id: 'liveActivitySearch',
        component: (
          <HintedSearchInput
            hint={t('CameraHealth.SearchHint')}
            placeholder={t('Devices.ActualForm.NamePlaceholder')}
            loading={isListLoading}
            onSearch={text =>
              setTableParams(params => ({
                ...params,
                pagination: {
                  ...params?.pagination,
                  current: 1,
                  pageSize
                },
                searchText: text || ''
              }))
            }
          />
        )
      }
    ],
    [t, isListLoading, pageSize]
  );

  const tableData = useMemo(
    () =>
      devices?.map(d => ({
        deviceId: d.deviceId,
        deviceName: d.deviceName,
        formatedLastCommsAt: d.lastCommsAt
          ? format(moment(d.lastCommsAt).toDate(), localization.formats.time.formats.dby_imp)
          : '',
        lastCommsAt: d.lastCommsAt,
        gps: d.gps
      })),
    [devices, localization?.formats.time.formats.dby_imp]
  );

  useEffect(() => {
    fetchDevicePing(true);
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams.sort,
    tableParams.sortBy,
    tableParams.searchText
  ]);

  const handleListChange = (pagination, filter, sorter) =>
    setTableParams(prev => ({
      ...prev,
      pagination,
      sort: sorter.order === 'ascend' ? 'desc' : 'asc',
      sortBy: sorter.field
    }));

  return (
    <VisualizationCard
      loading={!reqCtxRef.current.fetchTime}
      title={t('CameraHealth.Device Ping')}
      titleExtra={
        <div>
          <Tooltip title={t('Home.Refresh')}>
            <Button
              style={{ paddingTop: 4 }}
              type="text"
              icon={
                <ReloadOutlined style={{ color: '#64748B', fontSize: '20px' }} color="#9cca55" />
              }
              onClick={() => {
                let tableParamsChanged = false;
                setTableParams(params => {
                  tableParamsChanged = params.pagination.current !== 1;
                  return {
                    ...params,
                    pagination: {
                      ...params?.pagination,
                      current: 1,
                      pageSize
                    }
                  };
                });
                if (!tableParamsChanged) {
                  fetchDevicePing(true);
                }
              }}
              disabled={isListLoading}
            ></Button>
          </Tooltip>
        </div>
      }
      content={
        <DeviceList
          filterOptions={filterOptions}
          isListLoading={isListLoading}
          devices={tableData}
          columns={columns}
          pagination={tableParams.pagination}
          onChange={handleListChange}
        />
      }
    />
  );
}
