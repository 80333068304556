import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { AgreementTable } from './AgreementTable';
import { setBackButton, setPageTitle, addBreadcrumbs } from 'features/page/pageSlice';

import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { PATHS } from 'containers/Configuration/CompanyConfig/utils/constants';
import useDebounce from 'utils/hooks/useDebounce';
import { prepareDataForMultiselect } from 'utils/filters';
import styles from './CompanyAgreement.module.scss';
import { CompanyAgreementCopyModal } from './CompanyAgreementCopyModal';
import {
  executeDeleteCompanyAgreementsAction,
  useDeleteCompanyAgreementsMutation,
  useGetCompanyAgreementsQuery
} from 'services/nextgen/ngAgreementApi';
import { useCurrentCompany, useCurrentParentCompanies } from 'features/company/companySlice';
import { Button } from 'antd';
import { ParentCompanyAgreementCopyModal } from './ParentCompanyAgreementCopyModal';
import { FeatureFlag, useCan } from 'features/permissions';
import { PATHS as CompanyAgreementPaths } from './constants';
import { useHistory } from 'react-router';
import GlobalRoles from 'features/permissions/GlobalRoles';

export const CompanyAgreement = () => {
  const { t } = useTranslation();
  const [filterText, setFilterText] = useState('');
  const can = useCan();
  const history = useHistory();
  const debouncedSearchText = useDebounce(filterText, 300);
  const [filterAgreementData, setFilterAgreementData] = useState([]);
  const [filterSubscription, setFilterSubscription] = useState();
  const [showCopyAgreement, setShowCopyAgreement] = useState(false);
  const [showCopyParentAgreement, setShowCopyParentAgreement] = useState(false);
  const [selectedAgreement, setSelectedAgreement] = useState({});
  const currentParentCompanies = useCurrentParentCompanies();
  const [deleteCompanyAgreements] = useDeleteCompanyAgreementsMutation();

  const dispatch = useDispatch();
  const currentCompany = useCurrentCompany();

  const { data: agreementData, isFetching } = useGetCompanyAgreementsQuery(
    {
      id: currentCompany?.id,
      embed: 'devices'
    },
    { skip: currentCompany?.id === undefined }
  );

  useEffect(() => {
    dispatch(setPageTitle(t('CompanyConfig.CompanyAgreement.Title')));
    dispatch(setBackButton(false));

    dispatch(
      addBreadcrumbs([
        {
          breadcrumbName: t('CompanyConfig.Title'),
          path: PATHS.COMPANY_CONFIG
        },
        {}
      ])
    );

    return () => {
      dispatch(addBreadcrumbs([]));
    };
  }, [dispatch, t]);

  useEffect(() => {
    if (agreementData && !isFetching) {
      let filteredAgreement = agreementData;

      if (debouncedSearchText) {
        filteredAgreement = filteredAgreement.filter(
          i =>
            i.workOrder.toLowerCase()?.includes(debouncedSearchText?.toLowerCase()?.trim()) ||
            i.description?.toLowerCase()?.includes(debouncedSearchText.toLowerCase().trim()) ||
            i.agreements.some(a =>
              a.subscriptionPack.name
                ?.toLowerCase()
                ?.includes(debouncedSearchText.toLowerCase().trim())
            )
        );
      }

      if (filterSubscription) {
        const checkedsubscriptionIds = filterSubscription
          .filter(sub => sub.checked)
          .map(sub => parseInt(sub.id, 10));

        filteredAgreement = filteredAgreement.filter(i =>
          (i.agreements || []).some(a => checkedsubscriptionIds.includes(a.subscriptionPack.id))
        );
      }

      setFilterAgreementData(filteredAgreement);
    }
  }, [agreementData, debouncedSearchText, filterSubscription, isFetching]);

  useEffect(() => {
    if (agreementData && !isFetching) {
      let fullSubscriptions = agreementData
        .reduce(
          (acc, obj) => [...acc, ...(obj.agreements || []).filter(i => i.status === 'ENABLED')],
          []
        )
        .map(i => i.subscriptionPack);
      let uniqueSubscriptions = fullSubscriptions.filter(
        (item, index, self) => index === self.findIndex(t => t.id === item.id)
      );

      const orderedSubscriptions = (uniqueSubscriptions || []).sort((a, b) =>
        (a?.name || '').localeCompare(b?.name || '')
      );

      setFilterSubscription(
        prepareDataForMultiselect(
          orderedSubscriptions,
          t('CompanyConfig.CompanyAgreement.AllSubscriptionPack'),
          null
        )
      );
    }
  }, [agreementData, isFetching]);

  const handleAgreementAction = data => {
    if (data.action === 'copyAgreement') {
      setSelectedAgreement(data.data);
      setShowCopyAgreement(true);
    } else if (data.action === 'deleteAgreement') {
      executeDeleteCompanyAgreementsAction(
        { companyId: data.data.companyId, agreementId: data.data.agreements[0].id },
        deleteCompanyAgreements,
        dispatch
      );
    } else if (data.action === 'assignAgreement') {
      history.push(CompanyAgreementPaths.AGREEMENT_ASSIGN, { agreement: data.data });
    }
  };

  return (
    <>
      {currentParentCompanies.length > 1 &&
        can({
          oneOfRoles: [GlobalRoles.SubCompanyAdmin]
        }) && (
          <div className={styles.headerActions}>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                setShowCopyParentAgreement(true);
              }}
            >
              {t('CompanyConfig.CompanyAgreement.CopyAgreement')}
            </Button>
          </div>
        )}
      <div className={styles.listPanel}>
        <FilterWrapper>
          <AntSearchbar onFilter={value => setFilterText(value)} />
          <AntMultiselect
            title={
              filterSubscription?.some(value => !value.checked)
                ? t('CompanyConfig.CompanyAgreement.SubscriptionPack')
                : t('CompanyConfig.CompanyAgreement.AllSubscriptionPack')
            }
            onFilter={v => setFilterSubscription(v)}
            data={filterSubscription || []}
          />
        </FilterWrapper>
        <label className={styles.filterPanelLabel}>
          {filterAgreementData.length}{' '}
          {filterAgreementData.length === 1
            ? `${t('CompanyConfig.CompanyAgreement.Agreement')}`
            : `${t('CompanyConfig.CompanyAgreement.Agreements')}`}
        </label>
      </div>
      <div
        style={{
          display: 'flex',
          flex: '1 0 0',
          flexDirection: 'column'
        }}
      >
        <div>
          <AgreementTable
            agreementData={filterAgreementData}
            loading={isFetching}
            handleAction={handleAgreementAction}
          />
        </div>
      </div>
      <CompanyAgreementCopyModal
        isOpen={showCopyAgreement}
        agreement={selectedAgreement}
        onClose={() => {
          setShowCopyAgreement(false);
        }}
      />
      <ParentCompanyAgreementCopyModal
        isOpen={showCopyParentAgreement}
        agreement={selectedAgreement}
        onClose={() => {
          setShowCopyParentAgreement(false);
        }}
      />
    </>
  );
};
