import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GOOGLE_MAPS_API_KEY } from 'config';

import { Row, Col, Alert } from 'antd';
import { LoadingTable } from 'components/grid/LoadingTable';
import JourneyMap from './JourneyMap';
import RoutePreview from './RoutePreview';
import FormTitle from 'components/form/form-title/FormTitle';
import InfoRow from 'components/form/info-row/InfoRow';

import {
  getJourneyById,
  deleteJourneyApi,
  restoreJourneyApi
} from 'features/journeyPlanner/journeysSlice';
import { useUserKey } from 'features/user/userSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import Header from 'components/header/Header';

import { entities } from 'features/permissions';
import { secondsToHHMMSS, getIDFromPathname, insertIf } from 'utils/methods';
import { journeyHelpers } from './helpers';
import { JOURNEY_TYPE, getJourneyType } from './constants';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';

import styles from './JourneyPlanner.module.scss';

const JourneyPlannerView = ({ journeys, localization, companies, fleets, users, containerRef }) => {
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const dispatch = useDispatch();
  const history = useHistory();
  const userKey = useUserKey();
  const company = useCurrentCompany();
  const [journey, setJourney] = useState(null);
  const [waypoints, setWaypoints] = useState(null);
  const [journeyGeofences, setJourneyGeofences] = useState([]);
  const [mapDirections, setMapDirections] = useState(null);
  const hasManualDragEdits = waypoints?.some(waypoint => waypoint.stopover === false);
  const journeyPlannerFoldersFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.journeyPlannerFolders.flag
  });

  const journeyMapFormRef = useRef(null);
  const handleMapDirectionsChange = useMemo(
    () => ({
      onNoDirection() {
        setMapDirections(null);
      },
      onDirectionsSucceed: setMapDirections,
      onDirectionError() {
        //adjust map zoom level by visibleMarkers
        journeyHelpers.adjustMapWithWaypoints(journeyMapFormRef, waypoints);
      }
    }),
    [journeyMapFormRef, waypoints]
  );

  useEffect(() => {
    if (id && userKey) {
      getJourneyById(id, userKey, company.id).then(journey => {
        const filteredStops = journeyHelpers.getFilteredStops(journey.stops);
        const geofences = journey.stops.reduce((acc, stop) => {
          stop.geofence && acc.push({ ...stop.geofence });
          return acc;
        }, []);

        setJourney(journey);

        //build the directions by attr or stops/waypoints
        if (journey.attr) {
          const directionsRequest = JSON.parse(journey.attr);
          if (directionsRequest.waypoints?.length) {
            const newWaypoints = [...filteredStops];
            directionsRequest.waypoints.forEach((waypt, index) => {
              if (waypt.stopover === false) {
                newWaypoints.splice(index + 1, 0, { ...waypt, stopover: false });
              }
            });
            setWaypoints(newWaypoints);
          } else {
            setWaypoints(filteredStops);
          }
        } else {
          setWaypoints(filteredStops);
        }

        if (geofences) {
          setJourneyGeofences(geofences);
        }

        dispatch(setPageTitle(journey.name));
        dispatch(setBackButton(true));
      });
    }
  }, [id, journeys, dispatch, userKey, company.id]);

  if (!journey) {
    return <LoadingTable columnSizes={[400]} />;
  }

  const getCreatedBy = journey => {
    if (!users.length || !journey?.createdBy?.id) return;
    const user = users?.find(user => user.id === journey.createdBy.id);
    if (!user) return;
    return `${user.firstName} ${user.lastName}`;
  };

  const getAssociatedFleets = () => {
    if (!fleets.length || !journey.tripAssociations) return;
    const allFleets = [...fleets].concat({ id: -1, name: t('Common.NoFleet') });
    const renderFleet = journey?.tripAssociations.map(
      trip => allFleets?.find(fleet => fleet.id === trip.fleetId) || {}
    );
    if (!renderFleet) return;
    return (
      <>
        {renderFleet.map(fleet => {
          return (
            <div key={`${journey.id}-${fleet.id}`}>
              {journeyHelpers.renderFleetsName(fleet, companies)}
            </div>
          );
        })}
      </>
    );
  };

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        dispatch(deleteJourneyApi(journey, history));
        break;
      case 'restore':
        dispatch(restoreJourneyApi(journey));
        break;
      default:
    }
  };

  const handleExportToPdf = () => {
    window.open(`/journeyplanner/export/id/${id}`, '_blank');
  };

  const handleEditButton = () => {
    history.push(`/journeyplanner/edit/id/${id}`);
  };

  return (
    <>
      <Header
        containerRef={containerRef}
        primaryButton={{
          name: t('Common.Edit'),
          canEveryEntities: [entities.TRIP_UPDATE],
          onClick: handleEditButton
        }}
        defaultMoreMenu={{
          showAudits: true,
          showRestore: true,
          showDelete: true,
          data: { entityName: AUDIT_ENTITY.JOURNEY, ...journey },
          entity: entities.TRIP,
          typeOfEntityToDelete: t('Common.journey'),
          handleButtonAction: handleButtonAction
        }}
        moreMenuExtraItems={[
          ...insertIf(journey.stopsCount > 1, {
            name: t(`Common.EllipsisButton.ExportToPdf`),
            onClick: handleExportToPdf
          })
        ]}
      ></Header>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 32px',
          color: '#181c21'
        }}
      >
        <FormTitle title={t('JourneyPlanner.View.Details')} underlined />
        <InfoRow label={t('JourneyPlanner.View.Name')} value={journey.name} styles={styles} />
        <InfoRow
          label={t('JourneyPlanner.View.Type')}
          value={getJourneyType(journey.type)}
          styles={styles}
        />
        <InfoRow
          label={t('Common.Company')}
          value={journeyHelpers.getCompanyName(companies, journey.companyId)}
          styles={styles}
        />
        <InfoRow
          label={t('JourneyPlanner.View.PlannedDuration')}
          value={secondsToHHMMSS(journey.estimatedDuration)}
          styles={styles}
        />
        {journey.type === JOURNEY_TYPE.TYPE.ROUTE_COMPLIANCE && (
          <InfoRow
            label={t('JourneyPlanner.View.AssociatedFleets')}
            value={getAssociatedFleets()}
            styles={styles}
          />
        )}
        {journeyPlannerFoldersFlag && (
          <InfoRow
            label={t('JourneyPlanner.Folders')}
            value={
              journey?.folders
                ? journey?.folders
                    ?.map(f => f?.name)
                    ?.sort()
                    ?.join(', ')
                : ''
            }
            styles={styles}
          />
        )}
        <InfoRow
          label={t('Common.CreatedAt')}
          value={journeyHelpers.format_dby_imp(journey, 'createdAt', localization)}
          styles={styles}
        />
        <InfoRow
          label={t('Common.UpdatedAt')}
          value={journeyHelpers.format_dby_imp(journey, 'updatedAt', localization)}
          styles={styles}
        />
        <InfoRow label={t('Common.CreatedBy')} value={getCreatedBy(journey)} styles={styles} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 32px',
          color: '#181c21'
        }}
      >
        <div className={styles.formMapContainer}>
          <Row className={styles.routeWrapper}>
            <Col className={styles.routeLeft}>
              <RoutePreview
                waypoints={waypoints}
                localization={localization}
                readonly
                mapDirections={mapDirections || null}
              />
            </Col>
            <Col className={styles.routeRight}>
              {hasManualDragEdits && (
                <Alert
                  showIcon
                  type="warning"
                  message={t('JourneyPlanner.Form.AlertRouteChanged')}
                />
              )}
              <JourneyMap
                ref={journeyMapFormRef}
                isMarkerShown
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`}
                loadingElement={<div className={styles.journeyMapLoadingElement} />}
                containerElement={<div className={styles.journeyMapContainerElement} />}
                mapElement={<div className={styles.journeyMapElement} />}
                waypoints={waypoints}
                geofences={journeyGeofences}
                {...handleMapDirectionsChange}
                readonly
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default JourneyPlannerView;
