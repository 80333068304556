import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import i18n from 'i18next';
import ImageDocument from './ImageDocument';
import { formatOdometer } from 'features/meters';
import { format } from 'utils/dates';
import {
  findGPIOData,
  findValueByTitle,
  getMultiCameraIQDetails,
  InfoRowWithLink,
  SubTypesEnum
} from './InstallationAuditUtility';
import style from './InstallationLog.module.scss';

const styles = StyleSheet.create({
  page: {
    padding: 20
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000000',
    marginTop: 5,
    marginBottom: 5,
    textAlign: 'left',
    paddingBottom: 5
  },
  subTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#707070',
    textAlign: 'left',
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 5
  },
  table: {
    display: 'table',
    width: '100%',
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#DEE3E4'
  },
  tableRow: {
    display: 'table-row',
    flexDirection: 'row'
  },
  tableCell: {
    display: 'table-cell',
    borderTopWidth: 1,
    borderTopColor: '#DEE3E4',
    padding: 5,
    textAlign: 'left',
    width: '50%'
  },
  leftColumn: {
    color: '#000000',
    fontSize: 12,
    borderRightWidth: 1,
    borderRightColor: '#DEE3E4'
  },
  rightColumn: {
    color: '#707070',
    fontSize: 12
  },
  lastCell: {
    borderBottomWidth: 1,
    borderBottomColor: '#DEE3E4'
  }
});

const InstallationGalleryDetailsPage = ({ title, t, viewData }) => (
  <Page style={styles.page}>
    <Text style={styles.title}>{title}</Text>
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <Text style={[styles.tableCell, styles.leftColumn]}>
          {i18n.t(`SupportToolsELD.InstallationLogsColumns.Vehicle`)}
        </Text>
        <Text style={[styles.tableCell, styles.rightColumn]}>{viewData?.vehicle?.name || ''}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={[styles.tableCell, styles.leftColumn]}>
          {i18n.t(`SupportToolsELD.InstallationLogsColumns.Company`)}
        </Text>
        <Text style={[styles.tableCell, styles.rightColumn]}>{viewData?.company?.name || ''}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={[styles.tableCell, styles.leftColumn]}>
          {i18n.t(`SupportToolsELD.InstallationLogsColumns.Fleet`)}
        </Text>
        <Text style={[styles.tableCell, styles.rightColumn]}>
          {viewData?.fleets && viewData.fleets.length > 0
            ? viewData.fleets.map(f => f.name)?.join(', ')
            : ''}
        </Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={[styles.tableCell, styles.leftColumn]}>
          {i18n.t(`SupportToolsELD.InstallationLogsColumns.Engineer`)}
        </Text>
        <Text style={[styles.tableCell, styles.rightColumn]}>{viewData?.user?.name || ''}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={[styles.tableCell, styles.leftColumn]}>
          {i18n.t(`SupportToolsELD.InstallationLogsColumns.WorkOrder`)}
        </Text>
        <Text style={[styles.tableCell, styles.rightColumn]}>
          {viewData?.vehicle?.workorder || ''}
        </Text>
      </View>
      <View style={[styles.tableRow]}>
        <Text style={[styles.tableCell, styles.leftColumn]}>
          {i18n.t(`SupportToolsELD.InstallationLogsColumns.OperationType`)}
        </Text>
        <Text style={[styles.tableCell, styles.rightColumn]}>
          {viewData?.action === 'Test' ? 'Validate' : viewData?.action || ''}
        </Text>
      </View>
    </View>
  </Page>
);

const InstallationViewDetailsPage = ({
  title,
  t,
  supportType,
  localization,
  viewData,
  testResults
}) => {
  const gpioData = findGPIOData(testResults, localization);
  const multiCameraIQValues = getMultiCameraIQDetails(testResults);
  return (
    <Page style={styles.page}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsColumns.Vehicle`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {viewData?.vehicle?.name || ''}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsColumns.Company`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {viewData?.company?.name || ''}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsColumns.Fleet`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {viewData?.fleets && viewData.fleets.length > 0
              ? viewData.fleets.map(f => f.name)?.join(', ')
              : ''}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsColumns.Engineer`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>{viewData?.user?.name || ''}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsColumns.WorkOrder`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {viewData?.vehicle?.workorder || ''}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
            {i18n.t(`SupportToolsELD.InstallationLogsColumns.OperationType`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
            {viewData?.action === 'Test' ? 'Validate' : viewData?.action || ''}
          </Text>
        </View>
        <Text style={styles.subTitle}>
          {i18n.t(`SupportToolsELD.InstallationLogsView.OperationDescription`)}:
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.DateAndTime`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {format(viewData?.createdAt, localization.formats.time.formats.dby_imp) || ''}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsColumns.OperationType`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {viewData?.action === 'Test' ? 'Validate' : viewData?.action || ''}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsColumns.OperationStatus`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>{viewData?.status || ''} </Text>
        </View>
        {viewData?.action?.toUpperCase() === SubTypesEnum.TEST ? (
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.leftColumn]}>
              {i18n.t(`SupportToolsELD.InstallationLogsColumns.OperationSubtype`)}
            </Text>
            <Text style={[styles.tableCell, styles.rightColumn]}>{viewData?.subType || ''}</Text>
          </View>
        ) : (
          ''
        )}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsColumns.Engineer`)}{' '}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>{viewData?.user?.name || ''} </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsColumns.IMEI`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>{viewData?.device?.imei || ''}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.RegistrationOrLicence`)}{' '}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {viewData?.vehicle?.registration || ''}{' '}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsColumns.Vehicle`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {viewData?.vehicle?.name || ''}
          </Text>
        </View>
        {viewData && viewData?.action?.toUpperCase() === SubTypesEnum.INSTALL && (
          <>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftColumn]}>
                {i18n.t(`SupportToolsELD.InstallationLogsColumns.Make`)}
              </Text>
              <Text style={[styles.tableCell, styles.rightColumn]}>
                {viewData?.vehicle?.make || ''}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftColumn]}>
                {i18n.t(`SupportToolsELD.InstallationLogsColumns.Model`)}
              </Text>
              <Text style={[styles.tableCell, styles.rightColumn]}>
                {viewData?.vehicle?.model || ''}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftColumn]}>
                {i18n.t(`SupportToolsELD.InstallationLogsColumns.Odometer`)}
              </Text>
              <Text style={[styles.tableCell, styles.rightColumn]}>
                {formatOdometer(localization, viewData?.vehicle?.odometer) || ''}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
                {i18n.t(`SupportToolsELD.InstallationLogsColumns.EngineHours`)}
              </Text>
              <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
                {viewData?.vehicle?.engineHours || ''}
              </Text>
            </View>
          </>
        )}
        {viewData && viewData?.action?.toUpperCase() === SubTypesEnum.REPLACE && (
          <>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftColumn]}>
                {i18n.t(`SupportToolsELD.InstallationLogsColumns.ReplacedIMEI`)}
              </Text>
              <Text style={[styles.tableCell, styles.rightColumn]}>
                {viewData?.oldDevice?.oldDeviceImei || ''}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftColumn]}>
                {i18n.t(`SupportToolsELD.InstallationLogsColumns.Odometer`)}
              </Text>
              <Text style={[styles.tableCell, styles.rightColumn]}>
                {formatOdometer(localization, viewData?.vehicle?.odometer) || ''}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
                {i18n.t(`SupportToolsELD.InstallationLogsColumns.EngineHours`)}
              </Text>
              <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
                {viewData?.vehicle?.engineHours || ''}
              </Text>
            </View>
          </>
        )}
        <Text style={styles.subTitle}>
          {i18n.t(`SupportToolsELD.InstallationLogsView.OdometerEngineHours`)}:
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.DateAndTime`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {format(viewData?.createdAt, localization.formats.time.formats.dby_imp) || ''}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsColumns.Odometer`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {formatOdometer(localization, viewData?.vehicle?.odometer) || ''}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
            {i18n.t(`SupportToolsELD.InstallationLogsColumns.EngineHours`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
            {viewData?.vehicle?.engineHours || ''}
          </Text>
        </View>
        <Text style={styles.subTitle}>
          {i18n.t(`SupportToolsELD.InstallationLogsView.AccelerometerLastCalibrated`)}:
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.DateAndTime`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {format(viewData?.createdAt, localization.formats.time.formats.dby_imp) || ''}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.Accelerometer`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>{''}</Text>
        </View>
        <Text style={styles.subTitle}>
          {i18n.t(`SupportToolsELD.InstallationLogsView.VPMInformation`)}:
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.Charge`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'VPM Information: Charge', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.EngineLoad`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'VPM Information: Engineload', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.FuelConsumption`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'VPM Information: Fuelconsumption', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.FuelLevel`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'VPM Information: Fuellevel', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.Gear`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'VPM Information: Gear', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.GPS`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'VPM Information: Gps', localization)?.value?.At}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.Hours`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'VPM Information: Hours', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.ID`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'VPM Information: Id', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.MalfunctionIndicatorLightActive`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'VPM Information: Mil', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.Odometer`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {formatOdometer(
              localization,
              findValueByTitle(testResults, 'VPM Information: Odometer', localization).title
            )}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.OilPressure`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'VPM Information: Oilpressure', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.OilTemp`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'VPM Information: Oiltemp', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.RPM`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'VPM Information: Rpm', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.Speed`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
            {localization.formatSpeed(
              findValueByTitle(testResults, 'VPM Information: Speed', localization).title
            )}
          </Text>
        </View>
        <Text style={styles.subTitle}>
          {i18n.t(`SupportToolsELD.InstallationLogsView.LastCommDetails`)}:
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.LastCommValue`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'General: Last Comm', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.LastCommStatus`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'General: Last Comm', localization).status}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.LastCommDetails`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
            {findValueByTitle(testResults, 'General: Last Comm', localization).subtitle}
          </Text>
        </View>
        <Text style={styles.subTitle}>
          {i18n.t(`SupportToolsELD.InstallationLogsView.IgnitionDetails`)}:
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.IgnitionValue`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'General: Ignition', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.IgnitionStatus`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'General: Ignition', localization).status}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.IgnitionDetails`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
            {findValueByTitle(testResults, 'General: Ignition', localization).subtitle}
          </Text>
        </View>
        <Text style={styles.subTitle}>
          {i18n.t(`SupportToolsELD.InstallationLogsView.DriverDetails`)}:
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.DriverValue`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'General: Driver', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.DriverStatus`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'General: Driver', localization).status}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.DriverDetails`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
            {findValueByTitle(testResults, 'General: Driver', localization).subtitle}
          </Text>
        </View>
        <Text style={styles.subTitle}>
          {i18n.t(`SupportToolsELD.InstallationLogsView.GPSDetails`)}
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.GPSTimeValue`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'GPS: GPS Time', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.GPSTimeStatus`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'GPS: GPS Time', localization).status}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.GPSTimeDetails`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'GPS: GPS Time', localization).subtitle}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.GPSPositionValue`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'GPS: GPS Position', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.GPSPositionDetails`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'GPS: GPS Position', localization).subtitle}
          </Text>
        </View>
        {/* <View style={styles.tableRow}>
        <Text style={[styles.tableCell, styles.leftColumn]}>{i18n.t(`SupportToolsELD.InstallationLogsView.DriverDetails`)}</Text>
        <Text style={[styles.tableCell, styles.rightColumn]}>{findValueByTitle(testResults, 'General: Driver', localization).subtitle}</Text>
      </View> */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.GPSHDOPSATValue`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'GPS: GPS HDOP/SAT', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.GPSHDOPSATStatus`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'GPS: GPS HDOP/SAT', localization).status}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.GPSHDOPSATDetails`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
            {findValueByTitle(testResults, 'GPS: GPS HDOP/SAT', localization).subtitle}
          </Text>
        </View>
        <Text style={styles.subTitle}>
          {i18n.t('SupportToolsELD.InstallationLogsView.ValidationDetails', { title: 'GPIO' })}:
        </Text>
        {gpioData.length > 0 ? (
          gpioData.map((gpio, index) => (
            <>
              <View style={styles.tableRow} key={index}>
                <Text style={[styles.tableCell, styles.leftColumn]}>
                  {i18n.t('SupportToolsELD.InstallationLogsView.GPIOTitle', { title: gpio.title })}
                </Text>
                <Text style={[styles.tableCell, styles.rightColumn]}>{gpio.value || ''}</Text>
              </View>
              <View style={styles.tableRow} key={index}>
                <Text style={[styles.tableCell, styles.leftColumn]}>
                  {i18n.t('SupportToolsELD.InstallationLogsView.GPIODetails', {
                    title: gpio.title
                  })}
                </Text>
                <Text style={[styles.tableCell, styles.rightColumn]}>{gpio.subtitle || ''}</Text>
              </View>
            </>
          ))
        ) : (
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
              {i18n.t(`SupportToolsELD.InstallationLogsView.GPIOvalidationnotcompleted`)}
            </Text>
            <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}></Text>
          </View>
        )}
        <Text style={styles.subTitle}>
          {i18n.t('SupportToolsELD.InstallationLogsView.ValidationDetails', { title: 'Satellite' })}
          :
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.LastSatelliteTimeTitle', {
              title: t('Common.Value')
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'Satellite: Last Satellite Time', localization).title}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.LastSatelliteTimeTitle', {
              title: t('Common.Status')
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'Satellite: Last Satellite Time', localization).status}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.LastSatelliteTimeTitle', {
              title: t('Common.Details')
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {findValueByTitle(testResults, 'Satellite: Last Satellite Time', localization).subtitle}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.SatelliteValidationStatus`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
            {findValueByTitle(testResults, 'Satellite Event', localization).title}
          </Text>
        </View>
        <Text style={styles.subTitle}>
          {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTabletDetails', {
            title: 'Tablet (ELD)'
          })}
          :
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
              title: t('Common.Value')
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {
              findValueByTitle(testResults, 'Driver ID Validation: Tablet (ELD)', localization)
                .title
            }
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
              title: t('Common.Status')
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {
              findValueByTitle(testResults, 'Driver ID Validation: Tablet (ELD)', localization)
                .status
            }
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
              title: t('Common.Details')
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
            {
              findValueByTitle(testResults, 'Driver ID Validation: Tablet (ELD)', localization)
                .subtitle
            }
          </Text>
        </View>
        <Text style={styles.subTitle}>
          {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTabletDetails', {
            title: 'Tablet (Non-ELD)'
          })}
          :
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
              title: t('Common.Value')
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {
              findValueByTitle(testResults, 'Driver ID Validation: Tablet (Non-ELD)', localization)
                .title
            }
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
              title: t('Common.Status')
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {
              findValueByTitle(testResults, 'Driver ID Validation: Tablet (Non-ELD)', localization)
                .status
            }
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
              title: t('Common.Details')
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
            {
              findValueByTitle(testResults, 'Driver ID Validation: Tablet (Non-ELD)', localization)
                .subtitle
            }
            :
          </Text>
        </View>
        <Text style={styles.subTitle}>
          {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTabletDetails', {
            title: '(DID3)'
          })}
          :
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
              title: t('Common.Value')
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {
              findValueByTitle(
                testResults,
                'Driver ID Validation: Key Fob/PIN Pad/RFID Card',
                localization
              ).title
            }
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
              title: 'Status'
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {
              findValueByTitle(
                testResults,
                'Driver ID Validation: Key Fob/PIN Pad/RFID Card',
                localization
              ).status
            }
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
              title: t('Common.Details')
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
            {
              findValueByTitle(
                testResults,
                'Driver ID Validation: Key Fob/PIN Pad/RFID Card',
                localization
              ).subtitle
            }
          </Text>
        </View>
        <Text style={styles.subTitle}>
          {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTabletDetails', {
            title: 'IQ Camera'
          })}
          :
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
            {i18n.t(`SupportToolsELD.InstallationLogsView.IQCameraValidationStatus`)}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
            {InfoRowWithLink({
              value: findValueByTitle(testResults, 'IQ Camera Validation:', localization).title,
              styles: style
            })}
          </Text>
        </View>
        <Text style={styles.subTitle}>
          {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTabletDetails', {
            title: 'Multi-IQ Camera'
          })}
          :
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.MultiIQCameraValidationTV', { index: 1 })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {InfoRowWithLink({
              value: multiCameraIQValues.TV1,
              styles: style
            })}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn, styles.lastCell]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.MultiIQCameraValidationTV', { index: 2 })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn, styles.lastCell]}>
            {InfoRowWithLink({
              value: multiCameraIQValues.TV2,
              styles: style
            })}
          </Text>
        </View>
        <Text style={styles.subTitle}>
          {i18n.t('SupportToolsELD.InstallationLogsView.DriverIDValidationTabletDetails', {
            title: 'Temperature Sensor'
          })}
          :
        </Text>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.TemperatureSensorValidationTitle', {
              title: t('Common.Value')
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {
              findValueByTitle(
                testResults,
                'Temperature Sensor Data: Last Temperature',
                localization
              ).title
            }
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.TemperatureSensorValidationTitle', {
              title: t('Common.Status')
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {
              findValueByTitle(
                testResults,
                'Temperature Sensor Data: Last Temperature',
                localization
              ).status
            }
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.leftColumn]}>
            {i18n.t('SupportToolsELD.InstallationLogsView.TemperatureSensorValidationTitle', {
              title: t('Common.Details')
            })}
          </Text>
          <Text style={[styles.tableCell, styles.rightColumn]}>
            {
              findValueByTitle(
                testResults,
                'Temperature Sensor Data: Last Temperature',
                localization
              ).subtitle
            }
          </Text>
        </View>
      </View>
    </Page>
  );
};

export const InstallationGalleryDocument = ({ imageBlobs, localization, title, t, viewData }) => {
  return (
    <Document>
      <InstallationGalleryDetailsPage title={title} viewData={viewData} t={t} />
      <ImageDocument imageBlobs={imageBlobs} localization={localization} title={title} />
    </Document>
  );
};

export const InstallationViewDocument = ({
  imageBlobs,
  localization,
  title,
  t,
  viewData,
  supportType,
  testResults
}) => {
  return (
    <Document>
      <InstallationViewDetailsPage
        title={title}
        viewData={viewData}
        t={t}
        localization={localization}
        supportType={supportType}
        testResults={testResults}
      />
      {imageBlobs.length > 0 && (
        <ImageDocument imageBlobs={imageBlobs} localization={localization} title={title} />
      )}
    </Document>
  );
};
