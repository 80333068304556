import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Space } from 'antd';
import { Button } from 'antd';
// import { formatDiginData, formatDigoutData } from './helpers';

export const ASSETS_KEYS = [
  'asset.selection',
  'movement.inuse',
  'gpio.channel',
  'gpio.status',
  'rpm.over',
  'rpm.min'
];

export const PATHS = {
  ASSETS_DEFAULT: '/settings/company_config/assetsusage',
  ASSETS_CONFIGURED: '/settings/company_config/assetsusage/configured',
  ASSETS_CONFIGURED_ASSIGNED: '/settings/company_config/assetsusage/configured/assigned',
  ASSETS_CONFIGURED_UNASSIGNED: '/settings/company_config/assetsusage/configured/unassigned',
  ASSETS_DELETED: '/settings/company_config/assetsusage/deleted',
  ASSETS_ADD: '/settings/company_config/assetsusage/new',
  ASSETS_ADD_REFACTOR: '/settings/company_config/assetsusage/new_refactor',
  ASSETS_EDIT: '/settings/company_config/assetsusage/edit/id',
  ASSETS_EDIT_REFACTOR: '/settings/company_config/assetsusage/edit_refactor/id',
  ASSETS_AUDIT: '/settings/company_config/assetsusage/audit/id',
  ASSETS_VIEW: '/settings/company_config/assetsusage/id'
};
