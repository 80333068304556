import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import {
  useCurrentCompany,
  useCurrentParentCompanies,
  useIsFetchingCompany
} from 'features/company/companySlice';
import { Button, Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import styles from './CompanyAgreementCopyModal.module.scss';
import {
  executeAddCompanyAgreementsAction,
  useAddCompanyAgreementsMutation,
  useGetCompanyAgreementsQuery
} from 'services/nextgen/ngAgreementApi';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';

export const ParentCompanyAgreementCopyModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const parentCompanies = useCurrentParentCompanies();
  const isFetchingCompany = useIsFetchingCompany();
  const [agreementCopyForm] = Form.useForm();
  const [addCompanyAgreements] = useAddCompanyAgreementsMutation();
  const currentCompany = useCurrentCompany();
  const [agreementDataList, setAgreementDataList] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const formValues = Form.useWatch([], agreementCopyForm);
  const company = Form.useWatch('company', agreementCopyForm);
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const topMostParentCompany = (parentCompanies || []).find(
    i => i.id !== currentCompany.id && i.parent?.id === undefined
  );

  const { data: agreementData, isFetching: isFetchingAgreement } = useGetCompanyAgreementsQuery(
    {
      id: company,
      embed: 'devices'
    },
    { skip: company === undefined }
  );

  useEffect(() => {
    if (!isFetchingAgreement) {
      setAgreementDataList(
        (agreementData || []).map(e => {
          return {
            id: e.workOrder,
            name: e.workOrder,
            description: e.description,
            workOrder: e.workOrder,
            quantity: undefined,
            children: [
              ...[
                {
                  id: e.workOrder + '_subHeader',
                  workOrder: e.workOrder,
                  name: t('Agreement.SubscriptionPack'),
                  billingCode: t('Agreement.BillingCode'),
                  description: t('Agreement.Description'),
                  quantity: t('Agreement.CopyAgreementModal.Quantity'),
                  header: true
                }
              ],
              ...e.agreements.map(a => {
                return {
                  id: a.id,
                  workOrder: e.workOrder,
                  name: a.subscriptionPack.name,
                  billingCode: a.subscriptionPack.billingCode,
                  description: a.subscriptionPack.description,
                  quantity: a.quantity,
                  child: true
                };
              })
            ]
          };
        })
      );
    }
  }, [agreementData, isFetchingAgreement]);

  useEffect(() => {
    if (parentCompanies && isOpen) {
      agreementCopyForm.setFieldValue('company', topMostParentCompany?.id);
    } else if (!isOpen) {
      agreementCopyForm.resetFields();
      setSelectedRowKey([]);
    }
  }, [parentCompanies, isOpen]);

  const handleFormValueChange = () => {
    setIsFormDirty(agreementCopyForm.isFieldsTouched());
  };

  const handleResetFields = () => {
    setIsFormDirty(false);
    setIsFormValid(false);
    agreementCopyForm.resetFields();
  };

  const closeModal = () => {
    handleResetFields();
    onClose();
  };

  const onCancel = () => {
    isFormDirty || ['agreements'].some(value => !!agreementCopyForm.getFieldValue(value))
      ? confirmationModal(
          t('WiFi.confirmTitle'),
          t('WiFi.confirmDescription'),
          t('Common.Modal.CancelChanges'),
          t('Common.Modal.Stay'),
          closeModal
        )
      : closeModal();
  };

  const footer = () => {
    return (
      <>
        <Button
          type="primary"
          disabled={!isFormValid}
          onClick={() => {
            agreementCopyForm.submit();
          }}
          htmlType="submit"
        >
          {t('Agreement.CopyParentAgreementModal.CopyAgreement')}
        </Button>
        <Button onClick={onCancel}>{t('Common.CancelButton')}</Button>
      </>
    );
  };

  const columns = [
    { title: '', width: '1%' },
    { title: '', dataIndex: 'id', key: 'id', width: '4%', render: () => '' },
    Table.SELECTION_COLUMN,
    {
      title: t('Agreement.WorkOrder'),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      render: (e, r) => {
        if (r.header) {
          return <strong>{e}</strong>;
        }
        return e;
      }
    },
    {
      title: t('Agreement.Description'),
      dataIndex: 'description',
      key: 'description',
      width: '35%',
      onCell: (r, index) => {
        return {
          colSpan: r.children !== undefined && r.children.length !== 0 ? 2 : 1
        };
      },
      render: (e, r) => {
        if (r.header) {
          return <strong>{e}</strong>;
        }
        return e;
      }
    },
    {
      title: t(''),
      dataIndex: 'billingCode',
      key: 'billingCode',
      width: '33.5%',
      render: (e, r) => {
        if (r.header) {
          return <strong>{e}</strong>;
        }
        return e;
      }
    },
    {
      title: '',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      render: (e, r) => {
        if (r.header) {
          return <strong>{e}</strong>;
        } else if (r.quantity === undefined) {
          return <></>;
        } else {
          return (
            <Form.Item
              name={'quantity_' + r.id}
              colon={false}
              className={styles.tableInput}
              initialValue={r.quantity}
              rules={[
                {
                  validator: (_, value) => {
                    const num = Number(value);

                    if (!value) {
                      return Promise.reject(t('Agreement.CopyAgreementModal.QuantityRequired'));
                    }

                    if (!Number.isInteger(num)) {
                      return Promise.reject(
                        t('Agreement.CopyAgreementModal.QuantityIntegerRequired')
                      );
                    }

                    if (num < 0) {
                      return Promise.reject(
                        t('Agreement.CopyAgreementModal.QuantityMin', {
                          quantity: 0
                        })
                      );
                    }
                    if (num > r.quantity) {
                      return Promise.reject(
                        t('Agreement.CopyAgreementModal.QuantityMax', {
                          quantity: r.quantity
                        })
                      );
                    }

                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input />
            </Form.Item>
          );
        }
      },
      width: '6.5%'
    }
  ];

  useEffect(() => {
    if (isOpen) {
      agreementCopyForm
        .validateFields({ validateOnly: true })
        .then(() => setIsFormValid(true))
        .catch(() => setIsFormValid(false));
    }
  }, [agreementCopyForm, formValues, isOpen]);

  return (
    <Modal
      title={t('Agreement.CopyParentAgreementModal.Title')}
      open={isOpen}
      width={'80%'}
      centered={true}
      onCancel={onCancel}
      footer={footer()}
      destroyOnClose={true}
      wrapClassName={styles.copyAgreementModal}
    >
      <Form
        layout="vertical"
        form={agreementCopyForm}
        name="agreementCopyForm"
        onValuesChange={handleFormValueChange}
        onFinish={() => {
          const selectedAgreements = agreementCopyForm.getFieldValue('agreements');

          const newAgreements = agreementData
            .filter(i => i.agreements.find(k => selectedAgreements.includes(k.id)) !== undefined)
            .map(a => {
              return {
                companyId: currentCompany.id,
                enabledAt: a.enabledAt,
                expiresAt: a.expiresAt,
                contractEndAt: a.contractEndAt,
                contractStartAt: a.contractStartAt,
                description: a.description,
                workOrder: a.workOrder,
                agreements: (a.agreements || {})
                  .filter(k => selectedAgreements.includes(k.id))
                  .map(k => {
                    return {
                      quantity: parseInt(agreementCopyForm.getFieldValue('quantity_' + k.id)),
                      price: k.price,
                      subscriptionPack: { id: k.subscriptionPack.id }
                    };
                  })
              };
            });
          executeAddCompanyAgreementsAction(
            {
              companyId: currentCompany.id,
              agreement: newAgreements,
              companyName: currentCompany.name
            },
            addCompanyAgreements,
            dispatch
          ).then(e => {
            onClose();
          });
        }}
      >
        <Row gutter={[64, 0]}>
          <Col xs={24} md={8}>
            <Form.Item
              name="company"
              labelAlign={'left'}
              label={t(`Agreement.CopyAgreementModal.ParentCompany`)}
              colon={false}
            >
              <Select
                name="companySelect"
                showSearch={true}
                filterOption={(input, option) =>
                  option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                }
                disabled={true}
                placeholder={t('Agreement.CopyAgreementModal.SelectParentCompany')}
                options={(parentCompanies || [])
                  .filter(i => i.id !== currentCompany.id)
                  .map(e => {
                    return { value: e.id, label: e.name };
                  })}
                loading={isFetchingCompany}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="agreements"
              labelAlign={'left'}
              colon={false}
              rules={[
                {
                  required: true,
                  message: t('Agreement.CopyAgreementModal.AgreementRequired')
                }
              ]}
            >
              <Table
                loading={isFetchingAgreement}
                columns={columns}
                rowKey={'id'}
                tableLayout={'fixed'}
                rowClassName={record => {
                  if (record.child) {
                    return 'childRow';
                  } else if (record.header) {
                    return 'childHeaderRow';
                  }
                }}
                expandable={{
                  expandIcon: ({ expanded, onExpand, record }) => {
                    if (record.children && record.children?.length !== 0) {
                      if (expanded) {
                        return (
                          <a
                            style={{ color: '#000' }}
                            onClick={e => {
                              onExpand(record, e);
                            }}
                          >
                            <i className={`tn-i-chevron-up`} />
                          </a>
                        );
                      } else {
                        return (
                          <a
                            style={{ color: '#000' }}
                            onClick={e => {
                              onExpand(record, e);
                            }}
                          >
                            <i className={`tn-i-chevron-right`} />
                          </a>
                        );
                      }
                    }
                  },
                  rowExpandable: record => record.children && record.children?.length !== 0
                }}
                rowSelection={{
                  ...{
                    onChange: (selectedRowKeys, selectedRows) => {
                      agreementCopyForm.setFieldValue(
                        'agreements',
                        selectedRows
                          .filter(i => i.quantity !== undefined && !isNaN(Number(i.quantity)))
                          .map(i => parseInt(i.id))
                      );

                      const selectedChildRow = selectedRows.filter(i => i.child);
                      const fullSelectedKey = [
                        ...selectedChildRow,
                        ...selectedRows.filter(
                          i => i.header && selectedChildRow.some(j => j.workOrder == i.workOrder)
                        )
                      ].map(i => i.id);
                      setSelectedRowKey(fullSelectedKey);
                    },
                    selectedRowKeys: selectedRowKey,
                    checkStrictly: false,
                    getCheckboxProps: record => {
                      if (record.header) {
                        return {
                          className: 'hideCheckbox'
                        };
                      }
                    }
                  }
                }}
                dataSource={agreementDataList}
                pagination={false}
                scroll={{ y: 600 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
