import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Footage } from './Footage';
import { FootagePerRow, SortInitValue, snapshotsSorter } from '../helpers';
import styles from '../Snapshots.module.scss';

function _PositionedFootages({
  t,
  snapshotYear,
  snapshotMonth,
  footageSize: [width, height],
  footageHints,
  footages,
  isDeviceFailure = false,
  isDailyMode = false,
  sortBy = null
}) {
  const sortedFootages = useMemo(
    () =>
      snapshotsSorter(
        Object.values(footages).filter(footage => !!footage),
        !isDailyMode && sortBy?.item === SortInitValue.item
          ? sortBy?.order || SortInitValue.order
          : null
      ),
    [footages, sortBy]
  );
  return (
    <WithCarousel
      width={width}
      slidesToShow={
        isDailyMode
          ? Math.min(sortedFootages.length, FootagePerRow.DailyMode)
          : FootagePerRow.Default
      }
      totalSlides={sortedFootages.length}
    >
      {sortedFootages.map(footage => (
        <div
          key={footage.cameraPosition}
          style={{ width, height, minWidth: width, minHeight: height }}
        >
          <Footage
            t={t}
            hints={footageHints}
            camPosition={footage.cameraPosition}
            year={snapshotYear}
            month={snapshotMonth}
            footage={footage}
            isDeviceFailure={isDeviceFailure}
          />
        </div>
      ))}
    </WithCarousel>
  );
}

const WithCarousel = ({ width, totalSlides, slidesToShow, children }) => {
  const slides = useRef();
  const [index, setIndex] = useState(0);

  const { showPrev, showNext } = useMemo(() => {
    const canSlide = slidesToShow < totalSlides;
    return {
      canSlide,
      showPrev: canSlide && index > 0,
      showNext: canSlide && index + slidesToShow < totalSlides
    };
  }, [slidesToShow, totalSlides, index]);

  const onSlide = useCallback(
    dir => () => {
      let idx = index;
      const transX = i => {
        const marginX = 16;
        slides.current.style.transform = 'translateX(' + i * -(width + marginX) + 'px)';
      };
      if (dir === 'next') {
        idx++;
        transX(idx);
        setIndex(idx);
      } else if (dir === 'prev') {
        idx--;
        transX(idx);
        setIndex(idx);
      }
    },
    [index, slides, width]
  );

  return (
    <div className={styles.carousel}>
      <Button
        type="text"
        className={`${styles.icon} ${styles.prev} ${showPrev ? styles.show : styles.hide}`}
        onClick={onSlide('prev')}
        icon={<FontAwesomeIcon icon={faChevronLeft} color="#86b341" />}
      />
      <div className={styles.track}>
        <div className={styles.slides} ref={slides}>
          {children}
        </div>
      </div>
      <Button
        type="text"
        className={`${styles.icon} ${styles.next} ${showNext ? styles.show : styles.hide}`}
        onClick={onSlide('next')}
        icon={<FontAwesomeIcon icon={faChevronRight} color="#86b341" />}
      />
    </div>
  );
};

export const PositionedFootages = React.memo(_PositionedFootages);
