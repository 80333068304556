import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import React from 'react';
import { prepareAuthHeaders } from 'services/util';
import { useMemo } from 'react';
import { API_PATH } from 'config';
import { useCurrentCompany } from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';
import { parseErrorMessage } from 'utils/strings';
import { ToastType } from 'components/notifications/toasts/Toast';
import { Trans } from 'react-i18next';
import i18n from 'i18nextConfig';

export const ngAssetConfigurationApi = createApi({
  reducerPath: 'ngAssetConfigurationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_PATH}`,
    prepareHeaders: prepareAuthHeaders
  }),
  tagTypes: ['assetConfigurationTemplate'],
  endpoints: builder => ({
    getGpioChannels: builder.query({
      query: () => {
        return {
          url: `configuration/ASSETSUSAGE/gpio/list`
        };
      }
    }),
    getAssetConfigurationTemplateById: builder.query({
      query: ({ templateId, embed }) => {
        return {
          url: `configuration/ASSETSUSAGE/template/${templateId}`,
          params: {
            embed: embed
          }
        };
      },
      async onQueryStarted(query, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedData } = await queryFulfilled;
          dispatch(
            ngAssetConfigurationApi.util.updateQueryData(
              'getAssetConfigurationTemplateById',
              query,
              cache => {
                cache.dataFormatted = true;
                cache.configurationTemplate.configurations?.forEach(c => {
                  c.jsonValue = JSON.parse(c.value);
                });
              }
            )
          );
        } catch {}
      },
      providesTags: ['assetConfigurationTemplate']
    }),
    getAssetDeviceSelectId: builder.query({
      query: ({ companyId, embed, deviceId }) => {
        return {
          url: `configuration/device/${deviceId}/template`,
          params: {
            company_id: companyId,
            configType: 'ASSETS_USAGE'
          }
        };
      },
      providesTags: ['assetConfigurationTemplate']
    }),
    getAssetValues: builder.query({
      query: ({ deviceId }) => {
        return {
          url: `configuration/values`,
          params: {
            device_id: deviceId,
            service: 'ASSETSUSAGE'
          }
        };
      },
      providesTags: ['assetConfigurationTemplate']
    }),
    getAssetValuesByCompany: builder.query({
      query: ({ companyId }) => {
        return {
          url: `configuration/devices/values`,
          params: {
            company_id: companyId,
            service: 'ASSETSUSAGE'
          }
        };
      },
      async onQueryStarted(query, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedData } = await queryFulfilled;
          dispatch(
            ngAssetConfigurationApi.util.updateQueryData(
              'getAssetValuesByCompany',
              query,
              cache => {
                cache.dataFormatted = true;
                cache.configurationTemplate.configurations?.forEach(c => {
                  c.jsonValue = JSON.parse(c.value);
                });
              }
            )
          );
        } catch {}
      },
      providesTags: ['assetConfigurationTemplate']
    }),
    getAssetConfigurationTemplates: builder.query({
      query: ({ companyId, embed }) => ({
        url: `configuration/ASSETSUSAGE/template`,
        params: {
          company_id: companyId,
          embed: embed
        }
      }),
      providesTags: ['assetConfigurationTemplate']
    }),
    addAssetConfigurationTemplate: builder.mutation({
      query: ({ body }) => ({
        url: `configuration/ASSETSUSAGE/template`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['assetConfigurationTemplate']
    }),
    updateAssetConfigurationTemplate: builder.mutation({
      query: ({ id, body }) => ({
        url: `configuration/ASSETSUSAGE/template/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['assetConfigurationTemplate']
    }),
    copyAssetConfigurationTemplate: builder.mutation({
      query: payload => {
        const { id, companyIds } = payload;
        let url = '/configuration/ASSETSUSAGE/template/' + id + '/copy';

        return {
          url,
          method: 'POST',
          body: { targetCompanyIds: companyIds },
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        };
      },
      invalidatesTags: ['assetConfigurationTemplate']
    }),
    deleteAssetConfigurationTemplateById: builder.mutation({
      query: payload => {
        const { id } = payload;

        return {
          url: `configuration/ASSETSUSAGE/template/${id}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: (result, error, arg) => {
        return error ? [] : ['assetConfigurationTemplate'];
      }
    }),
    restoreAssetConfigurationTemplateById: builder.mutation({
      query: payload => {
        const { id } = payload;

        return {
          url: `configuration/ASSETSUSAGE/template/${id}/restore`,
          method: 'PUT'
        };
      },
      invalidatesTags: ['assetConfigurationTemplate']
    }),
    selectedConfigurationTemplate: builder.mutation({
      query: ({ companyId, templateId, id }) => ({
        url: `configuration/ASSETSUSAGE/device/${id}`,
        params: {
          company_id: companyId,
          template_id: templateId
        },
        method: 'PUT' // Correct placement for the HTTP method
      }),
      invalidatesTags: ['assetConfigurationTemplate']
    }),
    settingValueForOneDevice: builder.mutation({
      query: ({ companyId, id }) => ({
        url: `configuration/ASSETSUSAGE/device/${id}`,
        params: {
          company_id: companyId
        },
        method: 'PUT' // Correct placement for the HTTP method
      }),
      invalidatesTags: ['assetConfigurationTemplate']
    }),
    saveTheSettingValues: builder.mutation({
      query: ({ body, companyId }) => ({
        params: {
          company_id: companyId
        },
        url: `configuration/values`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['assetConfigurationTemplate']
    })
  })
});

export const {
  useGetAssetConfigurationTemplatesQuery,
  useGetAssetDeviceSelectIdQuery,
  useGetAssetValuesQuery,
  useGetAssetValuesByCompanyQuery,
  useGetAssetConfigurationTemplateByIdQuery,
  useAddAssetConfigurationTemplateMutation,
  useUpdateAssetConfigurationTemplateMutation,
  useCopyAssetConfigurationTemplateMutation,
  useRestoreAssetConfigurationTemplateByIdMutation,
  useDeleteAssetConfigurationTemplateByIdMutation,
  useSelectedConfigurationTemplateMutation,
  useSettingValueForOneDeviceMutation,
  useSaveTheSettingValuesMutation,
  useGetGpioChannelsQuery
} = ngAssetConfigurationApi;

export const useAssetConfigurations = (getActiveOnly = true) => {
  const currentCompany = useCurrentCompany();
  const { data: assetConfigurations, isFetching } = useGetAssetConfigurationTemplatesQuery(
    { companyId: currentCompany?.id },
    { skip: currentCompany?.id === undefined }
  );

  return useMemo(() => {
    let configurationTemplates = (assetConfigurations || []).map(i => i.configurationTemplate);

    if (getActiveOnly) {
      configurationTemplates = configurationTemplates.filter(i => i.status === 'ENABLED');
    }

    return { data: configurationTemplates, isFetching };
  }, [currentCompany?.id, isFetching]);
};

export const useCurrentCompanyServicesMap = () => {
  const currentCompany = useCurrentCompany();

  return useMemo(() => {
    const serviceMap = currentCompany.features.reduce((map, service) => {
      map[service.code] = service;
      return map;
    }, {});
    return serviceMap;
  }, [currentCompany?.id]);
};

export const executeRestoreAssetConfigurationTemplate = (
  payload,
  restoreAssetConfigurationTemplateById,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    restoreAssetConfigurationTemplateById(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          dispatch,
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ConfigurationRestored', {
            name: payload.name
          }),
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

export const executeDeleteAssetConfigurationTemplate = (
  payload,
  deleteAssetConfigurationTemplate,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    deleteAssetConfigurationTemplate(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          dispatch,
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ConfigurationDeleted', {
            name: payload.name
          }),
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

export const executeSetCompanyAssetConfigurationTemplate = (
  payload,
  updateAssetConfigurationTemplate,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    updateAssetConfigurationTemplate(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          dispatch,
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Success', {
            name: payload.name
          }),
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

export const executeCopyAssetConfigurationTemplate = (
  payload,
  copyAssetConfigurationTemplate,
  companies,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    copyAssetConfigurationTemplate(payload)
      .unwrap()
      .then(data => {
        const companyIds = data.companyIds;
        const companyName = companies
          .filter(i => companyIds.find(k => k === i.id))
          .map(i => i.name)
          .join(', ');

        if (data.totalError !== 0) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: data?.errors
                ? data?.errors.map((i, index) => {
                    const errorCompany = companies.find(k => k.id === i.companyId);
                    return (
                      <React.Fragment key={index}>
                        {errorCompany.name + ' : ' + i.message}
                        {<br />}
                      </React.Fragment>
                    );
                  })
                : parseErrorMessage(data.data)
            })
          );
          reject(data);
        } else {
          handleSuccessResult(
            dispatch,
            <Trans
              values={{ companies: companyName }}
              i18nKey={
                'CompanyConfig.DeviceConfigurations.AssetsCopyModal.GpioTemplateClonedResponseMessage'
              }
              components={{
                1: <span style={{ color: '#003a8c' }} />
              }}
            />,
            resolve
          );
        }
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

const handleException = (dispatch, error, reject) => {
  if (error) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseErrorMessage(error.data)
      })
    );
  }

  if (reject) {
    reject(error);
  }
};

const handleSuccessResult = (dispatch, msg, resolve) => {
  dispatch(
    openToast({
      type: ToastType.Success,
      message: msg
    })
  );

  if (resolve) {
    resolve();
  }
};
