import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import styles from '../../VehicleMaintenance/VehicleMaintenanceSchedules.module.scss';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { BUTTON_IDS } from 'utils/globalConstants';

export const InspectionSummaryToolbar = ({
  isFetching,
  filteredInspectionsCount,
  searchText,
  fleetsFilter,
  driversFilter,
  vehiclesFilter,
  daysFilter,
  statusFilter,
  distanceFilter,
  engineHoursFilter,
  passedFilter,
  failedFilter,
  onSearchChange,
  onFleetsFilterChange,
  onVehiclesFilterChange,
  onDriversFilterChange,
  onDaysFilterChange,
  onStatusFilterChange,
  onDistanceFilterChange,
  onEngineHoursFilterChange,
  onPassedFilterChange,
  onFailedFilterChange,
  onRestoreDefault
}) => {
  const { t } = useTranslation();
  const timeoutHandler = useRef(-1);

  const handleSearchChange = useCallback(value => {
    if (timeoutHandler.current !== -1) {
      clearTimeout(timeoutHandler.current);
      timeoutHandler.current = -1;
    }
    timeoutHandler.current = setTimeout(() => {
      if (onSearchChange) {
        onSearchChange(value);
      }
      timeoutHandler.current = -1;
    }, 100);
  }, []);

  return (
    <div className={styles.filterWrapper}>
      <FilterWrapper>
        <AntSearchbar defaultValue={searchText} onFilter={handleSearchChange} />
        <AntMultiselect
          title={
            vehiclesFilter?.some(value => !value.checked)
              ? t('Common.Vehicles')
              : t('Common.AllVehicles')
          }
          data={vehiclesFilter}
          onFilter={onVehiclesFilterChange}
        />
        <AntMultiselect
          title={
            fleetsFilter?.some(value => !value.checked) ? t('Common.Fleets') : t('Common.AllFleets')
          }
          data={fleetsFilter}
          onFilter={onFleetsFilterChange}
        />
        <AntMultiselect
          title={
            driversFilter?.some(value => !value.checked)
              ? t('Common.Drivers')
              : t('Common.AllDrivers')
          }
          data={driversFilter}
          onFilter={onDriversFilterChange}
        />
        <AntMultiselect
          title={daysFilter?.some(value => !value.checked) ? t('Common.Days') : t('Common.AllDays')}
          data={daysFilter}
          onFilter={onDaysFilterChange}
        />
        <AntMultiselect
          title={
            statusFilter?.some(value => !value.checked)
              ? t('InspectionSummary.Statuses')
              : t('Common.AllStatuses')
          }
          data={statusFilter}
          onFilter={onStatusFilterChange}
        />
        <AntMultiselect
          title={t('InspectionSummary.DistanceDriven')}
          data={distanceFilter}
          onFilter={onDistanceFilterChange}
        />
        <AntMultiselect
          title={t('InspectionSummary.EngineHours')}
          data={engineHoursFilter}
          onFilter={onEngineHoursFilterChange}
        />
        <AntMultiselect
          title={t('InspectionSummary.Completed')}
          data={passedFilter}
          onFilter={onPassedFilterChange}
        />
        <AntMultiselect
          title={t('InspectionSummary.Failed')}
          data={failedFilter}
          onFilter={onFailedFilterChange}
        />
        <Tooltip title={t('InspectionSummary.ResetFilter')} placement="rightTop">
          <Button
            type="secondary"
            size="large"
            onClick={onRestoreDefault}
            id={BUTTON_IDS.btn_inspectionSummaryRestoreDefault}
          >
            <FontAwesomeIcon icon={faRedo} style={{ color: '#64748b' }} />
          </Button>
        </Tooltip>
      </FilterWrapper>
      <div>
        <div className={styles.documentsCount}>
          {(!isFetching ? filteredInspectionsCount : 0) + ' ' + t('InspectionSummary.Records')}
        </div>
      </div>
    </div>
  );
};
