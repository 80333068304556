import React, { useState, useEffect, useCallback } from 'react';
import request from 'superagent';
import { sortBy, toLower } from 'lodash';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { useFleets } from 'features/fleets/fleetsSlice';
import {
  updateAssociations,
  uploadFileSuccess,
  clearEditFiles,
  addInitialValuesForEdit,
  setDirtyForm,
  deleteDocuments,
  changeDocumentStep
} from 'features/easydocs/documentsSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { fetchFolders, deleteFolder } from 'features/easydocs/foldersSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { Can, entities, useCanOneOfEntities } from 'features/permissions';
import { useSubCompanies, useCompanies } from 'features/company/companySlice';

import FormInput from 'components/form/form-input/FormInput';
import { ToastType } from 'components/notifications/toasts/Toast';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { AssociationsModal } from './AssociationsModal';
import { FormModal } from './FormModal/FormModal';
import { DocumentsTable } from './Documents/DocumentsTable';
import { DocumentsToolbar } from './Documents/DocumentsToolbar';

import { prepareDataForMultiselect } from 'utils/filters';
import { format } from 'utils/dates';
import { REGEXPS } from '../../utils/regex';

import { API_PATH } from 'config';
import { buttonStyle, folderlessFolderFile } from './constants';

import './ContentFolder.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { generateAssociations } from './AssociationsModal/util';

const FilterIds = {
  AllCompanies: 0,
  AllFleets: 0,
  NoCompany: -1,
  NoFleet: -1
};

const ContentFolder = ({
  activeFolder,
  setEditFolderName,
  company,
  userKey,
  documents,
  setTableRef,
  foldersForForms
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const subCompanies = useSubCompanies();
  const [modalData, setModalData] = useState({
    showModalDeleteFolders: false,
    showModalDeleteDocuments: false,
    showModalAssociations: false,
    showModalEdit: false
  });

  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [companiesFilter, setCompaniesFilter] = useState([]);
  const [fleetsFilter, setFleetsFilter] = useState([]);
  const localization = useLocalization();
  const fleets = useFleets('', 'APP');
  const companies = useCompanies();
  const FLEET_SELECTION = '1fleet';
  const VEHICLE_SELECTION = '2vehicle';
  const DEVICE_SELECTION = '3device';

  const [hierarchyAssociations, setHierarchyAssociations] = useState([]);
  useEffect(() => {
    const sortedFleets = sortBy(fleets, [
      function(fleet) {
        return toLower(fleet.name);
      }
    ])
      //Filter the fleets that belong to the current company and the standalone devices and vehicles
      .filter(
        fleet =>
          (fleet.company && fleet.company.id === company.id) || (fleet.devices && fleet.vehicles)
      );

    //The standalone vehicles and devices are the first in the sorted array of fleets. We want them to be the last.
    if (sortedFleets.length) {
      sortedFleets.push(sortedFleets.shift());
    }

    const hierarchy = sortedFleets.map(fleet => {
      const companyName = companies?.find(company => fleet.company?.id === company.id)?.name;
      return {
        hierarchyId: 'fleetId',
        title: `${fleet && fleet.id ? fleet.name : 'No Fleet'} ${
          companyName ? `(${companyName})` : ''
        }`,
        key: `${FLEET_SELECTION}-${fleet.id || 'No Fleet'}`,
        treeData: (fleet.vehicles || []).reduce((acc, vehicle) => {
          if (
            vehicle.id &&
            (vehicle.companyId === company.id ||
              subCompanies.some(i => i.id === vehicle.companyId)) &&
            (!vehicle.devices ||
              (vehicle.devices &&
                vehicle.devices.find(
                  device =>
                    device.type && device.type.features && device.type.features.includes('CAB')
                )))
          ) {
            const companyName = companies?.find(company => vehicle.companyId === company.id)?.name;
            acc.push({
              hierarchyId: 'vehicleId',
              title: `${
                vehicle.devices && vehicle.devices.length ? vehicle.name : `${vehicle.name}*`
              } ${companyName ? `(${companyName})` : ''}`,
              key: `${VEHICLE_SELECTION}-${fleet.id || 'No Fleet'}-${vehicle.id}`,
              treeData: []
            });
          } else if (vehicle.devices) {
            vehicle.devices.forEach(device => {
              if (
                (device.companyId === company.id ||
                  subCompanies.some(i => i.id === device.companyId)) &&
                device.type &&
                device.type.features &&
                device.type.features.includes('CAB')
              ) {
                const companyName = companies?.find(company => device.companyId === company.id)
                  ?.name;
                acc.push({
                  hierarchyId: 'deviceId',
                  title: `${device.name} ${companyName ? `(${companyName})` : ''}`,
                  key: `${DEVICE_SELECTION}-${fleet.id || 'No Fleet'}-${device.id}`,
                  treeData: []
                });
              }
            });
          }
          return sortBy(acc, [
            function(vehicleOrDevice) {
              return toLower(vehicleOrDevice.title);
            }
          ]);
        }, [])
      };
    });

    // get vehicle with unassigned devices
    setHierarchyAssociations(hierarchy);
  }, [fleets, company.id]);

  const [hierarchyWithCheckedStatus, setHierarchyWithCheckedStatus] = useState([]);
  const handleCheck = (hierarchy, formDirty) => {
    setHierarchyWithCheckedStatus(hierarchy);
    if (typeof formDirty === 'boolean') {
      dispatch(setDirtyForm(formDirty));
    }
  };

  const saveUpdateAssociations = () => {
    // For EACH selected documented, we create an association item to be sent in the docPayload
    const docPayload = checkedDocuments.reduce((prev, current) => {
      return [...prev, ...generateAssociations(current, hierarchyWithCheckedStatus)];
    }, []);

    setEditFolderName(activeFolder.name);
    dispatch(updateAssociations(docPayload));
    dispatch(setDirtyForm(false));
    hideModal();
  };

  const handleSearchChange = useCallback(searchText => {
    setSearchText(searchText);
  }, []);

  const handleCompaniesFilterChange = useCallback(companiesFilter => {
    setCompaniesFilter(companiesFilter);
  }, []);

  const handleFleetsFilterChange = useCallback(fleetsFilter => {
    setFleetsFilter(fleetsFilter);
  }, []);

  const updateFolder = () => (e, field) => {
    if (
      (activeFolder[field] !== e.target.value && e.type === 'blur') ||
      e.which === 13 ||
      e.keyCode === 13
    ) {
      if (!e.target.value.length && field === 'name') {
        e.target.value = activeFolder.name;
        dispatch(
          openToast({
            type: ToastType.Error,
            message: `The Name field for the folder is required.`,
            autohide: true
          })
        );
        return;
      } else {
        const payload =
          field === 'name'
            ? { name: e.target.value, description: activeFolder.description }
            : { name: activeFolder.name, description: e.target.value };
        const existingName = foldersForForms
          .filter(folder => folder.name !== activeFolder.name)
          .find(folder => folder.name === payload.name);
        if (existingName) {
          e.target.value = activeFolder.name;
          dispatch(
            openToast({
              type: ToastType.Error,
              message: `The name for the folder already exists.`,
              autohide: true
            })
          );
          return;
        }
        request('PUT', API_PATH + `/folders/${activeFolder.id}?company_id=${company?.id}`)
          .set('Authorization', `Token token="${userKey}"`)
          .set('Content-Type', 'application/json')
          .send(payload)
          .then(() => {
            setEditFolderName(payload.name);
            if (activeFolder.name === 'New Folder' && payload.name !== activeFolder.name) {
              dispatch(
                openToast({
                  type: ToastType.Success,
                  message: `The New Folder has been updated with the name ${payload.name}.`
                })
              );
            }
            dispatch(fetchFolders());
          })
          .catch(err => {
            // actions.setSubmitting(false);
            console.warn('ERROR', err);
          });
      }
    }
  };

  const changeFolderName = callback => newValue => {
    newValue = newValue.trimStart();
    if (!newValue.length) {
      callback('name', '');
    }
    if (newValue.length && newValue[newValue.length - 1].match(REGEXPS.folderEasydocs)) {
      callback('name', newValue);
    }
  };

  const changeFolderDescription = callback => newValue => {
    newValue = newValue.trimStart();
    if (!newValue.length) {
      callback('description', '');
    }
    if (newValue.length && newValue[newValue.length - 1].match(REGEXPS.folderEasydocs)) {
      callback('description', newValue);
    }
  };

  //Checked documents in table
  const [checkedDocumentIds, setCheckedDocuments] = useState([]);
  const [namesCheckedDocuments, setNameCheckedDocuments] = useState([]);
  const [checkedDocuments, setCheckedDocumentsArray] = useState([]);

  useEffect(() => {
    setCheckedDocuments([]);
    setHierarchyWithCheckedStatus([]);
  }, [activeFolder, documents]);

  useEffect(() => {
    const checkedDocuments = documents.filter(doc => doc.id && checkedDocumentIds.includes(doc.id));
    const nameDocuments = checkedDocuments.map(doc => doc.name || doc.filename);
    setCheckedDocumentsArray(checkedDocuments);
    setNameCheckedDocuments(nameDocuments);
  }, [checkedDocumentIds, documents, modalData.showModalAssociations]);

  // useEffect for setting up filter lists based on documents list content
  useEffect(() => {
    let companiesList = [];
    let fleetsList = [];

    if (documents) {
      // Get lists of all unique companies and fleets from the documents list
      documents.forEach(document => {
        if (document.company) {
          document.company.forEach(company => {
            if (company && !companiesList.find(c => c.id === company.id)) {
              companiesList.push({ id: company.id, name: company.name });
            }
          });
        }

        if (document.fleet) {
          document.fleet.forEach(fleet => {
            if (fleet && !fleetsList.find(f => f.id === fleet.id)) {
              fleetsList.push({ id: fleet.id, name: fleet.name });
            }
          });
        }
      });

      // sort all the lists by name
      companiesList = sortBy(companiesList, [item => item.name.toLowerCase()]);
      fleetsList = sortBy(fleetsList, [item => item.name.toLowerCase()]);

      // Add option for no company/fleet to account for the items that are missing this value
      companiesList.push({ id: FilterIds.NoCompany, name: t('Common.NoCompany') });
      fleetsList.push({ id: FilterIds.NoFleet, name: t('Common.NoFleet') });

      setCompaniesFilter(prepareDataForMultiselect(companiesList, t('Common.AllCompanies'), null));
      setFleetsFilter(prepareDataForMultiselect(fleetsList, t('Common.AllFleets'), null));
    }
  }, [documents]);

  // useEffect for applying the filters
  useEffect(() => {
    if (documents) {
      let newFilteredDocuments = [];

      documents.forEach(row => {
        let matchSearch = true;

        // filter by search text for name, company and fleet
        if (matchSearch && searchText && searchText.trim()) {
          const searchTextLowerCase = searchText.trim().toLowerCase();
          matchSearch =
            row.name?.toLowerCase().indexOf(searchTextLowerCase) >= 0 ||
            row.company?.some(c => c.name.toLowerCase().indexOf(searchTextLowerCase) >= 0) ||
            row.fleet?.some(f => f.name.toLowerCase().indexOf(searchTextLowerCase) >= 0);
        }

        // Filter by checked companies (0 = all companies, -1 = no company)
        if (
          matchSearch &&
          companiesFilter &&
          !companiesFilter.find(f => f.id === FilterIds.AllCompanies)?.checked
        ) {
          const hasCompany = row.company && row.company.length > 0;
          matchSearch = companiesFilter.some(
            f =>
              f.checked &&
              ((row.company && row.company.some(company => f.id === company.id)) ||
                (f.id === FilterIds.NoCompany && !hasCompany))
          );
        }

        // Filter by checked fleets (0 = all fleets, -1 = no fleet)
        if (
          matchSearch &&
          fleetsFilter &&
          !fleetsFilter.find(f => f.id === FilterIds.AllFleets)?.checked
        ) {
          const hasFleet = row.fleet && row.fleet.length > 0;
          matchSearch = fleetsFilter.some(
            f =>
              f.checked &&
              ((row.fleet && row.fleet.some(fleet => f.id === fleet.id)) ||
                (f.id === FilterIds.NoFleet && !hasFleet))
          );
        }

        if (matchSearch) {
          newFilteredDocuments.push(row);
        }
      });

      setFilteredDocuments(newFilteredDocuments);

      // Removed checked status for any document that just got filtered out
      const newCheckedDocumentIds = checkedDocumentIds.filter(id =>
        newFilteredDocuments.find(document => document.id === id)
      );
      setCheckedDocuments(newCheckedDocumentIds);
    }
  }, [documents, searchText, companiesFilter, fleetsFilter]);

  //Hide modal for delete process
  const hideModal = () => {
    setModalData({
      showModalDeleteFolders: false,
      showModalDeleteDocuments: false,
      showModalAssociations: false,
      showModalEdit: false
    });
    dispatch(changeDocumentStep(1));
    dispatch(clearEditFiles());
    setHierarchyWithCheckedStatus([]);
  };

  const deleteFolders = () => {
    dispatch(deleteFolder(activeFolder.id, activeFolder.name));
    setEditFolderName('');
    hideModal();
  };

  const deleteFiles = () => {
    dispatch(deleteDocuments(checkedDocumentIds));
    setCheckedDocuments([]);
    hideModal();
  };

  //For associations
  const handleClickAssociations = () => {
    setModalData({
      showModalAssociations: true
    });
  };

  //For editing selection
  const handleClickEdit = () => {
    for (const doc of checkedDocuments) {
      const foldersDocIds = doc.folders.map(folder => folder.id);
      const foldersForEdit = foldersForForms.map(folder => {
        return { ...folder, checked: foldersDocIds.includes(folder.id) };
      });
      const uploadfile = { name: doc.filename, documentId: doc.id, success: true };
      let initialEditValues = {
        [`name_${doc.id}`]: doc.name,
        [`description_${doc.id}`]: doc.description,
        [`acknowledge_${doc.id}`]: doc.acknowledge,
        [`folders_${doc.id}`]: foldersForEdit,
        [`hasExpirationDate_${doc.id}`]: doc.expiredAt ? true : false,
        [`autoRemoval_${doc.id}`]: doc.autoRemoval,
        [`expiredAt_${doc.id}`]: doc.expiredAt
      };
      dispatch(uploadFileSuccess(uploadfile));
      dispatch(addInitialValuesForEdit(initialEditValues));
    }
    dispatch(changeDocumentStep(2));
    setEditFolderName(activeFolder.name);
    setModalData({
      showModalEdit: true
    });
    //Remove the title attribute on the Edit Documents modal
    setTimeout(() => {
      const title = document.querySelector('[title="Edit Documents"]');
      if (title) {
        title.removeAttribute('title');
      }
    }, 1);
  };

  const showDocumentActionButtons = documents && documents.length > 0;

  const disableDocumentActionButtons = checkedDocumentIds.length === 0;

  const standardActionButtonStyle = disableDocumentActionButtons
    ? { ...buttonStyle.default, ...buttonStyle.standardBtn, ...buttonStyle.disabledStandardBtn }
    : { ...buttonStyle.default, ...buttonStyle.standardBtn };

  const deleteActionButtonStyle = disableDocumentActionButtons
    ? { ...buttonStyle.default, ...buttonStyle.deleteBtn, ...buttonStyle.disabledDeleteBtn }
    : { ...buttonStyle.default, ...buttonStyle.deleteBtn };

  //Permisions
  const canFolderUpdate = useCanOneOfEntities(['FOLDER_UPDATE']);
  return (
    <div style={{ width: 'calc(100% - 304px)', boxShadow: '-1px 0px 0px rgba(0, 0, 0, 0.05)' }}>
      <AssociationsModal
        show={modalData.showModalAssociations}
        title={i18next.t('Easydocs.AssociateDocumentstoFleets')}
        onSave={saveUpdateAssociations}
        onHide={hideModal}
        documents={checkedDocuments}
        hierarchy={hierarchyAssociations}
        handleCheck={handleCheck}
      />
      <FormModal
        show={modalData.showModalEdit}
        title={
          checkedDocuments.length !== 1
            ? i18next.t('Easydocs.EditDocuments')
            : i18next.t('Easydocs.EditDocument')
        }
        onHide={hideModal}
        deleteDataOnExit={false}
        folders={foldersForForms}
        companies={[]}
        documents={checkedDocuments}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '0 0 1px 0',
          background: '#fff'
        }}
      >
        {activeFolder && (
          <Formik enableReinitialize={true} initialValues={activeFolder}>
            {({ setFieldValue }) => {
              return (
                <Form>
                  <FormInput
                    name="name"
                    disabled={
                      activeFolder.name === folderlessFolderFile(t).name || !canFolderUpdate
                    }
                    onKeyDown={updateFolder(setFieldValue)}
                    onBlur={updateFolder(setFieldValue)}
                    onChange={changeFolderName(setFieldValue)}
                    isValidated
                  />
                  <FormInput
                    name="description"
                    disabled={
                      activeFolder.description === folderlessFolderFile(t).description ||
                      !canFolderUpdate
                    }
                    onKeyDown={updateFolder(setFieldValue)}
                    onBlur={updateFolder(setFieldValue)}
                    onChange={changeFolderDescription(setFieldValue)}
                    placeholder={
                      activeFolder.description === '' ? 'Folder description goes here...' : ''
                    }
                  />
                </Form>
              );
            }}
          </Formik>
        )}
        <div className="folder-info">
          {activeFolder && activeFolder.createdAt && (
            <span>
              {i18next.t('Easydocs.CreatedOn')}{' '}
              {format(new Date(activeFolder.createdAt), localization.formats.time.formats.dby_imsp)}
            </span>
          )}
        </div>
        {documents &&
          documents.length === 0 &&
          checkedDocumentIds.length === 0 &&
          activeFolder.name !== folderlessFolderFile(t).name && (
            <Can everyEntity={[entities.FOLDER_DESTROY]}>
              <Button
                onClick={() => {
                  confirmationModal(
                    i18next.t('Easydocs.Notifications.AreYouSure'),
                    i18next.t('Easydocs.Notifications.AreYouSureDeleteFolder', {
                      name: activeFolder?.name
                    }),
                    i18next.t('Common.Modal.Delete'),
                    i18next.t('Common.Modal.Cancel'),
                    deleteFolders,
                    'delete'
                  );
                  document.body.click();
                }}
                style={{ ...buttonStyle.default, ...buttonStyle.deleteBtn }}
                id={BUTTON_IDS.contentFolderDeleteFolder}
              >
                {i18next.t('Easydocs.DeleteFolder')}
              </Button>
            </Can>
          )}
        {showDocumentActionButtons && (
          <div style={{ margin: '0 0 0 auto' }}>
            <Can everyEntity={[entities.DOCUMENT_CREATE]}>
              {activeFolder.name !== folderlessFolderFile(t).name && (
                <Button
                  onClick={handleClickAssociations}
                  style={standardActionButtonStyle}
                  id={BUTTON_IDS.contentFolderAssociate}
                  disabled={disableDocumentActionButtons}
                >
                  {i18next.t('Easydocs.Associate')}
                </Button>
              )}
            </Can>
            <Can everyEntity={[entities.DOCUMENT_UPDATE]}>
              <Button
                onClick={handleClickEdit}
                style={standardActionButtonStyle}
                id={BUTTON_IDS.contentFolderEdit}
                disabled={disableDocumentActionButtons}
              >
                {i18next.t('Easydocs.EditSelection')}
              </Button>
            </Can>
            <Can everyEntity={[entities.DOCUMENT_DESTROY]}>
              <Button
                onClick={() => {
                  confirmationModal(
                    `${i18next.t('Common.DeleteButton')} ${namesCheckedDocuments.join(', ')}`,
                    i18next.t('Easydocs.Notifications.AreYouSureDeleteDoc', {
                      docs: namesCheckedDocuments.join(', ')
                    }),
                    i18next.t('Common.Modal.Delete'),
                    i18next.t('Common.Modal.Cancel'),
                    deleteFiles,
                    'delete'
                  );
                  document.body.click();
                }}
                style={deleteActionButtonStyle}
                id={BUTTON_IDS.contentFolderDeleteSelection}
                disabled={disableDocumentActionButtons}
              >
                {i18next.t('Easydocs.DeleteSelection')}
              </Button>
            </Can>
          </div>
        )}
      </div>
      <Can everyEntity={[entities.DOCUMENT]}>
        <DocumentsToolbar
          filteredDocumentsCount={filteredDocuments?.length}
          searchText={searchText}
          companiesFilter={companiesFilter}
          fleetsFilter={fleetsFilter}
          onSearchChange={handleSearchChange}
          onCompaniesFilterChange={handleCompaniesFilterChange}
          onFleetsFilterChange={handleFleetsFilterChange}
        />
        <div style={{ height: 'calc(100vh - 282px)' }}>
          <DocumentsTable
            documents={filteredDocuments}
            setTableRef={setTableRef}
            checkedItems={checkedDocumentIds}
            setCheckedItems={setCheckedDocuments}
          />
        </div>
      </Can>
    </div>
  );
};

export default ContentFolder;
