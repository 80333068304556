import * as Yup from 'yup';
import i18n from 'i18next';

export const postBodyShape = {
  name: '',
  description: '',
  status: 'ENABLED',
  path: '',
  docId: '',
  externalId: '',
  colour: '',
  iconType: 'asset'
};

export const newFleet = {
  name: '',
  company: '',
  description: '',
  colour: '',
  iconType: 'asset',
  iconSize: 'small',
  carrierId: -1,
  vehicles: [],
  devices: []
};

export const validationSchema = (allFleetNames, initialValues) => {
  return Yup.object().shape({
    name: Yup.string()
      .max(50, i18n.t('Fleets.FormValidation.NameMax'))
      .required(i18n.t('Fleets.FormValidation.NameRequired'))
      .matches(/\S/, i18n.t('Fleets.FormValidation.NameRequired'))
      .test(
        'Unique',
        i18n.t('Fleets.Notifications.FleetWithTheSameNameAlreadyExists'),
        values => !allFleetNames.includes(values) || values === initialValues?.name
      )
  });
};

export const GeofencesTypes = Object.defineProperties(
  {
    get User() {
      return i18n.t('Common.User');
    },
    get Managed() {
      return i18n.t('Common.Managed');
    },
    get Traffic() {
      return i18n.t('Common.Traffic');
    },
    get SpeedManagement() {
      return i18n.t('Common.SpeedManagement');
    },
    get Load() {
      return i18n.t('GeofencesFeature.Load');
    }
  },
  {
    SAFERTOGETHER: {
      enumerable: false,
      get: () => {
        return i18n.t('Common.Managed');
      }
    },
    UKECONOMICZONES: {
      enumerable: false,
      get: () => {
        return i18n.t('Common.Managed');
      }
    },
    SPEED_LIMIT_OVERRIDE: {
      enumerable: false,
      get: () => {
        return i18n.t('Common.SpeedManagement');
      }
    },
    MANAGED: {
      enumerable: false,
      get: () => {
        return i18n.t('Common.Managed');
      }
    },
    USER: {
      enumerable: false,
      get: () => {
        return i18n.t('Common.User');
      }
    },
    TRAFFIC: {
      enumerable: false,
      get: () => {
        return i18n.t('Common.Traffic');
      }
    },
    ROUTE: {
      enumerable: false,
      get: () => {
        return i18n.t('JourneyPlanner.Route');
      }
    },
    JOB: {
      enumerable: false,
      get: () => {
        return i18n.t('Entity.Job');
      }
    },
    LOAD: {
      enumerable: false,
      get: () => {
        return i18n.t('GeofencesFeature.Load');
      }
    }
  }
);

export const filterGeofenceTypes = ({
  hasManagedAccess,
  hasOnsiteTraffic,
  hasSmartJobHeadless,
  hasSpeedManagementOverride
}) => {
  let geoTypes = Object.keys(GeofencesTypes);
  if (!hasManagedAccess) {
    geoTypes = geoTypes.filter(t => t !== 'Managed');
  }
  if (!hasOnsiteTraffic) {
    geoTypes = geoTypes.filter(t => t !== 'Traffic');
  }
  if (!hasSmartJobHeadless) {
    geoTypes = geoTypes.filter(t => t !== 'Load');
  }
  if (!hasSpeedManagementOverride) {
    geoTypes = geoTypes.filter(t => t !== 'SpeedManagement');
  }

  return geoTypes;
};

export const PATHS = Object.freeze({
  FLEETS_DEFAULT: '/settings/fleets',
  FLEET_ADD: '/settings/fleets/newFleet',
  FLEET_VIEW: '/settings/fleets/id/',
  FLEET_EDIT: '/settings/fleets/edit/id/'
});
