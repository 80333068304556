import React, { useEffect, useState, useMemo, useRef, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Tag } from 'antd';
import _ from 'lodash';
import { useUser } from 'features/user/userSlice';
import { Iframe } from 'components/iframe/Iframe';
import { AuthUtil } from 'features/auth/authUtil';
import { useCurrentCompany } from 'features/company/companySlice';
import { useGetQsReportsQuery } from 'services/nextgen';

import { Comparators } from 'utils/sorting';
import { SortOrders } from 'containers/InsightsQS/constants';

import { setBackButton, setPageTitle } from 'features/page/pageSlice';

import { Grid } from 'components/tn';
import {
  getReportNameByType,
  getReportDescriptionByType,
  getReportNameByTypeInRow,
  isNextgenReport
} from './constants';
//import ReportHistoryCard from 'containers/InsightsQS/Cards/ReportHistoryCard';
import {
  ReportsPageToolbar,
  isReportCategory,
  getReportCategoryLabels,
  getReportCategoryLabel
} from 'containers/InsightsQS/Toolbars/ReportsPageToolbar';

import styles from 'containers/InsightsQS/Pages/pages.module.scss';
import cardStyles from 'containers/InsightsQS/Cards/cards.module.scss';

function _ReportHistory() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useUser();
  const company = useCurrentCompany();

  /**************************/
  const history = useHistory();
  const frameRef = useRef(null);
  const [reportId, setReportId] = useState();
  const [report, setReport] = useState();
  const [showIframe, setShowIframe] = useState(false);

  const pageUrl = useMemo(() => {
    if (isNextgenReport(report)) {
      return (
        AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
        `&url=${encodeURIComponent(`/ng/reports/${reportId}?source=nextgen`)}`
      );
    } else {
      return (
        AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
        `&url=${encodeURIComponent(`/ng/reports/${reportId}`)}`
      );
    }
  }, [userInfo, history.length, reportId, report]);
  /****************************/

  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [searchText, setSearchText] = useState();
  const [sortOrder, setSortOrder] = useState(SortOrders.Default);
  const [categories, setCategories] = useState();
  //const [viewType, setViewType] = useState('tile');

  const reportsQuery = useGetQsReportsQuery({
    userId: userInfo?.id
  });

  useEffect(() => {
    dispatch(setPageTitle(t('Reports.HistoryTitle')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  useEffect(() => {
    if (reportsQuery.isFetching) {
      return;
    }

    const allReports = _.orderBy(
      [...(reportsQuery?.data ? reportsQuery?.data : [])],
      ['id'],
      ['desc']
    );
    setReports(allReports);
    setFilteredReports(allReports);
  }, [reportsQuery]);

  useEffect(() => {
    if (
      categories !== undefined &&
      categories !== null &&
      reports !== undefined &&
      reports !== undefined
    ) {
      handleFilter(categories);
    }
  }, [categories, reports]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (frameRef.current && !frameRef.current.contains(event.target)) {
        setShowIframe(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearch = value => {
    setSearchText(value);

    let filteredReports = search(reports, value);
    if (categories) {
      filteredReports = filter(filteredReports, categories);
    }
    if (sortOrder) {
      filteredReports = sort(filteredReports, sortOrder);
    }

    setFilteredReports(filteredReports);
  };

  const handleSort = order => {
    setSortOrder(order);

    const sortedReports = sort(filteredReports, order);
    setFilteredReports(sortedReports);
  };

  const handleFilter = categoryIds => {
    setCategories(categoryIds);

    let filteredReports = filter(reports, categoryIds);
    if (searchText) {
      filteredReports = search(filteredReports, searchText);
    }
    if (sortOrder) {
      filteredReports = sort(filteredReports, sortOrder);
    }
    setFilteredReports(filteredReports);
  };

  const search = (reports, searchText) => {
    return reports.filter(
      report =>
        getReportNameByType(report?.type)
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        getReportDescriptionByType(report?.type)
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        getDateFromField(report)
          ?.toLowerCase()
          .includes(searchText.toLowerCase())
    );
  };

  const sort = (reports, order) => {
    const isDefault = order === SortOrders.Default;
    const isDescending = order === SortOrders.NameDesc;
    const sortedReports = isDefault
      ? _.orderBy(reports, ['id'], ['desc'])
      : reports.toSorted(Comparators.String(db => getReportNameByType(db.type), isDescending));
    return sortedReports;
  };

  const filter = (reports, categoryIds) => {
    return reports.filter(report =>
      categoryIds?.reduce(
        (accumulator, categoryId) => accumulator || isReportCategory(report, categoryId),
        false
      )
    );
  };

  // const onViewChange = () => {
  //   viewType === 'tile' ? setViewType('grid') : setViewType('tile');
  // };

  const onViewReportClick = report => {
    setReportId(report.id);
    setReport(report);
    //setShowIframe(true);

    if (isNextgenReport(report)) {
      history.push(`/reports/history/${report.id}?source=nextgen`);
    } else {
      history.push(`/reports/history/${report.id}`);
    }
  };

  const getDateFromField = rowData => {
    let generatedOn = '';
    if (rowData !== null) {
      const createdAt = rowData?.createdAt;

      if (createdAt !== undefined) {
        const date = new Date(createdAt);
        const options = {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        };
        const userLocale = navigator.language || 'en-US';

        generatedOn = date.toLocaleString(userLocale, options).replace(/[,]/g, '');
      }
    }
    return generatedOn;
  };

  //*****GRID************//
  const nameCellRenderer = (value, rowData, rowIndex) => {
    return <div>{getReportNameByType(rowData.type)}</div>;
  };

  const descriptionCellRenderer = (value, rowData, rowIndex) => {
    return <div>{getReportDescriptionByType(rowData.type)}</div>;
  };

  const categoryCellRenderer = (value, rowData, rowIndex) => {
    const categories = getReportCategoryLabels(rowData);

    return (
      Array.isArray(categories) &&
      categories?.map(category => (
        <Tag className={[cardStyles.categories, cardStyles.reportCard]} key={category - rowData.id}>
          {t(`Insights.Tags.${category}`)}
        </Tag>
      ))
    );
  };

  const generatedCellRenderer = (value, rowData, rowIndex) => {
    const moment = require('moment-timezone');

    const parsedDate = moment(rowData?.createdAt).format('MMM DD YYYY hh:mm:ss A');
    return <div> {parsedDate} </div>;
  };

  const tryParseJSON = jsonString => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return null;
    }
  };

  const selectionCellRenderer = (value, rowData, rowIndex) => {
    const moment = require('moment-timezone');

    const filterData = tryParseJSON(rowData?.filters?.replace(/\\/g, ''));

    let countsLabel = '';
    let counts = '';
    let selection = '';
    if (filterData !== null) {
      const vehicleIdValuesCount = filterData?.vehicle_id?.values?.length;
      const driverIdValuesCount = filterData?.driver_id?.values?.length;

      if (vehicleIdValuesCount !== undefined || driverIdValuesCount !== undefined) {
        countsLabel =
          vehicleIdValuesCount === undefined ? t('Insights.Driver') : t('Insights.Vehicle');
        counts =
          vehicleIdValuesCount === undefined
            ? driverIdValuesCount + ' selected'
            : vehicleIdValuesCount + ' selected';
      } else {
        countsLabel = t('Insights.Vehicle') + '/' + t('Insights.Driver');
        counts = t('Insights.NotAvailable');
      }
      const from = filterData?.event_time?.values?.from;
      const to = filterData?.event_time?.values?.to;
      const timezone = filterData?.timezone ? filterData.timezone.values : 'Europe/London';

      if (from !== undefined && to !== undefined) {
        const userFrom = moment.tz(from, 'DD-MM-YYYY HH:mm', timezone).format('DD MMM YYYY h:mm A');
        const userTo = moment.tz(to, 'DD-MM-YYYY HH:mm', timezone).format('DD MMM YYYY h:mm A');

        // Calculate the duration
        const duration = moment(to, 'DD-MM-YYYY HH:mm').diff(
          moment(from, 'DD-MM-YYYY HH:mm'),
          'days'
        );
        const durationString = duration > 1 ? ' days' : ' day';
        selection = userFrom + ' - ' + userTo + ' (' + duration + durationString + ')';
      } else {
        selection = t('Insights.NotAvailable');
      }
    } else {
      countsLabel = t('Insights.Vehicle') + '/' + t('Insights.Driver');
      counts = t('Insights.NotAvailable');

      selection = t('Insights.NotAvailable');
    }
    return (
      <div>
        <span> {countsLabel}: </span>
        <br />
        <span> {counts} </span>
        <br />
        <span> {t(`Insights.TimePeriod`)}: </span>
        <br />
        <span> {selection} </span>
      </div>
    );
  };

  const actionCellRenderer = (value, rowData, rowIndex) => {
    return (
      <Button className={styles.generate} onClick={() => onViewReportClick(rowData)}>
        {' '}
        {t(`Insights.View`)}{' '}
      </Button>
    );
  };

  const columns = [
    {
      title: t(`Insights.Table.Generated`),
      key: 'generated',
      fixed: 'left',
      width: 120,
      render: generatedCellRenderer,
      sorter: Comparators.Date('createdAt')
    },
    {
      title: t(`Insights.Table.Name`),
      key: 'name',
      fixed: 'left',
      width: 110,
      render: nameCellRenderer,
      sorter: Comparators.String(getReportNameByTypeInRow)
    },
    {
      title: t(`Insights.Table.Description`),
      key: 'description',
      fixed: 'left',
      width: 200,
      render: descriptionCellRenderer
    },
    {
      title: t(`Insights.Table.Selection`),
      key: 'selection',
      fixed: 'left',
      width: 200,
      render: selectionCellRenderer
    },
    {
      title: t(`Insights.Table.Category`),
      key: 'tag',
      fixed: 'left',
      width: 50,
      render: categoryCellRenderer,
      sorter: Comparators.String(getReportCategoryLabel)
    },
    {
      title: t(`Insights.Table.Action`),
      key: 'action',
      fixed: 'left',
      width: 50,
      render: actionCellRenderer
    }
  ];
  //*****GRID************//

  return (
    <>
      <ReportsPageToolbar
        count={filteredReports?.length}
        onSearch={handleSearch}
        onSort={handleSort}
        onFilter={handleFilter}
        //onViewChange={onViewChange}
        //viewType={viewType}
        showIframe={showIframe}
        reports={reports}
      />

      {/*!showIframe && viewType === 'tile' && (
        <List
          className={styles.dashboardListReport}
          grid={{
            gutter: 12,
            xs: 2,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 5
          }}
          itemLayout={'horizontal'}
          loading={reportsQuery.isFetching}
          dataSource={filteredReports}
          renderItem={report => (
            <List.Item key={report.reportId}>
              <ReportHistoryCard
                report={report}
                onViewReportClick={onViewReportClick}
                categories={getReportCategoryLabels(report)}
                //image={}
              />
            </List.Item>
          )}
        />
      )*/}
      {!showIframe /*&& viewType === 'grid'*/ && (
        <Grid
          columns={columns}
          data={filteredReports}
          isLoading={reportsQuery.isFetching}
          showHeader={false}
          tableClassName={`${styles.newGridStyle} ${styles.history}`}
        />
      )}

      {showIframe && <Iframe ref={frameRef} src={pageUrl} className={styles.iframe}></Iframe>}
    </>
  );
}

function _ReportFrame({ type }) {
  const userInfo = useUser();
  const frameRef = useRef(null);

  const [clickCount, increaseCount] = useReducer(
    state => {
      return { count: state.count + 1 };
    },
    { count: 0 }
  );

  const url = useMemo(() => {
    const url =
      AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
      `&url=${encodeURIComponent('/ng/reports/' + type)}`;
    return url;
  }, [type, userInfo, clickCount]);

  //useWalkMeInFrame(frameRef);
  return (
    <>
      <Iframe
        ref={frameRef}
        className={styles.reportFrame}
        src={url}
        width="100%"
        height="100%"
      ></Iframe>
    </>
  );
}

const ReportHistory = React.memo(_ReportHistory);
const ReportFrame = React.memo(_ReportFrame);

export const ReportHistoryNew = () => {
  return (
    <Switch>
      <Route path="/reports/history/:type">
        {({ match }) => <ReportFrame type={match.params.type} />}
      </Route>
      <Route exact path="/reports/history">
        <ReportHistory />
      </Route>
    </Switch>
  );
};
