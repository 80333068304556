import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Divider, Empty, Form, List, Select, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { CloseCircleOutlined } from '@ant-design/icons';

import FormFooter from 'components/form-footer/FormFooter';
import { ToastType } from 'components/notifications/toasts/Toast';

import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useCurrentCompany, useSubCompanies } from 'features/company/companySlice';
import { useAllRegions } from 'features/regions/regionsSlice';
import { useDevices, useIsFetching as isFleetsFetching } from 'features/fleets/fleetsSlice';
import {
  useSchemes,
  useIsSchemesFetching,
  addEnrolment,
  editEnrolment,
  useIsEnrolmentSaving,
  useEnrolments,
  getEnrolements,
  useIsEnrolmentsFetching
} from 'features/enrolments';
import { openToast } from 'features/toasts/toastsSlice';
import { parseErrorMessage } from 'utils/strings';

import formItemBuilder from './FormItemBuilder';
import {
  getBodyFromValues,
  getCompanySchemes,
  resetFieldsOnCompanyChange,
  parseEnrolment,
  getDeviceOption,
  getCompanyScheme
} from './helpers';
import {
  ITEM_LABELS,
  ITEM_NAMES,
  ITEM_PLACEHOLDERS,
  nonVinRules,
  registrationNumberRules,
  vinRadioGroupValues,
  vinRules,
  operatorStateAddress,
  pbsRules,
  operatorABNRules,
  registrationStateRules,
  operatorBusinessHoursPhone
} from './constants';

import styles from './EnrolmentsForm.module.scss';
import { getCountryRegions } from 'utils/countryState';

export const EnrolmentsForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id: enrolmentId } = useParams();
  const isEditPage = Boolean(enrolmentId);

  const currentCompany = useCurrentCompany();
  const subCompanies = useSubCompanies();
  const devices = useDevices();
  const regions = useAllRegions();
  const enrolments = useEnrolments();
  const isDevicesFetching = isFleetsFetching();
  const isSchemesFetching = useIsSchemesFetching();
  const isEnrolmentSaving = useIsEnrolmentSaving();
  const isEnrolmentsFetching = useIsEnrolmentsFetching();

  const [selectedCompanyId, setSelectedCompanyId] = useState(currentCompany.id);
  const schemes = useSchemes(selectedCompanyId);

  const [formSchemes, setFormSchemes] = useState([]);
  const [isOperatorDisabled, setIsOperatorDisabled] = useState(false);
  const [isDeviceDisabled, setIsDeviceDisabled] = useState(false);
  const [isVIN, setIsVIN] = useState(true);
  const [enrolmentOperatorABN, setEnrolmentOperatorABN] = useState([]);
  const [enrolmentDetails, setEnrolmentDetails] = useState([]);
  const [newOperatorAbn, setNewOperatorAbn] = useState('');
  const [isAbnOpen, setIsAbnOpen] = useState(false);
  const [schemesList, setSchemesList] = useState([]);
  const [editSchemesState, setEditSchemesState] = useState(null);

  const onCancel = () => history.push('/settings/enrolments');

  const abnSelectRef = useRef();

  const getEnrolment = id => {
    const editEnrolment = enrolment => enrolment.id === +id;
    const createEnrolment = enrolment =>
      enrolment.companyId === selectedCompanyId && enrolment.status !== 'CANCELLED';

    return enrolments.find(id ? editEnrolment : createEnrolment);
  };

  const handleCheckAllSchemes = () => {
    if (formSchemes.length !== schemesList.length) {
      setSchemesList(formSchemes.map(fs => fs.schemeCode).filter(Boolean));
      form.setFieldValue(ITEM_NAMES.SCHEME, formSchemes.map(fs => fs.schemeCode).filter(Boolean));
    } else {
      setSchemesList([]);
      form.setFieldValue(ITEM_NAMES.SCHEME, undefined);
    }
  };

  const modifyFieldOperatorABN = (enrolments, companyId) => {
    const operatorsAbnList = [];

    const operatorABNDetails = [];

    enrolments.forEach(enrolment => {
      const parsedEnrolment = parseEnrolment(enrolment);
      const parsedCompanyDetails = parsedEnrolment?.companyDetails;
      if (
        enrolment?.companyId === companyId &&
        !operatorsAbnList.find(e => e.id === parsedCompanyDetails?.identity?.operatorABN)
      ) {
        operatorsAbnList.push({
          id: parsedCompanyDetails?.identity?.operatorABN,
          name: parsedCompanyDetails?.identity?.operatorABN
        });
        operatorABNDetails.push(parsedCompanyDetails);
      }
    });

    setEnrolmentOperatorABN(operatorsAbnList);
    setEnrolmentDetails(operatorABNDetails);

    return { operatorsAbnList, operatorABNDetails };
  };

  useEffect(() => {
    setSelectedCompanyId(currentCompany.id);
  }, [currentCompany]);

  useEffect(() => {
    if (isEditPage) {
      setSelectedCompanyId(enrolments.find(e => e.id === +enrolmentId)?.companyId);
    }
  }, [enrolments]);

  useEffect(() => {
    let enrolment = parseEnrolment(getEnrolment(enrolmentId ?? null));
    modifyFieldOperatorABN(enrolments, enrolment?.companyId);

    setIsDeviceDisabled(isEditPage);
    setIsOperatorDisabled(isEditPage ? true : !!enrolment);
    isEditPage &&
      setSchemesList(
        (schemes && getCompanyScheme(schemes?.find(s => s.schemeCode === enrolment?.['scheme']))) ||
          []
      );
    isEditPage && setIsVIN(!!enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_VIN]);
    form.setFieldsValue({
      [ITEM_NAMES.COMPANY_ID]: enrolment?.[ITEM_NAMES.COMPANY_ID] || selectedCompanyId,
      [ITEM_NAMES.DEVICE_IMEI]: isEditPage ? enrolment?.deviceDetails?.imei : undefined,
      [ITEM_NAMES.DEVICE_INSTALLATION_LOCATION]: isEditPage
        ? enrolment?.deviceDetails?.[ITEM_NAMES.DEVICE_INSTALLATION_LOCATION]
        : undefined,
      [ITEM_NAMES.DEVICE_INSTALLATION_TIME]: isEditPage
        ? moment(enrolment?.deviceDetails?.[ITEM_NAMES.DEVICE_INSTALLATION_TIME])
        : undefined,
      [ITEM_NAMES.VEHICLE_REGISTRATION_NUMBER]: isEditPage
        ? enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_REGISTRATION_NUMBER]
        : undefined,
      [ITEM_NAMES.PBS]: isEditPage
        ? enrolment?.vehicleDetails?.[ITEM_NAMES.PBS]?.join()
        : undefined,
      [ITEM_NAMES.VEHICLE_REGISTRATION_STATE]: isEditPage
        ? enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_REGISTRATION_STATE]
        : undefined,
      [ITEM_NAMES.VEHICLE_VIN]: isEditPage
        ? enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_VIN] ||
          enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_NON_VIN]
        : undefined,
      [ITEM_NAMES.VIN_RADIO_GROUP]: isEditPage
        ? enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_VIN]
          ? 'vin'
          : 'nonVin'
        : 'vin',
      [ITEM_NAMES.SCHEME]: isEditPage ? enrolment?.['scheme'] : undefined,
      [ITEM_NAMES.OPERATOR_ADDRESS_LINE_ONE]:
        enrolment?.companyDetails?.postalAddress?.[ITEM_NAMES.OPERATOR_ADDRESS_LINE_ONE],
      [ITEM_NAMES.OPERATOR_ADDRESS_LOCALITY]:
        enrolment?.companyDetails?.postalAddress?.[ITEM_NAMES.OPERATOR_ADDRESS_LOCALITY],
      [ITEM_NAMES.OPERATOR_NAME]: enrolment?.companyDetails?.identity?.[ITEM_NAMES.OPERATOR_NAME],
      [ITEM_NAMES.OPERATOR_ABN]: enrolment?.companyDetails?.identity?.[ITEM_NAMES.OPERATOR_ABN],
      [ITEM_NAMES.OPERATOR_ADDRESS_STATE]:
        enrolment?.companyDetails?.postalAddress?.[ITEM_NAMES.OPERATOR_ADDRESS_STATE],
      [ITEM_NAMES.OPERATOR_BUSINESS_HOURS]:
        enrolment?.companyDetails?.[ITEM_NAMES.OPERATOR_BUSINESS_HOURS],
      [ITEM_NAMES.OPERATOR_ADDRESS_POSTCODE]:
        enrolment?.companyDetails?.postalAddress?.[ITEM_NAMES.OPERATOR_ADDRESS_POSTCODE]
    });
    isEditPage && setEditSchemesState(form.getFieldsValue());
  }, [enrolments, selectedCompanyId, schemes]);

  useEffect(() => {
    dispatch(
      setPageTitle(isEditPage ? t('TCAEnrolments.Form.Edit') : t('TCAEnrolments.Form.Create'))
    );
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (!isEditPage && subCompanies.length) {
      const newCompanyId = subCompanies.reduce((acc, comp) => Math.min(acc, comp.id), Infinity);
      resetFieldsOnCompanyChange(form, newCompanyId);
    }
  }, [subCompanies, isEditPage]);

  useEffect(() => {
    if (isEditPage) {
      setFormSchemes(schemes);
    } else {
      setFormSchemes((schemes || []).filter(scheme => scheme.isActive));
    }
  }, [isEditPage, schemes]);

  useEffect(() => {
    Array.isArray(form.getFieldValue(ITEM_NAMES.SCHEME)) &&
      form.setFieldValue(ITEM_NAMES.SCHEME, form.getFieldValue(ITEM_NAMES.SCHEME).filter(Boolean));
  }, [form.getFieldValue(ITEM_NAMES.SCHEME), schemes]);

  const getInitialValues = () => {
    return {
      [ITEM_NAMES.COMPANY_ID]: currentCompany.id
    };
  };

  const onValuesChange = form => changed => {
    const pbsFieldValue = form.getFieldValue(ITEM_NAMES.PBS);

    if (changed[ITEM_NAMES.SCHEME]) {
      setSchemesList(changed[ITEM_NAMES.SCHEME].filter(Boolean));
    }

    if (changed[ITEM_NAMES.COMPANY_ID]) {
      resetFieldsOnCompanyChange(form, changed[ITEM_NAMES.COMPANY_ID]);
      setSelectedCompanyId(changed[ITEM_NAMES.COMPANY_ID]);
    }

    if (pbsFieldValue === '') {
      form.setFieldValue(ITEM_NAMES.PBS, undefined);
    }

    if (changed[ITEM_NAMES.DEVICE_IMEI]) {
      let enrolment = parseEnrolment(
        enrolments.find(
          enrolment =>
            enrolment.companyId === selectedCompanyId &&
            JSON.parse(enrolment.deviceDetails)?.imei === changed[ITEM_NAMES.DEVICE_IMEI] &&
            enrolment.status !== 'CANCELLED'
        )
      );

      if (!isEditPage) {
        form.setFieldValue(ITEM_NAMES.SCHEME, undefined);
        setSchemesList([]);
      }

      const filteredSchemesDevice = enrolments
        .filter(
          enrolment =>
            enrolment.companyId === selectedCompanyId &&
            JSON.parse(enrolment.deviceDetails)?.imei === changed[ITEM_NAMES.DEVICE_IMEI] &&
            enrolment.status !== 'CANCELLED'
        )
        .map(e => e.scheme);

      setFormSchemes(schemes.filter(s => !filteredSchemesDevice.includes(s.schemeCode)));

      const device = devices.find(device => device.imei === changed[ITEM_NAMES.DEVICE_IMEI]);

      setIsDeviceDisabled(!!enrolment);
      setIsVIN(
        enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_VIN]?.length === 17 ||
          device?.vehicle?.vin.length === 17
      );

      form.setFieldsValue({
        [ITEM_NAMES.VEHICLE_REGISTRATION_NUMBER]:
          enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_REGISTRATION_NUMBER] ||
          device?.vehicle?.registration,
        [ITEM_NAMES.VEHICLE_REGISTRATION_STATE]:
          enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_REGISTRATION_STATE] ||
          device?.vehicle?.registrationState.replace('AUS', ''),
        [ITEM_NAMES.VEHICLE_VIN]:
          enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_VIN] ||
          device?.vehicle?.vin ||
          enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_NON_VIN],
        [ITEM_NAMES.VIN_RADIO_GROUP]:
          enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_VIN] || device?.vehicle?.vin
            ? enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_VIN]?.length === 17 ||
              device?.vehicle?.vin.length === 17
              ? 'vin'
              : 'nonVin'
            : 'nonVin',
        [ITEM_NAMES.DEVICE_INSTALLATION_LOCATION]:
          enrolment?.deviceDetails?.[ITEM_NAMES.DEVICE_INSTALLATION_LOCATION],
        [ITEM_NAMES.DEVICE_INSTALLATION_TIME]: !!enrolment
          ? moment(enrolment?.deviceDetails?.[ITEM_NAMES.DEVICE_INSTALLATION_TIME])
          : undefined
      });
    }

    if (changed[ITEM_NAMES.OPERATOR_ABN]) {
      let enrolment = enrolmentDetails.find(
        enrolment => enrolment?.identity?.operatorABN === changed[ITEM_NAMES.OPERATOR_ABN]
      );

      form.setFieldsValue({
        [ITEM_NAMES.OPERATOR_NAME]: enrolment?.identity?.operatorName,
        [ITEM_NAMES.OPERATOR_ADDRESS_LINE_ONE]: enrolment?.postalAddress?.lineOne,
        [ITEM_NAMES.OPERATOR_ADDRESS_LOCALITY]: enrolment?.postalAddress?.locality,
        [ITEM_NAMES.OPERATOR_ADDRESS_STATE]: enrolment?.postalAddress?.stateCode,
        [ITEM_NAMES.OPERATOR_ADDRESS_POSTCODE]: enrolment?.postalAddress?.postCode,
        [ITEM_NAMES.OPERATOR_BUSINESS_HOURS]: enrolment?.businessHoursPhone
      });
    }
  };

  const onSave = form => () => {
    const values = form.getFieldsValue(true);
    const device = devices.find(device => device.imei === values[ITEM_NAMES.DEVICE_IMEI]);
    const body = getBodyFromValues(values, device, isVIN);

    const toasters = isEditPage => ({
      success: () => {
        history.push('/settings/enrolments');
        return openToast({
          type: ToastType.Success,
          message: isEditPage
            ? t('TCAEnrolments.Form.Toasters.EnrolmentSuccessfullyEdited')
            : t('TCAEnrolments.Form.Toasters.EnrolmentSuccessfullyCreated')
        });
      },
      error: err =>
        openToast({
          type: ToastType.Error,
          message: parseErrorMessage(err)
        })
    });

    if (isEditPage) {
      dispatch(
        editEnrolment({
          body,
          toasters: toasters(isEditPage),
          id: +enrolmentId,
          callback: getEnrolements
        })
      );
    } else {
      dispatch(
        addEnrolment({
          body,
          toasters: toasters(isEditPage),
          callback: getEnrolements
        })
      );
    }
  };

  const filterDevicesByTypeModel = devices =>
    devices.filter(
      device =>
        device.companyId === selectedCompanyId &&
        (device.type?.name === 'IVU' ||
          (device.type?.name === 'HERMES' &&
            ['20Wire', 'AdvGateway', 'VT202'].includes(device.model?.name)) ||
          (device.type?.name === 'Qube' &&
            ['Qube 300', 'Qube 4', 'CalAmp LMU 3640'].includes(device.model?.name)) ||
          (device.type?.name === 'Camera' && ['IQCamera', 'MultiIQ'].includes(device.model?.name)))
    );

  const handleClickNotFoundAbn = () => {
    form.setFieldValue(ITEM_NAMES.OPERATOR_ABN, newOperatorAbn);
    abnSelectRef.current.blur();
    form.validateFields([ITEM_NAMES.OPERATOR_ABN]);
    setIsOperatorDisabled(false);
    setIsAbnOpen(false);
  };

  const notFoundOperatorAbn = () => {
    return (
      <>
        <Button
          size="medium"
          type="dashed"
          className={styles.addNewButton}
          onClick={handleClickNotFoundAbn}
        >
          +
        </Button>
        <span>{t('TCAEnrolments.Form.NotFoundAbn.Information')}</span>
      </>
    );
  };

  return (
    <Spin spinning={isEnrolmentsFetching || isEnrolmentSaving}>
      <Form
        form={form}
        onValuesChange={onValuesChange(form)}
        onFinish={onSave(form)}
        layout="vertical"
        initialValues={getInitialValues()}
        requiredMark={false}
      >
        <div id={styles.form}>
          {formItemBuilder(ITEM_NAMES.COMPANY_ID, subCompanies)
            .showSearch()
            .setLoading(isDevicesFetching)
            .disable(isEditPage)
            .buildSelect()}

          <Divider orientation="left" plain>
            {t('TCAEnrolments.Form.DeviceDetails')}
          </Divider>

          {formItemBuilder(ITEM_NAMES.DEVICE_IMEI, filterDevicesByTypeModel(devices))
            .showSearch()
            .setLoading(isDevicesFetching)
            .setCustomOption(getDeviceOption)
            .buildSelect()}
          <div className={styles.formItemGroupContainer}>
            {formItemBuilder(ITEM_NAMES.VEHICLE_REGISTRATION_NUMBER)
              .disable(isDeviceDisabled)
              .setRules(registrationNumberRules)
              .buildInput()}
            {formItemBuilder(ITEM_NAMES.VEHICLE_REGISTRATION_STATE, operatorStateAddress)
              .disable(isDeviceDisabled)
              .setRules(registrationStateRules)
              .buildSelect()}
            {formItemBuilder(ITEM_NAMES.VEHICLE_VIN)
              .disable(isDeviceDisabled)
              .setRules(isVIN ? vinRules : nonVinRules)
              .setDependencies([ITEM_NAMES.VIN_RADIO_GROUP])
              .buildInput()}
            {formItemBuilder(ITEM_NAMES.VIN_RADIO_GROUP, vinRadioGroupValues)
              .setOnChange(() => setIsVIN(!isVIN))
              .disable(isDeviceDisabled)
              .buildRadioGroup()}
            {formItemBuilder(ITEM_NAMES.DEVICE_INSTALLATION_TIME)
              .disable(isDeviceDisabled)
              .buildCalendar()}
            {formItemBuilder(ITEM_NAMES.DEVICE_INSTALLATION_LOCATION)
              .disable(isDeviceDisabled)
              .buildInput()}
            {formItemBuilder(ITEM_NAMES.PBS)
              .setRules(pbsRules)
              .setCustomCss('pbsFormElementContainer')
              .buildInput()}
          </div>

          <Divider orientation="left" plain>
            {t('TCAEnrolments.Form.Schemes')}
          </Divider>

          <div className={styles.formElementContainer}>
            <Spin spinning={!!isSchemesFetching}>
              <Form.Item
                name={ITEM_NAMES.SCHEME}
                label={t(ITEM_LABELS[ITEM_NAMES.SCHEME])}
                rules={[{ required: true }]}
              >
                {/* TODO: rethink multiselect */}
                <Select
                  className={styles.schemesSelect}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  maxTagCount="responsive"
                  mode="multiple"
                  placeholder={t(ITEM_PLACEHOLDERS[ITEM_NAMES.SCHEME])}
                  optionLabelProp="value"
                  showSearch
                  size="large"
                  disabled={isEditPage}
                  notFoundContent={
                    <Empty description={t('TCAEnrolments.Form.DeviceEnrolledInAllSchemes')} />
                  }
                >
                  {formSchemes?.length > 0 && (
                    <Select.Option
                      key="select-all"
                      value={formSchemes?.map(e => e.id)}
                      onChange={handleCheckAllSchemes}
                      onMouseDown={e => {
                        if (e.target.className === 'ant-select-item-option-content') {
                          handleCheckAllSchemes();
                        }
                      }}
                    >
                      <Checkbox checked={formSchemes?.length === schemesList?.length}>
                        {formSchemes?.length === schemesList?.length
                          ? t('Common.Deselect All')
                          : t('Common.Select All')}
                      </Checkbox>
                    </Select.Option>
                  )}
                  {!isEditPage &&
                    getCompanySchemes(formSchemes)?.map(value => (
                      <Select.Option
                        value={value.id}
                        key={value.id}
                        onMouseDown={e => {
                          if (
                            ['ant-checkbox-inner', 'ant-checkbox-wrapper-in-form-item'].some(v =>
                              e.target.className.includes(v)
                            )
                          ) {
                            e.target.click();
                          }
                        }}
                      >
                        <Tooltip placement="right" title={value.name}>
                          <div className={styles.schemeOptionContainer}>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={(schemesList || []).includes(value.id)}
                            />
                            <div className={styles.schemeName}>{value.id}</div>
                            <div className={styles.schemeCode}>
                              {`${t('TCAEnrolments.Form.AuthorityCode')}: ${value.authorityCode}`}
                            </div>
                          </div>
                        </Tooltip>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Spin>
          </div>

          {!isEditPage && schemesList?.length > 0 && (
            <Form.Item>
              <List bordered className={styles.listContainer}>
                {schemesList.map(sl => {
                  const fullScheme = getCompanySchemes(formSchemes).find(fs => sl === fs.id);

                  return (
                    <List.Item>
                      <div className={styles.schemeName}>{fullScheme?.id}</div>
                      <div className={styles.schemeCode}>
                        {`${t('TCAEnrolments.Form.AuthorityCode')}: ${fullScheme?.authorityCode}`}
                      </div>
                      <div>{fullScheme?.name}</div>
                      <div
                        onClick={() => {
                          form.setFieldValue(
                            ITEM_NAMES.SCHEME,
                            form.getFieldValue(ITEM_NAMES.SCHEME).filter(el => el !== sl)
                          );
                          setSchemesList(form.getFieldValue(ITEM_NAMES.SCHEME));
                          form.validateFields([ITEM_NAMES.SCHEME]);
                        }}
                        className={styles.deleteScheme}
                      >
                        <CloseCircleOutlined />
                      </div>
                    </List.Item>
                  );
                })}
              </List>
            </Form.Item>
          )}

          {isEditPage && (
            <List bordered className={styles.listContainer}>
              <List.Item className={styles.listItem}>
                <div className={styles.schemeName}>{schemesList?.id}</div>
                <div className={styles.schemeCode}>
                  {`${t('TCAEnrolments.Form.AuthorityCode')}: ${schemesList?.authorityCode}`}
                </div>
                <div>{schemesList?.name}</div>
              </List.Item>
            </List>
          )}

          <Divider orientation="left" plain>
            {t('TCAEnrolments.Form.OperatorDetails')}
          </Divider>

          <div className={styles.formItemGroupContainer}>
            {formItemBuilder(ITEM_NAMES.OPERATOR_ABN, enrolmentOperatorABN)
              .setRules(operatorABNRules)
              .buildSelect(notFoundOperatorAbn(), {
                onSearch: searchValue => {
                  setNewOperatorAbn(searchValue);
                },
                ref: abnSelectRef,
                onChange: () => {
                  abnSelectRef.current.blur();
                  setIsAbnOpen(false);
                },
                onBlur: () => {
                  setIsAbnOpen(false);
                },
                onFocus: () => setIsAbnOpen(true),
                open: isAbnOpen
              })}
            {formItemBuilder(ITEM_NAMES.OPERATOR_ALERTINFO).buildAlert(
              t('TCAEnrolments.Form.OperatorAlertInfo'),
              'info'
            )}
            {formItemBuilder(ITEM_NAMES.OPERATOR_NAME).buildInput()}
            {formItemBuilder(ITEM_NAMES.OPERATOR_ADDRESS_LINE_ONE)
              .disable(isOperatorDisabled)
              .buildInput()}
            {formItemBuilder(ITEM_NAMES.OPERATOR_ADDRESS_LOCALITY)
              .disable(isOperatorDisabled)
              .buildInput()}
            {formItemBuilder(ITEM_NAMES.OPERATOR_ADDRESS_STATE, operatorStateAddress)
              .disable(isOperatorDisabled)
              .buildSelect()}
            {formItemBuilder(ITEM_NAMES.OPERATOR_ADDRESS_POSTCODE)
              .disable(isOperatorDisabled)
              .buildInput()}
            {formItemBuilder(ITEM_NAMES.OPERATOR_BUSINESS_HOURS)
              .disable(isOperatorDisabled)
              .setRules(operatorBusinessHoursPhone)
              .buildInput()}
          </div>
        </div>
        <Form.Item shouldUpdate>
          {() => {
            return (
              <FormFooter
                isSaving={isEnrolmentSaving}
                handleCancel={onCancel}
                disabledPrimatyButton={
                  (isEditPage &&
                    JSON.stringify(editSchemesState) === JSON.stringify(form.getFieldsValue())) ||
                  Object.entries(form.getFieldsValue())
                    .filter(([key, _]) => key !== ITEM_NAMES.PBS)
                    .some(([_, value]) => !value) ||
                  form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                }
              />
            );
          }}
        </Form.Item>
      </Form>
    </Spin>
  );
};
