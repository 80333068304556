import React, { useEffect, useState } from 'react';
import { Table, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { deviceServiceColumns } from './constants';
import { useCurrentCompanyServices } from 'features/company/companySlice';
import { useHistory } from 'react-router';
import { useUpdateConfigsMutation, executeUpdateConfig } from 'services/nextgen/ngConfigKeyApi';
import { useDispatch } from 'react-redux';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import {
  useCanEveryCompanyService,
  FeatureFlag,
  services,
  GlobalRoles,
  useCan,
  useCanFeatureFlag
} from 'features/permissions';
import { Modal, Switch, InputNumber, Form, Col } from 'antd';
import { useGetSpeedAssistValuesQuery } from 'services/nextgen/ngSpeedAssistConfigurationApi'; // Add required imports for the modal
import { useGetDriverValuesQuery } from 'services/nextgen/ngDriverIdConfigurationApi';

const DeviceConfigurations = ({ device }) => {
  const speedAssistFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.deviceConfig_speedAssist.flag
  });

  const driverLoginalertFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.driverLoginAlert.flag
  });

  const localization = useLocalization();
  const can = useCan();
  const canControlSpeedAssistConfiguration = can({
    ...(speedAssistFlag
      ? {
          oneOfRoles: [GlobalRoles.SiteAdmin]
        }
      : []),
    oneOfCompanyServices: [services.SPEEDASSIST]
  });

  const canControlDriverLoginConfiguration = can({
    ...(driverLoginalertFlag
      ? {
          oneOfRoles: [GlobalRoles.SiteAdmin]
        }
      : []),
    oneOfCompanyServices: [services.DRIVERPIN]
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDriverModalVisible, setIsDriverModalVisible] = useState(false);
  const types = useDeviceTypesList();
  const isVPMEnabled = useCanEveryCompanyService(services.VPM);
  const isBPMEnabled = useCanEveryCompanyService(services.BPM);
  const isSpeedsirenEnabled = useCanEveryCompanyService(services.SPEEDSIREN);
  const { data: speedAssistValues } = useGetSpeedAssistValuesQuery({ deviceId: device.id });
  const { data: driverValues } = useGetDriverValuesQuery({ deviceId: device.id });

  const supportedServices = [
    {
      code: services.GPIO,
      getServiceCodes: () => {
        const serviceCodes = [services.GPIO];

        if (isVPMEnabled && device.services?.includes(services.VPM)) {
          serviceCodes.push(services.VPM);
        }

        if (isBPMEnabled && device.services?.includes(services.BPM)) {
          serviceCodes.push(services.BPM);
        }

        if (isSpeedsirenEnabled && device.services?.includes(services.SPEEDSIREN)) {
          serviceCodes.push(services.SPEEDSIREN);
        }

        return serviceCodes;
      },
      condition: () =>
        device.gpioTemplateId !== undefined &&
        device.services?.includes(services.GPIO) &&
        types.find(type => type.id === device?.type?.id)?.name?.toUpperCase() === 'HERMES'
    },
    canControlSpeedAssistConfiguration && device?.services?.includes('SPEEDASSIST')
      ? {
          code: services.SPEEDASSIST,
          getServiceCodes: () => [services.SPEEDASSIST],
          condition: () =>
            (device.services?.includes(services.SPEEDASSIST) &&
              types.find(type => type.id === device?.type?.id)?.name?.toUpperCase() ===
                'EXTERNAL') ||
            'IFACE'
        }
      : '',
    canControlDriverLoginConfiguration && device?.services?.includes('DRIVERPIN')
      ? {
          code: services.DRIVERPIN,
          getServiceCodes: () => [services.DRIVERPIN],
          condition: () =>
            device.services?.includes(services.DRIVERPIN) &&
            types.find(type => type.id === device?.type?.id)?.name?.toUpperCase() === 'HERMES'
        }
      : ''
  ];

  const companyServices = useCurrentCompanyServices();
  const [serviceAvailable, setServiceAvailable] = useState([]);
  const [updateConfigs] = useUpdateConfigsMutation();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyServices) {
      setServiceAvailable(
        companyServices.filter(i => supportedServices.some(k => k.code === i.code && k.condition()))
      );
    }
  }, [companyServices, device]);

  const handleView = d => {
    if (d.code === services.GPIO) {
      history.push('/settings/company_config/gpio/id/' + device.gpioTemplateId);
    } else if (d.code === services.SPEEDASSIST) {
      // Open the modal instead of navigating
      setIsModalVisible(true);
    } else if (d.code === services.DRIVERPIN) {
      // Open the modal instead of navigating
      setIsDriverModalVisible(true);
    }
  };

  const handleServicePush = d => {
    const serviceList = [services.GPIO, services.SPEEDASSIST, services.DRIVERPIN];

    if (serviceList.includes(d.code)) {
      const payload = {
        deviceId: device.id,
        service: supportedServices.find(i => i.code === d.code)?.getServiceCodes() || d.code
      };

      executeUpdateConfig(payload, updateConfigs, dispatch);
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={'middle'}>
      {serviceAvailable?.length === 0 ? (
        t('Devices.View.ConfigurationProtocolNotAvailable')
      ) : (
        <Table
          headerHeight={52}
          headerStyle={{ paddingTop: '18px' }}
          rowClassName="tableRow"
          columns={deviceServiceColumns(t, handleServicePush, handleView)}
          dataSource={serviceAvailable}
          rowKey="id"
          pagination={false}
          loading={false}
        />
      )}

      {isDriverModalVisible && (
        <Modal
          title={t('Common.Configuration')}
          onCancel={() => {
            setIsDriverModalVisible(false);
          }}
          footer={null}
          open={isDriverModalVisible}
          width={1000}
          centered
        >
          <Form layout="vertical">
            <Row justify={'start'}>
              <Col span={6}>
                <Form.Item
                  label={t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.AudiableAlert')}
                >
                  <Switch
                    disabled={true}
                    checked={
                      driverValues?.find(item => item.key === 'driver.pattern.gpio')?.value ===
                      'true'
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.DelayAutoLogOff')}
                >
                  <InputNumber
                    disabled={true}
                    precision={0}
                    value={localization.convertSpeed(
                      driverValues?.find(item => item.key === 'delayed.logoff')?.value || 0
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.DriverLoggedOn')}
                >
                  <InputNumber
                    disabled={true}
                    precision={0}
                    value={
                      driverValues?.find(item => item.key === 'driver.loggedoff.threshold')
                        ?.value || 0
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}

      {/* Modal */}
      {isModalVisible && (
        <Modal
          title={t('Common.Configuration')}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          footer={null}
          open={isModalVisible}
          width={1000}
          centered
        >
          <Form layout="vertical">
            <Row justify={'start'}>
              <Col span={4}>
                <Form.Item
                  label={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.CompanyManaged'
                  )}
                >
                  <Switch
                    disabled={true}
                    checked={
                      speedAssistValues?.find(item => item.key === 'company.managed')?.value ===
                      'true'
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.SignpostedDisabled'
                  )}
                >
                  <Switch
                    disabled={true}
                    checked={
                      speedAssistValues?.find(item => item.key === 'signposted.disable')?.value ===
                      'true'
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxThreshold')}
                >
                  <InputNumber
                    disabled={true}
                    precision={0}
                    value={localization.convertSpeed(
                      speedAssistValues?.find(item => item.key === 'company.max.threshold')
                        ?.value || 0
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxOffset')}
                >
                  <InputNumber
                    disabled={true}
                    precision={0}
                    value={localization.convertSpeed(
                      speedAssistValues?.find(item => item.key === 'company.max.offset')?.value || 0
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxDuration')}
                >
                  <InputNumber
                    disabled={true}
                    precision={0}
                    value={
                      speedAssistValues?.find(item => item.key === 'company.max.duration')?.value ||
                      0
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </Space>
  );
};

export default DeviceConfigurations;
