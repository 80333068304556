import React from 'react';
import { Route, Switch } from 'react-router';
import { Regulatory } from './Regulatory';
import { TMADeclaration } from './TMADeclaration';
import { Can, services } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { PATHS } from './constants';

export const RegulatoryRouter = () => {
  return (
    <Can everyCompanyService={[services.TMA]} onFail={<NoAccessBody />}>
      <Switch>
        <Route exact path={PATHS.TMA_DECLARATION}>
          <Can everyCompanyService={[services.TMA]} onFail={<NoAccessBody />}>
            <TMADeclaration />
          </Can>
        </Route>
        <Route path={PATHS.REGULATORY}>
          <Can everyCompanyService={[services.TMA]} onFail={<NoAccessBody />}>
            <Regulatory />
          </Can>
        </Route>
      </Switch>
    </Can>
  );
};
