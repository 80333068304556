import { Button, Form, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ToastType } from 'components/notifications/toasts/Toast';
import { usePrevious } from 'utils/methods';
import { Input } from 'antd';
import { useCurrentCompany } from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';
import { useOneWireMultiProbeFields, updateOneWireMultiProbeFields } from 'features/company_config';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styles from '../VehicleMaintenanceModal/VehicleMaintenanceModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export function OneWireModal({ show = false, title, onClose = () => {} }) {
  const [form] = Form.useForm();
  const UNDELETABLE_FIELDS = 10;
  const MAXIMUM_USER_DEFINED_FIELD = 20;
  const { t } = useTranslation();
  const company = useCurrentCompany();
  const dispatch = useDispatch();
  const userDefinedFieldsValues = useOneWireMultiProbeFields(company.id);
  const [currentDefinedField, setCurrentDefinedField] = useState({});
  const previousUserDefinedFieldsValues = usePrevious(userDefinedFieldsValues);

  const [isFormTouched, setIsFormTouched] = useState(false);

  const showNotification = (toastType, error) => {
    if (onClose) {
      onClose();
    }

    return openToast({
      type: toastType,
      message:
        toastType === ToastType.Success
          ? t('CompanyConfig.Notifications.UserDefinedSuccess', { name: title })
          : error
          ? error
          : t('CompanyConfig.Notifications.UserDefinedError', { error })
    });
  };

  const handleCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSave = () => {
    form.submit();
  };

  const handleSubmit = values => {
    const type = 'temperatureProbeNames';
    const onSuccessActions = undefined;
    dispatch(
      updateOneWireMultiProbeFields({
        body: {
          Config: JSON.stringify(values)
        },
        type,
        companyId: company.id,
        onSuccessActions,
        successToaster: () => showNotification(ToastType.Success),
        errorToaster: error => showNotification(ToastType.Error, error?.response?.body?.message)
      })
    );
  };

  const handleValuesChanged = () => {
    const checkFieldsValue =
      JSON.stringify(form.getFieldsValue()) !== JSON.stringify(userDefinedFieldsValues);
    setIsFormTouched(checkFieldsValue);
  };

  useEffect(() => {
    if (
      JSON.stringify(previousUserDefinedFieldsValues) !== JSON.stringify(userDefinedFieldsValues)
    ) {
      form.setFieldsValue(userDefinedFieldsValues);
    }
    setCurrentDefinedField(userDefinedFieldsValues);
  }, [userDefinedFieldsValues]);

  const modalFooter = [
    <div key="modalFooter">
      <Button
        disabled={!isFormTouched}
        onClick={handleSave}
        type="primary"
        id={BUTTON_IDS.vehicleMaintenanceSave}
      >
        {t('Common.SaveButton')}
      </Button>
      <Button id={BUTTON_IDS.vehicleMaintenanceCancel} onClick={handleCancel}>
        {t('Common.CancelButton')}
      </Button>
    </div>
  ];

  const addField = () => {
    for (let i = 1; i <= MAXIMUM_USER_DEFINED_FIELD; i++) {
      const newFieldName = 'field' + i;
      if (currentDefinedField[newFieldName] === undefined) {
        setCurrentDefinedField({ ...currentDefinedField, ...{ [newFieldName]: '' } });
        break;
      }
    }
    setIsFormTouched(true);
  };

  const removeField = fieldName => {
    const copyDefinedField = { ...currentDefinedField };
    delete copyDefinedField[fieldName];
    setCurrentDefinedField(copyDefinedField);
    setIsFormTouched(true);
  };

  return (
    <Modal
      centered
      className={styles.userDefinedModal}
      footer={modalFooter}
      onCancel={handleCancel}
      title={title}
      open={show}
    >
      <Form
        className={styles.form}
        form={form}
        name={'vehicleMaintenanceForm'}
        onFinish={handleSubmit}
        onValuesChange={handleValuesChanged}
      >
        {currentDefinedField &&
          Object.keys(currentDefinedField).map((e, index) => {
            return (
              <div style={{ display: 'flex', flexDirection: 'row' }} key={index}>
                <Form.Item
                  key={e}
                  name={e}
                  style={{ width: '50%' }}
                  rules={[{ required: true, message: t('Forms.FormValidation.Required') }]}
                >
                  {<Input placeholder={t('CompanyConfig.UserDefinedFields.AddDefinition')} />}
                </Form.Item>
                {Number(e.substring(5, 7)) > UNDELETABLE_FIELDS && (
                  <Form.Item style={{ marginLeft: '10px' }}>
                    <MinusCircleOutlined
                      style={{ fontSize: '20px', display: 'flex' }}
                      className="dynamic-delete-button"
                      onClick={() => removeField(e)}
                    />
                  </Form.Item>
                )}
              </div>
            );
          })}
        {currentDefinedField &&
          Object.keys(currentDefinedField).length < MAXIMUM_USER_DEFINED_FIELD && (
            <Form.Item>
              <Button
                id={BUTTON_IDS.vehicleMaintenanceAddDefinition}
                type="dashed"
                onClick={addField}
                className={styles.vehicleMaintenanceAddDefinition}
                icon={<PlusOutlined style={{ alignSelf: 'center' }} />}
              >
                {t('CompanyConfig.UserDefinedFields.AddDefinition')}
              </Button>
            </Form.Item>
          )}
      </Form>
    </Modal>
  );
}
