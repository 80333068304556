import React from 'react';
import i18next from 'i18nextConfig';
import moment from 'moment';
import { sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import { Divider, Space, Table } from 'antd';

import { Can } from 'features/permissions';
import { SortUtil } from 'components/tables/SortUtil';
import { Toolbar, ToolbarSkeleton } from './Toolbar';

import styles from './DeviceList.module.scss';

export const COLS = {
  DeviceName: {
    get title() {
      return i18next.t('Tracking.Columns.Device Name');
    },
    dataIndex: 'deviceName',
    key: 'deviceName',
    width: 220,
    render: (deviceName, { deviceId }) => (
      <Can onFail={<span>{deviceName}</span>}>
        <Link to={`/settings/devices/id/${deviceId}`}>{deviceName}</Link>
      </Can>
    )
  },
  SortableDeviceName: {
    get title() {
      return i18next.t('Tracking.Columns.Device Name');
    },
    dataIndex: 'deviceName',
    key: 'deviceName',
    width: 200,
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(b, a, 'deviceName'),
    render: (deviceName, { deviceId }) => (
      <Can onFail={<span>{deviceName}</span>}>
        <Link to={`/settings/devices/id/${deviceId}`}>{deviceName}</Link>
      </Can>
    )
  },
  VehicleName: {
    get title() {
      return i18next.t('Tracking.Columns.Vehicle Name');
    },
    dataIndex: 'vehicleName',
    key: 'vehicleName',
    width: 200,
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(b, a, 'vehicleName'),
    render: (vehicleName, { vehicleId }) =>
      vehicleId ? (
        <Can onFail={<span>{vehicleName}</span>}>
          <Link to={`/settings/vehicles/id/${vehicleId}`}>{vehicleName}</Link>
        </Can>
      ) : (
        ''
      )
  },
  HealthMetric: {
    get title() {
      return `${i18next.t('CameraHealth.Health Metric')} / ${i18next.t('CameraHealth.Alert Date')}`;
    },
    dataIndex: 'healthEventTypes',
    key: 'healthEventTypes',
    width: 260,
    render: healthEventTypes =>
      healthEventTypes?.length ? (
        <Space
          size={4}
          direction="vertical"
          split={<Divider style={{ margin: '2px 0' }} />}
          style={{ fontSize: '0.9em' }}
        >
          {sortBy(
            healthEventTypes.map(type => ({
              ...type,
              label: i18next.t([`CameraHealth.Event.${type.type}`, type.type])
            })),
            'label'
          ).map(({ type, label, timestamp }, idx) => (
            <Space direction="vertical" size={4} key={`${type}-${idx}`}>
              <div style={{ fontWeight: 800 }}>{label}</div>
              <div>{timestamp}</div>
            </Space>
          ))}
        </Space>
      ) : (
        ''
      )
  },
  HealthEventType: {
    get title() {
      return `${i18next.t('CameraHealth.Health Event')}`;
    },
    dataIndex: 'healthEventType',
    key: 'healthEventType',
    ellipsis: true,
    render: healthEventType =>
      healthEventType ? i18next.t([`CameraHealth.Event.${healthEventType}`, healthEventType]) : ''
  },
  TriggeredTimestamp: {
    get title() {
      return i18next.t('CameraHealth.Triggered Timestamp');
    },
    dataIndex: 'formattedEventTimestamp',
    key: 'formattedEventTimestamp',
    ellipsis: true
  },
  LastResponse: {
    get title() {
      return i18next.t('CameraHealth.Last Response');
    },
    dataIndex: 'lastResponse',
    key: 'lastResponse',
    width: 180,
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a, b) => moment(a.lastResponse) - moment(b.lastResponse)
  },
  LastLocation: {
    get title() {
      return i18next.t('Tracking.Columns.Last Location');
    },
    key: 'lastLocation',
    dataIndex: 'lastLocation',
    width: 300,
    fixedPosition: true
  },
  LastPinged: {
    get title() {
      return i18next.t('CameraHealth.Last Pinged Timestamp');
    },
    dataIndex: 'formatedLastCommsAt',
    key: 'formatedLastCommsAt',
    ellipsis: true
  },
  DaysInactive: {
    get title() {
      return i18next.t('CameraHealth.NoofDaysInactive');
    },
    dataIndex: 'lastCommsAt',
    key: 'lastCommsAt',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 200,
    ellipsis: true,
    render: lastCommsAt =>
      lastCommsAt
        ? moment()
            .startOf('day')
            .diff(moment(lastCommsAt), 'days')
        : ''
  },
  GPSCoord: {
    get title() {
      return i18next.t('CameraHealth.GPSCoordinates');
    },
    dataIndex: 'gps',
    key: 'gps',
    ellipsis: true,
    render: gps => (gps ? `( lat: ${gps.Lat}, ln: ${gps.Lng}) ` : '-')
  },
  TotalMobileConsumption: {
    get title() {
      return `${i18next.t('CameraHealth.Total Data Consumed')} (GB)`;
    },
    dataIndex: 'totalMobileConsumption',
    key: 'totalMobileConsumption',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    ellipsis: true
  }
};

export const DeviceList = ({
  filterOptions,
  isFilterLoading,
  isListLoading,
  columns,
  devices,
  actionOptions,
  rowKey,
  onChange,
  pagination,
  className
}) => {
  return (
    <div className={`${styles.deviceList} ${className || ''}`}>
      <ToolbarSkeleton
        loading={isFilterLoading}
        filterCount={filterOptions?.length || 0}
        actionCount={actionOptions?.length || 0}
      >
        <Toolbar filterOptions={filterOptions} actionOptions={actionOptions} />
      </ToolbarSkeleton>
      <Table
        rowKey={rowKey || 'deviceId'}
        sticky
        columns={columns}
        dataSource={devices}
        scroll={{ y: 'calc(100vh - 390px)' }}
        loading={isFilterLoading || isListLoading}
        onHeaderRow={(columns, index) => ({
          className: styles.tableHeaderRow
        })}
        onChange={onChange}
        pagination={
          pagination !== false
            ? {
                ...pagination,
                showQuickJumper: false,
                showSizeChanger: false
              }
            : false
        }
      />
    </div>
  );
};
