import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Tabs, Layout, Button, Modal, Space, Checkbox } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from '../Vehicles.module.scss';
import VehicleCalibrateMeters from './VehicleCalibrateMeters';
import VehicleManageMeterSources from './VehicleManageMeterSources';
import { useVehicleCalibrateAccelerometer } from './useVehicleCalibrateAccelerometer';
import { useVehicles, fetchFleets, useIsFetching } from 'features/fleets/fleetsSlice';
import { useDevicesStats } from 'features/devices/devicesStatsSlice';
import { useDispatch } from 'react-redux';
import { useLocalization } from 'features/localization/localizationSlice';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { format } from 'utils/dates';
import moment from 'moment';
import {
  useIsCalibratingVehicleEngineHours,
  useIsCalibratingVehicleOdometer,
  calibrateVehicleMeter,
  setVehicleMeterSource,
  useVehicleMeters,
  fetchVehicleMeters,
  fetchVehicleIOREvts
} from 'features/vehicles/vehiclesMetersSlice';
import { useRedirectToMainFeaturePageOnCompanyChange } from 'features/company/companySlice';
import { useVehicleConfig, getVehicleConfig } from 'features/vehicles/vehiclesConfigsSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useTranslation } from 'react-i18next';
import { useUserKey } from 'features/user/userSlice';
import { MeterSource, MeterSourceCalculatedBy } from 'features/meters';
import { METER_TYPES, PATHS, VehicleConfig } from '../constants';
import { fetchVehiclesStats } from 'features/vehicles/vehiclesStatsSlice';
import { LocalizationUtil } from 'features/localization/localization';
import { fetchTrips, updateVehicleSchedules } from 'features/vehicleMaintenance/schedulesSlice';
import { fetchDeviceMeters, useDevicesMeters } from 'features/devices/devicesMetersSlice';
import { fetchDevicesIOREvts, useIsFetchingDevicesIOREvts } from 'features/devices/devicesSlice';
import { BUTTON_IDS } from 'utils/globalConstants';

const { Footer } = Layout;
const { confirm } = Modal;
const footerStyle = {
  backgroundColor: 'inherit',
  boxShadow: '0px -4px 14px rgba(0, 0, 0, 0.04)',
  position: 'sticky',
  bottom: 0,
  width: '100%'
};

const useVehicleMeterSources = vehicleMeters => {
  const [vehicleMeterSources, setVehicleMeterSources] = useState({});
  const getCalculatedByFromVehicleMeter = useCallback(meter => {
    if (!meter) {
      return;
    }
    return meter.useDifferential ? MeterSourceCalculatedBy.Elapsed : MeterSourceCalculatedBy.Actual;
  }, []);

  useEffect(() => {
    if (vehicleMeters) {
      const vehicleHoursMeter = vehicleMeters.find(
        vehicleMeter =>
          vehicleMeter.type === METER_TYPES.HOURS && vehicleMeter.device?.id && vehicleMeter.source
      );
      const vehicleHoursDevice = vehicleHoursMeter?.device;

      const vehicleOdoMeter = vehicleMeters.find(
        vehicleMeter =>
          vehicleMeter.type === METER_TYPES.ODOMETER &&
          vehicleMeter.device?.id &&
          vehicleMeter.source
      );
      const vehicleOdoDevice = vehicleOdoMeter?.device;
      const vMeterSources = {
        [METER_TYPES.HOURS]: {
          device: vehicleHoursDevice,
          source: vehicleHoursMeter?.source,
          calculatedBy: getCalculatedByFromVehicleMeter(vehicleHoursMeter)
        },
        [METER_TYPES.ODOMETER]: {
          device: vehicleOdoDevice,
          source: vehicleOdoMeter?.source,
          calculatedBy: getCalculatedByFromVehicleMeter(vehicleOdoMeter)
        }
      };
      setVehicleMeterSources(vMeterSources);
    }
  }, [vehicleMeters]);

  const { source, calculatedBy } = useMemo(() => {
    const _source = {
      [METER_TYPES.ODOMETER]:
        vehicleMeterSources[METER_TYPES.ODOMETER]?.device?.id &&
        vehicleMeterSources[METER_TYPES.ODOMETER].source
          ? `${vehicleMeterSources[METER_TYPES.ODOMETER]?.device?.id};${
              vehicleMeterSources[METER_TYPES.ODOMETER].source
            }`
          : '',
      [METER_TYPES.HOURS]:
        vehicleMeterSources[METER_TYPES.HOURS]?.device?.id &&
        vehicleMeterSources[METER_TYPES.HOURS].source
          ? `${vehicleMeterSources[METER_TYPES.HOURS]?.device?.id};${
              vehicleMeterSources[METER_TYPES.HOURS].source
            }`
          : ''
    };
    const _calculatedBy = {
      [METER_TYPES.ODOMETER]: vehicleMeterSources[METER_TYPES.ODOMETER]?.calculatedBy || '',
      [METER_TYPES.HOURS]: vehicleMeterSources[METER_TYPES.HOURS]?.calculatedBy || ''
    };
    return {
      source: _source,
      calculatedBy: _calculatedBy
    };
  }, [vehicleMeterSources]);

  const hasInvalidSource = useMemo(() => {
    const validHours =
      (!!source[METER_TYPES.HOURS] && !!calculatedBy[METER_TYPES.HOURS]) ||
      (!source[METER_TYPES.HOURS] && !calculatedBy[METER_TYPES.HOURS]);
    const validOdometer =
      (!!source[METER_TYPES.ODOMETER] && !!calculatedBy[METER_TYPES.ODOMETER]) ||
      (!source[METER_TYPES.ODOMETER] && !calculatedBy[METER_TYPES.ODOMETER]);
    return !validHours || !validOdometer;
  }, [source, calculatedBy]);

  const onSourceChange = useCallback(
    (type, sourceOption) => {
      setVehicleMeterSources({
        ...vehicleMeterSources,
        [type]: {
          device: sourceOption?.device,
          source: sourceOption?.deviceMeter?.source,
          calculatedBy:
            sourceOption.deviceMeter.source === MeterSource.Gps
              ? MeterSourceCalculatedBy.Elapsed
              : vehicleMeterSources[type]?.calculatedBy
        }
      });
    },
    [vehicleMeterSources]
  );

  const onCalculatedByChange = useCallback(
    (type, newCalculatedBy) => {
      setVehicleMeterSources({
        ...vehicleMeterSources,
        [type]: {
          ...vehicleMeterSources[type],
          calculatedBy: newCalculatedBy
        }
      });
    },
    [vehicleMeterSources]
  );

  return {
    source,
    calculatedBy,
    onSourceChange,
    onCalculatedByChange,
    hasInvalidSource
  };
};

const VehicleCalibrate = () => {
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useRedirectToMainFeaturePageOnCompanyChange('/settings/vehicles');

  const [vehicleNotFound, setVehicleNotFound] = useState(false);
  const handleFetchError = useCallback(
    err => {
      if (history.location.pathname !== PATHS.VEHICLE_DEFAULT) {
        if (err) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: err
            })
          );
        }
        history.replace(PATHS.VEHICLE_DEFAULT);
      }
    },
    [dispatch, history]
  );

  const handleNotFound = useCallback(() => {
    setVehicleNotFound(true);
  }, []);

  const devicesStats = useDevicesStats();

  const vehiclesMeters = useVehicleMeters(id, handleFetchError, handleNotFound);
  const vehicleConfig = useVehicleConfig(
    id,
    VehicleConfig.OdometerForMnt.value,
    handleFetchError,
    handleNotFound
  );
  const vehicles = useVehicles(handleFetchError);
  const userKey = useUserKey();
  const localization = useLocalization();
  const [events, setEvents] = useState([]);
  const isFetchingEvents = useIsFetchingDevicesIOREvts();
  const isFetchingFleets = useIsFetching();
  const [calibrateValues, setCalibrateValues] = useState({
    odometer: '',
    hours: ''
  });
  const [syncDate, setSyncDate] = useState(); //can be set by selecting syncDate or overriding from ignitonOff event

  const calibratingEngionHoursInProgress = useIsCalibratingVehicleEngineHours();
  const calibratingOdometerInProgress = useIsCalibratingVehicleOdometer();
  const calibratingInProgress = useMemo(
    () => ({
      calibratingEngionHoursInProgress,
      calibratingOdometerInProgress
    }),
    [calibratingOdometerInProgress, calibratingEngionHoursInProgress]
  );

  const [rucOdometer, setRucOdometer] = useState({});
  const [changingSourceInProgress, setChangingSourceInProgress] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const parsedId = parseInt(id);
    if (parsedId <= 0 || isNaN(parsedId) || vehicleNotFound) {
      handleFetchError(t('Common.Invalid Request ID'));
    }
  }, [t, id, vehicleNotFound, handleFetchError]);

  const vehicleData = useMemo(() => {
    const vehicle = vehicles.find(v => parseInt(v.id, 10) === parseInt(id, 10));
    return {
      ...vehicle,
      devices:
        vehicle?.devices?.map(device => {
          const deviceStats = devicesStats.find(
            ds => parseInt(ds.deviceId, 10) === parseInt(device.id, 10)
          );
          return {
            ...device,
            stats: deviceStats
          };
        }) || [],
      meters: vehiclesMeters
    };
  }, [vehicles, devicesStats, id, vehiclesMeters]);

  const vehicleDeviceIds = useMemo(() => {
    return vehicleData?.devices?.map(device => device.id) || [];
  }, [vehicleData]);

  const { devicesMeters, isFetching: isFetchingDevicesMeters } = useDevicesMeters(vehicleDeviceIds);

  const options = useMemo(() => {
    const devices = isFetchingDevicesMeters
      ? []
      : vehicleData?.devices?.map(device => ({
          device,
          deviceMeters: devicesMeters[device.id]
        })) || [];
    return devices
      .filter(device => device?.deviceMeters?.length)
      .map(({ deviceMeters, device }) => {
        return deviceMeters
          ?.filter(dm => dm.source !== MeterSource.Ruc)
          .map(dm => ({
            value: `${dm.device.id};${dm.source}`,
            label: `${device?.type?.name} - ${device.name} - ${dm.source}`,
            type: dm.type,
            deviceMeter: dm,
            device
          }));
      })
      .reduce((a, c) => [...a, ...c], []);
  }, [devicesMeters, vehicleData, isFetchingDevicesMeters]);

  useEffect(() => {
    dispatch(
      setPageTitle(
        vehicleData.name && `${t('Vehicles.CalibratingVehicleMeters')} - ${vehicleData.name}`
      )
    );
    dispatch(setBackButton(true));
  }, [vehicleData, dispatch, t]);

  const {
    source,
    calculatedBy,
    onSourceChange,
    onCalculatedByChange,
    hasInvalidSource
  } = useVehicleMeterSources(vehiclesMeters);

  useEffect(() => {
    setRucOdometer({
      show: vehicleConfig.hasEDRDevice || vehicleConfig.isUsingRUCOdometerForMnt,
      value: vehicleConfig.isUsingRUCOdometerForMnt
    });
  }, [vehicleConfig]);

  const vehicleIsRUCConfigurable = useMemo(() => {
    return (
      (id && vehicleData?.devices?.some(device => device?.type?.code === 'EDR')) || rucOdometer.show
    );
  }, [vehicleData, id, rucOdometer]);

  const onSyncDateChange = useCallback(
    date => {
      setSyncDate(date);
      if (!date) {
        return;
      }
      if (vehicleData.id) {
        const dateStart = date.subtract(1, 'days').toISOString();
        const dateEnd = date.add(1, 'days').toISOString();

        dispatch(fetchTrips(dateStart, dateEnd, vehicleData.id, localization, t)).then(trips => {
          const ignitionOffTrips = trips?.filter(trip => trip.IgnOffGPS) || [];
          setEvents(
            ignitionOffTrips.map(event => ({
              value: format(new Date(event.timeAt), localization.formats.time.formats.dby_ims),
              label: `${t('Vehicles.IgnitionOffEvent')} (${format(
                new Date(event.timeAt),
                localization.formats.time.formats.dby_imp
              )})`,
              evtSyncDate: moment(new Date(event.timeAt)).add(1, 'minutes')
            }))
          );
        });
      } else {
        setEvents([]);
      }
    },
    [userKey, vehicleData, localization]
  );

  const onEventChange = useCallback(
    evtOption => {
      setSyncDate(evtOption?.evtSyncDate);
    },
    [setSyncDate]
  );

  const onCalibrateValueChange = useCallback(
    (value, type) => {
      setCalibrateValues({
        ...calibrateValues,
        [type]: value
      });
    },
    [calibrateValues, setCalibrateValues]
  );

  const onCalibrate = useCallback(
    type => {
      if (!syncDate || !calibrateValues[type]) {
        Modal.error({
          content: t('Vehicles.CalibrateSelectSyncDate')
        });
        return;
      }
      confirm({
        title: t('Common.Modal.SureTitle'),
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            {t('Vehicles.CalibrateMessage1')}
            <br />
            {t('Vehicles.CalibrateMessage2')}
          </div>
        ),
        onOk() {
          const localizedValue =
            localization.formats.speed.unit === 'mi' && type === METER_TYPES.ODOMETER
              ? LocalizationUtil.miletokm(parseFloat(calibrateValues[type]))
              : calibrateValues[type];
          dispatch(calibrateVehicleMeter(id, type, syncDate.toISOString(), localizedValue)).then(
            calibrated => {
              if (calibrated) {
                onRefresh();
                //should only update current editing type,instead of reset calibrateValues
                setCalibrateValues({
                  ...calibrateValues,
                  [type]: ''
                });
                dispatch(
                  openToast({
                    type: ToastType.Success,
                    message: t('Vehicles.CalibrateSuccess')
                  })
                );
              } else {
                dispatch(
                  openToast({
                    type: ToastType.Error,
                    message: t('Vehicles.CalibrateFailure')
                  })
                );
              }
            }
          );
        }
      });
    },
    [syncDate, calibrateValues, setCalibrateValues, id, localization, userKey]
  );

  const onRefresh = useCallback(() => {
    dispatch(fetchFleets());
    dispatch(fetchVehicleMeters(id, userKey));
    vehicleDeviceIds.forEach(deviceId => dispatch(fetchDeviceMeters(deviceId, userKey)));
    dispatch(fetchVehiclesStats());
    if (vehicleIsRUCConfigurable) {
      dispatch(getVehicleConfig(id, VehicleConfig.OdometerForMnt.value));
    }
    dispatch(updateVehicleSchedules());
  }, [rucOdometer, vehicleIsRUCConfigurable, id, userKey]);

  const onCancel = () => {
    setCalibrateValues({
      hours: '',
      odometer: ''
    });
  };

  const onSourceUpdate = useCallback(() => {
    confirm({
      title: t('Common.Modal.SureTitle'),
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          {t('Vehicles.SourceUpdateSureMessage1')}
          <br />
          {t('Vehicles.SourceUpdateSureMessage2')}
        </div>
      ),
      onOk() {
        const vehicleId = vehicleData.id;
        const sd = source.odometer?.split(';') || [];
        const deviceId = Number(sd[0]);
        const deviceType = sd[1];
        const hasOdometerCanMeter = options.some(
          option =>
            option.value.indexOf(MeterSource.Can) > -1 && option.type === METER_TYPES.ODOMETER
        );
        setChangingSourceInProgress(true);
        setVehicleMeterSource(
          vehicleId,
          deviceId,
          deviceType,
          userKey,
          source.hours,
          calculatedBy.hours,
          calculatedBy.odometer,
          hasOdometerCanMeter,
          rucOdometer.show ? { value: rucOdometer.value } : null
        ).then(response => {
          setChangingSourceInProgress(false);
          if (response) {
            onRefresh();
            onCancel();
            dispatch(
              openToast({
                type: ToastType.Success,
                message: t('Vehicles.SourceUpdateSuccess')
              })
            );
          } else {
            dispatch(
              openToast({
                type: ToastType.Error,
                message: t('Vehicles.SourceUpdateFailure')
              })
            );
          }
        });
      }
    });
  }, [rucOdometer, vehicleData, source, calculatedBy]);

  const onCheckRucOdometer = value => {
    setRucOdometer({ ...rucOdometer, value: value.target.checked });
  };
  const disableSourceUpdate = useMemo(() => {
    return hasInvalidSource || (!options?.length && !rucOdometer.show);
  }, [hasInvalidSource, options, rucOdometer]);

  const onAccelerometerCalibrated = useCallback(
    calibrated => {
      if (calibrated) {
        onRefresh();
      }
    },
    [dispatch, onRefresh]
  );

  const { canUse, VehicleCalibrateAccelerometer } = useVehicleCalibrateAccelerometer({
    vehicleData,
    onAccelerometerCalibrated
  });

  return (
    <div className={styles.tabby}>
      <Tabs
        defaultActiveKey="1"
        animated={false}
        size="large"
        tabBarStyle={{ boxShadow: 'none', paddingLeft: '20px' }}
        items={[
          {
            label: t('Vehicles.CalibrateVehicleMeters'),
            key: '1',
            style: { boxShadow: 'none' },
            children: (
              <Layout style={{ backgroundColor: 'inherit' }}>
                <VehicleCalibrateMeters
                  onEventChange={onEventChange}
                  onSyncDateChange={onSyncDateChange}
                  disableSyncDatePicker={isFetchingFleets}
                  isFetchingEvents={isFetchingEvents}
                  events={events}
                  vehicleData={vehicleData}
                  calibrateValues={calibrateValues}
                  onCalibrateValueChange={onCalibrateValueChange}
                  onCalibrate={onCalibrate}
                  calibratingInProgress={calibratingInProgress}
                />
                <Footer className={styles.vehicleCalibrateFormFooter}>
                  <Space size={16}>
                    <Button
                      size="large"
                      id={BUTTON_IDS.vehicleCalibrateRefresh}
                      onClick={onRefresh}
                      loading={isFetchingFleets}
                    >
                      {t('Common.Refresh')}
                    </Button>
                    <Button
                      id={BUTTON_IDS.vehicleCalibrateCancel}
                      onClick={history.goBack}
                      size="large"
                    >
                      {t('Common.Cancel')}
                    </Button>
                  </Space>
                </Footer>
              </Layout>
            )
          },
          {
            label: t('Vehicles.ManageSources'),
            key: '2',
            style: { boxShadow: 'none' },
            children: (
              <Layout style={{ backgroundColor: 'inherit' }}>
                <VehicleManageMeterSources
                  onSourceChange={onSourceChange}
                  source={source}
                  calculatedBy={calculatedBy}
                  onCalculatedByChange={onCalculatedByChange}
                  changingSourceInProgress={changingSourceInProgress}
                  options={options}
                />
                {rucOdometer.show && (
                  <div className="use-ruc-odometer-checkbox">
                    <Checkbox
                      name="useRucOdometer"
                      checked={rucOdometer.value}
                      onChange={onCheckRucOdometer}
                    >
                      {t('Vehicles.UseRucOdometer')}
                    </Checkbox>
                  </div>
                )}
                <Footer className={styles.vehicleCalibrateFormFooter}>
                  <Space size={16}>
                    <Button
                      onClick={onSourceUpdate}
                      size="large"
                      type="primary"
                      disabled={disableSourceUpdate}
                      id={BUTTON_IDS.vehicleCalibrateUpdate}
                    >
                      {t('Common.Update')}
                    </Button>
                    <Button
                      onClick={history.goBack}
                      size="large"
                      id={BUTTON_IDS.vehicleCalibrateCancel}
                    >
                      {t('Common.Cancel')}
                    </Button>
                  </Space>
                </Footer>
              </Layout>
            )
          },
          canUse && {
            label: t('Vehicles.CalibrateAccelerometer.Title'),
            key: '3',
            style: { boxShadow: 'none' },
            children: (
              <Layout style={{ backgroundColor: 'inherit' }}>
                <VehicleCalibrateAccelerometer
                  vehicleData={vehicleData}
                  calibrateValues={calibrateValues}
                />
              </Layout>
            )
          }
        ].filter(Boolean)}
      />
    </div>
  );
};

export default VehicleCalibrate;
