import { EventsTab } from './SubTabs/EventsTab';
import { SnapshotsTab } from './SubTabs/SnapshotsTab';
import { SnapshotsTabExtras } from './SubTabs/SnapshotsTabExtras';
import { VideoRequestsTab } from './SubTabs/VideoRequestsTab';
import { CameraHealthTab } from './SubTabs/CameraHealthTab';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcaseMedical } from '@fortawesome/free-solid-svg-icons';
import { FeatureFlag, services } from 'features/permissions';
import i18next from 'i18next';

export const ViewMode = {
  GridView: 'GridView',
  ListView: 'ListView'
};

export const CameraTabs = [
  {
    title: 'Snapshots',
    path: '/home/cameras',
    component: SnapshotsTab,
    tabExtras: SnapshotsTabExtras
  },
  {
    title: 'Events',
    path: '/home/cameras/events',
    component: EventsTab
  },
  {
    title: 'Video Requests',
    path: '/home/cameras/video_requests',
    component: VideoRequestsTab
  },
  {
    title: 'CameraHealth',
    path: '/home/cameras/camera_health',
    component: CameraHealthTab,
    icon: t => (
      <Tooltip title={t('Home.CameraHealth')}>
        <FontAwesomeIcon icon={faBriefcaseMedical} style={{ fontSize: '21px' }} />
      </Tooltip>
    ),
    position: 'end',
    can: {
      everyCompanyService: [services.IQ]
    }
  }
];

export const CameraEventTypes = {
  ACC_HIGH: 'High Acceleration',
  ACC_MEDIUM: 'Medium Acceleration',
  ACC_LOW: 'Low Acceleration',
  SPEED_VIOLATION: 'Signposted Speed Violation',
  DRIVER_INPUT: 'Driver Input',
  DISTRACTION: 'Distraction',
  FATIGUE: 'Fatigue',
  HARSH_BRAKING: 'Harsh Braking',
  HARSH_CORNERING: 'Harsh Cornering',
  HARSH_USAGE: 'Harsh Usage',
  IO_INPUT: 'I/O Input',
  SIGN_VIOLATION: 'Sign Violation',
  STOP_SIGN_VIOLATION: 'Stop Sign Violation',
  SNAPSHOT: 'Snapshot',
  CONFIG_SPEED_VIOLATION: 'Maximum Speed',
  VIDEO_REQUEST: 'Video Request',
  OTHER: 'Other',
  TAIL_GATING: 'Tailgating',
  LANE_DRIFT_WARNING: 'Lane Drift',
  COLLISION_WARNING: 'Collision Warning',
  CELLPHONE_DISTRACTION: 'Cellphone Distraction',
  GAZE_DOWN_DISTRACTION: 'Gaze Down Distraction',
  DROWSY_DRIVING_DETECTED: 'Drowsy Driving',
  POTENTIAL_CRASH: 'Potential Crash',
  UNBUCKLED_SEATBELT: 'Seatbelt Violation',
  ROLLOVER_DETECTED: 'Rollover Detection',
  TRAFFIC_LIGHT_VIOLATION: 'Traffic Light Violation'
  //Following two types are supported by API but exclude until not in lightmetrics beta
  // TEXTING_DISTRACTION:'Texting Distraction',
  // SMOKING_DISTRACTION:'Smoking Distraction'
};

export const getCameraEventTypeName = ({ typeKey, can }) =>
  i18next.t(
    `Common.CameraEventTypes.${
      ['UNBUCKLED_SEATBELT', 'ROLLOVER_DETECTED', 'TRAFFIC_LIGHT_VIOLATION'].includes(typeKey)
        ? can({
            oneOfFeatureFlags: [
              FeatureFlag.addIQEvents.flag,
              FeatureFlag.addIQEventsCapitalElectric.flag
            ]
          })
          ? CameraEventTypes[typeKey]
          : CameraEventTypes.OTHER
        : CameraEventTypes[typeKey] || CameraEventTypes.OTHER
    }`
  );

export const isSupportedCameraEventTypeKey = ({ typeKey, can }) => {
  const canAddIQEvents = can({
    oneOfFeatureFlags: [FeatureFlag.addIQEvents.flag, FeatureFlag.addIQEventsCapitalElectric.flag]
  });

  const AddedIQEvents = ['UNBUCKLED_SEATBELT', 'ROLLOVER_DETECTED', 'TRAFFIC_LIGHT_VIOLATION'];

  return AddedIQEvents.includes(typeKey) ? canAddIQEvents : true;
};

export const getSupportedCameraEventTypes = can =>
  Object.keys(CameraEventTypes).reduce(
    (a, typeKey) =>
      isSupportedCameraEventTypeKey({ typeKey, can })
        ? { ...a, [typeKey]: CameraEventTypes[typeKey] }
        : a,
    {}
  );

export const AttachmentStatus = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  READY_FOR_DOWNLOAD: 'READY_FOR_DOWNLOAD',
  PENDING: 'PENDING',
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  FAILED: 'FAILED',
  AVAILABLE_ON_REQUEST: 'AVAILABLE_ON_REQUEST',
  REQUESTED: 'REQUESTED',
  REMOVED: 'REMOVED',
  INPROGRESS: 'INPROGRESS'
};

export const CAMERA_POSITION = {
  FRONT: 'FRONT',
  DRIVER: 'DRIVER',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  LEFT_PASSENGER: 'LEFT_PASSENGER',
  RIGHT_DRIVER: 'RIGHT_DRIVER',
  CAMERA0: 'CAMERA0',
  CAMERA1: 'CAMERA1',
  CAMERA2: 'CAMERA2',
  CAMERA3: 'CAMERA3',
  CAMERA4: 'CAMERA4',
  CAMERA5: 'CAMERA5',
  CAMERA6: 'CAMERA6',
  CAMERA7: 'CAMERA7',
  CAMERA8: 'CAMERA8',
  CAMERA9: 'CAMERA9',
  CAMERA10: 'CAMERA10',
  CAMERA11: 'CAMERA11',
  CAMERA12: 'CAMERA12',
  CAMERA13: 'CAMERA13',
  CAMERA14: 'CAMERA14',
  CAMERA15: 'CAMERA15',
  CAMERA16: 'CAMERA16',
  UNKNOWN: 'UNKNOWN'
};

export const AvailableTableColumns = {
  RequestStatus: 'RequestStatus',
  RequestTime: 'RequestTime',
  Device: 'Device',
  Vehicle: 'Vehicle',
  Fleet: 'Fleet',
  Driver: 'Driver',
  EventTime: 'EventTime',
  Location: 'Location',
  Type: 'Type',
  Status: 'Status',
  CameraPosition: 'CameraPosition',
  Details: 'Details',
  Actions: 'Actions'
};

export const EventStatusToRequestStatus = {
  ENABLED: 'Enabled',
  REQUESTED: 'Pending',
  FAILED: 'Failed',
  AVAILABLE: 'Available',
  INPROGRESS: 'In Progress'
};

export function extractEventExtras(extras) {
  try {
    const errors = JSON.parse(extras).Errors?.sort(
      (err1, err2) => new Date(err2.TimeAt) - new Date(err1.TimeAt)
    );
    const error = errors?.[0];
    return {
      type: error?.MessageType,
      details: error?.MessageType
        ? i18next.t(`Footage.RequestDetails.${error.MessageType}`)
        : error?.Message || error?.Error || '',
      timeAt: error?.TimeAt
    };
  } catch (err) {
    return {};
  }
}

export function extractEventAttachmentStatus(event) {
  if (event.attachmentStatus === AttachmentStatus.AVAILABLE_ON_REQUEST && event.extras) {
    if (extractEventExtras(event.extras).type === ErrorMessageTypes.MEDIA_NOT_AVAILABLE) {
      return AttachmentStatus.UNAVAILABLE;
    }
  }
  return event.attachmentStatus;
}

export function extractCameraError(e) {
  let message = '';
  let errorJson = null;
  let messageType = null;
  if (!(e instanceof Object)) {
    try {
      errorJson = JSON.parse(e);
    } catch (err) {}
  } else {
    errorJson = e;
  }
  if (errorJson?.message || errorJson?.error) {
    message = errorJson.message || errorJson.error;
  } else if (errorJson?.Errors?.length > 0) {
    message = errorJson.Errors[0].Message;
    messageType = errorJson.Errors[0].MessageType;
  } else {
    message = e;
  }
  return { message, messageType };
}

export function extractCameraSuccessInfo(cameraStatus) {
  const statusObj = cameraStatus?.Message || (cameraStatus?.Errors || [])?.[0];
  return statusObj?.MessageType === 'LIMIT_WARN' ? statusObj?.Message : null;
}

export const ErrorMessageTypes = {
  MEDIA_NOT_AVAILABLE: 'MEDIA_NOT_AVAILABLE',
  MEDIA_REQUEST_PENDING: 'MEDIA_REQUEST_PENDING',
  MEDIA_UPLOAD_FAILED: 'MEDIA_UPLOAD_FAILED',
  BAD_MEDIA_REQUEST: 'BAD_MEDIA_REQUEST',
  DEVICE_NOT_FOUND: 'DEVICE_NOT_FOUND',
  MEDIA_REQUEST_DUPLICATED: 'MEDIA_REQUEST_DUPLICATED'
};

export const StatusFilterOptions = {
  AVAILABLE: 'AVAILABLE',
  AVAILABLE_ON_REQUEST: 'AVAILABLE_ON_REQUEST',
  FAILED: 'FAILED',
  REQUESTED: 'REQUESTED',
  UNAVAILABLE: 'UNAVAILABLE'
};

export const RequestStatusFilterOptions = {
  AVAILABLE: 'Available',
  INPROGRESS: 'In Progress',
  FAILED: 'Failed'
};

export const ALLOWED_EVENT_TYPES_CAMERA_HEALTH_OVERVIEW = [
  'RoadCameraLensCovered',
  'DriverCameraLensCovered',
  'PowerCutAlert',
  'ExternalStorageAvailable',
  'DataNotUploading',
  'MissingDailySnapshot',
  'DrivingWithIgnitionOFF',
  'DeviceOffline'
];
