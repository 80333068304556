import React, { useMemo } from 'react';
import { Button, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { writeFile, utils } from 'xlsx';
import { useTranslation } from 'react-i18next';

export const FileType = {
  EXCEL: 'xlsx',
  CSV: 'csv'
};

const defaultFormater = ({ columnName, columnValue, columnDataIndex }) => ({
  columnName,
  columnValue
});

export const DeviceListExportButton = ({
  id,
  type = 'primary',
  devices,
  columns,
  fileName,
  formater = defaultFormater
}) => {
  const { t } = useTranslation();
  const menu = useMemo(
    () => ({
      items: [
        {
          key: '1',
          label: t('Common.ExportToCSV')
        },
        {
          key: '2',
          label: t('Common.ExporttoExcel')
        }
      ],
      onClick: ({ key }) => {
        if (key === '1') {
          exportCSVFile({
            fileName: fileName || t('Common.Devices'),
            devices,
            columns,
            formater,
            sheetName: t('Common.Devices')
          });
        } else if (key === '2') {
          exportExcelFile({
            fileName: fileName || t('Common.Devices'),
            devices,
            columns,
            formater,
            sheetName: t('Common.Devices')
          });
        }
      }
    }),
    [t, devices, columns, fileName, formater]
  );

  return (
    <Dropdown disabled={!devices?.length} menu={menu} placement="bottomRight">
      <Button id={id} size="medium" type={type}>
        <Space>
          {t('Common.Export')}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

const exportCSVFile = ({
  devices = [],
  columns = [],
  fileName,
  sheetName,
  formater = defaultFormater
}) => {
  const workbook = utils.book_new();
  const sheet = utils.json_to_sheet(
    devices.map(device =>
      columns.reduce((cols, col) => {
        const { columnName, columnValue } = formater({
          columnName: col.title,
          columnValue: device?.[col.dataIndex],
          columnDataIndex: col.dataIndex
        });
        return {
          ...cols,
          [columnName]: Array.isArray(columnValue) ? columnValue.join(', ') : columnValue
        };
      }, {})
    )
  );
  utils.book_append_sheet(workbook, sheet, sheetName);
  writeFile(workbook, `${fileName}.${FileType.CSV}`);
};

const exportExcelFile = ({
  devices = [],
  columns = [],
  fileName,
  sheetName,
  formater = defaultFormater
}) => {
  const workbook = utils.book_new();
  const merges = [],
    colWidths = [];
  let rowPoint = 1;
  const worksheetJson = devices.reduce((acc, device) => {
    const deviceCols = {};
    let mergeAnchorColumnName = null;
    columns.forEach(col => {
      const { columnName, columnValue } = formater({
        columnName: col.title,
        columnValue: device?.[col.dataIndex],
        columnDataIndex: col.dataIndex
      });
      if (Array.isArray(columnValue) && columnValue.length > 0) {
        mergeAnchorColumnName = columnName;
      }
      deviceCols[columnName] = columnValue;
    });

    if (mergeAnchorColumnName) {
      const deviceRows = deviceCols[mergeAnchorColumnName].map(rowColValue => ({
        ...deviceCols,
        [mergeAnchorColumnName]: rowColValue
      }));

      Object.keys(deviceRows[0]).forEach((colName, colIndex) => {
        if (colName === mergeAnchorColumnName) {
          colWidths.push({
            wpx: 250
          });
        } else {
          merges.push({
            s: { r: rowPoint, c: colIndex },
            e: { r: rowPoint + deviceRows.length - 1, c: colIndex }
          });
          colWidths.push({
            wpx: 120
          });
        }
      });
      rowPoint += deviceRows.length;
      return [...acc, ...deviceRows];
    } else {
      rowPoint++;
      return [...acc, deviceCols];
    }
  }, []);
  const worksheet = utils.json_to_sheet(worksheetJson);
  worksheet['!merges'] = merges;
  worksheet['!cols'] = colWidths;
  utils.book_append_sheet(workbook, worksheet, sheetName);
  writeFile(workbook, `${fileName}.${FileType.EXCEL}`);
};
