import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Button, Space, Dropdown } from 'antd';
import { BUTTON_IDS, BULK_EDIT_ENTITY } from './helper';
import { useCan, entities, FeatureFlag } from 'features/permissions';
import { useIsBulkDeleting, useIsBulkUpdating } from 'features/devices/devicesSlice';
import { EntityEditModal } from './EntityEditModal/EntityEditModal';

import styles from './DevicesBulkEdit.module.scss';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';
import services from 'features/permissions/services';
import { useHistory } from 'react-router';
import { useUser } from 'features/user/userSlice';

export const BulkEditHeader = ({
  selectedRows = [],
  onBulkSave = () => {},
  onBulkDelete = () => {},
  onBulkAssignment = () => {},
  isBulkAssigningAgreement = false
}) => {
  const { t } = useTranslation();
  const [bulkEditEntity, setBulkEditEntity] = useState(null);
  const deviceTypes = useDeviceTypesList();

  const can = useCan();
  const history = useHistory();
  const canEditDevice = can({ everyEntity: [entities.DEVICE_UPDATE] });
  const currentUser = useUser();
  const isSiteAdmin = currentUser.siteAdmin;

  const canDeleteDevice = can({ everyEntity: [entities.DEVICE_DESTROY] }) && isSiteAdmin;
  const isEditing = useIsBulkUpdating();
  const isDeleting = useIsBulkDeleting();
  const isProcessing = useMemo(() => isEditing || isDeleting, [isEditing, isDeleting]);

  const { getMenuItemMeta, menu } = useMemo(() => {
    const getMenuItemMeta = entity => {
      const selectedCompanyIds = new Set(
        selectedRows.map(device => device.companyId).filter(companyId => !!companyId)
      );
      const selectedTypeIds = new Set(
        selectedRows.map(device => device.typeId).filter(typeId => !!typeId)
      );
      const isAllSameCompany = selectedCompanyIds.size < 2;
      const isAllSameType = selectedTypeIds.size < 2;
      const isAllStandalone = !selectedRows.some(device => !!device?.vehicleId);
      const hermesDeviceTypeId = (deviceTypes || []).find(i => i?.code === 'HERMES');
      const isSomeNonHermes = selectedRows.some(
        device => device?.typeId !== hermesDeviceTypeId?.id
      );
      const isSomeNonGpio = selectedRows.some(device => !device?.services.includes(services.GPIO));

      switch (entity) {
        case BULK_EDIT_ENTITY.COMPANY.entity:
          return {
            label: t('Entity.Company'),
            disabled: !isAllStandalone,
            disabledTootip: t('Devices.BulkEdit.Tooltip.StandaloneOnly'),
            required: true,
            id: BUTTON_IDS.edit_company
          };
        case BULK_EDIT_ENTITY.TYPE.entity:
          return {
            label: t('Vehicles.Form.Type'),
            id: BUTTON_IDS.edit_type
          };
        case BULK_EDIT_ENTITY.MODEL.entity:
          return {
            label: t('Vehicles.Form.Model'),
            disabled: !isAllSameType,
            disabledTootip: t('Devices.BulkEdit.Tooltip.SameTypeOnly'),
            deviceTypeId: Array.from(selectedTypeIds)[0],
            id: BUTTON_IDS.edit_model
          };
        case BULK_EDIT_ENTITY.VEHICLE.entity:
          return {
            label: t('Vehicles.Form.Vehicle'),
            id: BUTTON_IDS.edit_vehicle
          };
        case BULK_EDIT_ENTITY.FLEETS.entity:
          return {
            label: t('Fleets.Fleets'),
            disabled: !(isAllStandalone && isAllSameCompany && Array.from(selectedCompanyIds)?.[0]),
            disabledTootip: t(
              `Devices.BulkEdit.Tooltip.${!isAllStandalone ? 'StandaloneOnly' : 'SameCompanyOnly'}`
            ),
            companyId: Array.from(selectedCompanyIds)[0],
            id: BUTTON_IDS.edit_fleets
          };
        case BULK_EDIT_ENTITY.PHONE_NUMBER.entity:
          return {
            label: t('Devices.ActualForm.PhoneLabel'),
            disabled: !(isAllSameCompany && Array.from(selectedCompanyIds)?.[0]),
            disabledTootip: t('Devices.BulkEdit.Tooltip.SameCompanyOnly'),
            companyId: Array.from(selectedCompanyIds)[0],
            id: BUTTON_IDS.edit_phone
          };
        case BULK_EDIT_ENTITY.GPIO_CONFIGURATION.entity:
          return {
            label: t('Devices.ActualForm.GpioConfig'),
            disabled: isSomeNonHermes || isSomeNonGpio,
            disabledTootip: t('Devices.BulkEdit.Tooltip.HermesAndGpioOnly'),
            companyId: Array.from(selectedCompanyIds)[0],
            id: BUTTON_IDS.edit_gpio_configuration
          };

        case BULK_EDIT_ENTITY.NOTES.entity:
          return {
            label: t('Devices.ActualForm.NotesLabel'),
            placeholder: t('Devices.ActualForm.NotesPlaceholder'),
            id: BUTTON_IDS.edit_notes
          };
        default:
          return {};
      }
    };

    return {
      getMenuItemMeta,
      menu: {
        onClick: ({ key }) => setBulkEditEntity(key),
        items: Object.values(BULK_EDIT_ENTITY)
          .filter(i => i.can === undefined || can(i.can))
          .map(opt => {
            const meta = getMenuItemMeta(opt.entity);
            return {
              label: (
                <Tooltip title={meta.disabled && meta.disabledTootip} placement="left">
                  <div>{meta.label}</div>
                </Tooltip>
              ),
              id: meta.id,
              key: opt.entity,
              disabled: meta.disabled
            };
          })
      }
    };
  }, [selectedRows, deviceTypes]);

  const onEditConfirm = useCallback(
    (entityValues, callback = ({ allSuccess, allFailed, devices, entity, entityValue }) => {}) => {
      onBulkSave(bulkEditEntity, entityValues, selectedRows, callback);
      setBulkEditEntity(null);
    },
    [onBulkSave, selectedRows, bulkEditEntity]
  );

  const onEditCancel = useCallback(() => {
    setBulkEditEntity(null);
  }, []);

  return (
    <>
      <div>
        <span>{`${t('Devices.BulkEdit.SelectedRows', { count: selectedRows.length })}.`}</span>
        {!selectedRows.length && (
          <span className={styles.topBarInfo}>{`${t('Devices.BulkEdit.SelectedRowsInfo')}.`}</span>
        )}
      </div>
      {!!selectedRows.length && (
        <Space>
          {isBulkAssigningAgreement ? (
            <Button type="default" onClick={() => onBulkAssignment(selectedRows)}>
              <span>{t('Devices.BulkEdit.AssignAgreements')}</span>
            </Button>
          ) : (
            <>
              {canEditDevice && (
                <Dropdown menu={menu} disabled={isProcessing}>
                  <Button
                    type="primary"
                    id={BUTTON_IDS.edit_dropdown}
                    className={styles.bulkEditDropdown}
                  >
                    <Space>
                      {t('Devices.BulkEdit.EditColumn')}
                      <i className={'tn-i-chevron-down'} />
                    </Space>
                  </Button>
                </Dropdown>
              )}
              {canDeleteDevice && (
                <Button
                  disabled={isProcessing}
                  id={BUTTON_IDS.delete_multi_devices}
                  onClick={() => onBulkDelete(selectedRows)}
                >
                  <>
                    <span>{`${t('Common.DeleteButton')} ${selectedRows.length} `}</span>
                    <span style={{ textTransform: 'lowercase' }}>{`${
                      selectedRows.length < 2 ? t('Devices.Device') : t('Devices.Devices')
                    }`}</span>
                  </>
                </Button>
              )}
            </>
          )}
        </Space>
      )}
      <EntityEditModal
        show={bulkEditEntity && getMenuItemMeta(bulkEditEntity)}
        entity={bulkEditEntity}
        devices={selectedRows}
        onOk={onEditConfirm}
        onCancel={onEditCancel}
        {...getMenuItemMeta(bulkEditEntity)}
      />
    </>
  );
};
