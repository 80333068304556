import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, Row, Col, List } from 'antd';

import { useTranslation } from 'react-i18next';

import {
  useUserGridSettings,
  useIsUserGridSettingsUpdating,
  updateUserGridSettings
} from 'features/user/userGridSettingsSlice';

import {
  getClonedConfigOrDefault,
  getIncludedEventTypes,
  updateIncludedEventTypes
} from 'features/tracking/trackingGridSettings';

import { useSupportedEventTypes } from 'containers/Tracking/EventTypes';
import { FilterEventsSelector } from './FilterEventsSelector';

import { CloseCircleFilled } from '@ant-design/icons';

import styles from './FilterEventsModal.module.scss';

import defaultEventImg from 'components/map/markers/images/grey.png';

import { BUTTON_IDS } from 'utils/globalConstants';

export const FilterEventsModal = ({ gridSettingsKey, isOpen = false, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userGridSettings = useUserGridSettings(gridSettingsKey);
  const isUserGridSettingsUpdating = useIsUserGridSettingsUpdating(gridSettingsKey);

  const [newUserGridSettings, setNewUserGridSettings] = useState(null);
  const [includeAllEventTypes, setIncludeAllEventTypes] = useState(true);
  const [includedEventTypes, setIncludedEventTypes] = useState([]);

  // Get all event types with duplicates labels removed and sorted
  const {
    addEventTypesWithDuplicateLabels,
    removeEventTypesWithDuplicateLabels,
    allEventTypesNoDuplicatesSorted: allEventTypesNoDuplicates
  } = useSupportedEventTypes();

  const onFilterEventsModalSave = () => {
    const includedEventTypesWithDuplicates = addEventTypesWithDuplicateLabels(includedEventTypes);
    const updatedUserGridSettings = updateIncludedEventTypes(
      newUserGridSettings,
      includeAllEventTypes,
      includedEventTypesWithDuplicates
    );

    dispatch(updateUserGridSettings(updatedUserGridSettings, gridSettingsKey, onClose, true));
  };

  const onRemove = eventType => {
    // Remove item from included event types
    const newIncludedEventTypes = includedEventTypes.filter(item => {
      return item.key !== eventType.key;
    });
    setIncludedEventTypes(newIncludedEventTypes);
    setIncludeAllEventTypes(false);
  };

  const handleCancel = () => {
    // Todo: Warn user if there are unsaved changes

    onClose();
  };

  useEffect(() => {
    if (userGridSettings && userGridSettings.lastFetched) {
      let newIncludeAllEventTypes = true;
      let newIncludedEventTypes = [];

      const newConfig = getClonedConfigOrDefault(userGridSettings);
      setNewUserGridSettings(newConfig);

      // Check if config has any data yet
      if (newConfig) {
        let result = getIncludedEventTypes(newConfig, allEventTypesNoDuplicates);
        newIncludeAllEventTypes = result.includeAllEventTypes;
        newIncludedEventTypes = removeEventTypesWithDuplicateLabels(result.includedEventTypes);
      }

      setIncludedEventTypes(newIncludedEventTypes);
      setIncludeAllEventTypes(newIncludeAllEventTypes);
    }
  }, [userGridSettings]);

  return (
    <Modal
      title={t('Tracking.FilterEvents.FilterEvents')}
      open={isOpen}
      centered={true}
      closable={false}
      width={460}
      footer={null}
      wrapClassName={styles.filterEventsDialog}
    >
      <>
        <Row wrap={false} className={styles.filterEventsSelectRow}>
          <Col span={24}>
            <div className={styles.addEventsMessageText}>
              {t('Tracking.FilterEvents.AddEventsToDisplayWithWarning')}
            </div>
            <div>
              <FilterEventsSelector
                className={styles.eventTypesSelect}
                includeAllEventTypes={includeAllEventTypes}
                setIncludeAllEventTypes={setIncludeAllEventTypes}
                includedEventTypes={includedEventTypes}
                setIncludedEventTypes={setIncludedEventTypes}
              />
            </div>
          </Col>
        </Row>
        <Row wrap={false} className={styles.filterEventsRow}>
          <Col span={24}>
            <List
              className={styles.filterEventsList}
              //style={{ height: '300px' }}
              dataSource={includedEventTypes}
              bordered
              renderItem={eventType => (
                <List.Item key={eventType.key}>
                  <img
                    className={styles.eventTypeImage}
                    src={eventType?.markerSvg || defaultEventImg}
                    alt={t(`Tracking.Events.${eventType?.label}`, {
                      defaultValue: eventType?.label
                    })}
                  />
                  <div className={styles.eventTypeLabel}>
                    {t(`Tracking.Events.${eventType?.label}`, { defaultValue: eventType?.label })}
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      type="text"
                      icon={<CloseCircleFilled className={styles.removeIcon} />}
                      onClick={() => onRemove(eventType)}
                      id={BUTTON_IDS.filterEventsRemove}
                    ></Button>
                  </div>
                </List.Item>
              )}
            />
          </Col>
        </Row>

        <Row wrap={false} className={styles.filterEventsRow}>
          <Col span={24} style={{ display: 'flex' }}>
            <Button
              type="primary"
              size="middle"
              loading={isUserGridSettingsUpdating}
              className={styles.saveButton}
              onClick={onFilterEventsModalSave}
              id={BUTTON_IDS.filterEventsApply}
            >
              {t('Tracking.FilterColumns.ApplyFilters')}
            </Button>
            <Button
              size="medium"
              className={styles.cancelButton}
              onClick={handleCancel}
              id={BUTTON_IDS.filterEventsCancel}
            >
              {t('Common.Cancel')}
            </Button>
          </Col>
        </Row>
      </>
    </Modal>
  );
};
