import React, { useState, useEffect } from 'react';
import { Form, Card, Space, Alert, Switch, Button, Select, InputNumber, Radio } from 'antd';
import { BUTTON_IDS } from 'utils/globalConstants';
import { inputValidator } from '../helpers';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';
import { useDispatch } from 'react-redux';
import styles from '../Devices.module.scss';
import { useCurrentCompany } from 'features/company/companySlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { GlobalRoles, services, useCan } from 'features/permissions';
import { UNITS } from 'features/localization/localization';
import {
  useSelectedConfigurationTemplateMutation,
  useSettingValueForOneDeviceMutation,
  useSaveTheSettingValuesMutation,
  useGetAssetConfigurationTemplatesQuery,
  useGetGpioChannelsQuery
} from 'services/nextgen/ngAssetConfigurationApi';
import { useTranslation } from 'react-i18next';

export const Assets = ({ data, onClose, initialValues, selectedId }) => {
  const { Option } = Select;
  const localization = useLocalization();
  const can = useCan();
  const currentCompany = useCurrentCompany();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedTemplateName, setSelectedTemplateName] = useState(selectedId?.name);
  const [selectedConfigurationTemplate] = useSelectedConfigurationTemplateMutation();
  const [settingValues] = useSettingValueForOneDeviceMutation();
  const [saveTheSettingValues] = useSaveTheSettingValuesMutation();
  const [isTemplateSelected, setIsTemplateSelected] = useState(!!selectedId?.id);
  const [isOverrideSettingsEnabled, setIsOverrideSettingsEnabled] = useState(false);
  const [previousTemplateId, setPreviousTemplateId] = useState(selectedId?.id);
  const [isDropdownEnabled, setIsDropdownEnabled] = useState(true);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const [radioSelected, setRadioSelected] = useState('movement');
  const [rpmValue, setRpmValue] = useState(0);
  const [rpmSeconds, setRpmSeconds] = useState(0);
  const [inUse, setInUse] = useState(false);
  const [gpioChannel, setGpioChannel] = useState(null);
  const [gpioStatus, setGpioStatus] = useState(null);
  const [gpioChannelList, setGpioChannelList] = useState([]);
  const [gpioStatusList, setGpioStatusList] = useState([]);

  const { data: assetConfigurationTemplates } = useGetAssetConfigurationTemplatesQuery({
    companyId: currentCompany?.id
  });

  const gpioData = useGetGpioChannelsQuery();

  useEffect(() => {
    if (gpioData.status === 'fulfilled') {
      const gpios = Object.keys(gpioData?.data);
      setGpioChannelList(gpios);
    }
  }, [gpioData]);

  const canControlGpioConfiguration = can({
    oneOfRoles: [GlobalRoles.Reseller, GlobalRoles.SiteAdmin],
    oneOfCompanyServices: [services.GPIO]
  });

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      setSelectedTemplateName(initialValues.assetTemplateId);
      setPreviousTemplateId(initialValues.assetTemplateId);
      if (initialValues.assetTemplateId) {
        setIsOverrideSettingsEnabled(false);
      }
      if (initialValues.assetTemplateId === '') {
        setIsOverrideSettingsEnabled(true);
      }
    }
  }, [initialValues]);

  useEffect(() => {
    if (selectedId) {
      const editingTemplate = {
        ...assetConfigurationTemplates?.find(
          item => item.configurationTemplate.id === selectedId.id
        )
      };
      form.setFieldsValue(editingTemplate?.configurationTemplate);

      let isDefault = editingTemplate?.configurationTemplate?.default;

      if (isDefault) {
        form.setFieldValue('assetTemplateId', editingTemplate?.configurationTemplate?.id);
        setPreviousTemplateId(editingTemplate?.configurationTemplate?.id);
      } else {
        form.setFieldValue('overrideSettings', true);

        let config = editingTemplate?.configurationTemplate?.configurations?.find(
          item => item.key === 'asset.selection'
        );
        if (config) {
          form.setFieldValue('asset.selection', config.value);
          setRadioSelected(config.value);
        }
        if (config === 'movement') {
          config = editingTemplate?.configurationTemplate?.configurations?.find(
            item => item.key === 'movement.inuse'
          );
          if (config) {
            form.setFieldValue('movement.inuse', config.value);
            setInUse(config.value);
          }
        } else if (config === 'GPIO') {
          config = editingTemplate?.configurationTemplate?.configurations?.find(
            item => item.key === 'gpio.channel'
          );
          if (config) {
            form.setFieldValue('gpio.channel', config.value, 0);
            setGpioChannel(config.value);
          }
          config = editingTemplate?.configurationTemplate?.configurations?.find(
            item => item.key === 'gpio.status'
          );
          if (config) {
            form.setFieldValue('gpio.status', config.value, 0);
            setGpioStatus(config.value);
          }
        } else if (config === 'RPM') {
          config = editingTemplate?.configurationTemplate?.configurations?.find(
            item => item.key === 'rpm.over'
          );
          if (config) {
            form.setFieldValue('rpm.over', config.value, 0);
            setRpmValue(config.value);
          }
          config = editingTemplate?.configurationTemplate?.configurations?.find(
            item => item.key === 'rpm.min'
          );
          if (config) {
            form.setFieldValue('rpm.min', config.value, 0);
            setRpmSeconds(config.value);
          }
        }
      }
    }
  }, [assetConfigurationTemplates, selectedId]);

  const onAssetConfigurationTemplateChange = (templateId, setFieldValue) => {
    const selectedTemplate = assetConfigurationTemplates.find(
      template => template.configurationTemplate.id === templateId
    );

    setSelectedTemplateName(selectedTemplate?.configurationTemplate?.name || '');
    setPreviousTemplateId(templateId);
    setFieldValue('assetTemplateId', templateId);
    setIsTemplateSelected(!!templateId);
    setIsSaveEnabled(templateId !== previousTemplateId);

    if (templateId) {
      form.setFieldsValue({
        overrideSettings: false,
        radioSelected: null,
        inUse: null,
        gpioChannel: null,
        gpioStatus: null,
        rpmValue: null,
        rpmSeconds: null
      });
      setIsOverrideSettingsEnabled(false);
      setIsDropdownEnabled(true);
    }
    selectedConfigurationTemplate({ companyId: currentCompany?.id, templateId, id: data.id })
      .then(response => {
        console.log('Template Selection Response:', response);
      })
      .catch(error => {
        console.error('Template Selection Error:', error);
      });
  };

  const handleSaveForm = async () => {
    try {
      const configs = [
        {
          device: { id: data.id },
          service: 'ASSETSUSAGE',
          key: 'asset.selection',
          value: form.getFieldValue('asset.selection')
        },
        {
          device: { id: data.id },
          service: 'ASSETSUSAGE',
          key: 'movement.inuse',
          value: form.getFieldValue('movement.inuse')
        },
        {
          device: { id: data.id },
          service: 'ASSETSUSAGE',
          key: 'gpio.channel',
          value: form.getFieldValue('gpio.channel')
        },
        {
          device: { id: data.id },
          service: 'ASSETSUSAGE',
          key: 'gpio.status',
          value: form.getFieldValue('gpio.status')
        },
        {
          device: { id: data.id },
          service: 'ASSETSUSAGE',
          key: 'rpm.over',
          value: form.getFieldValue('rpm.over')
        },
        {
          device: { id: data.id },
          service: 'ASSETSUSAGE',
          key: 'rpm.min',
          value: form.getFieldValue('rpm.min')
        }
      ];
      if (isOverrideSettingsEnabled) {
        await settingValues({ companyId: currentCompany?.id, id: data.id }).unwrap();
      }
      await saveTheSettingValues({ body: configs, companyId: currentCompany?.id }).unwrap();

      dispatch(
        openToast({
          type: ToastType.Success,
          message: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Success')
        })
      );
    } catch (error) {
      const errorMessage =
        typeof error === 'string' ? error : error.message || JSON.stringify(error);

      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(
            'CompanyConfig.DeviceConfigurations.GPIOTemplates.Error'
          )} ${parseErrorMessage(errorMessage)}`
        })
      );
    }
  };

  const handleOverrideSettingsChange = checked => {
    setIsOverrideSettingsEnabled(checked);
    form.setFieldsValue({ overrideSettings: checked });

    if (checked) {
      form.setFieldsValue({ assetTemplateId: '' });
      setSelectedTemplateName('');
      setIsDropdownEnabled(false);
      setRadioSelected('movement');
      form.setFieldValue('asset.selection', 'movement');
    } else {
      // When override settings is disabled, restore the previous template
      setIsDropdownEnabled(true);

      setTimeout(() => {
        form.setFieldsValue({ assetTemplateId: previousTemplateId });
        const previousTemplate = assetConfigurationTemplates.find(
          template => template.configurationTemplate.id === previousTemplateId
        );
        setSelectedTemplateName(previousTemplate?.configurationTemplate?.name || '');
        setIsSaveEnabled(previousTemplateId !== selectedId?.id);
      }, 0);
    }
  };

  useEffect(() => {
    if (!isOverrideSettingsEnabled) {
      form.setFieldsValue({ assetTemplateId: previousTemplateId });
      const previousTemplate = assetConfigurationTemplates?.find(
        template => template.configurationTemplate.id === previousTemplateId
      );
      setSelectedTemplateName(previousTemplate?.configurationTemplate?.name || '');
    }
  }, [isOverrideSettingsEnabled, previousTemplateId, assetConfigurationTemplates, form]);

  useEffect(() => {
    form.setFieldsValue({ overrideSettings: isOverrideSettingsEnabled });
  }, [isOverrideSettingsEnabled, form]);

  const handleCancel = () => {
    form.setFieldsValue(initialValues);
    onClose();
  };

  const handleRadioChange = e => {
    const newValue = e.target.value;
    setRadioSelected(newValue);
    form.setFieldValue('asset.selection', newValue);
    if (newValue === 'movement') {
      form.setFieldValue('movement.inuse', true);
    } else {
      form.setFieldValue('movement.inuse', null);
    }
    form.setFieldValue('gpio.status', null);
    form.setFieldValue('gpio.channel', null);
    form.setFieldValue('rpm.over', null);
    form.setFieldValue('rpm.min', null);
  };

  const handleMovementInUseSwitch = e => {
    setInUse(!inUse);
    form.setFieldValue('movement.inuse', inUse);
  };

  const onGpioChannelChange = value => {
    form.setFieldValue('gpio.status', null);

    setGpioChannel(value);

    const selectedValues = gpioData.data[value][0].split(',');
    setGpioStatusList(selectedValues);

    form.setFieldValue('gpio.channel', value);
  };

  const onGpioStatusChange = value => {
    setGpioStatus();
    form.setFieldValue('gpio.status', gpioStatus);
  };

  const handleRpmOverChange = value => {
    setRpmValue(value);
    form.setFieldValue('rpm.over', rpmValue);
  };

  const handleRpmSecondsChange = value => {
    setRpmSeconds(value);
    form.setFieldValue('rpm.min', rpmSeconds);
  };

  const radioOptions = [
    {
      label: t('CompanyConfig.DeviceConfigurations.AssetsTemplates.Movement'),
      value: 'movement'
    }
  ];

  if (canControlGpioConfiguration) {
    radioOptions.push({
      label: t('CompanyConfig.DeviceConfigurations.AssetsTemplates.GPIO'),
      value: 'GPIO'
    });
  }

  radioOptions.push({
    label: t('CompanyConfig.DeviceConfigurations.AssetsTemplates.RPM'),
    value: 'RPM'
  });

  return (
    <Form form={form} initialValues={initialValues} layout="vertical">
      {({ setFieldValue, values, errors }) => (
        <Space direction="vertical" size={16}>
          <Card bodyStyle={{ borderRadius: '8px' }}>
            <h4 className={styles.templateHeader}>
              {t('CompanyConfig.DeviceConfigurations.View.Template')}
            </h4>
            <Alert message={t('Devices.DeviceConfigurations.TemplateAlert')} type="info" showIcon />
            <Form.Item
              label={t('Devices.DeviceConfigurations.SelectedTemplate')}
              style={{ marginTop: '12px' }}
              name="assetTemplateId"
            >
              <Select
                placeholder={t('Devices.DeviceConfigurations.SelectOption')}
                value={
                  isDropdownEnabled && !isOverrideSettingsEnabled ? selectedTemplateName : undefined
                }
                options={assetConfigurationTemplates
                  ?.filter(template => template?.configurationTemplate?.status === 'ENABLED')
                  .sort((a, b) =>
                    a?.configurationTemplate?.name?.localeCompare(b?.configurationTemplate?.name)
                  )
                  .map(template => ({
                    label: template?.configurationTemplate?.name,
                    value: template?.configurationTemplate?.id
                  }))}
                onChange={value => onAssetConfigurationTemplateChange(value, form.setFieldValue)}
                disabled={!isDropdownEnabled || isOverrideSettingsEnabled}
              />
            </Form.Item>
          </Card>
          <Card bodyStyle={{ padding: '24px', borderRadius: '8px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxHeight: '3em'
              }}
            >
              <div>
                <div className={styles.templateHeader}>{t('Common.Settings')}</div>
              </div>
              <div>
                <Form.Item
                  label={t('Devices.DeviceConfigurations.OverrideSettings')}
                  name="overrideSettings"
                  valuePropName="checked"
                  style={{ top: '6px', position: 'relative' }}
                >
                  <Switch
                    checked={isOverrideSettingsEnabled}
                    onChange={handleOverrideSettingsChange}
                    style={{ position: 'relative', right: '50px', bottom: '33px' }}
                  />
                </Form.Item>
              </div>
            </div>
            <Alert
              message={t('Devices.DeviceConfigurations.OverrideSettingsAlert')}
              type="info"
              showIcon
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '12px'
              }}
            >
              <Form.Item
                label={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.AssetsUsage')}
                name="asset.selection"
                style={{ marginLeft: '8px' }}
              >
                <Radio.Group
                  onChange={value => handleRadioChange(value)}
                  value={radioSelected}
                  disabled={!isOverrideSettingsEnabled}
                  options={radioOptions}
                ></Radio.Group>
              </Form.Item>
            </div>
            {isOverrideSettingsEnabled && (
              <Card disabled={!isOverrideSettingsEnabled} style={{ backgroundColor: '#f7f8f9' }}>
                {radioSelected === 'movement' && (
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <Form.Item
                      style={{ marginBottom: '3px' }}
                      label={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.MovementInUse')}
                      name="movement.inuse"
                      valuePropName="checked"
                    >
                      <Switch
                        disabled={!isOverrideSettingsEnabled}
                        defaultChecked={true}
                        onChange={value => handleMovementInUseSwitch(value)}
                        value={inUse}
                      />
                    </Form.Item>
                  </div>
                )}
                {radioSelected === 'GPIO' && (
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <Form.Item
                      style={{ display: 'inline-block', width: '220px', marginRight: '14px' }}
                      label={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.GPIOChannel')}
                      name="gpio.channel"
                    >
                      <Select
                        placeholder={t('Devices.DeviceConfigurations.SelectOption')}
                        onChange={onGpioChannelChange}
                        value={gpioChannel}
                        disabled={!isOverrideSettingsEnabled}
                      >
                        {gpioChannelList?.map(option => (
                          <Option key={option} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ display: 'inline-block', width: '220px' }}
                      label={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.GPIOInUse')}
                      name="gpio.status"
                    >
                      <Select
                        placeholder={t('Devices.DeviceConfigurations.SelectOption')}
                        onChange={onGpioStatusChange}
                        value={gpioStatus}
                        disabled={!isOverrideSettingsEnabled || !gpioChannel}
                      >
                        {gpioStatusList?.map(option => (
                          <Option key={option} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                )}
                {radioSelected === 'RPM' && (
                  <div>
                    <Form.Item name="rpm.over" style={{ marginBottom: '14px', width: '100%' }}>
                      <span>
                        {t('CompanyConfig.DeviceConfigurations.AssetsTemplates.RPMinUse')}
                      </span>
                      <InputNumber
                        placeholder="0"
                        min={0}
                        value={rpmValue}
                        style={{ marginRight: '10px', marginLeft: '10px' }}
                        onChange={value => handleRpmOverChange(value)}
                        disabled={!isOverrideSettingsEnabled}
                      />
                      <span>{t('CompanyConfig.DeviceConfigurations.AssetsTemplates.rpm')}</span>
                    </Form.Item>
                    <Form.Item style={{ width: '100%' }} name="rpm.min">
                      <span>
                        {t('CompanyConfig.DeviceConfigurations.AssetsTemplates.RPMnotUse')}
                        {rpmValue}
                        {t('CompanyConfig.DeviceConfigurations.AssetsTemplates.RPMnotUse2')}
                      </span>
                      <InputNumber
                        placeholder="0"
                        min={0}
                        value={rpmSeconds}
                        style={{ marginRight: '10px', marginLeft: '10px' }}
                        onChange={value => handleRpmSecondsChange(value)}
                        disabled={!isOverrideSettingsEnabled}
                      />
                      <span>{t('CompanyConfig.DeviceConfigurations.AssetsTemplates.seconds')}</span>
                    </Form.Item>
                  </div>
                )}
              </Card>
            )}
          </Card>
          <Card bodyStyle={{ padding: '8px', borderRadius: '8px' }}>
            <Space>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                id={BUTTON_IDS.configureFormSave}
                onClick={handleSaveForm}
                disabled={!isSaveEnabled && !isOverrideSettingsEnabled}
              >
                {t('Common.SaveButton')}
              </Button>
              <Button size="large" id={BUTTON_IDS.configureFormCancel} onClick={handleCancel}>
                {t('Common.CancelButton')}
              </Button>
            </Space>
          </Card>
        </Space>
      )}
    </Form>
  );
};
