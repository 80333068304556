import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Button, Modal, Row, Col, Alert, Tooltip } from 'antd';

import { useTranslation } from 'react-i18next';

import { v4 as uuidv4 } from 'uuid';

import {
  useUserGridSettings,
  useIsUserGridSettingsUpdating,
  updateUserGridSettings
} from 'features/user/userGridSettingsSlice';

import {
  getDefaultConditionAndValueForType,
  EmptyConditions
} from '../../../components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/Filters';

import { FilterColumnsRow } from './FilterColumnsRow';

import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';

import { sortStrings } from 'utils/strings';

import styles from '../../../components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/FilterColumnsModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FeatureFlag, useCan } from 'features/permissions';

export const FilterColumnsModal = ({
  allColumns,
  operationTypeData,
  operationStatusData,
  operationSubTypeData,
  operationSubTypeStatusData,
  gridSettingsKey,
  defaultGridConfig,
  isOpen = false,
  onClose
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const can = useCan();

  const userGridSettings = useUserGridSettings(gridSettingsKey);
  const isUserGridSettingsUpdating = useIsUserGridSettingsUpdating(gridSettingsKey);
  const [filters, setFilters] = useState([]);
  const [newUserGridSettings, setNewUserGridSettings] = useState(null);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [initialFiltersExist, setInitialFiltersExist] = useState(false);
  const [operationSubTypeData2, setOperationSubTypeData2] = useState(operationSubTypeData);
  const [filteredOperationType, setFilteredOperationType] = useState('');

  const handleAddFilter = () => {
    if (availableColumns.length > 0) {
      const defaultColumn = availableColumns[0];
      const foundColumn = allColumns.find(item => item.key === defaultColumn.id);
      if (foundColumn) {
        const { condition, value } = getDefaultConditionAndValueForType(foundColumn.type, t);
        let newFilters = [...filters];
        newFilters.push({
          id: uuidv4(),
          type: foundColumn.type,
          columnKey: foundColumn.key,
          condition: condition,
          value: value
        });
        setFilters(newFilters);
      }
    }
  };

  const handleApplyFilters = () => {
    // update filters before saving
    let viewConfig = newUserGridSettings.views[newUserGridSettings.selectedView];

    //checking for filter duplicates
    const filterStrings = filters.map(
      filter => `${filter.columnKey}-${filter.condition}-${filter.value}`
    );

    const filterDuplicatesExist = filterStrings.some(
      (filter, index) => filterStrings.indexOf(filter) !== index
    );

    if (filterDuplicatesExist) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Tracking.FilterColumns.FilterDuplicatesError')
        })
      );
      return;
    }

    viewConfig.filterColumns = filters.map(item => {
      return {
        // skip id
        type: item.type,
        columnKey: item.columnKey,
        condition: item.condition,
        value: item.value
      };
    });

    dispatch(updateUserGridSettings(newUserGridSettings, gridSettingsKey, onClose, true));
  };

  const handleCancel = () => {
    // Todo: Warn user if there are unsaved changes
    onClose();
  };

  useEffect(() => {
    if (userGridSettings && userGridSettings.lastFetched) {
      // Make a copy of the config from the API call for editing
      let newConfig;
      if (!userGridSettings.config) {
        newConfig = JSON.parse(JSON.stringify(defaultGridConfig));
      } else {
        newConfig = JSON.parse(JSON.stringify(userGridSettings.config));
      }
      setNewUserGridSettings(newConfig);

      // Get the filters from the view
      const viewConfig = newConfig?.views[newConfig?.selectedView];
      viewConfig?.filterColumns?.filter(item => {
        if (item?.columnKey === 'action') {
          setFilteredOperationType(item?.value);
        }
      });
      let filters = viewConfig.filterColumns.filter(filter =>
        allColumns.find(column => column.key === filter.columnKey)
      );
      filters = filters?.map(item => {
        return {
          id: uuidv4(), // Add id
          type: item.type,
          columnKey: item.columnKey,
          condition: item.condition,
          value: item.value
        };
      });
      filters?.length && setInitialFiltersExist(true);
      setFilters(filters);

      // Add columns from the view
      let newAvailableColumns = viewConfig?.columns?.filter(column =>
        allColumns?.find(item => item.key === column)
      );

      newAvailableColumns = newAvailableColumns?.map(column => {
        const foundColumn = allColumns.find(item => item.key === column);

        return {
          id: foundColumn.key,
          label: foundColumn ? foundColumn.title : column
        };
      });
      // Make sure to sort the columns
      newAvailableColumns = newAvailableColumns?.sort((a, b) => sortStrings(a.label, b.label));

      setAvailableColumns(newAvailableColumns);
    }
  }, [userGridSettings]);

  const isApplyFiltersButtonDisabled =
    (!initialFiltersExist && !filters?.length) ||
    filters?.some(filter => !filter?.value && !EmptyConditions.includes(filter?.condition));

  return (
    <Modal
      title={t('Tracking.FilterColumns.FilterColumns')}
      open={isOpen}
      centered={true}
      closable={false}
      width={590}
      footer={null}
      wrapClassName={styles.filterColumnsDialog}
    >
      <>
        {filters?.length > 0 && (
          <Row wrap={false} className={styles.filterColumnsRow}>
            <Col span={24}>
              <Row gutter={8}>
                <Col xs={{ span: 5 }} className={styles.columnNameLabel}>
                  {t('Tracking.FilterColumns.ColumnName').toUpperCase()}
                </Col>
                <Col xs={{ span: 19 }} className={styles.conditionLabel}>
                  {t('Tracking.FilterColumns.Condition').toUpperCase()}
                  <Tooltip
                    title={t('Tracking.FilterColumns.TooltipCondition')}
                    className={styles.tooltipCondition}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Col>
              </Row>
              <div
                className={
                  can({ featureFlag: FeatureFlag.trackingEnhancement.flag })
                    ? styles.filterColumnContent
                    : null
                }
              >
                {filters?.map((filter, index) => (
                  <FilterColumnsRow
                    allColumns={allColumns}
                    operationTypeData={operationTypeData}
                    operationStatusData={operationStatusData}
                    operationSubTypeData={operationSubTypeData}
                    filteredOperationType={filteredOperationType}
                    operationSubTypeStatusData={operationSubTypeStatusData}
                    availableColumns={availableColumns}
                    filters={filters}
                    setFilters={setFilters}
                    filter={filter}
                    setOperationSubTypeData2={setOperationSubTypeData2}
                    operationSubTypeData2={operationSubTypeData2}
                    key={index}
                  />
                ))}
              </div>
            </Col>
          </Row>
        )}
        <Row wrap={false} className={styles.addFilterButtonWrapper}>
          <Col span={24} style={{ display: 'flex' }}>
            <Button
              size="medium"
              type="dashed"
              id={BUTTON_IDS.addFilterColumnsModal}
              onClick={handleAddFilter}
            >
              {'+ ' + t('Tracking.FilterColumns.AddFilter')}
            </Button>
          </Col>
        </Row>
        <Row wrap={false} className={styles.warningMessageContainer}>
          <Col span={24}>
            <Alert type="info" message={t('Tracking.FilterColumns.InfoMessage')} />
          </Col>
        </Row>
        <Row wrap={false}>
          <Col span={24} style={{ display: 'flex' }}>
            <Button
              type="primary"
              size="middle"
              loading={isUserGridSettingsUpdating}
              className={styles.saveButton}
              id={BUTTON_IDS.applyFiltersColumnsModal}
              onClick={handleApplyFilters}
              disabled={isApplyFiltersButtonDisabled}
            >
              {t('Tracking.FilterColumns.ApplyFilters')}
            </Button>
            <Button size="medium" id={BUTTON_IDS.cancelFiltersColumnsModal} onClick={handleCancel}>
              {t('Common.Cancel')}
            </Button>
          </Col>
        </Row>
      </>
    </Modal>
  );
};
