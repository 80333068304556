import { API_PATH } from 'config';
import request from 'superagent';
import { fetchFleets, fetchFleetsBoth } from 'features/fleets/fleetsSlice';
import { fetchDeletedVehicles } from 'features/vehicles/vehiclesDeletedSlice';
import { fetchSchedules } from 'features/vehicleMaintenance/schedulesSlice';
import { api } from 'utils/api';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import i18n from 'i18next';
import { canHistoryGoBack } from 'utils/methods';
import { exportFile } from 'components/excelFile';
import { prepareFileForExcelExport } from './helpers';
import { getSortedBranches } from 'features/locations/locationsSlice';
import { toLower } from 'lodash';
import { parseErrorMessage } from 'utils/strings';

export const ELD_TACHO_DRIVER_PATTERN = /^ELD|UKT/gi;
export const deleteVehicleApi = (data, history) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const { id, name } = data;
  const url = `/vehicles/${id}`;
  try {
    const response = await api.delete(url, { authKey });
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('Vehicles.Notifications.Delete', { name })
        })
      );
      dispatch(fetchFleets());
      dispatch(fetchFleetsBoth());
      dispatch(fetchDeletedVehicles());
      dispatch(fetchSchedules());
      history && canHistoryGoBack(history, '/settings/vehicles');
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${name} could not be deleted: ${err}`
      })
    );
  }
};

export const restoreVehicleApi = data => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const { id, name } = data;
  const url = `${API_PATH}/vehicles/${id}/restore`;
  request('PUT', url)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(resp => {
      if (resp.ok) {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('Vehicles.Notifications.Restore', { name })
          })
        );
        dispatch(fetchFleets());
        dispatch(fetchFleetsBoth());
        dispatch(fetchDeletedVehicles());
        dispatch(fetchSchedules());
      }
    })
    .catch(err => {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: parseErrorMessage(err)
        })
      );
    });
};

export const exportVehiclesExcelFile = (data, properties, handleExportLoading) => async (
  dispatch,
  getState
) => {
  let filteredVehicles = data?.filteredVehicles || [];
  const vehicleIds = filteredVehicles.map(vehicle => vehicle.id);

  try {
    const response = await api.post(
      '/vehicles/meters/batch',
      {
        authKey: getState().user.current.auth.key
      },
      vehicleIds
    );

    if (response.status === 200) {
      const metersMap = response.body;

      filteredVehicles = filteredVehicles.map(vehicle => ({
        ...vehicle,
        meters: metersMap[vehicle.id] || []
      }));
    } else {
      filteredVehicles = filteredVehicles.map(vehicle => ({
        ...vehicle,
        meters: []
      }));
    }
  } catch (error) {
    filteredVehicles = filteredVehicles.map(vehicle => ({
      ...vehicle,
      meters: []
    }));
  }

  const rows = prepareFileForExcelExport({
    ...data,
    filteredVehicles,
    dateFormat: properties.dateFormat
  });

  if (filteredVehicles) {
    handleExportLoading();
  }
  dispatch(exportFile(rows, properties));
};

export const getCompanyDriverOptions = async (
  companyId,
  authKey,
  isSiteAdmin,
  driverOptionMapFunc,
  embed = 'associations'
) => {
  return Promise.all([
    api
      .get(`/users?pruning=ALL&embed=${embed}&company_id=${companyId}`, { authKey })
      .then(response => response?.body || [])
      .catch(err => []),
    api
      .get(`/locations?direction=DOWN&type[]=BRANCH&company_id=${companyId}`, { authKey })
      .then(response => response?.body || [])
      .catch(err => [])
      .then(_branches => getSortedBranches(_branches, isSiteAdmin))
  ]).then(([users, branches]) => {
    const drivers = (users || []).filter(user => {
      const isDriver =
        toLower(user?.type?.code) === 'driver' &&
        branches.some(b => b.id === user.location?.id || (b.id === -1 && user.location == null)) &&
        user.rulesets?.some(r => !(!r.expiresAt && r.ruleset.match(ELD_TACHO_DRIVER_PATTERN)));
      return isDriver;
    });
    return drivers.map(driver => driverOptionMapFunc(driver, branches));
  });
};
