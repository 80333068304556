import { Button, Space, Table } from 'antd';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import { useLocalization } from 'features/localization/localizationSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import i18n from 'i18next';
import { format } from 'utils/dates';
import styles from '../InstallationLog.module.scss';
import { ColumnTypes } from 'components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/Filters';
import { DeleteIcon, EditIcon } from '../svgs/SvgIcons';
import useDebounce from 'utils/hooks/useDebounce';
import { trim } from 'lodash';

export const ColumnKeys = {
  REPORTNAME: 'reportname',
  DESCRIPTION: 'description',
  SCHEDULE: 'schedule',
  COMPANY: 'company',
  NEXTRUN: 'nextrun',
  ACTION: 'action'
};

export const getAllColumns = localization => {
  const columns = [
    {
      title: i18n.t('SupportToolsELD.ScheduledReports.ReportName'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.REPORTNAME,
      key: ColumnKeys.REPORTNAME,
      width: '150px',
      isSearchable: true,
      render: (value, rowData, rowIndex) => {
        return rowData.reportname || '';
      },
      className: `${styles.first_column}`
    },
    {
      title: i18n.t('SupportToolsELD.ScheduledReports.Description'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.SCHEDULE,
      key: ColumnKeys.SCHEDULE,
      width: '150px',
      isSearchable: true,
      render: (value, rowData, rowIndex) => {
        return rowData.description || '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.ScheduledReports.Company'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.SCHEDULE,
      key: ColumnKeys.SCHEDULE,
      width: '150px',
      isSearchable: true,
      render: (value, rowData, rowIndex) => {
        return rowData.company || '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.ScheduledReports.Schedule'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.SCHEDULE,
      key: ColumnKeys.SCHEDULE,
      width: '150px',
      isSearchable: true,
      render: (value, rowData, rowIndex) => {
        return rowData.schedule || '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.ScheduledReports.NextRun'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.NEXTRUN,
      key: ColumnKeys.NEXTRUN,
      width: '150px',
      isSearchable: true,
      render: (value, rowData, rowIndex) => {
        return rowData.nextrun
          ? format(rowData.nextrun, localization.formats.time.formats.dby_imp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.ScheduledReports.Action'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.ACTION,
      key: ColumnKeys.ACTION,
      width: '150px',
      isSearchable: true,
      render: (value, rowData, rowIndex) => {
        return rowData.action || '';
      }
    }
  ];
  return columns;
};
export const ScheduledReports = () => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const dispatch = useDispatch();
  const history = useHistory();
  const [allColumns, setAllColumns] = useState(getAllColumns(localization));
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(trim(searchText), 300);
  const [allData, setAllData] = useState([
    {
      reportName: 'Transtech Dec 18th Report',
      schedule: ' Once',
      nextRunAt: '12/18/2023',
      company: {
        id: 1,
        name: 'Transtech'
      },
      description: 'Description comes here'
    },
    {
      reportName: 'Transtech Daily Report',
      schedule: ' Daily',
      nextRunAt: '01/09/2023',
      company: {
        id: 4,
        name: 'ELD Test'
      },
      description: 'Description comes here'
    },
    {
      reportName: 'Transtech Weekly Report',
      schedule: ' Weekly',
      nextRunAt: '11/17/2023',
      company: {
        id: 3,
        name: 'ELD ALL'
      },
      description: 'Description comes here'
    },
    {
      reportName: 'Transtech Monthly Report',
      schedule: ' Monthly',
      nextRunAt: '10/05/2023',
      company: {
        id: 2,
        name: 'TransGrid'
      },
      description: 'Description comes here'
    }
  ]);
  useEffect(() => {
    dispatch(setPageTitle(`${t('SupportToolsELD.ScheduledReportsTitle')}`));
    dispatch(setBackButton(true));
  }, [dispatch, t]);

  const handleSearchChange = text => {
    setSearchText(text);
    //Auth.setSearchText(text);
  };

  useEffect(() => {
    setData(PrepareDataForTable({ allData, searchText: debouncedSearchText }));
  }, [debouncedSearchText, allData]);

  const PrepareDataForTable = ({ allData, searchText }) => {
    let returnData = [];
    const searchableColumns = getAllColumns()?.filter(col => col.isSearchable);
    const handleAction = (action, entry) => {
      console.log(action, ' ------ ', entry);
    };
    returnData = allData?.map((entry, index) => {
      return {
        key: entry.id,
        [ColumnKeys.REPORTNAME]: entry.reportName,
        [ColumnKeys.DESCRIPTION]: entry.description,
        [ColumnKeys.COMPANY]: entry.company?.name,
        [ColumnKeys.SCHEDULE]: entry.schedule,
        [ColumnKeys.NEXTRUN]: entry.nextRunAt,
        [ColumnKeys.ACTION]: (
          <>
            <Space size={16}>
              <div
                style={{
                  cursor: 'pointer'
                }}
                onClick={() => handleAction('edit', entry)}
              >
                <EditIcon />
              </div>
              <div
                style={{
                  cursor: 'pointer'
                }}
                onClick={() => handleAction('delete', entry)}
              >
                <DeleteIcon />
              </div>
            </Space>
          </>
        )
      };
    });
    const filtered = returnData?.filter(row => {
      if (!searchText?.trim()) {
        return true;
      }
      const lowerSearchText = searchText.trim().toLowerCase();
      return searchableColumns.some(col =>
        row[col.dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(lowerSearchText)
      );
    });

    return filtered;
  };

  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div className={`${styles.btnLeftAlign}`}>
            <Button type="primary" size="large">
              {t('SupportToolsELD.AddNewSchedule')}
            </Button>
          </div>
        </HeaderPageWrapper>
        <div className={`${styles.searchDiv}`}>
          <FilterWrapper>
            <AntSearchbar
              onFilter={handleSearchChange}
              value={searchText}
              size={'medium'}
              className={`${styles.search}`}
            />
          </FilterWrapper>
        </div>
        <div>
          <Table
            className={`${styles.scheduler_table_header}`}
            dataSource={data}
            pagination={false}
            columns={allColumns}
          />
        </div>
      </ContainerPageWrapper>
    </>
  );
};
