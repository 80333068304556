/* global google */
import { Col, Row, Select, Tooltip } from 'antd';
import { Form, MoreMenu, RowStatus, Status } from 'components/ant';
import { ToastType } from 'components/notifications/toasts/Toast';
import { opaqueArrowStyle, polylineStyle } from 'containers/Easydocs/constants';
import { format } from 'utils/dates';
import { openToast } from 'features/toasts/toastsSlice';
import moment from 'moment';
import React, { useState } from 'react';
import { Marker, Polyline } from 'react-google-maps';
import { Link } from 'react-router-dom';
import canceledMarker from 'static/images/icons/Canceled.svg';
import completedMarker from 'static/images/icons/Completed.svg';
import createdMarker from 'static/images/icons/Created.svg';
import startedMarker from 'static/images/icons/Started.svg';
import { toTitleCase, toUnderScore } from 'utils/strings';
import { RunsheetActions } from '../Actions';

import style from '../SmartJobs.module.scss';
import {
  ColumnKeys,
  INDEX_PATHNAME_FOR_BEGINING_RUNSHEET_ID,
  ITEM_LABEL_JOBS_TABLE,
  JobItems,
  JobsColumnKeys,
  JOB_CATEGORY,
  JOB_STATUS,
  LocationType,
  RunsheetLabel,
  TableStatus,
  travelModes,
  JOB_ITEMS_TYPES,
  JOB_ITEMS_DELIVERY_STATUSES,
  SMARTJOB_ERRORS,
  TableFilterTypes,
  DEFAULT_SELECT_NO_OPTION_ID,
  RunsheetStatus
} from './constants';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { upperCase } from 'lodash';

const { Option } = Select;
export const getJobEventLinkedUser = (users, jobEvent) => {
  return users.find(user => user.id === parseInt(jobEvent?.user?.id, 10));
};

// Table Main Filters
export const filterByActiveTab = activeTab => item => {
  if (activeTab === TableStatus.INCOMPLETE) {
    return (item.status || '').toLowerCase() !== TableStatus.COMPLETED;
  }

  return (item.status || '').toLowerCase() === activeTab;
};
const filterByOwner = owners => item => owners.includes(item.carrier?.id);
const filterByDate = date => item => moment(item.scheduledAt).isSame(date, 'day');
const filterByQuery = query => item => {
  const fields = [
    item.externalId,
    item.name,
    item.vehicle?.name,
    item.fleet?.name,
    item.carrier?.name,
    item.externalReference
  ].map(field => (field || '').toLowerCase());
  return fields.some(f => f.includes(query.toLowerCase()));
};
const filterByFleet = fleets => item => {
  // Use case for when No Fleet option is selected
  if (!item.fleet?.length && fleets.includes(-1)) {
    return true;
  }

  // return fleets.includes(item?.fleet?.id);
  return (item.fleet || []).some(f => fleets.includes(f.id));
};

const filterByVehicle = vehicles => item => {
  return vehicles.some(vehicleId => vehicleId === item?.vehicle?.id);
};

const filterByLocation = locations => item =>
  (item.jobs || []).some(job => locations.includes(job.stop?.location?.name));

// Main function that prepares the table data
export const prepareRunsheetsForTable = (
  runsheets,
  properties,
  dispatch,
  setAllocateModal,
  setRunsheetForAlloc,
  translate,
  localization
) => {
  if (!runsheets || !runsheets.length) return [];
  const {
    activeTab,
    selectedCarriers,
    selectedDate,
    searchValue,
    selectedFleets,
    selectedVehicles,
    selectedLocations
  } = properties.filters;
  const isAllCarriers = selectedCarriers.some(sc => sc.id === 0 && sc.checked);
  const isAllFleets = selectedFleets.some(sf => sf.id === 0 && sf.checked);
  const isAllLocations = selectedLocations.some(sl => sl.id === 0 && sl.checked);
  const isAllVehicles = selectedVehicles.some(sv => sv.id === 0 && sv.checked);

  let filteredRunsheets = [...runsheets];

  // Search
  if (searchValue) {
    filteredRunsheets = filteredRunsheets.filter(filterByQuery(searchValue));
  }

  // Filter by Selected Date
  if (selectedDate) {
    filteredRunsheets = filteredRunsheets.filter(filterByDate(selectedDate));
  }

  // Filter by Active Tab
  if (activeTab && activeTab !== TableStatus.ALL) {
    filteredRunsheets = filteredRunsheets.filter(filterByActiveTab(properties.filters.activeTab));
  }

  // Filter by Owner
  if (!isAllCarriers) {
    const carriers = selectedCarriers.reduce((accumulator, carrier) => {
      if (carrier.checked) accumulator.push(carrier.id);
      return accumulator;
    }, []);

    filteredRunsheets = filteredRunsheets.filter(filterByOwner(carriers));
  }
  // // Filter by Fleet
  if (!isAllFleets) {
    const fleets = selectedFleets.reduce((accumulator, fleet) => {
      if (fleet.checked) accumulator.push(fleet.id);
      return accumulator;
    }, []);

    filteredRunsheets = filteredRunsheets.filter(filterByFleet(fleets));
  }

  // // Filter by Vehicle
  if (!isAllVehicles) {
    const vehicles = selectedVehicles.reduce((accumulator, currentValue) => {
      if (currentValue.checked) accumulator.push(currentValue.id);
      return accumulator;
    }, []);

    filteredRunsheets = filteredRunsheets.filter(filterByVehicle(vehicles));
  }

  // // // Filter By Location
  if (!isAllLocations) {
    const locations = selectedLocations.reduce((accumulator, location) => {
      if (location.checked) accumulator.push(location.label);
      return accumulator;
    }, []);

    filteredRunsheets = filteredRunsheets.filter(filterByLocation(locations));
  }

  // Filter By Date
  // Final Map
  // @TODO - move this in a separate function
  return filteredRunsheets.map(runsheet => ({
    key: runsheet.id,
    [ColumnKeys.STATUS]: getTableStatus(runsheet.status, translate),
    [ColumnKeys.ID]: (
      <Tooltip title={runsheet?.externalId}>
        <div className={style.overflowColumn}>
          <Link to={`/smartjobs/id/${runsheet.id}`}>{runsheet?.externalId}</Link>
        </div>
      </Tooltip>
    ),
    [ColumnKeys.NAME]: (
      <Tooltip title={runsheet[ColumnKeys.NAME]}>
        <div className={style.overflowColumn} id={`smartjob-name-column-${runsheet.id}`}>
          <Link to={`/smartjobs/id/${runsheet.id}`}>{runsheet[ColumnKeys.NAME]}</Link>
        </div>
      </Tooltip>
    ),
    [ColumnKeys.REF]: runsheet?.externalReference,
    [ColumnKeys.JOBS_NUMBER]: (runsheet.jobs || []).length,
    [ColumnKeys.DRIVER]: !!runsheet.user && (
      <Link to={`/settings/users/id/${runsheet.user.id}`}>
        {`${runsheet.user.firstName || ''} ${runsheet.user.lastName || ''}`}
      </Link>
    ),
    [ColumnKeys.VEHICLE]: runsheet.vehicle?.name,
    [ColumnKeys.CREATED_AT]: moment(runsheet.createdAt).format(
      localization?.formats.time.formats.dby_imsp
    ),
    [ColumnKeys.CARRIER]: runsheet.carrier?.name,
    [ColumnKeys.ACTIONS]: getActionsForRunsheet(runsheet, {
      dispatch,
      setAllocateModal,
      setRunsheetForAlloc,
      onEditJob: () => {},
      onShareJob: null
    }),
    [ColumnKeys.FLEET]: runsheet[ColumnKeys.FLEET]
      ? runsheet[ColumnKeys.FLEET].reduce((acc, curr, index) => {
          acc += `${curr.name}${index !== runsheet[ColumnKeys.FLEET].length - 1 ? ', ' : ''}`;
          return acc;
        }, '')
      : '-',
    [ColumnKeys.DEVICE]: !!runsheet[ColumnKeys.DEVICE] && (
      <Link to={`/settings/devices/id/${runsheet[ColumnKeys.DEVICE].id}`}>
        {runsheet[ColumnKeys.DEVICE].name}
      </Link>
    ),
    [ColumnKeys.COMPLETED_JOBS]:
      runsheet.jobs?.filter(j =>
        [JOB_STATUS.COMPLETED, JOB_STATUS.CANCELLED].includes(j[ColumnKeys.STATUS])
      ).length || 0,
    [ColumnKeys.ADHOC_JOBS]: runsheet.jobs?.filter(j => j.type === JOB_CATEGORY.ADHOC).length || 0,
    [ColumnKeys.DELIVERED_JOBS]:
      runsheet.jobs?.filter(
        j =>
          j.type === JOB_ITEMS_TYPES.DELIVERY &&
          [JOB_STATUS.COMPLETED].includes(j[ColumnKeys.STATUS])
      ).length || 0,
    [ColumnKeys.PICKEDUP_JOBS]:
      runsheet.jobs?.filter(
        j =>
          j.type === JOB_ITEMS_TYPES.PICKUP && [JOB_STATUS.COMPLETED].includes(j[ColumnKeys.STATUS])
      ).length || 0,
    [ColumnKeys.NOT_DELIVERED_ITEMS]:
      runsheet.jobs
        ?.filter(j => j.type === JOB_ITEMS_TYPES.DELIVERY)
        .reduce((total, curr) => {
          return (
            total +
            curr[JobsColumnKeys.ITEMS].filter(
              i => i[ColumnKeys.STATUS] === JOB_ITEMS_DELIVERY_STATUSES.NOT_DELIVERED
            ).length
          );
        }, 0) || 0
  }));
};

export const prepareRunsheetsForCalendarView = (
  runsheets,
  { searchValue, activeTab, selectedCarriers, selectedVehicles, selectedLocations, selectedFleets }
) => {
  const isAllCarriers = selectedCarriers.some(sc => sc.id === 0 && sc.checked);
  const isAllFleets = selectedFleets.some(sf => sf.id === 0 && sf.checked);
  const isAllVehicles = selectedVehicles.some(sv => sv.id === 0 && sv.checked);
  const isAllLocations = selectedLocations.some(sl => sl.id === 0 && sl.checked);

  let filteredRunsheets = [...runsheets];

  // Search
  if (searchValue) {
    filteredRunsheets = filteredRunsheets.filter(filterByQuery(searchValue));
  }

  // Filter by Owner
  if (!isAllCarriers) {
    const carriers = selectedCarriers.reduce((accumulator, carrier) => {
      if (carrier.checked) accumulator.push(carrier.id);
      return accumulator;
    }, []);

    filteredRunsheets = filteredRunsheets.filter(filterByOwner(carriers));
  }

  // // Filter by Fleet
  if (!isAllFleets) {
    const fleets = selectedFleets.reduce((accumulator, fleet) => {
      if (fleet.checked) accumulator.push(fleet.id);
      return accumulator;
    }, []);

    filteredRunsheets = filteredRunsheets.filter(filterByFleet(fleets));
  }

  // // Filter by Vehicle
  if (!isAllVehicles) {
    const vehicles = selectedVehicles.reduce((accumulator, vehicle) => {
      if (vehicle.checked) accumulator.push(vehicle.id);
      return accumulator;
    }, []);

    filteredRunsheets = filteredRunsheets.filter(filterByVehicle(vehicles));
  }

  // // // Filter By Location
  if (!isAllLocations) {
    const locations = selectedLocations.reduce((accumulator, location) => {
      if (location.checked) accumulator.push(location.label);
      return accumulator;
    }, []);

    filteredRunsheets = filteredRunsheets.filter(filterByLocation(locations));
  }

  // Filter by Active Tab
  if (activeTab && activeTab !== TableStatus.ALL) {
    filteredRunsheets = filteredRunsheets.filter(filterByActiveTab(activeTab));
  }

  return filteredRunsheets;
};

export const prepareJobsForRunsheetTable = (jobs = [], runsheet, locations, callbacks, t) => {
  return jobs.map((job, index) => {
    const locationName = getAddressLabel(job, locations);

    return {
      id: job.id,
      seq: job.seq,
      key: index,
      [JobsColumnKeys.NAME]: job[JobsColumnKeys.NAME],
      [JobsColumnKeys.LOCATION_TYPE]: t(
        `SmartJobs.Input.LocationType.${toTitleCase(job[JobsColumnKeys.LOCATION_TYPE])}`
      ),
      [JobsColumnKeys.LOCATION]: locationName,
      [JobsColumnKeys.JOB_TYPE]: t(
        `SmartJobs.Input.JobType.${toTitleCase(job[JobsColumnKeys.JOB_TYPE])}`
      ),
      [JobsColumnKeys.ITEMS]: (job[JobsColumnKeys.ITEMS] || []).length,
      [JobsColumnKeys.ID]: job[JobsColumnKeys.ID],
      // items for expanded row
      [JobsColumnKeys.EXPANDED_ITEMS]: job[JobsColumnKeys.ITEMS],
      [JobItems.ACTIONS.key]: (
        <RunsheetActions data={{ runsheet, job }} type="job" callbacks={callbacks} />
      )
    };
  });
};

export const prepareItemsForRunsheetTable = (items = [], translate) =>
  items.map(item => ({
    id: item?.id,
    [JobItems.QUANTITY.key]: item[JobItems.QUANTITY.key],
    [JobItems.ITEM_TYPE.key]: item[JobItems.ITEM_TYPE.key],
    [JobItems.DESCRIPTION.key]: item[JobItems.DESCRIPTION.key] || '-',
    [JobItems.WEIGHT.key]: `${item[JobItems.WEIGHT.key] || ''} ${
      item[JobItems.UNIT.key] ? translate(`SmartJobs.Input.Unit.${item[JobItems.UNIT.key]}`) : ''
    }`
  }));

function getActionsForRunsheet(runsheet, callbacks) {
  return <RunsheetActions data={{ runsheet }} type="runsheet" callbacks={callbacks} />;
}

// Table Helpers
export const getTableStatus = (status, translate, showIcon = true) => {
  const originalStatus = (status || '').toLowerCase();
  const runsheetStatus = statusMap(originalStatus);

  return (
    <RowStatus status={runsheetStatus} showIcon={showIcon}>
      {runsheetStatus === 'eta'
        ? translate(`SmartJobs.Status.eta`).toUpperCase()
        : toTitleCase(translate(`SmartJobs.Status.${originalStatus}`))}
    </RowStatus>
  );
};

export const getTableDescription = (description, translate) => {
  if (!description) {
    return '-';
  }
  const translatableDescription = toUnderScore(description.toLowerCase());

  return translate(`SmartJobs.Description.${translatableDescription}`);
};

export function statusMap(status) {
  const map = {
    opened: Status.WARNING,
    cancelled: Status.ERROR,
    completed: Status.DONE,
    created: Status.CREATED,
    load_verified: Status.SUCCESS,
    held: Status.RESTRICTED,
    updated: Status.UPDATED,
    selected: Status.SELECTED,
    eta: Status.ETA,
    arrived: Status.ARRIVED,
    started: Status.STARTED,
    departed: Status.DEPARTED,
    attachment: Status.ATTACHMENT
  };

  return map[status];
}

export const getEventDetails = (eventDetails, translate, localization) => {
  if (!eventDetails) {
    return '-';
  }
  const { attempts, lastFailureAt, sentAt, lastFailureReason } = eventDetails;
  const status = !attempts
    ? translate('SmartJobs.Ready')
    : attempts >= 1 && attempts <= 2
    ? translate('SmartJobs.Retrying')
    : translate('SmartJobs.Failed');
  const sent = sentAt
    ? format(moment(sentAt).toDate(), localization.formats.time.formats.dby_imsp)
    : '-';
  const lastFailure = lastFailureAt
    ? format(moment(lastFailureAt).toDate(), localization.formats.time.formats.dby_imsp)
    : '-';
  const reason = lastFailureReason ? (
    <Tooltip title={lastFailureReason}>
      <span className={style.reasonElipsis}>
        {translate('SmartJobs.LastFailureReason')} {lastFailureReason}
      </span>
    </Tooltip>
  ) : (
    <span>{translate('SmartJobs.LastFailureReason')} -</span>
  );

  return (
    <div className={style.detailsColumn}>
      <Tooltip title={status} placement="topLeft">
        <span>
          {translate('SmartJobs.Attempts')} {attempts}
        </span>
      </Tooltip>
      <span>
        {translate('SmartJobs.SentAt')} {sent}
      </span>
      <span>
        {translate('SmartJobs.LastFailureAt')} {lastFailure}
      </span>
      {reason}
    </div>
  );
};

// Filters
/**
 * Prepare Data for the Carrier Select
 *
 * @param {*} runsheets
 * @param {*} selections
 */
export function prepareCarriersForSelect(runsheets, selected) {
  if (!runsheets.length) {
    return [];
  }

  var selectData = runsheets.reduce((carriers, runsheet) => {
    if (runsheet.carrier && !carriers.some(item => item.label === runsheet.carrier.name)) {
      carriers.push({
        id: runsheet.carrier.id,
        label: runsheet.carrier.name,
        checked: true
      });
    }

    return carriers;
  }, []);

  const isAllSelected = selected.some(s => s.id === 0 && s.checked);
  const selectedIds = selected.reduce((accumulator, currentValue) => {
    if (currentValue.checked && selectData.some(entry => entry.id === currentValue.id)) {
      accumulator.push(currentValue.id);
    }

    return accumulator;
  }, []);

  if (!isAllSelected && selectedIds?.length) {
    return selectData.map(item =>
      selectedIds.includes(item.id) ? { ...item, checked: true } : { ...item, checked: false }
    );
  }

  return selectData;
}

export const preparFleetsForSelect = (fleets = [], selected, translate = string => string) => {
  const selectData = fleets.map(fleet => {
    if (fleet.id) {
      return {
        label: fleet.name,
        id: fleet.id,
        checked: true
      };
    } else {
      return {
        id: DEFAULT_SELECT_NO_OPTION_ID,
        checked: true,
        label: translate('Common.NoFleet')
      };
    }
  });
  const isAllSelected = selected.some(s => s.id === 0 && s.checked);
  const selectedFleetIds = selected
    .filter(s => s.checked && selectData.find(sd => sd.id === s.id))
    .map(s => s.id);

  if (!isAllSelected && selectedFleetIds?.length) {
    return selectData.map(item =>
      selectedFleetIds.includes(item.id) ? { ...item, checked: true } : { ...item, checked: false }
    );
  }

  return selectData;
};

export const prepareVehiclesForSelect = (vehicles = [], selected, translate = string => string) => {
  const selectData = vehicles.map(vehicle => {
    if (vehicle.id) {
      return {
        label: vehicle.name,
        id: vehicle.id,
        checked: true
      };
    } else {
      return {
        id: DEFAULT_SELECT_NO_OPTION_ID,
        checked: true,
        label: translate('Common.NoVehicle')
      };
    }
  });

  const isAllSelected = selected.some(s => s.id === 0 && s.checked);
  const selectedVehiclesIds = selected.reduce((accumulator, currentValue) => {
    if (currentValue.checked && selectData.some(entry => entry.id === currentValue.id)) {
      accumulator.push(currentValue.id);
    }

    return accumulator;
  }, []);

  if (!isAllSelected && selectedVehiclesIds?.length) {
    return selectData.map(item =>
      selectedVehiclesIds.includes(item.id)
        ? { ...item, checked: true }
        : { ...item, checked: false }
    );
  }

  return selectData;
};

export const prepareLocationsForSelect = (runsheets = [], selected) => {
  if (!runsheets.length) {
    return [];
  }

  var selectData = runsheets.reduce((locations, runsheet) => {
    if (runsheet?.jobs?.some(job => job.stop)) {
      runsheet.jobs.forEach(job => {
        const location = job?.stop?.location;

        if (location && !locations.some(item => item.label === location.name)) {
          locations.push({
            id: location.name,
            label: location.name,
            checked: true
          });
        }
      });
    }

    return locations;
  }, []);

  const isAllSelected = selected.some(s => s.id === 0 && s.checked);
  const selectedIds = selected.reduce((accumulator, currentValue) => {
    if (currentValue.checked && selectData.some(entry => entry.id === currentValue.id)) {
      accumulator.push(currentValue.id);
    }

    return accumulator;
  }, []);

  if (!isAllSelected && selectedIds?.length) {
    return selectData.map(item =>
      selectedIds.includes(item.id) ? { ...item, checked: true } : { ...item, checked: false }
    );
  }

  return selectData;
};

export const getIDFromPathname = pathname => {
  const indexBeginingId = pathname.lastIndexOf('/');
  const id = pathname.substr(indexBeginingId + 1, pathname.length - 1);
  return id;
};

export const getRunsheetIDFromPathname = pathname => {
  const indexEndingId = pathname.indexOf('/jobs/id/');
  const runsheetID = pathname.substring(INDEX_PATHNAME_FOR_BEGINING_RUNSHEET_ID, indexEndingId);
  return runsheetID;
};

// Manage Columns
// Render Form Rows
export const rowRenderer = (rows = []) => {
  return rows.map((row, rowIndex) => (
    <Row key={rowIndex} gutter={24}>
      {row.columns.map(({ span, item }, index) => (
        <Col key={`${rowIndex}-${index}`} span={span}>
          <Form.Item
            name={item.key}
            labelAlign={item.labelAlign}
            label={item.label}
            key={`${item.key}_${index}`}
            {...item.other}
          >
            {item.component}
          </Form.Item>
        </Col>
      ))}
    </Row>
  ));
};

// Form Validation
export const isFormValid = (formValues = {}, requiredFields = []) =>
  requiredFields.every(field => formValues[field]);

const FieldErrorMessages = {
  name: 'Please enter a valid name'
};

// Validate a specific field
export const validateField = (fieldName, value) => {
  if (value) {
    return { validateStatus: 'success' };
  }

  return { validateStatus: 'error', help: FieldErrorMessages[fieldName] };
};

// Manage Columns
const orderArrayByAnotherArray = (unOrderedArray, orderArray, key) => {
  unOrderedArray.sort((element, anotherElement) => {
    const valueForElement = element[key];
    const valueForAnotherelement = anotherElement[key];

    if (orderArray.indexOf(valueForElement) > orderArray.indexOf(valueForAnotherelement)) {
      return 1;
    } else {
      return -1;
    }
  });

  return unOrderedArray;
};

const filterVisiBleTableColumnsFromPreferences = (allTableColumns, activeTableColumns = []) => {
  // Filter all the columns using active columns
  const filteredColumns = allTableColumns.filter(
    column => activeTableColumns.includes(column.key) && column.key !== ColumnKeys.STATUS
  );
  // We need the columns to be displayed in the order that comes from the API
  const orderedFilteredColumns = orderArrayByAnotherArray(
    filteredColumns,
    activeTableColumns,
    'key'
  );

  return orderedFilteredColumns;
};

const filterHiddenTableColumnsFromPreferences = (allTableColumns, activeTableColumns = []) =>
  allTableColumns.filter(
    column =>
      ![...activeTableColumns, ColumnKeys.CARRIER, ColumnKeys.STATUS, ColumnKeys.ACTIONS].includes(
        column.key
      )
  );

export const manageColumns = {
  visible: filterVisiBleTableColumnsFromPreferences,
  hidden: filterHiddenTableColumnsFromPreferences
};

export const capitalizeFromUpperCase = string => {
  return string && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

//Jobs Table

const filterByJOBSearch = query => item =>
  (item.status || '')
    .toString()
    .toLowerCase()
    .includes(query.toLowerCase()) ||
  (item.id || '')
    .toString()
    .toLowerCase()
    .includes(query.toLowerCase()) ||
  (item.customerName || '')
    .toString()
    .toLowerCase()
    .includes(query.toLowerCase()) ||
  (item.type || '')
    .toString()
    .toLowerCase()
    .includes(query.toLowerCase()) ||
  item.jobItems.find(jobItem =>
    (jobItem.item.id || '')
      .toString()
      .toLowerCase()
      .includes(query.toLowerCase())
  );

export const timeColumns = (plannedTime, actualTime, localization) => {
  return plannedTime || actualTime ? (
    <div>
      {plannedTime && actualTime && <div>Planned</div>}
      {plannedTime && (
        <div>
          {format(moment(plannedTime).toDate(), localization.formats.time.formats.dby_imsp)}
        </div>
      )}
      {actualTime && (
        <div className={style.green}>
          {format(moment(actualTime).toDate(), localization.formats.time.formats.dby_imsp)}
        </div>
      )}
    </div>
  ) : (
    '-'
  );
};

const lengthRenderer = (items, label) => {
  return `${items.length || 0} ${items.length === 1 ? label : `${label}s`}`;
};

export const prepareJobsForTable = (jobs = [], properties, callbacks, translate) => {
  const { runsheet, searchValue, localization, path } = properties;
  // Search
  if (searchValue) {
    jobs = jobs.filter(filterByJOBSearch(searchValue));
  }

  // Final Map
  // @TODO - move this in a separate function
  return jobs.map(job => {
    return {
      [ColumnKeys.STATUS]: getTableStatus(job.status, translate),
      [ColumnKeys.JOB_SEQ]: job?.seq || 0,
      [ColumnKeys.JOB_ID]: (
        <Link to={`/smartjobs/id/${runsheet.id}/jobs/id/${job?.id}`}>#{job?.externalId}</Link>
      ),
      key: job?.id,
      [ColumnKeys.ATTRIBUTES]: job?.attributes.map(attr => (
        <div key={attr.id}>Order No. {attr.id}</div>
      )),
      [ColumnKeys.JOB_NAME]: job?.customerName || '',
      [ColumnKeys.JOB_TYPE]:
        translate(`SmartJobs.Input.JobType.${capitalizeFromUpperCase(job?.type)}`) || '',
      [ColumnKeys.START_AT]: timeColumns(job?.startAt, job?.actualStartAt, localization),
      [ColumnKeys.END_AT]: timeColumns(job?.endAt, job?.actualEndAt, localization),
      [ColumnKeys.JOB_ITEMS]: lengthRenderer(job?.jobItems, ITEM_LABEL_JOBS_TABLE),
      [ColumnKeys.ACTIONS]: (
        <RunsheetActions data={{ runsheet, job, path }} type="job" callbacks={callbacks} />
      )
    };
  });
};

export const renderItemQuantities = (initialQ, loadedQ, unloadedQ) => {
  const quantities = [];
  initialQ && quantities.push(`${Math.round(initialQ * 100) / 100} (O)`);
  loadedQ && quantities.push(`${Math.round(loadedQ * 100) / 100} (L)`);
  unloadedQ && quantities.push(`${Math.round(unloadedQ * 100) / 100} (U)`);
  return quantities.join(' ') || '-';
};

export const renderItemWeights = (initialW, unit, loadedW, unloadedW) => {
  if (!unit) {
    return '-';
  }
  const weights = [];
  initialW && weights.push(`${Math.round(initialW * 100) / 100} ${unit} (O)`);
  loadedW && weights.push(`${Math.round(loadedW * 100) / 100} ${unit} (L)`);
  unloadedW && weights.push(`${Math.round(unloadedW * 100) / 100} ${unit} (U)`);

  return weights.join(' ') || '-';
};

export const getDeliveryDetails = (item, job, t) => {
  const {
    originalQuantity,
    loadedQuantity,
    unloadedQuantity,
    unloadedVarianceCode = {},
    loadedVarianceCode = {},
    varianceReason = ''
  } = item;
  //delivery
  if (job?.type === JOB_ITEMS_TYPES.DELIVERY) {
    const { code, description } = unloadedVarianceCode;
    const reasonDescription = varianceReason ? ` - ${varianceReason}` : '';
    const reason = code ? code + reasonDescription : '-';
    if (originalQuantity && Number.isFinite(unloadedQuantity)) {
      if (unloadedQuantity === 0 && code) {
        return {
          status: t(`SmartJobs.DeliveryStatus.${JOB_ITEMS_DELIVERY_STATUSES.NOT_DELIVERED}`),
          reason: description || reason
        };
      } else {
        if (unloadedQuantity === originalQuantity) {
          return {
            status: t(`SmartJobs.DeliveryStatus.${JOB_ITEMS_DELIVERY_STATUSES.DELIVERED}`),
            reason: '-'
          };
        } else {
          return {
            status: t(
              `SmartJobs.DeliveryStatus.${JOB_ITEMS_DELIVERY_STATUSES.PARTIALLY_DELIVERED}`
            ),
            reason: description || reason
          };
        }
      }
    }
  }

  // pick up
  if (job?.type === JOB_ITEMS_TYPES.PICKUP) {
    const { code, description } = loadedVarianceCode;
    const reasonDescription = varianceReason ? ` - ${varianceReason}` : '';
    const reason = code ? code + reasonDescription : '-';

    if (loadedQuantity >= originalQuantity) {
      return {
        status: t(`SmartJobs.DeliveryStatus.${JOB_ITEMS_DELIVERY_STATUSES.PICKED_UP}`),
        reason: '-'
      };
    } else {
      if (job.status === 'COMPLETED') {
        return loadedQuantity === 0
          ? {
              status: t(`SmartJobs.DeliveryStatus.${JOB_ITEMS_DELIVERY_STATUSES.NOT_PICKED_UP}`),
              reason: '-'
            }
          : {
              status: t(
                `SmartJobs.DeliveryStatus.${JOB_ITEMS_DELIVERY_STATUSES.PARTIALLY_PICKED_UP}`
              ),
              reason: description || reason
            };
      }
    }
  }
  return {
    status: t(`SmartJobs.DeliveryStatus.${JOB_ITEMS_DELIVERY_STATUSES.CREATED}`),
    reason: '-'
  };
};

export const prepareItemsForTable = (
  jobItems = [],
  job,
  translate,
  localization,
  callbacks,
  itemTypes = []
) => {
  return jobItems.map(jobItem => {
    const { item } = jobItem;
    const delivery = getDeliveryDetails(jobItem, job || jobItem.job, translate);

    const { weight, weightUom } = localization.convertWeight(item.weight, item.weightUom);
    const { weight: localizedLoadedWeight } = localization.convertWeight(
      jobItem.loadedWeight,
      item.weightUom
    );
    const { weight: localizedUnLoadedWeight } = localization.convertWeight(
      jobItem.unloadedWeight,
      item.weightUom
    );
    const unit = weightUom ? translate(`SmartJobs.Input.Unit.${weightUom}`, weightUom) : '';
    const translatedItemType =
      itemTypes.find(type => type.code === item.type)?.description || item.type;

    return {
      [ColumnKeys.ITEM_ID]: item?.itemId || item?.id || '',
      key: item?.itemId || item?.id,
      [ColumnKeys.ITEM_ATTRS]:
        item?.attributes?.map(attr => (
          <div key={attr.key}>
            {attr.key}: {attr.value}
          </div>
        )) || '-',
      [ColumnKeys.ITEM_TYPE]: translatedItemType,
      [ColumnKeys.ITEM_QUANTITY]: renderItemQuantities(
        item.quantity,
        jobItem.loadedQuantity,
        jobItem.unloadedQuantity
      ),
      [ColumnKeys.ITEM_WEIGHT]: renderItemWeights(
        weight,
        unit,
        localizedLoadedWeight,
        localizedUnLoadedWeight
      ),
      [ColumnKeys.ITEM_DESCRIPTION]: getItemDescription(item) || `${delivery.reason}`,
      [ColumnKeys.ITEM_TIME]: item.time || '-',
      [ColumnKeys.JOB_TYPE]: jobItem.type
        ? translate(`SmartJobs.Input.JobType.${toTitleCase(jobItem.type)}`)
        : '-',
      [ColumnKeys.JOB_STATUS]: capitalizeFromUpperCase(delivery.status),
      [ColumnKeys.ITEM_REASON]: delivery?.reason,
      [ColumnKeys.ACTIONS]: getActionsForItem(item?.itemId || item?.id, translate, callbacks)
    };
  });
};

export const getItemDescription = item => {
  return !!item?.commodity || (item?.commodity && item?.commodity !== item?.description)
    ? `[${item?.commodity}] ${item?.description}`
    : item?.description;
};

//Item actiomns
function getActionsForItem(itemId, t, callbacks) {
  return (
    <MoreMenu
      useNewIcon={true}
      items={[
        {
          name: 'Delete',
          onClick: () => {
            confirmationModal(
              t('SmartJobs.Confirmation.confirm'),
              `${t('SmartJobs.Confirmation.deleteItem')} ${itemId}?`,
              t('SmartJobs.Confirmation.delete'),
              t('SmartJobs.Confirmation.cancel'),
              callbacks.onDelete(itemId),
              'delete'
            );
          },
          id: 'btn_smartjobsMenuConfirmation'
        }
      ]}
    />
  );
}

// End Jobs Table

export const getRunsheetLinkedDevice = (devices, deletedDevices, runsheet) => {
  return devices
    .concat(deletedDevices)
    .find(device => device.id === parseInt(runsheet?.device?.id, 10));
};

export const getInfoRowValue = (runsheetInfo, localization, translate) => {
  const { label, runsheet, startEventTime, endEventTime, linkedDevice } = runsheetInfo;

  switch (label) {
    case RunsheetLabel.DEVICE:
      return linkedDevice ? (
        <Link to={`/settings/devices/id/${runsheet?.device?.id}`}>{linkedDevice?.name || ''}</Link>
      ) : runsheet?.device?.name ? (
        runsheet?.device?.name
      ) : (
        '-'
      );
    case RunsheetLabel.VEHICLE:
      return runsheet?.vehicle ? (
        <Link to={`/settings/vehicles/id/${runsheet?.vehicle?.id}`}>
          {runsheet?.vehicle?.name || ''}
        </Link>
      ) : (
        '-'
      );
    case RunsheetLabel.DRIVER:
      return runsheet?.user ? (
        <Link to={`/settings/users/id/${runsheet?.user?.id}`}>
          {`${runsheet?.user?.firstName ?? ''} ${runsheet?.user?.lastName ?? ''}`}
        </Link>
      ) : (
        '-'
      );
    case RunsheetLabel.STATUS:
      return getTableStatus(runsheet?.status, translate, false);
    case RunsheetLabel.START:
      return startEventTime
        ? format(new Date(startEventTime), localization.formats.time.formats.dby_imp)
        : '-';
    case RunsheetLabel.END:
      return endEventTime
        ? format(new Date(endEventTime), localization.formats.time.formats.dby_imp)
        : '-';
    case RunsheetLabel.DETAILS:
      let duration = '';
      if (startEventTime && endEventTime) {
        let totalSeconds =
          (new Date(endEventTime).getTime() - new Date(startEventTime).getTime()) / 1000;
        let hours = Math.abs(Math.floor(totalSeconds / 3600));
        let minutes = Math.abs(Math.floor(totalSeconds / 60) % 60);
        let seconds = Math.abs(Math.floor(totalSeconds % 60));
        duration = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
          seconds < 10 ? `0${seconds}` : seconds
        }`;
      }
      return (
        <>
          {`${translate(`SmartJobs.JobDetails.Ref`)}: ${runsheet?.externalReference || '-'}`}
          <br />
          {`${translate(`SmartJobs.JobDetails.duration`)}: ${duration || '-'}`}
          <br />
          {`${translate(`SmartJobs.JobDetails.numberOfJobs`)}:`}
          {!!runsheet?.jobs?.length
            ? `${runsheet?.jobs?.filter(job => job.status === JOB_STATUS.COMPLETED).length} of ${
                runsheet?.jobs?.length
              }`
            : '-'}
          <br />
          {`${translate(`SmartJobs.JobDetails.numberOfAdHoc`)}:`}
          {runsheet?.jobs?.filter(job => job.type === JOB_CATEGORY?.ADHOC).length || '0'}
          <br />
        </>
      );
    case RunsheetLabel.ATTRIBUTES:
      return renderAttributes(runsheet?.attributes);
    default:
      return;
  }
};

const renderAttributes = (attributes = []) => {
  if (!attributes.length) {
    return '-';
  }

  return <CollapsibleAttributes attributes={attributes} />;
};

const CollapsibleAttributes = ({ attributes = [] }) => {
  const [isExpanded, setExpanded] = useState(false);

  return isExpanded ? (
    <>
      <DownOutlined onClick={() => setExpanded(false)} />
      <div className={style.attributesValue}>{attributes.map(renderAttribute)}</div>
    </>
  ) : (
    <>
      <UpOutlined onClick={() => setExpanded(true)} />
    </>
  );
};

const renderAttribute = (attribute = {}) => {
  const { key, value } = attribute;
  return (
    <div key={key}>
      <b>{key}:</b> {value}
    </div>
  );
};

// @TODO - refactor everything related to locations
export const getAddressLabel = (job, locations) => {
  if (!job?.location && !job?.stop) {
    return '';
  }

  if (typeof job.location === 'string' && !job.stop) {
    return job.location;
  }

  if (job.stop) {
    return job.stop.description || job.stop.location?.name;
  }
  // After adding location
  if (typeof job.location === 'number') {
    const newLocation = locations.find(l => l.id === job.location);
    return newLocation?.address && getAddress(newLocation.address, newLocation.name);
  }

  if (job.location.location) {
    const { address, name, description } = job.location.location;

    if (address) {
      return getAddress(address, name);
    }

    return name || description;
  }

  return '-';
};

export const getLocationType = (stop = {}) => {
  if (!stop.location?.type) {
    return '-';
  }

  return stop.location.type.code === LocationType.CUSTOMER ? stop.location.type.code : 'ADDRESS';
};

export const getAddress = (address, name) => {
  if (!address) return name;
  const { number, street, suburb, postcode, state, country } = address;

  return `${number || ''} ${street ? street + ',' : ''}  ${suburb ? suburb + ',' : ''} ${
    state ? state + ',' : ''
  } ${postcode ? postcode + ',' : ''} ${country || ''}`;
};

//Runsheet Events
export const prepareEventsForTable = (
  events,
  devices,
  deletedDevices,
  users,
  branches,
  localization,
  translate
) => {
  return events
    ?.slice()
    ?.sort((a, b) => moment(b.eventAt).unix() - moment(a.eventAt).unix())
    ?.map(event => {
      const linkedDevice = getRunsheetLinkedDevice(devices, deletedDevices, event);
      const linkedUser = getJobEventLinkedUser(users, event);
      const linkedBranch = branches.find(branch => branch.id === event?.location?.id);

      return {
        [ColumnKeys.STATUS]: getTableStatus(event.type, translate),
        [ColumnKeys.DEVICE]: (
          <Link to={`/settings/devices/id/${event?.device?.id}`}>{linkedDevice?.name || ''}</Link>
        ),
        [ColumnKeys.VEHICLE]: (
          <Link to={`/settings/vehicles/id/${linkedDevice?.vehicle?.id}`}>
            {linkedDevice?.vehicle?.name || ''}
          </Link>
        ),
        [ColumnKeys.FLEET]:
          Array.isArray(linkedDevice?.fleetInfo) &&
          linkedDevice?.fleetInfo?.map(fleet => <div key={fleet.id}>{fleet.name}</div>),
        [ColumnKeys.DRIVER]: (
          <Link to={`/settings/users/id/${event?.user?.id}`}>
            {linkedUser && `${linkedUser?.firstName} ${linkedUser?.lastName}`}
          </Link>
        ),
        [ColumnKeys.BRANCH]: linkedBranch?.name,
        [ColumnKeys.EVENT_AT]: timeColumns(event?.eventAt, false, localization),
        [ColumnKeys.DESCRIPTION]: event?.description || '-',
        [ColumnKeys.DETAILS]: getEventDetails(event?.queuedEvent, translate, localization),
        key: event.id
      };
    });
};

export const setRoutePolylines = (response, directionsRenderer, routePolylines, dispatch) => {
  if (response.status === google.maps.DirectionsStatus.OK) {
    directionsRenderer.setDirections(response.result);
    const routePolyToBeSet = [];
    const paths = directionsRenderer?.directions?.routes[0]?.legs[0]?.steps?.map(
      step => step?.path
    );
    paths.forEach(path => path.forEach(coordinate => routePolyToBeSet.push(coordinate)));
    routePolylines.push(routePolyToBeSet);
  } else {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${SMARTJOB_ERRORS.ROUTE}: ${response.status}`
      })
    );
    console.error(`error fetching directions ${response.result}`);
  }
};

export const getPolyline = (mapPoints, directions, current) => {
  const request = {
    origin: mapPoints.origin,
    destination: mapPoints.destination,
    waypoints: mapPoints.waypts || [],
    provideRouteAlternatives: false,
    travelMode: travelModes.DRIVING
  };

  if (!!directions?.service && !!request.destination) {
    directions.service.route(request, (result, status) => {
      current.setRoutePolylines(
        { result, status },
        directions.renderer,
        current.routePolylines,
        current.dispatch
      );
    });
  }
};

export const getActualPolylinesArray = unfilteredPositions => {
  let currentPolyline = [];
  const positions = unfilteredPositions?.filter(
    position => position?.Lat !== undefined && position?.Lng !== undefined
  );

  positions &&
    positions.forEach(position => {
      currentPolyline.push({
        lat: position?.Lat,
        lng: position?.Lng
      });
    });

  return currentPolyline.length ? [currentPolyline] : [];
};

export const getStopMarkers = (stops, marker) => {
  return stops
    ?.filter(stop => stop.coordinates.lat && stop.coordinates.lng)
    .map(stop => <Marker key={stop.jobId} position={stop.coordinates} icon={marker} />);
};

export const getPolylines = (polylines, iconArray) => {
  return polylines?.current.map((polyline, index) => {
    return (
      <Polyline
        key={`poly=${index}`}
        path={polyline}
        options={getPolylineOptions(polylines.color, polylines.zIndex, iconArray)}
      />
    );
  });
};

export const getActualStopMarkers = stops => {
  return stops?.map((stop, index) => getActualMarker(stop, index));
};

export const getRunsheetStops = runsheet => {
  return runsheet?.jobs?.map(job => {
    return {
      jobId: job?.id,
      id: job?.stop?.location?.id,
      status: job?.status,
      coordinates: {
        lat: job?.stop?.location?.GPS?.Lat,
        lng: job?.stop?.location?.GPS?.Lng
      }
    };
  });
};

export const getRunsheetActualStops = runsheet => {
  return runsheet?.jobs?.map(job => {
    return {
      position: {
        lat: job?.actualStop?.location?.GPS?.Lat,
        lng: job?.actualStop?.location?.GPS?.Lng
      },
      status: job?.status
    };
  });
};

export const getActualMarker = (stop, index) => {
  switch (stop.status) {
    case JOB_STATUS.CREATED:
      return stop?.position?.lat || stop?.position?.lng ? (
        <Marker key={index} position={stop?.position} icon={createdMarker} />
      ) : null;
    case JOB_STATUS.STARTED:
      return stop?.position?.lat || stop?.position?.lng ? (
        <Marker key={index} position={stop?.position} icon={startedMarker} />
      ) : null;
    case JOB_STATUS.CANCELLED:
      return stop?.position?.lat || stop?.position?.lng ? (
        <Marker key={index} position={stop?.position} icon={canceledMarker} />
      ) : null;
    case JOB_STATUS.COMPLETED:
    default:
      return stop?.position?.lat || stop?.position?.lng ? (
        <Marker key={index} position={stop?.position} icon={completedMarker} />
      ) : null;
  }
};

export const getOpaqueArrow = (transparentArrowIndex, opaqueArrowIndex) => {
  return {
    icon: {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      strokeColor: opaqueArrowStyle.strokeColor,
      strokeOpacity:
        transparentArrowIndex === opaqueArrowIndex
          ? opaqueArrowStyle.opacity
          : opaqueArrowStyle.transparency,
      scale: opaqueArrowStyle.scale,
      strokeWeight: opaqueArrowStyle.strokeWeight
    },
    offset: `${(transparentArrowIndex + 1) * 10}px`,
    zIndex: 1
  };
};

export const getPolylineOptions = (color, zIndex, iconArray) => {
  return {
    strokeColor: color,
    strokeOpacity: polylineStyle.strokeOpacity,
    strokeWeight: polylineStyle.strokeWeight,
    icons: iconArray,
    zIndex
  };
};

export const getFleetList = fleets => {
  return fleets.map(fleet => (
    <Option key={fleet.id} value={fleet.id}>
      {fleet.name}
    </Option>
  ));
};

export const getVehiclesList = vehiclesList => {
  return vehiclesList.map(vehicle => (
    <Option key={vehicle?.id} value={vehicle?.id}>
      {vehicle?.name}
    </Option>
  ));
};

export const chunk = (array, size) =>
  Array.from({ length: Math.ceil(array.length / size) }, (value, index) =>
    array.slice(index * size, index * size + size)
  );

export const translateItemsTypes = (items, types = []) =>
  items.map(item => ({
    ...item,
    item: {
      ...item.item,
      type: types.find(type => type.code === item.item.type)?.description || item.item.type
    }
  }));

export const reorderSequence = (job, index) => ({ ...job, seq: index + 1 });

export const isRunsheetCompleted = runsheet =>
  runsheet &&
  [RunsheetStatus.COMPLETED, RunsheetStatus.CANCELLED].includes(upperCase(runsheet?.status));
export const isRunsheetInProgress = runsheet =>
  runsheet &&
  !isRunsheetCompleted(runsheet) &&
  RunsheetStatus.CREATED !== upperCase(runsheet?.status);

//Not allow Edit/Allocate/Delete to Opened/LoadVerified/Held/Completed/Canceled runsheet(Only View/Copy)
//Allow Creared runsheet to be View/Copy/Edit/Allocate/Delete
export const isRunsheetFrozen = runsheet =>
  isRunsheetCompleted(runsheet) || upperCase(runsheet?.status) !== RunsheetStatus.CREATED;
