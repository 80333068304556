import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { format } from 'utils/dates';
import { InfoWindow } from 'react-google-maps';
import { Button, Modal } from 'antd';
import { TextButton } from 'components/buttons/TextButton';
import { services } from 'features/permissions';
import { EventAttributes, getEventAttributesByType } from 'containers/Tracking/EventTypes';
import { formatTimeDiff, formatOrdinalSuffix } from 'utils/methods';
import { AttachmentStatus } from 'containers/home/Camera/constant';

import styles from './InfoWindows.module.scss';

const isVREvent = evt => evt?.eventType === 'CAMERA' && evt?.subType === 'VIDEO_REQUEST';
const getVRRequestStatus = requestStatus => {
  let vrStatus = AttachmentStatus.FAILED;
  switch (requestStatus) {
    case AttachmentStatus.AVAILABLE:
    case AttachmentStatus.ENABLED:
      vrStatus = AttachmentStatus.AVAILABLE;
      break;
    case AttachmentStatus.REQUESTED:
    case AttachmentStatus.INPROGRESS:
      vrStatus = AttachmentStatus.INPROGRESS;
      break;
    default:
      break;
  }
  return vrStatus;
};

const EventDateTime = ({ t, event, localization }) =>
  isVREvent(event) && !!event?.timeAt ? (
    <div key={`${event.id}-dateTime`}>
      <label>{t('Common.DateTime')}</label>
      <span>
        {format(moment(event.timeAt).toDate(), localization.formats.time.formats.dby_imp)}
      </span>
    </div>
  ) : null;

const EventRequestStatus = ({ t, event }) => {
  const mappedStatus = {
    FAILED: 'Failed',
    AVAILABLE: 'Available',
    INPROGRESS: 'In Progress'
  };

  return isVREvent(event) ? (
    <div key={`${event.id}-requestStatus`}>
      <label>{t('Footage.Request Status')}</label>
      <span>
        {t(
          `Footage.RequestStatus.${
            mappedStatus[getVRRequestStatus(event?.requestVideo?.requestStatus)]
          }`
        )}
      </span>
    </div>
  ) : null;
};

const EventInfoWindow = ({
  event,
  localization,
  can,
  disableAutoPan = false,
  onCloseClick = null,
  onRequestEvtVideo,
  history
}) => {
  const { t } = useTranslation();

  const hasIridiumService = can({
    everyCompanyService: [services.IRIDIUM]
  });
  const hasGeoFenceSpeedOverrideService = can({
    oneOfCompanyServices: [services.GEOFENCE_SPEED_OVERRIDE]
  });

  // For optional communication channel label that only shows is Iridium feature is enabled
  let comChannelLabel = null;
  let comChannelClass = null;
  if (hasIridiumService) {
    if (event?.origin?.includes('satellite')) {
      comChannelLabel = t('Tracking.Satellite');
      comChannelClass = styles.comChannelSatellite;
    } else if (event?.origin?.includes('hermes')) {
      comChannelLabel = t('Tracking.Cellular');
      comChannelClass = styles.comChannelCellular;
    } else {
      // Else, if not from satellite or device, show event came from the server
      comChannelLabel = t('Tracking.Server');
      comChannelClass = styles.comChannelServer;
    }
  }

  const getEventAttributes = () => {
    let attributes = [];
    let eventAttributes =
      getEventAttributesByType(event.eventType, event.subType, event.customSubType)?.attributes ||
      [];
    attributes = eventAttributes
      .filter(attribute => event.attributes && event.attributes[attribute.name] !== null)
      .map(attribute => {
        let { name, unit } = attribute;
        if (unit === EventAttributes.UNITS.kmhour) {
          unit = localization.formats.speed.unit_per_hour;
          return (
            <div key={`${event.id}-kmhour`}>
              <label>
                {t(`Tracking.Events.${attribute.label}`, { defaultValue: attribute?.label })}
              </label>
              <span>
                {`${localization.convertDistance(event.attributes[attribute.name])} ${unit}`}
              </span>
            </div>
          );
        }
        if (unit === EventAttributes.UNITS.seconds) {
          if (Number(event.attributes[attribute.name]) < 0) {
            return (
              <div key={`${event.id}-seconds`}>
                <label>
                  {t(`Tracking.Events.${attribute.label}`, { defaultValue: attribute?.label })}
                </label>
                <span>{t('Tracking.NotAvailable')}</span>
              </div>
            );
          }
          return (
            <div key={`${event.id}-time`}>
              <label>
                {t(`Tracking.Events.${attribute.label}`, { defaultValue: attribute?.label })}
              </label>
              <span>{formatTimeDiff(Number(event.attributes[attribute.name]) * 1000, t)}</span>
            </div>
          );
        }
        if (unit === EventAttributes.UNITS.gear) {
          return (
            <div key={`${event.id}-gear`}>
              <label>
                {t(`Tracking.Events.${attribute.label}`, { defaultValue: attribute?.label })}
              </label>
              <span>
                {!!event.attributes[attribute.name] &&
                !isNaN(Number(event.attributes[attribute.name]))
                  ? `${formatOrdinalSuffix(Number(event.attributes[attribute.name])) + ' ' + unit}`
                  : '-'}
              </span>
            </div>
          );
        }
        if (name === EventAttributes.NAMES.driver) {
          return null;
        }
        if (unit === EventAttributes.UNITS.temperature) {
          unit = localization.formats.temperature.unit;
          return (
            <div key={`${event.id}-temperature`}>
              <label>
                {t(`Tracking.Events.${attribute.label}`, { defaultValue: attribute?.label })}
              </label>
              <span>
                {`${localization.convertTemperature(event.attributes[attribute.name])} ${unit}`}
              </span>
            </div>
          );
        }
        if (unit === EventAttributes.UNITS.ton) {
          return (
            <div key={`${event.id}-ton`}>
              <label>
                {t(`Tracking.Events.${attribute.label}`, { defaultValue: attribute?.label })}
              </label>
              <span>
                {`${
                  localization.convertWeight(event.attributes[attribute.name], unit).weight
                } ${unit}`}
              </span>
            </div>
          );
        }
        return (
          <div key={`${event.id}-attrInfo`}>
            <label>
              {t(`Tracking.Events.${attribute.label}`, { defaultValue: attribute?.label })}
            </label>
            <span>{`${event.attributes[attribute.name]} ${unit}`}</span>
          </div>
        );
      });

    return attributes;
  };

  const eventAttributes = getEventAttributesByType(
    event.eventType,
    event.subType,
    event.customSubType
  );

  return (
    <InfoWindow
      options={{ disableAutoPan: disableAutoPan }}
      zIndex={moment().valueOf()}
      onCloseClick={() => onCloseClick && onCloseClick(event)}
    >
      <div className={styles.infoWindow}>
        <div className={styles.infoWindowTitle}>{t('Tracking.QuickView')}</div>
        <div key={`${event.id}-eventType`}>
          <label>{t('Tracking.EventType')}</label>
          <span>
            {t(`Tracking.Events.${eventAttributes.label}`, {
              defaultValue: eventAttributes?.label
            }) || '-'}
          </span>
        </div>
        <div key={`${event.id}-driver`}>
          <label>{t('Common.Driver')}</label>
          <span>
            {event.user?.firstName && event.user?.lastName
              ? `${event.user.firstName} ${event.user.lastName}`
              : '-'}
          </span>
        </div>
        <div key={`${event.id}-location`}>
          <label>{t('Common.Location')}</label>
          <span>{event.location || '-'}</span>
        </div>
        {getEventAttributes()}
        {hasIridiumService && comChannelLabel && (
          <div key={`${event.id}-comChannel`}>
            <label>{t('Tracking.CommunicationChannel')}</label>
            <span className={comChannelClass}>{comChannelLabel}</span>
          </div>
        )}
        <EventDateTime t={t} event={event} localization={localization} />
        <EventRequestStatus t={t} event={event} />
        <footer>
          {isVREvent(event) ? (
            <VREventAction t={t} event={event} onRequestEvtVideo={onRequestEvtVideo} />
          ) : (
            <EventAction
              t={t}
              event={event}
              history={history}
              hasGeoFenceSpeedOverrideService={hasGeoFenceSpeedOverrideService}
            />
          )}
        </footer>
      </div>
    </InfoWindow>
  );
};

export default EventInfoWindow;

const EventAction = ({ t, event, history, hasGeoFenceSpeedOverrideService }) => {
  const [loading, setLoading] = useState(false);
  const [vrPriorCheckModal, vrPriorCheckModalCtx] = Modal.useModal();

  const handleSpeedManagementRedirect = () => {
    history.push({
      pathname: '/settings/geofences/newGeofence',
      state: { speedManagementEvent: JSON.stringify(event) }
    });
  };

  const handleRequestVideo = useCallback(() => {
    if (event.requestVideo?.canPriorCheck) {
      const { isExhausted, max, monthlyRenewOn, remaining } = event.requestVideo?.usage || {};
      if (isExhausted) {
        vrPriorCheckModal.error({
          content: t('Devices.View.DataPlan.Exhausted', {
            max,
            date: t(`Devices.View.DataPlan.MonthlyRenew.Next.${monthlyRenewOn}`)
          }),
          okText: t('Common.Modal.OK'),
          width: 600,
          centered: true,
          wrapClassName: styles.confirmModal
        });
      } else {
        vrPriorCheckModal.confirm({
          title: t('Common.Modal.DoYouWishToContinue'),
          content: t('Footage.RequestPriorConfirm', { max, remaining }),
          okText: t('Common.Modal.OK'),
          cancelText: t('Common.Modal.Cancel'),
          onOk: () => {
            setLoading(true);
            event.requestVideo.onRequest(() => setLoading(false));
          },
          width: 600,
          centered: true,
          wrapClassName: styles.confirmModal
        });
      }
    } else {
      setLoading(true);
      event.requestVideo.onRequest(() => setLoading(false));
    }
  }, [event, vrPriorCheckModal]);

  if (event?.requestVideo?.can) {
    switch (event.requestVideo.requestStatus) {
      case AttachmentStatus.AVAILABLE_ON_REQUEST:
        return (
          <>
            {vrPriorCheckModalCtx}
            <Button
              id={`${event.id}_eventFootageRequest`}
              className={styles.actionBtn}
              loading={loading}
              type="primary"
              onClick={handleRequestVideo}
            >
              {t('Footage.RequestEventVideo')}
            </Button>
          </>
        );
      case AttachmentStatus.REQUESTED:
        return (
          <p id={`${event.id}_eventFootageRequested`} className={styles.info}>
            {t('Footage.EventVideoRequested')}
          </p>
        );
      default:
        break;
    }
  }

  if (event.hasOwnProperty('url')) {
    if (event.eventType === 'SPEED' && hasGeoFenceSpeedOverrideService) {
      return (
        <div style={{ justifyContent: 'normal' }}>
          <TextButton
            style={{ width: '40%', marginLeft: '0px' }}
            text={t('Common.SpeedManagement')}
            variant="primary"
            onClick={handleSpeedManagementRedirect}
            id="btn_eventInfoViewDetails"
          />
          <TextButton
            style={{ width: '40%', marginLeft: '20px' }}
            text={t('Tracking.ViewDetails')}
            variant="primary"
            onClick={() => window.open(event.url, '_blank')}
            id="btn_eventInfoViewDetails"
          />
        </div>
      );
    }
    return (
      <TextButton
        style={{ width: '50%', marginLeft: '0px' }}
        text={t('Tracking.ViewDetails')}
        variant="primary"
        onClick={() => window.open(event.url, '_blank')}
        id="btn_eventInfoViewDetails"
      />
    );
  }
  return null;
};

const VREventAction = ({ t, event, onRequestEvtVideo = () => {} }) => {
  if (
    [AttachmentStatus.INPROGRESS, AttachmentStatus.FAILED].includes(
      getVRRequestStatus(event?.requestVideo?.requestStatus)
    )
  ) {
    return (
      <Button
        id={`${event.id}_requestVideo`}
        className={styles.actionBtn}
        type="primary"
        onClick={onRequestEvtVideo}
      >
        {t('Footage.RequestVideo')}
      </Button>
    );
  }

  if (event.hasOwnProperty('url')) {
    return (
      <TextButton
        style={{ width: '50%', marginLeft: '0px' }}
        text={t('Tracking.ViewDetails')}
        variant="primary"
        onClick={() => window.open(event.url, '_blank')}
        id="btn_eventInfoViewDetails"
      />
    );
  }
  return null;
};
