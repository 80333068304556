import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GOOGLE_MAPS_API_KEY } from 'config';

import JourneyMap from './JourneyMap';
import FormTitle from 'components/form/form-title/FormTitle';
import InfoRow from 'components/form/info-row/InfoRow';
import { LoadingTable } from 'components/grid/LoadingTable';
import { Row } from 'antd';
import { getJourneyById } from 'features/journeyPlanner/journeysSlice';
import { useUserKey } from 'features/user/userSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useLocalization } from 'features/localization/localizationSlice';

import { secondsToHHMMSS, getIDFromPathname } from 'utils/methods';
import { journeyHelpers } from './helpers';
import { getJourneyType } from './constants';
import logo from '../../static/images/tn-logo-green-140px.png';

//styles
import styles from './JourneyPlanner.module.scss';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';

const MapToPrint = React.forwardRef(({ setMapLoaded, setName }, ref) => {
  const id = getIDFromPathname(window.location.pathname);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const company = useCurrentCompany();
  const localization = useLocalization();
  const [journey, setJourney] = useState(null);
  const [waypoints, setWaypoints] = useState(null);
  const [journeyGeofences, setJourneyGeofences] = useState([]);
  const [mapDirections, setMapDirections] = useState(null);
  const route = mapDirections && mapDirections.routes[0];
  const totalDistanceCalculated = route && journeyHelpers.computeTotalDistance(route);
  const totalDuration = route && journeyHelpers.computeTotalDuration(route);
  const distanceAndDuration =
    journey &&
    `${localization.convertDistance(totalDistanceCalculated || 0)} ${
      localization.formats.speed.unit
    } (${secondsToHHMMSS(journey.estimatedDuration || totalDuration)})`;

  const journeyMapFormRef = useRef(null);
  const handleMapDirectionsChange = useMemo(
    () => ({
      onNoDirection() {
        setMapDirections(null);
      },
      onDirectionsSucceed: setMapDirections,
      onDirectionError() {
        //adjust map zoom level by visibleMarkers
        journeyHelpers.adjustMapWithWaypoints(journeyMapFormRef, waypoints);
      }
    }),
    [journeyMapFormRef, waypoints]
  );

  const journeyPlannerFoldersFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.journeyPlannerFolders.flag
  });

  useEffect(() => {
    if (id && userKey) {
      getJourneyById(id, userKey, company.id).then(journey => {
        const filteredStops = journeyHelpers.getFilteredStops(journey.stops);
        const geofences = journey.stops.reduce((acc, stop) => {
          stop.geofence && acc.push({ ...stop.geofence });
          return acc;
        }, []);

        setJourney(journey);
        setName(journey.name);

        //build the directions by attr or stops/waypoints
        if (journey.attr) {
          const directionsRequest = JSON.parse(journey.attr);
          if (directionsRequest.waypoints?.length) {
            const newWaypoints = [...filteredStops];
            directionsRequest.waypoints.forEach((waypt, index) => {
              if (waypt.stopover === false) {
                newWaypoints.splice(index + 1, 0, { ...waypt, stopover: false });
              }
            });
            setWaypoints(newWaypoints);
          } else {
            setWaypoints(filteredStops);
          }
        } else {
          setWaypoints(filteredStops);
        }

        if (geofences) {
          setJourneyGeofences(geofences);
        }

        dispatch(setPageTitle(journey.name));
        dispatch(setBackButton(true));
      });
    }
  }, [id, dispatch, userKey, company.id]);

  if (!journey) {
    return <LoadingTable columnSizes={[400]} />;
  }

  return (
    <>
      <div className={styles.mapExport} ref={ref}>
        <div className={styles.pageHeader}>
          <img src={logo} alt="Teletrac Navman" className={styles.logo} />
        </div>
        <table className={styles.mapTable}>
          <thead>
            <tr>
              <td>
                <div className={styles.pageHeaderSpace}></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.mapTable}>
                <FormTitle title={t('JourneyPlanner.View.JourneyDetails')} />
                <InfoRow
                  label={`${t('JourneyPlanner.View.Name')} (${t('JourneyPlanner.View.Type')})`}
                  value={`${journey.name} (${getJourneyType(journey.type)})`}
                  styles={styles}
                />
                <InfoRow
                  label={`${t('JourneyPlanner.View.Distance')} (${t(
                    'JourneyPlanner.View.Duration'
                  )})`}
                  value={distanceAndDuration}
                  styles={styles}
                />
                {journeyPlannerFoldersFlag && (
                  <InfoRow
                    label={`${t('JourneyPlanner.Folders')}`}
                    value={`${
                      journey?.folders
                        ? journey?.folders
                            ?.map(f => f?.name)
                            ?.sort()
                            ?.join(', ')
                        : ''
                    }`}
                    styles={styles}
                  />
                )}
                <InfoRow
                  label={t('JourneyPlanner.View.LastUpdated')}
                  value={journeyHelpers.format_dby_imp(journey, 'updatedAt', localization)}
                  styles={styles}
                />
                <Row>
                  <JourneyMap
                    ref={journeyMapFormRef}
                    isMarkerShown
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`}
                    loadingElement={<div className={styles.journeyMapLoadingElement} />}
                    containerElement={<div className={styles.journeyMapContainerElement} />}
                    mapElement={<div className={styles.journeyMapElement} />}
                    waypoints={waypoints}
                    geofences={journeyGeofences}
                    {...handleMapDirectionsChange}
                    readonly
                    onMapLoaded={() => setMapLoaded()}
                  />
                  <div id="panel" className={styles.printDirectionsPanel}></div>
                </Row>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
});

export default MapToPrint;
