import { LoadingTable } from 'components/grid/LoadingTable';
import { useCompanies } from 'features/company/companySlice';
import { useFleets } from 'features/fleets/fleetsSlice';
import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { Link } from 'react-router-dom';
import {
  cache,
  detailsCellRenderer,
  deviceNameCellRenderer,
  ellipsisButtonCellRenderer,
  typeCellRenderer,
  gpioCellRenderer,
  vehicleCellRenderer
} from './CellRenderers';
import { Empty } from 'components/ant';
import { useTranslation } from 'react-i18next';
import {
  useCan,
  entities,
  useIQCameraUser,
  GlobalRoles,
  FeatureFlag,
  Can,
  useCanFeatureFlag
} from 'features/permissions';
import services from 'features/permissions/services';
import { deviceTypeModelTranslate } from './constants';
import { useUser } from 'features/user/userSlice';

export const DevicesTable = ({
  devices,
  isLoading,
  handleDeleteAction,
  typeOfEntityToDelete,
  setTableRef,
  handleAction,
  filterTab,
  getDeviceModelName = modelName => modelName
}) => {
  const can = useCan();
  const canViewDevice = can && can({ everyEntity: [entities.DEVICE_VIEW] });
  const canViewVehicle = can && can({ everyEntity: [entities.VEHICLE_VIEW] });
  const companies = useCompanies();
  const fleetsList = useFleets();
  const { t } = useTranslation();
  const { canAccessNonCameraFeatures } = useIQCameraUser();
  const canControlGpioConfiguration = can({
    oneOfRoles: [GlobalRoles.Reseller, GlobalRoles.SiteAdmin],
    oneOfCompanyServices: [services.GPIO]
  });

  const currentUser = useUser();
  const isSiteAdmin = currentUser.siteAdmin;

  if (isLoading) return <LoadingTable columnSizes={[70, 42, 105, 47, 58, 43, 29]} />;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          name: 200,
          details: 225,
          type: 150,
          model: 145,
          vehicle: 150,
          imei: 150,
          gpio: 150,
          ellipsisButton: 60
        };

        const isDeleteTab = filterTab === 'deleted';

        const dynamicColumnWidth =
          width -
          (fixedColumnsWidth.name +
            fixedColumnsWidth.type +
            fixedColumnsWidth.model +
            fixedColumnsWidth.details +
            fixedColumnsWidth.vehicle +
            (canControlGpioConfiguration && !isDeleteTab ? fixedColumnsWidth.gpio : 0) +
            fixedColumnsWidth.ellipsisButton);

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={52}
            headerStyle={{ paddingTop: '18px' }}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={devices.length}
            rowGetter={({ index }) => devices[index]}
            rowStyle={{ alignItems: 'flex-start' }}
            ref={ref => setTableRef(ref)}
            noRowsRenderer={() => <Empty />}
          >
            <Column
              label={t('Devices.Table.NameLabel')}
              dataKey="name"
              width={fixedColumnsWidth.name}
              cellRenderer={props => deviceNameCellRenderer({ ...props, canViewDevice })}
            />
            <Column
              label={t('Devices.Table.DetailsLabel')}
              dataKey="name"
              width={fixedColumnsWidth.details}
              cellRenderer={props =>
                detailsCellRenderer({
                  ...props,
                  companies: companies,
                  fleetsAPIList: fleetsList
                })
              }
            />
            <Column
              label={t('Devices.Table.TypeLabel')}
              dataKey="type"
              width={fixedColumnsWidth.type}
              cellRenderer={({ rowData }) =>
                typeCellRenderer({
                  typeName: rowData?.type?.name,
                  modelName: getDeviceModelName(rowData?.model?.name)
                })
              }
            />
            <Column
              label={t('Devices.ActualForm.ModelLabel')}
              dataKey="model"
              width={fixedColumnsWidth.model}
              cellRenderer={({ cellData }) => (
                <div title={deviceTypeModelTranslate(getDeviceModelName(cellData?.name))}>
                  {deviceTypeModelTranslate(getDeviceModelName(cellData?.name))}
                </div>
              )}
            />
            <Column
              label={t('Devices.Table.VehicleLabel')}
              dataKey="vehicle"
              width={fixedColumnsWidth.vehicle}
              cellRenderer={props => vehicleCellRenderer({ ...props, canViewVehicle })}
            />
            <Column
              label={t('Devices.Table.ImeiLabel')}
              dataKey="imei"
              width={
                canControlGpioConfiguration && !isDeleteTab
                  ? fixedColumnsWidth.imei
                  : dynamicColumnWidth
              }
            />

            {canControlGpioConfiguration && !isDeleteTab && (
              <Column
                label={t('Devices.Table.GpioTemplate')}
                dataKey="GpioTemplate"
                width={dynamicColumnWidth}
                cellRenderer={props => gpioCellRenderer({ ...props, canViewDevice })}
              />
            )}
            {canAccessNonCameraFeatures && (
              <Column
                label={t('Devices.Table.ActionsLabel')}
                dataKey=""
                style={{ overflow: 'visible' }}
                width={fixedColumnsWidth.ellipsisButton}
                cellRenderer={props => {
                  const customAction = (
                    <Can
                      everyEntity={[entities[`DEVICE_UPDATE`]]}
                      oneOfRoles={[GlobalRoles.SubCompanyAdmin]}
                    >
                      <Link
                        to={{
                          pathname: `/settings/devices/agreement`,
                          state: { devices: [{ id: props.rowData?.id }] }
                        }}
                      >
                        <li>{t('Agreement.AssignAgreement')}</li>
                      </Link>
                    </Can>
                  );

                  const customDeleteContent =
                    props.rowData?.type.code === 'EDR' ? (
                      <div>
                        {t('Vehicles.Form.UpdateEDRWarning', {
                          action: t('Vehicles.Form.Delete'),
                          type: t('Common.Device').toLowerCase()
                        })}
                        <br />
                        {t('Vehicles.Form.UpdateEDRWarning2')}
                      </div>
                    ) : (
                      undefined
                    );
                  return ellipsisButtonCellRenderer({
                    ...props,
                    handleDeleteAction,
                    typeOfEntityToDelete,
                    hideConfig: isDeleteTab,
                    hideEdit: isDeleteTab,
                    hideDelete: isDeleteTab || !isSiteAdmin,
                    customDeleteContent,
                    enableRestoreAction: isDeleteTab,
                    customAction: customAction,
                    handleAction
                  });
                }}
              />
            )}
          </Table>
        );
      }}
    </AutoSizer>
  );
};
