import React from 'react';

const DownloadIcon1 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <g clipPath="url(#clip0_544_15411)">
      <path
        d="M7.77776 10H2.22222C1.62868 10 1.07063 9.76885 0.650885 9.3491C0.231155 8.9294 0 8.37134 0 7.77776V7.22219C0 6.91535 0.248718 6.66661 0.555559 6.66661C0.8624 6.66661 1.11112 6.91535 1.11112 7.22219V7.77776C1.11112 8.07455 1.2267 8.35356 1.43653 8.56341C1.64642 8.77328 1.92543 8.88888 2.22222 8.88888H7.77776C8.07453 8.88888 8.35355 8.77328 8.56341 8.56341C8.77328 8.35353 8.88888 8.07451 8.88888 7.77776V7.22219C8.88888 6.91535 9.1376 6.66661 9.44442 6.66661C9.75124 6.66661 10 6.91535 10 7.22219V7.77776C10 8.3713 9.76885 8.92933 9.3491 9.3491C8.92935 9.76885 8.3713 10 7.77776 10ZM4.99999 7.77774C4.92314 7.77774 4.84998 7.76214 4.78341 7.73394C4.72131 7.70771 4.66295 7.66959 4.61189 7.61973C4.61187 7.61972 4.61187 7.6197 4.61185 7.6197C4.61149 7.61935 4.61112 7.61898 4.61075 7.61861C4.61065 7.61853 4.61053 7.6184 4.61044 7.6183C4.61013 7.61803 4.60985 7.61774 4.60956 7.61744C4.60937 7.61725 4.6092 7.61709 4.609 7.61688C4.60881 7.61669 4.60855 7.61644 4.60838 7.61628C4.60799 7.61589 4.60756 7.61547 4.60718 7.61508L2.38494 5.39282C2.16799 5.17587 2.16799 4.82411 2.38494 4.60714C2.60189 4.39019 2.95368 4.39017 3.17063 4.60714L4.44445 5.88096V0.555559C4.44443 0.248718 4.69315 0 4.99999 0C5.30683 0 5.55557 0.248718 5.55557 0.555559V5.88094L6.82937 4.60714C7.0463 4.39019 7.39813 4.39019 7.61506 4.60714C7.83201 4.82409 7.83201 5.17587 7.61506 5.39282L5.39282 7.61504C5.39243 7.61543 5.39201 7.61585 5.39162 7.61624C5.39141 7.61644 5.39117 7.61669 5.391 7.61684C5.3908 7.61706 5.39063 7.61721 5.39044 7.61741C5.39016 7.61772 5.38985 7.61799 5.38958 7.61826C5.38948 7.61836 5.38935 7.61849 5.38925 7.61857C5.3889 7.61894 5.38853 7.61931 5.38816 7.61966C5.38815 7.61966 5.38815 7.61968 5.38813 7.6197C5.38201 7.62565 5.37582 7.63144 5.3695 7.63706C5.32298 7.67856 5.27116 7.71091 5.21638 7.734C5.21618 7.73408 5.21603 7.73416 5.21583 7.73424C5.21562 7.73431 5.21544 7.73443 5.21523 7.73449C5.14901 7.76237 5.07632 7.77774 4.99999 7.77774Z"
        fill="#DADEE3"
      />
    </g>
    <defs>
      <clipPath id="clip0_544_15411">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const RightIcon1 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M11.438 22.125L17.563 16L11.438 9.875L13.313 8L21.313 16L13.313 24L11.438 22.125Z"
      fill="#BCC3CD"
    />
  </svg>
);

const LeftIcon1 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M20.563 22.125L18.688 24L10.688 16L18.688 8L20.563 9.875L14.438 16L20.563 22.125Z"
      fill="#BCC3CD"
    />
  </svg>
);

export const DownloadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <g clipPath="url(#clip0_544_15411)">
      <path
        d="M7.77776 10H2.22222C1.62868 10 1.07063 9.76885 0.650885 9.3491C0.231155 8.9294 0 8.37134 0 7.77776V7.22219C0 6.91535 0.248718 6.66661 0.555559 6.66661C0.8624 6.66661 1.11112 6.91535 1.11112 7.22219V7.77776C1.11112 8.07455 1.2267 8.35356 1.43653 8.56341C1.64642 8.77328 1.92543 8.88888 2.22222 8.88888H7.77776C8.07453 8.88888 8.35355 8.77328 8.56341 8.56341C8.77328 8.35353 8.88888 8.07451 8.88888 7.77776V7.22219C8.88888 6.91535 9.1376 6.66661 9.44442 6.66661C9.75124 6.66661 10 6.91535 10 7.22219V7.77776C10 8.3713 9.76885 8.92933 9.3491 9.3491C8.92935 9.76885 8.3713 10 7.77776 10ZM4.99999 7.77774C4.92314 7.77774 4.84998 7.76214 4.78341 7.73394C4.72131 7.70771 4.66295 7.66959 4.61189 7.61973C4.61187 7.61972 4.61187 7.6197 4.61185 7.6197C4.61149 7.61935 4.61112 7.61898 4.61075 7.61861C4.61065 7.61853 4.61053 7.6184 4.61044 7.6183C4.61013 7.61803 4.60985 7.61774 4.60956 7.61744C4.60937 7.61725 4.6092 7.61709 4.609 7.61688C4.60881 7.61669 4.60855 7.61644 4.60838 7.61628C4.60799 7.61589 4.60756 7.61547 4.60718 7.61508L2.38494 5.39282C2.16799 5.17587 2.16799 4.82411 2.38494 4.60714C2.60189 4.39019 2.95368 4.39017 3.17063 4.60714L4.44445 5.88096V0.555559C4.44443 0.248718 4.69315 0 4.99999 0C5.30683 0 5.55557 0.248718 5.55557 0.555559V5.88094L6.82937 4.60714C7.0463 4.39019 7.39813 4.39019 7.61506 4.60714C7.83201 4.82409 7.83201 5.17587 7.61506 5.39282L5.39282 7.61504C5.39243 7.61543 5.39201 7.61585 5.39162 7.61624C5.39141 7.61644 5.39117 7.61669 5.391 7.61684C5.3908 7.61706 5.39063 7.61721 5.39044 7.61741C5.39016 7.61772 5.38985 7.61799 5.38958 7.61826C5.38948 7.61836 5.38935 7.61849 5.38925 7.61857C5.3889 7.61894 5.38853 7.61931 5.38816 7.61966C5.38815 7.61966 5.38815 7.61968 5.38813 7.6197C5.38201 7.62565 5.37582 7.63144 5.3695 7.63706C5.32298 7.67856 5.27116 7.71091 5.21638 7.734C5.21618 7.73408 5.21603 7.73416 5.21583 7.73424C5.21562 7.73431 5.21544 7.73443 5.21523 7.73449C5.14901 7.76237 5.07632 7.77774 4.99999 7.77774Z"
        fill="#DADEE3"
      />
    </g>
    <defs>
      <clipPath id="clip0_544_15411">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const RightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M11.438 22.125L17.563 16L11.438 9.875L13.313 8L21.313 16L13.313 24L11.438 22.125Z"
      fill="#BCC3CD"
    />
  </svg>
);

export const LeftIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M20.563 22.125L18.688 24L10.688 16L18.688 8L20.563 9.875L14.438 16L20.563 22.125Z"
      fill="#BCC3CD"
    />
  </svg>
);

export const DeleteIcon = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.125 3H14.75V4.5H4.25V3H6.875L7.625 2.25H11.375L12.125 3ZM6.5 15.75C5.675 15.75 5 15.075 5 14.25V5.25H14V14.25C14 15.075 13.325 15.75 12.5 15.75H6.5Z"
      fill="#818EA1"
    />
  </svg>
);

export const EditIcon = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0325 4.22251C16.325 4.51501 16.325 4.98751 16.0325 5.28001L14.66 6.65251L11.8475 3.84001L13.22 2.46751C13.5125 2.17501 13.985 2.17501 14.2775 2.46751L16.0325 4.22251ZM2.75 15.75V12.9375L11.045 4.64251L13.8575 7.45501L5.5625 15.75H2.75Z"
      fill="#818EA1"
    />
  </svg>
);
