import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { pageStyle } from '../../JourneyPlanner/constants';
import logo from '../../../static/images/tn-logo-green-140px.png';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import styles from 'components/tn/grid/grid.module.scss';
import style from './InstallationLog.module.scss';
import FormTitle from 'components/form/form-title/FormTitle';
import InfoRow from 'components/form/info-row/InfoRow';
import { Accordion } from 'components/ant';
import { capitalize } from 'utils/strings';
import { formatMeterValue, formatOdometer } from 'features/meters';

const DownloadAuditLogsView = ({
  triggerButton,
  setTriggerButton,
  state,
  findValueByTitle,
  testResults
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const [viewData, setViewData] = useState(state);
  const [supportType, setSupportType] = useState(state?.supportType);
  // const [testResults, setTestResults] = useState([]);
  const [flag, setFlag] = useState('details');

  // const capitalize = s => s && s[0].toUpperCase() + s.slice(1);
  const [icon, setIcon] = useState([true, true, true, true, true, true]);
  const handleFilterClick = value => {
    setFlag(value);
  };

  useEffect(() => {
    if (triggerButton) {
      handlePrint();
    }
  }, [triggerButton]);

  const componentRef = React.useRef(null);
  const handleCloseTab = () => {
    window.close();
    setTriggerButton(false);
  };

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `${t('SupportToolsELD.FeatureTitleInstallationLogs')} - ${state?.entityName ||
      ''}`,
    onAfterPrint: handleCloseTab,
    pageStyle
  });

  return (
    <>
      <ReactToPrint
        trigger={() => <button style={{ display: 'none' }}></button>}
        content={reactToPrintContent}
      />
      {viewData && (
        <ContainerPageWrapper>
          <div ref={componentRef}>
            <div className={style.pageHeader}>
              <img src={logo} alt="Teletrac Navman" className={style.logo} />
            </div>

            <div className={style.pageHeaderSpace}></div>

            <FormTitle title={t('SupportToolsELD.FeatureTitleInstallationLogs')} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 32px',
                color: '#181c21'
              }}
            >
              {viewData && (
                <>
                  <InfoRow
                    label={t(
                      `SupportToolsELD.InstallationLogsColumns.${supportType &&
                        capitalize(supportType)}`
                    )}
                    value={viewData?.[supportType]?.name || ''}
                    styles={styles}
                  />
                  <InfoRow
                    label={t(`SupportToolsELD.InstallationLogsColumns.Company`)}
                    value={viewData?.company?.id || ''}
                    styles={styles}
                  />
                  <InfoRow
                    label={t(`SupportToolsELD.InstallationLogsColumns.Fleet`)}
                    value={viewData?.vehicle?.fleet || ''}
                    styles={styles}
                  />
                  <InfoRow
                    label={t(`SupportToolsELD.InstallationLogsColumns.Engineer`)}
                    value={viewData?.vehicle?.engineer || ''}
                    styles={styles}
                  />
                  <InfoRow
                    label={t(`SupportToolsELD.InstallationLogsColumns.WorkOrder`)}
                    value={viewData?.vehicle?.workorder || ''}
                    styles={styles}
                  />
                  <InfoRow
                    label={t(`SupportToolsELD.InstallationLogsColumns.OperationType`)}
                    value={viewData?.action || ''}
                    styles={styles}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      maxWidth: '100%',
                      borderBottom: '1px solid #EEE',
                      padding: '10px 0'
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <Accordion
                        title={t('SupportToolsELD.InstallationLogsView.OperationDescription')}
                        body={
                          <>
                            <div style={{ display: 'flex', width: '70%' }}>
                              <p
                                style={{
                                  border: '1px solid #EEE',
                                  backgroundColor: flag == 'details' ? '#86b341' : '#EEE',
                                  padding: '0px 5px 0px',
                                  marginRight: '15px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => handleFilterClick('details')}
                              >
                                {t(`SupportToolsELD.InstallationLogsView.Details`)}
                              </p>
                              <p
                                style={{
                                  border: '1px solid #EEE',
                                  backgroundColor: flag == 'odometer' ? '#86b341' : '#EEE',
                                  padding: '0px 5px 0px',
                                  marginRight: '15px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => handleFilterClick('odometer')}
                              >
                                {t(`SupportToolsELD.InstallationLogsView.OdometerEngineHours`)}
                              </p>
                              <p
                                style={{
                                  border: '1px solid #EEE',
                                  backgroundColor: flag == 'accelerometer' ? '#86b341' : '#EEE',
                                  padding: '0px 5px 0px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => handleFilterClick('accelerometer')}
                              >
                                {t(
                                  `SupportToolsELD.InstallationLogsView.AccelerometerLastCalibrated`
                                )}
                              </p>
                            </div>
                            {flag == 'details' && (
                              <div className={style.megaDropView}>
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsView.DateAndTime')}
                                  value={viewData?.[supportType]?.createdAt || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsView.Operation')}
                                  value={viewData?.action || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t(
                                    'SupportToolsELD.InstallationLogsColumns.OperationStatus'
                                  )}
                                  value={viewData?.status || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.Engineer')}
                                  value={viewData?.vehicle?.engineer || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.IMEI')}
                                  value={viewData?.device?.imei || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t(
                                    'SupportToolsELD.InstallationLogsView.RegistrationOrLicence'
                                  )}
                                  value={viewData?.vehicle?.registration || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.Vehicle')}
                                  value={viewData?.vehicle?.name || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.Make')}
                                  value={viewData?.vehicle?.make || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.Model')}
                                  value={viewData?.vehicle?.model || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.Odometer')}
                                  value={
                                    formatOdometer(localization, viewData?.vehicle?.odometer) || ''
                                  }
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.EngineHours')}
                                  value={viewData?.vehicle?.engineHours || ''}
                                  styles={styles}
                                />
                              </div>
                            )}
                            {flag == 'odometer' && (
                              <div className={style.megaDropView}>
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsView.DateAndTime')}
                                  value={viewData?.[supportType]?.createdAt || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.EngineHours')}
                                  value={viewData?.vehicle?.engineHours || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.Odometer')}
                                  value={
                                    formatOdometer(localization, viewData?.vehicle?.odometer) || ''
                                  }
                                  styles={styles}
                                />
                              </div>
                            )}
                            {flag == 'accelerometer' && (
                              <div className={style.megaDropView}>
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsView.DateAndTime')}
                                  value={viewData?.[supportType]?.createdAt || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsView.Accelerometer')}
                                  value={''}
                                  styles={styles}
                                />
                              </div>
                            )}
                          </>
                        }
                        icon={icon[0]}
                        isOpen={true}
                      />
                      <Accordion
                        title={t('SupportToolsELD.InstallationLogsView.VPMInformation')}
                        body={
                          <div className={style.megaDropView}>
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.Charge')}
                              value={findValueByTitle(testResults, '').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.EngineLoad')}
                              value={findValueByTitle(testResults, '').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.FuelConsumption')}
                              value={findValueByTitle(testResults, '').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.FuelLevel')}
                              value={findValueByTitle(testResults, '').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.Gear')}
                              value={findValueByTitle(testResults, '').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.GPS')}
                              value={
                                findValueByTitle(testResults, 'VPM Information: Gps')?.value?.At
                              }
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.Hours')}
                              value={findValueByTitle(testResults, 'VPM Information: Hours').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.ID')}
                              value={findValueByTitle(testResults, 'VPM Information: Id').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t(
                                'SupportToolsELD.InstallationLogsView.MalfunctionIndicatorLightActive'
                              )}
                              value={findValueByTitle(testResults, 'VPM Information: Mil').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.Odometer')}
                              value={
                                findValueByTitle(testResults, 'VPM Information: Odometer').title
                              }
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.OilPressure')}
                              value={
                                findValueByTitle(testResults, 'VPM Information: Oilpressure').title
                              }
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.OilTemp')}
                              value={
                                findValueByTitle(testResults, 'VPM Information: Oiltemp').title
                              }
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.RPM')}
                              value={findValueByTitle(testResults, 'VPM Information: Rpm').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.Speed')}
                              value={findValueByTitle(testResults, 'VPM Information: Speed').title}
                              styles={styles}
                            />
                          </div>
                        }
                        icon={icon[1]}
                        isOpen={true}
                      />
                      <Accordion
                        title={t('SupportToolsELD.InstallationLogsView.LastCommDetails')}
                        body={
                          <div className={style.megaDropView}>
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.LastCommValue')}
                              value={findValueByTitle(testResults, 'General: Last Comm').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.LastCommStatus')}
                              value={findValueByTitle(testResults, 'General: Last Comm').status}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.LastCommDetails')}
                              value={findValueByTitle(testResults, 'General: Last Comm').subtitle}
                              styles={styles}
                            />
                          </div>
                        }
                        icon={icon[2]}
                        isOpen={true}
                      />
                      <Accordion
                        title={t('SupportToolsELD.InstallationLogsView.IgnitionDetails')}
                        body={
                          <div className={style.megaDropView}>
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.IgnitionValue')}
                              value={findValueByTitle(testResults, 'General: Ignition').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.IgnitionStatus')}
                              value={findValueByTitle(testResults, 'General: Ignition').status}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.IgnitionDetails')}
                              value={findValueByTitle(testResults, 'General: Ignition').subtitle}
                              styles={styles}
                            />
                          </div>
                        }
                        icon={icon[3]}
                        isOpen={true}
                      />
                      <Accordion
                        title={t('SupportToolsELD.InstallationLogsView.DriverDetails')}
                        body={
                          <div className={style.megaDropView}>
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.DriverValue')}
                              value={findValueByTitle(testResults, 'General: Driver').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.DriverStatus')}
                              value={findValueByTitle(testResults, 'General: Driver').status}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.DriverDetails')}
                              value={findValueByTitle(testResults, 'General: Driver').subtitle}
                              styles={styles}
                            />
                          </div>
                        }
                        icon={icon[4]}
                        isOpen={true}
                      />
                      <Accordion
                        title={t('SupportToolsELD.InstallationLogsView.GPSDetails')}
                        body={
                          <div className={style.megaDropView}>
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.GPSTimeValue')}
                              value={findValueByTitle(testResults, 'GPS: GPS Time').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.GPSTimeStatus')}
                              value={findValueByTitle(testResults, 'GPS: GPS Time').status}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.GPSTimeDetails')}
                              value={findValueByTitle(testResults, 'GPS: GPS Time').subtitle}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.GPSPositionValue')}
                              value={findValueByTitle(testResults, 'GPS: GPS Position').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.GPSPositionDetails')}
                              value={findValueByTitle(testResults, 'GPS: GPS Position').subtitle}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.GPSHDOPSATValue')}
                              value={findValueByTitle(testResults, 'GPS: GPS HDOP/SAT').title}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.GPSHDOPSATStatus')}
                              value={findValueByTitle(testResults, 'GPS: GPS HDOP/SAT').status}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.GPSHDOPSATDetails')}
                              value={findValueByTitle(testResults, 'GPS: GPS HDOP/SAT').subtitle}
                              styles={styles}
                            />
                          </div>
                        }
                        icon={icon[5]}
                        isOpen={true}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </ContainerPageWrapper>
      )}
    </>
  );
};

export default DownloadAuditLogsView;
