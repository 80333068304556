import { nanoid } from 'nanoid';

import {
  DEVICE_TYPE_OPTIONS,
  EXPIRATION_OPTIONS,
  IDLE_TIMER_OPTIONS,
  INPUT_PROPERTIES,
  MULTISELECT_TYPE,
  SELECT_PROPERTIES,
  TIMER_OPTIONS
} from '../constants';
import ALERT_ACTIONS from '../reducer/alertFormActions';
import { getAlertFormOptions } from './getAlertFormOptions';
import { ALERT_TYPES } from 'containers/Configuration/CompanyAlerts/constants';
import { getCameraVehiclesAndDevices, getStandaloneDevices } from './filterVehicles';

const COMPONENTS_UNIQUE_IDS = {
  ALERT_STATUS_BAR: String(nanoid()),
  NAME_FORM_INPUT: String(nanoid()),
  INPUT_NUMBER: String(nanoid()),
  EXPIRY_SELECT: String(nanoid()),
  TIMER_SELECT: String(nanoid()),
  TIMEZONE_SELECT: String(nanoid()),
  DEVICE_TYPE_SELECT: String(nanoid()),
  METER_TYPE_SELECT: String(nanoid()),
  METER_CONFIGURATION_SELECT: String(nanoid()),
  GPIO_SELECT: String(nanoid()),
  DURESS_SELECT: String(nanoid()),
  MULTISELECT_VEHICLES: String(nanoid()),
  MULTISELECT_FLEETS: String(nanoid()),
  MULTISELECT_DRIVERS: String(nanoid()),
  MULTISELECT_BRANCHES: String(nanoid()),
  MULTISELECT_USERS: String(nanoid()),
  CHECKBOX_CONTAINER: String(nanoid()),
  SWITCH_CONTAINER: String(nanoid())
};

export function getAlertStatusBar(handleDispatch) {
  return {
    id: COMPONENTS_UNIQUE_IDS.ALERT_STATUS_BAR,
    type: 'alertStatusBar',
    onChange: actionType => handleDispatch({ type: actionType })
  };
}

export function getNameFormInput(handleDispatch) {
  return {
    id: COMPONENTS_UNIQUE_IDS.NAME_FORM_INPUT,
    type: 'formInput',
    properties: INPUT_PROPERTIES.NAME,
    onChange: e => handleDispatch({ type: ALERT_ACTIONS.SET_NAME, payload: e.target.value })
  };
}

export function getInputNumber(handleDispatch, action, properties) {
  return {
    id: COMPONENTS_UNIQUE_IDS.INPUT_NUMBER,
    type: 'inputNumber',
    properties: properties,
    onChange: value => handleDispatch({ type: action, payload: value })
  };
}

export function getExpirySelect(handleDispatch) {
  return {
    id: COMPONENTS_UNIQUE_IDS.EXPIRY_SELECT,
    type: 'select',
    properties: SELECT_PROPERTIES.EXPIRATION,
    options: EXPIRATION_OPTIONS,
    onChange: value => handleDispatch({ type: ALERT_ACTIONS.SET_EXPIRY, payload: value })
  };
}

export function getTimeZoneSelect(handleDispatch, timezones) {
  return {
    id: COMPONENTS_UNIQUE_IDS.TIMEZONE_SELECT,
    type: 'select',
    properties: SELECT_PROPERTIES.TIMEZONE,
    options: timezones,
    onChange: value => handleDispatch({ type: ALERT_ACTIONS.SET_TIMEZONE, payload: value })
  };
}

export function getDeviceTypeSelect(handleDispatch) {
  return {
    id: COMPONENTS_UNIQUE_IDS.DEVICE_TYPE_SELECT,
    type: 'select',
    properties: SELECT_PROPERTIES.DEVICE_TYPE,
    options: DEVICE_TYPE_OPTIONS,
    onChange: value => handleDispatch({ type: ALERT_ACTIONS.SET_DEVICE_TYPE, payload: value })
  };
}

export function getMeterAlertConfiguration(handleDispatch, options, isLoading) {
  return {
    id: COMPONENTS_UNIQUE_IDS.METER_CONFIGURATION_SELECT,
    type: 'meterConfiguration',
    properties: SELECT_PROPERTIES.METER_CONFIGURATION_TYPE,
    options: options,
    isLoading: isLoading,
    onChange: {
      meterEntityType: value =>
        handleDispatch({ type: ALERT_ACTIONS.SET_METER_ENTITY_TYPE, payload: value }),
      source: value => handleDispatch({ type: ALERT_ACTIONS.SET_METER_SOURCE, payload: value }),
      operation: value =>
        handleDispatch({ type: ALERT_ACTIONS.SET_METER_OPERATION, payload: value }),
      meterValue: e =>
        handleDispatch({ type: ALERT_ACTIONS.SET_METER_VALUE, payload: e.target.value }),
      meterType: value => handleDispatch({ type: ALERT_ACTIONS.SET_METER_TYPE, payload: value }),
      deviceType: value => handleDispatch({ type: ALERT_ACTIONS.SET_DEVICE_TYPE, payload: value })
    }
  };
}

export function getGPIOTypeSelect(handleDispatch, gpioTypes, multiSelectType) {
  return {
    id: COMPONENTS_UNIQUE_IDS.GPIO_SELECT,
    type: 'select',
    multiSelectType: multiSelectType,
    mode: 'multiple',
    properties: SELECT_PROPERTIES.GPIO_TYPE,
    options: getAlertFormOptions(gpioTypes, MULTISELECT_TYPE.GPIO),
    onChange: value => handleDispatch({ type: ALERT_ACTIONS.SET_GPIO_TYPES, payload: value })
  };
}

export function getDuressTypeSelect(handleDispatch, duressTypes, multiSelectType) {
  return {
    id: COMPONENTS_UNIQUE_IDS.DURESS_SELECT,
    type: 'select',
    multiSelectType: multiSelectType,
    mode: 'multiple',
    properties: SELECT_PROPERTIES.DURESS_TYPE,
    options: duressTypes,
    onChange: value => handleDispatch({ type: ALERT_ACTIONS.SET_DURESS_TYPES, payload: value })
  };
}

export function getVehiclesMultiselect(
  handleDispatch,
  vehicles,
  devices,
  isLoading,
  type,
  state = {},
  initialValue = {}
) {
  // According to TN360WEB-564 - vehicles dropdown needs to also include standalone devices
  const standaloneDevices = getStandaloneDevices(devices);
  let vehiclesDevicesList = vehicles.concat(standaloneDevices);
  // Camera Alerts needs to show only the vehicles with a device of type CAMERA attached.
  if (type === ALERT_TYPES.CAMERA) {
    vehiclesDevicesList = getCameraVehiclesAndDevices(vehicles, standaloneDevices);
  }
  return {
    id: COMPONENTS_UNIQUE_IDS.MULTISELECT_VEHICLES,
    type: 'multiselect',
    multiSelectType: MULTISELECT_TYPE.VEHICLES,
    options: getAlertFormOptions(vehiclesDevicesList, MULTISELECT_TYPE.VEHICLES),
    properties: SELECT_PROPERTIES.VEHICLES,
    isLoading: isLoading,
    onChange: value => {
      //No-standalone device isn't always a VEHICLE, could be a device associated with a vehicle or a deleted device; Keep existing device/vehicle in alert
      const oldSelectedVehicleIds = state?.selectedVehicles || [];
      const oldSelectedDeviceIds = state?.selectedDevices || [];
      const deviceValues = value.filter(
        val =>
          standaloneDevices.some(device => +device?.id === val) ||
          oldSelectedDeviceIds.some(dId => String(dId) === String(val))
      );
      const vehicleValues = value.filter(
        val =>
          !deviceValues.some(dId => String(dId) === String(val)) ||
          oldSelectedVehicleIds.some(vId => String(vId) === String(val))
      );
      handleDispatch({ type: ALERT_ACTIONS.SET_DEVICES, payload: deviceValues });
      handleDispatch({ type: ALERT_ACTIONS.SET_VEHICLES, payload: vehicleValues });
    }
  };
}

export function getFleetsMultiselect(handleDispatch, fleets, isLoading) {
  return {
    id: COMPONENTS_UNIQUE_IDS.MULTISELECT_FLEETS,
    type: 'multiselect',
    multiSelectType: MULTISELECT_TYPE.FLEETS,
    options: getAlertFormOptions(fleets, MULTISELECT_TYPE.FLEETS),
    properties: SELECT_PROPERTIES.FLEETS,
    isLoading: isLoading,
    onChange: value => handleDispatch({ type: ALERT_ACTIONS.SET_FLEETS, payload: value })
  };
}

export function getDriversMultiselect(handleDispatch, drivers, isLoading) {
  return {
    id: COMPONENTS_UNIQUE_IDS.MULTISELECT_DRIVERS,
    type: 'multiselect',
    multiSelectType: MULTISELECT_TYPE.DRIVERS,
    options: getAlertFormOptions(drivers, MULTISELECT_TYPE.DRIVERS),
    properties: SELECT_PROPERTIES.DRIVERS,
    isLoading: isLoading,
    onChange: value => handleDispatch({ type: ALERT_ACTIONS.SET_DRIVERS, payload: value })
  };
}

export function getBranchesMultiselect(handleDispatch, branches, isLoading) {
  return {
    id: COMPONENTS_UNIQUE_IDS.MULTISELECT_BRANCHES,
    type: 'multiselect',
    multiSelectType: MULTISELECT_TYPE.BRANCHES,
    options: getAlertFormOptions(branches, MULTISELECT_TYPE.BRANCHES),
    properties: SELECT_PROPERTIES.BRANCHES,
    isLoading: isLoading,
    onChange: value => handleDispatch({ type: ALERT_ACTIONS.SET_BRANCHES, payload: value })
  };
}

export function getUsersMultiselect(
  handleDispatch,
  users,
  isLoading,
  options,
  properties = SELECT_PROPERTIES.USERS,
  action = ALERT_ACTIONS.SET_USERS,
  type = MULTISELECT_TYPE.USERS
) {
  return {
    id: COMPONENTS_UNIQUE_IDS.MULTISELECT_USERS,
    type: 'multiselect',
    multiSelectType: type,
    options: getAlertFormOptions(users, type, options),
    properties: properties,
    isLoading: isLoading,
    onChange: value => handleDispatch({ type: action, payload: value })
  };
}

export function getCheckboxContainer(handleDispatch, action, options, properties, idPrefix = '') {
  return {
    id: `${idPrefix}${COMPONENTS_UNIQUE_IDS.CHECKBOX_CONTAINER}`,
    type: 'checkbox',
    properties: properties,
    options: options,
    onChange: checkedValues =>
      handleDispatch({
        type: action,
        payload: { value: checkedValues }
      })
  };
}

export function getSwitchContainer(handleDispatch, action, properties, idPrefix = '') {
  return {
    id: `${idPrefix}${COMPONENTS_UNIQUE_IDS.SWITCH_CONTAINER}`,
    type: 'switch',
    properties: properties,
    style: { marginTop: 15 },
    onChange: value =>
      handleDispatch({
        type: action,
        payload: value
      })
  };
}
