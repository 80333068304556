import React from 'react';
import { Card, Statistic, Typography } from 'antd';
import styles from './StatsCard.module.scss';

export const StatsCard = ({ title, content, loading }) => (
  <Card bordered={false} className={styles.card}>
    <Statistic
      title={<Typography.Text ellipsis={true}>{title}</Typography.Text>}
      value={content}
      loading={loading}
    />
  </Card>
);
