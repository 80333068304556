import { LoadingTable } from 'components/grid/LoadingTable';
import React, { useState, useEffect, useRef } from 'react';
import scrollStyles from '../ELD/Overview/Overview.module.scss';
import { ColumnTypes } from 'components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/Filters';
import { Table, Checkbox, Tooltip } from 'antd';
import {
  cache,
  ellipsisButtonCellRenderer,
  nameCellRenderer,
  fleetsCellRenderer,
  plannedDurationCellRenderer,
  typeCellRenderer,
  stopsNumberCellRenderer,
  calculatedDistanceCellRenderer,
  foldersCellRenderer
} from './CellRenderers';
import { useFolderUpdateMode } from 'features/toasts/toastsSlice';
import { useTranslation } from 'react-i18next';
import { useCompanies } from 'features/company/companySlice';
import { useCan } from 'features/permissions';

export const JourneysTableNew = ({
  journeys,
  fleets,
  localization,
  deleteModal,
  filterTab,
  restoreJourney,
  isLoading,
  onJourneySelection
}) => {
  const { t } = useTranslation();
  const companies = useCompanies();
  const folderUpdateMode = useFolderUpdateMode();

  const { tableRef, vtSettings } = useTableResize();
  const can = useCan();

  const [selectedJourneys, setSelectedJourneys] = useState([]);
  console.log(selectedJourneys, 'selectedJourneys');

  useEffect(() => {
    // defaulting checkboxes on tab change
    // if (!folderUpdateMode) {
    setSelectedJourneys([]);
    // }
  }, [journeys, folderUpdateMode]);

  const handleCheckboxChange = (journey, isChecked) => {
    setSelectedJourneys(prevSelected => {
      const updatedSelection = isChecked
        ? [...prevSelected, journey.id]
        : prevSelected.filter(id => id !== journey.id);

      onJourneySelection(updatedSelection);
      return updatedSelection;
    });
  };

  const handleSelectAllChange = isChecked => {
    if (isChecked) {
      setSelectedJourneys(journeys.map(journey => journey.id));
    } else {
      setSelectedJourneys([]);
    }
    onJourneySelection(isChecked ? journeys.map(journey => journey.id) : []);
  };

  const allColumns = [
    {
      title: t('JourneyPlanner.Table.Name'),
      key: 'name',
      width: 200,
      type: ColumnTypes.String,
      dataIndex: 'name',
      render: (value, rowData, rowIndex) => {
        return nameCellRenderer({ rowData });
      },
      sorter: (a, b) => {
        const nameA = (a.name || '').toLowerCase();
        const nameB = (b.name || '').toLowerCase();
        return nameA.localeCompare(nameB);
      },
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('JourneyPlanner.Table.Fleet'),
      key: 'fleet',
      dataIndex: 'fleet',
      width: 200,
      render: (value, rowData, rowIndex) => {
        return fleetsCellRenderer({
          rowData,
          fleets: fleets.concat({ id: -1, name: t('Common.NoFleet') }),
          companies: companies,
          t
        });
      }
    },

    {
      title: t('JourneyPlanner.Table.Type'),
      key: 'type',
      dataIndex: 'type',
      width: 200,
      render: (value, rowData, rowIndex) => {
        return typeCellRenderer({ rowData });
      },
      sorter: (a, b) => {
        const typeA = (a.type || '').toLowerCase();
        const tyoeB = (b.type || '').toLowerCase();
        return typeA.localeCompare(tyoeB);
      },
      sortDirections: ['ascend', 'descend', 'ascend']
    },

    {
      title: t('JourneyPlanner.Table.Folder'),
      key: 'folder',
      dataIndex: 'folder',
      width: 200,
      render: (value, rowData, rowIndex) => {
        return foldersCellRenderer({ rowData });
      }
    },

    {
      title: t('JourneyPlanner.Table.Duration'),
      key: 'estimatedDuration',
      dataIndex: 'estimatedDuration',
      width: 200,
      render: (value, rowData, rowIndex) => {
        return plannedDurationCellRenderer({ rowData });
      },
      sorter: (a, b) => a.estimatedDuration - b.estimatedDuration,
      sortDirections: ['ascend', 'descend', 'ascend']
    },

    {
      title: `${t('JourneyPlanner.Table.Distance')}`,
      key: 'calculatedDistance',
      dataIndex: 'calculatedDistance',
      width: 200,
      render: (value, rowData, rowIndex) => {
        return calculatedDistanceCellRenderer({ rowData, localization });
      },
      sorter: (a, b) => a.calculatedDistance - b.calculatedDistance,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('JourneyPlanner.Table.Stops'),
      key: 'stopsCount',
      dataIndex: 'stopsCount',
      width: 200,
      render: (value, rowData, rowIndex) => {
        return stopsNumberCellRenderer({ rowData });
      },
      sorter: (a, b) => a.stopsCount - b.stopsCount,
      sortDirections: ['ascend', 'descend', 'ascend']
    },

    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.ActionsLabel'),
      key: 'action',
      dataIndex: 'action',
      width: 200,
      render: (value, rowData, rowIndex) => {
        return ellipsisButtonCellRenderer({
          rowData,
          can,
          deleteModal,
          filterTab,
          restoreJourney
        });
      }
    }
  ];

  // Add a checkbox column at the beginning
  folderUpdateMode &&
    allColumns.unshift({
      title: (
        // <Tooltip
        // // title={t('JourneyPlanner.Select all rows')} placement="leftTop"
        // >
        <Checkbox
          indeterminate={selectedJourneys.length > 0 && selectedJourneys.length < journeys.length}
          checked={journeys.length !== 0 && selectedJourneys.length === journeys.length}
          onChange={e => handleSelectAllChange(e.target.checked)}
        />
        // </Tooltip>
      ),
      dataIndex: 'selectAll',
      key: 'selectAll',
      width: 40,
      align: 'center',
      render: (_, record) => {
        const isChecked = selectedJourneys?.some(journey => journey === record.id);
        return (
          <>
            {/* <Tooltip
              title={t('JourneyPlanner.Check/Uncheck to select or unselect this row')}
              placement="leftTop"
            > */}
            <Checkbox
              checked={isChecked}
              onChange={e => handleCheckboxChange(record, e.target.checked)}
            />
            {/* </Tooltip> */}
          </>
        );
      }
    });

  return (
    <Table
      loading={isLoading}
      columns={allColumns}
      dataSource={journeys}
      deferredMeasurementCache={cache}
      className={`${scrollStyles.overviewGrid}`}
      pagination={false}
      ref={tableRef}
      showSorterTooltip={false}
      scroll={vtSettings.scroll}
      components={vtSettings.vt}
      rowClassName={record =>
        folderUpdateMode && selectedJourneys.some(journey => journey.id === record.id)
          ? scrollStyles.greenRow
          : ''
      }
    ></Table>
  );
};
export const useTableResize = () => {
  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);
  const tableRef = useRef();

  const [vtSettings, setVTSettings] = useState({
    scroll: {
      y: 600,
      x: 1200
    }
  });

  const handleResize = entries => {
    if (entries[0]) {
      const { width, height } = entries[0].contentRect;

      const newTableWidth = width > 10 ? width - 10 : width;
      setTableWidth(newTableWidth);

      const header = tableRef.current.querySelector('.ant-table-thead');
      const headerHeight = header?.getBoundingClientRect()?.height || 0;
      const newTableHeight = height - headerHeight - 16;
      setTableHeight(newTableHeight);

      const newVTSettings = {
        scroll: {
          y: newTableHeight,
          x: newTableWidth
        }
      };

      setVTSettings(newVTSettings);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    const tableElement = document.querySelector(`.${scrollStyles.overviewGrid}`);

    if (tableElement) {
      resizeObserver.observe(tableElement);
    }

    return () => {
      if (tableElement) {
        resizeObserver.unobserve(tableElement);
      }
    };
  }, []);

  return { tableRef, vtSettings };
};
