import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { sortBy } from 'lodash';
import { API_PATH } from 'config';
import request from 'superagent';
import { toLower } from 'lodash';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { api } from 'utils/api';
import i18next from 'i18next';

// Endpoint URLS
const FOLDER_DELETE_URL = '/folders/';
const FOLDERS_URL = '/folders';

const folders = {
  list: [],
  uploadFolders: [],
  journeysList: [],
  uploadJourneysFolders: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    isListEmpty: false,
    companyKey: null,
    isJourneyFetching: false
  }
};

function startLoading(state) {
  state.meta.isFetching = true;
}

function startJourneyLoading(state) {
  state.meta.isJourneyFetching = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.meta.isListEmpty = true;
  state.list = [];
  state.meta.companyKey = action.payload.companyKey;
}

export const foldersSlice = createSlice({
  name: 'folders',
  initialState: folders,
  reducers: {
    fetchFoldersStart: startLoading,
    fetchFoldersSuccess(state, { payload }) {
      state.list = sortBy(payload.list, [
        function(o) {
          return toLower(o.name);
        }
      ]);
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchFoldersFailure: loadingFailed,
    fetchUploadFoldersSuccess(state, { payload }) {
      state.uploadFolders = payload;
    },
    fetchUploadFoldersFailure(state, { payload }) {
      state.uploadFolders = [];
    },
    setActiveFolder(state, action) {
      state.activeFolder = action.payload;
    },
    fetchJourneyFoldersStart: startJourneyLoading,
    fetchJourneyFoldersSuccess(state, { payload }) {
      state.journeysList = sortBy(payload.list, [
        function(o) {
          return toLower(o.name);
        }
      ]);
      state.meta.isJourneyFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchJourneyFoldersFailure: loadingFailed,
    fetchJourneyUploadFoldersSuccess(state, { payload }) {
      state.uploadJourneysFolders = payload;
    },
    fetchJourneyUploadFoldersFailure(state, { payload }) {
      state.uploadJourneysFolders = [];
    },
    setJourneyActiveFolder(state, action) {
      state.activeJourneyFolder = action.payload;
    }
  }
});

export const {
  fetchFoldersStart,
  fetchFoldersSuccess,
  fetchFoldersFailure,
  fetchUploadFoldersSuccess,
  fetchUploadFoldersFailure,
  setActiveFolder,
  fetchJourneyFoldersStart,
  fetchJourneyFoldersSuccess,
  fetchJourneyFoldersFailure,
  fetchJourneyUploadFoldersSuccess,
  fetchJourneyUploadFoldersFailure,
  setJourneyActiveFolder
} = foldersSlice.actions;

export const fetchFolders = () => async (dispatch, getState) => {
  const companyKey = getState().companies.current.api_key;
  try {
    if (!companyKey) {
      return;
    }
    if (getState().fleets.meta.isFetching) {
      return;
    }
    dispatch(fetchFoldersStart());
    new Promise((resolve, reject) => {
      const userKey = getState().user.current.auth.key;
      const company_id = getState().companies.current.id;

      if (company_id === undefined || company_id === null) {
        return;
      }
      request('GET', `${API_PATH}/folders?company_id=${company_id}`)
        .set('Authorization', `Token token="${userKey}"`)
        .set('Content-Type', 'application/json')
        .then(res => {
          resolve({ list: res.body, userKey, companyKey });
          dispatch(fetchFoldersSuccess({ list: res.body, companyKey }));
        })
        .catch(err => {
          console.error(err);
          reject({ err, companyKey });
          dispatch(fetchFoldersFailure({ err: err.toString(), companyKey }));
        });
    });
  } catch (err) {
    dispatch(fetchFoldersFailure({ err: err.toString(), companyKey }));
  }
};

export const fetchCompanyFolders = id => async (dispatch, getState) => {
  const {
    companies: { current: currentCompany },
    documents: { uploadCompanyId },
    user: { current: currentUser }
  } = getState();

  const authKey = currentUser?.auth?.key;

  try {
    const response = await api.get(FOLDERS_URL, {
      authKey,
      query: {
        company_id: uploadCompanyId || currentCompany?.id
      }
    });
    const { body } = response;

    if (!body) {
      dispatch(fetchUploadFoldersFailure());
      return;
    }
    // transform response to what's needed in the form select (add value, label and checked)
    const folders = (body || []).map(folder => ({
      ...folder,
      label: folder.name,
      value: folder.id,
      checked: !!folder.checked
    }));
    dispatch(fetchUploadFoldersSuccess(folders));
  } catch (err) {
    console.error(err);
    dispatch(fetchUploadFoldersFailure());
  }
};

// DELETE method
const deleteMethod = (url, id, payload) => {
  let deleteRequest = request.delete(`${API_PATH}${url}${id}`).send();
  const { authKey } = payload;

  //Add the authorization key
  if (authKey) {
    deleteRequest.set('Authorization', `Token token="${authKey}"`);
  } else {
    return;
  }

  return deleteRequest;
};

export const deleteFolder = (id, name) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;

  try {
    const response = await deleteMethod(FOLDER_DELETE_URL, id, { authKey });
    const { ok, redirect, error } = response;

    //Error
    if (!ok && !redirect) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: error
        })
      );
    }

    //Success
    if (ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18next.t('Easydocs.Notifications.FolderDeleteSuccess', {
            name
          })
        })
      );
      dispatch(fetchFolders());
    }
  } catch (err) {
    console.log(err);
  }
};

export const deleteJourneyFolder = (id, name) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;

  try {
    const response = await deleteMethod(FOLDER_DELETE_URL, id, { authKey });
    const { ok, redirect, error } = response;

    //Error
    if (!ok && !redirect) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: error
        })
      );
    }

    //Success
    if (ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18next.t('Easydocs.Notifications.FolderDeleteSuccess', {
            name
          })
        })
      );
      dispatch(fetchJourneyFolders());
    }
  } catch (err) {
    console.log(err);
  }
};

export const useFolders = () => {
  const dispatch = useDispatch();
  const folders = useSelector(state => state.folders.list);
  const isFetching = useSelector(state => state.folders.meta.isFetching);
  const isListEmpty = useSelector(state => state.folders.meta.isListEmpty);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();

  if (!isFetching && (isCompanyKeyDifferent || (folders.length === 0 && !isListEmpty))) {
    dispatch(fetchFolders());
  }
  return folders;
};

export const fetchJourneyFolders = () => async (dispatch, getState) => {
  const companyKey = getState().companies.current.api_key;
  try {
    if (!companyKey) {
      return;
    }
    if (getState().fleets.meta.isJourneyFetching) {
      return;
    }
    dispatch(fetchJourneyFoldersStart());
    new Promise((resolve, reject) => {
      const userKey = getState().user.current.auth.key;
      const company_id = getState().companies.current.id;

      if (company_id === undefined || company_id === null) {
        return;
      }
      request('GET', `${API_PATH}/folders?company_id=${company_id}&entityType=Ng::Trip`)
        .set('Authorization', `Token token="${userKey}"`)
        .set('Content-Type', 'application/json')
        .then(res => {
          resolve({ list: res.body, userKey, companyKey });
          dispatch(fetchJourneyFoldersSuccess({ list: res.body, companyKey }));
        })
        .catch(err => {
          console.error(err);
          reject({ err, companyKey });
          dispatch(fetchJourneyFoldersFailure({ err: err.toString(), companyKey }));
        });
    });
  } catch (err) {
    dispatch(fetchJourneyFoldersFailure({ err: err.toString(), companyKey }));
  }
};

export const useJourneyFolders = () => {
  const dispatch = useDispatch();
  const folders = useSelector(state => state.folders.journeysList);
  const isFetching = useSelector(state => state.folders.meta.isJourneyFetching);
  const isListEmpty = useSelector(state => state.folders.meta.isListEmpty);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();

  if (!isFetching && (isCompanyKeyDifferent || (folders.length === 0 && !isListEmpty))) {
    dispatch(fetchJourneyFolders());
  }
  return folders;
};

export const useIsFetching = () => useSelector(state => state.folders.meta.isFetching);
export const useCompanyKey = () => useSelector(state => state.folders.meta.companyKey);
export const useGetUploadFolders = () => useSelector(state => state.folders.uploadFolders);

const useIsCompanyKeyDifferent = () => useCompanyKey() !== useCurrentCompanyKey();

export default foldersSlice.reducer;
