import React, { useState } from 'react';
import { AvailableTableColumns, ViewMode } from '../constant';
import { Tab } from '../Components/Tab';

export function VideoRequestsTab() {
  const [eventsColumns] = useState([
    AvailableTableColumns.RequestStatus,
    AvailableTableColumns.RequestTime,
    AvailableTableColumns.Device,
    AvailableTableColumns.Vehicle,
    AvailableTableColumns.Driver,
    AvailableTableColumns.EventTime,
    AvailableTableColumns.CameraPosition,
    AvailableTableColumns.Details,
    AvailableTableColumns.Location,
    AvailableTableColumns.Type,
    AvailableTableColumns.Actions
  ]);
  return (
    <Tab
      id="videoRequestTab"
      showViewSwitch={false}
      defaultView={ViewMode.ListView}
      forVideoRequest={true}
      tableColumns={eventsColumns}
    />
  );
}
