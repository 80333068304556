import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import React from 'react';
import { prepareAuthHeaders } from 'services/util';
import { useMemo } from 'react';
import { API_PATH } from 'config';
import { useCurrentCompany } from 'features/company/companySlice';
import i18n from 'i18nextConfig';
import { openToast } from 'features/toasts/toastsSlice';
import { parseErrorMessage } from 'utils/strings';
import { ToastType } from 'components/notifications/toasts/Toast';
import { Trans } from 'react-i18next';

export const ngSpeedAssistConfigurationApi = createApi({
  reducerPath: 'ngSpeedAssistConfigurationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_PATH}`,
    prepareHeaders: prepareAuthHeaders
  }),
  tagTypes: ['speedAssistConfigurationTemplate'],
  endpoints: builder => ({
    getSpeedAssistConfigurationTemplateById: builder.query({
      query: ({ templateId, embed }) => {
        return {
          url: `configuration/SPEEDASSIST/template/${templateId}`,
          params: {
            embed: embed
          }
        };
      },
      async onQueryStarted(query, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedData } = await queryFulfilled;
          dispatch(
            ngSpeedAssistConfigurationApi.util.updateQueryData(
              'getSpeedAssistConfigurationTemplateById',
              query,
              cache => {
                cache.dataFormatted = true;
                cache.configurationTemplate.configurations?.forEach(c => {
                  c.jsonValue = JSON.parse(c.value);
                });
              }
            )
          );
        } catch {}
      },
      providesTags: ['speedAssistConfigurationTemplate']
    }),
    getSpeedAssistDeviceSelectId: builder.query({
      query: ({ companyId, embed, deviceId }) => {
        return {
          url: `configuration/device/${deviceId}/template`,
          params: {
            company_id: companyId,
            configType: 'SPEED_ASSIST'
          }
        };
      },
      providesTags: ['speedAssistConfigurationTemplate']
    }),
    getSpeedAssistValues: builder.query({
      query: ({ deviceId }) => {
        return {
          url: `configuration/${deviceId}/SPEEDASSIST/values`
        };
      },
      providesTags: ['speedAssistConfigurationTemplate']
    }),
    getSpeedAssistValuesByCompany: builder.query({
      query: ({ companyId }) => {
        return {
          url: `configuration/devices/values`,
          params: {
            company_id: companyId,
            service: 'SPEEDASSIST'
          }
        };
      },
      async onQueryStarted(query, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedData } = await queryFulfilled;
          dispatch(
            ngSpeedAssistConfigurationApi.util.updateQueryData(
              'getSpeedAssistValuesByCompany',
              query,
              cache => {
                cache.dataFormatted = true;
                cache.configurationTemplate.configurations?.forEach(c => {
                  c.jsonValue = JSON.parse(c.value);
                });
              }
            )
          );
        } catch {}
      },
      providesTags: ['speedAssistConfigurationTemplate']
    }),
    getSpeedAssistConfigurationTemplates: builder.query({
      query: ({ companyId, embed }) => ({
        url: `configuration/SPEEDASSIST/template`,
        params: {
          company_id: companyId,
          embed: embed
        }
      }),
      providesTags: ['speedAssistConfigurationTemplate']
    }),
    addSpeedAssistConfigurationTemplate: builder.mutation({
      query: ({ body }) => ({
        url: `configuration/SPEEDASSIST/template`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['speedAssistConfigurationTemplate']
    }),
    updateSpeedAssistConfigurationTemplate: builder.mutation({
      query: ({ id, body }) => ({
        url: `configuration/SPEEDASSIST/template/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['speedAssistConfigurationTemplate']
    }),
    copySpeedAssistConfigurationTemplate: builder.mutation({
      query: payload => {
        const { id, companyIds } = payload;
        let url = '/configuration/SPEEDASSIST/template/' + id + '/copy';

        return {
          url,
          method: 'POST',
          body: { targetCompanyIds: companyIds },
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        };
      },
      invalidatesTags: ['speedAssistConfigurationTemplate']
    }),
    deleteSpeedAssistConfigurationTemplateById: builder.mutation({
      query: payload => {
        const { id } = payload;

        return {
          url: `configuration/SPEEDASSIST/template/${id}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: (result, error, arg) => {
        return error ? [] : ['speedAssistConfigurationTemplate'];
      }
    }),
    restoreSpeedAssistConfigurationTemplateById: builder.mutation({
      query: payload => {
        const { id } = payload;

        return {
          url: `configuration/SPEEDASSIST/template/${id}/restore`,
          method: 'PUT'
        };
      },
      invalidatesTags: ['speedAssistConfigurationTemplate']
    }),
    selectedConfigurationTemplate: builder.mutation({
      query: ({ companyId, templateId, id }) => ({
        url: `configuration/SPEEDASSIST/device/${id}`,
        params: {
          company_id: companyId,
          template_id: templateId
        },
        method: 'PUT' // Correct placement for the HTTP method
      }),
      invalidatesTags: ['speedAssistConfigurationTemplate']
    }),
    settingValueForOneDevice: builder.mutation({
      query: ({ companyId, id }) => ({
        url: `configuration/SPEEDASSIST/device/${id}`,
        params: {
          company_id: companyId
        },
        method: 'PUT' // Correct placement for the HTTP method
      }),
      invalidatesTags: ['speedAssistConfigurationTemplate']
    }),
    saveTheSettingValues: builder.mutation({
      query: ({ body, companyId }) => ({
        params: {
          company_id: companyId
        },
        url: `configuration/values`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['speedAssistConfigurationTemplate']
    }),
    getSpeedAssistDeviceLevelSettings: builder.query({
      query: ({ companyId }) => {
        return {
          url: `configuration/device/ids/SPEEDASSIST`,
          params: {
            company_id: companyId
          }
        };
      },
      providesTags: ['speedAssistConfigurationTemplate']
    })
  })
});

export const {
  useGetSpeedAssistConfigurationTemplatesQuery,
  useGetSpeedAssistDeviceSelectIdQuery,
  useGetSpeedAssistValuesQuery,
  useGetSpeedAssistDeviceLevelSettingsQuery,
  useGetSpeedAssistValuesByCompanyQuery,
  useGetSpeedAssistConfigurationTemplateByIdQuery,
  useAddSpeedAssistConfigurationTemplateMutation,
  useUpdateSpeedAssistConfigurationTemplateMutation,
  useCopySpeedAssistConfigurationTemplateMutation,
  useRestoreSpeedAssistConfigurationTemplateByIdMutation,
  useDeleteSpeedAssistConfigurationTemplateByIdMutation,
  useSelectedConfigurationTemplateMutation,
  useSettingValueForOneDeviceMutation,
  useSaveTheSettingValuesMutation
} = ngSpeedAssistConfigurationApi;

export const useSpeedAssistConfigurations = (getActiveOnly = true) => {
  const currentCompany = useCurrentCompany();
  const {
    data: speedassistConfigurations,
    isFetching
  } = useGetSpeedAssistConfigurationTemplatesQuery(
    { companyId: 1383 },
    { skip: 1383 === undefined }
  );

  return useMemo(() => {
    let configurationTemplates = (speedassistConfigurations || []).map(
      i => i.configurationTemplate
    );

    if (getActiveOnly) {
      configurationTemplates = configurationTemplates.filter(i => i.status === 'ENABLED');
    }

    return { data: configurationTemplates, isFetching };
  }, [currentCompany?.id, isFetching]);
};

export const useCurrentCompanyServicesMap = () => {
  const currentCompany = useCurrentCompany();

  return useMemo(() => {
    const serviceMap = currentCompany.features.reduce((map, service) => {
      map[service.code] = service;
      return map;
    }, {});
    return serviceMap;
  }, [currentCompany?.id]);
};

export const executeRestoreSpeedAssistConfigurationTemplate = (
  payload,
  restoreSpeedAssistConfigurationTemplateById,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    restoreSpeedAssistConfigurationTemplateById(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          dispatch,
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ConfigurationRestored', {
            name: payload.name
          }),
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

export const executeDeleteSpeedAssistConfigurationTemplate = (
  payload,
  deleteSpeedAssistConfigurationTemplate,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    deleteSpeedAssistConfigurationTemplate(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          dispatch,
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ConfigurationDeleted', {
            name: payload.name
          }),
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

export const executeSetCompanySpeedAssistConfigurationTemplate = (
  payload,
  updateSpeedAssistConfigurationTemplate,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    updateSpeedAssistConfigurationTemplate(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          dispatch,
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Success', {
            name: payload.name
          }),
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

export const executeCopySpeedAssistConfigurationTemplate = (
  payload,
  copySpeedAssistConfigurationTemplate,
  companies,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    copySpeedAssistConfigurationTemplate(payload)
      .unwrap()
      .then(data => {
        const companyIds = data.companyIds;
        const companyName = companies
          .filter(i => companyIds.find(k => k === i.id))
          .map(i => i.name)
          .join(', ');

        if (data.totalError !== 0) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: data?.errors
                ? data?.errors.map((i, index) => {
                    const errorCompany = companies.find(k => k.id === i.companyId);
                    return (
                      <React.Fragment key={index}>
                        {errorCompany.name + ' : ' + i.message}
                        {<br />}
                      </React.Fragment>
                    );
                  })
                : parseErrorMessage(data.data)
            })
          );
          reject(data);
        } else {
          handleSuccessResult(
            dispatch,
            <Trans
              values={{ companies: companyName }}
              i18nKey={
                'CompanyConfig.DeviceConfigurations.GPIOCopyModal.GpioTemplateClonedResponseMessage'
              }
              components={{
                1: <span style={{ color: '#003a8c' }} />
              }}
            />,
            resolve
          );
        }
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

const handleException = (dispatch, error, reject) => {
  if (error) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseErrorMessage(error.data)
      })
    );
  }

  if (reject) {
    reject(error);
  }
};

const handleSuccessResult = (dispatch, msg, resolve) => {
  dispatch(
    openToast({
      type: ToastType.Success,
      message: msg
    })
  );

  if (resolve) {
    resolve();
  }
};
