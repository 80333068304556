import {
  useCanOneOfServices,
  useCanOneOfEntities,
  useCanFeatureHide,
  useCanOneOfCompanyServices
} from 'features/permissions/canHooks';
import {
  useCan,
  entities,
  services,
  companyFeatures,
  useIQCameraUser,
  FeatureFlag,
  permissions
} from 'features/permissions';
import { ALERT_TYPES } from '../constants';

export const useAlertPermissions = () => {
  const { canAccessNonCameraFeatures } = useIQCameraUser();
  const can = useCan();
  const canHideAlert = useCanFeatureHide(companyFeatures.HTF);
  const cameraPermission = useCanOneOfServices([services.CAMERA]);
  const enginePermission = useCanOneOfServices([services.VPM, services.BPM]);

  const formPermission = useCanOneOfServices([services.FORM]);
  const formEntityPermission = useCanOneOfEntities([
    entities.FORM,
    entities.FORM_AUDIT,
    entities.FORM_COPY,
    entities.FORM_CREATE,
    entities.FORM_DESTROY,
    entities.FORM_UPDATE,
    entities.FORM_VIEW
  ]);

  const geofencePermission = useCanOneOfServices([services.GEOFENCE]);
  const geofenceEntityPermission = useCanOneOfEntities([
    entities.GEOFENCE,
    entities.GEOFENCE_AUDIT,
    entities.GEOFENCE_CREATE,
    entities.GEOFENCE_DESTROY,
    entities.GEOFENCE_UPDATE,
    entities.GEOFENCE_VIEW
  ]);

  const pretripPermission = useCanOneOfServices([services.PRETRIP]);
  const pretripEntityPermission = useCanOneOfEntities([
    entities.PRETRIPCHECKLIST,
    entities.PRETRIPCHECKLIST_AUDIT,
    entities.PRETRIPCHECKLIST_CREATE,
    entities.PRETRIPCHECKLIST_COPY,
    entities.PRETRIPCHECKLIST_DESTROY,
    entities.PRETRIPCHECKLIST_UPDATE,
    entities.PRETRIPCHECKLIST_VIEW
  ]);

  const routePermission = useCanOneOfServices([services.ROUTECOMPLIANCE]);
  const sentinelPermission = useCanOneOfServices([services.SWD, services.EWD, services.EDL]);
  const speedPermission = useCanOneOfServices([services.SPEED]);

  const vehicleMaintenancePermission = useCanOneOfServices([services.VEHMNT]);
  const vehicleMaintenanceEntityPermission = useCanOneOfEntities([
    entities.VEHICLEMAINTENANCESCHEDULE,
    entities.VEHICLEMAINTENANCESCHEDULE_AUDIT,
    entities.VEHICLEMAINTENANCESCHEDULE_CREATE,
    entities.VEHICLEMAINTENANCESCHEDULE_DESTROY,
    entities.VEHICLEMAINTENANCESCHEDULE_UPDATE,
    entities.VEHICLEMAINTENANCESCHEDULE_VIEW
  ]);

  const driverManagementPermission = useCanOneOfServices([services.DMGT]);
  const driverManagementEntityPermission = useCanOneOfEntities([
    entities.DRIVERMANAGEMENTSCHEDULE,
    entities.DRIVERMANAGEMENTSCHEDULE_AUDIT,
    entities.DRIVERMANAGEMENTSCHEDULE_CREATE,
    entities.DRIVERMANAGEMENTSCHEDULE_DESTROY,
    entities.DRIVERMANAGEMENTSCHEDULE_UPDATE,
    entities.DRIVERMANAGEMENTSCHEDULE_VIEW
  ]);

  const driverAndVehiclePermission = useCanOneOfServices([
    services.COMMON,
    services.IAP,
    services.SENTINEL4
  ]);
  const eldPermission = useCanOneOfServices([services.ELD]);
  const smartJobsPermission = useCanOneOfServices([services.SMARTJOBS]);

  const massManagementPermission = useCanOneOfServices([services.MASS]);

  const gpioPermissions = useCanOneOfServices([services.GPIO]);

  const oohPermissions = useCanOneOfServices([services.TRACKING]);
  const duressPermissions = useCanOneOfServices([services.TRACKING]);

  const meterPermission = useCanOneOfServices([services.TRACKING, services.TEMPERATURE]);
  const tachoPermisions = useCanOneOfServices([services.TACHO]);

  const geofenceSpeedManagementPermission = useCanOneOfCompanyServices([
    services.GEOFENCE_SPEED_OVERRIDE
  ]);

  const documentExpirationPermission = can({
    everyCompanyService: [services.EASYDOCS]
  });

  return [
    {
      type: ALERT_TYPES.CAMERA,
      permission: cameraPermission,
      services: [services.CAMERA]
    },
    {
      type: ALERT_TYPES.ENGINE,
      permission: enginePermission && canHideAlert && canAccessNonCameraFeatures,
      services: [services.VPM, services.BPM],
      featureHide: companyFeatures.HTF
    },
    {
      type: ALERT_TYPES.FORM,
      permission: formPermission && formEntityPermission,
      services: [services.FORM],
      entities: [
        entities.FORM,
        entities.FORM_AUDIT,
        entities.FORM_COPY,
        entities.FORM_CREATE,
        entities.FORM_DESTROY,
        entities.FORM_UPDATE,
        entities.FORM_VIEW
      ]
    },
    {
      type: ALERT_TYPES.GEOFENCE,
      permission: geofencePermission && geofenceEntityPermission && canHideAlert,
      services: [services.GEOFENCE],
      entities: [
        entities.GEOFENCE,
        entities.GEOFENCE_AUDIT,
        entities.GEOFENCE_CREATE,
        entities.GEOFENCE_DESTROY,
        entities.GEOFENCE_UPDATE,
        entities.GEOFENCE_VIEW
      ],
      featureHide: companyFeatures.HTF
    },
    {
      type: ALERT_TYPES.GEOFENCE_EXPIRY,
      permission: geofenceSpeedManagementPermission,
      companyServices: [services.GEOFENCE_SPEED_OVERRIDE]
    },
    {
      type: ALERT_TYPES.PRETRIP,
      permission: pretripPermission && pretripEntityPermission,
      services: [services.PRETRIP],
      entities: [
        entities.PRETRIPCHECKLIST,
        entities.PRETRIPCHECKLIST_AUDIT,
        entities.PRETRIPCHECKLIST_CREATE,
        entities.PRETRIPCHECKLIST_COPY,
        entities.PRETRIPCHECKLIST_DESTROY,
        entities.PRETRIPCHECKLIST_UPDATE,
        entities.PRETRIPCHECKLIST_VIEW
      ]
    },
    {
      type: ALERT_TYPES.ROUTE,
      permission: routePermission && canAccessNonCameraFeatures,
      services: [services.ROUTECOMPLIANCE]
    },
    {
      type: ALERT_TYPES.SENTINEL,
      permission: sentinelPermission && canAccessNonCameraFeatures,
      services: [services.SWD, services.EWD, services.EDL]
    },
    {
      type: ALERT_TYPES.SPEED,
      permission: speedPermission && canAccessNonCameraFeatures,
      services: [services.SPEED]
    },
    {
      type: ALERT_TYPES.VEHICLE_MAINTENANCE,
      permission:
        vehicleMaintenancePermission && vehicleMaintenanceEntityPermission && canHideAlert,
      services: [services.VEHMNT],
      entities: [
        entities.VEHICLEMAINTENANCESCHEDULE,
        entities.VEHICLEMAINTENANCESCHEDULE_AUDIT,
        entities.VEHICLEMAINTENANCESCHEDULE_CREATE,
        entities.VEHICLEMAINTENANCESCHEDULE_DESTROY,
        entities.VEHICLEMAINTENANCESCHEDULE_UPDATE,
        entities.VEHICLEMAINTENANCESCHEDULE_VIEW
      ],
      featureHide: companyFeatures.HTF
    },
    {
      type: ALERT_TYPES.DRIVER_MANAGEMENT,
      permission: driverManagementPermission && driverManagementEntityPermission,
      services: [services.DMGT],
      entities: [
        entities.DRIVERMANAGEMENTSCHEDULE,
        entities.DRIVERMANAGEMENTSCHEDULE_AUDIT,
        entities.DRIVERMANAGEMENTSCHEDULE_CREATE,
        entities.DRIVERMANAGEMENTSCHEDULE_DESTROY,
        entities.DRIVERMANAGEMENTSCHEDULE_UPDATE,
        entities.DRIVERMANAGEMENTSCHEDULE_VIEW
      ]
    },
    {
      type: ALERT_TYPES.DRIVER_AND_VEHICLE,
      permission: driverAndVehiclePermission && canAccessNonCameraFeatures,
      services: [services.COMMON, services.IAP, services.SENTINEL4]
    },
    {
      type: ALERT_TYPES.ELD,
      permission: eldPermission,
      services: [services.ELD]
    },
    {
      type: ALERT_TYPES.SMARTJOBS,
      permission: smartJobsPermission,
      services: [services.SMARTJOBS]
    },
    {
      type: ALERT_TYPES.OOH,
      permission: oohPermissions && canHideAlert && canAccessNonCameraFeatures,
      services: [services.TRACKING],
      featureHide: companyFeatures.HTF
    },
    {
      type: ALERT_TYPES.MASS_MANAGEMENT,
      permission: massManagementPermission && canAccessNonCameraFeatures,
      services: [services.MASS]
    },
    {
      type: ALERT_TYPES.METER,
      permission: meterPermission && canAccessNonCameraFeatures,
      services: [services.TRACKING, services.TEMPERATURE]
    },
    {
      type: ALERT_TYPES.GPIO,
      permission: gpioPermissions && canHideAlert && canAccessNonCameraFeatures,
      services: [services.GPIO],
      featureHide: companyFeatures.HTF
    },
    {
      type: ALERT_TYPES.DURESS,
      permission: duressPermissions && canHideAlert && canAccessNonCameraFeatures,
      services: [services.TRACKING],
      featureHide: companyFeatures.HTF
    },
    {
      type: ALERT_TYPES.DOCUMENTEXPIRATION,
      permission: documentExpirationPermission,
      services: [services.EASYDOCS]
    },
    {
      type: ALERT_TYPES.TACHO,
      permission: tachoPermisions,
      services: [services.TACHO]
    }
  ];
};
