import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { fetchImageBlobs } from './fetchImageBlobs';
import { InstallationGalleryDocument, InstallationViewDocument } from './DetailsPagePdfDocument';

export const InstallationAuditLogsPhotosPdfGenerator = async (
  attachments,
  localization,
  handleProgress,
  title,
  t,
  viewData
) => {
  try {
    const res = await fetchImageBlobs(attachments, handleProgress);
    const blob = await pdf(
      <InstallationGalleryDocument
        imageBlobs={res}
        localization={localization}
        title={title}
        t={t}
        viewData={viewData}
      />
    ).toBlob();
    saveAs(blob, `${title}.pdf`);
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};

export const InstallationAuditLogsViewPdfGenerator = async (
  title,
  viewData,
  t,
  supportType,
  localization,
  testResults,
  attachments,
  handleProgress
) => {
  try {
    const res = await fetchImageBlobs(attachments, handleProgress);
    const blob = await pdf(
      <InstallationViewDocument
        imageBlobs={res}
        title={title}
        viewData={viewData}
        t={t}
        supportType={supportType}
        localization={localization}
        testResults={testResults}
      />
    ).toBlob();
    saveAs(blob, `${title}.pdf`);
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};
