import React, { useRef, useMemo } from 'react';
import styles from './ReportResult.module.scss';
import { Iframe } from 'components/iframe/Iframe';
import { useParams } from 'react-router';
import { useUser } from 'features/user/userSlice';
import { AuthUtil } from 'features/auth/authUtil';
import { useWalkMeInFrame } from 'features/walkme/walkmeHooks';

function _ReportResult(isNextgen, props) {
  const params = useParams();
  const userInfo = useUser();
  const frameRef = useRef(null);
  useWalkMeInFrame(frameRef);

  const url = isNextgen?.isNextgen
    ? AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
      `&url=${encodeURIComponent('/ng/reports/' + params['id'] + '?source=nextgen')}`
    : AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
      `&url=${encodeURIComponent('/ng/reports/' + params['id'])}`;

  const reportUrl = useMemo(() => url, [params, userInfo]);

  return <Iframe ref={frameRef} src={reportUrl} className={styles.container}></Iframe>;
}

export const ReportResult = React.memo(_ReportResult);
