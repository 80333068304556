import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useAudits } from 'features/audits';
import { useCurrentCompanyId } from 'features/company/companySlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { AuditsTable } from 'components/auditsTable/AuditsTable';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

import { convertAuditPayloadToDisplay } from './helper';
import { parseErrorMessage } from 'utils/strings';
import { FeatureFlag, useCanOneOfFeatureFlags } from 'features/permissions';

export const IQCameraConfigAudit = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const localization = useLocalization();
  const currentCompanyId = useCurrentCompanyId();

  const onFetchError = useCallback(
    error => {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Audits.AuditFetchError', { error: parseErrorMessage(error) })
        })
      );
    },
    [t]
  );

  const audits = useAudits(AUDIT_ENTITY.CAMERA_CONFIGURATION, currentCompanyId, onFetchError);

  const canAddIQEvents = useCanOneOfFeatureFlags([
    FeatureFlag.addIQEvents.flag,
    FeatureFlag.addIQEventsCapitalElectric.flag
  ]);

  const hasAuditKeyPermission = useCallback(
    auditKey => {
      if (!canAddIQEvents) {
        try {
          return !/potentialCrash|Unbuckled-Seat-Belt|UnbuckledSeatBelt|Roll-Over-Detected|RollOver|Traffic-Light-Violated|trafficLight/i.test(
            auditKey
          );
        } catch (error) {}
      }
      return true;
    },
    [canAddIQEvents]
  );

  useEffect(() => {
    dispatch(setPageTitle(t('CompanyConfig.IQCamera.Audit.Title')));
    dispatch(setBackButton(true));
  }, [t, dispatch]);

  const onRenderChangeItemValue = useCallback(
    (displayValue, { changeItem, colKey, changeKey }) => {
      const item = colKey === 'from' ? changeItem[0] : changeItem[1];
      try {
        const parsed = JSON.parse(item);
        if (!Array.isArray(parsed) && Object.entries(parsed).length) {
          return Object.entries(parsed).reduce((acc, [key, value]) => {
            if (hasAuditKeyPermission(key)) {
              const {
                displayName,
                displayValue: converedDisplayValue
              } = convertAuditPayloadToDisplay({
                t,
                localization,
                payloadName: key,
                payloadValue: value
              });
              acc.push(`${displayName} : ${converedDisplayValue}`);
            }
            return acc;
          }, []);
        } else if (typeof parsed === 'boolean') {
          try {
            return convertAuditPayloadToDisplay({
              t,
              localization,
              payloadName: changeKey,
              payloadValue: item
            }).displayValue;
          } catch (error) {
            return displayValue;
          }
        }
      } catch (error) {
        try {
          return convertAuditPayloadToDisplay({
            t,
            localization,
            payloadName: changeKey,
            payloadValue: item
          }).displayValue;
        } catch (error) {
          return displayValue;
        }
      }
      return displayValue;
    },
    [t, localization]
  );

  const onRenderChangeItemKey = useCallback(
    changeKey => {
      try {
        const dutyTypeKey = changeKey?.match(/^configuration.(light|medium|heavy)$/)?.[1];
        if (dutyTypeKey) {
          return t(`CompanyConfig.IQCamera.${dutyTypeKey}`);
        }
        return convertAuditPayloadToDisplay({ payloadName: changeKey, t, localization })
          .displayName;
      } catch (error) {
        return changeKey;
      }
    },
    [t, localization]
  );

  return (
    <AuditsTable
      audits={audits}
      entity={AUDIT_ENTITY.CAMERA_CONFIGURATION}
      hasAuditKeyPermission={hasAuditKeyPermission}
      onRenderChangeItemKey={onRenderChangeItemKey}
      onRenderChangeItemValue={onRenderChangeItemValue}
      hideEmptyChangeItem
    />
  );
};
