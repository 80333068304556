import React, { useEffect, useMemo, useState } from 'react';
import { toLower } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDebounce from 'utils/hooks/useDebounce';
import { Button } from 'antd';
import { setBackButton, setPageTitle, addBreadcrumbs } from 'features/page/pageSlice';
import { useDevices, useFleets } from 'features/fleets/fleetsSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import {
  useGetSpeedAssistConfigurationTemplatesQuery,
  useDeleteSpeedAssistConfigurationTemplateByIdMutation,
  useRestoreSpeedAssistConfigurationTemplateByIdMutation,
  executeRestoreSpeedAssistConfigurationTemplate,
  executeDeleteSpeedAssistConfigurationTemplate,
  useUpdateSpeedAssistConfigurationTemplateMutation,
  executeSetCompanySpeedAssistConfigurationTemplate
} from 'services/nextgen/ngSpeedAssistConfigurationApi';
import { prepareDataForMultiselect } from 'utils/filters';
import { sortStrings, sortNumbers } from 'utils/strings';
import { SortDirection } from 'react-virtualized';

//components
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { TabNavLink } from 'components/nav/NavLinks';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';

import { PATHS } from 'containers/Configuration/CompanyConfig/utils/constants';
import { PATHS as SPEEDASSIST_PATHS } from './constants';
import { templateTypeEnum } from '../components/constants';
import { TemplatesTable } from './TemplatesTable';
import { BUTTON_IDS } from 'utils/globalConstants';
import { CloneSpeedAssistModal } from './CloneSpeedAssistModal';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';

export const SPEEDASSIST = ({ tab = 'all' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fleets = useFleets();
  const allDevices = useDevices();
  const currentCompany = useCurrentCompany();

  const [showCopyModal, setShowCopyModal] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [filterFleets, setFilterFleets] = useState([]);
  const [filterText, setFilterText] = useState('');
  const debouncedSearchText = useDebounce(filterText, 300);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);
  const history = useHistory();
  const [
    restoreSpeedAssistConfigurationTemplateById
  ] = useRestoreSpeedAssistConfigurationTemplateByIdMutation();
  const [
    deleteSpeedAssistConfigurationTemplateById
  ] = useDeleteSpeedAssistConfigurationTemplateByIdMutation();
  const [
    updateSpeedAssistConfigurationTemplate
  ] = useUpdateSpeedAssistConfigurationTemplateMutation();
  const { data, isFetching } = useGetSpeedAssistConfigurationTemplatesQuery(
    { companyId: currentCompany?.id, embed: 'devices' },
    { skip: currentCompany?.id === undefined }
  );
  const deviceMap = allDevices.reduce((map, device) => {
    map[device.id] = device;
    return map;
  }, {});

  const handleSort = ({ sortBy: vSortBy }) => {
    if (sortBy === vSortBy) {
      if (sortDirection === SortDirection.ASC) {
        setSortDirection(SortDirection.DESC);
      } else if (sortDirection === SortDirection.DESC) {
        setSortDirection(null);
      } else {
        setSortDirection(SortDirection.ASC);
      }
    } else {
      setSortBy(vSortBy);
      setSortDirection(SortDirection.ASC);
    }
  };

  useEffect(() => {
    dispatch(setPageTitle(t('CompanyConfig.DeviceConfigurations.SPEEDASSISTConfig.Title')));
    dispatch(setBackButton(false));

    dispatch(
      addBreadcrumbs([
        {
          breadcrumbName: t('CompanyConfig.Title'),
          path: PATHS.COMPANY_CONFIG
        },
        {
          breadcrumbName: t('CompanyConfig.DeviceConfigurations.Title'),
          path: PATHS.DEVICE_CONFIG_LINKS
        },
        {}
      ])
    );

    return () => {
      dispatch(addBreadcrumbs([]));
    };
  }, [dispatch, t]);

  useEffect(() => {
    const fleetsOptions = fleets.filter(
      fleet => fleet.id && fleet.company && currentCompany.id === fleet.company.id
    );
    fleetsOptions.push({ id: -1, name: t('Common.NoFleet') });
    setFilterFleets(prepareDataForMultiselect(fleetsOptions, t('Common.AllFleets')));
  }, [fleets, history, t]);

  const allTemplates = useMemo(() => {
    const configurationTemplates = (data || []).map(i => {
      const template = i.configurationTemplate;
      let devices = 0;
      const fleets = new Set();
      let vehicles = 0;
      if (i.associatedDevices?.length > 0) {
        const uniqueVehicles = new Set();
        i.associatedDevices.forEach(d => {
          const device = deviceMap[d.id];
          if (device && device.status === 'ENABLED') {
            devices++;
            const vehicle = device.vehicle;
            if (vehicle) {
              uniqueVehicles.add(vehicle.id);
            }
            if (device.fleetInfo?.length > 0) {
              device.fleetInfo.forEach(f => {
                if (f && f.name) {
                  fleets.add(f.id);
                }
              });
            }
          }
        });
        vehicles = uniqueVehicles.size;
      }
      return { ...template, associations: { devices, vehicles, fleets } };
    });
    return configurationTemplates;
  }, [currentCompany?.id, data, isFetching, deviceMap]);

  const [templates, setTemplates] = useState(allTemplates);

  useEffect(() => {
    let result = allTemplates;
    let checkedFleetsIds = filterFleets
      .filter(fleet => fleet.checked)
      .map(fleet => parseInt(fleet.id, 10));
    if (checkedFleetsIds.length <= 0) {
      setTemplates([]);
      return;
    }
    result = result.filter(t =>
      tab === 'deleted' ? t.status === 'DISABLED' : t.status === 'ENABLED'
    );
    if (debouncedSearchText) {
      result = result.filter(
        t =>
          toLower(t.name).indexOf(toLower(debouncedSearchText)) > -1 ||
          (t.configurations &&
            t.configurations.some(
              config => toLower(config.value).indexOf(toLower(debouncedSearchText)) > -1
            ))
      );
    }
    if (!(checkedFleetsIds.indexOf(-1) > -1)) {
      result = result.filter(t =>
        checkedFleetsIds.some(
          e => t.associations?.fleets?.size > 0 && t.associations?.fleets?.has(e)
        )
      );
    }
    const getConfigValue = (configurations, key) => {
      const config = configurations.find(config => config.key === key);
      return config ? config.value : '';
    };

    result = result.sort((a, b) => {
      let compare = 0;
      if (sortDirection) {
        if (sortBy === 'name') {
          compare = sortStrings(a?.name, b?.name);
        } else if (sortBy === 'maxspeedthreshold') {
          const maxSpeedThresholdA = getConfigValue(a.configurations, 'company.max.threshold');
          const maxSpeedThresholdB = getConfigValue(b.configurations, 'company.max.threshold');
          compare = sortNumbers(Number(maxSpeedThresholdA), Number(maxSpeedThresholdB));
        } else if (sortBy === 'maxoffset') {
          const maxOffsetA = getConfigValue(a.configurations, 'company.max.offset');
          const maxOffsetB = getConfigValue(b.configurations, 'company.max.offset');
          compare = sortNumbers(Number(maxOffsetA), Number(maxOffsetB));
        } else if (sortBy === 'maxduration') {
          const maxDurationA = getConfigValue(a.configurations, 'company.max.duration');
          const maxDurationB = getConfigValue(b.configurations, 'company.max.duration');
          compare = sortNumbers(Number(maxDurationA), Number(maxDurationB));
        } else if (sortBy === 'signposteddisable') {
          const signPostedDisableA = getConfigValue(a.configurations, 'signposted.disable');
          const signPostedDisableB = getConfigValue(b.configurations, 'signposted.disable');
          compare = sortStrings(signPostedDisableA, signPostedDisableB);
        } else if (sortBy === 'companymanaged') {
          const companyManagedA = getConfigValue(a.configurations, 'company.managed');
          const companyManagedB = getConfigValue(b.configurations, 'company.managed');
          compare = sortStrings(companyManagedA, companyManagedB);
        }
        if (sortDirection === SortDirection.DESC) {
          compare = compare * -1;
        }
      }
      return compare;
    });
    setTemplates(result);
  }, [filterFleets, debouncedSearchText, sortBy, sortDirection, data, tab]);

  const handleAction = actionObject => {
    if (actionObject.action === 'copy') {
      setShowCopyModal(true);
      setSelectedTemplateId(actionObject.data.id);
    } else if (actionObject.action === 'delete') {
      confirmationModal(
        `${t('Common.DeleteButton')} ${actionObject.data?.name}`,
        `${
          actionObject.data.default
            ? t('CompanyConfig.DeviceConfigurations.DeleteCompanyTemplateWarning', {
                templateName: 'Speed Assist'
              })
            : t('Common.SureDelete') + ' ' + actionObject.data?.name + '?'
        }`,
        t('Common.DeleteButton'),
        t('Common.CancelButton'),
        () => {
          executeDeleteSpeedAssistConfigurationTemplate(
            { id: actionObject.data.id, name: actionObject.data?.name },
            deleteSpeedAssistConfigurationTemplateById,
            dispatch
          );
        },
        'delete'
      );
    } else if (actionObject.action === 'restore') {
      executeRestoreSpeedAssistConfigurationTemplate(
        { id: actionObject.data.id, name: actionObject.data?.name },
        restoreSpeedAssistConfigurationTemplateById,
        dispatch
      );
    } else if (actionObject.action === 'setCompanyTemplate') {
      const configurationTemplateRequest = {
        configurationTemplate: {
          default: true,
          id: actionObject.data.id,
          company: {
            id: currentCompany.id
          }
        }
      };
      executeSetCompanySpeedAssistConfigurationTemplate(
        { body: configurationTemplateRequest, id: actionObject.data.id },
        updateSpeedAssistConfigurationTemplate,
        dispatch
      );
    }
  };

  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div>
            <TabNavLink exact to={SPEEDASSIST_PATHS.SPEEDASSIST_DEFAULT}>
              {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AllTemplate')}
            </TabNavLink>
            <TabNavLink exact to={SPEEDASSIST_PATHS.SPEEDASSIST_CONFIGURED}>
              {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Configured')}
            </TabNavLink>
            <TabNavLink exact to={SPEEDASSIST_PATHS.SPEEDASSIST_DELETED}>
              {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Deleted')}
            </TabNavLink>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                history.push(SPEEDASSIST_PATHS.SPEEDASSIST_ADD);
              }}
              id={BUTTON_IDS.speedAssistAddTemplate}
              style={{ margin: '0 10px 0 0' }}
            >
              {t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.AddTemplate')}
            </Button>
          </div>
        </HeaderPageWrapper>
        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={value => setFilterText(value)} />
            <AntMultiselect
              title={
                filterFleets?.some(value => !value.checked)
                  ? t('Common.Fleets')
                  : t('Common.AllFleets')
              }
              data={filterFleets}
              onFilter={v => setFilterFleets(v)}
            />
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {templates.length}{' '}
            {templates.length === 1
              ? `${t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Template')}`
              : `${t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Templates')}`}
          </label>
        </div>
        <div style={{ flex: '1 0 0' }}>
          <TemplatesTable
            templates={templates}
            isFetching={isFetching}
            filterTab={tab}
            sortBy={sortBy}
            sortDirection={sortDirection}
            handleSort={handleSort}
            handleAction={handleAction}
            templateType={templateTypeEnum.SpeedAssist}
          />
        </div>
      </ContainerPageWrapper>
      <CloneSpeedAssistModal
        showModal={showCopyModal}
        onCancel={() => {
          setShowCopyModal(false);
        }}
        selectedTemplateId={selectedTemplateId}
        onOk={() => {
          setShowCopyModal(false);
        }}
      />
    </>
  );
};
