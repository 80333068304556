import { deleteAlertById, fetchAlerts } from 'features/company_alerts';
import { ALERT_TYPES, ALERT_LINKS } from 'containers/Configuration/CompanyAlerts/constants';
import { insertIf } from 'utils/methods';
import { ENTITIES } from '../constants';
import i18n from 'i18nextConfig';
import { MPTrackingEvents } from 'features/mixpanel';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { deleteMeterThresholdById } from 'features/company_alerts/meter_alert';

export const getLinkFromType = type => {
  switch (type) {
    case ALERT_TYPES.CAMERA:
      return ALERT_LINKS.CAMERA;
    case ALERT_TYPES.ENGINE:
      return ALERT_LINKS.HARSHDRIVING;
    case ALERT_TYPES.FORM:
      return ALERT_LINKS.FORM;
    case ALERT_TYPES.GEOFENCE:
      return ALERT_LINKS.GEOFENCE;
    case ALERT_TYPES.GEOFENCE_EXPIRY:
      return ALERT_LINKS.GEOFENCE_EXPIRY;
    case ALERT_TYPES.PRETRIP:
      return ALERT_LINKS.PRETRIP;
    case ALERT_TYPES.ROUTE:
      return ALERT_LINKS.ROUTE;
    case ALERT_TYPES.SENTINEL:
      return ALERT_LINKS.SENTINEL;
    case ALERT_TYPES.SPEED:
      return ALERT_LINKS.SPEED;
    case ALERT_TYPES.VEHICLE_MAINTENANCE:
      return ALERT_LINKS.VEHICLE_MAINTENANCE;
    case ALERT_TYPES.DRIVER_MANAGEMENT:
      return ALERT_LINKS.DRIVER_MANAGEMENT;
    case ALERT_TYPES.DRIVER_AND_VEHICLE:
      return ALERT_LINKS.DRIVER_AND_VEHICLE;
    case ALERT_TYPES.SMARTJOBS:
      return ALERT_LINKS.SMARTJOBS;
    case ALERT_TYPES.DOCUMENTEXPIRATION:
      return ALERT_LINKS.DOCUMENTEXPIRATION;
    case ALERT_TYPES.OOH:
      return ALERT_LINKS.OOH;
    case ALERT_TYPES.ELD:
      return ALERT_LINKS.ELD;
    case ALERT_TYPES.MASS_MANAGEMENT:
      return ALERT_LINKS.MASS_MANAGEMENT;
    case ALERT_TYPES.METER:
      return ALERT_LINKS.METER;
    case ALERT_TYPES.GPIO:
      return ALERT_LINKS.GPIO;
    case ALERT_TYPES.DURESS:
      return ALERT_LINKS.DURESS;
    case ALERT_TYPES.TACHO:
      return ALERT_LINKS.TACHO;
    default:
      return '';
  }
};

const getDeleteItemOption = (actions, id, alertState, name) => ({
  id: 'btn_alertDelete',
  name: i18n.t('Alerts.DeleteAlert'),
  danger: true,
  onClick: () => {
    confirmationModal(
      `${i18n.t('Common.DeleteButton')} ${name}`,
      `${i18n.t('Common.SureDelete')} ${i18n.t('Common.alert')} ${name}?`,
      i18n.t('Common.DeleteButton'),
      i18n.t('Common.CancelButton'),
      async () => {
        MPTrackingEvents.Settings.Alerts.sendAlerstUpdateEvent(alertState, 'delete');
        await actions.dispatch(
          deleteAlertById({
            id,
            query: {
              entity_type: 'Ng::Company',
              entity_id: actions.companyId
            }
          })
        );
        if (alertState.type === ALERT_TYPES.METER) {
          await actions.dispatch(
            deleteMeterThresholdById({
              query: {
                id: alertState.meterThresholdId,
                entity_type: 'Ng::Company',
                entity_id: actions.companyId
              }
            })
          );
        }
        await actions.dispatch(
          fetchAlerts({
            query: {
              entity_type: 'Ng::Company',
              entity_id: actions.companyId
            }
          })
        );
        actions.dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('Alerts.Toast.AlertDeleted', {
              name
            })
          })
        );
      },
      'delete'
    );
  }
});

const getEditItemOption = (entityType, alertState, alertLink) => ({
  id: 'btn_alertEdit',
  name: i18n.t('Alerts.EditAlert'),
  link:
    entityType === ENTITIES.USER
      ? `/settings/user/alerts/edit/${getLinkFromType(alertState.type)}`
      : `/settings/alerts/edit/${alertLink}/${alertState.id}`,
  state: JSON.stringify(alertState)
});

const getCopyItemOption = alertState => ({
  id: 'btn_alertCopy',
  name: i18n.t('Alerts.CreateCopy'),
  link: `/settings/alerts/new/${getLinkFromType(alertState.type)}/clone/${alertState.id}`,
  state: JSON.stringify(alertState)
});

export const getAlertMoreMenuItems = (row, entityType, can) => {
  const { actions, link, ...alertState } = row;
  return [
    ...insertIf(can.edit, getEditItemOption(entityType, alertState, link)),
    ...insertIf(entityType === ENTITIES.COMPANY && can.copy, getCopyItemOption(alertState)),
    ...insertIf(
      entityType === ENTITIES.COMPANY && can.delete,
      getDeleteItemOption(actions, row.id, alertState, row.name)
    )
  ];
};
