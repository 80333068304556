import ignitionOnSvg from 'components/map/markers/images/ignition-on.svg';
import ignitionOffSvg from 'components/map/markers/images/ignition-off.svg';
import imgGeofenceEntry from 'components/map/markers/images/event-geofence-entry.png';
import imgGeofenceExit from 'components/map/markers/images/event-geofence-exit.png';
import imgGeofenceUndertime from 'components/map/markers/images/event-geofence-undertime.png';
import imgGeofenceOvertime from 'components/map/markers/images/event-geofence-overtime.png';
import imgGeofenceSpeeding from 'components/map/markers/images/event-geofence-speeding.png';
import imgIAPDeclaration from 'components/map/markers/images/event-iap-declaration.png';
import imgMassDeclaration from 'components/map/markers/images/event-mass-mgmt-declaration.png';
import imgFormCompleted from 'components/map/markers/images/event-completed-form.png';
import imgPTCCompleted from 'components/map/markers/images/event-completed-ptc.png';
import imgDriverLogOn from 'components/map/markers/images/event-log-in.png';
import imgDriverLogOff from 'components/map/markers/images/event-log-out.png';
import imgDriverIncorrectLogon from 'components/map/markers/images/event-log-in-error.png';
import imgDriverRest from 'components/map/markers/images/event-start-rest.png';
import imgDriverWork from 'components/map/markers/images/event-start-work.png';
import imgCamera from 'components/map/markers/images/event-camera.png';
import imgAlarmDrivingDuringRest from 'components/map/markers/images/event-driving-while-rest.png';
import imgAlarmDuress from 'components/map/markers/images/event-duress.png';
import DriverUnknown from 'components/map/markers/images/event-driver-unknown.png';
import imgAlarmHandBrakeOn from 'components/map/markers/images/event-hand-brake-on.png';
import imgAlarmHandBrakeOff from 'components/map/markers/images/event-hand-brake-off.png';
import imgAlarmMoving from 'components/map/markers/images/event-no-driver-while-moving.png';
import imgAlarmNoSeatbelt from 'components/map/markers/images/event-no-seatbelt.png';
import imgAlarmSweeperOn from 'components/map/markers/images/event-sweeper-on.png';
import imgAlarmSweeperOff from 'components/map/markers/images/event-sweeper-off.png';
import imgAlarmRollOver from 'components/map/markers/images/event-turn-over.png';
import imgAlarmExternalPowerConnected from 'components/map/markers/images/event-external-power-conected.png';
import imgAlarmExternalPowerDisconnected from 'components/map/markers/images/event-external-power-disconected.png';
import imgAlertEngine from 'components/map/markers/images/event-engine-alerts.png';
import imgAlertRoute from 'components/map/markers/images/event-route-compliance-alert.png';
import imgVPMExcessiveIdle from 'components/map/markers/images/event-excessive-idle.png';
import imgVPMHarshAcceleration from 'components/map/markers/images/event-harsh-acceleration.png';
import imgVPMHarshBraking from 'components/map/markers/images/event-harsh-braking.png';
import imgVPMHarshCornering from 'components/map/markers/images/event-harsh-cornering.png';
import imgVPMOverRevving from 'components/map/markers/images/event-over-revving.png';
import vehicleStarted from 'components/map/markers/images/vehicle-started.png';
import vehicleStopped from 'components/map/markers/images/vehicle-stopped.png';
import imgFatigueBreach from 'components/map/markers/images/event-fatigue-breach.png';
import imgPTOOn from 'components/map/markers/images/event-power-take-off-on.png';
import imgPTOOff from 'components/map/markers/images/event-power-take-off-off.png';
import imgMixerStarted from 'components/map/markers/images/event-mixer-started.png';
import imgMixerStopped from 'components/map/markers/images/event-mixer-stopped.png';
import imgSeatbeltFastened from 'components/map/markers/images/event-seatbelt-fastened.png';
import imgSeatbeltUnfastened from 'components/map/markers/images/event-seatbelt-unfastened.png';
import imgSeatbeltUnknown from 'components/map/markers/images/event-seatbelt-unknown.png';
import imgInputOn from 'components/map/markers/images/event-input-on.png';
import imgInputOff from 'components/map/markers/images/event-input-off.png';
import imgDoorOpened from 'components/map/markers/images/event-doors-opened.png';
import imgDoorClosed from 'components/map/markers/images/event-doors-closed.png';
import imgSpeed from 'components/map/markers/images/event-speeding-start.png';
import imgARTowAway from 'components/map/markers/images/event-ar-tow-away.png';
import imgImpact from 'components/map/markers/images/event-impact.png';
import imgPosition from 'components/map/markers/images/event-position.png';
import imgCommunication from 'components/map/markers/images/event-communication.png';
import imgUndefinedEvent from 'components/map/markers/images/grey.png';

import { TrackingLens, TrackingRouteLens } from './constants';
import { FeatureFlag, useCan } from 'features/permissions';
import { useMemo } from 'react';
import { uniqBy, sortBy } from 'lodash';

export const EventAttributes = {
  NAMES: {
    duration: 'duration',
    currentGear: 'currentGear',
    maxSpeed: 'maxSpeed',
    acceleration: 'acceleration',
    deceleration: 'deceleration',
    driver: 'driver',
    vehicle_type: 'vehicle_type',
    temperature: 'temperature',
    axle: 'axle',
    tcm: 'tcm',
    speed: 'speed'
  },
  UNITS: {
    seconds: 's',
    msseconds: 'm/s²',
    kmhour: 'km/h',
    gear: 'gear',
    temperature: '˚C',
    ton: 'tonnes'
  },
  LABELS: {
    Speed: 'Speed'
  }
};

const UndefinedEventType = {
  key: 'UNDEFINED_EVENT',
  label: 'Undefined Event',
  marker: 'grey.png',
  markerSvg: imgUndefinedEvent,
  lens: TrackingLens().Track
};

export const EventTypes = {
  // IOR Events
  IgnitionOn: {
    key: 'IORON',
    apiKey: 'IOR',
    label: 'Ignition On',
    marker: 'ignition-on.svg',
    markerSvg: ignitionOnSvg,
    lens: TrackingLens().Track
  },
  IgnitionOff: {
    key: 'IOROFF',
    apiKey: 'IOR',
    label: 'Ignition Off',
    marker: 'ignition-off.svg',
    markerSvg: ignitionOffSvg,
    lens: TrackingLens().Track
  },

  // POSITION
  PositionUpdate: {
    key: 'POSITIONUPDATE',
    apiKey: 'POSITION',
    label: 'Position',
    marker: 'event-position.png',
    markerSvg: imgPosition,
    lens: TrackingLens().Trips
    //url: '/events/:id:/position?time_at=:timeAt:' // Not implemented yet in NextGen UI
  },

  // GEOFENCE Events
  GeofenceEntry: {
    key: 'GEOFENCEEN',
    apiKey: 'GEOFENCE',
    label: 'Geofence Entry',
    marker: 'event-geofence-entry.png',
    markerSvg: imgGeofenceEntry,
    lens: TrackingLens().Trips,
    url: '/events/:id:/geofence?time_at=:timeAt:'
  },
  GeofenceExit: {
    key: 'GEOFENCEEX',
    apiKey: 'GEOFENCE',
    label: 'Geofence Exit',
    marker: 'event-geofence-exit.png',
    markerSvg: imgGeofenceExit,
    lens: TrackingLens().Trips,
    url: '/events/:id:/geofence?time_at=:timeAt:',
    attributes: [
      {
        label: 'Duration',
        name: EventAttributes.NAMES.duration,
        unit: EventAttributes.UNITS.seconds
      }
    ]
  },
  GeofenceUndertime: {
    key: 'GEOFENCEUT',
    apiKey: 'GEOFENCE',
    label: 'Geofence Undertime',
    marker: 'event-geofence-undertime.png',
    markerSvg: imgGeofenceUndertime,
    lens: TrackingLens().Trips,
    url: '/events/:id:/geofence?time_at=:timeAt:',
    attributes: [
      {
        label: 'Duration',
        name: EventAttributes.NAMES.duration,
        unit: EventAttributes.UNITS.seconds
      }
    ]
  },
  GeofenceOvertime: {
    key: 'GEOFENCEOT',
    apiKey: 'GEOFENCE',
    label: 'Geofence Overtime',
    marker: 'event-geofence-overtime.png',
    markerSvg: imgGeofenceOvertime,
    lens: TrackingLens().Trips,
    url: '/events/:id:/geofence?time_at=:timeAt:',
    attributes: [
      {
        label: 'Duration',
        name: EventAttributes.NAMES.duration,
        unit: EventAttributes.UNITS.seconds
      }
    ]
  },
  GeofenceSpeeding: {
    key: 'GEOFENCESE',
    apiKey: 'GEOFENCE',
    label: 'Geofence Speeding',
    marker: 'event-geofence-speeding.png',
    markerSvg: imgGeofenceSpeeding,
    lens: TrackingLens().Trips,
    url: '/events/:id:/speed?time_at=:timeAt:'
  },

  // GPIO Events
  AlarmHandBrakeOn: {
    key: 'GPIOparkbrake-engaged',
    apiKey: 'GPIO',
    label: 'Handbrake On',
    marker: 'event-hand-brake-on.png',
    markerSvg: imgAlarmHandBrakeOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  AlarmHandBrakeOff: {
    key: 'GPIOparkbrake-disengaged',
    apiKey: 'GPIO',
    label: 'Handbrake Off',
    marker: 'event-hand-brake-off.png',
    markerSvg: imgAlarmHandBrakeOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  ParkBrakeOn: {
    key: 'GPIOparkbrake-on',
    apiKey: 'GPIO',
    label: 'Parkbrake On',
    marker: 'event-hand-brake-on.png',
    markerSvg: imgAlarmHandBrakeOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  ParkBrakeOff: {
    key: 'GPIOparkbrake-off',
    apiKey: 'GPIO',
    label: 'Parkbrake Off',
    marker: 'event-hand-brake-off.png',
    markerSvg: imgAlarmHandBrakeOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  DuressEnd: {
    key: 'GPIOduress-off',
    apiKey: 'GPIO',
    label: 'Duress Off',
    marker: 'event-duress.png',
    markerSvg: imgAlarmDuress,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  DuressStarted: {
    key: 'GPIOduress-on',
    apiKey: 'GPIO',
    label: 'Duress On',
    marker: 'event-duress.png',
    markerSvg: imgAlarmDuress,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  DuressUnknown: {
    key: 'GPIOduress-unknown',
    apiKey: 'GPIO',
    label: 'Duress Unknown',
    marker: 'event-duress.png',
    markerSvg: imgAlarmDuress,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  Input1Off: {
    key: 'GPIOinput1-off',
    apiKey: 'GPIO',
    label: 'Input 1 off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  Input1On: {
    key: 'GPIOinput1-on',
    apiKey: 'GPIO',
    label: 'Input 1 on',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  Input2Off: {
    key: 'GPIOinput2-off',
    apiKey: 'GPIO',
    label: 'Input 2 off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  Input2On: {
    key: 'GPIOinput2-on',
    apiKey: 'GPIO',
    label: 'Input 2 on',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  Input3Off: {
    key: 'GPIOinput3-off',
    apiKey: 'GPIO',
    label: 'Input 3 off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  Input3On: {
    key: 'GPIOinput3-on',
    apiKey: 'GPIO',
    label: 'Input 3 on',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  Input4Off: {
    key: 'GPIOinput4-off',
    apiKey: 'GPIO',
    label: 'Input 4 off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  Input4On: {
    key: 'GPIOinput4-on',
    apiKey: 'GPIO',
    label: 'Input 4 on',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  LightsOff: {
    key: 'GPIOlights-off',
    apiKey: 'GPIO',
    label: 'Lights Off',
    marker: 'event-lights-off.png',
    markerSvg: imgAlarmDuress,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  LightsOn: {
    key: 'GPIOlights-on',
    apiKey: 'GPIO',
    label: 'Lights On',
    marker: 'event-lights-on.png',
    markerSvg: imgAlarmDuress,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  MixerStarted: {
    key: 'GPIOmixer-started',
    apiKey: 'GPIO',
    label: 'Mixer Started',
    marker: 'event-mixer-started.png',
    markerSvg: imgMixerStarted,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  MixerStopped: {
    key: 'GPIOmixer-stopped',
    apiKey: 'GPIO',
    label: 'Mixer Stopped',
    marker: 'event-mixer-stopped.png',
    markerSvg: imgMixerStopped,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  PourerStarted: {
    key: 'GPIOpourer-started',
    apiKey: 'GPIO',
    label: 'Pourer Started',
    marker: 'event-mixer-started.png',
    markerSvg: imgMixerStarted,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  PourerStopped: {
    key: 'GPIOpourer-stopped',
    apiKey: 'GPIO',
    label: 'Pourer Stopped',
    marker: 'event-mixer-stopped.png',
    markerSvg: imgMixerStopped,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  SeatbeltFastened: {
    key: 'GPIOseatbelt-fastened',
    apiKey: 'GPIO',
    label: 'Seatbelt Fastened',
    marker: 'event-seatbelt-fastened.png',
    markerSvg: imgSeatbeltFastened,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  SeatbeltUnfastened: {
    key: 'GPIOseatbelt-unfastened',
    apiKey: 'GPIO',
    label: 'Seatbelt Unfastened',
    marker: 'event-seatbelt-unfastened.png',
    markerSvg: imgSeatbeltUnfastened,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  SeatbeltUnknown: {
    key: 'GPIOseatbelt-unknown',
    apiKey: 'GPIO',
    label: 'Seatbelt Unknown',
    marker: 'event-seatbelt-unknown.png',
    markerSvg: imgSeatbeltUnknown,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  DriverSeatbeltFastened: {
    key: 'GPIOdriver-seatbelt-fastened',
    apiKey: 'GPIO',
    label: 'Driver Seatbelt Fastened',
    marker: 'event-seatbelt-fastened.png',
    markerSvg: imgSeatbeltFastened,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  DriverSeatbeltUnfastened: {
    key: 'GPIOdriver-seatbelt-unfastened',
    apiKey: 'GPIO',
    label: 'Driver Seatbelt Unfastened',
    marker: 'event-seatbelt-unfastened.png',
    markerSvg: imgSeatbeltUnfastened,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  DriverSeatbeltUnknown: {
    key: 'GPIOdriver-seatbelt-unknown',
    apiKey: 'GPIO',
    label: 'Driver Seatbelt Unknown',
    marker: 'event-seatbelt-unknown.png',
    markerSvg: imgSeatbeltUnknown,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  PassengerSeatbeltFastened: {
    key: 'GPIOpassenger-seatbelt-fastened',
    apiKey: 'GPIO',
    label: 'Passenger Seatbelt Fastened',
    marker: 'event-seatbelt-fastened.png',
    markerSvg: imgSeatbeltFastened,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  PassengerSeatbeltUnfastened: {
    key: 'GPIOpassenger-seatbelt-unfastened',
    apiKey: 'GPIO',
    label: 'Passenger Seatbelt Unfastened',
    marker: 'event-seatbelt-unfastened.png',
    markerSvg: imgSeatbeltUnfastened,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  PassengerSeatbeltUnknown: {
    key: 'GPIOpassenger-seatbelt-unknown',
    apiKey: 'GPIO',
    label: 'Passenger Seatbelt Unknown',
    marker: 'event-seatbelt-unknown.png',
    markerSvg: imgSeatbeltUnknown,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  RearSeatbeltFastened: {
    key: 'GPIOreat-seatbelt-fastened',
    apiKey: 'GPIO',
    label: 'Rear Seatbelt Fastened',
    marker: 'event-seatbelt-fastened.png',
    markerSvg: imgSeatbeltFastened,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  RearSeatbeltUnfastened: {
    key: 'GPIOrear-seatbelt-unfastened',
    apiKey: 'GPIO',
    label: 'Rear Seatbelt Unfastened',
    marker: 'event-seatbelt-unfastened.png',
    markerSvg: imgSeatbeltUnfastened,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  RearSeatbeltUnknown: {
    key: 'GPIOrear-seatbelt-unknown',
    apiKey: 'GPIO',
    label: 'Rear Seatbelt Unknown',
    marker: 'event-seatbelt-unknown.png',
    markerSvg: imgSeatbeltUnknown,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  DriverDoorOpened: {
    key: 'GPIOdriver-door-opened',
    apiKey: 'GPIO',
    label: 'Driver Door Opened',
    marker: 'event-doors-opened.png',
    markerSvg: imgDoorOpened,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  DriverDoorClosed: {
    key: 'GPIOdriver-door-closed',
    apiKey: 'GPIO',
    label: 'Driver Door Closed',
    marker: 'event-doors-closed.png',
    markerSvg: imgDoorClosed,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  DoorOpened: {
    key: 'GPIOdoor-opened',
    apiKey: 'GPIO',
    label: 'Door Opened',
    marker: 'event-doors-opened.png',
    markerSvg: imgDoorOpened,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  DoorClosed: {
    key: 'GPIOdoor-closed',
    apiKey: 'GPIO',
    label: 'Door Closed',
    marker: 'event-doors-closed.png',
    markerSvg: imgDoorClosed,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  PassengerDoorOpened: {
    key: 'GPIOpassenger-door-opened',
    apiKey: 'GPIO',
    label: 'Passenger Door Opened',
    marker: 'event-doors-opened.png',
    markerSvg: imgDoorOpened,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  PassengerDoorClosed: {
    key: 'GPIOpassenger-door-closed',
    apiKey: 'GPIO',
    label: 'Passenger Door Closed',
    marker: 'event-doors-closed.png',
    markerSvg: imgDoorClosed,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  RearDoorOpened: {
    key: 'GPIOrear-door-opened',
    apiKey: 'GPIO',
    label: 'Rear Door Opened',
    marker: 'event-doors-opened.png',
    markerSvg: imgDoorOpened,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  RearDoorClosed: {
    key: 'GPIOrear-door-closed',
    apiKey: 'GPIO',
    label: 'Rear Door Closed',
    marker: 'event-doors-closed.png',
    markerSvg: imgDoorClosed,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  FourwdOff: {
    key: 'GPIO4wd-off',
    apiKey: 'GPIO',
    label: '4WD Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  FourwdOn: {
    key: 'GPIO4wd-on',
    apiKey: 'GPIO',
    label: '4WD On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  BeaconOff: {
    key: 'GPIObeacon-off',
    apiKey: 'GPIO',
    label: 'Beacon Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  BeaconOn: {
    key: 'GPIObeacon-on',
    apiKey: 'GPIO',
    label: 'Beacon On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  SirenOn: {
    key: 'GPIOsiren-on',
    apiKey: 'GPIO',
    label: 'Siren On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  SirenOff: {
    key: 'GPIOsiren-off',
    apiKey: 'GPIO',
    label: 'Siren Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  FanOff: {
    key: 'GPIOfan-off',
    apiKey: 'GPIO',
    label: 'Fan Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  FanOn: {
    key: 'GPIOfan-on',
    apiKey: 'GPIO',
    label: 'Fan On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  ReverseStopped: {
    key: 'GPIOreverse-stopped',
    apiKey: 'GPIO',
    label: 'Reverse Stopped',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  ReverseStarted: {
    key: 'GPIOreverse-started',
    apiKey: 'GPIO',
    label: 'Reverse Started',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  SweeperOff: {
    key: 'GPIOsweeper-off',
    apiKey: 'GPIO',
    label: 'Sweeper Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  SweeperOn: {
    key: 'GPIOsweeper-on',
    apiKey: 'GPIO',
    label: 'Sweeper On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  UnnamedOff: {
    key: 'GPIOunnamed-off',
    apiKey: 'GPIO',
    label: 'Unnamed Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  UnnamedOn: {
    key: 'GPIOunnamed-on',
    apiKey: 'GPIO',
    label: 'Unnamed On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  GpioPtoOff: {
    key: 'GPIOpto-off',
    apiKey: 'GPIO',
    label: 'PTO Off',
    marker: 'event-power-take-off-off.png',
    markerSvg: imgPTOOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  GpioPtoOn: {
    key: 'GPIOpto-on',
    apiKey: 'GPIO',
    label: 'PTO On',
    marker: 'event-power-take-off-on.png',
    markerSvg: imgPTOOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  RightTurnIndicatorOff: {
    key: 'GPIOright-turnindicator-off',
    apiKey: 'GPIO',
    label: 'Right Turn Indicator Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  LeftTurnIndicatorOff: {
    key: 'GPIOleft-turnindicator-off',
    apiKey: 'GPIO',
    label: 'Left Turn Indicator Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  RightTurnIndicatorOn: {
    key: 'GPIOright-turnindicator-on',
    apiKey: 'GPIO',
    label: 'Right Turn Indicator On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  LeftTurnIndicatorOn: {
    key: 'GPIOleft-turnindicator-on',
    apiKey: 'GPIO',
    label: 'Left Turn Indicator On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  ImmobiliseOff: {
    key: 'GPIOimmobilise-off',
    apiKey: 'GPIO',
    label: 'Immobilise Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  ImmobiliseOn: {
    key: 'GPIOimmobilise-on',
    apiKey: 'GPIO',
    label: 'Immobilise On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  ImmobilizerOff: {
    key: 'GPIOimmobilizer-off',
    apiKey: 'GPIO',
    label: 'Immobilizer Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  ImmobilizerOn: {
    key: 'GPIOimmobilizer-on',
    apiKey: 'GPIO',
    label: 'Immobilizer On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  JammerDetectorDeactivated: {
    key: 'GPIOjammerdetector-deactivated',
    apiKey: 'GPIO',
    label: 'Jammer Detector Deactivated',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  JammerDetectorActivated: {
    key: 'GPIOjammerdetector-activated',
    apiKey: 'GPIO',
    label: 'Jammer Detector Activated',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  FifthWheelOff: {
    key: 'GPIO5thwheel-off',
    apiKey: 'GPIO',
    label: '5th Wheel Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  FifthWheelOn: {
    key: 'GPIO5thwheel-on',
    apiKey: 'GPIO',
    label: '5th Wheel On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  MechanicalLockUnlocked: {
    key: 'GPIOmechanicallock-unlocked',
    apiKey: 'GPIO',
    label: 'Mechanical Lock Unlocked',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  MechanicalLockLocked: {
    key: 'GPIOmechanicallock-locked',
    apiKey: 'GPIO',
    label: 'Mechanical Lock Locked',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  TurnIndicatorOff: {
    key: 'GPIOturnindicator-off',
    apiKey: 'GPIO',
    label: 'Turn Indicator Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  TurnIndicatorOn: {
    key: 'GPIOturnindicator-on',
    apiKey: 'GPIO',
    label: 'Turn Indicator On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  StarterInterruptBypassEngaged: {
    key: 'GPIOstartintbypass-engaged',
    apiKey: 'GPIO',
    label: 'Starter Interrupt Bypass Engaged',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  StarterInterruptBypassDisengaged: {
    key: 'GPIOstartintbypass-disengaged',
    apiKey: 'GPIO',
    label: 'Starter Interrupt Bypass Disengaged',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  HydrovacTiltDown: {
    key: 'GPIOhydrovac-tilt-down',
    apiKey: 'GPIO',
    label: 'Hydrovac Tilt Down',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  HydrovacTiltUp: {
    key: 'GPIOhydrovac-tilt-up',
    apiKey: 'GPIO',
    label: 'Hydrovac Tilt Up',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  CompactorEngaged: {
    key: 'GPIOcompactor-on',
    apiKey: 'GPIO',
    label: 'Compactor Engaged',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  CompactorDisengaged: {
    key: 'GPIOcompactor-off',
    apiKey: 'GPIO',
    label: 'Compactor Disengaged',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  DrivereventStarted: {
    key: 'GPIOdriverevent-started',
    apiKey: 'GPIO',
    label: 'Driverevent Started',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  DrivereventStopped: {
    key: 'GPIOdriverevent-stopped',
    apiKey: 'GPIO',
    label: 'Driverevent Stopped',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  RightSweeperOn: {
    key: 'GPIOright-sweeper-on',
    apiKey: 'GPIO',
    label: 'Right Sweeper On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  RightSweeperOff: {
    key: 'GPIOright-sweeper-off',
    apiKey: 'GPIO',
    label: 'Right Sweeper Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  LeftSweeperOn: {
    key: 'GPIOleft-sweeper-on',
    apiKey: 'GPIO',
    label: 'Left Sweeper On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  LeftSweeperOff: {
    key: 'GPIOleft-sweeper-off',
    apiKey: 'GPIO',
    label: 'Left Sweeper Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  VacuumOn: {
    key: 'GPIOvacuum-on',
    apiKey: 'GPIO',
    label: 'Vacuum On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  VacuumOff: {
    key: 'GPIOvacuum-off',
    apiKey: 'GPIO',
    label: 'Vacuum Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  BinUp: {
    key: 'GPIObin-up',
    apiKey: 'GPIO',
    label: 'Bin Up',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  BinDown: {
    key: 'GPIObin-down',
    apiKey: 'GPIO',
    label: 'Bin Down',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  GpioDefault: {
    key: 'GPIOdefault',
    apiKey: 'GPIO',
    label: 'GPIO',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  WaterPumpOn: {
    key: 'GPIOwaterpump-on',
    apiKey: 'GPIO',
    label: 'Waterpump On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  WaterPumpOff: {
    key: 'GPIOwaterpump-off',
    apiKey: 'GPIO',
    label: 'Waterpump Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  WaterOn: {
    key: 'GPIOwater-on',
    apiKey: 'GPIO',
    label: 'Water On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  WaterOff: {
    key: 'GPIOwater-off',
    apiKey: 'GPIO',
    label: 'Water Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  LeftWaterOn: {
    key: 'GPIOleft-water-on',
    apiKey: 'GPIO',
    label: 'Left Water On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  LeftWaterOff: {
    key: 'GPIOleft-water-off',
    apiKey: 'GPIO',
    label: 'Left Water Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  RightWaterOn: {
    key: 'GPIOright-water-on',
    apiKey: 'GPIO',
    label: 'Right Water On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  RightWaterOff: {
    key: 'GPIOright-water-off',
    apiKey: 'GPIO',
    label: 'Right Water Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  TemperatureStarted: {
    key: 'GPIOtemperature-started',
    apiKey: 'GPIO',
    label: 'Temperature Started',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  TemperatureStopped: {
    key: 'GPIOtemperature-stopped',
    apiKey: 'GPIO',
    label: 'Temperature Stopped',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  TemperatureAboveStarted: {
    key: 'GPIOabove-temperature-started',
    apiKey: 'GPIO',
    label: 'Temperature Above Started',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  TemperatureAboveStopped: {
    key: 'GPIOabove-temperature-stopped',
    apiKey: 'GPIO',
    label: 'Temperature Above Stopped',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  TemperatureBelowStarted: {
    key: 'GPIObelow-temperature-started',
    apiKey: 'GPIO',
    label: 'Temperature Below Started',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  TemperatureBelowStopped: {
    key: 'GPIObelow-temperature-stopped',
    apiKey: 'GPIO',
    label: 'Temperature Below Stopped',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  AuxiliaryMotorOn: {
    key: 'GPIOauxiliary motor-on',
    apiKey: 'GPIO',
    label: 'Auxiliary Motor On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  AuxiliaryMotorOff: {
    key: 'GPIOauxiliary motor-off',
    apiKey: 'GPIO',
    label: 'Auxiliary Motor Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  NozzleOn: {
    key: 'GPIOnozzle-on',
    apiKey: 'GPIO',
    label: 'Nozzle On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  NozzleOff: {
    key: 'GPIOnozzle-off',
    apiKey: 'GPIO',
    label: 'Nozzle Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  NozzleLeftOn: {
    key: 'GPIOlh-nozzle-on',
    apiKey: 'GPIO',
    label: 'LH Nozzle On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  NozzleLeftOff: {
    key: 'GPIOlh-nozzle-off',
    apiKey: 'GPIO',
    label: 'LH Nozzle Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  NozzleRightOn: {
    key: 'GPIOrh-nozzle-on',
    apiKey: 'GPIO',
    label: 'RH Nozzle On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  NozzleRightOff: {
    key: 'GPIOrh-nozzle-off',
    apiKey: 'GPIO',
    label: 'RH Nozzle Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },

  LedOn: {
    key: 'GPIOled-on',
    apiKey: 'GPIO',
    label: 'Led On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  LedOff: {
    key: 'GPIOled-off',
    apiKey: 'GPIO',
    label: 'Led Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  BrushOn: {
    key: 'GPIObrush-on',
    apiKey: 'GPIO',
    label: 'Brush On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  BrushOff: {
    key: 'GPIObrush-off',
    apiKey: 'GPIO',
    label: 'Brush Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  BrushLeftOn: {
    key: 'GPIOlh-brush-on',
    apiKey: 'GPIO',
    label: 'LH Brush On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  BrushLeftOff: {
    key: 'GPIOlh-brush-off',
    apiKey: 'GPIO',
    label: 'LH Brush Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  BrushRightOn: {
    key: 'GPIOrh-brush-on',
    apiKey: 'GPIO',
    label: 'RH Brush On',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  BrushRightOff: {
    key: 'GPIOrh-brush-off',
    apiKey: 'GPIO',
    label: 'RH Brush Off',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/gpio?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.speed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },
  // ALARM Events
  AlarmMoving: {
    key: 'ALARMMV',
    apiKey: 'ALARM',
    label: 'Moving Without Driver Logged On',
    marker: 'event-no-driver-while-moving.png',
    markerSvg: imgAlarmMoving,
    lens: TrackingLens().Trips,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmSweeperOn: {
    key: 'ALARMSWON',
    apiKey: 'ALARM',
    label: 'Sweeper On',
    marker: 'event-sweeper-on.png',
    markerSvg: imgAlarmSweeperOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmSweeperOff: {
    key: 'ALARMSWOFF',
    apiKey: 'ALARM',
    label: 'Sweeper Off',
    marker: 'event-sweeper-off.png',
    markerSvg: imgAlarmSweeperOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmDriving: {
    key: 'ALARMDR',
    apiKey: 'ALARM',
    label: 'Driving During Rest Break',
    marker: 'event-driving-while-rest.png',
    markerSvg: imgAlarmDrivingDuringRest,
    lens: TrackingLens().Safety,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmRollOver: {
    key: 'ALARMRO',
    apiKey: 'ALARM',
    label: 'Vehicle Roll Over',
    marker: 'event-turn-over.png',
    markerSvg: imgAlarmRollOver,
    lens: TrackingLens().Safety,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmNoSeatbelt: {
    key: 'ALARMSB',
    apiKey: 'ALARM',
    label: 'Seatbelt Off While Driving',
    marker: 'event-no-seatbelt.png',
    markerSvg: imgAlarmNoSeatbelt,
    lens: TrackingLens().Safety,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmDuress: {
    key: 'ALARMSOS',
    apiKey: 'ALARM',
    label: 'Duress',
    marker: 'event-duress.png',
    markerSvg: imgAlarmDuress,
    lens: TrackingLens().Safety,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmDuressOn: {
    key: 'ALARMSOS-on',
    apiKey: 'ALARM',
    label: 'Duress On Alert',
    marker: 'event-duress.png',
    markerSvg: imgAlarmDuress,
    lens: TrackingLens().Safety,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmDuressOff: {
    key: 'ALARMSOS-off',
    apiKey: 'ALARM',
    label: 'Duress Off Alert',
    marker: 'event-duress.png',
    markerSvg: imgAlarmDuress,
    lens: TrackingLens().Safety,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmTowAway: {
    key: 'ALARMTOW',
    apiKey: 'ALARM',
    label: 'TOW',
    marker: 'event-ar-tow-away.png',
    markerSvg: imgARTowAway,
    lens: TrackingLens().Trips,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmPosition: {
    key: 'ALARMPR2',
    apiKey: 'ALARM',
    label: 'Position',
    marker: 'event-position.png',
    markerSvg: imgPosition,
    lens: TrackingLens().Trips
    //url: '/events/:id:/position?time_at=:timeAt:' // Not implemented yet in NextGen UI
  },
  AlarmExternalPowerConnected: {
    key: 'ALARMEPC',
    apiKey: 'ALARM',
    label: 'External Power Connected',
    marker: 'event-external-power-conected.png',
    markerSvg: imgAlarmExternalPowerConnected,
    lens: TrackingLens().Trips,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmExternalPowerDisconnected: {
    key: 'ALARMEPD',
    apiKey: 'ALARM',
    label: 'External Power Disconnected',
    marker: 'event-external-power-disconected.png',
    markerSvg: imgAlarmExternalPowerDisconnected,
    lens: TrackingLens().Trips,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmRecoveryModeOn: {
    key: 'ALARMREC-on',
    apiKey: 'ALARM',
    label: 'Recovery Mode ON',
    marker: 'event-input-on.png',
    markerSvg: imgInputOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmRecoveryModeOff: {
    key: 'ALARMREC-off',
    apiKey: 'ALARM',
    label: 'Recovery Mode OFF',
    marker: 'event-input-off.png',
    markerSvg: imgInputOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmForcedLogoffSuccess: {
    key: 'ALARMFORCED-LOGOFF-SUCCESS',
    apiKey: 'ALARM',
    label: 'Forced Logoff Succesful',
    marker: 'event-log-out.png',
    markerSvg: imgDriverLogOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },
  AlarmForcedLogoffFailed: {
    key: 'ALARMFORCED-LOGOFF-FAILED',
    apiKey: 'DRIVER',
    label: 'Forced Logoff Failed',
    marker: 'event-log-in-error.png',
    markerSvg: imgDriverIncorrectLogon,
    lens: TrackingLens().Trips,
    url: '/events/:id:/alarm?time_at=:timeAt:'
  },

  // VPM Events
  VPMExcessiveIdle: {
    key: 'VPMIT',
    apiKey: 'VPM_IT',
    label: 'Idle',
    marker: 'event-excessive-idle.png',
    markerSvg: imgVPMExcessiveIdle,
    lens: TrackingLens().Trips,
    url: '/events/:id:/vpm_it?time_at=:timeAt:'
  },
  VPMOverRevving: {
    key: 'VPMOR',
    apiKey: 'VPM_OR',
    label: 'Over Revving',
    marker: 'event-over-revving.png',
    markerSvg: imgVPMOverRevving,
    lens: TrackingLens().Trips,
    url: '/events/:id:/vpm_or?time_at=:timeAt:',
    attributes: [
      {
        label: 'Current Gear',
        name: EventAttributes.NAMES.currentGear,
        unit: EventAttributes.UNITS.gear
      },
      {
        label: 'Duration',
        name: EventAttributes.NAMES.duration,
        unit: EventAttributes.UNITS.seconds
      }
    ]
  },
  AlertEngineOilTemp: {
    key: 'VPMEOT',
    apiKey: 'VPM_EOT',
    label: 'Engine Oil Temperature',
    marker: 'event-engine-alerts.png',
    markerSvg: imgAlertEngine,
    lens: TrackingLens().Trips,
    url: '/events/:id:/vpm_eot?time_at=:timeAt:',
    attributes: [
      {
        label: 'Oil Temp',
        name: 'oilTemperature',
        unit: EventAttributes.UNITS.temperature
      }
    ]
  },
  AlertEngineCoolantTemp: {
    key: 'VPMECT',
    apiKey: 'VPM_ECT',
    label: 'Engine Coolant Temperature',
    marker: 'event-engine-alerts.png',
    markerSvg: imgAlertEngine,
    lens: TrackingLens().Trips,
    url: '/events/:id:/vpm_ect?time_at=:timeAt:',
    attributes: [
      {
        label: 'Coolant Temp',
        name: 'coolantTemperature',
        unit: EventAttributes.UNITS.temperature
      }
    ]
  },
  AlertEngineOilPressure: {
    key: 'VPMEOP',
    apiKey: 'VPM_EOP',
    label: 'Engine Oil Pressure',
    marker: 'event-engine-alerts.png',
    markerSvg: imgAlertEngine,
    lens: TrackingLens().Trips,
    url: '/events/:id:/vpm_eop?time_at=:timeAt:',
    attributes: [
      {
        label: 'Oil Pressure',
        name: 'oilPressure',
        unit: 'kPa'
      }
    ]
  },
  VPMHarshCornering: {
    key: 'VPMHC',
    apiKey: 'VPM_HC',
    label: 'Harsh Cornering',
    marker: 'event-harsh-cornering.png',
    markerSvg: imgVPMHarshCornering,
    lens: TrackingLens().Safety,
    url: '/events/:id:/vpm_hc?time_at=:timeAt:',
    attributes: [
      {
        label: 'Acceleration',
        name: EventAttributes.NAMES.acceleration,
        unit: EventAttributes.UNITS.msseconds
      },
      {
        label: 'Duration',
        name: EventAttributes.NAMES.duration,
        unit: EventAttributes.UNITS.seconds
      }
    ]
  },
  VPMHarshBraking: {
    key: 'VPMHB',
    apiKey: 'VPM_HB',
    label: 'Harsh Braking',
    marker: 'event-harsh-braking.png',
    markerSvg: imgVPMHarshBraking,
    lens: TrackingLens().Safety,
    url: '/events/:id:/vpm_hb?time_at=:timeAt:',
    attributes: [
      {
        label: 'Deceleration',
        name: EventAttributes.NAMES.deceleration,
        unit: EventAttributes.UNITS.msseconds
      },
      {
        label: 'Duration',
        name: EventAttributes.NAMES.duration,
        unit: EventAttributes.UNITS.seconds
      }
    ]
  },
  VPMHarshAcceleration: {
    key: 'VPMEA',
    apiKey: 'VPM_EA',
    label: 'Harsh Acceleration',
    marker: 'event-harsh-acceleration.png',
    markerSvg: imgVPMHarshAcceleration,
    lens: TrackingLens().Safety,
    url: '/events/:id:/vpm_ea?time_at=:timeAt:',
    attributes: [
      {
        label: 'Acceleration',
        name: EventAttributes.NAMES.acceleration,
        unit: EventAttributes.UNITS.msseconds
      },
      {
        label: 'Duration',
        name: EventAttributes.NAMES.duration,
        unit: EventAttributes.UNITS.seconds
      }
    ]
  },

  VPMImpact: {
    key: 'VPMIM',
    apiKey: 'VPM_IM',
    label: 'Impact',
    marker: 'event-impact.png',
    markerSvg: imgImpact,
    lens: TrackingLens().Safety,
    url: '/events/:id:/vpm_im?time_at=:timeAt:',
    attributes: [
      {
        label: 'Acceleration',
        name: EventAttributes.NAMES.acceleration,
        unit: EventAttributes.UNITS.msseconds
      },
      {
        label: 'Duration',
        name: EventAttributes.NAMES.duration,
        unit: EventAttributes.UNITS.seconds
      }
    ]
  },

  // ROUTECOMPLIANCE Events
  AlertTripStarted: {
    key: 'ROUTECOMPLIANCETRIP-STARTED',
    apiKey: 'ROUTECOMPLIANCE',
    label: 'Trip Started',
    marker: 'event-route-compliance-alert.png',
    markerSvg: imgAlertRoute,
    lens: TrackingLens().Trips,
    url: '/events/:id:/route_compliance?time_at=:timeAt:'
  },
  AlertTripFinished: {
    key: 'ROUTECOMPLIANCETRIP-FINISHED',
    apiKey: 'ROUTECOMPLIANCE',
    label: 'Trip Finished',
    marker: 'event-route-compliance-alert.png',
    markerSvg: imgAlertRoute,
    lens: TrackingLens().Trips,
    url: '/events/:id:/route_compliance?time_at=:timeAt:'
  },
  AlertTripEntry: {
    key: 'ROUTECOMPLIANCETRIP-ENTRY',
    apiKey: 'ROUTECOMPLIANCE',
    label: 'Trip Entry',
    marker: 'event-route-compliance-alert.png',
    markerSvg: imgAlertRoute,
    lens: TrackingLens().Trips,
    url: '/events/:id:/route_compliance?time_at=:timeAt:'
  },
  AlertTripExit: {
    key: 'ROUTECOMPLIANCETRIP-EXIT',
    apiKey: 'ROUTECOMPLIANCE',
    label: 'Trip Exit',
    marker: 'event-route-compliance-alert.png',
    markerSvg: imgAlertRoute,
    lens: TrackingLens().Trips,
    url: '/events/:id:/route_compliance?time_at=:timeAt:'
  },

  // FORM Events
  FormComplete: {
    key: 'FORMDONE',
    apiKey: 'FORM',
    label: 'Completed Form',
    marker: 'event-completed-form.png',
    markerSvg: imgFormCompleted,
    lens: TrackingLens().Trips,
    url: '/events/:id:/form?time_at=:timeAt:'
  },

  // PRETRIP Events
  PTCComplete: {
    key: 'PRETRIPDONE',
    apiKey: 'PRETRIP',
    label: 'Completed Inspection',
    marker: 'event-completed-ptc.png',
    markerSvg: imgPTCCompleted,
    lens: TrackingLens().Trips,
    url: '/events/:id:/pretrip?time_at=:timeAt:'
  },

  // MASS Events
  MassDeclaration: {
    key: 'MASSDECL',
    apiKey: 'MASS',
    label: 'Mass Management Declaration',
    marker: 'event-mass-mgmt-declaration.png',
    markerSvg: imgMassDeclaration,
    lens: TrackingLens().Trips,
    url: '/events/:id:/mass?time_at=:timeAt:'
  },

  // Communication Events
  CsrDeclaration: {
    key: 'CSRDECL',
    apiKey: 'CSR',
    label: 'Communication Event Declaration',
    marker: 'event-communication.png',
    markerSvg: imgCommunication,
    lens: TrackingLens().Trips,
    can: { featureFlag: FeatureFlag.npi.flag }
  },

  // IAP Events
  IAPDeclaration: {
    key: 'IAPDECL',
    apiKey: 'IAP',
    label: 'IAP Declaration',
    marker: 'event-iap-declaration.png',
    markerSvg: imgIAPDeclaration,
    lens: TrackingLens().Trips,
    attributes: [
      {
        label: 'Vehicle Type',
        name: EventAttributes.NAMES.vehicle_type,
        unit: ''
      },
      {
        label: 'Axle',
        name: EventAttributes.NAMES.axle,
        unit: ''
      },
      {
        label: 'TCM',
        name: EventAttributes.NAMES.tcm,
        unit: EventAttributes.UNITS.ton
      }
    ]
  },

  // DRIVER Events
  DriverLogOn: {
    key: 'DRIVERLOGON',
    apiKey: 'DRIVER',
    label: 'Driver Log On',
    marker: 'event-log-in.png',
    markerSvg: imgDriverLogOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/driver?time_at=:timeAt:'
  },
  DriverLogOff: {
    key: 'DRIVERLOGOFF',
    apiKey: 'DRIVER',
    label: 'Driver Log Off',
    marker: 'event-log-out.png',
    markerSvg: imgDriverLogOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/driver?time_at=:timeAt:'
  },
  DriverLogonIncorrect: {
    key: 'DRIVERLOGON_INCORRECT',
    apiKey: 'DRIVER',
    label: 'Driver Logon Incorrect',
    marker: 'event-log-in-error.png',
    markerSvg: imgDriverIncorrectLogon,
    lens: TrackingLens().Trips,
    url: '/events/:id:/driver?time_at=:timeAt:'
  },
  DriverRest: {
    key: 'DRIVERREST',
    apiKey: 'DRIVER',
    label: 'Driver Start Rest',
    marker: 'event-start-rest.png',
    markerSvg: imgDriverRest,
    lens: TrackingLens().Trips,
    url: '/events/:id:/driver?time_at=:timeAt:'
  },
  DriverWork: {
    key: 'DRIVERWORK',
    apiKey: 'DRIVER',
    label: 'Driver Start Work',
    marker: 'event-start-work.png',
    markerSvg: imgDriverWork,
    lens: TrackingLens().Trips,
    url: '/events/:id:/driver?time_at=:timeAt:'
  },
  DriverDrive: {
    key: 'DRIVERDRIVE',
    apiKey: 'DRIVER',
    label: 'Driving Started',
    marker: 'event-start-work.png',
    markerSvg: imgDriverWork,
    lens: TrackingLens().Trips,
    url: '/events/:id:/driver?time_at=:timeAt:'
  },
  DriverUnknown: {
    key: 'DRIVERUNKNOWN',
    apiKey: 'DRIVER',
    label: 'Driver Unknown Event',
    marker: 'event-driver-unknown.png',
    markerSvg: DriverUnknown,
    lens: TrackingLens().Trips,
    url: '/events/:id:/driver?time_at=:timeAt:'
  },
  DriverPOA: {
    key: 'DRIVERPOA',
    apiKey: 'DRIVER',
    label: 'Driver Available',
    marker: 'event-start-work.png',
    markerSvg: imgDriverWork,
    lens: TrackingLens().Trips,
    url: '/events/:id:/driver?time_at=:timeAt:'
  },
  DriverOnDuty: {
    key: 'DRIVERDUTY',
    apiKey: 'DRIVER',
    label: 'On Duty',
    marker: 'event-start-work.png',
    markerSvg: imgDriverWork,
    lens: TrackingLens().Trips,
    url: '/events/:id:/driver?time_at=:timeAt:'
  },
  DriverOffDuty: {
    key: 'DRIVEROFF',
    apiKey: 'DRIVER',
    label: 'Off Duty / Sleeper Berth',
    marker: 'event-start-rest.png',
    markerSvg: imgDriverRest,
    lens: TrackingLens().Trips,
    url: '/events/:id:/driver?time_at=:timeAt:'
  },

  // PTO Events
  PTOOn: {
    key: 'PTOON',
    apiKey: 'PTO',
    label: 'PTO On',
    marker: 'event-power-take-off-on.png',
    markerSvg: imgPTOOn,
    lens: TrackingLens().Trips,
    url: '/events/:id:/pto?time_at=:timeAt:',
    attributes: [
      {
        label: 'Duration',
        name: EventAttributes.NAMES.duration,
        unit: EventAttributes.UNITS.seconds
      }
    ]
  },
  PTOOff: {
    key: 'PTOOFF',
    apiKey: 'PTO',
    label: 'PTO Off',
    marker: 'event-power-take-off-off.png',
    markerSvg: imgPTOOff,
    lens: TrackingLens().Trips,
    url: '/events/:id:/pto?time_at=:timeAt:',
    attributes: [
      {
        label: 'Duration',
        name: EventAttributes.NAMES.duration,
        unit: EventAttributes.UNITS.seconds
      }
    ]
  },

  // SPEED Events
  SpeedingStart: {
    key: 'SPEEDSTART',
    apiKey: 'SPEED',
    label: 'Speeding',
    marker: 'event-speeding-start.png',
    markerSvg: imgSpeed,
    lens: TrackingLens().Track,
    url: '/events/:id:/speed?time_at=:timeAt:'
  },
  SpeedingUpdate: {
    key: 'SPEEDUPDATE',
    label: 'Speeding Update',
    marker: 'event-speeding-start.png',
    markerSvg: imgSpeed,
    lens: TrackingLens().Track,
    url: '/events/:id:/speed?time_at=:timeAt:'
  },
  SpeedingEnd: {
    key: 'SPEEDEND',
    apiKey: 'SPEED',
    label: 'Speeding',
    marker: 'event-speeding-start.png',
    markerSvg: imgSpeed,
    lens: TrackingLens().Safety,
    url: '/events/:id:/speed?time_at=:timeAt:',
    attributes: [
      {
        label: EventAttributes.LABELS.Speed,
        name: EventAttributes.NAMES.maxSpeed,
        unit: EventAttributes.UNITS.kmhour
      }
    ]
  },

  // MOVEMENT Events
  MovementMoving: {
    key: 'MOVEMENTMOVING',
    apiKey: 'MOVEMENT',
    label: 'Movement Moving',
    marker: 'vehicle-started.png',
    markerSvg: vehicleStarted,
    lens: TrackingLens().Trips,
    url: '/events/:id:/movement?time_at=:timeAt:'
  },
  MovementStationary: {
    key: 'MOVEMENTSTATIONARY',
    apiKey: 'MOVEMENT',
    label: 'Movement Stationary',
    marker: 'vehicle-stopped.png',
    markerSvg: vehicleStopped,
    lens: TrackingLens().Trips,
    url: '/events/:id:/movement?time_at=:timeAt:'
  },

  // FATIGUE Events
  FatigueBreach: {
    key: 'FATIGUEBR',
    apiKey: 'FATIGUE',
    label: 'Fatigue Breach',
    marker: 'event-fatigue-breach.png',
    markerSvg: imgFatigueBreach,
    lens: TrackingLens().Safety,
    url: '/events/:id:/fatigue_violation?time_at=:timeAt:',
    attributes: [{ label: 'Driver', name: EventAttributes.NAMES.driver }]
  },
  FatigueEldViolation: {
    key: 'FATIGUEVIOLATION',
    apiKey: 'FATIGUE',
    label: 'Violation',
    marker: 'event-fatigue-breach.png',
    markerSvg: imgFatigueBreach,
    lens: TrackingLens().Safety,
    url: '/events/:id:/fatigue_violation?time_at=:timeAt:',
    attributes: [{ label: 'Driver', name: EventAttributes.NAMES.driver }]
  },

  // CAMERA Events
  CameraAccelerationHigh: {
    key: 'CAMERAACC_HIGH',
    apiKey: 'CAMERA',
    label: 'Camera Acceleration (High)',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraAccelerationMedium: {
    key: 'CAMERAACC_MEDIUM',
    apiKey: 'CAMERA',
    label: 'Camera Acceleration (Medium)',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraAccelerationLow: {
    key: 'CAMERAACC_LOW',
    apiKey: 'CAMERA',
    label: 'Camera Acceleration (Low)',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraFatigue: {
    key: 'CAMERAFATIGUE',
    apiKey: 'CAMERA',
    label: 'Camera Fatigue',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraDistraction: {
    key: 'CAMERADISTRACTION',
    apiKey: 'CAMERA',
    label: 'Camera Distraction',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraDriverInput: {
    key: 'CAMERADRIVER_INPUT',
    apiKey: 'CAMERA',
    label: 'Camera Driver Input',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraIOInput: {
    key: 'CAMERAIO_INPUT',
    apiKey: 'CAMERA',
    label: 'Camera IO Input',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraSpeedViolation: {
    key: 'CAMERASPEED_VIOLATION',
    apiKey: 'CAMERA',
    label: 'Camera Speed Violation',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraConfigSpeedViolation: {
    key: 'CAMERACONFIG_SPEED_VIOLATION',
    apiKey: 'CAMERA',
    label: 'Camera Speed Violation (Config)',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraHarshUsage: {
    key: 'CAMERAHARSH_USAGE',
    apiKey: 'CAMERA',
    label: 'Camera Harsh Usage',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraHarshBraking: {
    key: 'CAMERAHARSH_BRAKING',
    apiKey: 'CAMERA',
    label: 'Camera Harsh Braking',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraHarshCornering: {
    key: 'CAMERAHARSH_CORNERING',
    apiKey: 'CAMERA',
    label: 'Camera Harsh Cornering',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraTailGating: {
    key: 'CAMERATAIL_GATING',
    apiKey: 'CAMERA',
    label: 'Tailgating',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraCollisionWarning: {
    key: 'CAMERACOLLISION_WARNING',
    apiKey: 'CAMERA',
    label: 'Collision Warning',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraLaneDriftWarning: {
    key: 'CAMERALANE_DRIFT_WARNING',
    apiKey: 'CAMERA',
    label: 'Lane Drift',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraSignViolation: {
    key: 'CAMERASIGN_VIOLATION',
    apiKey: 'CAMERA',
    label: 'Camera Sign Violation',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraVideoRequest: {
    key: 'CAMERAVIDEO_REQUEST',
    apiKey: 'CAMERA',
    label: 'Camera Video Request',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraOther: {
    key: 'CAMERAOTHER',
    apiKey: 'CAMERA',
    label: 'Camera Other',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraStopSignViolation: {
    key: 'CAMERASTOP_SIGN_VIOLATION',
    apiKey: 'CAMERA',
    label: 'Camera Stop Sign Violation',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraCellphoneDistraction: {
    key: 'CAMERACELLPHONE_DISTRACTION',
    apiKey: 'CAMERA',
    label: 'Cellphone Distraction',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraGazeDownDistraction: {
    key: 'CAMERAGAZE_DOWN_DISTRACTION',
    apiKey: 'CAMERA',
    label: 'Gaze Down Distraction',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraDrowsyDrivingDistraction: {
    key: 'CAMERADROWSY_DRIVING_DETECTED',
    apiKey: 'CAMERA',
    label: 'Drowsy Driving',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraPotentialCrash: {
    key: 'CAMERAPOTENTIAL_CRASH',
    apiKey: 'CAMERA',
    label: 'Potential Crash',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:'
  },
  CameraUnbuckledSeatbelt: {
    key: 'CAMERAUNBUCKLED_SEATBELT',
    apiKey: 'CAMERA',
    label: 'Seatbelt Violation',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:',
    can: {
      oneOfFeatureFlags: [FeatureFlag.addIQEvents.flag, FeatureFlag.addIQEventsCapitalElectric.flag]
    }
  },
  CameraRolloverDetected: {
    key: 'CAMERAROLLOVER_DETECTED',
    apiKey: 'CAMERA',
    label: 'Rollover Detection',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:',
    can: {
      oneOfFeatureFlags: [FeatureFlag.addIQEvents.flag, FeatureFlag.addIQEventsCapitalElectric.flag]
    }
  },
  CameraTrafficLightViolation: {
    key: 'CAMERATRAFFIC_LIGHT_VIOLATION',
    apiKey: 'CAMERA',
    label: 'Traffic Light Violation',
    marker: 'event-camera.png',
    markerSvg: imgCamera,
    lens: TrackingLens().Safety,
    url: '/events/:id:/camera?time_at=:timeAt:',
    can: {
      oneOfFeatureFlags: [FeatureFlag.addIQEvents.flag, FeatureFlag.addIQEventsCapitalElectric.flag]
    }
  }
};

const eventTypesByKey = Object.values(EventTypes).reduce((eventTypes, eventType) => {
  eventTypes[eventType.key] = eventType;
  return eventTypes;
}, {});

export const getEventAttributesByKey = key => {
  if (eventTypesByKey.hasOwnProperty(key)) {
    return eventTypesByKey[key];
  } else {
    return UndefinedEventType;
  }
};

export const getEventAttributesByType = (eventType, eventSubType, customSubType) => {
  const eventTypeKey =
    eventType === 'GPIO' && !eventTypesByKey[eventType + eventSubType]
      ? 'GPIOdefault'
      : eventType + eventSubType;

  let eventAttributes = { ...getEventAttributesByKey(eventTypeKey) };

  if (!!customSubType) {
    //replace the label with custom noun and verb
    eventAttributes.label = customSubType.replace(/-/g, ' ');
  }
  return eventAttributes;
};

export const useSupportedEventTypes = () => {
  const can = useCan();
  const allSupportedEvtTypes = useMemo(
    () => Object.values(EventTypes).filter(t => (t.can ? can(t.can) : true)),
    [can]
  );

  return {
    allSupportedEvtTypes,
    allEventTypeKeys: allSupportedEvtTypes.map(eventType => eventType.key),
    allEventTypesNoDuplicates: uniqBy(allSupportedEvtTypes, 'label'),
    allEventTypesNoDuplicatesSorted: sortBy(uniqBy(allSupportedEvtTypes, 'label'), 'label'),
    getEventTypes: trackingLens =>
      allSupportedEvtTypes
        .filter(eventType => eventType.lens === trackingLens)
        .reduce((eventTypes, eventType) => [...eventTypes, eventType.key], []),
    getEventApiKeys: trackingLens =>
      allSupportedEvtTypes
        .filter(eventType => eventType.lens === trackingLens)
        .reduce(
          (eventTypes, eventType) =>
            eventType.apiKey && !eventTypes.includes(eventType.apiKey)
              ? [...eventTypes, eventType.apiKey]
              : eventTypes,
          []
        ),
    addEventTypesWithDuplicateLabels: eventTypes => {
      let newEventTypes = [];

      // Re-Add all event types with duplicate labels (before saving)
      allSupportedEvtTypes.forEach(eventType => {
        if (eventTypes.find(item => item.label === eventType.label)) {
          newEventTypes.push(eventType);
        }
      });
      return newEventTypes;
    },
    removeEventTypesWithDuplicateLabels: eventTypes => {
      let newEventTypes = [];

      // Exclude items with same labels
      eventTypes.forEach(eventType => {
        if (!newEventTypes.find(item => item.label === eventType.label)) {
          newEventTypes.push(eventType);
        }
      });
      return newEventTypes;
    }
  };
};
