import React, { useState, useEffect, useMemo, useCallback } from 'react';
//Hooks
import { useDispatch } from 'react-redux';

import { useLocalization } from 'features/localization/localizationSlice';
import { useHistory } from 'react-router';

import InfoRow from 'components/form/info-row/InfoRow';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
//Methods
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { getIDFromPathname } from 'utils/methods';
//Constants
import { PATHS } from './constants';
//styles
import styles from './CompanyAgreement.module.scss';
import { useTranslation } from 'react-i18next';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';

import { useCompanies, useCurrentCompany, useSubCompanies } from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import moment from 'moment';
import i18n from 'i18next';
import { Column, Table, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { useGetCompanyAgreementsQuery } from 'services/nextgen/ngAgreementApi';
import { FeatureFlag, useCan } from 'features/permissions';
import { Button } from 'antd';
import { CompanyAgreementCopyModal } from './CompanyAgreementCopyModal';
import { PATHS as CompanyAgreementPaths } from './constants';
import GlobalRoles from 'features/permissions/GlobalRoles';

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 60
});

export const ColumnKeys = {
  SUBSCRIPTION: 'subscription',
  QUANTITY: 'quantity',
  STATUS: 'status',
  ASSIGNED_DEVICES: 'assignedDevices'
};

const CompanyAgreementView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const history = useHistory();
  const companies = useCompanies();
  const subCompanies = useSubCompanies();
  const can = useCan();
  const [showCopyAgreement, setShowCopyAgreement] = useState(false);
  const handleFetchError = useCallback(() => {
    history.replace(PATHS.AGREEMENT_DEFAULT);
  }, [history]);
  const currentCompany = useCurrentCompany();
  const { data: agreementData, isFetching } = useGetCompanyAgreementsQuery(
    {
      id: currentCompany?.id,
      embed: 'devices'
    },
    { skip: currentCompany?.id === undefined }
  );
  const agreementDetail = agreementData?.find(d => d.agreements.find(k => k.id === id));
  const localization = useLocalization();
  const [agreementSubscriptionData, setAgreementSubscriptionData] = useState([]);

  useEffect(() => {
    dispatch(setPageTitle(agreementDetail?.workOrder));
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (id <= 0 || isNaN(id) || (agreementData?.length > 0 && !agreementDetail)) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Common.Invalid Request ID')
        })
      );
      handleFetchError();
    }
  }, [dispatch, id, agreementData, handleFetchError, agreementDetail]);

  useEffect(() => {
    if (agreementDetail) {
      const parsedAgreements = agreementDetail.agreements.map(entry => {
        return {
          [ColumnKeys.SUBSCRIPTION]: entry.subscriptionPack.billingCode,
          [ColumnKeys.STATUS]: entry.status,
          [ColumnKeys.QUANTITY]: entry.quantity,
          [ColumnKeys.ASSIGNED_DEVICES]: (agreementDetail.deviceAgreements || []).filter(
            k => k.agreementId === entry.id
          ).length,
          key: entry.id
        };
      });

      setAgreementSubscriptionData(parsedAgreements);
    }
  }, [dispatch, agreementDetail]);

  const cellRenderer = ({ dataKey, parent, rowIndex, rowData }) => {
    return (
      <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div>{rowData[dataKey]}</div>
      </CellMeasurer>
    );
  };

  return (
    <>
      <ViewHeaderWrapper
        data={{ entityName: AUDIT_ENTITY.AGREEMENT, id: id }}
        auditPath={`${PATHS.AGREEMENT_AUDIT}/${id}`}
        canUse="AGREEMENT"
      >
        {can({
          oneOfRoles: [GlobalRoles.SubCompanyAdmin]
        }) && (
          <Button
            size="large"
            type="secondary"
            loading={isFetching}
            onClick={() => {
              history.push(CompanyAgreementPaths.AGREEMENT_ASSIGN, { agreement: agreementDetail });
            }}
          >
            {t('Agreement.AssignAgreement')}
          </Button>
        )}
        {can({
          oneOfRoles: [GlobalRoles.SubCompanyAdmin]
        }) &&
          currentCompany.parent?.id === undefined &&
          subCompanies.filter(i => i.id !== currentCompany?.id).length !== 0 && (
            <Button
              size="large"
              type="secondary"
              loading={isFetching}
              onClick={() => {
                setShowCopyAgreement(true);
              }}
            >
              {t('Agreement.CopyToSubCompany')}
            </Button>
          )}
      </ViewHeaderWrapper>
      <div className={styles.mainWrapper}>
        <div className={styles.view__details}>
          <h5 className={styles.view__heading}>{t('CompanyConfig.CompanyAgreement.Detail')}</h5>
          <InfoRow
            label={t('CompanyConfig.CompanyAgreement.Company')}
            labelWidth={'30%'}
            sxValue={{ width: '70%' }}
            value={(companies || []).find(i => i.id === agreementDetail?.company?.id)?.name}
            styles={styles}
          />
          <InfoRow
            label={t('CompanyConfig.CompanyAgreement.Description')}
            labelWidth={'30%'}
            sxValue={{ width: '70%' }}
            value={agreementDetail?.description}
            styles={styles}
          />
          <InfoRow
            label={t('CompanyConfig.CompanyAgreement.WorkOrder')}
            labelWidth={'30%'}
            sxValue={{ width: '70%' }}
            value={agreementDetail?.workOrder}
            styles={styles}
          />
          <InfoRow
            label={t('CompanyConfig.CompanyAgreement.ContractDate')}
            labelWidth={'30%'}
            sxValue={{ width: '70%' }}
            value={`${moment(agreementDetail?.contractStartAt).format(
              localization.formats.time.formats.dby_imp
            )} - ${moment(agreementDetail?.contractEndAt).format(
              localization.formats.time.formats.dby_imp
            )}`}
            styles={styles}
          />
          <InfoRow
            label={t('CompanyConfig.CompanyAgreement.TechnicalDate')}
            labelWidth={'30%'}
            sxValue={{ width: '70%' }}
            value={`${moment(agreementDetail?.enabledAt).format(
              localization.formats.time.formats.dby_imp
            )} - ${moment(agreementDetail?.expiresAt).format(
              localization.formats.time.formats.dby_imp
            )}`}
            styles={styles}
          />
        </div>
      </div>
      <div className={styles.subscriptionPanel}>
        <AutoSizer>
          {({ height, width }) => (
            <Table
              deferredMeasurementCache={cache}
              width={width}
              height={height}
              headerHeight={52}
              rowHeight={cache.rowHeight}
              rowCount={agreementSubscriptionData.length}
              rowGetter={({ index }) => agreementSubscriptionData[index]}
            >
              <Column
                label={i18n.t('CompanyConfig.CompanyAgreement.SubscriptionPack')}
                dataKey={ColumnKeys.SUBSCRIPTION}
                width={width * 0.4}
                cellRenderer={cellRenderer}
              />
              <Column
                label={i18n.t('CompanyConfig.CompanyAgreement.Status')}
                dataKey={ColumnKeys.STATUS}
                width={width * 0.2}
                cellRenderer={cellRenderer}
              />
              <Column
                label={i18n.t('CompanyConfig.CompanyAgreement.Quantity')}
                dataKey={ColumnKeys.QUANTITY}
                cellRenderer={cellRenderer}
                width={width * 0.2}
              />
              <Column
                label={i18n.t('CompanyConfig.CompanyAgreement.AssignedDevices')}
                dataKey={ColumnKeys.ASSIGNED_DEVICES}
                width={width * 0.2}
                cellRenderer={cellRenderer}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
      <CompanyAgreementCopyModal
        isOpen={showCopyAgreement}
        agreement={agreementDetail || {}}
        onClose={() => {
          setShowCopyAgreement(false);
        }}
      />
    </>
  );
};

export default CompanyAgreementView;
