import React, { useState, useContext } from 'react';
import { List } from 'antd';

import { TrackEventsList } from './TrackEventsList';

import eventStyles from './TrackEvents.module.scss';
import { ForceUpdateContext } from '../Tables/TrackGrid';

export const OutOfTripEventsItem = ({
  device,
  allEvents,
  tripSummaryEvents,
  trip,
  selectedEvent,
  t,
  onEventClicked,
  onDeviceClicked
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const forceRerender = useContext(ForceUpdateContext);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
    forceRerender();
  };

  const numEvents = allEvents.length;

  const groupIndicatorClass = isExpanded
    ? eventStyles.groupIndicatorExpanded
    : eventStyles.groupIndicatorCollapsed;

  return numEvents > 0 ? (
    <>
      <List.Item className={eventStyles.outOfTripEventsItem} key={trip.id ? trip.id : trip.index}>
        <div className={eventStyles.outOfTripEventsHeader} onClick={handleClick}>
          {numEvents} {t('Tracking.OutOfTripEvents')}
        </div>
        {isExpanded && (
          <div className={eventStyles.outOfTripEventsContainer}>
            <TrackEventsList
              device={device}
              allEvents={allEvents}
              tripSummaryEvents={tripSummaryEvents}
              trip={trip}
              nextTrip={null}
              groupByTripsEnabled={true}
              isOutOfTripEvents={true}
              isLoading={false}
              isExpanded={true}
              selectedEvent={selectedEvent}
              showScroll={false}
              onEventClicked={onEventClicked}
              onDeviceClicked={onDeviceClicked}
            />
          </div>
        )}
        <div className={groupIndicatorClass} />
      </List.Item>
    </>
  ) : null;
};
