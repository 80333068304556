import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router';
import { DownOutlined, UpOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Space, Input, Checkbox, Modal } from 'antd';
import {
  Layout,
  TabsFilters,
  Table,
  DateRangePicker,
  ExpandAllButton,
  Select
} from 'components/ant';

import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useSubCompanies } from 'features/company/companySlice';
import { useFleets } from 'features/fleets/fleetsSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useCanOneOfCompanyServices, services } from 'features/permissions';
import useDebounce from 'utils/hooks/useDebounce';

import { getTabs } from 'utils/tabs';
import {
  InspectionInnerColumns,
  VEHICLE_INSPECTIONS_TABS,
  INSPECTION_TYPES,
  GROUP_BY
} from './utils/constants';
import { getCheckedFilterIds, prepareDataForMultiselect, FilterTypes } from 'utils/filters';
import {
  getInspectionsTabs,
  prepareAllDataForTable,
  prepareDataForInnerTable,
  prepareColumnsForTable,
  filterInspections,
  getFilterLabel
} from './utils/helpers';
import {
  useGroupedInspections,
  useIsInspectionsFetching,
  useFilters,
  updateFilters,
  fetchInspections,
  addInspectionNotes,
  closeInspection
} from 'features/vehicleMaintenanceInspections';

import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';

import styles from './Inspections.module.scss';
import dayjs from 'dayjs';

export const Inspections = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const localization = useLocalization();
  const [activeTab, setActiveTab] = useState(VEHICLE_INSPECTIONS_TABS.all);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 300);
  const filters = useFilters();
  const groupBy = filters?.groupBy;
  const [isEditNotesModalOpened, setIsEditNotesModalOpened] = useState(false);
  const [isCloseInspectionModalOpened, setisCloseInspectionModalOpened] = useState(false);
  const [isCertifyCompleted, setIsCertifyCompleted] = useState(false);
  const calendarDate = filters?.calendarDate;
  const [dateFrom, setDateFrom] = useState(
    calendarDate
      ? calendarDate?.dateFrom
      : moment()
          .add(-6, 'days')
          .format('YYYY-MM-DD')
  );
  const [dateTo, setDateTo] = useState(
    calendarDate ? calendarDate?.dateTo : moment().format('YYYY-MM-DD')
  );
  const [datePickerOpen, setDatePickerOpen] = useState();
  const groupedInspections = useGroupedInspections(groupBy);
  const allExpandedRowKeys = groupedInspections.map(item => item.key);
  const isInspectionsFetching = useIsInspectionsFetching();
  const subCompanies = useSubCompanies();
  const fleets = useFleets();
  const [filteredInspections, setFilteredInspections] = useState(groupedInspections);
  const [inspectionsCounter, setInspectionsCounter] = useState();
  const [expandAll, setExpandAll] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [inspection, setInspection] = useState({});
  const [notesInputs, setNotesInput] = useState('');
  const dvirPermission = useCanOneOfCompanyServices([services.DVIR]);
  const filterCompanies =
    subCompanies &&
    prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), filters?.companies);
  const subCompaniesIdsFromFilter = filterCompanies
    .filter(comp => comp.checked)
    .map(comp => comp.id);
  const companyIds = getCheckedFilterIds(filterCompanies);
  const filterFleets =
    fleets &&
    prepareDataForMultiselect(
      fleets.filter(fleet => subCompaniesIdsFromFilter?.includes(fleet?.company?.id)),
      t('Common.AllFleets'),
      filters?.fleets
    );
  const rowFilter = useMemo(
    () =>
      groupedInspections &&
      prepareDataForMultiselect(
        groupedInspections.map(v => ({ name: v.item?.checklistName, id: v.item?.id })),
        getFilterLabel({ groupBy, t, all: true }),
        filters?.rowFilter
      ),
    [groupedInspections, filters]
  );

  const fleetIds = getCheckedFilterIds(filterFleets);
  const filterTypes = prepareDataForMultiselect(
    INSPECTION_TYPES,
    t('Common.AllTypes'),
    filters?.types
  );
  const typeIds = getCheckedFilterIds(filterTypes);
  const rowFilterIds = getCheckedFilterIds(rowFilter);
  const groupByItems = useMemo(() => {
    return Object.keys(GROUP_BY).map(id => ({
      id,
      label: t(`Inspections.${id}`)
    }));
  }, [t]);

  useEffect(() => {
    dispatch(setPageTitle(t('VehicleMntSchedules.Title')));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  useEffect(() => {
    setExpandAll(false);
    setFilteredInspections(
      filterInspections({
        inspections: groupedInspections,
        filters: {
          activeTab,
          searchText: debouncedSearchText,
          companyIds,
          fleetIds,
          typeIds,
          rowFilterIds
        },
        setExpandAll
      })
    );
  }, [debouncedSearchText, groupedInspections, activeTab, filters]);

  // get the number of total inspections for the counter
  useEffect(() => {
    setInspectionsCounter(
      filteredInspections.reduce((acc, item) => acc + item?.groupedItems?.length, 0)
    );
  }, [filteredInspections]);

  useEffect(() => {
    if (!datePickerOpen) {
      dispatch(fetchInspections({ query: { from: dateFrom, to: dateTo } }));
    }
  }, [datePickerOpen]);

  const handleTabChange = tab => {
    setActiveTab(tab);
  };

  const handleSearchTable = text => {
    setSearchText(text);
  };

  const handleFilterChange = filterType => newFilterValues => {
    const filterValue = getCheckedFilterIds(newFilterValues);
    dispatch(updateFilters({ filterType, filterValue }));
  };

  const handleGroupByChange = filterValue => {
    dispatch(updateFilters({ filterType: FilterTypes.groupBy, filterValue }));
    // when Grouping is changed -> Reset the the Inspections/Vehicles by name filter
    dispatch(updateFilters({ filterType: FilterTypes.rowFilter, filterValue: null }));
  };

  const handleDateRangeChange = dates => {
    if (!dates || !dates[0] || !dates[1]) {
      return;
    }

    const tempStartAt = moment(dates[0].format('YYYY-MM-DD'));
    const tempEndAt = moment(dates[1].format('YYYY-MM-DD'));
    const startAt = tempStartAt;
    const endAt = tempEndAt;

    setDateFrom(startAt.format('YYYY-MM-DD'));
    setDateTo(endAt.format('YYYY-MM-DD'));

    dispatch(
      updateFilters({
        filterType: 'calendarDate',
        filterValue: { dateFrom: startAt.format('YYYY-MM-DD'), dateTo: endAt.format('YYYY-MM-DD') }
      })
    );
  };

  const handleDateRangeClose = isOpen => {
    setDatePickerOpen(isOpen);
  };

  const openEditModal = inspection => {
    setNotesInput(inspection?.notes);
    setInspection(inspection);
    setIsEditNotesModalOpened(true);
  };

  const handleSaveEditNotesModal = async () => {
    await dispatch(
      addInspectionNotes({
        inspectionId: inspection?.inspectionId,
        notes: notesInputs,
        dateFrom: dateFrom,
        dateTo: dateTo
      })
    );
    setIsEditNotesModalOpened(false);
  };

  const handleCancelEditNotesModal = () => {
    setIsEditNotesModalOpened(false);
  };

  const openInspectionModal = inspection => {
    setNotesInput(inspection?.notes);
    setIsCertifyCompleted(false);
    setInspection(inspection);
    setisCloseInspectionModalOpened(true);
  };

  const handleSaveCloseInspection = async () => {
    await dispatch(closeInspection({ inspectionId: inspection?.inspectionId, notes: notesInputs }));
    setisCloseInspectionModalOpened(false);
  };

  const handleCancelCloseInspection = () => {
    setisCloseInspectionModalOpened(false);
  };

  const renderRowTable = row => {
    return (
      <Table
        dataSource={prepareDataForInnerTable({
          groupedItems: row.groupedItems,
          fleetIds,
          groupBy,
          localization,
          openEditModal,
          openInspectionModal,
          history,
          t,
          dispatch
        })}
        columns={InspectionInnerColumns(groupBy)}
        pagination={false}
        sticky={true}
      />
    );
  };

  const ExpandButton = ({ expanded, onExpand, record }) => {
    return expanded ? (
      <UpOutlined className={styles.expandButton} onClick={e => onExpand(record, e)} />
    ) : (
      <DownOutlined className={styles.expandButton} onClick={e => onExpand(record, e)} />
    );
  };

  //toggle between expand all/collapse all boolean
  const handleExpandCollapseAll = () => {
    if (!expandAll) {
      setExpandedRowKeys([]);
    }
    setExpandAll(!expandAll);
  };

  const expandAllRenderer = (
    <ExpandAllButton
      handleOnClick={handleExpandCollapseAll}
      expandAll={expandAll}
      customStyle={styles.expandAllButton}
    />
  );

  const handleExpandedRowsChange = expandedRows => {
    setExpandAll(false); // reset the expand all button
    setExpandedRowKeys(expandedRows);
  };

  const handleEditNotes = event => {
    const notes = event.target.value;
    setNotesInput(notes);
  };

  const handleCheckboxChange = event => {
    setIsCertifyCompleted(event.target.checked);
  };

  const Header = () => {
    const tabs = getInspectionsTabs(dvirPermission);

    return (
      <div className={styles.tabFilters}>
        <TabsFilters tabs={getTabs(tabs, activeTab, handleTabChange, t)} />
      </div>
    );
  };

  return (
    <Layout>
      <Header />
      <Layout.Content className={styles.inspectionContent}>
        <div className={`${styles.filterWrapper} ${styles.spaceAround}`}>
          <Space size={8} className={styles.wrap}>
            <AntSearchbar size={'medium'} className={styles.search} onFilter={handleSearchTable} />
            <AntMultiselect
              title={
                !filterCompanies.find(company => !company.checked)
                  ? t('Users.AllCompanies')
                  : t('Common.Companies')
              }
              data={filterCompanies}
              onFilter={handleFilterChange(FilterTypes.companies)}
              className={styles.multiselect}
            />
            <AntMultiselect
              title={
                !filterFleets.find(value => !value.checked)
                  ? t('Common.AllFleets')
                  : t('Common.Fleets')
              }
              data={filterFleets}
              onFilter={handleFilterChange(FilterTypes.fleets)}
              className={styles.multiselect}
            />
            {dvirPermission && (
              <AntMultiselect
                title={
                  !filterTypes.find(value => !value.checked)
                    ? t('Common.AllTypes')
                    : t('Common.Types')
                }
                data={filterTypes}
                onFilter={handleFilterChange(FilterTypes.types)}
                className={styles.multiselect}
              />
            )}
            <AntMultiselect
              title={
                !rowFilter.find(value => !value.checked)
                  ? getFilterLabel({ groupBy, t, all: true })
                  : getFilterLabel({ groupBy, t })
              }
              data={rowFilter}
              onFilter={handleFilterChange(FilterTypes.rowFilter)}
              className={styles.multiselect}
            />
            <Select
              size="medium"
              className={styles.groupBy}
              data={groupByItems}
              onSelect={handleGroupByChange}
              value={groupBy}
            />
            <DateRangePicker
              className={styles.dateRangePicker}
              format={localization.formats.time.formats.dby}
              maxDayRange={90}
              defaultDates={[dayjs(dateFrom), dayjs(dateTo)]}
              onDateRangeChanged={dates => handleDateRangeChange(dates)}
              onOpenChange={handleDateRangeClose}
            />
          </Space>
          <div className={styles.counter}>{`${inspectionsCounter} ${
            inspectionsCounter === 1 ? t('Inspections.Counter') : t('Inspections.CounterPlural')
          }`}</div>
        </div>
        <Table
          dataSource={prepareAllDataForTable(filteredInspections, groupBy, fleetIds)}
          columns={prepareColumnsForTable(t, groupBy, expandAllRenderer)}
          pagination={false}
          loading={isInspectionsFetching}
          rowClassName={styles.inspectionTableRow}
          expandable={{
            expandedRowRender: renderRowTable,
            expandIcon: ExpandButton,
            expandRowByClick: true
          }}
          expandedRowKeys={expandAll ? allExpandedRowKeys : expandedRowKeys}
          onExpandedRowsChange={handleExpandedRowsChange}
          scroll={{ y: 'calc(100vh - 270px)' }}
        />
        <Modal
          title={t('Actions.EditNotes')}
          open={isEditNotesModalOpened}
          okText={t('Common.SaveButton')}
          onOk={handleSaveEditNotesModal}
          onCancel={handleCancelEditNotesModal}
        >
          <Input.TextArea rows={10} value={notesInputs} onChange={handleEditNotes} />
        </Modal>

        <Modal
          title={
            <div className={styles.closeInspectionContainer}>
              <ExclamationCircleFilled className={styles.exclamationCircleFilled} />
              <span>{t('Inspections.ConfirmationModal.AreYouSure')}</span>
            </div>
          }
          open={isCloseInspectionModalOpened}
          okText={t('Inspections.ConfirmationModal.CloseInspection')}
          cancelText={t('Inspections.ConfirmationModal.Close')}
          onOk={handleSaveCloseInspection}
          onCancel={handleCancelCloseInspection}
          okButtonProps={{
            disabled: !isCertifyCompleted
          }}
        >
          <div className={styles.inspectionCertifyContainer}>
            <Input.TextArea rows={5} value={notesInputs} onChange={handleEditNotes} />
            <span>{t('Inspections.ConfirmationModal.ModalMessage')}</span>
            <Checkbox checked={isCertifyCompleted} onChange={handleCheckboxChange}>
              {t('Inspections.ConfirmationModal.ModalCertifyRepair')}
            </Checkbox>
          </div>
        </Modal>
      </Layout.Content>
    </Layout>
  );
};
