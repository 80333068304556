import React, { useEffect } from 'react';
import { useUser } from 'features/user/userSlice';
import { useCurrentRegion } from 'features/regions/regionsSlice';
import { useCompanies } from 'features/company/companySlice';
import { useTranslation } from 'react-i18next';
import { AWS_CHAT_OFFICE, AWS_CHAT_ENV } from 'config';
import { REGION_NAMES, REGION_PRIVACY_URL } from './constants';
import { LANGUAGE_LOCALES } from 'features/localization/languages';

export const AWSConnectChat = () => {
  const user = useUser();
  const currentRegion = useCurrentRegion();
  const companies = useCompanies();
  const { t } = useTranslation();
  const companyUser = companies.find(company => company.id === user.companyId);
  const lngStored = (localStorage.getItem('i18nextLng') || LANGUAGE_LOCALES.EN_AU).split('-');
  const currentLanguage = Array.isArray(lngStored) ? lngStored[0] : lngStored;

  useEffect(() => {
    if (!user || !companyUser || !currentRegion || !currentLanguage) {
      return;
    }

    const { firstName, lastName, email } = user;
    const { name: companyName } = companyUser;

    let urlPrivacyNotice;

    let office = AWS_CHAT_OFFICE;

    switch (currentRegion?.name) {
      case REGION_NAMES.AUSTRALIA:
        urlPrivacyNotice = REGION_PRIVACY_URL.AUSTRALIA;
        office = 'AU';
        break;
      case REGION_NAMES.UK:
        urlPrivacyNotice = REGION_PRIVACY_URL.UK;
        break;
      case REGION_NAMES.NZ:
        urlPrivacyNotice = REGION_PRIVACY_URL.NZ;
        office = 'NZ';
        break;
      case REGION_NAMES.US:
        urlPrivacyNotice = REGION_PRIVACY_URL.US;
        break;
      case REGION_NAMES.MEXICO:
        urlPrivacyNotice = REGION_PRIVACY_URL.MEXICO;
        break;
      default:
        urlPrivacyNotice = REGION_PRIVACY_URL.GLOBAL;
    }

    const contactFlowIdParam =
      AWS_CHAT_ENV === 'Prod'
        ? '0f9a3c87-5f20-4012-9274-b4f0e9e3aa4c'
        : '4ce81ec3-3e00-4498-a781-c64ffbcfbf51';
    const instanceIdParam =
      AWS_CHAT_ENV === 'Prod'
        ? 'd44a466b-5fc7-4547-a5c5-87fccca8fae5'
        : '7168f7fd-06ef-4a80-b588-11b167a7451f';
    const apiGatewayParam =
      AWS_CHAT_ENV === 'Prod'
        ? 'https://slpp9dvoxe.execute-api.us-west-2.amazonaws.com/Prod'
        : 'https://6mph9qjctl.execute-api.us-west-2.amazonaws.com/Prod';

    const contactInitialAttr =
      AWS_CHAT_ENV === 'Prod'
        ? {
            Environment: AWS_CHAT_ENV,
            Office: office,
            Customer: companyName,
            Platform: 'TN360',
            Framework: 'TN360'
          }
        : {
            Environment: AWS_CHAT_ENV,
            Office: office,
            Customer: companyName,
            Platform: 'TN360',
            Framework: 'TN360'
          };
    if (!document.querySelector('#awsChatInterface')) {
      const scriptNode = document.createElement('script');
      scriptNode.src = `/awsConnectChat/amazon-connect-chat-interface.js`;
      scriptNode.id = 'awsChatInterface';
      document.head.appendChild(scriptNode);
    }
    if (!document.querySelector('#awsChatScript')) {
      const chatScriptNode = document.createElement('script');
      chatScriptNode.src = '/awsConnectChat/ACChat.js';
      chatScriptNode.id = 'awsChatScript';
      chatScriptNode.onload = () => {
        window.AmazonCustomChatWidget.ChatInterface.init({
          containerId: 'awsChatContainer',
          initiationIcon: 'icon',
          region: 'us-west-2',
          name: 'refer|inputFields|FirstName',
          username: 'refer|inputFields|Username',
          apiGateway: apiGatewayParam,
          contactFlowId: contactFlowIdParam,
          instanceId: instanceIdParam,
          contactAttr: contactInitialAttr,
          preChatForm: {
            visible: true,
            inputFields: [
              {
                name: 'FirstName',
                validation: 'required',
                title: t('Users.Form.FirstName'),
                value: firstName
              },
              {
                name: 'LastName',
                validation: 'required',
                title: t('Users.Form.LastName'),
                value: lastName
              },
              { name: 'Username', validation: 'email', title: t('Users.Form.Email'), value: email }
            ]
          },
          primaryColor: 'rgb(34, 34, 34)',
          description: t('AWSConnectChat.Chat'),
          urlPrivacyNotice: urlPrivacyNotice,
          translationMethod: t
        });
      };
      const chatContainer = document.createElement('div');
      chatContainer.id = 'awsChatContainer';
      document.body.appendChild(chatContainer);
      document.head.appendChild(chatScriptNode);
    }
  }, [user, companyUser, currentRegion, currentLanguage]);

  return <></>;
};
