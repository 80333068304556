import React, { useState, useEffect, useMemo, useCallback } from 'react';
//Hooks
import { useDispatch } from 'react-redux';
import {
  useSpecificRole,
  useIsFetching as isRolesFetching,
  usePermissionsMetadata,
  useEntityPermissionsMetadata,
  useModulePermissionsMetadata
} from 'features/roles/rolesSlice';
import { useUserInfo } from 'features/user/userSlice';
import {
  useSubCompanies,
  useCurrentCompany,
  useRedirectToMainFeaturePageOnCompanyChange
} from 'features/company/companySlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useHistory } from 'react-router';
//Components
import { EntityTable } from './EntityTable';
import { ModuleTable } from './ModuleTable';
import { LoadingTable } from 'components/grid/LoadingTable';
import InfoRow from 'components/form/info-row/InfoRow';
import { Tabs } from 'antd';
import { cache } from './CellRenderers';
import EntityPermissions from './EntityPermissions';
import { PermissionsTypes, PermissionsCriteria, ReservedPermissionTags, PATHS } from './constants';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
//Methods
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { format } from 'utils/dates';
import { removeRole, useRoles } from 'features/roles/rolesSlice';
import { uniqBy } from 'lodash';
import { filterEntityPermissionsBySubjectName } from './helpers';
import { isSuperAdmin } from 'utils/permissions';
//Constants
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
//styles
import styles from './Roles.module.scss';
import { useTranslation } from 'react-i18next';

const getAssignableByRolesValue = (role, roles, t) => {
  if (role && role.features && roles) {
    let assignableByRoles = [];
    role.features.forEach(feature => {
      const parts = feature.split(':');
      if (parts && parts.length > 1 && parts[0] === 'AF') {
        const assignableByRoleId = Number(parts[1]);
        if (assignableByRoleId === -1) assignableByRoles.unshift(role.name);
        else {
          const foundRole = roles.find(role => role.id === assignableByRoleId);
          if (foundRole) assignableByRoles.push(foundRole.name);
        }
      }
    });

    if (assignableByRoles.length > 0) return assignableByRoles.join(', ');
    else return t('Common.All');
  }
};

const RoleView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const roles = useRoles();
  //Get id for individual GET call
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );
  const handleFetchError = useCallback(() => {
    history.replace(PATHS.Default);
  }, [history]);

  const _rawRole = useSpecificRole(id, handleFetchError);
  const role = useMemo(
    () =>
      _rawRole && {
        ..._rawRole,
        permissions: _rawRole?.permissions?.filter(p =>
          ReservedPermissionTags.every(reservedTag => reservedTag !== p?.tag)
        )
      },
    [_rawRole]
  );
  const isFetching = isRolesFetching();
  //All permissions from API
  const permissionsMetadata = usePermissionsMetadata();
  const _rawEntityGlobalPermissions = useEntityPermissionsMetadata();
  const entityGlobalPermissions = useMemo(
    () =>
      _rawEntityGlobalPermissions.filter(p =>
        ReservedPermissionTags.every(reservedTag => reservedTag !== p?.tag)
      ),
    [_rawEntityGlobalPermissions]
  );
  const moduleGlobalPermissions = useModulePermissionsMetadata();
  //Check that user is super admin
  const currentCompanyInfo = useCurrentCompany();
  const currentUser = useUserInfo();
  const canEditGlobalRoles = isSuperAdmin(currentUser, currentCompanyInfo);

  const localization = useLocalization();
  const compaies = useSubCompanies();
  const [currentCompany, setCurrentCompany] = useState();
  useEffect(() => {
    setCurrentCompany(compaies?.find(company => company.id === role?.companyId));
  }, [compaies, role]);

  const [entitySubjects, setEntitySubjects] = useState([]);
  //For Entity Actions Permissions screen
  const [entityActionsIndex, setEntityActionsIndex] = useState(0);
  const [entityActionsPermissions, setEntityActionsPermissions] = useState([]);
  const [entityActionsMetadata, setEntityActionsMetaData] = useState([]);
  const [modules, setModules] = useState([]);

  useRedirectToMainFeaturePageOnCompanyChange('/settings/roles');

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (role?.permissions?.length) {
      const entityPermissions = role.permissions?.filter(
        per => per.type === PermissionsTypes.Entity
      );
      const modulePermissions = role.permissions?.filter(
        per => per.type === PermissionsTypes.Module
      );
      const entitySubjectsNames = uniqBy(entityGlobalPermissions, PermissionsCriteria.Subject)
        .map(per => per.subject)
        .sort();
      const moduleNames = moduleGlobalPermissions.map(module => module.name).sort();
      const entitySubjectObjects = entitySubjectsNames.map(entityName => {
        return {
          name: entityName,
          permissions:
            entityPermissions
              .filter(permission => permission.subject === entityName)
              .map(permission => permission.action) || [],
          maxNumberPermissions: filterEntityPermissionsBySubjectName(
            entityGlobalPermissions,
            entityName
          )?.length,
          viewPermissions: false
        };
      });
      const moduleObjects = moduleNames.map(moduleName => {
        return {
          name: moduleName,
          isChecked: modulePermissions.find(module => module.name === moduleName) ? true : false
        };
      });
      setEntitySubjects(entitySubjectObjects);
      setModules(moduleObjects);
    }
  }, [role, permissionsMetadata]);

  useEffect(() => {
    const activeSubject = entitySubjects.find(subject => subject.viewPermissions);
    const activeSubjectIndex = entitySubjects.findIndex(subject => subject.viewPermissions);
    if (activeSubject && activeSubjectIndex !== -1) {
      setEntityActionsIndex(activeSubjectIndex);
      setEntityActionsPermissions(activeSubject.permissions || []);
      const activeSubjectActionsMetadata = entityGlobalPermissions.filter(
        per => per.subject === activeSubject.name
      );
      setEntityActionsMetaData(activeSubjectActionsMetadata);
    }
  }, [entitySubjects]);

  const handleClickPermissions = name => {
    const entitySubjectsModifiedView = entitySubjects.map(entity => {
      if (entity.name === name) {
        entity.viewPermissions = !entity.viewPermissions;
      } else {
        entity.viewPermissions = false;
      }
      return entity;
    });
    setEntitySubjects(entitySubjectsModifiedView);
  };

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        dispatch(removeRole(role));
        history.goBack();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (role && parseInt(role.id) === parseInt(id)) {
      dispatch(setPageTitle(role.name));
    }
  }, [role, id, dispatch]);

  const [tableRef, setTableRef] = useState(null);
  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [entitySubjects, tableRef]);

  // Get Only Assignable By display value
  const assignableByRolesValue = getAssignableByRolesValue(role, roles, t);

  if (isFetching) {
    return <LoadingTable columnSizes={[400]} numberOfRows={25} />;
  }
  return (
    <>
      {(role?.global === false || canEditGlobalRoles) && (
        <ViewHeaderWrapper
          data={{ entityName: AUDIT_ENTITY.ROLE, ...role }}
          editPath={`/settings/roles/edit/id/${id}`}
          auditPath={`/settings/roles/audit/id/${id}`}
          canUse="ROLE"
          handleButtonAction={handleButtonAction}
          typeOfEntityToDelete={t('Common.role')}
        />
      )}

      <div className={styles.mainWrapper}>
        <div className={styles.view__details}>
          <h5 className={styles.view__heading}>{t('Roles.View.Details')}</h5>
          <InfoRow
            label={t('Roles.View.Company')}
            labelWidth={'30%'}
            sxValue={{ width: '70%' }}
            value={role?.global ? 'Global' : currentCompany?.name}
            styles={styles}
          />
          <InfoRow
            label={t('Roles.View.Description')}
            labelWidth={'30%'}
            sxValue={{ width: '70%' }}
            value={role?.description.replace('NextGen', 'TN360')}
            styles={styles}
          />
          <InfoRow
            label={t('Roles.View.CreatedAt')}
            labelWidth={'30%'}
            sxValue={{ width: '70%' }}
            value={
              role?.createdAt
                ? format(new Date(role.createdAt), localization.formats.time.formats.dby_imp)
                : '-'
            }
            styles={styles}
          />
          <InfoRow
            label={t('Roles.View.UpdatedAt')}
            labelWidth={'30%'}
            sxValue={{ width: '70%' }}
            value={
              role?.updatedAt
                ? format(new Date(role.updatedAt), localization.formats.time.formats.dby_imp)
                : '-'
            }
            styles={styles}
          />
          <InfoRow
            label={t('Roles.Form.AssignableFrom')}
            labelWidth={'30%'}
            sxValue={{ width: '70%' }}
            value={assignableByRolesValue}
            styles={styles}
          />
        </div>
        <div className={styles.view__permissions}>
          <h5 className={styles.view__heading}>{t('Roles.EntityTable.Permissions')}</h5>
          {entitySubjects.find(subject => subject.viewPermissions) && (
            <EntityPermissions
              permissions={entityActionsPermissions}
              permissionsMetadata={entityActionsMetadata}
              index={entityActionsIndex}
              viewPage
            />
          )}
          <Tabs
            type="line"
            animated={false}
            size="large"
            items={[
              {
                label: t('Roles.EntityTable.Entity'),
                key: 'entity',
                children: (
                  <div style={{ height: `${(entitySubjects.length || 3) * 60 + 52}px ` }}>
                    <EntityTable
                      minHeight={(entitySubjects.length || 3) * 60 + 52}
                      subjects={entitySubjects || []}
                      isLoading={isFetching}
                      setTableRef={setTableRef}
                      handleClickPermissions={handleClickPermissions}
                      buttonActionText={t('Roles.EntityTable.View')}
                    />
                  </div>
                )
              },
              {
                label: t('Roles.EntityTable.Module'),
                key: 'module',
                children: (
                  <div style={{ height: `${(modules.length || 3) * 60 + 52}px ` }}>
                    <ModuleTable
                      minHeight={(modules.length || 3) * 60 + 52}
                      modules={modules || []}
                      isLoading={isFetching}
                      setTableRef={setTableRef}
                    />
                  </div>
                )
              }
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default RoleView;
