import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { List, Skeleton, Space, Switch } from 'antd';
import { updateCameraChannels, useCameraChannels } from 'features/camera/cameraSlice';
import { FlipCard, FLIP_DIR } from 'components/visualization/FlipCard';
import { parseErrorMessage } from 'utils/strings';

export function useChannelFlipsUpdater() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [channelFlips, setChannelFlips] = useState(null);
  const [isChannelFlipsChanged, setIsChannelFlipsChanged] = useState(false);

  const onFlip = useCallback(
    (flipDir, flipOn, { channel, name, position, ...initialChannelProps }, initialChannels) => {
      const otherFlipDir = flipDir === FLIP_DIR.hflip ? FLIP_DIR.vflip : FLIP_DIR.hflip;
      setChannelFlips(settings => {
        const currChannelFlip = {
          channel,
          name,
          position,
          [otherFlipDir]: settings?.[channel]
            ? settings[channel][otherFlipDir]
            : initialChannelProps[otherFlipDir],
          [flipDir]: flipOn
        };

        const ret = {
          ...settings,
          [channel]: currChannelFlip
        };

        setIsChannelFlipsChanged(
          initialChannels.some(
            initialChannel =>
              ret[initialChannel.channel] &&
              (ret[initialChannel.channel][flipDir] !== initialChannel[flipDir] ||
                ret[initialChannel.channel][otherFlipDir] !== initialChannel[otherFlipDir])
          )
        );
        return ret;
      });
    },
    []
  );

  const update = useCallback(
    async deviceImei => {
      if (!deviceImei) {
        return {
          updated: false,
          errorMsg: t(`Devices.MultiView.ImeiRequired`)
        };
      }
      const { updated, error } = await dispatch(
        updateCameraChannels({ deviceImei, channels: Object.values(channelFlips) })
      );
      if (updated) {
        setIsChannelFlipsChanged(false);
      }
      return {
        updated,
        errorMsg:
          error &&
          t(`Devices.MultiView.UpdateError`, {
            error: parseErrorMessage(error)
          })
      };
    },
    [dispatch, channelFlips]
  );

  return {
    update,
    isChannelFlipsChanged,
    channelFlips,
    onFlip
  };
}

export const ChannelFlipsField = ({
  deviceImei,
  label,
  channelFlips,
  onFlip = (flipDir, flipOn, channel) => {},
  channelItemStyle,
  bordered = false,
  readOnly = false,
  container
}) => {
  const { t } = useTranslation();
  const { isFetching, channels } = useCameraChannels(deviceImei);
  const Container = useMemo(() => container || (({ children }) => <>{children}</>), []);
  const isFlip = useCallback(
    (channelId, flipDir) =>
      channelFlips?.[channelId]
        ? channelFlips?.[channelId]?.[flipDir]
        : channels?.find(c => c.channel === channelId)?.[flipDir],
    [channels, channelFlips]
  );
  return (
    deviceImei && (
      <Skeleton active loading={isFetching} style={{ width: '100%' }}>
        {channels?.length && (
          <Container>
            {label}
            <List
              size="small"
              itemLayout={readOnly ? 'horizontal' : 'vertical'}
              bordered={bordered}
              dataSource={channels}
              rowKey={'channel'}
              renderItem={channel => (
                <List.Item
                  key={channel.channel}
                  style={channelItemStyle}
                  actions={[FLIP_DIR.hflip, FLIP_DIR.vflip].map(dir => (
                    <Space key={`${channel.channel}-${dir}`}>
                      <span style={{ fontSize: 13, color: readOnly ? 'inherit' : '#000000e0' }}>
                        {t(`Devices.MultiView.${dir}`)}
                      </span>
                      <Switch
                        title={t(`Devices.MultiView.${dir}`)}
                        size="small"
                        defaultChecked={channel[dir]}
                        disabled={readOnly}
                        checked={isFlip(channel.channel, dir)}
                        onChange={flip => onFlip(dir, flip, channel, channels)}
                      />
                    </Space>
                  ))}
                  extra={
                    !readOnly && (
                      <FlipCard
                        size={60}
                        vflip={isFlip(channel.channel, FLIP_DIR.vflip)}
                        hflip={isFlip(channel.channel, FLIP_DIR.hflip)}
                      />
                    )
                  }
                >
                  {readOnly ? (
                    `${channel.name} (${t(`Devices.MultiView.${channel.position}`)})`
                  ) : (
                    <List.Item.Meta
                      description={`${channel.name} (${t(
                        `Devices.MultiView.${channel.position}`
                      )})`}
                    />
                  )}
                </List.Item>
              )}
            />
          </Container>
        )}
      </Skeleton>
    )
  );
};
