import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { TabNavLink } from 'components/nav/NavLinks';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
// import { ReportsStandard } from './ReportsStandard';
// import { ReportSchedule } from './ReportSchedule';
// import { ReportHistory } from './ReportHistory';
import { ReportsStandardNew } from './ReportsStandardNew';
import { ReportHistoryNew } from './ReportHistoryNew';
import { ReportScheduleNew } from './ReportScheduleNew';
import { ReportResult } from './ReportResult';
import { setPageTitle } from 'features/page/pageSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const Reports = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setPageTitle(t('Reports.ReportTitle')));
  }, [dispatch]);

  return (
    <div
      style={{
        display: 'flex',
        flex: '1 0 0',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
    >
      <HeaderPageWrapper>
        <div>
          <TabNavLink
            to="/reports"
            isActive={(match, location) => {
              return (
                ['/reports', '/reports/catalog'].includes(location.pathname) ||
                location.pathname.startsWith('/reports/catalog')
              );
            }}
          >
            {t('Reports.Catalog')}
          </TabNavLink>
          <TabNavLink to="/reports/schedules">{t('Reports.Schedules')}</TabNavLink>
          <TabNavLink to="/reports/history">{t('Reports.History')}</TabNavLink>
        </div>
      </HeaderPageWrapper>

      <Switch>
        <Route exact path={['/reports', '/reports/catalog', '/reports/catalog/:type']}>
          <ReportsStandardNew />
        </Route>
        <Route exact path={['/reports/schedules', '/reports/schedules/:type/:reportId']}>
          <ReportScheduleNew />
        </Route>
        <Route exact path={'/reports/history'}>
          <ReportHistoryNew />
        </Route>
        <Route exact path="/reports/history/:id?source=nextgen">
          <div
            style={{
              display: 'flex',
              flex: '1 0 0'
            }}
          >
            <ReportResult isNextgen={true} />
          </div>
        </Route>
        <Route exact path={'/reports/history/:id'}>
          <div
            style={{
              display: 'flex',
              flex: '1 0 0'
            }}
          >
            <ReportResult isNextgen={false} />
          </div>
        </Route>
      </Switch>
    </div>
  );
};
