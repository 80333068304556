import { getEventAttributesByKey } from 'containers/Tracking/EventTypes';

import { DEFAULT_VIEW_NAME } from 'features/user/userGridSettingsSlice';

import { sortStrings } from 'utils/strings';

export const GRID_SETTING_KEY = 'tracking.track.vehicleGrid';

export const DEFAULT_VEHICLE_VIEW_CONFIG = {
  schemaVersion: 1,
  selectedView: DEFAULT_VIEW_NAME,
  views: {
    Default: {
      // Old schema included for backwards compatibility
      columns: ['vehicle', 'driver', 'lastUpdate', 'location'],
      // New schema for selected columns
      includedColumns: [
        { key: 'vehicle', width: 150 },
        { key: 'driver', width: 150 },
        { key: 'lastUpdate', width: 150 },
        { key: 'location', width: 350 }
      ],
      sort: {
        column: 'lastUpdate',
        order: 'descend'
      },
      groupBy: [],
      filterColumns: [],
      filterEvents: {
        includeAllEventTypes: true,
        includedEventTypes: []
      },
      filterFleets: {
        includeAllFleets: true,
        includedFleetIds: [],
        companyId: null
      },
      eventsGroupByTrips: true
    }
  }
};

export const getConfigOrDefault = userGridSettings => {
  let userGridSettingsConfig = !userGridSettings.config
    ? DEFAULT_VEHICLE_VIEW_CONFIG
    : userGridSettings.config;

  return userGridSettingsConfig;
};

export const getClonedConfigOrDefault = userGridSettings => {
  let userGridSettingsConfig;
  if (!userGridSettings.config) {
    userGridSettingsConfig = JSON.parse(JSON.stringify(DEFAULT_VEHICLE_VIEW_CONFIG)); // deep copy
  } else {
    userGridSettingsConfig = JSON.parse(JSON.stringify(userGridSettings.config)); // deep copy
  }
  return userGridSettingsConfig;
};

export const getIncludedColumns = userGridSettings => {
  let config = getConfigOrDefault(userGridSettings);
  const viewConfig = config.views[config.selectedView];
  let columnsFromConfig = DEFAULT_VEHICLE_VIEW_CONFIG.views.Default.includedColumns;
  let newColumnsSchema = true;

  // New schema that has column keys and widths
  if (viewConfig?.hasOwnProperty('includedColumns') && viewConfig?.includedColumns?.length > 0) {
    columnsFromConfig = viewConfig.includedColumns;
  } else if (viewConfig?.hasOwnProperty('columns') && viewConfig.columns.length > 0) {
    newColumnsSchema = false;
    columnsFromConfig = viewConfig.columns;
  }

  return { newColumnsSchema, columnsFromConfig };
};

export const updateIncludedColumns = (userGridSettings, includedColumns) => {
  let newConfig = getClonedConfigOrDefault(userGridSettings);

  const viewConfig = newConfig.views[newConfig.selectedView];
  viewConfig.includedColumns = includedColumns;

  return newConfig;
};

export const getSort = (userGridSettings, columnsFromConfig) => {
  let config = getConfigOrDefault(userGridSettings);
  const viewConfig = config.views[config.selectedView];

  let sort = DEFAULT_VEHICLE_VIEW_CONFIG.views.Default.sort;

  if (viewConfig?.hasOwnProperty('sort')) {
    sort = viewConfig.sort;

    // If column is not there anymore, default to lastUpdate or vehicle
    if (!columnsFromConfig.find(x => x.key === sort.column)) {
      if (columnsFromConfig.find(x => x.key === 'lastUpdate')) {
        sort = { column: 'lastUpdate', order: 'descend' };
      } else {
        sort = { column: 'vehicle', order: 'ascend' };
      }
    }
  } else {
    // If older config doesn't have sort yet and doesn't have last update default to vehicle column
    if (!columnsFromConfig.find(x => x.key === 'lastUpdate')) {
      sort = { column: 'vehicle', order: 'ascend' };
    }
  }

  return sort;
};

export const updateSort = (userGridSettings, column) => {
  let newConfig = getClonedConfigOrDefault(userGridSettings);

  const viewConfig = newConfig.views[newConfig.selectedView];

  if (viewConfig?.hasOwnProperty('sortBy')) {
    delete viewConfig.sortBy;
  }

  if (!viewConfig?.hasOwnProperty('sort')) {
    viewConfig.sort = { column: column.key, order: 'ascend' };
  } else if (viewConfig.sort.column !== column.key) {
    viewConfig.sort.column = column.key;
    viewConfig.sort.order = 'ascend';
  } else {
    viewConfig.sort.order = viewConfig.sort.order === 'descend' ? 'ascend' : 'descend';
  }

  return newConfig;
};

export const getFilterFleets = userGridSettings => {
  let config = getConfigOrDefault(userGridSettings);

  const viewConfig = config.views[config.selectedView];
  const filterFleets = viewConfig?.filterFleets;

  return filterFleets;
};

export const getGroupByTrips = userGridSettings => {
  let groupByTripsEnabled = true;
  if (userGridSettings.config) {
    const viewConfig = userGridSettings.config.views[userGridSettings.config.selectedView];
    if (viewConfig?.hasOwnProperty('eventsGroupByTrips')) {
      groupByTripsEnabled = viewConfig.eventsGroupByTrips;
    }
  }
  return groupByTripsEnabled;
};

export const updateGroupByTrips = (userGridSettings, eventsGroupByTrips) => {
  let newConfig = getClonedConfigOrDefault(userGridSettings);

  let viewConfig = newConfig.views[newConfig.selectedView];
  viewConfig.eventsGroupByTrips = eventsGroupByTrips;

  return newConfig;
};

export const getFilteredEventTypeKeys = (userGridSettings, allEventTypeKeys) => {
  if (userGridSettings && userGridSettings.config) {
    const viewConfig = userGridSettings.config.views[userGridSettings.config.selectedView];
    if (
      !viewConfig.filterEvents.includeAllEventTypes &&
      viewConfig.filterEvents.includedEventTypes
    ) {
      const includedEventTypes = viewConfig.filterEvents.includedEventTypes;
      return includedEventTypes;
    }
  }

  return allEventTypeKeys;
};

export const getIncludedEventTypes = (newConfig, allEventTypes) => {
  let includeAllEventTypes = true;
  let includedEventTypes = allEventTypes;

  const viewConfig = newConfig.views[newConfig.selectedView];

  if (!viewConfig?.filterEvents?.hasOwnProperty('includeAllEventTypes')) {
    // Backwards compatibility, if no field in config, use defaults
    includeAllEventTypes = true;
    includedEventTypes = allEventTypes; // Default is all event types
  } else {
    includeAllEventTypes = viewConfig.filterEvents.includeAllEventTypes;
    if (includeAllEventTypes) {
      includedEventTypes = allEventTypes;
    } else {
      const eventsTypesFromConfig = viewConfig.filterEvents.includedEventTypes;
      if (eventsTypesFromConfig) {
        includedEventTypes = [];
        eventsTypesFromConfig.forEach(eventTypeFromConfig => {
          const eventType = getEventAttributesByKey(eventTypeFromConfig);
          if (
            eventType?.key &&
            allEventTypes.some(avaliableType => avaliableType.key === eventType.key)
          ) {
            includedEventTypes.push(eventType);
          }
        });
        includedEventTypes = includedEventTypes.sort((a, b) => sortStrings(a?.label, b?.label));
      }
    }
  }

  return { includeAllEventTypes, includedEventTypes };
};

export const updateIncludedEventTypes = (
  newUserGridSettings,
  includeAllEventTypes,
  includedEventTypes
) => {
  const updatedUserGridSettings = JSON.parse(JSON.stringify(newUserGridSettings)); // deep copy

  let viewConfig = updatedUserGridSettings.views[updatedUserGridSettings.selectedView];
  viewConfig.filterEvents.includeAllEventTypes = includeAllEventTypes;
  if (includeAllEventTypes) {
    viewConfig.filterEvents.includedEventTypes = [];
  } else {
    viewConfig.filterEvents.includedEventTypes = includedEventTypes.map(c => c.key);
  }
  return updatedUserGridSettings;
};

export const getViewsGridFilters = (userGridSettings, allColumns) => {
  if (userGridSettings && userGridSettings.config) {
    const viewConfig = userGridSettings.config.views[userGridSettings.config.selectedView];
    let filters = viewConfig.filterColumns;
    filters = filters.filter(filter => allColumns.find(column => column.key === filter.columnKey));
    return filters;
  }

  return [];
};
