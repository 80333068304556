import React from 'react';

import { LoadingTable } from 'components/grid/LoadingTable';
import { Column, Table, AutoSizer } from 'react-virtualized';
import {
  cache,
  nameCellRenderer,
  countryCellRenderer,
  ellipsisButtonCellRenderer
} from './CellRenderers';

import { useTranslation } from 'react-i18next';
import { useCan, entities } from 'features/permissions';
import { useCompanies, useDeletedSubCompanies } from 'features/company/companySlice';
import { useRegions } from 'features/regions/regionsSlice';

export const SubCompaniesTable = ({
  companies,
  isLoading,
  setTableRef,
  typeOfEntityToDelete,
  handleDeleteAction,
  handleAction,
  filterTab
}) => {
  const { t } = useTranslation();
  const can = useCan();
  const fullActiveCompany = useCompanies();
  const fullDeletedCompanies = useDeletedSubCompanies();
  const canView = can && can({ everyEntity: [entities.SUBCOMPANY_VIEW] });
  const regions = useRegions();

  if (isLoading) return <LoadingTable columnSizes={[73, 94, 57, 57, 94, 19]} />;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          name: 350,
          identifier: 300,
          parent: 250,
          externalId: 300,
          ellipsisButton: 60
        };

        const dynamicColumnWidth =
          width -
          (fixedColumnsWidth.identifier +
            fixedColumnsWidth.parent +
            fixedColumnsWidth.externalId +
            fixedColumnsWidth.name +
            fixedColumnsWidth.ellipsisButton);

        const hideDelete = filterTab === 'deleted';
        const hideEdit = filterTab === 'deleted';
        const enableRestoreAction = filterTab === 'deleted';
        return (
          <>
            <Table
              deferredMeasurementCache={cache}
              width={width}
              height={height}
              headerHeight={52}
              headerStyle={{ paddingTop: '18px' }}
              rowClassName="tableRow"
              rowHeight={cache.rowHeight}
              rowCount={companies.length}
              rowGetter={({ index }) => companies[index]}
              ref={ref => setTableRef(ref)}
            >
              <Column
                label={t('SubCompanies.Table.Name')}
                dataKey="name"
                width={fixedColumnsWidth.name}
                cellRenderer={props => nameCellRenderer({ ...props, canView })}
              />
              <Column
                label={t('SubCompanies.Table.Identifier')}
                dataKey="slug"
                width={fixedColumnsWidth.identifier}
              />
              <Column
                label={t('SubCompanies.Table.ParentCompany')}
                dataKey="parentCompanyId"
                width={fixedColumnsWidth.parent}
                cellDataGetter={({ rowData }) =>
                  fullActiveCompany.find(i => i.id === rowData.parent?.id)?.name ||
                  fullDeletedCompanies.find(i => i.id === rowData.parent?.id)?.name ||
                  '-'
                }
              />
              <Column
                label={t('SubCompanies.Table.ExternalId')}
                dataKey="externalId"
                width={fixedColumnsWidth.externalId}
              />
              <Column
                label={t('SubCompanies.Table.Country')}
                dataKey="country"
                width={dynamicColumnWidth}
                cellRenderer={props => countryCellRenderer({ ...props, regions })}
              />
              <Column
                label={t('SubCompanies.Table.Action')}
                dataKey=""
                style={{ overflow: 'visible' }}
                width={fixedColumnsWidth.ellipsisButton}
                cellRenderer={props =>
                  ellipsisButtonCellRenderer({
                    ...props,
                    typeOfEntityToDelete,
                    hideEdit,
                    hideDelete,
                    enableRestoreAction,
                    handleAction,
                    handleDeleteAction
                  })
                }
              />
            </Table>
          </>
        );
      }}
    </AutoSizer>
  );
};
