import React, { useState, useEffect } from 'react';
import { Form, Card, Space, Alert, Switch, Button, Select, Input } from 'antd';
import { BUTTON_IDS } from 'utils/globalConstants';
import { inputValidator } from '../helpers';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';
import { useDispatch } from 'react-redux';
import styles from '../Devices.module.scss';
import styles1 from '../../../Configuration/CompanyConfig/Forms/DriverId/DriverIdForm.module.scss';
import { useCurrentCompany } from 'features/company/companySlice';
import { services } from 'features/permissions';
import { useUpdateConfigsMutation, executeUpdateConfig } from 'services/nextgen/ngConfigKeyApi';
import {
  useSaveTheSettingValuesMutation,
  useGetDriverIdConfigurationTemplatesQuery,
  useSelectedConfigurationTemplateMutation,
  useSettingValueForOneDeviceMutation,
  useCurrentCompanyServicesMap,
  useGetDriverValuesForSelectedTemplateQuery
} from 'services/nextgen/ngDriverIdConfigurationApi';
import { useTranslation } from 'react-i18next';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';

export const DriverId = ({ data, onClose, initialDriverIdValues, selectedId }) => {
  const serviceMap = useCurrentCompanyServicesMap();
  const currentCompany = useCurrentCompany();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const types = useDeviceTypesList();
  const [selectedTemplateName, setSelectedTemplateName] = useState(selectedId?.name);
  const [selectedConfigurationTemplate] = useSelectedConfigurationTemplateMutation();
  const [selectDriverId, setSelectDriverId] = useState(selectedId?.id);
  const { data: selectDriverIdValues } = useGetDriverValuesForSelectedTemplateQuery({
    companyId: currentCompany?.id,
    templateId: selectDriverId || initialDriverIdValues.driverTemplateId
  });

  const [settingValues] = useSettingValueForOneDeviceMutation();
  const [saveTheSettingValues] = useSaveTheSettingValuesMutation();
  const [isTemplateSelected, setIsTemplateSelected] = useState(!!selectedId?.id);
  const [isOverrideSettingsEnabled, setIsOverrideSettingsEnabled] = useState(false);
  const [previousTemplateId, setPreviousTemplateId] = useState(selectedId?.id);
  const [isDropdownEnabled, setIsDropdownEnabled] = useState(true);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const supportedServices = [
    {
      code: services.DRIVERPIN,
      getServiceCodes: () => [services.DRIVERPIN],
      condition: () =>
        data.services?.includes(services.DRIVERPIN) &&
        types.find(type => type.id === data?.type?.id)?.name?.toUpperCase() === 'HERMES'
    }
  ];
  const [updateConfigs] = useUpdateConfigsMutation();

  const { data: DriverIdConfigurationTemplates } = useGetDriverIdConfigurationTemplatesQuery({
    companyId: currentCompany?.id
  });

  const getInitialValues = (selectDriverIdValues, initialDriverIdValues) => {
    return {
      driverTemplateId:
        selectDriverIdValues?.configurationTemplate?.id ||
        initialDriverIdValues.driverTemplateId ||
        '',
      DelayAutoLogOff: parseInt(
        selectDriverIdValues?.configurationTemplate?.configurations?.find(
          config => config.key === 'delayed.logoff'
        )?.value ||
          initialDriverIdValues.DelayAutoLogOff ||
          0
      ),
      DriverLoggedOn: parseInt(
        selectDriverIdValues?.configurationTemplate?.configurations?.find(
          config => config.key === 'driver.loggedoff.threshold'
        )?.value ||
          initialDriverIdValues.DriverLoggedOn ||
          300
      ),
      AudiableAlert:
        selectDriverIdValues?.configurationTemplate?.configurations?.find(
          config => config.key === 'driver.pattern.gpio'
        )?.value === '49932'
          ? true
          : false || initialDriverIdValues.AudiableAlert
    };
  };

  // const initialValues = getInitialValues(selectDriverIdValues, initialDriverIdValues);

  useEffect(() => {
    if (selectDriverIdValues?.configurationTemplate || initialDriverIdValues) {
      const initialValues = getInitialValues(selectDriverIdValues, initialDriverIdValues);
      if (initialValues) {
        form.setFieldsValue(initialValues);
        setSelectedTemplateName(initialValues.driverTemplateId);
        setPreviousTemplateId(initialValues.driverTemplateId);
        if (initialValues.driverTemplateId) {
          setIsOverrideSettingsEnabled(false);
        }
        if (initialValues.driverTemplateId === '') {
          setIsOverrideSettingsEnabled(true);
        }
      }
    }
  }, [selectDriverIdValues, initialDriverIdValues]);

  const onDriverIdConfigurationTemplateChange = (templateId, setFieldValue) => {
    setSelectDriverId(templateId);
    const selectedTemplate = DriverIdConfigurationTemplates.find(
      template => template.configurationTemplate.id === templateId
    );

    setSelectedTemplateName(selectedTemplate?.configurationTemplate?.name || '');
    setPreviousTemplateId(templateId);
    setFieldValue('driverTemplateId', templateId);
    setIsTemplateSelected(!!templateId);
    setIsSaveEnabled(templateId !== previousTemplateId);

    if (templateId) {
      form.setFieldsValue({
        overrideSettings: false,
        DelayAutoLogOff: '',
        DriverLoggedOn: ''
      });
      setIsOverrideSettingsEnabled(false);
      setIsDropdownEnabled(true);
    }

    selectedConfigurationTemplate({ companyId: currentCompany?.id, templateId, id: data.id })
      .then(response => {
        console.log('Template Selection Response:', response);
      })
      .catch(error => {
        console.error('Template Selection Error:', error);
      });
  };

  const handleSaveForm = async () => {
    try {
      const serviceMapInfo = serviceMap['DRIVERPIN'];
      await form.validateFields();

      const configs = [
        {
          device: { id: data.id },
          service: serviceMapInfo.code,
          key: 'delayed.logoff',
          value: form.getFieldValue('DelayAutoLogOff')
        },
        {
          device: { id: data.id },
          service: serviceMapInfo.code,
          key: 'driver.loggedoff.threshold',
          value: form.getFieldValue('DriverLoggedOn')
        },
        {
          device: { id: data.id },
          service: serviceMapInfo.code,
          key: 'driver.pattern.gpio',
          value: form.getFieldValue('AudiableAlert') ? '49932' : '0'
        }
      ];

      if (isOverrideSettingsEnabled) {
        await settingValues({ companyId: currentCompany?.id, id: data.id }).unwrap();
      }

      await saveTheSettingValues({ body: configs, companyId: currentCompany?.id }).unwrap();

      dispatch(
        openToast({
          type: ToastType.Success,
          message: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Success')
        })
      );
    } catch (error) {
      const errorMessage =
        typeof error === 'string' ? error : error.message || JSON.stringify(error);

      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(
            'CompanyConfig.DeviceConfigurations.GPIOTemplates.Error'
          )} ${parseErrorMessage(errorMessage)}`
        })
      );
    }
  };

  const handlePush = () => {
    const servicesToCheck = [services.SPEEDASSIST, services.DRIVERPIN];
    const matchedService = data.services?.find(service => servicesToCheck.includes(service));
    if (matchedService) {
      const payload = {
        deviceId: data.id,
        service:
          supportedServices.find(i => i.code === matchedService)?.getServiceCodes() ||
          matchedService
      };

      executeUpdateConfig(payload, updateConfigs, dispatch);
    }
  };

  const handleOverrideSettingsChange = checked => {
    setIsOverrideSettingsEnabled(checked);
    form.setFieldsValue({ overrideSettings: checked });

    if (checked) {
      form.setFieldsValue({ driverTemplateId: '' });
      setSelectedTemplateName('');
      setIsDropdownEnabled(false);
    } else {
      // When override settings is disabled, restore the previous template
      setIsDropdownEnabled(true);

      setTimeout(() => {
        form.setFieldsValue({ driverTemplateId: previousTemplateId });
        const previousTemplate = DriverIdConfigurationTemplates.find(
          template => template.configurationTemplate.id === previousTemplateId
        );
        setSelectedTemplateName(previousTemplate?.configurationTemplate?.name || '');
        setIsSaveEnabled(previousTemplateId !== selectedId?.id);
      }, 0);
    }
  };

  useEffect(() => {
    if (!isOverrideSettingsEnabled) {
      form.setFieldsValue({ driverTemplateId: previousTemplateId });
      const previousTemplate = DriverIdConfigurationTemplates?.find(
        template => template.configurationTemplate.id === previousTemplateId
      );
      setSelectedTemplateName(previousTemplate?.configurationTemplate?.name || '');
    }
  }, [isOverrideSettingsEnabled, previousTemplateId, DriverIdConfigurationTemplates, form]);

  useEffect(() => {
    form.setFieldsValue({ overrideSettings: isOverrideSettingsEnabled });
  }, [isOverrideSettingsEnabled, form]);

  const handleCancel = () => {
    if (selectDriverIdValues?.configurationTemplate || initialDriverIdValues) {
      const initialValues = getInitialValues(selectDriverIdValues, initialDriverIdValues);

      form.setFieldsValue(initialValues);
      setIsOverrideSettingsEnabled(initialValues.overrideSettings || false);
      setSelectedTemplateName(initialValues.driverTemplateId || '');
      setPreviousTemplateId(initialValues.driverTemplateId || '');
      setIsDropdownEnabled(!initialValues.overrideSettings);
      setIsSaveEnabled(false);
      onClose();
    }
  };

  return (
    <Form form={form} initialValues={initialDriverIdValues} layout="vertical">
      {({ setFieldValue, values, errors }) => (
        <Space direction="vertical" size={16}>
          <Card bodyStyle={{ borderRadius: '8px' }}>
            <h4 className={styles.templateHeader}>
              {t('CompanyConfig.DeviceConfigurations.View.Template')}
            </h4>
            <Alert message={t('Devices.DeviceConfigurations.TemplateAlert')} type="info" showIcon />
            <Form.Item
              label={t('Devices.DeviceConfigurations.SelectedTemplate')}
              style={{ marginTop: '12px' }}
            >
              <Select
                name="driverTemplateId"
                style={{ maxWidth: '38em' }}
                placeholder={t('Devices.DeviceConfigurations.SelectOption')}
                value={
                  isDropdownEnabled && !isOverrideSettingsEnabled ? selectedTemplateName : undefined
                }
                options={DriverIdConfigurationTemplates?.filter(
                  template => template?.configurationTemplate?.status === 'ENABLED'
                )
                  .sort((a, b) =>
                    a?.configurationTemplate?.name?.localeCompare(b?.configurationTemplate?.name)
                  )
                  .map(template => ({
                    label: template?.configurationTemplate?.name,
                    value: template?.configurationTemplate?.id
                  }))}
                onChange={value => onDriverIdConfigurationTemplateChange(value, form.setFieldValue)}
                disabled={!isDropdownEnabled || isOverrideSettingsEnabled}
              />
            </Form.Item>
          </Card>
          <Card bodyStyle={{ padding: '24px', borderRadius: '8px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxHeight: '3em'
              }}
            >
              <div>
                <div className={styles.templateHeader}>{t('Common.Settings')}</div>
              </div>
              <div>
                <Form.Item
                  label={t('Devices.DeviceConfigurations.OverrideSettings')}
                  name="overrideSettings"
                  valuePropName="checked"
                  style={{ top: '6px', position: 'relative' }}
                >
                  <Switch
                    checked={isOverrideSettingsEnabled}
                    onChange={handleOverrideSettingsChange}
                    style={{ position: 'relative', right: '50px', bottom: '33px' }}
                  />
                </Form.Item>
              </div>
            </div>
            <Alert
              message={t('Devices.DeviceConfigurations.OverrideSettingsAlert')}
              type="info"
              showIcon
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '5px'
              }}
            >
              <Form.Item
                className={styles1.setThresholdInput}
                tooltip={t(
                  'CompanyConfig.DeviceConfigurations.DriverIdTemplates.DelayAutoLogOffTooltip'
                )}
                label={t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.DelayAutoLogOff')}
                name="DelayAutoLogOff"
                initialValue={0}
                rules={inputValidator({ min: 0, max: 65535 }).number}
              >
                <Input
                  suffix={<div>{t('Common.seconds')}</div>}
                  disabled={!isOverrideSettingsEnabled}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItemMargin}
                tooltip={t(
                  'CompanyConfig.DeviceConfigurations.DriverIdTemplates.DriverLoggedOnTooltip'
                )}
                label={t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.DriverLoggedOn')}
                name="DriverLoggedOn"
                initialValue={300}
                rules={inputValidator({ min: 10, max: 65535 }).number}
              >
                <Input
                  suffix={<div>{t('Common.seconds')}</div>}
                  disabled={!isOverrideSettingsEnabled}
                />
              </Form.Item>
            </div>
            <Card style={{ backgroundColor: '#f7f8f9' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <Form.Item
                  className={styles.formItemMargin}
                  tooltip={t(
                    'CompanyConfig.DeviceConfigurations.DriverIdTemplates.AudiableAlertTooltip'
                  )}
                  label={t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.AudiableAlert')}
                  name="AudiableAlert"
                  valuePropName="checked"
                >
                  <Switch
                    onChange={checked => form.setFieldValue('AudiableAlert', checked)}
                    disabled={!isOverrideSettingsEnabled}
                  />
                </Form.Item>
              </div>
            </Card>
          </Card>
          <Card
            bodyStyle={{
              padding: '8px',
              borderRadius: '8px',
              position: 'fixed',
              bottom: '50px',
              background: 'white'
            }}
          >
            <Space>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                id={BUTTON_IDS.configureFormSave}
                onClick={handleSaveForm}
                disabled={!isSaveEnabled && !isOverrideSettingsEnabled}
              >
                {t('Common.SaveButton')}
              </Button>
              <Button size="large" id={BUTTON_IDS.configureFormCancel} onClick={handleCancel}>
                {t('Common.CancelButton')}
              </Button>
            </Space>
            <Space>
              <Button
                size="large"
                id={BUTTON_IDS.configureFormPush}
                style={{ marginLeft: '315px' }}
                onClick={handlePush}
              >
                {t('Common.PushButton')}
              </Button>
            </Space>
          </Card>
        </Space>
      )}
    </Form>
  );
};
