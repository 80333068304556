import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import { PATHS } from './constants';
import { Assets } from './Assets';
import { Devices } from './Devices';
import { AssetForm } from './AssetForm';
import { AssetView } from './AssetView';
import { AssetAudits } from './AssetAudits';

export const AssetsRouter = () => {
  const history = useHistory();
  const pathnameSegments = history.location.pathname.split('/');
  const id = pathnameSegments[pathnameSegments.length - 1];
  return (
    <Switch>
      <Route path={PATHS.ASSETS_CONFIGURED_ASSIGNED}>
        <Devices tab="assigned" />
      </Route>
      <Route path={PATHS.ASSETS_CONFIGURED_UNASSIGNED}>
        <Devices tab="unassigned" />
      </Route>
      <Route path={PATHS.ASSETS_CONFIGURED}>
        <Devices />
      </Route>
      <Route path={PATHS.ASSETS_VIEW}>
        <AssetView />
      </Route>
      <Route path={PATHS.ASSETS_DELETED}>
        <Assets tab="deleted" />
      </Route>
      <Route path={PATHS.ASSETS_ADD}>
        <AssetForm action="add" />
      </Route>{' '}
      <Route path={PATHS.ASSETS_EDIT}>
        <AssetForm action="edit" templateId={parseInt(id)} />
      </Route>
      <Route path={PATHS.ASSETS_AUDIT}>
        <AssetAudits />
      </Route>
      <Route path={PATHS.ASSETS_DEFAULT}>
        <Assets />
      </Route>
    </Switch>
  );
};
