import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'utils/api';
import request from 'superagent';
import { API_PATH } from 'config';
import {
  DELETE_CAMERA_FIELDS,
  DELETE_DRIVER_MANAGEMENT_FIELDS,
  DELETE_VEHICLE_MAINTENANCE_FIELDS,
  FETCH_CAMERA_FIELDS,
  FETCH_DRIVER_MANAGEMENT_FIELDS,
  FETCH_VEHICLE_MAINTENANCE_FIELDS,
  UPDATE_CAMERA_FIELDS,
  UPDATE_DRIVER_MANAGEMENT_FIELDS,
  UPDATE_VEHICLE_MAINTENANCE_FIELDS,
  UPDATE_SMARTJOBS_INTEGRATION_CONFIG,
  UPDATE_NON_BUSINESS_CONFIG,
  FETCH_CARRIER_DATA,
  FETCH_MULTICARRIER_FIELDS,
  UPDATE_MULTICARRIER_FIELDS,
  UPDATE_SMART_DASHCAM_CONFIG,
  FETCH_WITHIN_CONFIG,
  DELETE_ONE_WIRE_MULTI_PROBE_FIELDS,
  FETCH_ONE_WIRE_MULTI_PROBE_FIELDS,
  UPDATE_ONE_WIRE_MULTI_PROBE_FIELDS
} from './actions';
import {
  fetchVehicleLoginLimitConfigStart,
  fetchVehicleLoginLimitConfigSuccess,
  fetchVehicleLoginLimitConfigFailure,
  updateVehicleLoginLimitConfigStart,
  updateVehicleLoginLimitConfigSuccess,
  updateVehicleLoginLimitConfigFailure,
  fetchIQCameraConfigStart,
  fetchIQCameraConfigSuccess,
  fetchIQCameraConfigFailure,
  updateIQCameraConfigStart,
  updateIQCameraConfigSuccess,
  updateIQCameraConfigFailure,
  fetchIQCameraDevicesConfigStart,
  fetchIQCameraDevicesConfigSuccess,
  fetchIQCameraDevicesConfigFailure,
  bulkUpdateIQCamerasStart,
  bulkUpdateIQCamerasSuccess,
  bulkUpdateIQCamerasFailure
} from './reducers';
import { toVehicleLoginLimitFormData } from 'containers/Configuration/CompanyConfig/Modals/VehicleLoginLimit/helper';
import moment from 'moment';
import { fetchAudits, fetchVehicleDeviceAssociationAudits } from 'features/audits';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';

const IQ_CAMERA_API_PATH = API_PATH.replace('/v1', '/');
const asyncThunkWrapper = (action, verb = 'get') =>
  createAsyncThunk(action, async (payload = {}, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState();
    const {
      body,
      companyId,
      configId,
      errorToaster,
      successToaster,
      type,
      onSuccessActions
    } = payload;
    const authKey = user?.current?.auth?.key;

    const method = api[verb];
    const url = `/companies/${companyId}/${type}/config`;

    try {
      const response = await method(
        configId ? `${url}/${configId}` : url,
        {
          authKey
        },
        body
      );

      if (onSuccessActions?.length) {
        onSuccessActions.forEach(action => dispatch(action(companyId)));
      }

      if (typeof successToaster === 'function') {
        dispatch(successToaster());
      }

      return response?.statusCode === 200 ? JSON.parse(response?.body.value) : '';
    } catch (err) {
      console.log(err);
      if (typeof errorToaster === 'function') {
        dispatch(errorToaster(err));
      }
      return rejectWithValue(err.response?.body);
    }
  });

export const deleteCameraFields = asyncThunkWrapper(DELETE_CAMERA_FIELDS, 'delete');
export const deleteDriverManagementFields = asyncThunkWrapper(
  DELETE_DRIVER_MANAGEMENT_FIELDS,
  'delete'
);
export const deleteVehicleMaintenanceFields = asyncThunkWrapper(
  DELETE_VEHICLE_MAINTENANCE_FIELDS,
  'delete'
);
export const fetchCameraFields = asyncThunkWrapper(FETCH_CAMERA_FIELDS);
export const fetchCarrierData = asyncThunkWrapper(FETCH_CARRIER_DATA);
export const fetchDriverManagementFields = asyncThunkWrapper(FETCH_DRIVER_MANAGEMENT_FIELDS);
export const fetchVehicleMaintenanceFields = asyncThunkWrapper(FETCH_VEHICLE_MAINTENANCE_FIELDS);
export const updateNonBusinessConfig = asyncThunkWrapper(UPDATE_NON_BUSINESS_CONFIG, 'put');
export const updateSmartJobsIntegrationConfig = asyncThunkWrapper(
  UPDATE_SMARTJOBS_INTEGRATION_CONFIG,
  'put'
);
export const updateCameraFields = asyncThunkWrapper(UPDATE_CAMERA_FIELDS, 'put');
export const updateDriverManagementFields = asyncThunkWrapper(
  UPDATE_DRIVER_MANAGEMENT_FIELDS,
  'put'
);
export const updateVehicleMaintenanceFields = asyncThunkWrapper(
  UPDATE_VEHICLE_MAINTENANCE_FIELDS,
  'put'
);

export const fetchMultiCarrierFields = asyncThunkWrapper(FETCH_MULTICARRIER_FIELDS);
export const updateMultiCarrierFields = asyncThunkWrapper(UPDATE_MULTICARRIER_FIELDS, 'put');

export const updateSmartDashcamConfig = asyncThunkWrapper(UPDATE_SMART_DASHCAM_CONFIG, 'put');

export const deleteOneWireMultiProbeFields = asyncThunkWrapper(
  DELETE_ONE_WIRE_MULTI_PROBE_FIELDS,
  'delete'
);
export const fetchOneWireMultiProbeFields = asyncThunkWrapper(FETCH_ONE_WIRE_MULTI_PROBE_FIELDS);
export const updateOneWireMultiProbeFields = asyncThunkWrapper(
  UPDATE_ONE_WIRE_MULTI_PROBE_FIELDS,
  'put'
);

export const fetchVehicleLoginLimitConfig = companyId => async (dispatch, getState) => {
  dispatch(fetchVehicleLoginLimitConfigStart({ companyId }));
  return request('GET', `${API_PATH}/companies/${companyId}/vehicleloginlimit/config`)
    .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
    .then((resp, error) => {
      if (error) {
        dispatch(fetchVehicleLoginLimitConfigFailure({ error, companyId }));
      } else {
        const config = toVehicleLoginLimitFormData(resp?.body, companyId);
        dispatch(fetchVehicleLoginLimitConfigSuccess({ config, companyId }));
        return config;
      }
    })
    .catch(error => {
      dispatch(fetchVehicleLoginLimitConfigFailure({ error, companyId }));
    });
};

export const updateVehicleLoginLimitConfig = (companyId, config) => async (dispatch, getState) => {
  dispatch(updateVehicleLoginLimitConfigStart({ companyId }));
  return request('PUT', `${API_PATH}/companies/${companyId}/vehicleloginlimit/config`)
    .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
    .set('Content-Type', 'application/json')
    .send({ Config: config })
    .then((resp, error) => {
      if (error || resp.status !== 200) {
        dispatch(updateVehicleLoginLimitConfigFailure({ error, companyId }));
        return { updated: false, error: error || resp.status };
      } else {
        const config = toVehicleLoginLimitFormData(resp?.body, companyId);
        dispatch(updateVehicleLoginLimitConfigSuccess({ config, companyId }));
        return { updated: true };
      }
    })
    .catch(error => {
      dispatch(updateVehicleLoginLimitConfigFailure({ error, companyId }));
      return { updated: false, error };
    });
};

export const fetchIQCameraConfig = ({
  companyId,
  onError = () => {},
  silentFetch = false
}) => async (dispatch, getState) => {
  dispatch(fetchIQCameraConfigStart({ companyId, silentFetch }));

  return request(
    'GET',
    `${IQ_CAMERA_API_PATH}cameras/lightmetrics/configuration?company_id=${companyId}`
  )
    .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
    .then((resp, error) => {
      if (error) {
        if (onError) {
          onError(error, silentFetch);
        }
        dispatch(fetchIQCameraConfigFailure({ error, companyId, silentFetch }));
      } else {
        const config = resp?.body;
        dispatch(fetchIQCameraConfigSuccess({ config, companyId, silentFetch }));
        return config;
      }
    })
    .catch(error => {
      if (onError) {
        onError(error);
      }
      dispatch(fetchIQCameraConfigFailure({ error, companyId, silentFetch }));
    });
};

export const updateIQCameraConfig = ({
  companyId,
  configuration,
  onSuccess = () => {},
  onError = () => {}
}) => async (dispatch, getState) => {
  dispatch(updateIQCameraConfigStart({ companyId }));
  const lastUpdated = getState().companyConfig.iqCameraConfig.companies[companyId]?.config
    ?.lastUpdated;
  return request(
    'PATCH',
    `${IQ_CAMERA_API_PATH}cameras/lightmetrics/configuration?company_id=${companyId}`
  )
    .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
    .set('Content-Type', 'application/json')
    .send({ configuration, lastUpdated })
    .then((resp, error) => {
      if (error || resp.status !== 200) {
        dispatch(updateIQCameraConfigFailure({ error, companyId }));
        if (onError) {
          onError(error);
        }
        return { updated: false, error: error || resp.status };
      } else {
        const config = resp?.body;
        dispatch(updateIQCameraConfigSuccess({ config, companyId }));
        dispatch(
          fetchAudits({ entityName: AUDIT_ENTITY.CAMERA_CONFIGURATION, entityId: companyId })
        );
        if (onSuccess) {
          onSuccess(config);
        }
        return { updated: true };
      }
    })
    .catch(error => {
      if (onError) {
        onError(error);
      }
      dispatch(updateIQCameraConfigFailure({ error, companyId }));
      return { updated: false, error };
    });
};

export const fetchIQCamerDevicesConfig = ({
  companyId,
  deviceIds = [],
  onError = () => {},
  onSuccess = (devices, keyConvertor) => {},
  forceFetch = false
}) => async (dispatch, getState) => {
  if (!deviceIds?.length) {
    return;
  }
  const configs = getState().companyConfig.iqCameraDevicesConfig.companies[companyId]
    ?.devicesConfig;
  const isFetching =
    getState().companyConfig.iqCameraDevicesConfig.companies[companyId]?.isFetching &&
    deviceIds.every(deviceId => !!configs?.hasOwnProperty(deviceId));
  const lastFetchedAt = getState().companyConfig.iqCameraDevicesConfig.companies[companyId]
    ?.fetchedAt;
  const hasValidConfig =
    deviceIds.every(deviceId => !!configs?.[deviceId]?.config) &&
    moment().valueOf() - lastFetchedAt < 15 * 1000;

  if (!forceFetch && isFetching) {
    return;
  }
  if (!forceFetch && hasValidConfig) {
    if (onSuccess) {
      onSuccess(
        deviceIds.map(id => ({
          id,
          config: configs?.[id]?.config
        })),
        key => {
          const keys = {
            dutyType: 'cameraSensitivity',
            deviceAudioVolume: 'volume'
          };
          return keys[key] || key;
        }
      );
    }
    return;
  }
  dispatch(fetchIQCameraDevicesConfigStart({ companyId, deviceIds }));
  return request('GET', `${API_PATH}/configuration/values?device_id=${deviceIds.join(',')}`)
    .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
    .set('Content-Type', 'application/json')
    .then((resp, error) => {
      if (error) {
        if (onError) {
          onError(error);
        }
        dispatch(fetchIQCameraDevicesConfigFailure({ error, companyId, deviceIds }));
      } else {
        const data = resp?.body || [];
        const devices = deviceIds.map(id => {
          const deviceConfigs = data.filter(
            config => parseInt(config?.device?.id, 10) === parseInt(id, 10)
          );
          return {
            id,
            config: deviceConfigs.length
              ? deviceConfigs.reduce((a, config) => {
                  const fieldsToParse = ['audioAlertsEnabled', 'enableDriverCamera'];
                  let value = config.value;

                  if (fieldsToParse.includes(config.key)) {
                    try {
                      value = JSON.parse(value);
                    } catch (error) {}
                  }

                  return { ...a, [config.key]: value };
                }, {})
              : null
          };
        });
        dispatch(fetchIQCameraDevicesConfigSuccess({ devices, companyId }));
        if (onSuccess) {
          onSuccess(devices, key => {
            const keys = {
              dutyType: 'cameraSensitivity',
              deviceAudioVolume: 'volume'
            };
            return keys[key] || key;
          });
        }
        return devices;
      }
    })
    .catch(error => {
      if (onError) {
        onError(error);
      }
      dispatch(fetchIQCameraDevicesConfigFailure({ error, companyId, deviceIds }));
    });
};

export const bulkUpdateIQCamerDevicesConfig = ({
  companyId,
  devices,
  onSuccess = () => {},
  onError = () => {}
}) => async (dispatch, getState) => {
  dispatch(bulkUpdateIQCamerasStart({ companyId }));
  return request('POST', `${API_PATH}/configuration/bulk/values`)
    .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
    .set('Content-Type', 'application/json')
    .send(devices)
    .then((resp, error) => {
      if (error || resp.status !== 200) {
        dispatch(bulkUpdateIQCamerasFailure({ error, companyId }));
        if (onError) {
          onError(error);
        }
        return { updated: false, error: error || resp.status };
      } else {
        const successResults = resp?.body?.results || [];
        const failedResults = resp?.body?.errors || [];
        const configs = devices.map(({ device }) => {
          const deviceConfigs = successResults.filter(
            config => parseInt(config?.device?.id, 10) === parseInt(device.id, 10)
          );
          return {
            id: device.id,
            config: deviceConfigs.length
              ? deviceConfigs.reduce((a, config) => ({ ...a, [config.key]: config.value }), {})
              : null
          };
        });
        dispatch(bulkUpdateIQCamerasSuccess({ devices: configs, companyId }));
        if (onSuccess) {
          onSuccess({ successResults, failedResults });
          dispatch(
            fetchVehicleDeviceAssociationAudits({
              entityName: AUDIT_ENTITY.CAMERA_VEHICLE_DEVICE_ASSOCIATION,
              entityId: companyId
            })
          );
        }
        return { updated: true };
      }
    })
    .catch(error => {
      if (onError) {
        onError(error);
      }
      dispatch(bulkUpdateIQCamerasFailure({ error, companyId }));
      return { updated: false, error };
    });
};

export const fetchWithinConfig = asyncThunkWrapper(FETCH_WITHIN_CONFIG);
