import { createAsyncThunk } from '@reduxjs/toolkit';
import { api, asyncThunk } from 'utils/api';
import i18n from 'i18next';
import {
  FETCH_TRIPS,
  TRIP_PURPOSE,
  BULK_UPLOAD,
  FETCH_TRIP_STATE,
  FETCH_POSITIONS,
  ADD_TRIP_STATE,
  DELETE_TRIP_STATE,
  SET_TRIP_STATE_DEFAULT
} from './actions';
import { splitDatesIntoChunks, formatDatesToIso } from '../../containers/FBTManager/utils';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';

const GET_TRIPS_URL = '/iotrips';
const TRIP_PURPOSE_URL = '/iotrips/purpose';
const TRIP_STATE_URL = '/iotrips/tripstate';
const SET_TRIP_STATE_DEFAULT_URL = '/iotrips/tripstate/default';
const BULK_UPLOAD_URL = '/iotrips/purpose/list';

export const fetchTripStates = asyncThunk(FETCH_TRIP_STATE, TRIP_STATE_URL);
export const setTripStateDefault = asyncThunk(
  SET_TRIP_STATE_DEFAULT,
  SET_TRIP_STATE_DEFAULT_URL,
  'put'
);

export const fetchTrips = createAsyncThunk(
  FETCH_TRIPS,
  async (args = {}, { getState, dispatch, rejectWithValue }) => {
    const { user, companies } = getState();
    const { query } = args;
    const company_id = companies?.current?.id;
    const fbtMultipleCallsFeatureFlag = query?.fbtMultipleCallsFeatureFlag;
    const numberOfApiCallsSplit = 8;

    const multipleDatesArray = splitDatesIntoChunks({
      fromDate: query.from,
      toDate: query.to,
      numberOfApiCallsSplit
    });

    if (fbtMultipleCallsFeatureFlag && multipleDatesArray) {
      const promiseArray = [];
      multipleDatesArray.forEach(date => {
        promiseArray.push(
          new Promise(async (resolve, reject) => {
            try {
              const resp = await api
                .get(
                  GET_TRIPS_URL +
                    (user?.current?.type?.code === 'DRIVER' ? '/user/' + user?.current?.id : ''),
                  {
                    authKey: user?.current?.auth?.key,
                    query: {
                      company_id,
                      from: formatDatesToIso({ from: date.dateFrom, to: date.dateTo }).from,
                      to: formatDatesToIso({ from: date.dateFrom, to: date.dateTo }).to
                    }
                  }
                )
                .then(response => {
                  resolve(response?.body);
                });
            } catch (err) {
              dispatch(
                openToast({
                  type: ToastType.Error,
                  message: `${i18n.t('FBTManager.Toast.ErrorFetchingTrips')}`
                })
              );
              rejectWithValue(err.response?.body);
            }
          })
        );
      });
      const response = await Promise.all(promiseArray);
      // merge all the responses into one array
      return [].concat(...response);
    } else {
      try {
        const response = await api.get(
          GET_TRIPS_URL +
            (user?.current?.type?.code === 'DRIVER' ? '/user/' + user?.current?.id : ''),
          {
            authKey: user?.current?.auth?.key,
            query: {
              company_id,
              from: formatDatesToIso({ from: query.from, to: query.to }).from,
              to: formatDatesToIso({ from: query.from, to: query.to }).to
            }
          }
        );

        return response?.body;
      } catch (err) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: `${i18n.t('FBTManager.Toast.ErrorFetchingTrips')}`
          })
        );
        return rejectWithValue(err.response?.body);
      }
    }
  }
);

export const saveTripPurpose = createAsyncThunk(
  TRIP_PURPOSE,
  async ({ query }, { getState, dispatch, rejectWithValue }) => {
    const { user, companies } = getState();
    const authKey = user?.current?.auth?.key;
    const company_id = companies?.current?.id;
    const { fbtMultipleCallsFeatureFlag = false } = query;

    try {
      const response = await api.put(TRIP_PURPOSE_URL, {
        authKey,
        query: {
          company_id,
          ...query
        }
      });
      dispatch(
        fetchTrips({ query: { from: query.from, to: query.to, fbtMultipleCallsFeatureFlag } })
      );

      return response?.body;
    } catch (err) {
      return rejectWithValue(err.response?.body);
    }
  }
);

export const bulkUploadTripPurposes = createAsyncThunk(
  BULK_UPLOAD,
  async (postBody, { getState, dispatch, rejectWithValue }) => {
    const userKey = getState().user.current.auth.key;
    const company_id = getState().companies?.current?.id;
    try {
      const response = await api.post(
        BULK_UPLOAD_URL,
        { authKey: userKey, query: { company_id } },
        postBody
      );
      if (response?.body) {
        return response.body;
      }
      return;
    } catch (err) {
      const message = err.response?.body?.error || err.message;
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${message}`
        })
      );
      return rejectWithValue(err.response?.body);
    }
  }
);

export const fetchPositions = createAsyncThunk(
  FETCH_POSITIONS,
  async (args = {}, { getState, rejectWithValue }) => {
    const { user } = getState();
    const { query, tripId } = args;

    try {
      const response = await api.get(`/devices/${query.deviceId}/positions`, {
        authKey: user?.current?.auth?.key,
        query
      });

      return {
        id: tripId,
        response: response?.body
      };
    } catch (err) {
      return rejectWithValue(err.response?.body);
    }
  }
);
export const addTripStates = createAsyncThunk(
  ADD_TRIP_STATE,
  async (args = {}, { getState, dispatch, rejectWithValue }) => {
    const { user, companies } = getState();
    const company_id = companies?.current?.id;
    const { tripStates = [] } = args;
    const responses = [];

    for (let i = 0; i < tripStates.length; i++) {
      try {
        const response = await api.post(TRIP_STATE_URL, {
          authKey: user?.current?.auth?.key,
          query: {
            company_id,
            newTripState: tripStates[i]
          }
        });

        responses.push(response?.body);
      } catch (err) {
        responses.push(rejectWithValue(err.response?.body));
      }
    }

    return responses;
  }
);

export const deleteTripStates = createAsyncThunk(
  DELETE_TRIP_STATE,
  async (args = {}, { getState, dispatch, rejectWithValue }) => {
    const { user, companies } = getState();
    const company_id = companies?.current?.id;
    const { tripStates = [] } = args;
    const responses = [];

    for (let i = 0; i < tripStates.length; i++) {
      try {
        const response = await api.delete(
          `${TRIP_STATE_URL}?company_id=${company_id}&deleteTripState=${tripStates[i].replaceAll(
            ' ',
            '%20'
          )}`,
          {
            authKey: user?.current?.auth?.key
          }
        );
        responses.push(response?.body);
      } catch (err) {
        responses.push(rejectWithValue(err.response?.body));
      }
    }

    return responses;
  }
);
