import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { useMemo } from 'react';
import { prepareAuthHeaders } from 'services/util';
import { API_PATH } from 'config';
import { openToast } from 'features/toasts/toastsSlice';
import { parseErrorMessage } from 'utils/strings';
import { ToastType } from 'components/notifications/toasts/Toast';
import i18n from 'i18nextConfig';

export const ngConfigKeyApi = createApi({
  reducerPath: 'ngConfigKeyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_PATH}`,
    prepareHeaders: prepareAuthHeaders
  }),
  endpoints: builder => ({
    getConfigKeys: builder.query({
      query: ({ service }) => ({
        url: `configuration/keys`,
        params: {
          service: service
        }
      })
    }),
    updateConfigs: builder.mutation({
      query: payload => {
        return {
          url: `configuration/push?device_id=${payload.deviceId}&service=${payload.service}`,
          method: 'PUT'
        };
      }
    })
  })
});

export const { useGetConfigKeysQuery, useUpdateConfigsMutation } = ngConfigKeyApi;

export const useGpioRelatedConfigKeys = () => {
  const { isFetching: isVpmFetching, data: vpmData } = useGetConfigKeysQuery({ service: 'VPM' });
  const { isFetching: isBpmFetching, data: bpmData } = useGetConfigKeysQuery({ service: 'BPM' });
  const { isFetching: isDriverFetching, data: driverData } = useGetConfigKeysQuery({
    service: 'DRIVERPIN'
  });
  const { isFetching: isSpeedsirenFetching, data: speedsirenData } = useGetConfigKeysQuery({
    service: 'SPEEDSIREN'
  });
  const { isFetching: isGpioFetching, data: gpioData } = useGetConfigKeysQuery({ service: 'GPIO' });

  return useMemo(() => {
    return {
      vpmData,
      bpmData,
      driverData,
      gpioData,
      speedsirenData,
      isFetching:
        isVpmFetching || isBpmFetching || isDriverFetching || isSpeedsirenFetching || isGpioFetching
    };
  }, [
    vpmData,
    bpmData,
    driverData,
    speedsirenData,
    gpioData,
    isVpmFetching,
    isBpmFetching,
    isDriverFetching,
    isSpeedsirenFetching,
    isGpioFetching
  ]);
};

export const executeUpdateConfig = (payload, updateConfigs, dispatch) => {
  return new Promise((resolve, reject) => {
    updateConfigs(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          dispatch,
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.PushToastMessage', {
            name: payload.name
          }),
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

const handleException = (dispatch, error, reject) => {
  if (error) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseErrorMessage(error.data)
      })
    );
  }
  if (reject) {
    reject(error);
  }
};

const handleSuccessResult = (dispatch, msg, resolve) => {
  dispatch(
    openToast({
      type: ToastType.Success,
      message: msg
    })
  );
  if (resolve) {
    resolve();
  }
};
