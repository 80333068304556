import { createAsyncThunk } from '@reduxjs/toolkit';

import { asyncThunk, api } from 'utils/api';

import {
  FETCH_INSPECTION,
  FETCH_INSPECTIONS,
  ADD_INSPECTION_NOTES,
  CLOSE_INSPECTION
} from './actions';

const INPECTIONS_URL = '/inspections';
export const fetchInspections = asyncThunk(FETCH_INSPECTIONS, INPECTIONS_URL);
export const fetchInspection = asyncThunk(FETCH_INSPECTION, INPECTIONS_URL); // fetch inspection by id

export const addInspectionNotes = createAsyncThunk(
  ADD_INSPECTION_NOTES,
  async (payload = {}, { getState, dispatch, rejectWithValue }) => {
    const authKey = getState().user.current.auth.key;
    const { inspectionId, notes, dateFrom, dateTo } = payload;

    try {
      const response = await api.put(
        `${INPECTIONS_URL}/${inspectionId}/notes`,
        { authKey },
        { notes }
      );
      if (response?.ok) dispatch(fetchInspections({ query: { from: dateFrom, to: dateTo } }));

      return response?.body;
    } catch (err) {
      return rejectWithValue(err.response?.body);
    }
  }
);

export const closeInspection = createAsyncThunk(
  CLOSE_INSPECTION,
  async (payload = {}, { getState, dispatch, rejectWithValue }) => {
    const authKey = getState().user.current.auth.key;
    const { inspectionId, notes } = payload;

    try {
      const response = await api.put(
        `${INPECTIONS_URL}/${inspectionId}/complete`,
        { authKey },
        { notes }
      );
      if (response?.ok) dispatch(fetchInspections());

      return response?.body;
    } catch (err) {
      return rejectWithValue(err.response?.body);
    }
  }
);
