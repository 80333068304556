import { getCalendarStateFromPayload } from 'components/scheduler/helpers';
import i18n from 'i18nextConfig';
import { ALERT_TYPES } from '../../constants';

const getUserAlertName = alertType => {
  switch (alertType) {
    case ALERT_TYPES.CAMERA:
      return i18n.t('Alerts.Camera Alert');
    case ALERT_TYPES.ENGINE:
      return i18n.t('Alerts.Harsh Driving Alert');
    case ALERT_TYPES.FORM:
      return i18n.t('Alerts.Form Alert');
    case ALERT_TYPES.GEOFENCE:
      return i18n.t('Alerts.Geofence Alert');
    case ALERT_TYPES.GEOFENCE_EXPIRY:
      return i18n.t('Alerts.Geofence Expiry Alert');
    case ALERT_TYPES.PRETRIP:
      return i18n.t('Alerts.Pre-Trip Checklist Alert');
    case ALERT_TYPES.ROUTE:
      return i18n.t('Alerts.Route Compliance Alert');
    case ALERT_TYPES.SENTINEL:
      return i18n.t('Alerts.Sentinel Alert');
    case ALERT_TYPES.SPEED:
      return i18n.t('Alerts.Speed Alert');
    case ALERT_TYPES.VEHICLE_MAINTENANCE:
      return i18n.t('Alerts.Vehicle Maintenance Alert');
    case ALERT_TYPES.DRIVER_MANAGEMENT:
      return i18n.t('Alerts.Driver Management Alert');
    case ALERT_TYPES.DRIVER_AND_VEHICLE:
      return i18n.t('Alerts.Driver & Vehicle Alert');
    case ALERT_TYPES.SMARTJOBS:
      return i18n.t('Alerts.Smartjobs Alert');
    case ALERT_TYPES.DOCUMENTEXPIRATION:
      return i18n.t('Alerts.DocumentExpiration Alert');
    case ALERT_TYPES.ELD:
      return i18n.t('Alerts.ELD Alert');
    case ALERT_TYPES.MASS_MANAGEMENT:
      return i18n.t('Alerts.Mass Management Alert');
    case ALERT_TYPES.METER:
      return i18n.t('Alerts.MeterAlert.title');
    case ALERT_TYPES.GPIO:
      return i18n.t('Alerts.GPIO Alert');
    case ALERT_TYPES.DURESS:
      return i18n.t('Alerts.Duress Alert');
    case ALERT_TYPES.TACHO:
      return i18n.t('Alerts.Tacho Alert');
    default:
      return;
  }
};

export const mapUserAlertsAPIResponseToState = (
  alerts,
  localization,
  alertMeterThresholds = {},
  classifiedGeofences = { geofences: [], managedGeofences: [] }
) => {
  const filteredAlerts = alerts.filter(alert =>
    Object.values(ALERT_TYPES).some(type => type === alert.type && type !== ALERT_TYPES.OOH)
  );

  return filteredAlerts.map(alert => {
    const extras = alert?.extras ? JSON.parse(alert?.extras) : {};
    let isThirdAlertEnabled = false;
    let isSecondAlertEnabled = false;
    let secondAlertTimer = 0;
    let thirdAlertTimer = 0;

    if (alert.multiTieredConfig) {
      const secondAlertSetting = alert.multiTieredConfig.find(i => i.level === 2);
      const thirdAlertSetting = alert.multiTieredConfig.find(i => i.level === 3);

      if (secondAlertSetting) {
        secondAlertTimer = secondAlertSetting.timeout / 60 / 1000;
        isSecondAlertEnabled = true;
      }

      if (thirdAlertSetting) {
        thirdAlertTimer = thirdAlertSetting.timeout / 60 / 1000;
        isThirdAlertEnabled = true;
      }
    }

    return {
      id: alert?.id,
      type: alert?.type,
      active: alert?.active,
      expiry: alert?.expiry,
      desktop: alert?.associations?.some(assoc => assoc.onscreen),
      mail: alert?.associations?.some(assoc => assoc.email),
      phone: alert?.associations?.some(assoc => assoc.sms),
      name: getUserAlertName(alert?.type),
      secondAlertTimer,
      thirdAlertTimer,
      isSecondAlertEnabled,
      isThirdAlertEnabled,
      selectedFleets: alert?.associations?.reduce((accumulator, currentValue) => {
        if (currentValue?.fleetId) {
          accumulator.push(currentValue.fleetId);
        }
        return accumulator;
      }, []),
      selectedVehicles: alert?.associations?.reduce((accumulator, currentValue) => {
        if (currentValue?.vehicleId) {
          accumulator.push(currentValue.vehicleId);
        }
        return accumulator;
      }, []),
      selectedDevices: alert?.associations?.reduce((accumulator, currentValue) => {
        if (currentValue?.deviceId) {
          accumulator.push(currentValue.deviceId);
        }
        return accumulator;
      }, []),
      selectedDrivers: alert?.associations?.reduce((accumulator, currentValue) => {
        if (currentValue?.userId) {
          accumulator.push(currentValue.userId);
        }
        return accumulator;
      }, []),
      selectedBranches: alert?.associations?.reduce((accumulator, currentValue) => {
        if (currentValue?.locationId) {
          accumulator.push(currentValue.locationId);
        }
        return accumulator;
      }, []),
      ...(alert?.type === ALERT_TYPES.ENGINE && {
        eventTypes: extras?.event_types || []
      }),
      ...(alert?.type === ALERT_TYPES.FORM && {
        selectedForms: alert?.formAlertAssociations?.map(assoc => assoc.formId)
      }),
      ...(alert?.type === ALERT_TYPES.METER && {
        meterThresholdId:
          alert?.meterThresholdAlertAssociations &&
          alert?.meterThresholdAlertAssociations[0]?.meterThresholdId
      }),
      ...(alert?.type === ALERT_TYPES.METER && {
        deviceType: alertMeterThresholds?.deviceType,
        meterOperation: alertMeterThresholds?.expression?.split(' ')[1],
        meterSource: alertMeterThresholds?.source,
        meterType: alertMeterThresholds?.type,
        meterEntityType: alertMeterThresholds?.entityType,
        meterValue: alertMeterThresholds?.expression?.split(' ')[2]
      }),
      ...(alert?.type === ALERT_TYPES.GEOFENCE && {
        geofencesTypes: {
          entry: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options).entry
            : false,
          exit: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options).exit
            : false,
          thrsh_undertime: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options).thrsh_undertime
            : false,
          thrsh_overtime: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options).thrsh_overtime
            : false
        },
        managedGeofencesTypes: {
          entry: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options).managed_entry
            : false,
          exit: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options).managed_exit
            : false
        },
        selectedGeofences: (
          alert?.geofenceAlertAssociations?.map(g => g.geofenceId) || []
        ).filter(gId => classifiedGeofences.geofences.some(g => String(g.id) === String(gId))),
        selectedManagedGeofences: (
          alert?.geofenceAlertAssociations?.map(g => g.geofenceId) || []
        ).filter(gId =>
          classifiedGeofences.managedGeofences.some(g => String(g.id) === String(gId))
        ),
        speedLimit:
          alert?.associations?.length > 0 &&
          localization?.convertDistance(
            JSON.parse(alert?.associations?.find(assoc => assoc)?.options)?.thrsh_speed || 0
          )
      }),
      ...(alert?.type === ALERT_TYPES.GEOFENCE_EXPIRY && {
        selectedGeofences: (
          alert?.geofenceAlertAssociations?.map(g => g.geofenceId) || []
        ).filter(gId => classifiedGeofences.geofences.some(g => String(g.id) === String(gId)))
      }),
      ...(alert?.type === ALERT_TYPES.PRETRIP && {
        selectedPretrips: alert?.preTripAlertAssociations?.map(assoc => assoc.checklistId),
        inspectionTypes: {
          dvir_failed: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options)?.dvir_failed || false
            : false,
          dvir_passed: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options)?.dvir_passed || false
            : false,
          dvir_repaired: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options)?.dvir_repaired || false
            : false
        }
      }),
      ...(alert?.type === ALERT_TYPES.ROUTE && {
        routeTypes: (alert?.associations?.find(assoc => assoc)?.options &&
          JSON.parse(alert?.associations?.find(assoc => assoc)?.options)) || {
          started: false,
          exit: false,
          finished: false
        }
      }),
      ...(alert?.type === ALERT_TYPES.SENTINEL && {
        fatigueViolationsNotif:
          (alert?.associations?.find(assoc => assoc)?.options &&
            JSON.parse(alert?.associations?.find(assoc => assoc)?.options)) ||
          []
      }),
      ...(alert?.type === ALERT_TYPES.DRIVER_AND_VEHICLE && {
        alarmCodes: extras?.alarm_codes || [],
        eventTypes: extras?.event_types || []
      }),
      ...(alert.type === ALERT_TYPES.ELD && {
        eldCodes:
          (alert?.associations?.find(assoc => assoc)?.options &&
            JSON.parse(alert?.associations?.find(assoc => assoc)?.options)) ||
          []
      }),
      ...(alert.type === ALERT_TYPES.SMARTJOBS && {
        smartjobTypes: {
          completed: extras[`job.completed`] || false,
          partially_completed: extras[`job.partially_completed`] || false,
          non_delivered: extras[`job.non_delivered`] || false
        },
        inputNumberValue: extras['job.driver_feedback'] || 0
      }),
      ...(alert.type === ALERT_TYPES.DOCUMENTEXPIRATION && {
        selectedDocumentExpirationFolders: extras.folders || [],
        inputNumberValue: extras.daystoexpire || 0
      }),
      ...(alert.type === ALERT_TYPES.TACHO && {
        tachoCodes:
          (alert?.associations?.find(as => as)?.options &&
            JSON.parse(alert?.associations?.find(as => as)?.options)) ||
          []
      }),
      ...(alert?.type === ALERT_TYPES.SPEED && {
        speedLimit:
          alert?.associations?.length > 0 &&
          localization?.convertDistance(
            JSON.parse(alert?.associations?.find(assoc => assoc)?.options)?.threshold || 0
          ),
        signposted:
          alert?.associations?.length > 0 &&
          JSON.parse(alert?.associations?.find(assoc => assoc)?.options)?.signposted
            ? 'signposted'
            : []
      }),
      ...(alert?.type === ALERT_TYPES.OOH && {
        eventTypes: (() => {
          const oohSelectedOptions = [];
          if (extras?.io?.enabled) {
            oohSelectedOptions.push('io');
          }
          if (extras?.movement?.enabled) {
            oohSelectedOptions.push('movement');
          }
          if (extras?.geofence?.enabled) {
            oohSelectedOptions.push('geofence');
          }

          return oohSelectedOptions;
        })()
      }),
      ...(alert?.type === ALERT_TYPES.OOH && getCalendarStateFromPayload(alert?.calendar)),
      ...(alert?.type === ALERT_TYPES.GPIO && {
        selectedGpioTypes: extras?.gpio_types || []
      }),
      ...(alert.type === ALERT_TYPES.DURESS && {
        selectedDuressTypes: extras?.duress_types || []
      })
    };
  });
};
