import { ApiClient, AuthApi, UsersApi } from '../nextgen_api';
import { API_PATH } from '../config';
import jwt from 'jsonwebtoken';
import { useSelector } from 'react-redux';

export const COMPANY_TOKEN = 'session_company_key';
export const USER_TOKEN = 'session_user_key';
export const USER = 'session_user';
export const SUPPORT_TYPE = 'supportType';
export const SEARCH_TEXT = 'searchText';
export const ON_STATUS = 'onStatus';
export const TO_DATE = 'toDate';
export const FROM_DATE = 'fromDate';

class Auth {
  // const url = '/v1/auth?format=jwt';
  static login(username, password) {
    const data = {
      referrer: 'app.teletracnavman.com',
      username,
      password
    };

    let apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    const authApi = new AuthApi(apiClient);

    const loginPromise = new Promise((resolve, reject) => {
      authApi.authUser(
        {
          format: 'jwt',
          body: JSON.stringify(data)
        },
        (err, data, resp) => {
          if (err) {
            console.log(err, resp);
            reject(err);
          } else {
            const tokenInfo = jwt.decode(resp.body.auth.token);
            Auth.setUserKey(tokenInfo['user.key']);
            Auth.setCompanyKey(tokenInfo['company.key']);
            let userInfo = resp.body;
            userInfo.companyId = tokenInfo['company.id'];
            Auth.setUser(JSON.stringify(userInfo));
            resolve(userInfo);
          }
        }
      );
    });
    return loginPromise;
  }

  static remoteSignIn(credentials) {
    let apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    const authApi = new AuthApi(apiClient);

    const loginPromise = new Promise((resolve, reject) => {
      authApi.authSSO(
        {
          format: 'jwt',
          userId: credentials.user_id,
          ...credentials
        },
        (err, data, resp) => {
          //needs to check error status since the Api Client SDK throw an error when convert JWT failed.
          //the JWT can get from err.rawResponse.
          if (err && err.status !== 200) {
            console.log(err, resp);
            reject(err);
          } else {
            const tokenInfo = jwt.decode(err.rawResponse || resp.body);
            Auth.setUserKey(tokenInfo['user.key']);
            Auth.setCompanyKey(tokenInfo['company.key']);
            let userInfo = {};
            userInfo.companyId = tokenInfo['company.id'];

            const userApi = new UsersApi(apiClient);
            apiClient.defaultHeaders = {
              Authorization: 'Token token="' + Auth.getUserKey() + '"'
            };
            userApi.findById(tokenInfo['user.id'], (err, data, resp) => {
              if (err != null) {
                console.error(err, resp);
                reject(err);
              } else {
                userInfo = Object.assign(userInfo, data);
                Auth.setUser(JSON.stringify(userInfo));
                resolve(userInfo);
              }
            });
          }
        }
      );
    });
    return loginPromise;
  }

  static isLoggedIn() {
    return !!Auth.getUser();
  }

  static logout() {
    Auth.removeUserKey();
    Auth.removeCompanyKey();
    Auth.removeUser();
    Auth.removeSupportTypeValue();
    Auth.removeOnStatus();
    Auth.removeSearchText();
    Auth.removeFromDate();
    Auth.removeToDate();
  }

  static getUserKey() {
    return sessionStorage.getItem(USER_TOKEN);
  }

  static setUserKey(token) {
    sessionStorage.setItem(USER_TOKEN, token);
  }

  static removeUserKey() {
    sessionStorage.removeItem(USER_TOKEN);
  }

  static getCompanyKey() {
    return sessionStorage.getItem(COMPANY_TOKEN);
  }

  static setCompanyKey(token) {
    return sessionStorage.setItem(COMPANY_TOKEN, token);
  }

  static removeCompanyKey(token) {
    sessionStorage.removeItem(COMPANY_TOKEN);
  }

  static getUser() {
    return sessionStorage.getItem(USER);
  }

  static setUser(user) {
    sessionStorage.setItem(USER, user);
  }

  static removeUser(user) {
    sessionStorage.removeItem(USER);
  }

  static setSupportTypeValue(supportType) {
    sessionStorage.setItem(SUPPORT_TYPE, supportType);
  }

  static removeSupportTypeValue() {
    sessionStorage.removeItem(SUPPORT_TYPE);
  }

  static getSupportTypeValue() {
    return sessionStorage.getItem(SUPPORT_TYPE);
  }

  static setSearchText(searchText) {
    sessionStorage.setItem(SEARCH_TEXT, searchText);
  }

  static removeSearchText() {
    sessionStorage.removeItem(SEARCH_TEXT);
  }

  static getSearchText() {
    return sessionStorage.getItem(SEARCH_TEXT);
  }

  static setOnStatus(onStatus) {
    sessionStorage.setItem(ON_STATUS, onStatus);
  }

  static removeOnStatus() {
    sessionStorage.removeItem(ON_STATUS);
  }

  static getOnStatus() {
    return sessionStorage.getItem(ON_STATUS);
  }

  static setToDate(ToDate) {
    sessionStorage.setItem(TO_DATE, ToDate);
  }

  static removeToDate() {
    sessionStorage.removeItem(TO_DATE);
  }

  static getToDate() {
    return sessionStorage.getItem(TO_DATE);
  }
  static setFromDate(fromDate) {
    sessionStorage.setItem(FROM_DATE, fromDate);
  }

  static removeFromDate() {
    sessionStorage.removeItem(FROM_DATE);
  }

  static getFromDate() {
    return sessionStorage.getItem(FROM_DATE);
  }
}

export default Auth;
