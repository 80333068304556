import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import React from 'react';
import { prepareAuthHeaders } from 'services/util';
import { useMemo } from 'react';
import { API_PATH } from 'config';
import { useCurrentCompany } from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';
import { parseErrorMessage } from 'utils/strings';
import { ToastType } from 'components/notifications/toasts/Toast';
import { Trans } from 'react-i18next';
import i18n from 'i18nextConfig';

export const ngDriverIdConfigurationApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_PATH}`,
    prepareHeaders: prepareAuthHeaders
  }),
  tagTypes: ['driverIdConfigurationTemplate'],
  endpoints: builder => ({
    getDriverIdConfigurationTemplateById: builder.query({
      query: ({ templateId, embed }) => {
        return {
          url: `configuration/DRIVERPIN/template/${templateId}`,
          params: {
            embed: embed
          }
        };
      },
      async onQueryStarted(query, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedData } = await queryFulfilled;
          dispatch(
            ngDriverIdConfigurationApi.util.updateQueryData(
              'getDriverIdConfigurationTemplateById',
              query,
              cache => {
                cache.dataFormatted = true;
                cache.configurationTemplate.configurations?.forEach(c => {
                  c.jsonValue = JSON.parse(c.value);
                });
              }
            )
          );
        } catch {}
      },
      providesTags: ['driverIdConfigurationTemplate']
    }),
    getDriverIdConfigurationTemplates: builder.query({
      query: ({ companyId, embed }) => ({
        url: `configuration/DRIVERPIN/template`,
        params: {
          company_id: companyId,
          embed: embed
        }
      }),
      providesTags: ['driverIdConfigurationTemplate']
    }),
    addDriverIdConfigurationTemplate: builder.mutation({
      query: ({ body }) => ({
        url: `configuration/DRIVERPIN/template`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['driverIdConfigurationTemplate']
    }),
    updateDriverIdConfigurationTemplate: builder.mutation({
      query: ({ id, body }) => ({
        url: `configuration/DRIVERPIN/template/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['driverIdConfigurationTemplate']
    }),
    copyDriverIdConfigurationTemplate: builder.mutation({
      query: payload => {
        const { id, companyIds } = payload;
        let url = '/configuration/DRIVERPIN/template/' + id + '/copy';

        return {
          url,
          method: 'POST',
          body: { targetCompanyIds: companyIds },
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        };
      },
      invalidatesTags: ['driverIdConfigurationTemplate']
    }),
    deleteDriverIdConfigurationTemplateById: builder.mutation({
      query: payload => {
        const { id } = payload;

        return {
          url: `configuration/DRIVERPIN/template/${id}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: (result, error, arg) => {
        return error ? [] : ['driverIdConfigurationTemplate'];
      }
    }),
    restoreDriverIdConfigurationTemplateById: builder.mutation({
      query: payload => {
        const { id } = payload;

        return {
          url: `configuration/DRIVERPIN/template/${id}/restore`,
          method: 'PUT'
        };
      },
      invalidatesTags: ['driverIdConfigurationTemplate']
    }),
    getDriverIdDeviceSelectId: builder.query({
      query: ({ companyId, embed, deviceId }) => {
        return {
          url: `configuration/device/${deviceId}/template`,
          params: {
            company_id: companyId,
            configType: 'DRIVERPIN'
          }
        };
      },
      providesTags: ['driverIdConfigurationTemplate']
    }),
    getDriverValues: builder.query({
      query: ({ deviceId }) => {
        return {
          url: `configuration/values`,
          params: {
            device_id: deviceId,
            service: 'DRIVERPIN'
          }
        };
      },
      providesTags: ['driverIdConfigurationTemplate']
    }),
    getDriverValuesForSelectedTemplate: builder.query({
      query: ({ templateId, companyId }) => {
        return {
          url: `configuration/DRIVERPIN/template/${templateId}`,
          params: {
            company_id: companyId
          }
        };
      },
      providesTags: ['driverIdConfigurationTemplate']
    }),
    selectedConfigurationTemplate: builder.mutation({
      query: ({ companyId, templateId, id }) => ({
        url: `configuration/DRIVERPIN/device/${id}`,
        params: {
          company_id: companyId,
          template_id: templateId
        },
        method: 'PUT'
      }),
      invalidatesTags: ['driverIdConfigurationTemplate']
    }),
    settingValueForOneDevice: builder.mutation({
      query: ({ companyId, id }) => ({
        url: `configuration/DRIVERPIN/device/${id}`,
        params: {
          company_id: companyId
        },
        method: 'PUT'
      }),
      invalidatesTags: ['driverIdConfigurationTemplate']
    }),
    saveTheSettingValues: builder.mutation({
      query: ({ body, companyId }) => ({
        params: {
          company_id: companyId
        },
        url: `configuration/values`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['driverIdConfigurationTemplate']
    })
  })
});

export const {
  useGetDriverIdConfigurationTemplatesQuery,
  useGetDriverIdConfigurationTemplateByIdQuery,
  useAddDriverIdConfigurationTemplateMutation,
  useCopyDriverIdConfigurationTemplateMutation,
  useUpdateDriverIdConfigurationTemplateMutation,
  useRestoreDriverIdConfigurationTemplateByIdMutation,
  useDeleteDriverIdConfigurationTemplateByIdMutation,
  useGetDriverValuesQuery,
  useGetDriverIdDeviceSelectIdQuery,
  useSelectedConfigurationTemplateMutation,
  useSettingValueForOneDeviceMutation,
  useSaveTheSettingValuesMutation,
  useGetDriverValuesForSelectedTemplateQuery
} = ngDriverIdConfigurationApi;

export const executeRestoreDriverIdConfigurationTemplate = (
  payload,
  restoreDriverIdConfigurationTemplateById,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    restoreDriverIdConfigurationTemplateById(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          dispatch,
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ConfigurationRestored', {
            name: payload.name
          }),
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

export const executeDeleteDriverIdConfigurationTemplate = (
  payload,
  deleteDriverIdConfigurationTemplate,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    deleteDriverIdConfigurationTemplate(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          dispatch,
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ConfigurationDeleted', {
            name: payload.name
          }),
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

const handleException = (dispatch, error, reject) => {
  if (error) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseErrorMessage(error.data)
      })
    );
  }

  if (reject) {
    reject(error);
  }
};

const handleSuccessResult = (dispatch, msg, resolve) => {
  dispatch(
    openToast({
      type: ToastType.Success,
      message: msg
    })
  );

  if (resolve) {
    resolve();
  }
};

export const useCurrentCompanyServicesMap = () => {
  const currentCompany = useCurrentCompany();

  return useMemo(() => {
    const serviceMap = currentCompany.features.reduce((map, service) => {
      map[service.code] = service;

      return map;
    }, {});

    return serviceMap;
  }, [currentCompany?.id]);
};

export const executeCopyDriverIdConfigurationTemplate = (
  payload,
  copyDriverIdConfigurationTemplate,
  companies,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    copyDriverIdConfigurationTemplate(payload)
      .unwrap()
      .then(data => {
        const companyIds = data.companyIds;
        const companyName = companies
          .filter(i => companyIds.find(k => k === i.id))
          .map(i => i.name)
          .join(', ');

        if (data.totalError !== 0) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: data?.errors
                ? data?.errors.map((i, index) => {
                    const errorCompany = companies.find(k => k.id === i.companyId);
                    return (
                      <React.Fragment key={index}>
                        {errorCompany.name + ' : ' + i.message}
                        {<br />}
                      </React.Fragment>
                    );
                  })
                : parseErrorMessage(data.data)
            })
          );
          reject(data);
        } else {
          handleSuccessResult(
            dispatch,
            <Trans
              values={{ companies: companyName }}
              i18nKey={
                'CompanyConfig.DeviceConfigurations.GPIOCopyModal.GpioTemplateClonedResponseMessage'
              }
              components={{
                1: <span style={{ color: '#003a8c' }} />
              }}
            />,
            resolve
          );
        }
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

export const executeSetCompanyDriverIdConfigurationTemplate = (
  payload,
  updateDriverIdConfigurationTemplate,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    updateDriverIdConfigurationTemplate(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          dispatch,
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Success', {
            name: payload.name
          }),
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};
