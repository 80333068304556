import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Select, Space, Layout, Form, Tabs, Dropdown, Tooltip, Checkbox } from 'antd';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import {
  AutoSizer,
  Column,
  Table,
  CellMeasurer,
  SortDirection,
  SortIndicator
} from 'react-virtualized';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { LoadingTable } from 'components/grid/LoadingTable';
import { useCurrentCompanyId } from 'features/company/companySlice';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import {
  bulkUpdateIQCamerDevicesConfig,
  useBulkUpdateIQCamerasStatus,
  useIQCameraDevicesConfigFetchStatus
} from 'features/company_config';

import {
  getDifferentValues,
  hasDifferentValues,
  getDirtyCheckConfirmProps,
  DUTY_TYPE
} from '../helper';
import { vehiclesCache, devicesCache, ENTITY_TABS, toIQCameraBulkUpdatePayload } from './helper';
import { useIQCameraBulkEdit } from './useIQCameraBulkEdit';

import styles from '../IQCamera.module.scss';
import { LoadingCentered } from 'components/loading/Loading';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useCan } from 'features/permissions';

const { Option } = Select;
const { Content, Footer } = Layout;

export const BulkEditForm = ({ formMeta, setFormMeta }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const can = useCan();

  const { onFetchError, onUpdateError, onUpadteSuccess } = useMemo(
    () => ({
      onFetchError: error => {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('CompanyConfig.Notifications.BulkEdit.DevicesConfigFetchError', { error })
          })
        );
      },
      onUpdateError: error => {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('CompanyConfig.Notifications.BulkEdit.DevicesConfigUpdateError', { error })
          })
        );
      },
      onUpadteSuccess: ({ successResults, failedResults, entity }) => {
        const failedCount = failedResults?.length || 0;
        if (!failedCount) {
          //All success
          dispatch(
            openToast({
              type: ToastType.Success,
              message: t('CompanyConfig.Notifications.BulkEdit.DevicesConfigUpdateSuccess')
            })
          );
        } else if (failedCount > 0) {
          //partially failed or All failed
          const entityName =
            entity === ENTITY_TABS.VEHICLE.entity
              ? failedCount > 1
                ? t(ENTITY_TABS.VEHICLE.label)
                : t('Entity.Vehicle')
              : failedCount > 1
              ? t(ENTITY_TABS.DEVICE.label)
              : t('Entity.DEVICE');
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t('CompanyConfig.Notifications.BulkEdit.DevicesConfigUpdatePartiallyError', {
                count: failedCount,
                entity: entityName
              })
            })
          );
        }
      }
    }),
    [t, dispatch]
  );

  const currentCompanyId = useCurrentCompanyId();
  const [form] = Form.useForm();
  const [bulkUpdateResult, setBulkUpdateResult] = useState({
    successResults: [],
    failedResults: []
  });
  const getEntityUpdatedFailedInfo = useCallback(
    ({ entity, entityData }) => {
      if (!bulkUpdateResult.failedResults?.length) {
        return {
          isUpdatedFailed: false
        };
      }
      const deviceId =
        entity === ENTITY_TABS.VEHICLE.entity ? entityData.iqCamera?.id : entityData.id;
      const matchedFailedDevice = bulkUpdateResult.failedResults.find(
        failedDevice => parseInt(failedDevice.id, 10) === parseInt(deviceId, 10)
      );
      if (matchedFailedDevice) {
        return {
          isUpdatedFailed: true,
          failedMessage: matchedFailedDevice.message || t('Home.CameraConfigUpdateFailed')
        };
      }
      return {
        isUpdatedFailed: false
      };
    },
    [t, bulkUpdateResult]
  );

  const {
    entityData,
    setCurrentTab,
    onSearch,
    filters,
    setDutyType,
    formData,
    multiSelections,
    resetFilters,
    isLaodingEntityData,
    sort,
    setSort
  } = useIQCameraBulkEdit({ onFetchError, form });

  const [checkedEntities, setCheckedEntities] = useState(new Set());

  const resetCheckedEntities = useCallback(() => {
    setCheckedEntities(new Set());
  }, []);

  const CheckableCell = useCallback(
    ({ children, isSelectAll, entityId }) => {
      const indeterminate =
        isSelectAll && checkedEntities.size && checkedEntities.size !== entityData?.length;
      const checked = isSelectAll
        ? checkedEntities.size && checkedEntities.size === entityData?.length
        : checkedEntities.has(String(entityId));
      return (
        <Checkbox
          id={`${filters.currentTab}-${entityId || 'ALL'}`}
          style={{ color: 'inherit', fontSize: 'inherit' }}
          className={styles.headerLabel}
          onClick={e => e.stopPropagation()}
          onChange={e => {
            const checked = e.target.checked;
            if (isSelectAll) {
              setCheckedEntities(
                checked
                  ? new Set(
                      entityData.map(item =>
                        String(
                          filters.currentTab === ENTITY_TABS.VEHICLE.entity
                            ? item.iqCamera.id
                            : item.id
                        )
                      )
                    )
                  : new Set()
              );
            } else {
              setCheckedEntities(records => {
                records = new Set(records);
                if (checked) {
                  records.add(String(entityId));
                } else {
                  records.delete(String(entityId));
                }
                return records;
              });
            }
          }}
          indeterminate={indeterminate}
          checked={checked}
          disabled={!entityData?.length}
        >
          {children}
        </Checkbox>
      );
    },
    [entityData, filters.currentTab, checkedEntities]
  );

  const fetchStatus = useIQCameraDevicesConfigFetchStatus(currentCompanyId);
  const hasFetchError = useMemo(
    () => fetchStatus && fetchStatus.fetchedAt && fetchStatus.fetchError,
    [fetchStatus]
  );
  const updateStatus = useBulkUpdateIQCamerasStatus(currentCompanyId);

  const isFormProcessing = useMemo(() => !!updateStatus?.isUpdating || isLaodingEntityData, [
    isLaodingEntityData,
    updateStatus
  ]);

  const onBulkAssign = useCallback(
    dutyToApply => {
      setDutyType(dutyToApply);
      const currentTabFormValues = form.getFieldValue(filters.currentTab);
      form.setFieldValue(
        filters.currentTab,
        Object.keys(currentTabFormValues)
          .filter(
            entityId =>
              entityData.some(
                item =>
                  String(
                    filters.currentTab === ENTITY_TABS.VEHICLE.entity ? item.iqCamera.id : item.id
                  ) === String(entityId)
              ) && checkedEntities.has(String(entityId))
          )
          .reduce(
            (a, entityId) => ({
              ...a,
              [entityId]: {
                ...currentTabFormValues[entityId],
                cameraSensitivity: dutyToApply
              }
            }),
            {}
          )
      );
      setFormMeta(prev => ({
        ...prev,
        isDirty: hasDifferentValues(
          form.getFieldValue(filters.currentTab),
          formData?.[filters.currentTab]
        )
      }));
      resetCheckedEntities();
    },
    [
      setDutyType,
      form,
      filters.currentTab,
      formData,
      entityData,
      checkedEntities,
      resetCheckedEntities
    ]
  );

  const onSubmit = useCallback(
    async (withConfirmation = true) => {
      const allValues = form.getFieldsValue(true);
      const changedValues = getDifferentValues(allValues, formData) || {};
      const count = Object.keys(changedValues).length;
      const entity =
        filters.currentTab === ENTITY_TABS.VEHICLE.entity
          ? count > 1
            ? t(ENTITY_TABS.VEHICLE.label)
            : t('Entity.Vehicle')
          : count > 1
          ? t(ENTITY_TABS.DEVICE.label)
          : t('Entity.Device');

      if (withConfirmation) {
        confirmationModal(
          t('Common.Modal.SureTitle'),
          t('CompanyConfig.IQCamera.BULK_EDIT.SureBulkEdit', { count, entity }),
          t('Common.Modal.Confirm'),
          t('Common.Modal.Cancel'),
          () => {
            setBulkUpdateResult({ successResults: [], failedResults: [] });
            dispatch(
              bulkUpdateIQCamerDevicesConfig({
                companyId: currentCompanyId,
                devices: toIQCameraBulkUpdatePayload(changedValues),
                onError: onUpdateError,
                onSuccess: ({ successResults, failedResults }) => {
                  onUpadteSuccess({ successResults, failedResults, entity: filters.currentTab });
                  setBulkUpdateResult({ successResults, failedResults });
                  setFormMeta(prev => ({
                    ...prev,
                    isDirty: false
                  }));
                  resetFilters(filters.currentTab);
                  resetCheckedEntities();
                }
              })
            );
          },
          'warning'
        );
      } else {
        setBulkUpdateResult({ successResults: [], failedResults: [] });
        return dispatch(
          bulkUpdateIQCamerDevicesConfig({
            companyId: currentCompanyId,
            devices: toIQCameraBulkUpdatePayload(changedValues),
            onError: onUpdateError,
            onSuccess: ({ successResults, failedResults }) => {
              onUpadteSuccess({ successResults, failedResults, entity: filters.currentTab });
              setBulkUpdateResult({ successResults, failedResults });
              setFormMeta(prev => ({
                ...prev,
                isDirty: false
              }));
              resetFilters(filters.currentTab);
              resetCheckedEntities();
            }
          })
        );
      }
    },
    [
      dispatch,
      currentCompanyId,
      onUpadteSuccess,
      onUpdateError,
      formData,
      form,
      filters.currentTab,
      resetFilters,
      resetCheckedEntities
    ]
  );

  const onTabChange = useCallback(
    tab => {
      if (formMeta.isDirty) {
        const self = confirmationModal(
          ...getDirtyCheckConfirmProps({ t }),
          async () => {
            const { updated } = await onSubmit(false);
            if (updated) {
              setCurrentTab(tab);
              resetFilters(tab);
            }
          },
          'warning',
          null,
          null,
          null,
          {
            footer: (_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                {
                  <Button
                    onClick={() => {
                      form.resetFields();
                      setCurrentTab(tab);
                      setFormMeta(prev => ({
                        ...prev,
                        isDirty: false
                      }));
                      resetFilters(tab);
                      resetCheckedEntities();
                      self.destroy();
                    }}
                  >
                    {t('RouteGuard.LeavePage')}
                  </Button>
                }
                <OkBtn />
              </>
            )
          }
        );
      } else {
        setCurrentTab(tab);
        resetFilters(tab);
        resetCheckedEntities();
      }
    },
    [t, setCurrentTab, formMeta, form, resetFilters, resetCheckedEntities.onSubmit]
  );

  useEffect(() => {
    setFormMeta(prev => ({ ...prev, onSubmit }));
  }, [onSubmit]);

  useEffect(() => {
    form.setFieldsValue(formData);
  }, [formData, form]);

  const disableSaveBtn = useMemo(() => isFormProcessing || !formMeta.isDirty, [
    isFormProcessing,
    formMeta.isDirty
  ]);
  const confirmOnLeave = useMemo(() => formMeta.isDirty, [formMeta.isDirty]);

  const [cancelConfirmed, setCancelConfirmed] = useState(false);

  const applyRouteGuard = useMemo(() => confirmOnLeave && !cancelConfirmed, [
    cancelConfirmed,
    confirmOnLeave
  ]);

  const confirmModalProps = useMemo(() => {
    const [title, message, leaveBtnText, stayBtnText] = getDirtyCheckConfirmProps({ t });
    return {
      title,
      message,
      leaveBtnText,
      stayBtnText,
      getFooter: ({ OkBtn, CancelBtn, modalSelf, handleConfirmNavigationClick }) => (
        <>
          <CancelBtn />
          <Button
            onClick={() => {
              handleConfirmNavigationClick();
              modalSelf.destroy();
            }}
          >
            {t('RouteGuard.LeavePage')}
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              const { updated } = await onSubmit(false);
              if (updated) {
                handleConfirmNavigationClick();
              }
              modalSelf.destroy();
            }}
          >
            {t('RouteGuard.SaveAndLeavePage')}
          </Button>
        </>
      )
    };
  }, [t, onSubmit]);

  const onCancel = useCallback(() => {
    if (confirmOnLeave) {
      const modalSelf = confirmationModal(
        confirmModalProps.title,
        confirmModalProps.message,
        confirmModalProps.leaveBtnText,
        confirmModalProps.stayBtnText,
        () => {
          setCancelConfirmed(true);
          history.goBack();
        },
        'warning',
        null,
        null,
        null,
        confirmModalProps.getFooter
          ? {
              footer: (_, { OkBtn, CancelBtn }) =>
                confirmModalProps?.getFooter({
                  OkBtn,
                  CancelBtn,
                  modalSelf,
                  handleConfirmNavigationClick: () => {
                    setCancelConfirmed(true);
                    history.goBack();
                  }
                })
            }
          : null
      );
    } else {
      history.goBack();
    }
  }, [confirmModalProps, history, confirmOnLeave, setCancelConfirmed]);

  const [tableRef, setTableRef] = useState(null);

  useEffect(() => {
    if (tableRef) {
      devicesCache.clearAll();
      vehiclesCache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [filters, tableRef, entityData]);

  const renderTabBar = useCallback(
    (props, DefaultTabBar) => (
      <EntityTabBar
        t={t}
        tab={<DefaultTabBar {...props} />}
        bulkAssignDisabled={!checkedEntities.size}
        onBulkAssign={onBulkAssign}
        currentTab={filters.currentTab}
      />
    ),
    [t, checkedEntities, onBulkAssign, filters.currentTab]
  );

  if (isLaodingEntityData && formData) {
    return <LoadingCentered />;
  }

  return (
    <>
      <EditRouteGuard
        when={applyRouteGuard}
        navigate={history.push}
        confirmModalProps={confirmModalProps}
      />
      <Form
        initialValues={formData}
        form={form}
        onFieldsChange={_ =>
          setFormMeta(prev => ({
            ...prev,
            isDirty: hasDifferentValues(
              form.getFieldValue(filters.currentTab),
              formData?.[filters.currentTab]
            )
          }))
        }
        className={styles.iqCameraForm}
        colon={false}
        requiredMark={false}
        size="large"
        style={{ height: '100%' }}
      >
        <Layout className={styles.bulkEditTab}>
          <Layout>
            <Content>
              <Tabs
                activeKey={filters.currentTab}
                onChange={onTabChange}
                className={styles.subTabs}
                renderTabBar={renderTabBar}
                items={Object.values(ENTITY_TABS).map(({ entity, label }) => ({
                  key: entity,
                  label: t(label),
                  style: { height: '100%' },
                  id: `${entity}-Tab`,
                  children: (
                    <EntityAssociationTable
                      t={t}
                      entityData={entityData}
                      entity={entity}
                      onSearch={onSearch}
                      multiSelections={multiSelections}
                      entityCols={ENTITY_TABS[entity].cols.filter(col =>
                        col.can ? can(col.can) : true
                      )}
                      setTableRef={setTableRef}
                      preventEditing={hasFetchError}
                      getEntityUpdatedFailedInfo={getEntityUpdatedFailedInfo}
                      CheckableCell={CheckableCell}
                      resetCheckedEntities={resetCheckedEntities}
                      sort={sort}
                      setSort={setSort}
                      enableSort={true}
                    />
                  )
                }))}
              />
            </Content>
          </Layout>
          <Footer className={styles.tabFooter}>
            <Space size={[16, 0]}>
              <Button
                id={BUTTON_IDS.iqBulkEditSave}
                size="large"
                type="primary"
                disabled={disableSaveBtn}
                onClick={() => onSubmit()}
              >
                {t('Common.Save')}
              </Button>
              <Button id={BUTTON_IDS.iqBulkEditCancel} size="large" onClick={onCancel}>
                {t('Common.Cancel')}
              </Button>
            </Space>
          </Footer>
        </Layout>
      </Form>
    </>
  );
};

const EntityTabBar = ({ t, tab, bulkAssignDisabled, currentTab, onBulkAssign = () => {} }) => {
  return (
    <div className={styles.entityTabBar}>
      {tab}
      <Dropdown
        disabled={bulkAssignDisabled}
        menu={{
          onClick: ({ key }) => onBulkAssign(key),
          items: Object.keys(DUTY_TYPE).map(type => ({
            label: <div className={styles.dropdownItem}>{t(`CompanyConfig.IQCamera.${type}`)}</div>,
            key: type
          }))
        }}
      >
        <Tooltip
          title={
            bulkAssignDisabled &&
            t('CompanyConfig.IQCamera.BULK_EDIT.AssignTooltip', {
              entity: t(
                currentTab === ENTITY_TABS.VEHICLE.entity ? 'Vehicles.Vehicles' : 'Devices.Devices'
              )
            })
          }
        >
          <Button
            id="bulkAssignDropdown"
            className={styles.entityTabBarBtn}
            disabled={bulkAssignDisabled}
          >
            <Space>
              {t('CompanyConfig.IQCamera.BULK_EDIT.AssignCameraSensitivity')}
              <i className={'tn-i-chevron-down'} />
            </Space>
          </Button>
        </Tooltip>
      </Dropdown>
    </div>
  );
};

const EntityAssociationTable = ({
  t,
  entity,
  entityData = [],
  entityCols = [],
  multiSelections = [],
  onSearch = () => {},
  setTableRef,
  preventEditing = false,
  getEntityUpdatedFailedInfo = () => ({}),
  CheckableCell,
  resetCheckedEntities,
  sort,
  setSort,
  enableSort = false
}) => {
  return (
    <>
      <EntityTableFilterBar
        t={t}
        entity={entity}
        entityCount={entityData.length}
        onSearch={onSearch}
        multiSelections={multiSelections}
        onFilter={resetCheckedEntities}
      />
      <EntityTable
        t={t}
        entity={entity}
        entityData={entityData}
        entityCols={entityCols}
        setTableRef={setTableRef}
        preventEditing={preventEditing}
        getEntityUpdatedFailedInfo={getEntityUpdatedFailedInfo}
        CheckableCell={CheckableCell}
        sort={sort}
        setSort={setSort}
        enableSort={enableSort}
      />
    </>
  );
};

const EntityTableFilterBar = ({
  t,
  onSearch = () => {},
  multiSelections = [],
  entityCount = 0,
  entity,
  onFilter = () => {}
}) => {
  return (
    <div style={{ display: 'flex', background: '#f7f8f9' }}>
      <FilterWrapper>
        <AntSearchbar
          id={`${entity}-search`}
          onFilter={props => {
            onSearch(props);
            onFilter();
          }}
        />
        {multiSelections.map(({ title, data, onSelect }, index) => (
          <AntMultiselect
            key={`entity-multi-selection-${index}`}
            title={title}
            id={`${entity}-multiSelection-${index}`}
            onFilter={props => {
              onSelect(props);
              onFilter();
            }}
            data={data}
          />
        ))}
      </FilterWrapper>
      <label
        style={{
          display: 'flex',
          width: '100%',
          marginBottom: 0,
          paddingRight: '20px',
          alignItems: 'center',
          justifyContent: 'flex-end',
          minHeight: '52px'
        }}
      >
        {entityCount}{' '}
        {entityCount === 1
          ? t(entity === ENTITY_TABS.VEHICLE.entity ? 'Vehicles.Vehicle' : 'Devices.Device')
          : t(entity === ENTITY_TABS.VEHICLE.entity ? 'Vehicles.Vehicles' : 'Devices.Devices')}
      </label>
    </div>
  );
};

const EntityTable = ({
  t,
  entityData = [],
  entityCols = [],
  entity,
  setTableRef,
  preventEditing = false,
  getEntityUpdatedFailedInfo = () => ({}),
  CheckableCell,
  sort,
  setSort,
  enableSort = false
}) => {
  const cache = entity === ENTITY_TABS.VEHICLE.entity ? vehiclesCache : devicesCache;
  if (preventEditing) {
    return <LoadingTable columnSizes={[73, 94, 57, 57, 94, 19]} />;
  }
  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <Table
            id={`${entity}-Table`}
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            rowClassName={styles.tableRow}
            rowHeight={cache.rowHeight}
            rowCount={entityData.length}
            rowGetter={({ index }) => entityData[index]}
            rowStyle={{ alignItems: 'flex-start' }}
            ref={ref => setTableRef(ref)}
            sortBy={sort.sortBy}
            sortDirection={sort.sortDirection}
            sort={({ sortBy }) => {
              if (!enableSort) {
                return;
              }
              if (sortBy !== 'fleets') {
                setSort(prevSort => ({
                  sortBy,
                  sortDirection:
                    prevSort.sortDirection === SortDirection.DESC
                      ? SortDirection.ASC
                      : SortDirection.DESC
                }));
              }
            }}
          >
            {entityCols.map(
              (
                { label, width: colWidth, dataKey, getValue, formFieldProps = null, sortable },
                index
              ) => (
                <Column
                  key={`auto-col-${index}`}
                  label={t(label)}
                  dataKey={dataKey}
                  width={width * colWidth}
                  headerStyle={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    minHeight: '24px'
                  }}
                  headerRenderer={({ dataKey, label, sortBy, sortDirection }) => (
                    <>
                      {dataKey === 'name' ? (
                        <CheckableCell isSelectAll>{label}</CheckableCell>
                      ) : (
                        <span className={styles.headerLabel}>{label}</span>
                      )}
                      {enableSort && sortable && (
                        <Tooltip
                          title={t(
                            `AntDesign.Table.${
                              sortDirection === SortDirection.DESC ? 'TriggerAsc' : 'TriggerDesc'
                            }`
                          )}
                        >
                          <span
                            className={dataKey === sortBy ? styles.sortIconActive : styles.sortIcon}
                          >
                            <SortIndicator
                              key="SortIndicator"
                              sortDirection={dataKey === sortBy ? sortDirection : null}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </>
                  )}
                  cellRenderer={cellProps => (
                    <CellMeasurer
                      cache={cache}
                      columnIndex={cellProps.columnIndex}
                      key={`${dataKey}-${cellProps.key}`}
                      parent={cellProps.parent}
                      rowIndex={cellProps.rowIndex}
                    >
                      <EntityCellRenderer
                        cellId={`${dataKey}-${cellProps.rowIndex}`}
                        entityValue={getValue(
                          cellProps,
                          getEntityUpdatedFailedInfo({ entity, entityData: cellProps.rowData }),
                          CheckableCell
                        )}
                        entityFormField={
                          formFieldProps?.getFieldName &&
                          formFieldProps.getFieldName(cellProps, entity)
                            ? {
                                ...formFieldProps,
                                fieldName: formFieldProps.getFieldName(cellProps, entity),
                                ...(typeof formFieldProps.options === 'function'
                                  ? { options: formFieldProps.options({ t }) }
                                  : {})
                              }
                            : null
                        }
                      />
                    </CellMeasurer>
                  )}
                />
              )
            )}
          </Table>
        );
      }}
    </AutoSizer>
  );
};

const EntityCellRenderer = ({ entityValue, entityFormField = null, cellId }) => {
  if (entityFormField) {
    const { fieldName, options = [] } = entityFormField;
    return (
      <Form.Item name={fieldName}>
        <Select id={cellId}>
          {options.map(({ value, label }, index) => (
            <Option key={`${fieldName.join('-')}-${index}`} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  } else {
    return <div id={cellId}>{entityValue}</div>;
  }
};
