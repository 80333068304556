import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Popover,
  Card,
  Collapse,
  OverlayTrigger,
  DropdownButton,
  Dropdown
} from 'react-bootstrap';
import SentinelD3, { SentinelJquery } from './SentinelD3';
import SentinelViolations from './SentinelViolations';
import SentinelTable from './SentinelTable';
import { setUser as setSentinelUser } from './user/sentinelUserSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import {
  setActiveRuleset,
  useActiveRuleset,
  clearRulesets,
  useRulesets,
  fetchUserRulesets
} from './rulesets/rulesetsSlice';
import { format } from 'utils/dates';
import { setPeriod } from './events/eventsSlice';
import { Link } from 'react-router-dom';
import SentinelAddEditFormik from './SentinelAddEditFormik';
import { Can, services } from 'features/permissions';
import { EDLExportButton } from './EDLExportButton';
import { DateRangePicker } from 'components/ant';
import moment from 'moment';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useLocalization } from 'features/localization/localizationSlice';

const DatePicker = () => {
  const dispatch = useDispatch();
  const fromDate = useSelector(state => state.sentinel.fromDate);
  const toDate = useSelector(state => state.sentinel.toDate);
  const [defaultDate] = useState([moment(fromDate), moment(toDate)]);
  const [availableDatesRange] = useState([
    moment().subtract(10, 'year'),
    moment()
      .add(2, 'month')
      .endOf('day')
  ]);
  const localization = useLocalization();

  const handleDateRangeChanged = useCallback(
    dates => {
      dispatch(
        setPeriod({
          fromDate: dates[0].startOf('day').valueOf(),
          toDate: dates[1].endOf('day').valueOf()
        })
      );
    },
    [dispatch]
  );

  return (
    <DateRangePicker
      defaultDates={defaultDate}
      maxDayRange={90}
      onDateRangeChanged={handleDateRangeChanged}
      showNavigation={true}
      availableDatesRange={availableDatesRange}
      format={localization.formats.date.formats.default}
    />
  );
};

const Header = ({ userID }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.sentinel.user);
  const userRulesets = useSelector(state => state.sentinel.userRulesets);
  useRulesets();
  const fromDate = useSelector(state => state.sentinel.fromDate);
  const toDate = useSelector(state => state.sentinel.toDate);
  const activeRuleset = useActiveRuleset();

  useEffect(() => {
    let activeRulesets = [];
    if (userRulesets.length) {
      activeRulesets = userRulesets.filter(ruleset => {
        if (ruleset.expiresAt && ruleset.expiresAt < fromDate) {
          return false;
        }
        if (ruleset.enabledAt < toDate) {
          return true;
        }
        return false;
      });
    }
    dispatch(setActiveRuleset(activeRulesets[0]));
  }, [dispatch, fromDate, toDate, userRulesets]);

  useEffect(() => {
    if (user.id !== parseInt(userID, 10)) {
      dispatch(setSentinelUser({ id: parseInt(userID, 10) }));
      dispatch(fetchUserRulesets(parseInt(userID, 10)));
      dispatch(clearRulesets());
    }
  }, [dispatch, userID, user.id]);

  useEffect(() => {
    if (user.firstName) {
      dispatch(
        setPageTitle(user.firstName ? `Sentinel - ${user.firstName} ${user.lastName}` : 'Sentinel')
      );
    }
    dispatch(setBackButton(true));
  }, [dispatch, user]);

  const popover = (
    <Popover id="popover-basic">
      {activeRuleset && activeRuleset.enabledAt && (
        <Popover.Content>
          Active from {format(activeRuleset.enabledAt, 'LLL')}
          {activeRuleset.expiresAt && ' to ' + format(activeRuleset.expiresAt, 'LLL')}
        </Popover.Content>
      )}
    </Popover>
  );
  return (
    <Row className="mb-3">
      <Col>
        <Row>
          <Col className="col-md-auto">
            <strong>Licence:</strong>
            <br /> {user.licenceNumber}
          </Col>
          <Col className="col-md-auto">
            <strong>State:</strong>
            <br /> {user.licenceState}
          </Col>
          <Col className="col-md-auto">
            <strong>Driver Timezone:</strong>
            <br /> {user.timeZone}
          </Col>
          <Col className="col-md-auto">
            {activeRuleset && activeRuleset.desc && (
              <>
                <strong>Ruleset:</strong>
                <div style={{ display: 'flex' }}>
                  <DropdownButton
                    id="dropdown-item-button"
                    title={activeRuleset.desc}
                    variant="outline-secondary"
                  >
                    {userRulesets.map((rS, index) => (
                      <Dropdown.Item
                        key={index}
                        as="button"
                        onClick={() => {
                          dispatch(setActiveRuleset(rS));
                        }}
                      >
                        {rS.desc}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                    <Button
                      className="ml-2 badge badge-pill badge-light small"
                      variant="outline"
                      id={BUTTON_IDS.sentinelActiveRuleset}
                    >
                      ?
                    </Button>
                  </OverlayTrigger>
                </div>
              </>
            )}
          </Col>
          <Col sm="auto" className="pt-1 ml-md-auto">
            <Link to={`/fatigue/sentinel/workdiary/${user.id}/?date=${fromDate}`}>
              <Button variant="outline-secondary" id={BUTTON_IDS.sentinelWorkDiary}>
                Work Diary View
              </Button>
            </Link>
            <Can everyCompanyService={[services.EDL]}>
              <div style={{ marginLeft: '5px', display: 'inline-block' }}>
                <EDLExportButton driverId={userID} />
              </div>
            </Can>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const Sentinel = props => {
  const [open, setOpen] = useState(false);
  const isEditabled = useActiveRuleset('editable');
  const ref = useRef(null);
  console.log(ref);

  return (
    <div
      style={{
        display: 'flex',
        flex: '1 0 0',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: '1 0 0'
        }}
      >
        <div style={{ display: 'flex', width: '100%', flex: '1 0 0', flexDirection: 'column' }}>
          <Col className="mt-3">
            <Header userID={props.match.params.id} />
            <Row className="mb-3">
              <Col>
                <Card>
                  <Card.Header className="p-2">
                    <Row>
                      <Col sm="auto">
                        <h2 className="h5 m-0 p-2">Activity</h2>
                      </Col>
                      <Col>
                        <Row style={{ height: '100%' }}>
                          <DatePicker />
                        </Row>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body className="card-body pt-1 px-0">
                    <Row>
                      <Col id="activity-widget">
                        <SentinelD3 svg={ref} />
                        <SentinelJquery ref={ref} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Card>
                  <Card.Header>
                    <Row>
                      <Col sm="auto">
                        <h4 className="h5 py-2 m-0">Events</h4>
                      </Col>
                      {isEditabled && (
                        <Col sm="auto" className="pl-0">
                          <Button
                            onClick={() => setOpen(!open)}
                            aria-expanded={open}
                            variant="outline-secondary"
                            id={BUTTON_IDS.sentinelAdd}
                          >
                            Add
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Collapse in={open}>
                      <div>
                        <SentinelAddEditFormik setOpen={open => setOpen(open)} />
                      </div>
                    </Collapse>
                    <SentinelTable />
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="3" className="pl-0">
                <SentinelViolations />
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default Sentinel;
