import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import {
  cache,
  nameCellRenderer,
  companyCellRenderer,
  fleetsInCellRenderer,
  locationCellRenderer,
  areaCellRenderer,
  ellipsisButtonCellRenderer,
  scorecardEventsCellRenderer,
  geofenceTypeCellRenderer
} from './CellRenderers';
import { useTranslation } from 'react-i18next';

import { LoadingTable } from 'components/grid/LoadingTable';
import { useLocalization } from 'features/localization/localizationSlice';

import styles from './Geofences.module.scss';
import { useCompanyGeofenceProviders } from 'features/company/companySlice';
import { entities, useCan } from 'features/permissions';
import { Empty } from 'components/ant';

export const GeofencesTable = ({
  geofences,
  companies,
  locations,
  isLoading,
  setTableRef,
  typeOfEntityToDelete,
  handleDeleteAction,
  canViewScorecardItems,
  viewMode,
  selectedGeofenceId,
  onGeofenceSelected
}) => {
  const can = useCan();
  const canView = can && can({ everyEntity: [entities.GEOFENCE_VIEW] });
  const localization = useLocalization();
  const { t } = useTranslation();
  const geofenceProviders = useCompanyGeofenceProviders();

  if (isLoading) return <LoadingTable columnSizes={[91, 60, 60, 68, 63, 49]} />;
  const handleRowClick = ({ rowData }) => {
    if (viewMode === 'mapview' && onGeofenceSelected) {
      onGeofenceSelected(rowData.id);
    }
  };
  const rowClassSelector = idx => {
    if (viewMode === 'mapview') {
      if (
        idx.index >= 0 &&
        selectedGeofenceId &&
        geofences?.[idx.index]?.id === selectedGeofenceId
      ) {
        return styles.tableRowMapViewSelected;
      } else {
        return styles.tableRowMapView;
      }
    }
    return '';
  };

  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          name: 280,
          company: 150,
          fleetsIn: 200,
          location: 200,
          scorecardEvents: 200,
          ellipsisButton: 80,
          type: 230
        };

        const dynamicColumnWidth =
          width -
          (fixedColumnsWidth.name +
            fixedColumnsWidth.company +
            fixedColumnsWidth.fleetsIn +
            fixedColumnsWidth.location +
            fixedColumnsWidth.ellipsisButton +
            fixedColumnsWidth.type);

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={52}
            rowClassName={rowClassSelector}
            rowHeight={cache.rowHeight}
            rowCount={geofences.length}
            onRowClick={handleRowClick}
            rowGetter={({ index }) => geofences[index]}
            ref={ref => setTableRef(ref)}
            scrollToIndex={
              (geofences &&
                selectedGeofenceId &&
                geofences.findIndex(g => g.id === selectedGeofenceId)) ||
              0
            }
            noRowsRenderer={() => <Empty />}
          >
            <Column
              label={t('Common.Name')}
              dataKey="name"
              width={fixedColumnsWidth.name}
              cellRenderer={props => nameCellRenderer({ ...props, canView })}
            />
            <Column
              label={t('Common.Company')}
              dataKey="companyId"
              width={fixedColumnsWidth.company}
              cellRenderer={props => companyCellRenderer({ ...props, companies: companies })}
            />
            <Column
              label={t('Fleets.Fleet')}
              dataKey="fleet"
              width={fixedColumnsWidth.fleetsIn}
              cellRenderer={props => fleetsInCellRenderer({ ...props, companies })}
            />
            {viewMode !== 'mapview' && (
              <Column
                label={t('Locations.Location')}
                dataKey="location"
                width={fixedColumnsWidth.location}
                cellRenderer={props => locationCellRenderer({ ...props, locations: locations })}
              />
            )}
            {viewMode !== 'mapview' && (
              <Column
                label={t('VehicleTypes.Type')}
                dataKey="type"
                style={{ overflow: 'visible' }}
                width={fixedColumnsWidth.type}
                cellRenderer={geofenceTypeCellRenderer}
              />
            )}
            {viewMode !== 'mapview' && (
              <Column
                label={`${t('GeofencesFeature.Area')} (${localization.formats.area.unit})`}
                dataKey="area"
                width={dynamicColumnWidth}
                cellRenderer={props => areaCellRenderer({ localization, ...props })}
              />
            )}
            {viewMode !== 'mapview' && canViewScorecardItems && (
              <Column
                label={t('Scorecard.ExcludedScorecardEvents')}
                dataKey="features"
                width={fixedColumnsWidth.location}
                cellRenderer={props => scorecardEventsCellRenderer({ ...props, localization, t })}
              />
            )}
            {viewMode !== 'mapview' && (
              <Column
                label={t('Common.TableColumns.Actions')}
                dataKey=""
                style={{ overflow: 'visible' }}
                width={fixedColumnsWidth.ellipsisButton}
                cellRenderer={props =>
                  ellipsisButtonCellRenderer({
                    ...props,
                    typeOfEntityToDelete,
                    handleDeleteAction,
                    geofenceProviders
                  })
                }
              />
            )}
          </Table>
        );
      }}
    </AutoSizer>
  );
};
