import moment from 'moment';
import i18n from 'i18next';
import { isValid, parseISO } from 'date-fns';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export const dateDiffInDays = (a, b) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

export const getIntervalFromDate = (date, unit = 'day') => {
  const currentDate = date ? moment(date) : moment();

  return {
    start: currentDate.startOf(unit).toISOString(),
    end:
      unit === 'week'
        ? currentDate
            .endOf(unit)
            .add(1, 'day')
            .toISOString()
        : currentDate.endOf(unit).toISOString()
  };
};

export function format(date, dateFormat) {
  return moment(date).format(dateFormat);
}

export function formatDistance(d1, d2, o) {
  //for moment, o value will be minutes/hours/days/weeks/months etc
  const diff = moment(d2).diff(moment(d1), o);
  return moment.duration(diff, o).humanize();
}

export function formatRelative(d1) {
  return moment(d1).fromNow(true);
}

export function formatTimeago(date) {
  const diffSeconds = moment().diff(moment(date), 'seconds'),
    diffMins = moment().diff(moment(date), 'minutes'),
    diffHours = moment().diff(moment(date), 'hours'),
    diffDays = moment().diff(moment(date), 'days');
  const prefix =
    (diffSeconds >= 45 && diffSeconds < 60) ||
    (diffMins >= 45 && diffMins < 60) ||
    (diffHours >= 22 && diffHours < 24) ||
    (diffDays >= 26 && diffDays < 30) ||
    (diffDays >= 320 && diffDays < 365);
  return `${prefix ? `${i18n.t('Common.about')} ` : ''}${moment(date).fromNow()}`;
}

export const isValidDate = dateString => {
  const parsedDate = parseISO(dateString);
  return isValid(parsedDate);
};
