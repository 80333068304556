import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../config';
import {
  ApiClient,
  CompaniesApi,
  DashboardApi,
  DevicesApi,
  FleetsApi
} from '../../nextgen_api/index';
import { useSelector } from 'react-redux';
import { RequestQueue } from 'features/requestQueue/RequestQueue';
import moment from 'moment';

export const actionTypes = {
  init: 0,
  processing: 1,
  error: 2,
  done: 3
};

export const dashboard_data = {
  dashboardSummaryData: {
    data: [],
    status: {
      fetching: actionTypes.init,
      error: null
    }
  },
  companyFleets: {
    data: {},
    status: {}
  },
  deviceStats: {
    data: {},
    status: {}
  },
  deviceSnapshots: {
    data: {},
    status: {}
  },
  companySnapshots: {
    data: {},
    status: {}
  },
  devicesSnapshots: {
    filters: {
      companyId: null,
      from: moment()
        .startOf('month')
        .startOf('day')
        .format(),
      to: moment()
        .endOf('month')
        .endOf('day')
        .format(),
      orderField: 'EventTime',
      orderDirection: 'desc',
      rowOffset: 0,
      searchText: '',
      deviceIds: [],
      totalCount: 0
    },
    data: {},
    queryParams: {
      companyId: null,
      period: {
        from: moment()
          .startOf('month')
          .startOf('day'),
        to: moment()
          .endOf('month')
          .endOf('day')
      },
      orderField: 'EventTime',
      orderDirection: 'desc',
      deviceIds: [],
      rowOffset: 0,
      rowCountLimit: 100,
      searchText: ''
    }
  }
};

const dashboardSlice = createSlice({
  name: 'dashboard_data',
  initialState: dashboard_data,
  reducers: {
    fetchDashboardSummaryDataStart(state) {
      state.dashboardSummaryData.status.fetching = actionTypes.processing;
      state.dashboardSummaryData.status.error = null;
    },
    fetchDashboardSummaryDataSucceeded(state, { payload }) {
      state.dashboardSummaryData.data = payload;
      state.dashboardSummaryData.status.fetching = actionTypes.done;
    },
    fetchDashboardSummaryDataFailed(state, { payload }) {
      state.dashboardSummaryData.status.fetching = actionTypes.error;
      state.dashboardSummaryData.status.error = payload;
    },
    //fleets, vehicles, devices
    fetchCompanyFleetDataStart(state, { payload }) {
      const companyId = payload;
      state.companyFleets.status[companyId] = {
        fetching: actionTypes.processing,
        error: null
      };
    },
    fetchCompanyFleetDataSucceeded(state, { payload }) {
      const { companyId, data } = { ...payload };
      state.companyFleets.data[companyId] = data;
      state.companyFleets.status[companyId].fetching = actionTypes.done;
    },
    fetchCompanyFleetDataFailed(state, { payload }) {
      const { companyId, err } = { ...payload };
      state.companyFleets.status[companyId].fetching = actionTypes.error;
      state.companyFleets.status[companyId].error = err;
    },
    //Snapshots
    fetchSnapshotsStart(state, { payload }) {
      const { deviceId, year, month } = payload;
      state.deviceSnapshots.status[deviceId] = {
        [year + '-' + month]: {
          fetching: actionTypes.processing,
          error: null
        }
      };
    },
    fetchSnapshotsSucceeded(state, { payload }) {
      const { deviceId, year, month, data, companyId } = { ...payload };
      state.deviceSnapshots.data[deviceId] = { [year + '-' + month]: data };
      state.deviceSnapshots.status[deviceId][year + '-' + month].fetching = actionTypes.done;
      if (state.companySnapshots.data[companyId] == null) {
        state.companySnapshots.data[companyId] = {};
      }
      if (state.companySnapshots.data[companyId][year + '-' + month] == null) {
        state.companySnapshots.data[companyId][year + '-' + month] = {};
      }
      state.companySnapshots.data[companyId][year + '-' + month][deviceId] = data;
    },
    fetchSnapshotsFailed(state, { payload }) {
      const { deviceId, year, month, err } = { ...payload };
      state.deviceSnapshots.status[deviceId][year + '-' + month].fetching = actionTypes.error;
      state.deviceSnapshots.status[deviceId][year + '-' + month].error = err;
    },
    fetchSnapshotsCancelled(state, { payload }) {
      const { deviceId, year, month } = { ...payload };
      if (
        state.deviceSnapshots.status[deviceId][year + '-' + month].fetching ===
        actionTypes.processing
      ) {
        state.deviceSnapshots.status[deviceId][year + '-' + month].fetching = actionTypes.init;
        state.deviceSnapshots.status[deviceId][year + '-' + month].error = null;
      }
    },
    //device stats
    fetchDeviceStatsStart(state, { payload }) {
      const companyId = payload;
      state.deviceStats.status[companyId] = {
        fetching: actionTypes.processing,
        lastFetchingDate: new Date().toISOString(),
        error: null
      };
    },
    fetchDeviceStatsSucceeded(state, { payload }) {
      const { companyId, data } = { ...payload };
      const dataMap = {};
      if (data != null) {
        data.forEach(ds => (dataMap[ds.deviceId] = ds));
      }
      if (state.deviceStats.data[companyId] != null) {
        Object.assign(state.deviceStats.data[companyId], dataMap);
      } else {
        state.deviceStats.data[companyId] = dataMap;
      }

      state.deviceStats.status[companyId].fetching = actionTypes.done;
    },
    fetchDeviceStatsFailed(state, { payload }) {
      const { companyId, err } = { ...payload };
      state.deviceStats.status[companyId] = state.deviceStats.data[companyId] || {};
      state.deviceStats.status[companyId].fetching = actionTypes.error;
      state.deviceStats.status[companyId].error = err;
    },
    //company snapshots
    fetchCompanySnapshotsStart(state, { payload }) {
      const { companyId, year, month } = payload;
      state.companySnapshots.status[companyId] = {
        [year + '-' + month]: {
          fetching: actionTypes.processing,
          error: null
        }
      };
    },
    fetchCompanySnapshotsSucceeded(state, { payload }) {
      const { companyId, year, month, data } = { ...payload };
      if (state.companySnapshots.data[companyId] == null) {
        state.companySnapshots.data[companyId] = {};
      }
      const snapshotsByDeviceId = {};
      if (data?.length > 0) {
        data.forEach(a => {
          if (a.device?.id != null) {
            if (snapshotsByDeviceId[a.device.id] == null) {
              snapshotsByDeviceId[a.device.id] = [];
            }
            snapshotsByDeviceId[a.device.id].push(a);
          }
        });
        state.companySnapshots.data[companyId][year + '-' + month] = snapshotsByDeviceId;
      }
      state.companySnapshots.status[companyId][year + '-' + month].fetching = actionTypes.done;
    },
    fetchCompanySnapshotsFailed(state, { payload }) {
      const { companyId, year, month, err } = { ...payload };
      state.companySnapshots.status[companyId][year + '-' + month].fetching = actionTypes.error;
      state.companySnapshots.status[companyId][year + '-' + month].error = err;
    },
    updateDevicesSnapshotsQueryParams(state, { payload }) {
      state.devicesSnapshots.queryParams = {
        ...state.devicesSnapshots.queryParams,
        ...payload
      };
    }
  }
});

export const {
  fetchDashboardSummaryDataStart,
  fetchDashboardSummaryDataSucceeded,
  fetchDashboardSummaryDataFailed,

  fetchCompanyFleetDataStart,
  fetchCompanyFleetDataSucceeded,
  fetchCompanyFleetDataFailed,

  fetchSnapshotsStart,
  fetchSnapshotsSucceeded,
  fetchSnapshotsFailed,
  fetchSnapshotsCancelled,

  fetchDeviceStatsStart,
  fetchDeviceStatsSucceeded,
  fetchDeviceStatsFailed,

  fetchCompanySnapshotsStart,
  fetchCompanySnapshotsSucceeded,
  fetchCompanySnapshotsFailed,

  updateDevicesSnapshotsQueryParams
} = dashboardSlice.actions;

export const fetchDashboardData = (userKey, companyId) => async (dispatch, getState) => {
  dispatch(fetchDashboardSummaryDataStart());
  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH;

  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };

  const dashboardApi = new DashboardApi(apiClient);
  const promise = new Promise((resolve, reject) => {
    dashboardApi.stats(companyId, { pruning: 'APP' }, (err, data, resp) => {
      if (err && resp?.status !== 200) {
        console.log(err);
        reject(err);
      } else {
        resolve(resp.body);
      }
    });
  });

  try {
    const data = await promise;
    dispatch(fetchDashboardSummaryDataSucceeded(data));
  } catch (err) {
    dispatch(fetchDashboardSummaryDataFailed(err));
  }
};

export const fetchCompanyFleetData = (userKey, companyId, embed, deviceTypes) => async (
  dispatch,
  getState
) => {
  const status = getState().dashboardData.companyFleets.status[companyId];
  if (userKey == null || companyId == null || status?.fetching === actionTypes.processing) return;

  dispatch(fetchCompanyFleetDataStart(companyId));
  const apiClient = new ApiClient();
  apiClient.timeout = 3 * 60 * 1000;
  apiClient.basePath = API_PATH;
  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };

  const fleetsApi = new FleetsApi(apiClient);
  const promise = new Promise((resolve, reject) => {
    fleetsApi.list(
      null,
      {
        embed: embed,
        deviceTypes: deviceTypes,
        pruning: 'ALL',
        companyId: companyId,
        direction: 'DOWN'
      },
      (err, data, resp) => {
        if (err && resp?.status !== 200) {
          console.log(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(fetchCompanyFleetDataSucceeded({ companyId: companyId, data: data }));
  } catch (err) {
    dispatch(fetchDashboardSummaryDataFailed({ companyId: companyId, err: err }));
  }
};

export const fetchDeviceStats = (userKey, companyId, embed) => async (dispatch, getState) => {
  const status = getState().dashboardData.deviceStats.status[companyId];
  if (!userKey || !companyId || status?.fetching === actionTypes.processing) return;
  const prevStatus = getState().dashboardData.deviceStats.status[companyId];
  dispatch(fetchDeviceStatsStart(companyId));
  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH;

  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };

  let lastUpdatedDate = null;
  if (prevStatus != null) {
    lastUpdatedDate = prevStatus.lastFetchingDate;
  }

  const devicesApi = new DevicesApi(apiClient);
  const promise = new Promise((resolve, reject) => {
    devicesApi.deviceStats(
      null,
      {
        embed: embed,
        lastUpdated: lastUpdatedDate,
        pruning: 'ALL',
        companyId: companyId,
        direction: 'DOWN'
      },
      (err, data, resp) => {
        if (err) {
          console.log(err);
          reject(err.body);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(fetchDeviceStatsSucceeded({ companyId: companyId, data: data }));
  } catch (err) {
    const error = err?.message ? err.message : err || 'Unknown Error';
    dispatch(fetchDeviceStatsFailed({ companyId: companyId, err: error }));
  }
};

export const fetchDeviceSnapshots = (userKey, deviceId, year, month, companyId) => async (
  dispatch,
  getState
) => {
  dispatch(fetchSnapshotsStart({ deviceId, year, month }));
  const reqHandle = RequestQueue.queueRequest(
    () => {
      const apiClient = new ApiClient();
      apiClient.basePath = API_PATH;

      apiClient.defaultHeaders = {
        Authorization: `Token token="${userKey}"`
      };

      const devicesApi = new DevicesApi(apiClient);
      const promise = new Promise((resolve, reject) => {
        devicesApi.getSnapshots(
          deviceId,
          userKey,
          { year: year, month: month, pruning: 'APP' },
          (err, data, resp) => {
            if (err && resp?.status !== 200) {
              console.log(err);
              reject(err);
            } else {
              resolve(resp.body);
            }
          }
        );
      });
      return promise;
    },
    data =>
      dispatch(
        fetchSnapshotsSucceeded({
          deviceId: deviceId,
          year: year,
          month: month,
          data: data,
          companyId: companyId
        })
      ),
    err =>
      dispatch(fetchSnapshotsFailed({ deviceId: deviceId, year: year, month: month, err: err })),
    () => dispatch(fetchSnapshotsCancelled({ deviceId: deviceId, year: year, month: month }))
  );

  return reqHandle;
};

export const fetchCompanySnapshots = (companyId, userKey, year, month) => async (
  dispatch,
  getState
) => {
  dispatch(fetchCompanySnapshotsStart({ companyId, year, month }));
  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH;

  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };
  const companyApi = new CompaniesApi(apiClient);

  const promise = new Promise((resolve, reject) => {
    companyApi.getSnapshots(
      companyId,
      { year: year, month: month, pruning: 'APP' },
      (err, data, resp) => {
        if (err && resp?.status !== 200) {
          console.log(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(fetchCompanySnapshotsSucceeded({ companyId, data, year, month }));
  } catch (ex) {
    dispatch(fetchCompanySnapshotsFailed({ companyId, err: ex, year, month }));
  }
};

export const useDashboardData = () => useSelector(state => state.dashboardData);
export const useDashboardSummaryData = () =>
  useSelector(state => state.dashboardData.dashboardSummaryData);
export const useCompaniesFleet = () => useSelector(state => state.dashboardData.companyFleets);
export const useDeviceSnapshots = () => useSelector(state => state.dashboardData.deviceSnapshots);

export const useSnapshotsByCompanyId = (companyId, year, month) =>
  useSelector(state => state.dashboardData.companySnapshots.data[companyId]?.[year + '-' + month]);
export const useSnapshotsStatusByCompanyId = (companyId, year, month) =>
  useSelector(
    state => state.dashboardData.companySnapshots.status[companyId]?.[year + '-' + month]
  );
export const useSnapshotsByDeviceIdFromCompany = (companyId, deviceId, year, month) =>
  useSelector(
    state => state.dashboardData.companySnapshots.data[companyId]?.[year + '-' + month]?.[deviceId]
  );

export const useDeviceStats = () => useSelector(state => state.dashboardData.deviceStats);

export default dashboardSlice.reducer;
