import { Affix, Button, Space } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Can from 'features/permissions/Can';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import { useTranslation } from 'react-i18next';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { useDispatch } from 'react-redux';
import { fetchAudits } from 'features/audits';
import { BUTTON_IDS } from 'utils/globalConstants';
import entities from 'features/permissions/entities';

import { useCan } from 'features/permissions';

const ViewHeaderWrapper = ({
  auditPath,
  canLogInAsUser,
  canUse,
  canService = [],
  children,
  data,
  editPath,
  handleButtonAction,
  handleEditAction,
  handleLogInAsUser,
  typeOfEntityToDelete,
  disableEdit = false,
  disableDelete = false,
  disableRestore = false,
  skipDeleteConfirmation = false,
  customDeleteContent
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const can = useCan();

  if (!data) return '';
  const { status, statusType } = data;
  const name = data.name || data.Name || data.username || '';

  const handleDeleteModal = () => {
    if (skipDeleteConfirmation) {
      handleButtonAction('delete')();
    } else {
      confirmationModal(
        `${t('Common.DeleteButton')} ${name}`,
        customDeleteContent
          ? customDeleteContent
          : `${t('Common.SureDelete')} ${typeOfEntityToDelete ?? ''} ${name}?`,
        t('Common.DeleteButton'),
        t('Common.CancelButton'),
        handleButtonAction('delete'),
        'delete'
      );
    }
  };

  const handleAuditClick = () => {
    dispatch(fetchAudits({ entityName: data.entityName, entityId: data.id }));
    history.push(auditPath);
  };

  return (
    <Affix offsetTop={56.1}>
      <HeaderPageWrapper>
        <Space size={16} style={{ marginLeft: 'auto' }}>
          {children}
          {canLogInAsUser && (
            <Button
              size="large"
              type="secondary"
              danger
              id={BUTTON_IDS.loginAsUserViewHeader}
              onClick={handleLogInAsUser}
            >
              {t('Users.LogInAsUser.LogInAsUser')}
            </Button>
          )}

          {auditPath &&
            (can({ everyEntity: [entities[`${canUse}_AUDIT`]] }) ||
              can({ oneOfCompanyServices: canService })) && (
              <Button
                size="large"
                type="secondary"
                id={BUTTON_IDS.auditViewHeader}
                onClick={handleAuditClick}
              >
                {t('Common.AuditsButton')}
              </Button>
            )}
          {(status === 'CREATED' || status === 'ENABLED' || statusType === 'SCHEDULE') && (
            <>
              {!disableEdit &&
                editPath &&
                (can({ everyEntity: [entities[`${canUse}_UPDATE`]] }) ||
                  can({ oneOfCompanyServices: canService })) && (
                  <Button
                    size="large"
                    type="secondary"
                    onClick={
                      handleEditAction
                        ? handleEditAction
                        : () => {
                            history.push(editPath);
                          }
                    }
                    id={BUTTON_IDS.editViewHeader}
                  >
                    {t('Common.EditButton')}
                  </Button>
                )}
              {!disableDelete &&
                (can({ everyEntity: [entities[`${canUse}_DESTROY`]] }) ||
                  can({ oneOfCompanyServices: canService })) && (
                  <Button
                    size="large"
                    type="secondary"
                    danger
                    onClick={handleDeleteModal}
                    id={BUTTON_IDS.deleteViewHeader}
                  >
                    {t('Common.DeleteButton')}
                  </Button>
                )}
            </>
          )}
          {!disableRestore &&
            (status === 'DELETED' || status === 'DISABLED') &&
            (can({ everyEntity: [entities[`${canUse}_RESTORE`]] }) ||
              can({ oneOfCompanyServices: canService })) && (
              <Button
                size="large"
                type="secondary"
                onClick={handleButtonAction('restore')}
                id={BUTTON_IDS.restoreViewHeader}
              >
                {t('Common.RestoreButton')}
              </Button>
            )}
        </Space>
      </HeaderPageWrapper>
    </Affix>
  );
};

export default ViewHeaderWrapper;
