import { useCallback, useMemo, useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import { orderBy, isEqual } from 'lodash';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';

import { useCompanySnapshotsEntities } from './useCompanySnapshotsEntities';
import { useSnapshotsFilter } from './useSnapshotsFilter';
import { getAvailablePositionedFootages, getPositionedFootages } from '../helpers';
import { useSnapshotEntityCameraStatus } from './useSnapshotEntityCameraStatus';

import { fetchSnapshots } from 'features/camera/cameraApi';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';

const RowCountLimit = 100;
export const useDailySnapshots = ({ showNonCameraVehicle = false, entity, entityId }) => {
  const localization = useLocalization();

  const {
    isLoading: isDataFetching,
    fleets: allFleets,
    vehiclesAndDevices: allVehicles,
    companies: allCompanies,
    companyId,
    allDeviceIds,
    snapshotsEntity
  } = useCompanySnapshotsEntities({
    showNonCameraVehicle,
    entity,
    entityId
  });

  const {
    sortOption,
    filterText,
    selectedYear,
    selectedMonth,
    from,
    to,
    filterCompanies,
    filterFleets,
    orderField,
    orderDirection,
    rowOffset,
    setRowOffset,
    ...filterProps
  } = useSnapshotsFilter({ allCompanies, allFleets, allVehicles, companyId });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const reqCtxRef = useRef({
    request: null,
    ctxParams: null,
    fetchTime: null,
    isFetching: null,
    error: null,
    totalCount: null
  });

  const [data, setData] = useState(null);
  const [isLoadingSnapshots, setIsLoadingSnapshots] = useState(true);

  const allLoaded = useMemo(
    () =>
      data?.length &&
      !isNaN(Number(reqCtxRef?.current?.totalCount)) &&
      data.length >= Number(reqCtxRef?.current?.totalCount),
    [data?.length, reqCtxRef?.current?.totalCount]
  );

  useEffect(() => {
    if (!companyId || !allDeviceIds?.length) {
      return;
    }

    const curCtxParams = {
      startTime: from,
      endTime: to,
      rowOffset,
      devices: allDeviceIds,
      orderField,
      orderDirection
    };

    let sameParams = false,
      prevReqCtx = reqCtxRef.current;
    if (prevReqCtx?.ctxParams) {
      const {
        startTime: prevStart,
        endTime: prevEnd,
        rowOffset: prevOffset,
        devices: prevIds,
        orderField: prevOrderField,
        orderDirection: prevOrderDirection
      } = prevReqCtx.ctxParams;
      const {
        startTime: curStart,
        endTime: curEnd,
        rowOffset: curOffset,
        devices: curIds,
        orderField: curOrderField,
        orderDirection: curOrderDirection
      } = curCtxParams;
      sameParams =
        isEqual(prevStart, curStart) &&
        isEqual(prevEnd, curEnd) &&
        isEqual(prevOrderField, curOrderField) &&
        isEqual(prevOrderDirection, curOrderDirection) &&
        isEqual(new Set(prevIds), new Set(curIds)) &&
        isEqual(prevOffset || 0, curOffset || 0);
    }

    if (!prevReqCtx.ctxParams || (!isLoadingSnapshots && !prevReqCtx.fetchTime) || !sameParams) {
      const onReqDone = (totalCount, error) => {
        if (reqCtxRef.current.request) {
          reqCtxRef.current.request.abort();
        }
        reqCtxRef.current.request = null;
        reqCtxRef.current.fetchTime = moment().valueOf();
        reqCtxRef.current.isFetching = false;
        reqCtxRef.current.error = error || null;
        if (totalCount !== null) {
          reqCtxRef.current.totalCount = totalCount;
        }
        setIsLoadingSnapshots(false);
      };

      if (prevReqCtx.request) {
        prevReqCtx.request.abort();
        reqCtxRef.current.request = null;
      }
      reqCtxRef.current.ctxParams = curCtxParams;
      if (curCtxParams.rowOffset === 0) {
        reqCtxRef.current.totalCount = 0;
        setData([]);
      }
      reqCtxRef.current.request = dispatch(
        fetchSnapshots({
          ...curCtxParams,
          rowCountLimit: RowCountLimit,
          latestOnly: false,
          onSuccess: res => {
            onReqDone(res?.totalCount || 0);
            setData(prev =>
              rowOffset === 0 ? res?.events || [] : [...prev, ...(res?.events || [])]
            );
          },
          onError: errMsg => {
            onReqDone(rowOffset === 0 ? 0 : null, errMsg);
            setData(prev => (rowOffset === 0 ? [] : prev));
          }
        })
      );
      const fetchData = async () => {
        try {
          setIsLoadingSnapshots(true);
          reqCtxRef.current.isFetching = true;
          await reqCtxRef.current.request;
        } catch (e) {
          onReqDone(e);
          dispatch(
            openToast({
              type: ToastType.Error,
              message: parseErrorMessage(e)
            })
          );
        }
      };
      fetchData();
    }
  }, [t, dispatch, reqCtxRef, from, to, rowOffset, allDeviceIds, orderDirection, orderField]);

  const { availablePositions, isDeviceFailure } = useSnapshotEntityCameraStatus(snapshotsEntity);

  const { maxDailySnapshotsCount, snapshots, snapshotsCount } = useMemo(() => {
    const isFilteredDateTime = formatedDateTime => {
      if (!filterText || !filterText.trim()) {
        return true;
      }
      try {
        return formatedDateTime
          .replace(/\s/g, '')
          .match(new RegExp(filterText.replace(/\s/g, ''), 'gi'));
      } catch (error) {}
      return true;
    };
    const snapshotsByDates = {};
    for (const snapshotEvent of data || []) {
      const { id, timeAt, attachments } = snapshotEvent;
      const dateTime = moment(timeAt)
        .startOf('day')
        .valueOf();
      //Filter By Date Locally
      if (
        isFilteredDateTime(
          format(moment(Number(dateTime)).valueOf(), localization.formats.time.formats.dby)
        )
      ) {
        //Empty Check for Company/Fleet
        if (
          !(
            filterFleets.every(fleet => !fleet.checked) ||
            filterCompanies.every(comp => !comp.checked)
          )
        ) {
          snapshotsByDates[dateTime] = snapshotsByDates[dateTime] || [];
          snapshotsByDates[dateTime].push(...attachments.map(att => ({ ...att, id, timeAt })));
        }
      }
    }
    //Sort
    return orderBy(Object.keys(snapshotsByDates), [Number], [sortOption.order]).reduce(
      (a, snapshotDate) => {
        const positionedFootages = getAvailablePositionedFootages(
          getPositionedFootages(snapshotsByDates[snapshotDate]),
          availablePositions
        );
        return {
          maxDailySnapshotsCount: Math.max(
            a.maxDailySnapshotsCount,
            Object.values(positionedFootages || {}).length
          ),
          snapshotsCount:
            a.snapshotsCount + Object.values(positionedFootages || {}).filter(f => !!f).length,
          snapshots: [
            ...a.snapshots,
            {
              snapshotDateId: snapshotDate,
              positionedFootages: positionedFootages,
              driverId: snapshotsEntity?.driverId,
              isDeviceFailure
            }
          ]
        };
      },
      { maxDailySnapshotsCount: 0, snapshots: [], snapshotsCount: 0 }
    );
  }, [
    data,
    sortOption,
    filterText,
    localization,
    snapshotsEntity,
    filterCompanies,
    filterFleets,
    availablePositions,
    isDeviceFailure
  ]);

  const onLoadMore = useCallback(() => setRowOffset(data?.length || 0), [data?.length]);

  return {
    snapshotsEntity,
    isDataFetching,
    filters: {
      snapshotsCount,
      filterCompanies,
      filterFleets,
      filterText,
      selectedYear,
      selectedMonth,
      sortOption,
      ...filterProps
    },
    snapshots: {
      isLoading: isLoadingSnapshots,
      maxDailySnapshotsCount,
      snapshots,
      snapshotsCount,
      sortBy: sortOption,
      onLoadMore,
      allLoaded,
      snapshotYear: selectedYear,
      snapshotMonth: selectedMonth
    }
  };
};
