import { Tooltip } from 'components/ant';
import React from 'react';
import { Link } from 'react-router-dom';
import { CellMeasurer, CellMeasurerCache, SortDirection } from 'react-virtualized';
import { Tag } from 'antd';
import i18n from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { EllipsisMenu } from 'components/tables/EllipsisMenu';
import styles from './CellRenderers.module.scss';
import styles1 from 'components/tn/grid/grid.module.scss';

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 52
});

export const templateCache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 52
});

export const detailsCellRenderer = ({ rowIndex, rowData, dataKey, parent }) => {
  const device = rowData;
  const fleets = (device.fleetInfo || [])
    .filter(fleet => fleet.id)
    .map((fleet, i) => {
      if (i >= 5) {
        if (i === device.fleetInfo.length - 1) {
          return <Tag key={`fleet-${dataKey}-${fleet.id}`}>+{device.fleetInfo.length - 5}</Tag>;
        } else {
          return null;
        }
      }
      return (
        <div key={`fleet-${dataKey}-${fleet.id}`}>
          {fleet.name}
          {i < device.fleetInfo.length - 1 ? ',' : ''}
        </div>
      );
    });

  return (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${device.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      <div className={styles.cellMultilineContainer}>
        {fleets.length > 0 && <div>{fleets}</div>}
      </div>
    </CellMeasurer>
  );
};

export const fleetsRenderer = ({ rowData, dataKey }) => {
  const device = rowData;
  const fleets = (device.fleetInfo || [])
    .filter(fleet => fleet.id)
    .map((fleet, i) => {
      if (i >= 5) {
        if (i === device.fleetInfo.length - 1) {
          return <Tag key={`fleet-${dataKey}-${fleet.id}`}>+{device.fleetInfo.length - 5}</Tag>;
        } else {
          return null;
        }
      }
      return (
        <div key={`fleet-${dataKey}-${fleet.id}`}>
          {fleet.name}
          {i < device.fleetInfo.length - 1 ? ',' : ''}
        </div>
      );
    });

  return (
    <div className={styles.cellMultilineContainer}>{fleets.length > 0 && <div>{fleets}</div>}</div>
  );
};

export const deviceNameCellRenderer = ({ dataKey, parent, rowIndex, rowData, canViewDevice }) => {
  return canViewDevice ? (
    <Link to={`/settings/devices/id/${rowData.id}`}>{rowData.name}</Link>
  ) : (
    <span>{rowData.name}</span>
  );
};

export const typeCellRenderer = ({ dataKey, parent, rowIndex, rowData }) => {
  return (
    <Tooltip content={rowData?.model?.name || rowData?.type?.name} target={rowData?.type?.name} />
  );
};

export const vehicleCellRenderer = ({ dataKey, parent, rowIndex, rowData, canViewVehicle }) => {
  return (
    <div className={styles.cellMultilineContainer}>
      {rowData.vehicle && rowData.vehicleInfo ? (
        <>
          {canViewVehicle ? (
            <Link to={`/settings/vehicles/id/${rowData.vehicleInfo.id}`}>
              {rowData.vehicleInfo.name}
            </Link>
          ) : (
            <span>{rowData.vehicleInfo.name}</span>
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export const nameCellRenderer = ({ rowIndex, rowData, dataKey, parent, t, templateType }) => {
  return (
    <CellMeasurer
      cache={templateCache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${rowData.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      <Link to={`/settings/company_config/${templateType?.toLowerCase()}/id/${rowData.id}`}>
        <span className={styles1.twoLineEllipsisCell} style={{ overflowWrap: 'anywhere' }}>
          {rowData.name}
        </span>
      </Link>
    </CellMeasurer>
  );
};

export const associationsCellRenderer = ({ rowIndex, rowData, dataKey, parent, t }) => {
  return (
    <CellMeasurer
      cache={templateCache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${rowData.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Link
          to={{
            pathname: '/settings/devices',
            state: rowData.type === 'GPIO' ? { gpioConfigurationId: rowData.id } : undefined
          }}
        >
          {rowData.associations.devices + ' ' + t('Devices.Devices')}
        </Link>
        /
        <Link
          to={{
            pathname: '/settings/vehicles',
            state: rowData.type === 'GPIO' ? { gpioConfigurationId: rowData.id } : undefined
          }}
        >
          {rowData.associations.vehicles + ' ' + t('Vehicles.Vehicles')}
        </Link>
      </div>
    </CellMeasurer>
  );
};

export const configCellRenderer = (configKey, t, localization) => ({
  rowIndex,
  rowData,
  dataKey,
  parent
}) => {
  const config = rowData.configurations?.find(config => config.key === configKey);
  const configValue = config ? config.value : 'false';

  let displayValue;
  if (configValue === 'false') {
    displayValue = t('Common.No');
  } else if (configValue === 'true') {
    displayValue = t('Common.Yes');
  } else if (config.key === 'asset.selection') {
    displayValue = configValue.charAt(0).toUpperCase() + configValue.slice(1);
  } else if (configKey !== 'company.max.duration') {
    displayValue =
      localization.convertDistance(configValue, 0) + ' ' + localization.formats.speed.unit_per_hour;
  } else {
    displayValue = configValue + ' ' + t('Common.seconds');
  }

  return (
    <CellMeasurer
      cache={templateCache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${rowData.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      {displayValue}
    </CellMeasurer>
  );
};

export const ellipsisButtonCellRenderer = ({
  rowData,
  hideEdit,
  showDelete,
  hideCopy,
  enableRestoreAction,
  handleAction,
  templateType
}) => {
  const menuItems = [
    {
      label: `${i18n.t('Common.EllipsisButton.View')}`,
      link: `/settings/company_config/${templateType.toLowerCase()}/id/${rowData.id}`,
      visible: true,
      id: 'btn_gpioView'
    },
    {
      label: `${i18n.t('Common.EllipsisButton.Edit')}`,
      link: `/settings/company_config/${templateType.toLowerCase()}/edit/id/${rowData.id}`,
      visible: !hideEdit,
      id: 'btn_gpioEdit'
    },
    {
      label: `${i18n.t('Common.EllipsisButton.Copy')}`,
      onClick: () => handleAction({ data: rowData, action: 'copy' }),
      visible: !hideCopy,
      id: 'btn_gpioCopy'
    },
    {
      label: `${i18n.t('Common.EllipsisButton.Delete')}`,
      onClick: () => handleAction({ data: rowData, action: 'delete' }),
      visible: showDelete,
      danger: true,
      id: 'btn_gpioDelete'
    },
    {
      label: `${i18n.t('Common.EllipsisButton.Restore')}`,
      onClick: () => handleAction({ data: rowData, action: 'restore' }),
      visible: enableRestoreAction,
      id: 'btn_gpioRestore'
    },
    {
      label: `${i18n.t(
        'CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.SetAsCompanyTemplate'
      )}`,
      onClick: () => handleAction({ data: rowData, action: 'setCompanyTemplate' }),
      visible: !rowData.default && showDelete,
      id: 'btn_gpioSetCompanyTemplate'
    }
  ];

  const allowedMenuItems = menuItems.filter(item => item.visible);

  return (
    <EllipsisMenu
      menuItems={allowedMenuItems}
      menuStyle={{ width: '194px', top: '-80px', right: '45px' }}
    />
  );
};

const SortingIcons = ({ name, sortBy, direction }) => {
  if (name === sortBy) {
    if (direction === SortDirection.ASC) {
      return <FontAwesomeIcon icon={faSortUp} />;
    } else if (direction === SortDirection.DESC) {
      return <FontAwesomeIcon icon={faSortDown} />;
    } else {
      return <FontAwesomeIcon icon={faSort} />;
    }
  } else {
    return <FontAwesomeIcon icon={faSort} />;
  }
};

export const sortableHeaderRenderer = ({ label, name, handleSort, sortBy, sortDirection }) => (
  <div
    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
    onClick={() => handleSort({ sortBy: name })}
  >
    {label}{' '}
    <div style={{ marginLeft: 'auto' }}>
      <SortingIcons name={name} sortBy={sortBy} direction={sortDirection} />
    </div>
  </div>
);
