import React, { useEffect, useState, useMemo, useRef, useReducer } from 'react';
import { useDispatch } from 'react-redux';
//import { useHistory } from 'react-router';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tag, Alert } from 'antd';
import _ from 'lodash';
import { useUser } from 'features/user/userSlice';
import { Iframe } from 'components/iframe/Iframe';
import { AuthUtil } from 'features/auth/authUtil';
import { useCurrentCompany } from 'features/company/companySlice';
import {
  useGetQsReportSchedulesQuery,
  useDeleteQsReportScheduleMutation,
  QsEntityType
} from 'services/nextgen';
import { confirmDeleteEntity } from 'components/tn/modal/confirm';
import { Comparators } from 'utils/sorting';
import { SortOrders } from 'containers/InsightsQS/constants';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';

import { Grid } from 'components/tn';
import { getReportNameByType } from './constants';
import {
  ReportsPageToolbar,
  isReportCategory,
  getReportCategoryLabels,
  getReportCategoryLabel
} from 'containers/InsightsQS/Toolbars/ReportsPageToolbar';

import styles from 'containers/InsightsQS/Pages/pages.module.scss';
import scheduleStyles from './ReportSchedule.module.scss';
import cardStyles from 'containers/InsightsQS/Cards/cards.module.scss';

import imgView from 'containers/InsightsQS/Images/actions/view.svg';
import imgEdit from 'containers/InsightsQS/Images/actions/edit.svg';
import imgDelete from 'containers/InsightsQS/Images/actions/delete.svg';

function _ReportSchedule() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useUser();
  const company = useCurrentCompany();

  /**************************/
  const frameRef = useRef(null);
  const [reportId, setReportId] = useState();
  const [showIframeView, setShowIframeView] = useState(false);
  const [showIframeEdit, setShowIframeEdit] = useState(false);

  const pageUrlView = useMemo(() => {
    return (
      AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
      `&url=${encodeURIComponent(`/ng/report_schedules/${reportId}`)}`
    );
  }, [reportId]);

  const pageUrlEdit = useMemo(() => {
    return (
      AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
      `&url=${encodeURIComponent(`/ng/report_schedules/${reportId}/edit`)}`
    );
  }, [reportId]);
  /****************************/

  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [searchText, setSearchText] = useState();
  const [sortOrder, setSortOrder] = useState(SortOrders.Default);
  const [categories, setCategories] = useState();
  const [pageUrl, setPageUrl] = useState();
  const [getQueryData, setGetQueryData] = useState(true);
  const [deleteReportScheduleQuery] = useDeleteQsReportScheduleMutation();
  const history = useHistory();

  const reportsQuery = useGetQsReportSchedulesQuery({
    userId: userInfo?.id
  });

  useEffect(() => {
    dispatch(setPageTitle(t('Insights.ReportsMenu.Scheduled')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  useEffect(() => {
    if (reportsQuery.isFetching /*|| !getQueryData*/) {
      return;
    }

    const allReports = _.orderBy(
      [...(reportsQuery?.data ? reportsQuery?.data : [])],
      ['id'],
      ['desc']
    );
    setReports(allReports);
    setFilteredReports(allReports);

    if (getQueryData) {
      reportsQuery.refetch();
      setGetQueryData(false);
    }
  }, [reportsQuery, getQueryData]);

  useEffect(() => {
    if (
      categories !== undefined &&
      categories !== null &&
      reports !== undefined &&
      reports !== undefined
    ) {
      handleFilter(categories);
    }
  }, [categories, reports]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (frameRef.current && !frameRef.current.contains(event.target)) {
        setShowIframeView(false);
        setShowIframeEdit(false);
        //setGetQueryData(true);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearch = value => {
    setSearchText(value);

    let filteredReports = search(reports, value);
    if (categories) {
      filteredReports = filter(filteredReports, categories);
    }
    if (sortOrder) {
      filteredReports = sort(filteredReports, sortOrder);
    }

    setFilteredReports(filteredReports);
  };

  const handleSort = order => {
    setSortOrder(order);

    const sortedReports = sort(filteredReports, order);
    setFilteredReports(sortedReports);
  };

  const handleFilter = categoryIds => {
    setCategories(categoryIds);

    let filteredReports = filter(reports, categoryIds);
    if (searchText) {
      filteredReports = search(filteredReports, searchText);
    }
    if (sortOrder) {
      filteredReports = sort(filteredReports, sortOrder);
    }
    setFilteredReports(filteredReports);
  };

  const search = (reports, searchText) => {
    return reports.filter(
      report =>
        getReportNameByType(report.type)
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        report?.otherEmails?.toLowerCase().includes(searchText.toLowerCase()) ||
        report?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
        getDateFromField(report)
          ?.toLowerCase()
          .includes(searchText.toLowerCase())
    );
  };

  const sort = (reports, order) => {
    const isDefault = order === SortOrders.Default;
    const isDescending = order === SortOrders.NameDesc;
    const sortedReports = isDefault
      ? _.orderBy(reports, ['id'], ['desc'])
      : reports.toSorted(Comparators.String(db => db.name, isDescending));
    return sortedReports;
  };

  const filter = (reports, categoryIds) => {
    return reports.filter(report =>
      categoryIds?.reduce(
        (accumulator, categoryId) => accumulator || isReportCategory(report, categoryId),
        false
      )
    );
  };

  // const onViewChange = () => {
  //   viewType === 'tile' ? setViewType('grid') : setViewType('tile');
  // };

  const onViewReportClick = report => {
    setReportId(report.id);
    setPageUrl(
      AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
        `&url=${encodeURIComponent(`/ng/report_schedules/${report.id}`)}`
    );
    //setShowIframeView(true);
    history.push(`/reports/schedules/view/${report.id}`);
  };

  const onEditReportClick = report => {
    setReportId(report.id);
    setPageUrl(pageUrlEdit);
    //setShowIframeEdit(true);
    history.push(`/reports/schedules/edit/${report.id}`);
  };

  const onDeleteReportClick = report => {
    confirmDeleteEntity({
      t,
      width: '700px',
      onConfirm: () => handleDeleteEntity(report),
      entityType: t('Insights.Report'),
      entityName: report.name
    });
  };

  const handleDeleteEntity = report => {
    setReportId(report.id);

    deleteReportScheduleQuery({
      reportId: report.id
    })
      .then(result => {
        //console.debug('deleteEntity', { result: result.data });
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('Insights.Toasts.DeleteEntitySuccess', {
              entityType: QsEntityType.Reports.singular,
              entityName: report.name
            })
          })
        );

        const updatedFilteredReports = filteredReports.filter(x => x.id !== report.id);
        setFilteredReports(updatedFilteredReports);
      })
      .catch(error => {
        //console.error('deleteEntity', { error: error });
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('Insights.Toasts.DeleteEntityError', {
              entityType: QsEntityType.Reports.singular,
              entityName: report.name
            })
          })
        );
      });
  };

  const capitalizeFirstLetter = str => {
    return str.charAt(0) + str.slice(1).toLowerCase();
  };

  const getDateFromField = rowData => {
    let runAt = '';
    if (rowData !== null) {
      const dataNextRun = rowData?.nextRunAt;

      if (dataNextRun !== undefined) {
        const date = new Date(dataNextRun);
        const options = {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        };
        const userLocale = navigator.language || 'en-US';

        runAt = date.toLocaleString(userLocale, options).replace(/[,]/g, '');
      }
    }
    return runAt;
  };

  //*****GRID************//
  const typeCellRenderer = (value, rowData, rowIndex) => {
    return <div>{getReportNameByType(rowData?.type)}</div>;
  };

  const nameCellRenderer = (value, rowData, rowIndex) => {
    return <div>{rowData?.name}</div>;
  };

  const frequencyCellRenderer = (value, rowData, rowIndex) => {
    return <div> {capitalizeFirstLetter(rowData?.frequency)} </div>;
  };

  const deliveryCellRenderer = (value, rowData, rowIndex) => {
    return <div> {rowData?.deliveryFormat} </div>;
  };

  const runAtCellRenderer = (value, rowData, rowIndex) => {
    let runAt = getDateFromField(rowData);

    return <div> {runAt} </div>;
  };

  const emailsCellRenderer = (value, rowData, rowIndex) => {
    let emails = '';
    if (
      rowData !== null &&
      rowData !== undefined &&
      rowData.otherEmails !== null &&
      rowData.otherEmails !== undefined &&
      rowData.otherEmails !== ''
    ) {
      emails = rowData?.otherEmails?.substring(1, rowData.otherEmails.length - 1).replace(/"/g, '');
    }
    return <div> {emails} </div>;
  };

  const categoryCellRenderer = (value, rowData, rowIndex) => {
    const categories = getReportCategoryLabels(rowData);

    return (
      Array.isArray(categories) &&
      categories?.map(category => (
        <Tag className={[cardStyles.categories, cardStyles.reportCard]} key={category - rowData.id}>
          {t(`Insights.Tags.${category}`)}
        </Tag>
      ))
    );
  };

  const actionCellRenderer = (value, rowData, rowIndex) => {
    return (
      <>
        <img
          src={imgView}
          onClick={() => onViewReportClick(rowData)}
          className={scheduleStyles.viewImg}
          alt={t(`Insights.Actions.View`)}
          title={t(`Insights.Actions.View`)}
        />
        <img
          src={imgEdit}
          onClick={() => onEditReportClick(rowData)}
          className={scheduleStyles.editImg}
          alt={t(`Insights.Actions.Edit`)}
          title={t(`Insights.Actions.Edit`)}
        />
        <img
          src={imgDelete}
          onClick={() => onDeleteReportClick(rowData)}
          className={scheduleStyles.deleteImg}
          alt={t(`Insights.Actions.Delete`)}
          title={t(`Insights.Actions.Delete`)}
        />
      </>
    );
  };

  const columns = [
    {
      title: t(`Insights.Table.ScheduleName`),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 150,
      render: nameCellRenderer,
      sorter: Comparators.String('name')
    },
    {
      title: t(`Insights.Table.Type`),
      key: 'type',
      fixed: 'left',
      width: 120,
      render: typeCellRenderer,
      sorter: Comparators.String('type')
    },
    {
      title: t(`Insights.Table.Frequency`),
      key: 'frequency',
      fixed: 'left',
      width: 50,
      render: frequencyCellRenderer
    },
    {
      title: t(`Insights.Table.DeliveryFormat`),
      key: 'delivery',
      fixed: 'left',
      width: 50,
      render: deliveryCellRenderer
    },
    {
      title: t(`Insights.Table.NextRunAt`),
      key: 'runat',
      fixed: 'left',
      width: 120,
      render: runAtCellRenderer,
      sorter: Comparators.Date('nextRunAt')
    },
    {
      title: t(`Insights.Table.Emails`),
      key: 'emails',
      fixed: 'left',
      width: 200,
      render: emailsCellRenderer
    },
    {
      title: t(`Insights.Table.Category`),
      key: 'tag',
      fixed: 'left',
      width: 50,
      render: categoryCellRenderer,
      sorter: Comparators.String(getReportCategoryLabel)
    },
    {
      title: t(`Insights.Table.Actions`),
      key: 'action',
      fixed: 'left',
      width: 100,
      render: actionCellRenderer
    }
  ];
  //*****GRID************//

  return (
    <>
      <ReportsPageToolbar
        count={filteredReports?.length}
        onSearch={handleSearch}
        onSort={handleSort}
        onFilter={handleFilter}
        //onViewChange={onViewChange}
        //viewType={viewType}
        showIframe={showIframeView || showIframeEdit}
        reports={reports}
      />

      {!(showIframeView || showIframeEdit) && (
        <Grid
          columns={columns}
          data={filteredReports}
          isLoading={reportsQuery.isFetching}
          showHeader={false}
          tableClassName={`${styles.newGridStyle} ${styles.schedule}`}
        />
      )}

      {showIframeView && !showIframeEdit && (
        <Iframe ref={frameRef} src={pageUrlView} className={styles.iframe}></Iframe>
      )}
      {showIframeEdit && !showIframeView && (
        <Iframe ref={frameRef} src={pageUrlEdit} className={styles.iframe}></Iframe>
      )}
    </>
  );
}

function _ReportFrame({ type, reportId }) {
  const userInfo = useUser();
  const frameRef = useRef(null);

  const [clickCount, increaseCount] = useReducer(
    state => {
      return { count: state.count + 1 };
    },
    { count: 0 }
  );

  const url = useMemo(() => {
    if (type === 'view') {
      return (
        AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
        `&url=${encodeURIComponent(`/ng/report_schedules/${reportId}`)}`
      );
    } else {
      return (
        AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
        `&url=${encodeURIComponent(`/ng/report_schedules/${reportId}/edit`)}`
      );
    }
  }, [type, userInfo, clickCount, reportId]);

  //useWalkMeInFrame(frameRef);
  return (
    <>
      <Iframe
        ref={frameRef}
        className={styles.reportFrame}
        src={url}
        width="100%"
        height="100%"
      ></Iframe>
    </>
  );
}

const ReportSchedule = React.memo(_ReportSchedule);
const ReportFrame = React.memo(_ReportFrame);

export function ReportScheduleNew() {
  const currentUrl = window.location.href;

  return (
    <Switch>
      <Route path="/reports/schedules/:type/:reportId">
        {({ match }) => <ReportFrame type={match.params.type} reportId={match.params.reportId} />}
      </Route>
      <Route exact path="/reports/schedules">
        <ReportSchedule />
      </Route>
    </Switch>
  );
}
