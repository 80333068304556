import React from 'react';

import { MobileOutlined, MailOutlined, DesktopOutlined } from '@ant-design/icons';
import { Switch, Tooltip } from 'antd';

import { MoreMenu } from 'components/ant/MoreMenu';
import ALERT_ACTIONS from 'containers/Configuration/CompanyAlerts/reducer/alertFormActions';
import { insertIf } from 'utils/methods';
import { Link } from 'react-router-dom';

import { handleSwitchChange } from './handleSwitchChange';
import { getAlertMoreMenuItems, getLinkFromType } from './getAlertMoreMenuItems';
import styles from '../AlertStatusBar.module.scss';
import { ENTITIES, PAGE_TYPES } from '../constants';
import {
  usesFleetsAndVehicles,
  usesDriversAndBranches,
  ALERT_LINKS,
  ALERT_TYPES
} from 'containers/Configuration/CompanyAlerts/constants';
import i18n from 'i18nextConfig';
import { isEntitySelected } from './isEntitySelected';

const getSwitchTooltipTitle = (isAlertConfigured, isUserAlertsMuted, pageType) => {
  if (!isAlertConfigured && pageType === PAGE_TYPES.VIEW) {
    return i18n.t('Alerts.Tooltip.SelectionRequired');
  }
  if (isUserAlertsMuted && pageType === PAGE_TYPES.VIEW) {
    return i18n.t('Alerts.Tooltip.DisabledAlert');
  }
  return '';
};

export const prepareAlertsDataForTable = (listOfAlerts, functions, currentUser, type, can) => {
  const { entityType, currentCompanyId } = listOfAlerts;
  const { dispatch, onChange, setIsDirty, localization } = functions;
  const canCopyAlert = can.copy;
  const canEditAlert =
    (can.edit && entityType === ENTITIES.COMPANY) || entityType === ENTITIES.USER;
  const canDeleteAlert = can.delete;
  const isDVIRService = can?.isDVIRServiceEnabled || false;

  const properties = {
    dispatch,
    current: {
      companyId: currentCompanyId,
      user: currentUser
    },
    entityType,
    onChange
  };

  const isDisabled = (row, entityType) =>
    !(isEntitySelected(row) && row.selectedUsers?.length) && entityType !== ENTITIES.USER;

  const isDocumentOrGeofenceExpiryDisabled = (row, entityType) =>
    !row.selectedUsers?.length && entityType !== ENTITIES.USER;

  return [
    {
      title: i18n.t('Common.Name'),
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (text, row) =>
        listOfAlerts?.action !== 'edit' ? (
          <Link
            to={{
              pathname:
                entityType === ENTITIES.COMPANY
                  ? `/settings/alerts/edit/${getLinkFromType(row.type)}/${row.id}`
                  : `/settings/user/alerts/edit/${getLinkFromType(row.type)}/${row.id}`
            }}
          >
            <div className={styles.nameColumnContainer}>{text}</div>
          </Link>
        ) : (
          <div className={styles.nameColumnContainer}>{text}</div>
        )
    },
    {
      get title() {
        return (
          <>
            <div className={styles.iconsContainer}>
              <span className={styles.icony}>
                <Tooltip title={i18n.t('Alerts.NotificationTypes.Phone')}>
                  <MobileOutlined className={styles.icon} />
                </Tooltip>
                <Tooltip title={i18n.t('Alerts.NotificationTypes.Email')}>
                  <MailOutlined className={styles.icon} />
                </Tooltip>
                <Tooltip title={i18n.t('Alerts.NotificationTypes.Desktop')}>
                  <DesktopOutlined className={styles.icon} />
                </Tooltip>
              </span>
            </div>
          </>
        );
      },
      dataIndex: 'not_types',
      key: 'not_types',
      className: styles.notificationsContainer,
      width: 250,
      render: (_text, row) => {
        const isUserAlertsMuted = entityType === ENTITIES.USER && !can?.active;
        const isSwitchDisabled = [
          ALERT_TYPES.GEOFENCE_EXPIRY,
          ALERT_TYPES.DOCUMENTEXPIRATION
        ].includes(row?.type)
          ? isDocumentOrGeofenceExpiryDisabled(row, entityType) || isUserAlertsMuted
          : isDisabled(row, entityType) || isUserAlertsMuted;
        const isAlertConfigured =
          isEntitySelected(row) ||
          row?.selectedUsers?.length ||
          [ALERT_TYPES.DOCUMENTEXPIRATION, ALERT_TYPES.GEOFENCE_EXPIRY].includes(row?.type);
        const isSmartjobs = type === ALERT_LINKS.SMARTJOBS || row?.type === ALERT_TYPES.SMARTJOBS;
        const disableSwitch =
          (isSwitchDisabled || !canEditAlert || !isAlertConfigured) &&
          row.pageType === PAGE_TYPES.VIEW;
        const switchTooltipTitle = getSwitchTooltipTitle(
          isAlertConfigured,
          isUserAlertsMuted,
          row.pageType
        );
        return (
          <span className={styles.content}>
            <Tooltip title={switchTooltipTitle}>
              <Switch
                checked={row?.phone}
                disabled={disableSwitch || isSmartjobs}
                onChange={() => {
                  handleSwitchChange(
                    {
                      ...row,
                      phone: row.pageType === PAGE_TYPES.VIEW ? !row.phone : row.phone,
                      localization,
                      isDVIRServiceEnabled: isDVIRService
                    },
                    properties,
                    ALERT_ACTIONS.SWITCH_MOBILE
                  );

                  if (typeof setIsDirty === 'function') {
                    setIsDirty(true);
                  }
                }}
              />
            </Tooltip>
            <Tooltip title={switchTooltipTitle}>
              <Switch
                checked={row?.mail}
                disabled={disableSwitch}
                onChange={() => {
                  handleSwitchChange(
                    {
                      ...row,
                      mail: row.pageType === PAGE_TYPES.VIEW ? !row.mail : row.mail,
                      localization,
                      isDVIRServiceEnabled: isDVIRService
                    },
                    properties,
                    ALERT_ACTIONS.SWITCH_MAIL
                  );
                  if (typeof setIsDirty === 'function') {
                    setIsDirty(true);
                  }
                }}
              />
            </Tooltip>
            <Tooltip title={switchTooltipTitle}>
              <Switch
                checked={row?.desktop}
                disabled={isSmartjobs || disableSwitch}
                onChange={() => {
                  handleSwitchChange(
                    {
                      ...row,
                      desktop: row.pageType === PAGE_TYPES.VIEW ? !row.desktop : row.desktop,
                      localization,
                      isDVIRServiceEnabled: isDVIRService
                    },
                    properties,
                    ALERT_ACTIONS.SWITCH_DESKTOP
                  );

                  if (typeof setIsDirty === 'function') {
                    setIsDirty(true);
                  }
                }}
              />
            </Tooltip>
          </span>
        );
      }
    },
    {
      title: i18n.t('Assigned'),
      dataIndex: 'vehicles',
      key: 'vehicles',
      render: (_txt, row) => (
        <div>
          {usesFleetsAndVehicles(row.type) &&
            `${i18n.t(
              row?.selectedVehicles?.length === 1 || row?.selectedDevices?.length === 1
                ? 'Alerts.Assigned.SingleVehicle'
                : 'Alerts.Assigned.MultipleVehicles',
              { number: (row?.selectedVehicles?.length || 0) + (row?.selectedDevices?.length || 0) }
            )} | ${i18n.t(
              row?.selectedFleets?.length === 1
                ? 'Alerts.Assigned.SingleFleet'
                : 'Alerts.Assigned.MultipleFleets',
              { number: row?.selectedFleets?.length }
            )}`}
          {usesDriversAndBranches(row.type) &&
            `${i18n.t(
              row?.selectedDrivers?.length === 1
                ? 'Alerts.Assigned.SingleDriver'
                : 'Alerts.Assigned.MultipleDrivers',
              { number: row?.selectedDrivers?.length }
            )} | ${i18n.t(
              row?.selectedBranches?.length === 1
                ? 'Alerts.Assigned.SingleBranch'
                : 'Alerts.Assigned.MultipleBranches',
              { number: row?.selectedBranches?.length }
            )}`}
        </div>
      )
    },
    ...insertIf(entityType === ENTITIES.COMPANY, {
      title: i18n.t('Alerts.SubscribedUsers'),
      dataIndex: 'users',
      key: 'users',
      render: (_txt, row) => {
        const combinedArray = Array.from(
          new Set([
            ...row?.selectedUsers,
            ...row?.selectedThirdAlertUsers,
            ...row?.selectedSecondAlertUsers
          ])
        );
        return (
          <div>
            {i18n.t(
              row?.selectedUsers?.length === 1
                ? 'Alerts.Assigned.SingleUser'
                : 'Alerts.Assigned.MultipleUsers',
              { number: combinedArray?.length }
            )}
          </div>
        );
      }
    }),
    ...insertIf(listOfAlerts?.action !== 'edit', {
      title: i18n.t('Alerts.Actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (_text, row) => {
        const { actions } = row;
        if (actions) {
          return entityType === ENTITIES.COMPANY ? (
            <MoreMenu
              useNewIcon={true}
              items={getAlertMoreMenuItems(row, entityType, {
                copy: canCopyAlert,
                edit: canEditAlert,
                delete: canDeleteAlert
              })}
            />
          ) : (
            <Link
              to={{
                pathname: `/settings/user/alerts/edit/${getLinkFromType(row.type)}/${row.id}`
              }}
            >
              {i18n.t('Alerts.Edit')}
            </Link>
          );
        }
      }
    })
  ];
};
