import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Space, Tooltip } from 'antd';
import { SnapshotConfigPopup } from 'features/dashboard/camera_tab/SnapshotConfig';
import { Can, GlobalRoles, services } from 'features/permissions';
import {
  fetchCompanyFleetData,
  fetchDeviceStats,
  actionTypes,
  updateDevicesSnapshotsQueryParams
} from 'features/dashboard/dashboardSlice';
import { useUserKey } from 'features/user/userSlice';
import { fetchSnapshots } from 'features/camera/cameraApi';
import { ReloadOutlined, SettingOutlined } from '@ant-design/icons';

export function SnapshotsTabExtras() {
  const { t } = useTranslation();
  const [showConfigPopup, setShowConfigPopup] = useState(false);

  const { isRefreshing, onRefresh, canRefresh } = useRefresh();
  const handleRefresh = useCallback(async () => {
    await onRefresh();
  }, [onRefresh]);
  return (
    <Space size={[6]} split={<Divider type="vertical" />}>
      <Tooltip title={t('Home.Refresh')}>
        <Button
          style={{ minWidth: '20px', width: '20px', height: '20px' }}
          type="text"
          icon={<ReloadOutlined style={{ color: '#64748B', fontSize: '20px' }} />}
          disabled={!canRefresh || isRefreshing}
          onClick={handleRefresh}
        ></Button>
      </Tooltip>
      <Can oneOfRoles={[GlobalRoles.Admin, GlobalRoles.SiteAdmin]} everyService={[services.CAMERA]}>
        <Tooltip title={t('Home.Configure')} placement="topLeft">
          <Button
            style={{ minWidth: '20px', width: '20px', height: '20px' }}
            type="text"
            icon={<SettingOutlined style={{ color: '#64748B', fontSize: '20px' }} />}
            onClick={() => setShowConfigPopup(true)}
          ></Button>
        </Tooltip>
        <SnapshotConfigPopup show={showConfigPopup} onHide={() => setShowConfigPopup(false)} />
      </Can>
    </Space>
  );
}

const useRefresh = () => {
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const [isLoadingSnapshots, setIsLoadingSnapshots] = useState(false);
  const queryParams = useSelector(state => state.dashboardData.devicesSnapshots.queryParams);

  const isRefreshing = useSelector(state => {
    const isFetchingCompanyFleet =
      state.dashboardData.companyFleets.status?.[queryParams?.companyId]?.fetching ===
      actionTypes.processing;
    const isFetchingCompanyDeviceStats =
      state.dashboardData.deviceStats.status?.[queryParams?.companyId]?.fetching ===
      actionTypes.processing;
    return isFetchingCompanyFleet || isFetchingCompanyDeviceStats || isLoadingSnapshots;
  });

  const canRefresh = useMemo(
    () =>
      queryParams?.companyId &&
      queryParams?.period?.from &&
      queryParams?.period?.to &&
      queryParams?.orderField &&
      queryParams?.orderDirection &&
      !!queryParams?.deviceIds?.length,
    [queryParams]
  );

  const onRefresh = useCallback(async () => {
    if (canRefresh) {
      const {
        companyId,
        period: { from: startTime, to: endTime },
        orderDirection,
        orderField,
        searchText: search,
        rowOffset,
        rowCountLimit,
        deviceIds: devices
      } = queryParams;
      return Promise.all([
        dispatch(fetchCompanyFleetData(userKey, companyId, 'fleets,vehicles,devices')),
        dispatch(fetchDeviceStats(userKey, companyId, 'users'))
      ]).then(() => {
        if (rowOffset === 0) {
          setIsLoadingSnapshots(true);
          return dispatch(
            fetchSnapshots({
              startTime: startTime.format(),
              endTime: endTime.format(),
              devices,
              orderField,
              orderDirection,
              rowOffset: 0,
              search,
              rowCountLimit,
              latestOnly: true,
              onSuccess() {
                setIsLoadingSnapshots(false);
              },
              onError(error) {
                setIsLoadingSnapshots(false);
              }
            })
          );
        } else {
          dispatch(updateDevicesSnapshotsQueryParams({ rowOffset: 0 }));
        }
      });
    }
  }, [dispatch, canRefresh, userKey, queryParams]);

  return {
    isRefreshing,
    canRefresh,
    onRefresh
  };
};
