import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/fontawesome-free-regular';
import styles from './FlipCard.module.scss';

export const FlipCard = ({ hflip = false, vflip = false, size = 80, color = '#00000073' }) => (
  <div
    className={`${styles.flipCard} ${
      hflip && vflip ? styles.flipHV : hflip ? styles.flipH : vflip ? styles.flipV : ''
    }`}
    style={{ width: size, height: size }}
  >
    <FontAwesomeIcon icon={faImage} color={color} style={{ width: size, height: size }} />
  </div>
);

export const FLIP_DIR = {
  hflip: 'hflip',
  vflip: 'vflip'
};
