import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  useSubCompanyEntityConfig,
  CompanyConfigKey,
  fetchCompanyHideNonBusinessConfig,
  fetchCompanySFIConfig,
  useCompanyConfig
} from 'features/company/companySlice';

import { companyConfigSelectors } from './selectors';
import {
  fetchCameraFields,
  fetchDriverManagementFields,
  fetchVehicleMaintenanceFields,
  fetchVehicleLoginLimitConfig,
  fetchCarrierData,
  fetchMultiCarrierFields,
  fetchIQCameraConfig,
  fetchIQCamerDevicesConfig,
  fetchWithinConfig,
  fetchOneWireMultiProbeFields
} from './thunks';
import { useTimer } from 'utils/hooks/useTimer';

const CONFIG_PERIODIC_CHECKING_TIME = 10 * 60 * 1000;

export const useCameraFields = companyId => {
  const dispatch = useDispatch();

  const cameraFields = useSelector(companyConfigSelectors.getCameraFields(companyId));

  const isError = useSelector(companyConfigSelectors.isErrorCameraFields(companyId));
  const isFetching = useSelector(companyConfigSelectors.fetchingCameraFields(companyId));
  const isSuccess = useSelector(companyConfigSelectors.isSuccessCameraFields(companyId));

  const isFetchingComplete = isSuccess || isError;

  if (!isFetching && !isFetchingComplete && companyId) {
    dispatch(fetchCameraFields({ companyId, type: 'camera' }));
  }

  return cameraFields;
};

export const useDriverManagementFields = companyId => {
  const dispatch = useDispatch();

  const driverManagementFields = useSelector(
    companyConfigSelectors.getDriverManagementFields(companyId)
  );

  const isError = useSelector(companyConfigSelectors.isErrorDriverManagementFields(companyId));
  const isFetching = useSelector(companyConfigSelectors.fetchingDriverManagementFields(companyId));
  const isSuccess = useSelector(companyConfigSelectors.isSuccessDriverManagementFields(companyId));

  const isFetchingComplete = isSuccess || isError;

  if (!isFetching && !isFetchingComplete && companyId) {
    dispatch(fetchDriverManagementFields({ companyId, type: 'drivermng' }));
  }

  return driverManagementFields;
};

export const useVehicleMaintenanceFields = companyId => {
  const dispatch = useDispatch();

  const vehicleMaintenanceFields = useSelector(
    companyConfigSelectors.getVehicleMaintenanceFields(companyId)
  );

  const isError = useSelector(companyConfigSelectors.isErrorVehicleMaintenanceFields(companyId));
  const isFetching = useSelector(
    companyConfigSelectors.fetchingVehicleMaintenanceFields(companyId)
  );
  const isSuccess = useSelector(
    companyConfigSelectors.isSuccessVehicleMaintenanceFields(companyId)
  );

  const isFetchingComplete = isSuccess || isError;

  if (!isFetching && !isFetchingComplete && companyId) {
    dispatch(fetchVehicleMaintenanceFields({ companyId, type: 'vehicle' }));
  }

  return vehicleMaintenanceFields;
};

export const useOneWireMultiProbeFields = companyId => {
  const dispatch = useDispatch();

  const fields = useSelector(companyConfigSelectors.getOneWireMultiProbeFields(companyId));

  const isError = useSelector(companyConfigSelectors.isErrorOneWireMultiProbeFields(companyId));
  const isFetching = useSelector(companyConfigSelectors.fetchingOneWireMultiProbeFields(companyId));
  const isSuccess = useSelector(companyConfigSelectors.isSuccessOneWireMultiProbeFields(companyId));

  const isFetchingComplete = isSuccess || isError;

  if (!isFetching && !isFetchingComplete && companyId) {
    dispatch(fetchOneWireMultiProbeFields({ companyId, type: 'temperatureProbeNames' }));
  }

  return fields;
};

export const useVehicleLoginLimitConfig = companyId => {
  const dispatch = useDispatch();

  const fetchStatus = useSelector(
    companyConfigSelectors.getVehicleLoginLimitConfigStatus(companyId)
  );
  const vehicleLoginLimitConfig = useSelector(
    companyConfigSelectors.getVehicleLoginLimitConfig(companyId)
  );

  const isFetching = fetchStatus?.isFetching;
  const hasFetched = fetchStatus && fetchStatus.fetchedAt;

  useEffect(() => {
    if (!isFetching && !hasFetched && companyId) {
      dispatch(fetchVehicleLoginLimitConfig(companyId));
    }
  }, [hasFetched, companyId]);

  return vehicleLoginLimitConfig;
};

export const useVehicleLoginLimitConfigUpdateStatus = companyId => {
  const updateStatus = useSelector(
    companyConfigSelectors.updateVehicleLoginLimitConfigStatus(companyId)
  );
  return updateStatus;
};

export const useNonBusinessConfig = companyId => {
  const dispatch = useDispatch();

  dispatch(fetchCompanyHideNonBusinessConfig(companyId));

  const companyEntityConfig = useCompanyConfig();
  const { hideNonBusinessEnabled } = companyEntityConfig;

  return { field1: hideNonBusinessEnabled };
};

export const useSmartjobsIntegrationConfig = companyId => {
  const dispatch = useDispatch();

  dispatch(fetchCompanySFIConfig(companyId));

  const companyEntityConfig = useCompanyConfig();
  const { SFIEnabled } = companyEntityConfig;

  return { enabled: SFIEnabled };
};

export const useCarrierData = companyId => {
  const dispatch = useDispatch();

  const carrierData = useSelector(companyConfigSelectors.carrierData);

  const isError = useSelector(companyConfigSelectors.isErrorCarrierData(companyId));
  const isFetching = useSelector(companyConfigSelectors.isFetchingCarrierData(companyId));
  const isSuccess = useSelector(companyConfigSelectors.isSuccessCarrierData(companyId));

  const isFetchingComplete = isSuccess || isError;

  if (!isFetching && !isFetchingComplete && companyId) {
    dispatch(fetchCarrierData({ companyId, type: 'carrier' }));
  }

  return carrierData;
};

export const useIsFetchingCarrierData = () =>
  useSelector(companyConfigSelectors.isFetchingCarrierData);

export const useMulticarrierFields = companyId => {
  const dispatch = useDispatch();

  const multicarrierFields = useSelector(companyConfigSelectors.getMulticarrierFields(companyId));

  const isError = useSelector(companyConfigSelectors.isErrorMulticarrierFields(companyId));
  const isFetching = useSelector(companyConfigSelectors.fetchingMulticarrierFields(companyId));
  const isSuccess = useSelector(companyConfigSelectors.isSuccessMulticarrierFields(companyId));

  const isFetchingComplete = isSuccess || isError;

  if (!isFetching && !isFetchingComplete && companyId) {
    dispatch(fetchMultiCarrierFields({ companyId, type: 'multicarrierenable' }));
  }

  return multicarrierFields;
};

export const useIQCameraConfig = (companyId, onError = () => {}) => {
  const dispatch = useDispatch();

  const fetchStatus = useSelector(companyConfigSelectors.getIQCameraConfigStatus(companyId));
  const iqCameraConfig = useSelector(companyConfigSelectors.getIQCameraConfig(companyId));

  const isFetching = fetchStatus?.isFetching;
  const hasFetched = fetchStatus && fetchStatus.fetchedAt;
  const isSilentFetching = isFetching && fetchStatus?.silentFetch;

  useTimer(CONFIG_PERIODIC_CHECKING_TIME, () => {
    if (hasFetched && !isFetching) {
      dispatch(fetchIQCameraConfig({ companyId, onError, silentFetch: true }));
    }
  });

  useEffect(() => {
    if (!isFetching && !hasFetched && companyId) {
      dispatch(fetchIQCameraConfig({ companyId, onError }));
    }
  }, [isFetching, hasFetched, companyId]);

  return { iqCameraConfig, isFetching, isSilentFetching };
};

export const useIQCameraUpdateStatus = companyId => {
  const updateStatus = useSelector(companyConfigSelectors.updateIQCameraConfigStatus(companyId));
  return updateStatus;
};

export const useIQCameraDevicesConfig = ({ companyId, deviceIds, onError = () => {} }) => {
  const dispatch = useDispatch();

  const fetchStatus = useSelector(companyConfigSelectors.getIQCameraDevicesConfigStatus(companyId));
  const iqCameraDevicesConfig = useSelector(
    companyConfigSelectors.getIQCameraDevicesConfig(companyId)
  );

  const isFetching = fetchStatus?.isFetching;

  const deviceIdsNeedFetch = useMemo(() => {
    if (!iqCameraDevicesConfig) {
      return deviceIds;
    }
    return deviceIds.filter(deviceId => {
      const deviceConfig = iqCameraDevicesConfig[deviceId];
      const deviceIsFetching = deviceConfig === null;
      const deviceIsFetched = !!deviceConfig && deviceConfig?.hasOwnProperty('config');
      return deviceId && !deviceIsFetching && !deviceIsFetched;
    });
  }, [deviceIds, iqCameraDevicesConfig]);

  useEffect(() => {
    if (deviceIdsNeedFetch.length) {
      dispatch(fetchIQCamerDevicesConfig({ companyId, deviceIds: deviceIdsNeedFetch, onError }));
    }
  }, [deviceIdsNeedFetch, companyId]);

  return { iqCameraDevicesConfig, isFetching };
};

export const useIQCameraDevicesConfigFetchStatus = companyId => {
  const fetchStatus = useSelector(companyConfigSelectors.getIQCameraDevicesConfigStatus(companyId));
  return fetchStatus;
};

export const useBulkUpdateIQCamerasStatus = companyId => {
  const updateStatus = useSelector(companyConfigSelectors.bulkUpdateIQCamerasStatus(companyId));
  return updateStatus;
};

export const useSmartDashcamConfig = companyId => {
  const subCompanyEntityConfig = useSubCompanyEntityConfig(
    companyId,
    CompanyConfigKey.SmartDashcam
  );

  return {
    driverEventSynch: subCompanyEntityConfig?.driverEventSynch,
    ssoAndUserSynch: subCompanyEntityConfig?.ssoAndUserSynch
  };
};

export const useIsFetchingWithinConfig = companyId =>
  useSelector(companyConfigSelectors.isFetchingWithinConfig(companyId));

export const useWithinConfig = companyId => {
  const dispatch = useDispatch();

  const withinConfig = useSelector(companyConfigSelectors.getWithinConfig(companyId));

  const isError = useSelector(companyConfigSelectors.isErrorWithinConfig(companyId));
  const isFetching = useSelector(companyConfigSelectors.isFetchingWithinConfig(companyId));
  const isSuccess = useSelector(companyConfigSelectors.isSuccessWithinConfig(companyId));

  const isFetchingComplete = isSuccess || isError;

  if (!isFetching && !isFetchingComplete && companyId) {
    dispatch(fetchWithinConfig({ companyId, type: 'within.enable' }));
  }

  return withinConfig;
};
