export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const countDropdownSelectedValues = (list, name, isAnt = false) => {
  const checkedLength = list.filter(item => item.checked).length;
  let displayValue;

  if (checkedLength === list.length) {
    displayValue = `All ${name}`;
    return displayValue;
  }

  if (checkedLength === 0) {
    displayValue = `${name}`;
  } else if (checkedLength === 1) {
    displayValue = list.find(item => item.checked).label;
  } else {
    displayValue = isAnt ? name : `${checkedLength} • ${name}`;
  }

  return displayValue;
};

export const FilterTypes = {
  text: 'text',
  companies: 'companies',
  fleets: 'fleets',
  vehicles: 'vehicles',
  types: 'types',
  filterBy: 'filterBy',
  drivers: 'drivers',
  branches: 'branches',
  groupBy: 'groupBy',
  typesOfUses: 'typesOfUses',
  rowFilter: 'rowFilter'
};

export const getCheckedFilterIds = filters => {
  return filters.filter(f => f.checked).map(f => f.id);
};

export const prepareDataForMultiselect = (items = [], allLabel, checkedIds = null) => {
  const data = allLabel ? [{ id: 0, name: allLabel }, ...items] : items;
  return data.map(filter => ({
    label: filter.name,
    id: filter.id,
    checked: isAllOptionChecked(checkedIds) ? true : checkedIds?.includes(filter.id)
  }));
};

export const isAllOptionChecked = checkedIds => {
  //convention: checkedIds null - initially checkedIds is null, it means the filters are pristine and "All" option is checked by default
  //convention checkedIds includes 0, it explicitly checks if "All" option is checked
  return checkedIds === null || checkedIds.includes(0);
};
