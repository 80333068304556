import { REGION_LOCALE } from 'features/localization/localization';
import { FeatureFlag } from 'features/permissions';
import { upperCase } from 'lodash';
import { Trans } from 'react-i18next';
import { format } from 'utils/dates';
import { fetchCameraChannels } from './cameraSlice';

const CameraObtainableMeta = {
  cameraCount: {
    title(t) {
      return t('Footage.NumberOfCameras');
    },
    value({ cameraInfo }) {
      return cameraInfo?.cameras || 0;
    }
  },
  sim: {
    title(t) {
      return t('Footage.SIM');
    },
    value({ device, cameraInfo }) {
      return device?.model?.name === CameraModelConfig.Visiontrack.name
        ? cameraInfo?.sim
        : device?.sim || cameraInfo?.sim;
    }
  },
  lastUpdated: {
    title(t) {
      return t('Footage.LastUpdated');
    },
    value({ t, localization, cameraInfo }) {
      const lastUpdated = cameraInfo?.lastUpdated;
      return (
        (lastUpdated && format(new Date(lastUpdated), localization.formats.time.formats.dby_hm)) ||
        t('Common.Unknown')
      );
    }
  },
  firstConnected: {
    title(t) {
      return t('Footage.FirstConnected');
    },
    value({ t, localization, cameraInfo }) {
      const firstConnected = cameraInfo?.firstConnected || cameraInfo?.first_connected;
      return (
        (firstConnected &&
          format(new Date(firstConnected), localization.formats.time.formats.dby_hm)) ||
        t('Common.Unknown')
      );
    }
  },
  state: {
    title(t) {
      return t('Footage.State');
    },
    value({ t, cameraInfo }) {
      return cameraInfo?.state;
    }
  },
  lastConnected: {
    title(t) {
      return t('Footage.LastConnected');
    },
    value({ t, localization, cameraInfo }) {
      const lastConnected = cameraInfo?.lastConnection || cameraInfo?.last_connected;
      return (
        (lastConnected &&
          format(new Date(lastConnected), localization.formats.time.formats.dby_hm)) ||
        t('Common.Unknown')
      );
    }
  }
};

const is_US_or_MX_Region = regionCode =>
  regionCode &&
  [upperCase(REGION_LOCALE.US.region), upperCase(REGION_LOCALE.MX.region)].includes(
    upperCase(regionCode)
  );

export const CameraModelConfig = {
  Goluk: {
    name: 'Goluk',
    provider: 'Goluk',
    obtainableMeta: [CameraObtainableMeta.lastConnected]
  },
  IQCamera: {
    name: 'IQCamera',
    provider: 'IQCamera',
    obtainableMeta: [CameraObtainableMeta.state, CameraObtainableMeta.lastConnected],
    getDataPlanMonthlyRenewOn: currentRegionCode =>
      is_US_or_MX_Region(currentRegionCode) ? 19 : 1,
    getDataPlanUsage: ({ dataPlan = {}, planKeys = [], currentRegionCode }) => {
      let plan = dataPlan;
      for (const planKey of planKeys) {
        plan = plan?.[planKey];
      }
      const max = isNaN(Number(plan?.max)) ? 0 : Number(plan?.max),
        used = isNaN(Number(plan?.count)) ? 0 : Number(plan?.count);
      const remaining = max - used < 0 ? 0 : max - used;
      return {
        isExhausted: remaining <= 0,
        max,
        remaining,
        used: max - remaining,
        monthlyRenewOn: is_US_or_MX_Region(currentRegionCode) ? 19 : 1
      };
    }
  },
  MultiIQ: {
    name: 'MultiIQ',
    provider: 'IQCamera',
    obtainableMeta: [CameraObtainableMeta.state, CameraObtainableMeta.lastConnected],
    supportMultiChannel: true,
    asyncChannelFetch: true,
    canSelectCameraChannel: (can = () => false) =>
      can({ oneOfFeatureFlags: [FeatureFlag.evoPhase1.flag, FeatureFlag.evoPhaseTGE.flag] }),
    getCameraChannelOptions: ({ t, deviceImei, dispatch }) => {
      dispatch(fetchCameraChannels(deviceImei));
    }
  },
  Netradyne: {
    name: 'Netradyne',
    provider: 'Netradyne',
    obtainableMeta: [],
    getRequestFootageWarning: t => [
      t('Footage.Warning1'),
      t('Footage.Warning2', { minutesValue: 2 }),
      t('Footage.Warning3'),
      t('Footage.Warning5')
    ]
  },
  Stonkam: {
    name: 'Stonkam',
    provider: 'Stonkam',
    obtainableMeta: [CameraObtainableMeta.lastConnected]
  },
  Trajet: {
    name: 'Trajet',
    provider: 'Trajet',
    getCameraChannelOptions: ({ t, cameraInfo }) => {
      const cameraCount = CameraObtainableMeta.cameraCount.value({ cameraInfo });
      const all = {
        label: t('Common.All'),
        value: 'all'
      };
      return cameraCount
        ? [
            all,
            ...new Array(cameraCount).fill(0).map((_, idx) => ({
              label: String(idx + 1),
              value: idx + 1
            }))
          ]
        : [all];
    },
    obtainableMeta: [
      CameraObtainableMeta.cameraCount,
      CameraObtainableMeta.lastConnected,
      CameraObtainableMeta.state
    ],
    getRequestFootageWarning: t => [
      <Trans i18nKey={'Footage.Warning1'} />,
      <Trans
        i18nKey={'Footage.Warning2'}
        values={{
          minutesValue: 20
        }}
      />,
      <Trans i18nKey={'Footage.Warning3'} />,
      <Trans
        i18nKey={'Footage.Warning4'}
        values={{ note: ` (${t('Footage.WarningNote')})` }}
        components={{ italic: <i /> }}
      />
    ]
  },
  Visiontrack: {
    name: 'Visiontrack',
    provider: 'Visiontrack'
  },
  defaultModel: {
    video: {
      maxLength: 120,
      stepper: 30
    },
    obtainableMeta: [],
    getRequestFootageWarning: t => [
      t('Footage.Warning1'),
      t('Footage.Warning2', { minutesValue: 2 }),
      t('Footage.Warning3'),
      t('Footage.Warning5')
    ]
  }
};

export const isIQCamModelName = modelName =>
  modelName &&
  [CameraModelConfig.IQCamera.name, CameraModelConfig.MultiIQ.name].some(
    iqModelName => iqModelName.toUpperCase() === modelName?.toUpperCase()
  );

export const isIQCamModelId = (modelId, deviceModels) =>
  isIQCamModelName(deviceModels?.find(model => String(model.id) === String(modelId))?.name);

export const isMultiIQCamModelId = (modelId, deviceModels) =>
  modelId &&
  deviceModels?.find(model => String(model.id) === String(modelId))?.name &&
  deviceModels.find(model => String(model.id) === String(modelId)).name.toUpperCase() ===
    CameraModelConfig.MultiIQ.name.toUpperCase();

export const isIQCamera = device => {
  const deviceModelName = device?.model?.name?.toUpperCase(),
    deviceTypeCode = device?.type?.code;
  return deviceTypeCode === 'CAMERA' && !!isIQCamModelName(deviceModelName);
};

export const isNewCameraDevice = device => device.stats.lastEvent === 'NEW';
